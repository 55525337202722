/**
 * state machine:
 *
 * ```
 *             ,----------------------------.
 *             |                            |
 *             V                            |
 * INTRO --> MENU <-> GAME -> ENDGAME - POST_ENDGAME
 *             ^        ^
 *             |        |
 *             V        V
 *        PAUSE_MENU  PAUSE
 * ```
 */
export enum GAME_STATES {
    INTRO = 0,
    MENU = 1,
    GAME = 2,
    ENDGAME = 3,
    // Used for Hoverboard, after player input on finishing the race
    POST_ENDGAME = 4,
    PAUSE = 5,
    // XXX like the PAUSE state, but resuming goes back to the MENU state
    // instead of the GAME state
    PAUSE_MENU = 6,
}