export class DynamicChevronsManager {
    constructor() {
        this.dynamicChevronsComponents = [];
    }

    setChevrons(dynamicChevronsComponents) {
        this.resetChevrons(); // old chevrons

        this.dynamicChevronsComponents = dynamicChevronsComponents;

        this.resetChevrons(); // new chevrons
    }

    resetChevrons() {
        for (let dynamicChevronComponent of this.dynamicChevronsComponents) {
            dynamicChevronComponent.resetChevrons();
        }
    }

    initializeChevrons() {
        for (let dynamicChevronComponent of this.dynamicChevronsComponents) {
            dynamicChevronComponent.initializeChevrons();
        }
    }

    activateChevrons() {
        for (let dynamicChevronComponent of this.dynamicChevronsComponents) {
            dynamicChevronComponent.activateChevrons();
        }
    }

    deactivateChevrons() {
        for (let dynamicChevronComponent of this.dynamicChevronsComponents) {
            dynamicChevronComponent.deactivateChevrons();
        }
    }
}