import { type Observable, type ObservableCallback } from "lazy-widgets";
import { AnalyticsUtils } from "wle-pp";

export abstract class GameModeVariable<T> implements Observable<T> {
    private listeners: ObservableCallback<T>[] = [];
    private _value: T;

    constructor(initialValue: T, readonly analyticsEvent: string) {
        this._value = initialValue;
    }

    setValue(value: T, userInitiated: boolean) {
        if (this._value === value) return;
        this._value = value;

        for (const listener of this.listeners) listener(this, undefined);

        if (userInitiated) {
            AnalyticsUtils.sendEventOnce(this.analyticsEvent);
            this.sendNetVar();
        }
    }

    set value(value: T) {
        this.setValue(value, true);
    }

    get value() {
        return this._value;
    }

    protected abstract sendNetVar(): void;

    watch(callback: ObservableCallback<T>, callNow?: boolean | undefined, group?: unknown): this {
        this.listeners.push(callback);
        if (callNow) callback(this, group);
        return this;
    }

    unwatch(callback: ObservableCallback<T>): this {
        const idx = this.listeners.indexOf(callback);
        if (idx >= 0) this.listeners.splice(idx, 1);
        return this;
    }
}