import { XRUtils } from "wle-pp";
import { GLOBAL_MODALS } from "./modal-manager.js";

export function copyText(description, text) {
    // stop XR session. text will be put in an HTML text box that is easily
    // copyable, so we have to kick the user from the session so that they can
    // see the text box
    if (XRUtils.isSessionActive()) {
        XRUtils.getSession().end();
    }

    // make copy box
    const container = GLOBAL_MODALS.makeModal();

    const descriptionPara = document.createElement("p");
    descriptionPara.textContent = description;
    const copyRow = document.createElement("div");
    copyRow.className = "copy-row";

    const copyInput = document.createElement("input");
    copyInput.type = "text";
    copyInput.value = text;
    copyInput.readOnly = true;
    const copyButton = document.createElement("div");
    copyButton.textContent = "Copy";

    let copyTimeout = null;
    copyInput.addEventListener("click", () => copyInput.select());
    copyButton.addEventListener("click", () => {
        if ("navigator" in window && "clipboard" in window.navigator) {
            window.navigator.clipboard.writeText(text);
            copyButton.textContent = "Copied!";

            if (copyTimeout !== null) {
                clearTimeout(copyTimeout);
            }

            copyTimeout = setTimeout(() => {
                copyTimeout = null;
                copyButton.textContent = "Copy";
            }, 5000);
        }
    });

    copyRow.appendChild(copyInput);
    copyRow.appendChild(copyButton);

    container.appendChild(descriptionPara);
    container.appendChild(copyRow);
}