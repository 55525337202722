import common from "../common.js";

export class WarningEmitter {
    constructor(showMessage, hideMessage, delayMS) {
        this.showMessage = showMessage;
        this.hideMessage = hideMessage;
        this.delayMS = delayMS;
        this.show = false;
        this.timeout = null;
    }

    setShow(show) {
        if (this.show === show) {
            return;
        }

        this.show = show;
        if (show) {
            this.timeout = setTimeout(() => {
                common.MAIN_CHANNEL.emit(this.showMessage);
                this.timeout = null;
            }, this.delayMS);
        } else if (this.timeout !== null) {
            clearTimeout(this.timeout);
            this.timeout = null;
        } else {
            common.MAIN_CHANNEL.emit(this.hideMessage);
        }
    }
}