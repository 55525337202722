import { BaseContainer, SingleParentXMLInputConfig, type Rect, type Widget, type WidgetAutoXML, type WidgetProperties } from "lazy-widgets";

export class KioskBackground<W extends Widget = Widget> extends BaseContainer<W> {
    static override autoXML: WidgetAutoXML = {
        name: "kiosk-background",
        inputConfig: SingleParentXMLInputConfig
    };

    constructor(child: W, properties?: Readonly<WidgetProperties>) {
        super(child, {
            containerPadding: { left: 0, right: 0, top: 0, bottom: 0 },
            ...properties,
        });
    }

    protected override handlePainting(dirtyRects: Array<Rect>): void {
        const ctx = this.viewport.context;
        ctx.fillStyle = "#1A232A";
        ctx.fillRect(this.x, this.y, this.width, this.height);
        super.handlePainting(dirtyRects);
    }
}