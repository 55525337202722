import { Component } from "@wonderlandengine/api";
import { XRUtils } from "wle-pp";

export class ResetPositionOnSessionComponent extends Component {
    static TypeName = "reset-position-on-session-end";
    static Properties = {};

    init() {
        this.listener = this._onXRSessionEnd.bind(this);
    }

    onActivate() {
        this.resetTransformLocal = this.object.pp_getTransformLocal();
        XRUtils.registerSessionEndEventListener(this, this.listener, this.engine);
    }

    onDeactivate() {
        XRUtils.unregisterSessionStartEndEventListeners(this, this.engine);
    }

    _onXRSessionEnd() {
        this.object.pp_setTransformLocal(this.resetTransformLocal);
    }
}