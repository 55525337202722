import { AudioPlayer, Vector3 } from "wle-pp";
import { HowlerAudio } from "./howler-audio.js";

export class PPAudio extends HowlerAudio {

    private _audioPlayer: AudioPlayer;

    constructor(audioPlayer: AudioPlayer) {
        super(audioPlayer._myAudio);

        this._audioPlayer = audioPlayer;
    }

    override play(): void {
        this._audioPlayer.play();
    }

    override stop(): void {
        this._audioPlayer.stop();
    }

    override pause(): void {
        this._audioPlayer.pause();
    }

    override resume(): void {
        this._audioPlayer.resume();
    }

    override isPlaying(): boolean {
        return this._audioPlayer.isPlaying();
    }

    override setPosition(position: Vector3, updateOnlyLastAudio: boolean = false): void {
        this._audioPlayer.updatePosition(position, updateOnlyLastAudio);
    }

    override setVolume(volume: number, updateOnlyLastAudio: boolean = false): void {
        this._audioPlayer.updateVolume(volume, updateOnlyLastAudio);
    }

    override getVolume(): number {
        return this._audioPlayer.getVolume();
    }

    override fade(fromVolume: number, toVolume: number, durationSeconds: number): void {
        this._audioPlayer.fade(fromVolume, toVolume, durationSeconds);
    }

    override setPitch(pitch: number, updateOnlyLastAudio: boolean = false): void {
        return this._audioPlayer.updatePitch(pitch, updateOnlyLastAudio);
    }
}