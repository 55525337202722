import common from "../../common.js";
import { AudioChannel, AudioChannelName } from "./audio-channel.js";
import { AudioID } from "./audio-id.js";
import { Audio } from "./audio.js";
import { SourceAudio } from "./source-audio.js";

export enum AudioMainChannelName {
    MUSIC = "music",
    SFX = "sfx",
    VOICE_OVERS = "voice_overs",
    VOIP = "voip"
}

export class AudioManager {

    private _audioChannelMap = new Map<AudioChannelName, AudioChannel>();

    private _mainChannelVolumes = new Map<AudioMainChannelName, number>();
    private _quietModeEnabled = false;

    constructor() {
        for (const audioChannel of Object.values(AudioChannelName)) {
            this._audioChannelMap.set(audioChannel, new AudioChannel());
        }

        for (const audioChannel of Object.values(AudioMainChannelName)) {
            this._mainChannelVolumes.set(audioChannel, 1);
        }

        const quietModeSavedValue = common.quietMode;
        if (quietModeSavedValue != null) {
            this.setQuietModeEnabled(quietModeSavedValue);
        }
    }

    getMainChannelVolume(mainChannelName: AudioMainChannelName) {
        return this._mainChannelVolumes.get(mainChannelName);
    }

    setMainChannelVolume(volume: number, mainChannelName: AudioMainChannelName) {
        this._mainChannelVolumes.set(mainChannelName, volume);
        // #TODO save channel volumes in preferences? And load them in constructor

        switch (mainChannelName) {
            case AudioMainChannelName.MUSIC:
                this.getChannel(AudioChannelName.MUSIC).getVolumeMixer().setVolume(volume);
                this.getChannel(AudioChannelName.AMBIENT).getVolumeMixer().setVolume(volume);

                if (this._quietModeEnabled) {
                    this.getChannel(AudioChannelName.MUSIC_ALMOST_QUIET).getVolumeMixer().setVolume(volume * 0.05);
                    this.getChannel(AudioChannelName.MUSIC_QUIET).getVolumeMixer().setVolume(0);
                } else {
                    this.getChannel(AudioChannelName.MUSIC_ALMOST_QUIET).getVolumeMixer().setVolume(volume);
                    this.getChannel(AudioChannelName.MUSIC_QUIET).getVolumeMixer().setVolume(volume);
                }
                break;
            case AudioMainChannelName.SFX:
                this.getChannel(AudioChannelName.SFX).getVolumeMixer().setVolume(volume);
                this.getChannel(AudioChannelName.INSTRUCTIONS).getVolumeMixer().setVolume(volume);

                if (this._quietModeEnabled) {
                    this.getChannel(AudioChannelName.SFX_QUIET).getVolumeMixer().setVolume(0);
                } else {
                    this.getChannel(AudioChannelName.SFX_QUIET).getVolumeMixer().setVolume(volume);
                }
                break;
            case AudioMainChannelName.VOICE_OVERS:
                if (this._quietModeEnabled) {
                    this.getChannel(AudioChannelName.VOICE_OVERS).getVolumeMixer().setVolume(0);
                } else {
                    this.getChannel(AudioChannelName.VOICE_OVERS).getVolumeMixer().setVolume(volume);
                }
                break;
            case AudioMainChannelName.VOIP:
                this.getChannel(AudioChannelName.VOIP).getVolumeMixer().setVolume(volume);
                break;
        }
    }

    isQuietModeEnabled() {
        return this._quietModeEnabled;
    }

    setQuietModeEnabled(enabled: boolean) {
        this._quietModeEnabled = enabled;

        this.setMainChannelVolume(this._mainChannelVolumes.get(AudioMainChannelName.MUSIC)!, AudioMainChannelName.MUSIC);
        this.setMainChannelVolume(this._mainChannelVolumes.get(AudioMainChannelName.SFX)!, AudioMainChannelName.SFX);
        this.setMainChannelVolume(this._mainChannelVolumes.get(AudioMainChannelName.VOICE_OVERS)!, AudioMainChannelName.VOICE_OVERS);
        this.setMainChannelVolume(this._mainChannelVolumes.get(AudioMainChannelName.VOIP)!, AudioMainChannelName.VOIP);
    }

    getChannel(audioChannelName: AudioChannelName): AudioChannel {
        return this._audioChannelMap.get(audioChannelName)!;
    }

    getAudio(audioID: AudioID | string): Audio | null {
        let audio: Audio | null = null;

        for (const audioChannel of this._audioChannelMap.values()) {
            audio = audioChannel.getAudio(audioID);
            if (audio != null) {
                break;
            }
        }

        return audio;
    }

    getAudioChannel(audioID: string): AudioChannel | null {
        let audioChannel: AudioChannel | null = null;

        for (const currentAudioChannel of this._audioChannelMap.values()) {
            if (currentAudioChannel.hasAudio(audioID)) {
                audioChannel = currentAudioChannel;
                break;
            }
        }

        return audioChannel;
    }

    addSourceAudioToChannel(audioID: string, sourceAudio: SourceAudio, audioChannelName: AudioChannelName): void {
        if (this.getAudioChannel(audioID) == null) {
            const audioChannel = this._audioChannelMap.get(audioChannelName)!;
            audioChannel.addSourceAudio(audioID, sourceAudio);
        } else {
            console.warn("The source audio is already in an audio channel");
        }
    }

    removeAudio(audioID: string): void {
        for (const audioChannel of this._audioChannelMap.values()) {
            if (audioChannel.removeAudio(audioID)) {
                break;
            }
        }
    }
}