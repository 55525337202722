export enum Handedness {
    LEFT = "left",
    RIGHT = "right"
}

export enum HandednessIndex {
    LEFT = 0,
    RIGHT = 1
}

export enum InputSourceType {
    GAMEPAD = 0,
    TRACKED_HAND = 1
}

export enum TrackedHandJointID {
    WRIST = "wrist",

    THUMB_METACARPAL = "thumb-metacarpal",
    THUMB_PHALANX_PROXIMAL = "thumb-phalanx-proximal",
    THUMB_PHALANX_DISTAL = "thumb-phalanx-distal",
    THUMB_TIP = "thumb-tip",

    INDEX_FINGER_METACARPAL = "index-finger-metacarpal",
    INDEX_FINGER_PHALANX_PROXIMAL = "index-finger-phalanx-proximal",
    INDEX_FINGER_PHALANX_INTERMEDIATE = "index-finger-phalanx-intermediate",
    INDEX_FINGER_PHALANX_DISTAL = "index-finger-phalanx-distal",
    INDEX_FINGER_TIP = "index-finger-tip",

    MIDDLE_FINGER_METACARPAL = "middle-finger-metacarpal",
    MIDDLE_FINGER_PHALANX_PROXIMAL = "middle-finger-phalanx-proximal",
    MIDDLE_FINGER_PHALANX_INTERMEDIATE = "middle-finger-phalanx-intermediate",
    MIDDLE_FINGER_PHALANX_DISTAL = "middle-finger-phalanx-distal",
    MIDDLE_FINGER_TIP = "middle-finger-tip",

    RING_FINGER_METACARPAL = "ring-finger-metacarpal",
    RING_FINGER_PHALANX_PROXIMAL = "ring-finger-phalanx-proximal",
    RING_FINGER_PHALANX_INTERMEDIATE = "ring-finger-phalanx-intermediate",
    RING_FINGER_PHALANX_DISTAL = "ring-finger-phalanx-distal",
    RING_FINGER_TIP = "ring-finger-tip",

    PINKY_FINGER_METACARPAL = "pinky-finger-metacarpal",
    PINKY_FINGER_PHALANX_PROXIMAL = "pinky-finger-phalanx-proximal",
    PINKY_FINGER_PHALANX_INTERMEDIATE = "pinky-finger-phalanx-intermediate",
    PINKY_FINGER_PHALANX_DISTAL = "pinky-finger-phalanx-distal",
    PINKY_FINGER_TIP = "pinky-finger-tip"
}

export enum TrackedHandJointIDIndex {
    WRIST = 0,

    THUMB_METACARPAL = 1,
    THUMB_PHALANX_PROXIMAL = 2,
    THUMB_PHALANX_DISTAL = 3,
    THUMB_TIP = 4,

    INDEX_FINGER_METACARPAL = 5,
    INDEX_FINGER_PHALANX_PROXIMAL = 6,
    INDEX_FINGER_PHALANX_INTERMEDIATE = 7,
    INDEX_FINGER_PHALANX_DISTAL = 8,
    INDEX_FINGER_TIP = 9,

    MIDDLE_FINGER_METACARPAL = 10,
    MIDDLE_FINGER_PHALANX_PROXIMAL = 11,
    MIDDLE_FINGER_PHALANX_INTERMEDIATE = 12,
    MIDDLE_FINGER_PHALANX_DISTAL = 13,
    MIDDLE_FINGER_TIP = 14,

    RING_FINGER_METACARPAL = 15,
    RING_FINGER_PHALANX_PROXIMAL = 16,
    RING_FINGER_PHALANX_INTERMEDIATE = 17,
    RING_FINGER_PHALANX_DISTAL = 18,
    RING_FINGER_TIP = 19,

    PINKY_FINGER_METACARPAL = 20,
    PINKY_FINGER_PHALANX_PROXIMAL = 21,
    PINKY_FINGER_PHALANX_INTERMEDIATE = 22,
    PINKY_FINGER_PHALANX_DISTAL = 23,
    PINKY_FINGER_TIP = 24
}