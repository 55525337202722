import { Component, Property } from "@wonderlandengine/api";
import { Globals } from "wle-pp";
import { HoverboardComponent } from "../../game/hoverboard/components/hoverboard-component.js";

/**
 * speedometer-blocks
 */
export class SpeedometerBlocksRotationComponent extends Component {
    static TypeName = "speedometer-blocks-rotation";
    /* Properties that are configurable in the editor */
    static Properties = {
        maxRotateInterval: Property.float(1.5),
        minRotateInterval: Property.float(0.05),
        rotationAmount: Property.float(-12.0)
    };

    start() {
        this.hoverboard = Globals.getRootObject(this.engine).pp_getComponent(HoverboardComponent);
        this.currentTimer = this.maxRotateInterval;
        this.rotationAxis = [0, 0, 1];
        this.children = this.object.children;
    }

    rotate() {
        for (const child of this.children) {
            child.rotateAxisAngleDegLocal(this.rotationAxis, this.rotationAmount);
        }
    }

    update(dt) {
        this.currentTimer -= dt * (this.hoverboard.speedPercentage + 1.0) * 2;

        if (this.currentTimer <= 0.0) {
            let rotateInterval = (this.maxRotateInterval + this.minRotateInterval) - (this.minRotateInterval + (this.hoverboard.speedPercentage * (this.maxRotateInterval - this.minRotateInterval)));
            this.currentTimer = rotateInterval;
            this.rotate();
        }
    }
}
