import { Component, Object3D } from "@wonderlandengine/api";
import { GameGlobals } from "src/hoverfit/misc/game-globals.js";
import { Vector3, vec3_create } from "wle-pp";

export class FinishGoalComponent extends Component {
    static override TypeName = "finish-goal";

    private _pivotObject!: Object3D;

    override init(): void {
        this._pivotObject = this.object.pp_getObjectByName("Pivot")!;
    }

    hide(): void {
        this.object.pp_setActive(false);
    }

    prepareNewLapLine(showGoal: boolean, splineForward: Vector3): void {
        this._pivotObject.pp_setUp(splineForward, GameGlobals.up);

        const verticalStretch = 3;
        const finishLineHeight = 1.5;

        this._pivotObject.pp_setScaleLocal(vec3_create(1, verticalStretch, 1));
        this._pivotObject.pp_translateObject(vec3_create(0, -verticalStretch * finishLineHeight, 0));

        if (showGoal) {
            this.object.pp_setActive(true);
        }
    }

    prepareFinishLine(showGoal: boolean) {
        this._pivotObject.pp_resetPositionLocal();
        this._pivotObject.pp_resetRotationLocal();
        this._pivotObject.pp_setScaleLocal(vec3_create(1, 1, 1));

        if (showGoal) {
            this.object.pp_setActive(true);
        }
    }

    resetGoal() {
        this.object.pp_setActive(false);
    }
}
