import { type CatalogItem, type heyVRSDK, type InventoryItem } from "@heyvr/sdk-types";
import { currentPlayerData } from "src/hoverfit/data/player-data.js";
import { unpackAssetShortID } from "src/hoverfit/utils/asset-utils.js";
import { wait } from "src/hoverfit/utils/wait.js";
import { type AssetBase, type AssetManifest } from "./asset-manifest-types.js";
import { AssetProvider, GenericPurchaseError, type ItemCategory, ItemCurrency, ItemNamespace, NoFundsPurchaseError } from "./asset-provider.js";
import { type IAPContentController } from "./iap-content-controller.js";
import { ShopItem } from "./shop-item.js";
import { mockConfig } from "./mock-config.js";

const IAP_ASSET_MANIFEST_URL = "assets/json/shop/iap-asset-manifest.json";

abstract class BaseIAPAssetProvider extends AssetProvider {
    protected readonly manifest: Promise<AssetManifest>;

    constructor(controller: IAPContentController) {
        super(controller);
        this.manifest = controller.fetchAndLoadManifest(IAP_ASSET_MANIFEST_URL, ItemNamespace.IAP);
    }
}

abstract class BasePurchaseableIAPAssetProvider extends BaseIAPAssetProvider {
    protected async parseCatalog(catalog: readonly CatalogItem[]) {
        const manifest = await this.manifest;
        const added: Array<[id: string, item: HeyVRShopItem]> = [];

        for (const item of catalog) {
            const category = item.item_class as ItemCategory | null;
            if (!category) continue;
            const manifestCat = manifest[category];
            if (!manifestCat) continue;
            const asset = manifestCat[item.slug];
            if (!asset) continue;
            const heyVRShopItem = new HeyVRShopItem(this, asset, item);
            added.push([heyVRShopItem.id, heyVRShopItem]);
        }

        this.catalog.removeNamespace(ItemNamespace.IAP);
        this.catalog.addMany(added);
    }
}

class MockHeyVRIAPAssetProvider extends BasePurchaseableIAPAssetProvider {
    private onAuthChanged = (changedKey?: string) => {
        if (changedKey != "auth_changed") return;
        this.onUserLoggedIn();
    };

    constructor(controller: IAPContentController) {
        super(controller);

        currentPlayerData.listen(this.onAuthChanged, "auth_changed");

        wait(0.5).then(async () => {
            if (!currentPlayerData.isGuest) {
                this.onUserLoggedIn();
            }

            await this.parseCatalog(mockConfig.inventory.catalog as CatalogItem[]);
        });
    }

    async fetchInventory(): Promise<void> {
        await wait(0.5);

        if (mockConfig.inventory) {
            const added: string[] = [];
            const manifest = await this.manifest;
            for (const item of mockConfig.inventory.myItems) {
                unpackAssetShortID(added, manifest, item.slug);
            }

            this.inventory.replaceNamespace(added, ItemNamespace.IAP);
        }
    }

    async purchaseItem(shortID: string): Promise<boolean> {
        await wait(0.5);

        let catItem: CatalogItem | null = null;
        for (const otherCatItem of mockConfig.inventory.catalog) {
            if (otherCatItem.slug === shortID) {
                catItem = otherCatItem as CatalogItem;
                break;
            }
        }

        if (!catItem) {
            throw new GenericPurchaseError(`Item with short ID "${shortID}" is not for sale`);
        }

        const price = catItem.price_discounted ?? catItem.price;

        if (price > currentPlayerData.heyVRCoins) {
            throw new NoFundsPurchaseError(ItemCurrency.HeyVR);
        }

        currentPlayerData.heyVRCoins -= price;
        mockConfig.user.userBalance -= price;

        // For persistence through map reloads
        const added: string[] = [];
        unpackAssetShortID(added, await this.manifest, shortID);
        this.inventory.addManyShort(added, ItemNamespace.IAP);

        mockConfig.inventory.myItems.push({
            // XXX doing assignment like this because the types are bad
            ...(catItem as Omit<CatalogItem, "model">),
            model: catItem.model as null,
            item_class: catItem.item_class as string,
        });

        return true;
    }

    private onUserLoggedIn(): void {
        currentPlayerData.heyVRCoins = mockConfig.user.userBalance;

        this.inventory.removeNamespace(ItemNamespace.IAP);
        this.fetchInventory();
    }

    override dispose() {
        super.dispose();
        currentPlayerData.unlisten(this.onAuthChanged);
    }
}

class HeyVRIAPAssetProvider extends BasePurchaseableIAPAssetProvider {
    private onAuthChanged = (changedKey?: string) => {
        if (changedKey != "auth_changed") return;

        if (!currentPlayerData.isGuest) {
            this.onUserLoggedIn();
        } else {
            this.inventory.removeNamespace(ItemNamespace.IAP);
            currentPlayerData.heyVRCoins = 0;
        }
    };

    constructor(readonly heyVR: heyVRSDK, controller: IAPContentController) {
        super(controller);

        if (!currentPlayerData.isGuest) {
            this.onUserLoggedIn();
        }

        currentPlayerData.listen(this.onAuthChanged, "auth_changed");

        this.heyVR.inventory.getCatalog().then((catalog) => {
            this.parseCatalog(catalog);
        });
    }

    private async updateCoins(): Promise<void> {
        currentPlayerData.heyVRCoins = await this.heyVR.user.getAmountCoins();
    }

    override async fetchInventory(): Promise<void> {
        const inventoryItems: ReadonlyArray<InventoryItem> = await this.heyVR.inventory.get();

        this.inventory.removeNamespace(ItemNamespace.IAP);
        const added: string[] = [];
        const manifest = await this.manifest;

        for (const item of inventoryItems) {
            // FIXME somehow inventoryItems is an array of slug strings when using the heyvr sandbox
            unpackAssetShortID(added, manifest, item.slug);
        }

        this.inventory.replaceNamespace(added, ItemNamespace.IAP);
    }

    private onUserLoggedIn(): void {
        this.inventory.removeNamespace(ItemNamespace.IAP);
        this.updateCoins();
        this.fetchInventory();
    }

    override async purchaseItem(shortID: string): Promise<boolean> {
        try {
            if (!await this.heyVR.inventory.purchase(shortID, 1)) return false;
            const added: string[] = [];
            unpackAssetShortID(added, await this.manifest, shortID);
            this.inventory.addManyShort(added, ItemNamespace.IAP);
            return true;
        } catch (err) {
            if (err && (err as any).status) {
                if (((err as any).status.debug === "err_not_enough_coins") || ((err as any).status.debug === "err_insufficient_balance")) {
                    throw new NoFundsPurchaseError(ItemCurrency.HeyVR);
                } else {
                    throw new GenericPurchaseError(`${(err as any).status.message}`);
                }
            } else {
                throw new GenericPurchaseError();
            }
        } finally {
            this.updateCoins();
        }
    }

    override dispose() {
        super.dispose();
        currentPlayerData.unlisten(this.onAuthChanged);
    }
}

export class HeyVRShopItem extends ShopItem {
    constructor(shopProvider: AssetProvider, asset: AssetBase, readonly catalogItem: Readonly<CatalogItem>) {
        super(shopProvider, asset);
    }

    get shortID() {
        return this.catalogItem.slug;
    }

    get namespace(): ItemNamespace.IAP {
        return ItemNamespace.IAP;
    }

    get itemCategory() {
        return this.catalogItem.item_class as ItemCategory;
    }

    get currencyType(): ItemCurrency.HeyVR {
        return ItemCurrency.HeyVR;
    }

    get price() {
        return this.catalogItem.price;
    }

    get priceDiscounted() {
        return this.catalogItem.price_discounted;
    }

    get description() {
        return super.description ?? this.catalogItem.description;
    }

    purchase() {
        return this.shopProvider.purchaseItem(this.shortID);
    }
}

/**
 * An IAP asset provider that only defines the IAP asset namespace, but doesn't
 * let you own or purchase any IAP asset. Used for environments without a mock
 * shop and without the HeyVR SDK, but where you can still join multiplayer
 * sessions with HeyVR players and therefore need to know the HeyVR assets.
 */
class UnpurchaseableIAPAssetProvider extends BaseIAPAssetProvider {
    override async fetchInventory(): Promise<void> {
        this.inventory.removeNamespace(ItemNamespace.IAP);
    }

    override async purchaseItem(shortID: string): Promise<boolean> {
        return false;
    }
}

export const IAPAssetProvider: new (controller: IAPContentController) => BaseIAPAssetProvider = window.heyVR
    ? HeyVRIAPAssetProvider.bind(null, window.heyVR)
    : ((!WL_EDITOR && DEV_MODE) ? MockHeyVRIAPAssetProvider : UnpurchaseableIAPAssetProvider);