import { Component } from '@wonderlandengine/api';
import { property } from '@wonderlandengine/api/decorators.js';
import common from 'src/hoverfit/common.js';
import { GAME_STATES } from 'src/hoverfit/game/game-states.js';
import { AudioPlayer, AudioSetup, MathUtils, Vec3Utils, vec3_create } from 'wle-pp';
import { Timer } from 'wle-pp/cauldron/cauldron/timer.js';
import { AudioChannelName } from '../../audio-manager/audio-channel.js';

const audioSourcePosition = vec3_create();
const hoverboardPosition = vec3_create();

export class ProximityAudioPlayerComponent extends Component {
    static TypeName = 'proximity-audio-player';

    @property.float(100.0)
    playDistanceThreshold!: number;

    @property.float(20.0)
    replayTime!: number;

    @property.string("")
    sfxPath!: string;

    private _replayTimer: Timer | undefined;
    private _audioId: string = "";

    start() {
        if (!this.sfxPath) return console.error("proximity-audio-player: No path to audio file.");

        const sfxAudioSetup = new AudioSetup(this.sfxPath);
        sfxAudioSetup.myVolume = 1.0;
        sfxAudioSetup.mySpatial = true;
        sfxAudioSetup.myReferenceDistance = this.playDistanceThreshold;
        // Assumed that this object is static
        const audioPosition = vec3_create();
        this.object.getPositionWorld(audioPosition);
        sfxAudioSetup.myPosition = audioPosition;

        this._audioId = 'proximity_audio_' + MathUtils.randomUUID();
        common.audioManager.addSourceAudioToChannel(this._audioId, new AudioPlayer(sfxAudioSetup), AudioChannelName.SFX);

        this._replayTimer = new Timer(this.replayTime);
    }

    private _checkIsTriggered() {
        this.object.getPositionWorld(audioSourcePosition);
        common.hoverboard.object.getPositionWorld(hoverboardPosition);
        const distance = Vec3Utils.distanceSquared(hoverboardPosition, audioSourcePosition);

        if (distance < this.playDistanceThreshold * this.playDistanceThreshold) {
            this._onTriggered();
        }
    }

    private _onTriggered() {
        common.audioManager.getAudio(this._audioId)?.play();
        this._replayTimer?.start();
    }


    update(dt: number) {
        if (common.CURRENT_STATE !== GAME_STATES.GAME) return;

        if (this._replayTimer?.isRunning()) {
            this._replayTimer.update(dt);
        } else {
            this._checkIsTriggered();
        }
    }
}
