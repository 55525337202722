export function replaceSearchParams(url: URL, searchParams: URLSearchParams) {
    if (Array.from(searchParams.keys()).length > 0) {
        window.history.replaceState(null, "", `${url.origin}${url.pathname}?${searchParams.toString()}${url.hash}`);
    } else {
        window.history.replaceState(null, "", `${url.origin}${url.pathname}${url.hash}`);
    }
}

const LOCAL_URL_SCHEMA = "local://";
export function getNameFromLocalURL(url: string) {
    return url.substring(LOCAL_URL_SCHEMA.length);
}

export function isLocalURL(url: string) {
    return url.startsWith(LOCAL_URL_SCHEMA);
}

function parseNumStr(str: string, message: string) {
    const dim = Number(str);
    if (isNaN(dim) || dim <= 0 || Math.trunc(dim) !== dim) {
        throw new Error(message);
    }

    return dim;
}

export interface StringImageConfig {
    width: number,
    height: number,
    targetFontSize: number,
    font: string,
    fillStyle: string,
    text: string,
}

const STRING_IMAGE_URL_SCHEMA = "string-image://";
const SI_BADNUM_MSG = "'Invalid number in string-image URL'";
export function parseStringImageURL(url: string): StringImageConfig {
    const urlNoSchema = url.substring(STRING_IMAGE_URL_SCHEMA.length);

    const parts = urlNoSchema.split(',', 6);
    if (parts.length !== 6) throw new Error('Invalid string-image URL');

    return {
        width: parseNumStr(parts[0], SI_BADNUM_MSG),
        height: parseNumStr(parts[1], SI_BADNUM_MSG),
        targetFontSize: parseNumStr(parts[2], SI_BADNUM_MSG),
        font: parts[3],
        fillStyle: parts[4],
        text: parts[5],
    };
}

export function isStringImageURL(url: string) {
    return url.startsWith(STRING_IMAGE_URL_SCHEMA);
}

export function isStringImageConfigEqual(a: Readonly<StringImageConfig>, b: Readonly<StringImageConfig>): boolean {
    if (a === b) return true;

    return a.width === b.width && a.height === b.height
        && a.targetFontSize === b.targetFontSize && a.font === b.font
        && a.fillStyle === b.fillStyle && a.text === b.text;
}

export interface LoftMeshConfig {
    texWidth: number,
    texHeight: number,
}

const LOFT_MESH_URL_SCHEMA = "loft-mesh://";
const LM_BADNUM_MSG = "'Invalid number in loft-mesh URL'";
export function parseLoftMeshURL(url: string): LoftMeshConfig {
    const urlNoSchema = url.substring(LOFT_MESH_URL_SCHEMA.length);

    const parts = urlNoSchema.split(',', 2);
    if (parts.length !== 2) throw new Error('Invalid loft-mesh URL');

    return {
        texWidth: parseNumStr(parts[0], LM_BADNUM_MSG),
        texHeight: parseNumStr(parts[1], LM_BADNUM_MSG),
    };
}

export function isLoftMeshURL(url: string) {
    return url.startsWith(LOFT_MESH_URL_SCHEMA);
}

export function isLoftMeshConfigEqual(a: Readonly<LoftMeshConfig>, b: Readonly<LoftMeshConfig>): boolean {
    return a === b || (a.texWidth === b.texWidth && a.texHeight === b.texHeight);
}