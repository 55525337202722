import { Container, type WidgetAutoXML } from "lazy-widgets";
import { type ItemCurrency } from "src/hoverfit/misc/asset-provision/asset-provider.js";
import { BaseDecoratedButton, type DecoratedButtonProperties } from "./base-decorated-button.js";
import { PriceRow } from "./price-row.js";

export class PurchaseButton extends BaseDecoratedButton {
    static override autoXML: WidgetAutoXML = {
        name: "purchase-button",
        inputConfig: [
            {
                mode: "value",
                name: "price",
            },
            {
                mode: "value",
                name: "currency",
            }
        ]
    };

    private priceRow: PriceRow;
    private _discounted = false;

    constructor(price: number, currency: ItemCurrency, properties?: Readonly<DecoratedButtonProperties>) {
        const priceRow = new PriceRow(price, currency);

        super(
            new Container(
                priceRow,
                {
                    containerPadding: { top: 4, bottom: 4, left: 0, right: 0 },
                },
            ),
            properties,
        );

        this.priceRow = priceRow;
        this.updateTextColor();
    }

    get price() {
        return this.priceRow.price;
    }

    set price(price: number) {
        this.priceRow.price = price;
    }

    get discounted() {
        return this._discounted;
    }

    set discounted(discounted: boolean) {
        this._discounted = discounted;
        this.priceRow.label.bodyTextFill = discounted ? 'white' : 'black';
        this.forceBackgroundFill = discounted ? '#FF4D4D' : null;
        this.holdBackgroundFill = discounted ? '#FF4D4D' : null;
        this.hoverBackgroundFill = discounted ? '#FF4D4D' : null;
        this.unhoverBackgroundFill = discounted ? 'red' : null;
    }

    get currency() {
        return this.priceRow.currency;
    }

    set currency(currency: ItemCurrency) {
        this.priceRow.currency = currency;
    }

    private updateTextColor() {
        this.child.bodyTextFill = this.clickable ? "black" : "rgb(128,128,128)";
    }

    override set clickable(clickable) {
        super.clickable = clickable;
        this.updateTextColor();
    }

    override get clickable() {
        return super.clickable;
    }
}