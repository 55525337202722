import { CollisionComponent, Component } from "@wonderlandengine/api";
import { vec3 } from "gl-matrix";

export class ScaleChildCollisionsComponent extends Component {
    static TypeName = "scale-child-collisions";
    static Properties = {
    };

    start() {
        let scale = this.object.pp_getScale()[0];

        if (Math.abs(scale - 1) > Math.PP_EPSILON) {
            this.scaleChildren(this.object, scale);
        }
    }

    scaleChildren(node, scale) {
        let collisions = node.pp_getComponents(CollisionComponent);

        for (let collision of collisions) {
            vec3.scale(collision.extents, collision.extents, scale);
        }
    }
}