// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore - The build system supports XML importing, but typescript doesn't
import xmlContent from "../xml/kiosk-upper.xml";

import { MultiContainer, ObservableTransformer, Variable, Widget, XMLUIParserContext } from "lazy-widgets";
import { WLVirtualKeyboardRoot } from "lazy-widgets-wle";
import { XRUtils } from "wle-pp";
import common from "../../../common.js";
import { currentGameConfig } from "../../../data/game-configuration.js";
import { currentPlayerData } from "../../../data/player-data.js";
import { BaseFitnessResortUIComponent } from "../../lazy-widgets/components/base-fitness-resort-ui-component.js";
import { BackPane } from "../../lazy-widgets/widgets/back-pane.js";
import { Book } from "../../lazy-widgets/widgets/book.js";
import { DecoratedButton } from "../../lazy-widgets/widgets/decorated-button.js";
import { KioskBackground } from "../widgets/kiosk-background.js";
import { Leaderboard, type LeaderboardEntry } from "../widgets/leaderboard.js";
import { ScoreRow } from "../widgets/score-row.js";

const DUMMY_ARRAY: LeaderboardEntry[] = [];

export const enum UpperUIMode {
    Leaderboard,
    CustomisationPreview,
}

export class KioskUpperUIComponent extends BaseFitnessResortUIComponent {
    static override TypeName = "kiosk-upper-ui";

    ready: boolean = false;

    private playerName!: Variable<string>;
    private playerDataListener!: () => void;
    private leaderboardData = new Variable<Array<LeaderboardEntry>>([]);
    private lastTrackChecked!: Variable<number>;
    private modeBook!: Book;
    private leaderboardBook!: Book;
    private fitPoints!: Variable<number>;
    private fitabux!: Variable<number>;
    private heyVRCoins!: Variable<number>;
    private localLeaderboardTrackText!: Variable<string>;

    override init() {
        super.init();

        this.playerName = new Variable<string>(currentPlayerData.name);
        this.fitPoints = new Variable<number>(currentPlayerData.totalFitPoints);
        this.heyVRCoins = new Variable<number>(currentPlayerData.heyVRCoins);
        this.fitabux = new Variable<number>(currentPlayerData.fitabux);
        this.localLeaderboardTrackText = new Variable<string>("");

        this.playerDataListener = () => {
            this.fitPoints.value = currentPlayerData.totalFitPoints;
            this.playerName!.value = currentPlayerData.name;
            this.heyVRCoins!.value = currentPlayerData.heyVRCoins;
            this.fitabux!.value = currentPlayerData.fitabux;
        };

        currentPlayerData.listen(this.playerDataListener, ["name", "totalFitPoints", "heyVRCoins", "fitabux"]);

        common.kioskUpperUI = this;
    }

    override createXMLParser() {
        const parser = super.createXMLParser();
        parser.autoRegisterFactory(Leaderboard);
        parser.autoRegisterFactory(Book);
        parser.autoRegisterFactory(KioskBackground);
        parser.autoRegisterFactory(BackPane);
        parser.autoRegisterFactory(ScoreRow);
        parser.autoRegisterFactory(DecoratedButton);

        return parser;
    }

    override getXMLParserConfig() {
        const TABLE_WIDTH = 560;
        const RANK_WIDTH = 100;
        const TIME_WIDTH = 100;
        this.lastTrackChecked = new Variable(currentGameConfig.track);

        return {
            ...super.getXMLParserConfig(),
            variables: {
                welcome: new ObservableTransformer([this.playerName], () => `Welcome, ${this.playerName.value}`),
                fitPoints: this.fitPoints,
                fitabux: this.fitabux,
                heyvrCoins: this.heyVRCoins,
                leaderboardWidth: TABLE_WIDTH,
                leaderboardRankWidth: RANK_WIDTH,
                leaderboardTimeWidth: TIME_WIDTH,
                leaderboardRowHeight: 18,
                globalLeaderboardTrackText: new ObservableTransformer([this.lastTrackChecked], () => {
                    common.leaderboard.getGlobalLeaderboard();
                    return `${currentGameConfig.locationConfig.name}, ${currentGameConfig.trackConfig.name}`;
                }),
                localLeaderboardTrackText: this.localLeaderboardTrackText,
                openLogin: () => {
                    if (XRUtils.isSessionActive()) {
                        common.kioskLowerUI.displayLoginVRWarning();
                    } else {
                        currentPlayerData.openLogin();
                    }
                }
            },
        };
    }

    private onAuthChanged!: (changedKey?: string) => void;

    protected override onUITreePicked(uiTree: Widget, context: XMLUIParserContext): void {
        super.onUITreePicked(uiTree, context);

        const globalLeaderboard = context.idMap.get("global-leaderboard") as Leaderboard;
        this.leaderboardData.watch(() => {
            globalLeaderboard.replaceLeaderboardData(this.leaderboardData.value);
        }, true);

        const localLeaderboard = context.idMap.get("local-leaderboard") as Leaderboard;
        this.leaderboardData.watch(() => {
            localLeaderboard.replaceLeaderboardData(this.leaderboardData.value);
        }, true);

        this.leaderboardBook = context.idMap.get("leaderbord-book") as Book;

        this.modeBook = context.idMap.get("mode-book") as Book;

        const openLoginButtonContainer = context.idMap.get("openLoginButtonContainer") as MultiContainer;

        openLoginButtonContainer.enabled = window.heyVR && currentPlayerData.isGuest;

        this.onAuthChanged = (changedKey?: string) => {
            if (changedKey != "auth_changed") return;

            openLoginButtonContainer.enabled = window.heyVR && currentPlayerData.isGuest;
        };
        currentPlayerData.listen(this.onAuthChanged, "auth_changed");

        this.ready = true;
    }

    changeLeaderboardType(leaderboardType: number) {
        this.leaderboardBook.changePage(leaderboardType);
    }

    replaceLeaderboardData(leaderboardDataArray: Array<LeaderboardEntry>) {
        // HACK change to dummy before changing to actual data so that the
        //      change is detected. ideally we would use a Variable class that
        //      can detect changes in an array
        this.leaderboardData.value = DUMMY_ARRAY;
        this.leaderboardData.value = leaderboardDataArray;

        this.localLeaderboardTrackText.value = currentGameConfig.locationConfig.name + ", " + currentGameConfig.trackConfig.name;
    }

    setLastRaceTime(finishTime: number, bestLapTime: number) {
        // TODO
    }

    changeMode(mode: UpperUIMode) {
        this.modeBook.changePage(mode);
    }

    protected override getXMLContent(): string {
        return xmlContent;
    }

    protected override beforeWidgetUpdate(root: WLVirtualKeyboardRoot, dt: number): boolean | void {
        this.lastTrackChecked.value = currentGameConfig.track;
        super.beforeWidgetUpdate(root, dt);
    }

    override onDestroy(): void {
        currentPlayerData.unlisten(this.onAuthChanged);
        currentPlayerData.unlisten(this.playerDataListener);
    }
}
