import { AudioID } from "src/hoverfit/audio/audio-manager/audio-id.js";
import common from "src/hoverfit/common.js";
import { Globals, Timer } from "wle-pp";
import { StatusEffectTarget } from "../status-effect-target.js";
import { StatusEffect, StatusEffectType } from "../status-effect.js";

export class ExpiringStatusEffectParams {
    duration: number = 1.5;

    gamepadPulseIntensityOnStart: number = 0.35;
    gamepadPulseDurationOnStart: number = 0.5;

    sfxOnStart: AudioID | null = null;
}

export abstract class ExpiringStatusEffect extends StatusEffect {

    protected _params: ExpiringStatusEffectParams;

    private _timer: Timer;

    constructor(type: StatusEffectType, target: StatusEffectTarget, params: ExpiringStatusEffectParams) {
        super(type, target);

        this._params = params;
        this._timer = new Timer(this._params.duration);
    }

    getParams(): ExpiringStatusEffectParams {
        return this._params;
    }

    start(): void {
        if (this._target.areStatusEffectFeedbacksEnabled()) {
            if (this._params.sfxOnStart != null) {
                const sfx = common.audioManager.getAudio(this._params.sfxOnStart);
                sfx!.play();
            }

            if (this._params.gamepadPulseIntensityOnStart > 0 && this._params.gamepadPulseDurationOnStart > 0) {
                Globals.getLeftGamepad()!.pulse(this._params.gamepadPulseIntensityOnStart, this._params.gamepadPulseDurationOnStart);
                Globals.getRightGamepad()!.pulse(this._params.gamepadPulseIntensityOnStart, this._params.gamepadPulseDurationOnStart);
            }
        }

        this._startHook();
    }

    update(dt: number): void {
        if (this.isDone()) return;

        this._timer.update(dt);
        if (this._timer.isDone()) {
            this.clear();
        } else {
            this._updateHook(dt);
        }
    }

    clear(): void {
        super.clear();

        this._clearHook();
    }

    protected _startHook(): void { }

    protected _updateHook(dt: number): void { }

    protected _clearHook(): void { }
}