import { BaseContainer, type Rect, type Widget, type WidgetAutoXML, type WidgetProperties, damageField } from "lazy-widgets";

export interface Background9SliceProperties extends WidgetProperties {
    horizontalSlicePercent: number;
    verticalSlicePercent: number;
    horizontalEdgePixels: number;
    verticalEdgePixels: number;
}

/**
 * A container which displays a 9-slice image as the background.
 */
export class Background9Slice extends BaseContainer {
    static override autoXML: WidgetAutoXML = {
        name: 'background-9slice',
        inputConfig: [
            {
                mode: 'widget',
                name: 'child'
            },
            {
                mode: 'value',
                name: 'image',
                validator: 'image-source'
            }
        ]
    };

    /** The current image used by the icon. */
    private _media: HTMLImageElement;
    /**
     * The last source that the current image was using. Used for tracking if
     * the image source changed and if the image is fully loaded.
     */
    private lastSrc: string | null = null;
    /** Has the user already been warned about the broken image? */
    private warnedBroken = false;
    /** Horizontal percentage that belongs to left/right slices */
    @damageField
    horizontalSlicePercent: number;
    /** Vertical percentage that belongs to top/bottom slices */
    @damageField
    verticalSlicePercent: number;
    /** Horizontal edge size, in pixels (for left/right slices) */
    @damageField
    horizontalEdgePixels: number;
    /** Vertical edge size, in pixels (for top/bottom slices) */
    @damageField
    verticalEdgePixels: number;

    constructor(child: Widget, image: HTMLImageElement | string, properties?: Readonly<Background9SliceProperties>) {
        super(child, properties);

        this.horizontalSlicePercent = properties?.horizontalSlicePercent ?? 0.33333;
        this.verticalSlicePercent = properties?.verticalSlicePercent ?? 0.33333;
        this.horizontalEdgePixels = properties?.horizontalEdgePixels ?? 10;
        this.verticalEdgePixels = properties?.verticalEdgePixels ?? 10;

        if (typeof image === 'string') {
            const imgElem = document.createElement('img');
            imgElem.src = image;
            image = imgElem;
        }

        this._media = image;
    }

    /**
     * The image used by this background.
     *
     * Sets {@link Background9Slice#_media} if changed and sets
     * {@link Background9Slice#lastSrc} to null to mark the image as loading so
     * that flickers are minimised.
     *
     * If getting, returns {@link Background9Slice#_media}.
     */
    set image(image: HTMLImageElement) {
        if (image === this._media) return;
        this._media = image;
        this.lastSrc = null;
    }

    get image(): HTMLImageElement {
        return this._media;
    }

    protected override handlePreLayoutUpdate(): void {
        // backgrounds only needs to be re-drawn if image changed, which is
        // tracked by the image setter, or if the source changed, but not if the
        // icon isn't loaded yet.
        const curSrc = this._media.src;
        if (curSrc !== this.lastSrc && this._media.complete) {
            this._layoutDirty = true;
            this.lastSrc = curSrc;
            this.markWholeAsDirty();
        }

        super.handlePreLayoutUpdate();
    }

    protected override handlePainting(dirtyRects: Array<Rect>): void {
        // paint background
        if (this._media.complete) {
            const [exScale, eyScale] = this.viewport.effectiveScale;
            const left = Math.trunc(this.x * exScale) / exScale;
            const top = Math.trunc(this.y * eyScale) / eyScale;
            const right = Math.trunc((this.x + this.width) * exScale) / exScale;
            const bottom = Math.trunc((this.y + this.height) * eyScale) / eyScale;
            const width = right - left;
            const height = bottom - top;

            const ctx = this.viewport.context;
            ctx.save();
            ctx.beginPath();
            ctx.rect(left, top, width, height);
            ctx.clip();

            let midLeft = Math.trunc((left + this.horizontalEdgePixels) * exScale) / exScale;
            let midRight = Math.trunc((left + width - this.horizontalEdgePixels) * exScale) / exScale;
            let midTop = Math.trunc((top + this.verticalEdgePixels) * eyScale) / eyScale;
            let midBottom = Math.trunc((top + height - this.verticalEdgePixels) * eyScale) / eyScale;

            const imgWidth = this._media.width;
            const imgHeight = this._media.height;
            let imgHorizSlice = imgWidth * this.horizontalSlicePercent;
            let imgVertSlice = imgHeight * this.verticalSlicePercent;

            try {
                const wantHMid = midLeft < midRight;
                if (!wantHMid) {
                    // horizontally compressed 9-slice
                    midLeft = midRight = Math.trunc((midLeft + midRight) * 0.5 * exScale) / exScale;
                    imgHorizSlice *= (midLeft - left) / this.horizontalEdgePixels;
                }

                const wantVMid = midTop < midBottom;
                if (!wantVMid) {
                    // vertically compressed 9-slice
                    midTop = midBottom = Math.trunc((midTop + midBottom) * 0.5 * eyScale) / eyScale;
                    imgVertSlice *= (midTop - top) / this.verticalEdgePixels;
                }

                const leftLen = midLeft - left;
                const rightLen = right - midRight;
                const horizMidLen = midRight - midLeft;
                const topLen = midTop - top;
                const bottomLen = bottom - midBottom;
                const vertMidLen = midBottom - midTop;
                const imgRightMidSlice = imgWidth - imgHorizSlice;
                const imgBottomMidSlice = imgHeight - imgVertSlice;
                const imgHorizMidSlice = imgRightMidSlice - imgHorizSlice;
                const imgVertMidSlice = imgBottomMidSlice - imgVertSlice;

                // corners
                ctx.drawImage(this._media, 0, 0, imgHorizSlice, imgVertSlice, left, top, leftLen, topLen);
                ctx.drawImage(this._media, imgRightMidSlice, 0, imgHorizSlice, imgVertSlice, midRight, top, rightLen, topLen);
                ctx.drawImage(this._media, 0, imgBottomMidSlice, imgHorizSlice, imgVertSlice, left, midBottom, leftLen, bottomLen);
                ctx.drawImage(this._media, imgRightMidSlice, imgBottomMidSlice, imgHorizSlice, imgVertSlice, midRight, midBottom, rightLen, bottomLen);

                // mid sections and center
                if (wantHMid) {
                    ctx.drawImage(this._media, imgHorizSlice, 0, imgHorizMidSlice, imgVertSlice, midLeft, top, horizMidLen, topLen);
                    ctx.drawImage(this._media, imgHorizSlice, imgBottomMidSlice, imgHorizMidSlice, imgVertSlice, midLeft, midBottom, horizMidLen, bottomLen);

                    if (wantVMid) {
                        ctx.drawImage(this._media, imgHorizSlice, imgVertSlice, imgHorizMidSlice, imgVertMidSlice, midLeft, midTop, horizMidLen, vertMidLen);
                    }
                }

                if (wantVMid) {
                    ctx.drawImage(this._media, 0, imgVertSlice, imgHorizSlice, imgVertMidSlice, left, midTop, leftLen, vertMidLen);
                    ctx.drawImage(this._media, imgRightMidSlice, imgVertSlice, imgHorizSlice, imgVertMidSlice, midRight, midTop, rightLen, vertMidLen);
                }

            } catch (err) {
                // HACK even though complete is true, the image might be in a broken
                // state, which is not easy to detect. to prevent a crash, catch the
                // exception and log as a warning
                if (!this.warnedBroken) {
                    this.warnedBroken = true;
                    console.error(err);
                    console.warn("Failed to paint image to Background9Slice widget. Are you using an invalid image URL? This warning won't be shown again");
                }
            }

            ctx.restore();
        }

        // paint child
        super.handlePainting(dirtyRects);
    }
}
