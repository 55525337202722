import { Component, type ComponentProperty, type Material, Mesh, MeshAttribute, MeshIndexType, type Object3D, Texture, Type, type WonderlandEngine } from "@wonderlandengine/api";
import { property } from "@wonderlandengine/api/decorators.js";
import common from "src/hoverfit/common.js";
import { getNameFromLocalURL, isLocalURL, isLoftMeshConfigEqual, isLoftMeshURL, isStringImageConfigEqual, isStringImageURL, type LoftMeshConfig, parseLoftMeshURL, parseStringImageURL, type StringImageConfig } from "src/hoverfit/utils/url-utils.js";

class StringImageManager {
    // TODO use hashes and verify equality agains stringimageconfig
    private cache = new Map<Readonly<StringImageConfig>, Texture>();

    constructor(readonly engine: WonderlandEngine) {}

    private generate(cfg: Readonly<StringImageConfig>): Texture {
        const canvas = document.createElement('canvas');
        const vpWidth = cfg.width;
        const vpHeight = cfg.height;
        canvas.width = vpWidth;
        canvas.height = vpHeight;

        const ctx = canvas.getContext('2d', { alpha: true });
        if (!ctx) throw new Error('Could not create 2D context');

        const vpWidthPad = vpWidth - 2;
        const vpHeightPad = vpHeight - 2;
        ctx.translate(1, 1);

        // ctx.fillStyle = 'red';
        // ctx.fillRect(0, 0, vpWidthPad, vpHeightPad);

        ctx.font = `${cfg.targetFontSize}px ${cfg.font}`;
        const text = cfg.text;
        const metrics = ctx.measureText(text);
        const width = metrics.width;
        const height = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;

        ctx.fillStyle = cfg.fillStyle;
        ctx.textBaseline = 'top';

        const scale = Math.min(1, vpWidthPad / width, vpHeightPad / height);
        if (scale < 1) {
            ctx.scale(scale, scale);
            const halfInvScale = 0.5 / scale;
            ctx.fillText(text, (vpWidthPad - width * scale) * halfInvScale, (vpHeightPad - height * scale) * halfInvScale);
        } else {
            ctx.fillText(text, (vpWidthPad - width) * 0.5, (vpHeightPad - height) * 0.5);
        }

        const tex = new Texture(this.engine, canvas);
        if (!tex.valid) throw new Error('Could not create texture');

        return tex;
    }

    getTexture(cfg: Readonly<StringImageConfig>): Texture {
        let cached = this.cache.get(cfg);
        if (cached) return cached;

        for (const [key, value] of this.cache) {
            if (isStringImageConfigEqual(cfg, key)) return value;
        }

        cached = this.generate(cfg);
        this.cache.set(cfg, cached);
        return cached;
    }
}

class LoftMeshManager {
    // TODO use hashes and verify equality agains stringimageconfig
    private cache = new Map<Readonly<LoftMeshConfig>, Mesh>();

    constructor(readonly engine: WonderlandEngine) {}

    private generate(cfg: Readonly<LoftMeshConfig>): Mesh {
        const l = 1 / cfg.texWidth;
        const r = 1 - l;
        const b = 1 / cfg.texWidth;
        const t = 1 - b;

        const mesh = new Mesh(this.engine, {
            indexData: new Uint8Array([
                0, 3, 1, // top-right triangle
                0, 2, 3, // bottom-left triangle
            ]),
            indexType: MeshIndexType.UnsignedByte,
            vertexCount: 4,
        });

        // TL, TR, BL, BR
        const positions = mesh.attribute(MeshAttribute.Position)!;
        positions.set(0, [-1, 1, 0, 1, 1, 0, -1, -1, 0, 1, -1, 0]);
        const normals = mesh.attribute(MeshAttribute.Normal)!;
        normals.set(0, [0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1]);
        const texCoords = mesh.attribute(MeshAttribute.TextureCoordinate)!;
        texCoords.set(0, [l, t, r, t, l, b, r, b]);

        return mesh;
    }

    getMesh(cfg: Readonly<LoftMeshConfig>): Mesh {
        let cached = this.cache.get(cfg);
        if (cached) return cached;

        for (const [key, value] of this.cache) {
            if (isLoftMeshConfigEqual(cfg, key)) return value;
        }

        cached = this.generate(cfg);
        this.cache.set(cfg, cached);
        return cached;
    }
}

export class HoverfitSceneResources {
    private readonly textures = new Map<string, Texture>();
    private readonly externalTextures = new Map<string, Promise<Texture>>();
    private readonly meshes = new Map<string, Mesh>();
    private readonly objects = new Map<string, Object3D>();
    private readonly externalObjects = new Map<string, Promise<Object3D>>();
    private readonly materials = new Map<string, Material>();
    private readonly strImgMgr: StringImageManager;
    private readonly loftMeshMgr: LoftMeshManager;

    constructor(readonly engine: WonderlandEngine, properties: Record<string, ComponentProperty>, component: Component) {
        this.strImgMgr = new StringImageManager(engine);
        this.loftMeshMgr = new LoftMeshManager(engine);

        for (const key of Object.getOwnPropertyNames(properties)) {
            const value = properties[key];
            if (value.type === Type.Texture) {
                this.textures.set(key, (component as any)[key]);
            } else if (value.type === Type.Mesh) {
                this.meshes.set(key, (component as any)[key]);
            } else if (value.type === Type.Object) {
                this.objects.set(key, (component as any)[key]);
            } else if (value.type === Type.Material) {
                this.materials.set(key, (component as any)[key]);
            } else {
                console.warn(`Unsupported built-in resource type (${value}) for key "${key}"`);
            }
        }
    }

    private getResource<T>(key: string, map: Map<string, T>): T {
        const val = map.get(key);
        if (val === undefined) {
            throw new Error(`Invalid built-in resource key "${key}"`);
        }

        return val;
    }

    private maybeGetLocalResource<T>(url: string, map: Map<string, T>): T | null {
        if (!isLocalURL(url)) return null;
        return this.getResource(getNameFromLocalURL(url), map);
    }

    getTexture(key: string) {
        return this.getResource(key, this.textures);
    }

    maybeGetLocalTexture(url: string) {
        return this.maybeGetLocalResource(url, this.textures);
    }

    maybeGetSpecialTexture(url: string): Texture | null {
        if (!isStringImageURL(url)) return null;
        return this.strImgMgr.getTexture(parseStringImageURL(url));
    }

    getTextureFromURL(url: string): Promise<Texture> {
        const localTex = this.maybeGetLocalTexture(url);
        if (localTex) return Promise.resolve(localTex);
        const specialTex = this.maybeGetSpecialTexture(url);
        if (specialTex) return Promise.resolve(specialTex);
        let promise = this.externalTextures.get(url);
        if (promise) return promise;
        promise = this.engine.textures.load(url, 'anonymous');
        this.externalTextures.set(url, promise);
        return promise;
    }

    getMesh(key: string) {
        return this.getResource(key, this.meshes);
    }

    maybeGetLocalMesh(url: string) {
        return this.maybeGetLocalResource(url, this.meshes);
    }

    maybeGetSpecialMesh(url: string): Mesh | null {
        if (!isLoftMeshURL(url)) return null;
        return this.loftMeshMgr.getMesh(parseLoftMeshURL(url));
    }

    getMeshFromURL(url: string): Promise<Mesh> {
        const localMesh = this.maybeGetLocalMesh(url);
        if (localMesh) return Promise.resolve(localMesh);
        const specialMesh = this.maybeGetSpecialMesh(url);
        if (specialMesh) return Promise.resolve(specialMesh);
        return Promise.reject(new Error(`Invalid mesh URL: ${url}`));
    }

    getObject(key: string) {
        return this.getResource(key, this.objects);
    }

    maybeGetLocalObject(url: string) {
        return this.maybeGetLocalResource(url, this.objects);
    }

    getObjectFromURL(url: string): Promise<Object3D> {
        const localObj = this.maybeGetLocalObject(url);
        if (localObj) return Promise.resolve(localObj);
        let promise = this.externalObjects.get(url);
        if (promise) return promise;
        promise = this.engine.scene.append(url) as Promise<Object3D>;
        this.externalObjects.set(url, promise);
        return promise;
    }

    getMaterial(key: string) {
        return this.getResource(key, this.materials);
    }

    maybeGetLocalMaterial(url: string) {
        return this.maybeGetLocalResource(url, this.materials);
    }
}

export class HoverfitSceneResourcesComponent extends Component {
    static TypeName = "hoverfit-scene-resources";

    @property.texture()
    hoverboardOakDiffuseTexture!: Texture;
    @property.texture()
    hoverboardOakNormalTexture!: Texture;
    @property.texture()
    hoverboardSpruceDiffuseTexture!: Texture;
    @property.texture()
    hoverboardSpruceNormalTexture!: Texture;
    @property.texture()
    hoverboardCarbonDiffuseTexture!: Texture;
    @property.texture()
    hoverboardCarbonNormalTexture!: Texture;
    @property.texture()
    suitDefaultFemaleDiffuseTexture!: Texture;
    @property.texture()
    suitDefaultFemaleEmissiveTexture!: Texture;
    @property.texture()
    suitDefaultMaleDiffuseTexture!: Texture;
    @property.texture()
    suitDefaultMaleEmissiveTexture!: Texture;
    @property.texture()
    defaultLogoTexture!: Texture;
    @property.object()
    hoverboardDefaultRoot!: Object3D;
    @property.object()
    suitDefaultFemaleRoot!: Object3D;
    @property.object()
    suitDefaultMaleRoot!: Object3D;

    init() {
        // XXX do not put this in start. we want this to be available VERY early
        common.hoverfitSceneResources = new HoverfitSceneResources(this.engine, HoverfitSceneResourcesComponent.Properties, this);
    }
}