import { OnAuthMessage } from "@heyvr/sdk-types";
import { Object3D } from "@wonderlandengine/api";
import { AnalyticsUtils, Globals, ObjectUtils, Timer } from "wle-pp";
import { AvatarComponent } from "../avatar/components/avatar-component.js";
import common from "../common.js";
import { HoverboardComponent } from "../game/hoverboard/components/hoverboard-component.js";
import { ItemCategory } from "../misc/asset-provision/asset-provider.js";
import { getBuiltInItemIDByIndex, getRandomBuiltInItemID } from "../misc/asset-provision/built-in-asset-provider.js";
import { getRandomName } from "../misc/get-random-name.js";
import { PENDING_LEADERBOARD_SCORES_DEFAULT, PENDING_LEADERBOARD_SCORES_KEY, PERMISSIONS_DEFAULT, PERMISSIONS_KEY, SAVE_SLOT_DEFAULT, SAVE_SLOT_KEY } from "../misc/preferences/pref-keys.js";
import { GLOBAL_PREFS } from "../misc/preferences/preference-manager.js";
import { PopupIconImage } from "../ui/popup/popup.js";
import { getCookie, setCookie } from "../utils/cookie-utils.js";
import { RewardTier } from "../utils/reward-utils.js";
import { type Gender } from "./values/gender.js";

class PendingLeaderboardScore {
    private static _persistHour = 10;

    leaderboardID: string = "";
    score: number = 0;
    elapsingTimeOnNewSession: number = Date.now() + PendingLeaderboardScore._persistHour * 60 * 60 * 1000;
}

class PendingLeaderboardScores {
    pendingScores: PendingLeaderboardScore[] = [];
}

const SAVEDATA_ASSET_KEYS: ReadonlyMap<string, ItemCategory> = new Map([
    ['skinColor', ItemCategory.Skin],
    ['hoverboardVariant', ItemCategory.Hoverboard],
    ['suitVariant', ItemCategory.Suit],
    ['hairColor', ItemCategory.HairColor],
    ['headwearVariant', ItemCategory.Headwear],
]);

export interface SaveData {
    avatarType: Gender;
    skinColor: string;
    hoverboardVariant: string;
    suitVariant: string;
    hairColor: string;
    headwearVariant: string;
    totalFitPoints: number;
    dailyFitPoints: number;
    dailyRewardTier: RewardTier;
    bronzeMedals: number;
    silverMedals: number;
    goldMedals: number;
    platinumMedals: number;
    currentMidnightTime: number;
    fitabux: number;
    fitabuxInventory: string[];
}

export type PlayerDataListener = (changedKey?: string) => void;

export class PlayerData implements SaveData {
    private _initialized: boolean = false;

    private _saveSlotVersion: number = 2;

    private _player: Object3D | null = null;
    private _avatar: AvatarComponent | null = null;
    private _hoverboard: HoverboardComponent | null = null;
    private _name: string | null = null;
    private _defaultName: string | null = null;

    private _avatarType!: Gender;
    skinColor!: string;
    hoverboardVariant!: string;
    suitVariant!: string;
    hairColor!: string;
    headwearVariant!: string;
    private _totalFitPoints!: number;
    dailyFitPoints!: number;
    dailyRewardTier!: RewardTier;
    bronzeMedals!: number;
    silverMedals!: number;
    goldMedals!: number;
    platinumMedals!: number;
    currentMidnightTime!: number;
    private _fitabux!: number;
    private _heyVRCoins!: number;
    private _fitabuxInventory: string[] = [];

    private _isGuest = true;
    private listeners = new Set<PlayerDataListener>();
    private listenerFilters = new Map<PlayerDataListener, string[]>();

    private _checkDailyFitPointsResetTimer = new Timer(5);

    private _firstLoadDataDone = false;
    private _lastLoadSucceded = false;
    private _pendingLeaderboardScores: PendingLeaderboardScores | null = null;
    private _fallbackGuestDataUsed = false;
    private _fallbackCookieDataUsed = false;
    private _displayLoadFailedMessage = false;

    constructor(private readonly _randomize: boolean = false) {
        this._resetPlayerData();
    }

    init() {
        if (this._initialized) return;

        const savedPendingLeaderboardScoresJSON = GLOBAL_PREFS.getPref(PENDING_LEADERBOARD_SCORES_KEY, PENDING_LEADERBOARD_SCORES_DEFAULT);
        if (savedPendingLeaderboardScoresJSON != null) {
            try {
                const pendingLeaderboardScoresToCheck = JSON.parse(savedPendingLeaderboardScoresJSON) as PendingLeaderboardScores | null;
                if (pendingLeaderboardScoresToCheck != null) {
                    this._pendingLeaderboardScores = pendingLeaderboardScoresToCheck;
                }
            } catch (error) {
                // Do nothing
            }
        }

        if (this._pendingLeaderboardScores == null) {
            this._pendingLeaderboardScores = new PendingLeaderboardScores();
        } else {
            const currentTime = Date.now();
            this._pendingLeaderboardScores.pendingScores.pp_removeAll((elementToCheck) => { return elementToCheck.elapsingTimeOnNewSession < currentTime; });
        }

        if (window.heyVR) {
            window.heyVR.user.onAuthChange((payload: OnAuthMessage) => {
                if (payload.loggedIn) {
                    this.isGuest = false;

                    this.loadPlayerName();

                    const leaderboardPromises = [];
                    for (const pendingScore of this._pendingLeaderboardScores!.pendingScores) {
                        if (pendingScore.leaderboardID.length > 0) {
                            leaderboardPromises.push(window.heyVR.leaderboard.postScore(pendingScore.leaderboardID, pendingScore.score));
                        }
                    }
                    Promise.allSettled(leaderboardPromises).finally(() => common.leaderboard?.getLeaderboard());

                    this._pendingLeaderboardScores = new PendingLeaderboardScores();
                    GLOBAL_PREFS.setPref(PENDING_LEADERBOARD_SCORES_KEY, JSON.stringify(this._pendingLeaderboardScores));
                } else {
                    this.isGuest = true;
                    this.name = null;
                }

                this._loadPlayerDataInternal();
            });
        }

        this.loadPlayerName().then((loadSucceeded) => {
            if (!loadSucceeded) {
                const searchParams = new URLSearchParams(window.location.search);
                const desiredName = searchParams.get("name");
                if (desiredName != null && desiredName.length > 0) {
                    this.name = desiredName;
                }
            }
        }).finally(() => {
            this._loadPlayerDataInternal();
        });

        this._initialized = true;
    }

    invalidateSceneData() {
        this._player = null;
        this._avatar = null;
        this._hoverboard = null;
    }

    isFirstLoadDataDone() {
        return this._firstLoadDataDone;
    }

    get player(): Object3D {
        if (!this._player) this._player = Globals.getPlayerObjects()!.myPlayer!;
        return this._player;
    }

    get avatar() {
        if (!this._avatar) this._avatar = ObjectUtils.getComponent(this.player, AvatarComponent);
        return this._avatar;
    }

    get hoverboard() {
        if (!this._hoverboard) this._hoverboard = ObjectUtils.getComponent(this.player, HoverboardComponent);
        return this._hoverboard;
    }

    isUsingDefaultName() {
        return this._name == null;
    }

    get avatarType() {
        return this._avatarType;
    }

    set avatarType(avatarType) {
        if (this._avatarType !== avatarType) {
            this._avatarType = avatarType;
            this._defaultName = getRandomName(this);
            if (this.isUsingDefaultName()) {
                this.notify();
            }
        }
    }

    get defaultName() {
        if (!this._defaultName) {
            this._defaultName = getRandomName(this);
        }

        return this._defaultName;
    }

    get name(): string {
        return this._name != null ? this._name : this.defaultName;
    }

    // TODO i don't like that name can be null, but the getter always returns a
    //      string. this is going to cause a bug eventually
    set name(name: string | null) {
        if (this._name !== name) {
            this._name = name;
            this.notify("name");
        }
    }

    get isGuest() {
        return this._isGuest;
    }

    set isGuest(isGuest) {
        if (this._isGuest !== isGuest) {
            this._isGuest = isGuest;
            this.notify();
        }
    }

    get totalFitPoints() {
        return this._totalFitPoints;
    }

    set totalFitPoints(totalFitPoints) {
        if (totalFitPoints === this._totalFitPoints) return;
        this._totalFitPoints = totalFitPoints;
        this.notify("totalFitPoints");
    }

    get fitabuxInventory() {
        return this._fitabuxInventory;
    }

    set fitabuxInventory(fitabuxInventory) {
        if ((fitabuxInventory === this._fitabuxInventory) || (fitabuxInventory.length === 0 && this._fitabuxInventory.length === 0)) return;
        this._fitabuxInventory = fitabuxInventory;
        this.notify("fitabuxInventory");
    }

    get fitabux() {
        return this._fitabux;
    }

    set fitabux(fitabux) {
        if (fitabux === this._fitabux) return;
        this._fitabux = fitabux;
        this.notify("fitabux");
    }

    get heyVRCoins() {
        return this._heyVRCoins;
    }

    set heyVRCoins(heyVRCoins) {
        if (heyVRCoins === this._heyVRCoins) return;
        this._heyVRCoins = heyVRCoins;
        this.notify("heyVRCoins");
    }

    private notify(changedKey?: string) {
        if (!changedKey) {
            for (const listener of this.listeners) {
                listener(changedKey);
            }
        } else {
            for (const listener of this.listeners) {
                const filter = this.listenerFilters.get(listener);
                if (filter && filter.indexOf(changedKey) < 0) continue;
                listener(changedKey);
            }
        }
    }

    listen(callback: () => void, keyFilter?: string | string[]) {
        this.listeners.add(callback);

        if (keyFilter) {
            if (Array.isArray(keyFilter)) {
                this.listenerFilters.set(callback, keyFilter);
            } else {
                this.listenerFilters.set(callback, [keyFilter]);
            }
        }
    }

    unlisten(callback: () => void) {
        this.listeners.delete(callback);
        this.listenerFilters.delete(callback);
    }

    update(dt: number) {
        if (this.isFirstLoadDataDone()) {
            this._checkDailyFitPointsResetTimer.update(dt);
            if (this._checkDailyFitPointsResetTimer.isDone()) {
                this.checkDailyFitPointsReset();
                this._checkDailyFitPointsResetTimer.start();
            }
        }

        if (common.intro.isDone()) {
            if (this._displayLoadFailedMessage) {
                this._displayLoadFailedMessage = false;
                common.popupManager.showMessagePopup("Failed to load current player data!\nPlease reload the page", PopupIconImage.Error);
            }
        }
    }

    private async loadPlayerName() {
        if (window.heyVR == null) return false;

        let loadSucceeded = false;

        try {
            const username = await window.heyVR.user.getName();

            this.name = username;
            this.isGuest = false;

            loadSucceeded = true;

            AnalyticsUtils.sendEventOnce("logged_in");
        } catch (error) {
            console.error("Error loading name:", error);
        }

        return loadSucceeded;
    }

    private checkDailyFitPointsReset() {
        let dailyFitPointsReset = false;

        const newCurrentTime = new Date();
        const oneDayInMs = 24 * 60 * 60 * 1000;
        const newDay = (newCurrentTime.getTime() - this.currentMidnightTime) > oneDayInMs;
        if (newDay) {
            this.dailyFitPoints = 0;
            this.dailyRewardTier = RewardTier.None;

            // This will update to the midnight of the current time zone if you changed it in the meantime
            // It's a good thing, since this way until a day from the last midnight has passed, you use the old midnight
            // And then adjust to the new one.
            // This means you will have less time for the current day due to this, but after that it will adjust
            // If you keep changing time zones then screw you
            this.currentMidnightTime = newCurrentTime.setHours(0, 0, 0, 0);

            dailyFitPointsReset = true;
        }

        return dailyFitPointsReset;
    }

    async savePlayerData() {
        if (!this._lastLoadSucceded) return false;

        this.checkDailyFitPointsReset();

        const saveData: SaveData = {
            avatarType: this.avatarType,
            skinColor: this.skinColor,
            hoverboardVariant: this.hoverboardVariant,
            suitVariant: this.suitVariant,
            hairColor: this.hairColor,
            headwearVariant: this.headwearVariant,
            totalFitPoints: this._totalFitPoints,
            bronzeMedals: this.bronzeMedals,
            silverMedals: this.silverMedals,
            goldMedals: this.goldMedals,
            platinumMedals: this.platinumMedals,
            dailyFitPoints: Math.floor(this.dailyFitPoints),
            dailyRewardTier: this.dailyRewardTier,
            currentMidnightTime: this.currentMidnightTime,
            fitabux: this._fitabux,
            fitabuxInventory: this._fitabuxInventory,

            /* add height when implemented */
            //   height: this.height,
        };

        let saveSucceeded = false;
        if (window.heyVR && !this.isGuest) {
            try {
                saveSucceeded = await window.heyVR.saveGame.write(saveData, true, this._saveSlotVersion, "Player Save " + this._saveSlotVersion);
            } catch (error) {
                console.error("Error saving data:", error);
            }
        } else {
            try {
                const saveDataJSON = JSON.stringify(saveData);
                GLOBAL_PREFS.setPref(SAVE_SLOT_KEY + this._saveSlotVersion, saveDataJSON);

                saveSucceeded = true;
            } catch (error) {
                console.error("Error saving data:", error);
            }
        }

        if (saveSucceeded) {
            if (this._fallbackCookieDataUsed) {
                this._fallbackCookieDataUsed = false;
                setCookie("hoverfit_total_fp", 0, 0);
            }

            if (this._fallbackGuestDataUsed) {
                this._fallbackGuestDataUsed = false;
                GLOBAL_PREFS.setPref(SAVE_SLOT_KEY + this._saveSlotVersion, SAVE_SLOT_DEFAULT);
            }
        }

        return saveSucceeded;
    }

    async loadPlayerData() {
        // FIXME this smells. iapContentController should be independent from
        //       the lower UI and available in the common global
        const iapContentController = common.kioskLowerUI.iapContentController;
        await iapContentController.waitForReady();

        this._lastLoadSucceded = false;
        this._fallbackGuestDataUsed = false;
        this._fallbackCookieDataUsed = false;

        let saveData: SaveData | null | { totalFitPoints: number } = null;
        let fallbackToGuestData = false;

        if (window.heyVR && !this.isGuest) {
            try {
                // TODO migrate to a single save slot in a future MR, and
                //      implement a backwards compatible loader with the
                //      save-game version INSIDE THE SAVE DATA. check the save
                //      version to decide which loader to use, and if there's no
                //      version, then use a legacy loader
                const loadedData = await window.heyVR.saveGame.load(this._saveSlotVersion);

                if (loadedData && loadedData.save_data) {
                    saveData = loadedData.save_data as unknown as SaveData;
                } else {
                    fallbackToGuestData = true;
                }

                this._lastLoadSucceded = true;
            } catch (error) {
                if (error != null && (error as any).status != null && (error as any).status.debug == "err_invalid_save") {
                    fallbackToGuestData = true;
                    this._lastLoadSucceded = true;
                } else {
                    console.error("Error loading data:", error);
                }
            }
        }

        let fallbackCookieUsed = false;
        if (!window.heyVR || this.isGuest || fallbackToGuestData) {
            try {
                const saveDataJSON = GLOBAL_PREFS.getPref(SAVE_SLOT_KEY + this._saveSlotVersion, SAVE_SLOT_DEFAULT);
                saveData = JSON.parse(saveDataJSON);

                if (saveData == null) {
                    fallbackToGuestData = false;

                    if (GLOBAL_PREFS.getPref(PERMISSIONS_KEY, PERMISSIONS_DEFAULT)) {
                        const fallbackTotalFPStr = Number(getCookie("hoverfit_total_fp")); // TODO remove total fitpoint fallback in 1st march 2025
                        if (!isNaN(fallbackTotalFPStr)) {
                            const fallbackTotalFP = fallbackTotalFPStr === undefined ? 0 : fallbackTotalFPStr;
                            saveData = { totalFitPoints: fallbackTotalFP };

                            fallbackCookieUsed = true;
                        }
                    }
                }

                this._lastLoadSucceded = true;
            } catch (error) {
                console.error("Error loading data:", error);
            }
        }

        if (this._lastLoadSucceded) {
            if (saveData != null) {
                for (const key in saveData) {
                    if (key in this) {
                        const assetCat = SAVEDATA_ASSET_KEYS.get(key);
                        const value = (saveData as any)[key];

                        if (assetCat !== undefined) {
                            // this is an asset. make sure it's valid
                            const actualCat = iapContentController.getAssetClass(value);
                            if (actualCat !== assetCat) {
                                // invalid asset, reset to default
                                switch (assetCat) {
                                    case ItemCategory.Skin:
                                        this.resetSkinColor();
                                        break;
                                    case ItemCategory.Hoverboard:
                                        this.resetHoverboard();
                                        break;
                                    case ItemCategory.Suit:
                                        this.resetSuit();
                                        break;
                                    case ItemCategory.HairColor:
                                        this.resetHairColor();
                                        break;
                                    case ItemCategory.Headwear:
                                        this.resetHeadwear();
                                        break;
                                }
                                continue;
                            }
                        }

                        (this as any)[key] = value;
                    }
                }
            }

            const dailyFitPointsReset = this.checkDailyFitPointsReset();

            if (saveData == null || fallbackToGuestData || fallbackCookieUsed || dailyFitPointsReset) {
                this._fallbackGuestDataUsed = fallbackToGuestData;
                this._fallbackCookieDataUsed = fallbackCookieUsed;

                try {
                    // Since these cases change some data on load, save the new data
                    await this.savePlayerData();
                } catch (error) {
                    // Do nothing
                }
            }
        }

        return this._lastLoadSucceded;
    }

    addPendingLeaderboardScore(leaderboardID: string, score: number) {
        const currentScore = this._pendingLeaderboardScores!.pendingScores.pp_remove((elementToCheck) => { return elementToCheck.leaderboardID == leaderboardID; });
        const newScore = new PendingLeaderboardScore();
        newScore.leaderboardID = leaderboardID;
        if (currentScore == null) {
            newScore.score = score;
        } else {
            newScore.score = Math.min(currentScore.score, score);
        }

        this._pendingLeaderboardScores!.pendingScores.push(newScore);

        GLOBAL_PREFS.setPref(PENDING_LEADERBOARD_SCORES_KEY, JSON.stringify(this._pendingLeaderboardScores));
    }

    updateDailyRewardTier(newDailyRewardTier: RewardTier) {
        this._updateRewardTierAmount(this.dailyRewardTier, -1);
        this._updateRewardTierAmount(newDailyRewardTier, 1);
        this.dailyRewardTier = newDailyRewardTier;
    }

    private _updateRewardTierAmount(rewardTier: RewardTier, amount: number) {
        switch (rewardTier) {
            case RewardTier.Bronze:
                this.bronzeMedals = Math.max(0, this.bronzeMedals + amount);
                break;
            case RewardTier.Silver:
                this.silverMedals = Math.max(0, this.silverMedals + amount);
                break;
            case RewardTier.Gold:
                this.goldMedals = Math.max(0, this.goldMedals + amount);
                break;
            case RewardTier.Platinum:
                this.platinumMedals = Math.max(0, this.platinumMedals + amount);
                break;
        }
    }

    resetSkinColor() {
        this.skinColor = this._randomize ? getRandomBuiltInItemID(ItemCategory.Skin) : getBuiltInItemIDByIndex(ItemCategory.Skin, 0);
    }

    resetHoverboard() {
        this.hoverboardVariant = this._randomize ? getRandomBuiltInItemID(ItemCategory.Hoverboard) : getBuiltInItemIDByIndex(ItemCategory.Hoverboard, 0);
    }

    resetSuit() {
        this.suitVariant = this._randomize ? getRandomBuiltInItemID(ItemCategory.Suit) : getBuiltInItemIDByIndex(ItemCategory.Suit, 0);
    }

    resetHeadwear() {
        this.headwearVariant = this._randomize ? getRandomBuiltInItemID(ItemCategory.Headwear) : getBuiltInItemIDByIndex(ItemCategory.Headwear, 0);
    }

    resetHairColor() {
        this.hairColor = this._randomize ? getRandomBuiltInItemID(ItemCategory.HairColor) : getBuiltInItemIDByIndex(ItemCategory.HairColor, 0);
    }

    private _resetPlayerData() {
        this._avatarType = this._randomize ? Math.trunc(Math.random() * 2) : 0;
        this.resetSkinColor();
        this.resetHoverboard();
        this.resetSuit();
        this.resetHeadwear();
        this.resetHairColor();
        this.totalFitPoints = 0;
        this.dailyFitPoints = 0;
        this.dailyRewardTier = RewardTier.None;
        this.bronzeMedals = 0;
        this.silverMedals = 0;
        this.goldMedals = 0;
        this.platinumMedals = 0;
        this.currentMidnightTime = new Date().setHours(0, 0, 0, 0);
        this.fitabux = 0;
        this.heyVRCoins = 0;
        this.fitabuxInventory = [];
    }

    private _loadPlayerDataInternal() {
        this._displayLoadFailedMessage = false;

        this.loadPlayerData().then((loadSucceeded) => {
            if (loadSucceeded) {
                common.menu?.updateAvatarConfig();
            } else {
                this._resetPlayerData();
                this._displayLoadFailedMessage = true;
            }
        }).catch(() => {
            this._lastLoadSucceded = false;
            this._fallbackGuestDataUsed = false;
            this._fallbackCookieDataUsed = false;

            this._resetPlayerData();

            this._displayLoadFailedMessage = true;
        }).finally(() => {
            this._firstLoadDataDone = true;
        });
    }
}

// XXX persistent, don't put in the common object
export const currentPlayerData = new PlayerData(true);