import { Component, MeshComponent, PhysXComponent, Property } from "@wonderlandengine/api";
import { AudioMainChannelName } from "src/hoverfit/audio/audio-manager/audio-manager.js";
import { AnalyticsUtils, BrowserUtils, EasyTransformComponent, EasyTuneBool, EasyTuneUtils, EasyTuneVariableExtraParams, GamepadButtonID, Globals, PlayerLocomotionComponent, SaveUtils, Timer, vec3_create } from "wle-pp";
import common from "../../common.js";
import { InvincibilityStatusEffect, InvincibilityStatusEffectParams } from "../hoverboard/status-effects/implementations/invincibility-status-effect.js";

let _debugEnabledOnInit = false;

export let HoverboardDebugs = {
    debugStartedEnabled: false,
    debugFly: false,
    autoRace: false,
    turboMode: false,
    disableStartBoost: false,
    boardNoCollisionCheck: false,
    unlimitedHoverboardTurn: false,
    endRaceOnFirstGoal: false,
    endLapOnFirstGoal: false,
    showSpline: false,
    showSplineForward: false,
    tuneSpline: false,
    showTagCatchVolume: false,
    skipIntro: false,
    saveSpawnPositionShortcutEnabled: true,
    disableAnalyticsOnLocalhost: true,
    boardMovementDisabled: false,
    skipCountdown: false,
    showAnalyticsLog: false,
    disableFeedbackModal: false,
    toggleVOIPTypeShortcutEnabled: false,
    logAnalyticsEvents: false,
    noAudio: false,
    unlockAllKioskUI: false,
    showPlayerEasyTransform: false,
    addHoverfitGlobalsToWindow: false,
    tagRandomCatchEvader: false,
};

export let HoverboardRuntimeDebugs = {
    moveToTrackDebug: false
};

export let EasyTuneExtraParamsSet = {
    importExportEnabled: new EasyTuneVariableExtraParams(),
    importExportDisabled: new EasyTuneVariableExtraParams()
};

export class HoverboardDebugsComponent extends Component {
    static TypeName = "hoverboard-debugs";
    static Properties = {
        addCommonToWindow: Property.bool(true),
        debugFly: Property.bool(false),
        autoRace: Property.bool(false),
        turboMode: Property.bool(false),
        disableStartBoost: Property.bool(false),
        boardNoCollisionCheck: Property.bool(false),
        unlimitedHoverboardTurn: Property.bool(false),
        endRaceOnFirstGoal: Property.bool(false),
        endLapOnFirstGoal: Property.bool(false),
        showSpline: Property.bool(false),
        showSplineForward: Property.bool(false),
        tuneSpline: Property.bool(false),
        showTagCatchVolume: Property.bool(false),
        skipIntro: Property.bool(true),
        disableAnalyticsOnLocalhost: Property.bool(true),
        boardMovementDisabled: Property.bool(false),
        skipCountdown: Property.bool(false),
        saveSpawnPositionShortcutEnabled: Property.bool(true),
        showAnalyticsLog: Property.bool(false),
        disableFeedbackModal: Property.bool(true),
        toggleVOIPTypeShortcutEnabled: Property.bool(false),
        logAnalyticsEvents: Property.bool(true),
        noAudio: Property.bool(false),
        unlockAllKioskUI: Property.bool(true),
        showPlayerEasyTransform: Property.bool(false),
        addHoverfitGlobalsToWindow: Property.bool(true),
        tagRandomCatchEvader: Property.bool(false),
    };

    init() {
        HoverboardDebugs.addCommonToWindow = this.addCommonToWindow;
        HoverboardDebugs.debugFly = this.debugFly;
        HoverboardDebugs.autoRace = this.autoRace;
        HoverboardDebugs.turboMode = this.turboMode;
        HoverboardDebugs.disableStartBoost = this.disableStartBoost;
        HoverboardDebugs.boardNoCollisionCheck = this.boardNoCollisionCheck;
        HoverboardDebugs.unlimitedHoverboardTurn = this.unlimitedHoverboardTurn;
        HoverboardDebugs.endRaceOnFirstGoal = this.endRaceOnFirstGoal;
        HoverboardDebugs.endLapOnFirstGoal = this.endLapOnFirstGoal;
        HoverboardDebugs.showSpline = this.showSpline;
        HoverboardDebugs.showSplineForward = this.showSplineForward;
        HoverboardDebugs.tuneSpline = this.tuneSpline;
        HoverboardDebugs.showTagCatchVolume = this.showTagCatchVolume;
        HoverboardDebugs.skipIntro = this.skipIntro;
        HoverboardDebugs.saveSpawnPositionShortcutEnabled = this.saveSpawnPositionShortcutEnabled;
        HoverboardDebugs.disableAnalyticsOnLocalhost = this.disableAnalyticsOnLocalhost;
        HoverboardDebugs.boardMovementDisabled = this.boardMovementDisabled;
        HoverboardDebugs.skipCountdown = this.skipCountdown;
        HoverboardDebugs.showAnalyticsLog = this.showAnalyticsLog;
        HoverboardDebugs.disableFeedbackModal = this.disableFeedbackModal;
        HoverboardDebugs.toggleVOIPTypeShortcutEnabled = this.toggleVOIPTypeShortcutEnabled;
        HoverboardDebugs.logAnalyticsEvents = this.logAnalyticsEvents;
        HoverboardDebugs.noAudio = this.noAudio;
        HoverboardDebugs.unlockAllKioskUI = this.unlockAllKioskUI;
        HoverboardDebugs.showPlayerEasyTransform = this.showPlayerEasyTransform;
        HoverboardDebugs.addHoverfitGlobalsToWindow = this.addHoverfitGlobalsToWindow;

        this._debugSetupDone = false;
        this._firstUpdate = true;
        this._firstClosestEasyTransformSet = true;
        this._rampsToTune = [];

        this.closestEasyTransformVariable = null;
        this.closestEasyTransformUpdateTimer = new Timer(1);
        this.easyTransformsObjects = [];

        EasyTuneUtils.setAutoImportEnabledDefaultValue(false);
        EasyTuneUtils.setManualImportEnabledDefaultValue(false);
        EasyTuneUtils.setExportEnabledDefaultValue(false);

        EasyTuneExtraParamsSet.importExportEnabled.myAutoImportEnabled = true;
        EasyTuneExtraParamsSet.importExportEnabled.myManualImportEnabled = true;
        EasyTuneExtraParamsSet.importExportEnabled.myExportEnabled = true;

        EasyTuneExtraParamsSet.importExportDisabled.myAutoImportEnabled = false;
        EasyTuneExtraParamsSet.importExportDisabled.myManualImportEnabled = false;
        EasyTuneExtraParamsSet.importExportDisabled.myExportEnabled = false;
    }

    start() {
        // Add here so it's the first in the list
        const debugEnabledVariable = new EasyTuneBool("Debug Enabled", false, null, true);
        debugEnabledVariable.setEasyTuneVariableExtraParams(EasyTuneExtraParamsSet.importExportDisabled);

        Globals.getEasyTuneVariables().add(debugEnabledVariable);
    }

    update(dt) {
        if (this._firstUpdate) {
            this._firstUpdate = false;

            if (Globals.isDebugEnabled() || _debugEnabledOnInit) {
                _debugEnabledOnInit = true;

                HoverboardDebugs.debugStartedEnabled = BrowserUtils.isLocalhost();

                let debugEnabled = SaveUtils.loadBool("debug_enabled", false);
                Globals.setDebugEnabled(debugEnabled);

                const debugEnabledVariable = new EasyTuneBool("Debug Enabled", Globals.isDebugEnabled(),
                    function (newValue) {
                        Globals.setDebugEnabled(newValue);
                        SaveUtils.save("debug_enabled", newValue);

                        if (newValue) {
                            this._setupDebug();
                        }
                    }.bind(this), true);
                debugEnabledVariable.setEasyTuneVariableExtraParams(EasyTuneExtraParamsSet.importExportDisabled);
                Globals.getEasyTuneVariables().add(debugEnabledVariable, true);

                if (debugEnabled) {
                    this._setupDebug();

                    if (HoverboardDebugs.noAudio) {
                        common.audioManager.setMainChannelVolume(0, AudioMainChannelName.MUSIC);
                        common.audioManager.setMainChannelVolume(0, AudioMainChannelName.SFX);
                        common.audioManager.setMainChannelVolume(0, AudioMainChannelName.VOICE_OVERS);
                        common.audioManager.setMainChannelVolume(0, AudioMainChannelName.VOIP);
                    }
                }
            }

            if (Globals.isDebugEnabled() && HoverboardDebugs.disableAnalyticsOnLocalhost && BrowserUtils.isLocalhost()) {
                AnalyticsUtils.setAnalyticsEnabled(false);
            }

            if (Globals.isDebugEnabled() && HoverboardDebugs.logAnalyticsEvents) {
                AnalyticsUtils.setEventsLogEnabled(true);
            }

            if (Globals.isToolEnabled()) {
                this._addEasyTransformElements();
            }
        }

        if (Globals.isToolEnabled()) {
            this._updateClosestEasyTransform(dt);
        }

        let applyStatusEffectDebugShortcutEnabled = false;
        if (applyStatusEffectDebugShortcutEnabled) {
            if (Globals.getLeftGamepad().getButtonInfo(GamepadButtonID.TOP_BUTTON).isPressEnd(2)) {
                const statusEffectsManager = common.tracksManager.getStatusEffectsManager();

                const statusEffect = new InvincibilityStatusEffect(common.hoverboard, new InvincibilityStatusEffectParams());
                statusEffectsManager.addStatusEffect(statusEffect);
            }
        }
    }

    _setupDebug() {
        if (this._debugSetupDone) return;

        if (Globals.isDebugEnabled() && HoverboardDebugs.addHoverfitGlobalsToWindow) {
            window.hoverfit = {};
            window.hoverfit.common = common;
        }

        if (Globals.isDebugEnabled() && HoverboardDebugs.showAnalyticsLog) {
            AnalyticsUtils.setEventsLogEnabled(true);
        }

        if (Globals.isDebugEnabled() && HoverboardDebugs.debugFly) {
            this.playerLocomotion = Globals.getPlayerObjects(this.engine).myPlayer.pp_getComponent(PlayerLocomotionComponent);
            this.playerLocomotion._myMaxSpeed = 30;
            this.playerLocomotion._myFlyEnabled = true;
            this.playerLocomotion._myFlyWithButtonsEnabled = false;
            this.playerLocomotion._myFlyWithViewAngleEnabled = false;
            this.playerLocomotion._myCollisionCheckDisabled = true;
            this.playerLocomotion._myMoveThroughCollisionShortcutEnabled = true;

            if (this.playerLocomotion.getPlayerLocomotion() != null) {
                this.playerLocomotion.getPlayerLocomotion().getParams().myCollisionCheckDisabled = true;

                this.playerLocomotion.getPlayerLocomotion().getPlayerLocomotionSmooth().getParams().myMaxSpeed = 20;
                this.playerLocomotion.getPlayerLocomotion().getPlayerLocomotionSmooth().getParams().myFlyEnabled = true;
                this.playerLocomotion.getPlayerLocomotion().getPlayerLocomotionSmooth().getParams().myFlyWithButtonsEnabled = true;
                this.playerLocomotion.getPlayerLocomotion().getPlayerLocomotionSmooth().getParams().myFlyWithViewAngleEnabled = false;
                this.playerLocomotion.getPlayerLocomotion().getPlayerLocomotionSmooth().getParams().myMoveThroughCollisionShortcutEnabled = true;
                this.playerLocomotion.getPlayerLocomotion().getPlayerLocomotionSmooth().getParams().myTripleSpeedShortcutEnabled = true;
            }
        }

        if (Globals.isDebugEnabled() && HoverboardDebugs.addCommonToWindow) {
            window.common = common;
        }

        this._debugSetupDone = true;
    }

    _updateClosestEasyTransform(dt) {
        if (this.closestEasyTransformVariable != null && this.closestEasyTransformVariable.isWidgetCurrentVariable()) {
            this.closestEasyTransformUpdateTimer.update(dt);
            if (this.closestEasyTransformUpdateTimer.isDone()) {
                this.closestEasyTransformUpdateTimer.start();
                let minDistanceSquared = Number.MAX_VALUE;

                let closestObject = null;
                let playerPosition = Globals.getPlayerObject().pp_getPosition();
                let currentPosition = vec3_create();
                for (const easyTransformsObject of this.easyTransformsObjects) {
                    if (easyTransformsObject.pp_getComponent(MeshComponent).active) {
                        easyTransformsObject.pp_getPosition(currentPosition);
                        let currentDistanceSquared = currentPosition.vec3_distanceSquared(playerPosition);
                        if (currentDistanceSquared < minDistanceSquared) {
                            minDistanceSquared = currentDistanceSquared;
                            closestObject = easyTransformsObject;
                        }
                    }
                }

                if (closestObject != null) {
                    if (this._firstClosestEasyTransformSet) {
                        this._firstClosestEasyTransformSet = false;

                        for (const rampToTune of this._rampsToTune) {
                            const physXComponents = rampToTune.pp_getComponents(PhysXComponent);
                            for (const physXComponent of physXComponents) {
                                physXComponent.static = false;
                                physXComponent.kinematic = true;
                                physXComponent.active = false;
                                physXComponent.active = true;
                            }
                        }
                    }

                    Globals.setEasyTuneTarget(closestObject);
                    Globals.getEasyTuneVariables().changeEasyTuneVariableName(this.closestEasyTransformVariable.getName(), closestObject.pp_getName() + " - Closest");
                    EasyTuneUtils.refreshWidget();
                }
            }
        } else {
            this.closestEasyTransformUpdateTimer.end();
        }
    }

    _addEasyTransformElements() {
        const debugTransformEnabledVariable = new EasyTuneBool("Debug Transform Enabled", false, null, false);
        debugTransformEnabledVariable.setEasyTuneVariableExtraParams(EasyTuneExtraParamsSet.importExportDisabled);
        Globals.getEasyTuneVariables().add(debugTransformEnabledVariable);

        const easyTuneVariables = [];
        easyTuneVariables.push(this._addEasyTransformElementByParent("Ramps", this._rampsToTune));
        easyTuneVariables.push(this._addEasyTransformElementByParent("Pickups Rocket"));
        easyTuneVariables.push(this._addEasyTransformElementByParent("Pickups Super Rocket"));
        easyTuneVariables.push(this._addEasyTransformElementByParent("Pickups Super Spring"));
        easyTuneVariables.push(this._addEasyTransformElementByParent("Pickups Glue"));
        easyTuneVariables.push(this._addEasyTransformElementByParent("Pickups Timer"));

        debugTransformEnabledVariable.registerValueChangedEventListener(this, function (newValue) {
            for (const easyTuneVariable of easyTuneVariables) {
                easyTuneVariable.setShowOnWidget(newValue);
            }
        });

        const closestEasyTransformComponent = this.object.pp_addComponent(EasyTransformComponent, {
            _myVariableName: "Closest Easy Transform",
            _myUseTuneTarget: true,
            _myPositionStepPerSecond: 10
        });

        this.closestEasyTransformVariable = closestEasyTransformComponent.getEasyObjectTuner().getEasyTuneVariable();
        this.closestEasyTransformVariable.setManualImportEnabled(false);
        this.closestEasyTransformVariable.setAutoImportEnabled(false);
        this.closestEasyTransformVariable.setExportEnabled(false);
        this.closestEasyTransformVariable.registerValueChangedEventListener(this, function () {
            this.closestEasyTransformUpdateTimer.start();
        }.bind(this));

        // This is mostly to check the position quickly when tuning
        const playerEasyTransformComponent = Globals.getPlayerObject().pp_addComponent(EasyTransformComponent, {
            _myVariableName: "Player Easy Transform",
            _myPositionStepPerSecond: 10
        });

        const playerEasyTransformVariable = playerEasyTransformComponent.getEasyObjectTuner().getEasyTuneVariable();
        playerEasyTransformVariable.setManualImportEnabled(false);
        playerEasyTransformVariable.setAutoImportEnabled(false);
        playerEasyTransformVariable.setExportEnabled(false);
        playerEasyTransformVariable.setShowOnWidget(HoverboardDebugs.showPlayerEasyTransform);
    }

    _addEasyTransformElementByParent(parentName, outElementsToTune) {
        const debugElementTransformEnabledVariable = new EasyTuneBool("Debug " + parentName + " Transform", false, null, false);
        debugElementTransformEnabledVariable.setEasyTuneVariableExtraParams(EasyTuneExtraParamsSet.importExportDisabled);
        Globals.getEasyTuneVariables().add(debugElementTransformEnabledVariable);

        const elementsParents = Globals.getSceneObject().pp_getObjectsByName(parentName);

        const elementsToTune = [];
        for (const elementParent of elementsParents) {
            elementsToTune.push(...elementParent.pp_getChildren());
        }

        const easyTuneVariables = [];
        for (const elementToTune of elementsToTune) {
            const easyTransformComponent = elementToTune.pp_addComponent(EasyTransformComponent, {
                _myVariableName: elementToTune.pp_getName(),
                _myPositionStepPerSecond: 10
            });

            this.easyTransformsObjects.push(elementToTune);

            const easyTuneVariable = easyTransformComponent.getEasyObjectTuner().getEasyTuneVariable();
            easyTuneVariable.setEasyTuneVariableExtraParams(EasyTuneExtraParamsSet.importExportEnabled);
            easyTuneVariable.setShowOnWidget(false);
            easyTuneVariables.push(easyTuneVariable);
        }

        debugElementTransformEnabledVariable.registerValueChangedEventListener(this, function (newValue) {
            for (const easyTuneVariable of easyTuneVariables) {
                easyTuneVariable.setShowOnWidget(newValue);
            }
        });

        if (outElementsToTune != null) {
            outElementsToTune.push(...elementsToTune);
        }

        return debugElementTransformEnabledVariable;
    }
}