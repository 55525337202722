import { AudioID } from "src/hoverfit/audio/audio-manager/audio-id.js";
import { StatusEffectTarget } from "../status-effect-target.js";
import { StatusEffectType } from "../status-effect.js";
import { ExpiringStatusEffect, ExpiringStatusEffectParams } from "./expiring-status-effect.js";

export class GlueStatusEffectParams extends ExpiringStatusEffectParams {
    maxSpeedMultiplier: number = 0.25;
    speedBrakingMultiplier: number = 2;

    sfxOnStart: AudioID | null = AudioID.STATUS_EFFECT_GLUE;
}

export class GlueStatusEffect extends ExpiringStatusEffect {

    declare protected _params: GlueStatusEffectParams;

    constructor(target: StatusEffectTarget, params: GlueStatusEffectParams) {
        super(StatusEffectType.Glue, target, params);
    }

    getParams(): GlueStatusEffectParams {
        return this._params;
    }
}

export class HoverboardGlueStatusEffectParams extends GlueStatusEffectParams {
    speedGainMultiplier: number = 0.5;
}

export class HoverboardGlueStatusEffect extends GlueStatusEffect {

    declare protected _params: HoverboardGlueStatusEffectParams;

    constructor(target: StatusEffectTarget, params: HoverboardGlueStatusEffectParams) {
        super(target, params);
    }

    getParams(): HoverboardGlueStatusEffectParams {
        return this._params;
    }
}