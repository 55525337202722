import { Object3D } from "@wonderlandengine/api";
import { MathUtils } from "wle-pp";
import common from "../../common.js";
import { currentGameConfig } from "../../data/game-configuration.js";
import { GAME_STATES } from "../../game/game-states.js";
import { EncryptedAudioComponent } from "../encrypted-audio/components/encrypted-audio-component.js";
import { AudioChannelName } from "./audio-channel.js";
import { AudioID } from "./audio-id.js";
import { baseMusicJSONURL, locationToName, modeToName } from "./components/audio-manager-component.js";

export interface TrackMusicConfig {
    url: string;
}

export class TrackMusicManager {

    private _sinkObject: Object3D;
    private _audioManagerObject: Object3D;

    private _trackMusicConfigs: TrackMusicConfig[] = [];
    private _trackMusicSourceAudios: Map<string, EncryptedAudioComponent> = new Map();

    private _trackMusicSourceAudio: EncryptedAudioComponent | null = null;
    private _nextTrackMusicSourceAudio: EncryptedAudioComponent | null = null;
    private _lastPlayedTrackMusicIndexes: (number | null)[] = [];

    private _trackMusicAutoPlayEnabled: boolean = false;
    private _nextTrackMusicCanInterruptCurrent: boolean = false;

    private _randomizeTrackMusic: boolean = true;

    private _firstTrackMusicSet: boolean = true;

    constructor(sinkObj: Object3D, audioManagerObject: Object3D) {
        this._sinkObject = sinkObj;
        this._audioManagerObject = audioManagerObject;

        const avoidRepeatLastMusicAmount = 3;
        for (let i = 0; i < avoidRepeatLastMusicAmount; i++) {
            this._lastPlayedTrackMusicIndexes.push(null);
        }

        this._setupTrackMusicAudios();
    }

    update(dt: number) {
        if (common.CURRENT_STATE === GAME_STATES.GAME || common.CURRENT_STATE === GAME_STATES.PAUSE || this._firstTrackMusicSet) {
            if (this._nextTrackMusicSourceAudio != null && this._nextTrackMusicSourceAudio.ready &&
                (this._trackMusicSourceAudio == null || !this._trackMusicSourceAudio.playing || this._nextTrackMusicCanInterruptCurrent)) {
                this._setTrackMusicSourceAudio(this._nextTrackMusicSourceAudio);
                this._nextTrackMusicSourceAudio = null;
                this._nextTrackMusicCanInterruptCurrent = false;
            } else if (this._trackMusicSourceAudio != null) {
                if (this._trackMusicSourceAudio.playing) {
                    this._trackMusicAutoPlayEnabled = true;

                    const amountPlayed = this._trackMusicSourceAudio.currentTime / this._trackMusicSourceAudio.duration;
                    if (amountPlayed > 0.1 && this._nextTrackMusicSourceAudio == null) {
                        this._prepareNextTrackMusic();
                    }
                } else {
                    if (common.countdown.finished) {
                        this._trackMusicSourceAudio.play();

                        if (this._trackMusicAutoPlayEnabled) {
                            // The new track wan't ready before the current finished, so we play it again, but can be interrupted
                            this._nextTrackMusicCanInterruptCurrent = true;
                        }
                    }
                }
            }
        } else {
            this._trackMusicAutoPlayEnabled = false;

            if (this._trackMusicSourceAudio != null && this._trackMusicSourceAudio.playing) {
                this._trackMusicSourceAudio.stop();
                this._trackMusicAutoPlayEnabled = false;

                this._trackMusicSourceAudio = null;
                this._prepareNextTrackMusic();
            }
        }
    }

    private _setupTrackMusicAudios() {
        const configPath = `${baseMusicJSONURL}/race/${modeToName[currentGameConfig.mode]}-${locationToName[currentGameConfig.location]}.json`;

        fetch(configPath).then(result => result.json()).then((config) => {
            this._trackMusicConfigs = config;

            // This is done to avoid keeping track of too many tracks and therefore they are played in order after the array is filled
            let maxAmountOfLastPlayed = Math.floor(this._trackMusicConfigs.length / 2);
            maxAmountOfLastPlayed = maxAmountOfLastPlayed > 2 ? maxAmountOfLastPlayed : 0;
            while (this._lastPlayedTrackMusicIndexes.length > maxAmountOfLastPlayed) {
                this._lastPlayedTrackMusicIndexes.shift();
            }

            this._prepareNextTrackMusic();
        });
    }

    private _prepareNextTrackMusic() {
        if (this._trackMusicConfigs.length == 0) return;

        if (this._randomizeTrackMusic) {
            let maxAttempt = 100;
            let nextTrackMusicIndex: number = 0;
            while (maxAttempt > 0) {
                maxAttempt--;

                nextTrackMusicIndex = MathUtils.randomInt(0, this._trackMusicConfigs.length - 1);

                if (!this._lastPlayedTrackMusicIndexes.pp_hasEqual(nextTrackMusicIndex)) {
                    break;
                }
            }

            this._lastPlayedTrackMusicIndexes.shift();
            this._lastPlayedTrackMusicIndexes.push(nextTrackMusicIndex);

            const nextTrackMusicConfig = this._trackMusicConfigs[nextTrackMusicIndex];

            if (this._trackMusicSourceAudios.has(nextTrackMusicConfig.url)) {
                this._nextTrackMusicSourceAudio = this._trackMusicSourceAudios.get(nextTrackMusicConfig.url)!;
            } else {
                this._nextTrackMusicSourceAudio = this._audioManagerObject.addComponent(EncryptedAudioComponent, {
                    encryptedAudioURL: nextTrackMusicConfig.url,
                    loopStartValue: false,
                    volumeStartValue: 0.1,
                    sinkObjectStartValue: this._sinkObject,
                })!;

                this._nextTrackMusicSourceAudio.stop();

                this._trackMusicSourceAudios.set(nextTrackMusicConfig.url, this._nextTrackMusicSourceAudio);
            }
        } else {
            console.error("race-music-manager: Only randomized track selection for now");
        }
    }

    private _setTrackMusicSourceAudio(newTrackMusicSourceAudio: EncryptedAudioComponent) {
        let raceMusicWasPlaying = false;
        if (this._trackMusicSourceAudio != null) {
            raceMusicWasPlaying = this._trackMusicSourceAudio.playing;
            this._trackMusicSourceAudio.stop();
        }

        common.audioManager.removeAudio(AudioID.TRACK_MUSIC);
        common.audioManager.addSourceAudioToChannel(AudioID.TRACK_MUSIC, newTrackMusicSourceAudio, AudioChannelName.MUSIC_QUIET);

        if (raceMusicWasPlaying) {
            newTrackMusicSourceAudio.play();
        }

        this._trackMusicSourceAudio = newTrackMusicSourceAudio;

        this._firstTrackMusicSet = false;
    }
}