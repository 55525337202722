import { Alignment, Justification, TextEffect, type Object3D, type TextComponent, type WonderlandEngine } from "@wonderlandengine/api";
import { getBaseUrl } from "@wonderlandengine/api/utils/fetch.js";
import { Globals } from "wle-pp";
import { AudioID } from "../../audio/audio-manager/audio-id.js";
import common, { destroyCommon, initCommon } from "../../common.js";
import { HoverboardGameConfig, currentGameConfig } from "../../data/game-configuration.js";
import { currentPlayerData } from "../../data/player-data.js";
import { RoomData, currentRoomData } from "../../network/components/hoverboard-networking-component.js";
import { fetchCancellableProgress } from "../../utils/fetch-utils.js";
import { GameGlobals } from "../game-globals.js";


export let isAnotherSceneLoading = false;
let cancelSceneDownload: (() => void) | null = null;

function traceObjectAndAscendants(obj: Object3D, out: Set<Object3D>) {
    out.add(obj);
    let focus: Object3D | null = obj;
    while ((focus = focus.parent)) {
        out.add(focus);
    }
}

function deactivateExceptView(obj: Object3D, except: Set<Object3D>) {
    for (const child of obj.children) {
        deactivateExceptView(child, except);
    }

    if (except.has(obj)) {
        for (const comp of obj.getComponents().reverse()) {
            if (comp.type !== "view") {
                comp.active = false;
            }
        }
    } else {
        obj.active = false;
    }
}

function nukeObjectExceptView(obj: Object3D, except: Set<Object3D>) {
    if (except.has(obj)) {
        for (const child of obj.children) {
            nukeObjectExceptView(child, except);
        }

        for (const comp of obj.getComponents().reverse()) {
            if (comp.type !== "view") {
                try {
                    comp.destroy();
                } catch (err) {
                    console.error(err);
                }
            }
        }
    } else {
        try {
            obj.destroy();
        } catch (err) {
            console.error(err);
        }
    }
}

export function cancelSceneLoad() {
    if (cancelSceneDownload) {
        cancelSceneDownload();
        return true;
    } else {
        return false;
    }
}

/**
 * Switch to another scene. Only attempts to load the new scene if the download
 * is successful. If downloading fails, an error is thrown. If scene loading
 * fails, the promise never resolves and an error message is shown to the user.
 * The screen fades to white when scene loading begins
 */
export function loadScene(engine: WonderlandEngine, newGameConfig: HoverboardGameConfig, newRoomData: RoomData | null = null) {
    if (isAnotherSceneLoading) return Promise.resolve();

    // eslint-disable-next-line no-async-promise-executor
    return new Promise<void>(async (resolve, reject) => {
        isAnotherSceneLoading = true;
        const MAIN_CHANNEL = common.MAIN_CHANNEL;
        MAIN_CHANNEL.emit("load-scene-start");
        let recoverable = true;
        let textComp: TextComponent | null = null;
        let textCompPrev: TextComponent | null = null;

        let downloadTimeoutID = null;
        let loadingTimeoutID = null;
        try {
            // fade out
            await common.intro.manualFadeOut();

            // get loader materials/meshes
            const sceneSwitcherVars = common.sceneSwitcherVars;
            const backgroundMesh = sceneSwitcherVars.backgroundMesh;
            const backgroundMaterial = sceneSwitcherVars.backgroundMaterial;
            const loadingTextMat = sceneSwitcherVars.textMaterial;

            const textObjPrevScene = engine.scene.addObject(Globals.getPlayerHeadObject());
            textObjPrevScene.setPositionLocal([0, 0, 2.5]);
            textObjPrevScene.rotateAxisAngleDegObject(GameGlobals.up, 180);

            const newMapAndModeText = "SWITCHING TO\n" + newGameConfig.locationConfig.name + " - " + newGameConfig.fancyMode + "\n\n";
            textCompPrev = textObjPrevScene.addComponent("text", {
                text: newMapAndModeText,
                material: loadingTextMat,
                alignment: Alignment.Center,
                justification: Justification.Line,
                effect: TextEffect.None,
            });

            let displayDownloadMapProgress = false;
            let downloadMapProgress = 0;
            downloadTimeoutID = setTimeout(() => {
                displayDownloadMapProgress = true;
                textCompPrev!.text = newMapAndModeText + "Downloading Map: " + downloadMapProgress.toFixed() + "%";
            }, 1000);

            let promise: Promise<ArrayBuffer>;
            [promise, cancelSceneDownload] = fetchCancellableProgress(newGameConfig.sceneBinPath, (cur, total) => {
                if (cur !== 0 && displayDownloadMapProgress) {
                    downloadMapProgress = 100 * cur / total;
                    textCompPrev!.text = newMapAndModeText + "Downloading Map: " + downloadMapProgress.toFixed() + "%";
                }
            });

            const buffer = await promise;
            clearTimeout(downloadTimeoutID);

            cancelSceneDownload = null;

            // trace objects that lead to views
            const except = new Set<Object3D>();
            const scene = engine.scene;
            for (const view of scene.activeViews) {
                traceObjectAndAscendants(view.object, except);
            }

            // clean up current scene, except object with views (and ascendants,
            // but deactivate components in ascendants)
            MAIN_CHANNEL.emit("try-disconnect");
            recoverable = false;

            common.audioManager.getAudio(AudioID.TRACK_MUSIC)?.stop();

            const balconyMusicAudio = common.audioManager.getAudio(AudioID.BALCONY_MUSIC);
            const ambientAudio = common.audioManager.getAudio(AudioID.AMBIENT);
            balconyMusicAudio!.fade(balconyMusicAudio!.getVolume(), 0, 1.0);
            ambientAudio!.fade(ambientAudio!.getVolume(), 0, 1.0);

            // HACK deactivate first, otherwise there will be a tonne of errors
            const sceneChildren = engine.wrapObject(0).children;
            for (const child of sceneChildren) {
                deactivateExceptView(child, except);
            }
            for (const child of sceneChildren) {
                nukeObjectExceptView(child, except);
            }

            destroyCommon();
            currentPlayerData.invalidateSceneData();

            // move views to world origin
            for (const obj of except) {
                obj.resetTransform();
            }

            // make inverted sphere around player with loading text
            const loadingObj = engine.scene.addObject();
            loadingObj.setScalingLocal([10, 10, 10]);
            loadingObj.addComponent("mesh", {
                mesh: backgroundMesh,
                material: backgroundMaterial,
            });

            const textObj = engine.scene.addObject();
            textObj.setPositionLocal([0, 0, -2.5]);
            textComp = textObj.addComponent("text", {
                text: newMapAndModeText + (displayDownloadMapProgress ? "Loading..." : ""),
                material: loadingTextMat,
                alignment: Alignment.Center,
                justification: Justification.Line,
                effect: TextEffect.None,
            });

            loadingTimeoutID = setTimeout(() => {
                textComp!.text = newMapAndModeText + "Loading...";
            }, 1000);

            // re-initialize common object
            // TODO: Reset any scene related issues and/or call any related emitters
            initCommon();

            if (newRoomData != null) {
                currentRoomData.roomNumber = newRoomData.roomNumber;
                currentRoomData.privateRoom = newRoomData.privateRoom;
            } else {
                currentRoomData.roomNumber = null;
                currentRoomData.privateRoom = false;
            }

            currentGameConfig.copyFrom(newGameConfig);

            await new Promise<void>((timeoutResolve) => setTimeout(() => timeoutResolve(), 750));

            // load new scene
            const loadPromise = engine.scene.load({
                buffer,
                baseURL: getBaseUrl(newGameConfig.sceneBinPath),
            });
            // HACK have to set isAnotherSceneLoading to false before load,
            //      otherwise the promise is resolved too late and the new
            //      loaded scene thinks the scene is still loading
            isAnotherSceneLoading = false;
            // XXX event not fired because it's too late to do anything about
            //     scene switching at this point
            await loadPromise;

            clearTimeout(loadingTimeoutID);

            resolve();
        } catch (err) {
            console.error(err);

            if (downloadTimeoutID != null) {
                clearTimeout(downloadTimeoutID);
            }

            if (loadingTimeoutID != null) {
                clearTimeout(loadingTimeoutID);
            }

            if (!recoverable && textComp) {
                textComp.text = "Fatal error occurred\nCheck console for details";
            } else if (recoverable && textCompPrev) {
                textCompPrev.object.destroy();
            }

            reject(err);
        } finally {
            cancelSceneDownload = null;
            isAnotherSceneLoading = false;
            MAIN_CHANNEL.emit("load-scene-end");
        }
    });
}