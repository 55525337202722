import { Column, Container, Icon, Label, LiveLabel, ObservableTransformer, Row, TextAlignMode, type Observable, type WidgetAutoXML, type WidgetProperties } from "lazy-widgets";
import { BackPane } from "../../lazy-widgets/widgets/back-pane.js";

export enum AwardType {
    Medal = "medal",
    Trophy = "trophy",
}

function makeAwardCol(iconURL: string, variable: Observable<number>) {
    return new Column([
        new Container(
            new Icon(iconURL, { width: 20, height: 20 }),
            { containerPadding: { left: 4, right: 4, top: 0, bottom: 4 } },
        ),
        new BackPane(
            new LiveLabel(new ObservableTransformer([variable], () => {
                return `${variable.value}`;
            }), { bodyTextAlign: TextAlignMode.Center }),
            { containerPadding: { left: 0, right: 0, top: 0, bottom: 2 } },
        ),
    ]);
}

export class AwardRow extends Row {
    static override autoXML: WidgetAutoXML = {
        name: "award-row",
        inputConfig: [
            {
                mode: "value",
                name: "award-type",
                validator: "string"
            },
            {
                mode: "value",
                name: "award-label",
                validator: "string"
            },
            {
                mode: "value",
                name: "bronze-var",
                validator: "observable"
            },
            {
                mode: "value",
                name: "silver-var",
                validator: "observable"
            },
            {
                mode: "value",
                name: "gold-var",
                validator: "observable"
            },
            {
                mode: "value",
                name: "platinum-var",
                validator: "observable"
            },
        ]
    };

    constructor(awardType: AwardType, awardLabel: string, bronzeVar: Observable<number>, silverVar: Observable<number>, goldVar: Observable<number>, platinumVar: Observable<number>, properties?: WidgetProperties) {
        super([
            new Column([
                new Label(
                    awardLabel, {
                    bodyTextAlign: TextAlignMode.Center
                }),
                new BackPane<Row>(
                    new Row([
                        makeAwardCol(`assets/textures/ui/icons/kiosk/${awardType}-bronze.svg`, bronzeVar),
                        makeAwardCol(`assets/textures/ui/icons/kiosk/${awardType}-silver.svg`, silverVar),
                        makeAwardCol(`assets/textures/ui/icons/kiosk/${awardType}-gold.svg`, goldVar),
                        makeAwardCol(`assets/textures/ui/icons/kiosk/${awardType}-platinum.svg`, platinumVar),
                    ], {
                        multiContainerSpacing: 6,
                    }),
                    {
                        containerPadding: { left: 8, right: 8, top: 12, bottom: 12 },
                        ...properties,
                    }
                )
            ]
            )
        ]);
    }
}