import { Component, Property, TextComponent } from "@wonderlandengine/api";
import anime from "animejs/lib/anime.es.js";
import { GameMode } from "hoverfit-shared-netcode";
import { currentGameConfig } from "src/hoverfit/data/game-configuration.js";
import { getTime } from "src/hoverfit/utils/time-utils.js";
import { AudioPlayer, AudioSetup, Globals, Timer, vec3_create } from "wle-pp";
import { AudioChannelName } from "../../../audio/audio-manager/audio-channel.js";
import { AudioID } from "../../../audio/audio-manager/audio-id.js";
import common from "../../../common.js";
import { GameGlobals } from "../../../misc/game-globals.js";
import { HoverboardDebugs, HoverboardRuntimeDebugs } from "../../components/hoverboard-debugs-component.js";
import { HoverboardComponent } from "../../hoverboard/components/hoverboard-component.js";

export class CountdownComponent extends Component {
    static TypeName = "countdown";
    static Properties = {
        countdownTextObject: Property.object(),
        backgroundObject: Property.object()
    };

    init() {
        this.startPositionLocal = this.object.pp_getPositionLocal();

        this.delayStartTimer = new Timer(1, false);
    }

    start() {
        this.hoverboard = Globals.getRootObject(this.engine).pp_getComponent(HoverboardComponent);

        let countdownSoundSetup = new AudioSetup("assets/audio/sfx/maps/misc/track/misc/misc/countdown.webm");
        countdownSoundSetup.myVolume = 0.5;
        let countdownSoundPlayer = new AudioPlayer(countdownSoundSetup);
        common.audioManager.addSourceAudioToChannel(AudioID.COUNTDOWN, countdownSoundPlayer, AudioChannelName.SFX);

        this.scale = vec3_create(0, 0, 0);
        this.textScaleVector = vec3_create();

        common.countdown = this;
        this.countdownText = this.countdownTextObject.pp_getComponent(TextComponent);
        this.countdownText.text = "";
        this.targetTextScale = this.countdownTextObject.getScalingLocal(this.textScaleVector)[0];
        this.currentTextScale = 0;
        this.countdownTextObject.setScalingLocal(this.scale); // Set to zero at the beginning

        this.rotationAxis = GameGlobals.left;
        this.targetScale = this.object.getScalingLocal(this.scale)[1];
        this.resetCountdown();
        this.paused = false;

        this.visible = false;
        this.setVisible(false);
    }

    isUsingFixedPosition() {
        return common.tracksManager.getCurrentTrack().isCountdownUsingFixedPosition();
    }

    resetToFixedPosition() {
        if (this.isUsingFixedPosition()) {
            this.object.pp_setPosition(common.tracksManager.getCurrentTrack().getCountdownPosition().quat2_getPosition());
            this.object.pp_setRotationQuat(common.tracksManager.getCurrentTrack().getCountdownPosition().quat2_getRotationQuat());
        }
    }

    setVisible(visible) {
        this.visible = visible;
        this.object.pp_setActiveDescendants(visible);

        if (!visible) {
            this.object.pp_resetTransformLocal();
        }
    }

    isRunning() {
        return this.countdownStart && !this.finished;
    }

    resetCountdown() {
        this.object.active = true;
        this.scale[0] = 1;
        this.scale[1] = 0;
        this.scale[2] = 1;
        this.countdownText.active = false;
        this.countdownText.text = "";
        this.object.scaleLocal(this.scale);
        this.currentScale = 0;
        this.currentTextScale = 0;
        this.finished = false;
        this.countdownStart = false;
        this.paused = false;
        this.scaleUp = true;

        this.delayStartTimer.reset();
        common.audioManager.getAudio(AudioID.COUNTDOWN).stop();
    }

    startCountdown() {
        common.tracksManager.countdownStarted();

        if (!common.balcony.isPlayerOnBalcony) {
            this.hoverboard.startHoverboardRiseAnimation();
        } else {
            const balconyMusicAudio = common.audioManager.getAudio(AudioID.BALCONY_MUSIC);
            balconyMusicAudio.fade(balconyMusicAudio.getVolume(), 0.0, 0.8);
        }

        this.finished = false;
        this.countdownStart = false;
        this.paused = false;
        this.scaleUp = true;

        this.active = true;

        if (Globals.isDebugEnabled() && (HoverboardDebugs.skipCountdown || HoverboardRuntimeDebugs.moveToTrackDebug)) {
            this.delayStartTimer.start(0.1);
        } else {
            this.delayStartTimer.start();
        }

        common.audioManager.getAudio(AudioID.COUNTDOWN).stop();
    }

    pauseCountdown() {
        if (this.countdownStart && !this.finished && !this.paused) {
            common.audioManager.getAudio(AudioID.COUNTDOWN).pause();
            this.paused = true;
        }
    }

    resumeCountdown() {
        if (this.countdownStart && !this.finished && this.paused) {
            common.audioManager.getAudio(AudioID.COUNTDOWN).resume();
            this.paused = false;
        }
    }

    endCountdown(skipAnimation) {
        if (skipAnimation) {
            this.resetCountdown();
            this.object.active = false;
        } else {
            this.anim = null;
            this.animStarted = false;
            this.finished = true;
            this.scaleUp = false;
            this.scaleDown = true;
        }

        this.onCountDownFinished();
    }

    beginAnimation() {
        common.audioManager.getAudio(AudioID.COUNTDOWN).stop();
        common.audioManager.getAudio(AudioID.COUNTDOWN).play();
        this.animStarted = true;
        let amountLoops = 0;

        let secondDuration = 1000;
        if (Globals.isDebugEnabled() && (HoverboardDebugs.skipCountdown || HoverboardRuntimeDebugs.moveToTrackDebug)) {
            secondDuration = 50;
        }

        this.anim = anime({
            targets: this,
            easing: "easeInOutCirc",
            currentTextScale: this.targetTextScale,
            loop: true,
            duration: secondDuration,
            autoplay: false,
            update: (anim) => {
                if (this.anim != null) {
                    this.textScaleVector.vec3_set(this.currentTextScale);
                    this.countdownTextObject.setScalingLocal(this.textScaleVector);

                }
            },
            loopBegin: () => {
                this.animationCountdownText = amountLoops === 3 ? "GO" : 3 - amountLoops;
                this.countdownText.text = this.animationCountdownText;
                if (amountLoops == 3) this.onCountDownFinished();
            },
            loopComplete: (anim) => {
                amountLoops++;
                if (amountLoops == 4) {
                    this.anim = null;
                    this.animStarted = false;
                    this.finished = true;
                    this.scaleUp = false;
                    this.scaleDown = true;
                }
            }
        });
    }

    onCountDownFinished() {
        if (!common.balcony.isPlayerOnBalcony) {
            this.hoverboard.startHoverboard(true);
            common.motivationalAudio.startMotivational();
        }

        common.timer.startTimer();

        const raceMusicAudio = common.audioManager.getAudio(AudioID.TRACK_MUSIC);
        if (raceMusicAudio != null) {
            if (raceMusicAudio != null && raceMusicAudio.isPlaying()) {
                // Restart the race music from beginning
                raceMusicAudio.stop();
            }

            raceMusicAudio.play();
        }

        const hoverboardTracker = common.hoverboardTracker;
        if (hoverboardTracker && hoverboardTracker.trackAndExport) {
            hoverboardTracker.startTracking();
        }

        if (currentGameConfig.mode === GameMode.Race) {
            common.tracksManager.getRaceManager().startRace();
        }

        common.menu.onCountdownFinished();
    }

    update(dt) {
        if (Globals.isDebugEnabled() && (HoverboardDebugs.skipCountdown || HoverboardRuntimeDebugs.moveToTrackDebug)) {
            if (this.scaleUp) {
                this.scale.vec3_set(this.targetScale);
                this.object.setScalingLocal(this.scale);
                this.scaleUp = false;
            }
        } else if (this.scaleUp && this.scale[1] < this.targetScale) {
            this.scale[1] = Math.min(this.targetScale, this.scale[1] + dt);
            this.object.setScalingLocal(this.scale);
            if (this.scale[1] === this.targetScale) {
                this.scaleUp = false;
            }
        }

        if (this.scaleDown && this.scale[1] > 0) {
            this.scale[0] = Math.max(0, this.scale[1] - dt);
            this.scale[1] = Math.max(0, this.scale[1] - dt);
            this.scale[2] = Math.max(0, this.scale[1] - dt);
            this.object.setScalingLocal(this.scale);
            if (this.scale[1] === 0) {
                this.scaleDown = false;
                this.object.active = false;
            }
        }

        if (!this.paused) {
            if (this.delayStartTimer.isRunning()) {
                this.delayStartTimer.update(dt);
                if (this.delayStartTimer.isDone()) {
                    this.finished = false;

                    this.beginAnimation();

                    this.countdownStart = true;
                }
            }
        }

        if (!this.finished && this.countdownStart && !this.paused) {
            if (this.anim) this.anim.tick(getTime());
            this.countdownText.active = true;
        }

        this.backgroundObject.pp_rotateAxisObject(30 * dt, this.rotationAxis);
    }
}
