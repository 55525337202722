import { AudioID } from "src/hoverfit/audio/audio-manager/audio-id.js";
import common from "src/hoverfit/common.js";
import { StatusEffectTarget } from "../status-effect-target.js";
import { StatusEffectType } from "../status-effect.js";
import { ExpiringStatusEffect, ExpiringStatusEffectParams } from "./expiring-status-effect.js";

export class InvincibilityStatusEffectParams extends ExpiringStatusEffectParams {
    duration: number = 5;

    sfxOnStart: AudioID | null = AudioID.STATUS_EFFECT_INVINCIBILITY;
}

export class InvincibilityStatusEffect extends ExpiringStatusEffect {

    declare protected _params: InvincibilityStatusEffectParams;

    constructor(target: StatusEffectTarget, params: InvincibilityStatusEffectParams) {
        super(StatusEffectType.Invincibility, target, params);
    }

    getParams(): InvincibilityStatusEffectParams {
        return this._params;
    }

    protected _startHook(): void {
        common.tracksManager.getStatusEffectsManager().clearEffects(this.getTarget(), StatusEffectType.Glue);
        common.tracksManager.getStatusEffectsManager().clearEffects(this.getTarget(), StatusEffectType.Invincibility, [this]);

        if (common.hoverboardNetworking.room) {
            common.hoverboardNetworking.setPlayerInvincible(true);
        }
    }

    protected _clearHook(): void {
        const hasInvincibility = common.tracksManager.getStatusEffectsManager().hasStatusEffect(this.getTarget(), StatusEffectType.Invincibility);
        if (!hasInvincibility) {
            if (common.hoverboardNetworking.room) {
                common.hoverboardNetworking.setPlayerInvincible(false);
            }
        }
    }
}