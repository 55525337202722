import { CanvasViewport, LeaveEvent, PointerEvent, PropagationModel, Root, SingleParent, SingleParentXMLInputConfig, Widget, WidgetAutoXML, type Rect, type Viewport, type WidgetEvent, type WidgetProperties } from "lazy-widgets";

function snappedCurve(x: number, linearPercent: number) {
    if (x <= 0) {
        return 0;
    } else if (x < linearPercent) {
        return x / linearPercent;
    } else {
        return 1;
    }
}

export interface CarouselProperties extends WidgetProperties {
    /** Does the carousel scroll vertically? False by default */
    vertical?: boolean,
    /**
     * Does the carousel loop? If true, then the carousel will render a
     * continuous looping view into the child widget. If the widget length along
     * the scrolling axis is smaller than the carousel's length along the
     * scrolling axis, then multiply views into the widget will be displayed.
     * True by default
     */
    loops?: boolean,
    slideSpeedPercent?: number;
    slideThreshold?: number;
    slideLinearThreshold?: number;
}

export class Carousel<W extends Widget = Widget> extends SingleParent<W> {
    static override autoXML: WidgetAutoXML = {
        name: "carousel",
        inputConfig: SingleParentXMLInputConfig,
    };

    private readonly internalViewport: CanvasViewport;
    offset = 0;
    private _effectiveOffset = 0;
    private _lastEffectiveOffset = 0;
    private slideStart = 0;
    private slideDirection = 0;
    slideSpeedPercent: number;
    slideThreshold: number;
    slideLinearThreshold: number;
    private scratchCanvasCtx: CanvasRenderingContext2D | null = null;
    readonly vertical: boolean;
    readonly loops: boolean;

    constructor(child: W, properties?: Readonly<CarouselProperties>) {
        super(child, properties);

        this.vertical = properties?.vertical ?? false;
        this.loops = properties?.loops ?? true;
        this.slideSpeedPercent = properties?.slideSpeedPercent ?? 0.5;
        this.slideThreshold = properties?.slideThreshold ?? 0.25;
        this.slideLinearThreshold = properties?.slideLinearThreshold ?? 0.5;
        this.internalViewport = new CanvasViewport(child);

        const scratchCanvas = document.createElement("canvas");
        this.scratchCanvasCtx = scratchCanvas.getContext("2d");
        if (!this.scratchCanvasCtx) {
            console.warn("Carousel: could not create scratch canvas; there won't be a fading effect on the sliding zones");
        }
    }

    protected override handleEvent(event: WidgetEvent): Widget | null {
        if (event.propagation === PropagationModel.Trickling) {
            // Drop event if it is a positional event with no target outside the
            // child's viewport
            if (event.isa(LeaveEvent)) {
                if (!event.target || event.target === this) this.slideDirection = 0;
            } else if (event instanceof PointerEvent) {
                const [cl, ct, cw, ch] = this.rect;
                const cr = cl + cw;
                const cb = ct + ch;

                if (event.target === null) {
                    if (event.x < cl) {
                        return null;
                    }
                    if (event.x >= cr) {
                        return null;
                    }
                    if (event.y < ct) {
                        return null;
                    }
                    if (event.y >= cb) {
                        return null;
                    }
                }

                const mainLength = this.mainLength;
                const mainOffset = this.mainOffset;
                const clickOffset = this.vertical ? event.y : event.x;
                const slideWindowSpan = this.slideThreshold * mainLength;
                const slideBackwardMax = mainOffset + slideWindowSpan;
                const slideForwardMin = mainOffset + mainLength - slideWindowSpan;
                let needsSetTime = this.slideDirection === 0;
                if (clickOffset < slideBackwardMax) {
                    this.slideDirection = -snappedCurve(1 - (clickOffset - mainOffset) / slideWindowSpan, this.slideLinearThreshold);
                } else if (clickOffset > slideForwardMin) {
                    this.slideDirection = snappedCurve((clickOffset - slideForwardMin) / slideWindowSpan, this.slideLinearThreshold);
                } else {
                    this.slideDirection = 0;
                    needsSetTime = false;
                }

                if (needsSetTime) this.slideStart = performance.now();

                const mainStart = this.childMainStart;
                const innerLength = this.childMainLength;
                let nearestChildOffset = mainStart;
                if (this.loops) {
                    nearestChildOffset += Math.trunc((clickOffset - mainStart) / innerLength) * innerLength;
                }

                if (nearestChildOffset !== 0) {
                    if (this.vertical) {
                        event = event.correctOffset(cl, nearestChildOffset);
                    } else {
                        event = event.correctOffset(nearestChildOffset, ct);
                    }
                }
            }

            return this.child.dispatchEvent(event);
        } else {
            return super.handleEvent(event);
        }
    }

    protected override handlePreLayoutUpdate(): void {
        if (this.slideDirection !== 0 && this.slideSpeedPercent !== 0) {
            const now = performance.now();
            this.offset += this.slideDirection * this.slideSpeedPercent * this.mainLength * (now - this.slideStart) * 0.001;
            this.slideStart = now;
        }

        // Pre-layout update child
        const child = this.child;
        child.preLayoutUpdate();

        // If child's layout is dirty, set self's layout as dirty
        if (child.layoutDirty) {
            this._layoutDirty = true;
        }

        // Update viewport resolution if needed
        this.internalViewport.resolution = this.root.resolution;
    }

    protected override handlePostLayoutUpdate(): void {
        // Update viewport rect
        this.internalViewport.rect = [0, 0, this.width, this.height];

        // Post-layout update child
        const child = this.child;
        child.postLayoutUpdate();

        // clamp offset to a reasonable range
        const innerLength = this.childMainLength;
        let eOffset = this.offset;
        if (this.loops) {
            if (eOffset > innerLength) {
                eOffset -= Math.trunc(eOffset / innerLength) * innerLength;
            } else if (eOffset < 0) {
                eOffset += Math.ceil(-eOffset / innerLength) * innerLength;
            }
        } else {
            const length = this.mainLength;
            if (eOffset < 0 || innerLength <= length) {
                eOffset = 0;
            } else {
                const slack = innerLength - length;
                if (eOffset > slack) {
                    eOffset = slack;
                }
            }
        }

        this._effectiveOffset = eOffset;
        this.offset = eOffset;

        if (eOffset !== this._lastEffectiveOffset) {
            this._lastEffectiveOffset = eOffset;
            this.markWholeAsDirty();
        }
    }

    protected override handleResolveDimensions(minWidth: number, maxWidth: number, minHeight: number, maxHeight: number): void {
        if (this.vertical) {
            this.internalViewport.constraints = [minWidth, maxWidth, this.loops ? 0 : minHeight, Infinity];
            this.internalViewport.resolveLayout();
            const childDims = this.child.idealDimensions;
            this.idealWidth = Math.min(Math.max(minWidth, childDims[0]), maxWidth);
            this.idealHeight = Math.min(Math.max(minHeight, childDims[1]), maxHeight);
        } else {
            this.internalViewport.constraints = [this.loops ? 0 : minWidth, Infinity, minHeight, maxHeight];
            this.internalViewport.resolveLayout();
            const childDims = this.child.idealDimensions;
            this.idealWidth = Math.min(Math.max(minWidth, childDims[0]), maxWidth);
            this.idealHeight = Math.min(Math.max(minHeight, childDims[1]), maxHeight);
        }
    }

    override attach(root: Root, viewport: Viewport, parent: Widget | null): void {
        // HACK Parent#attach attaches child widgets with this._viewport, but
        // we want to use this.internalViewport
        Widget.prototype.attach.call(this, root, viewport, parent);
        this.internalViewport.parent = viewport;
        this.child.attach(root, this.internalViewport, this);
    }

    override detach(): void {
        // unset parent viewport of internal viewport. using a clipped viewport
        // after this will crash; make sure to only use the viewport if the
        // widget is active
        this.internalViewport.parent = null;
        super.detach();
    }

    get mainLength() {
        return this.vertical ? this.height : this.width;
    }

    get crossLength() {
        return this.vertical ? this.width : this.height;
    }

    get mainOffset() {
        return this.vertical ? this.y : this.x;
    }

    get crossOffset() {
        return this.vertical ? this.x : this.y;
    }

    get childMainStart() {
        return this.mainOffset - this._effectiveOffset;
    }

    get childMainEnd() {
        const mainLength = this.childMainLength;
        if (this.loops) {
            return this.childMainStart + Math.ceil((this.mainLength + this._effectiveOffset) / mainLength) * mainLength;
        } else {
            return this.childMainStart + mainLength;
        }
    }

    get childMainLength() {
        const [childWidth, childHeight] = this.child.dimensions;
        return this.vertical ? childHeight : childWidth;
    }

    protected override handlePainting(dirtyRects: Array<Rect>): void {
        const iv = this.internalViewport;
        const childMainLength = this.childMainLength;
        const mainStart = this.childMainStart;
        const crossOffset = this.crossOffset;
        for (const rect of dirtyRects) {
            const start = (this.vertical ? rect[1] : rect[0]) - mainStart;
            const len = this.vertical ? rect[3] : rect[2];
            const end = start + len;
            const crossLen = this.vertical ? rect[2] : rect[3];
            const crossStart = (this.vertical ? rect[0] : rect[1]) - crossOffset;
            const innerLen = this.childMainLength;

            if (len >= innerLen) {
                if (this.vertical) {
                    iv.markDirtyRect([crossStart, 0, crossLen, innerLen]);
                } else {
                    iv.markDirtyRect([0, crossStart, innerLen, crossLen]);
                }
            } else {
                const nearestChildX = Math.floor(start / innerLen) * innerLen;
                const nextChildX = nearestChildX + innerLen;
                if (end > nextChildX) {
                    if (this.vertical) {
                        iv.markDirtyRect([crossStart, start - nearestChildX, crossLen, nextChildX - start]);
                        iv.markDirtyRect([crossStart, 0, crossLen, end - nextChildX]);
                    } else {
                        iv.markDirtyRect([start - nearestChildX, crossStart, nextChildX - start, crossLen]);
                        iv.markDirtyRect([0, crossStart, end - nextChildX, crossLen]);
                    }
                } else {
                    if (this.vertical) {
                        iv.markDirtyRect([crossStart, start - nearestChildX, crossLen, len]);
                    } else {
                        iv.markDirtyRect([start - nearestChildX, crossStart, len, crossLen]);
                    }
                }
            }
        }

        iv.paintToInternal();

        const ctx = this.viewport.context;
        ctx.save();
        ctx.beginPath();
        ctx.rect(this.x, this.y, this.width, this.height);
        ctx.clip();

        const res = iv.resolution;
        const mainEnd = this.childMainEnd;
        const [innerWidth, innerHeight] = this.child.dimensions;
        const srcW = Math.min(iv.usableMaxCanvasWidth, Math.ceil(innerWidth * res));
        const srcH = Math.min(iv.usableMaxCanvasHeight, Math.ceil(innerHeight * res));
        const ivCanvas = iv.canvas;
        const sctx = this.scratchCanvasCtx;
        let fastPath = true;
        if (sctx && this.slideThreshold > 0) {
            let startFade = this.slideThreshold;
            let endFade = 1 - this.slideThreshold;
            if (!this.loops) {
                const mainLength = this.mainLength;
                startFade = Math.min(startFade, this._effectiveOffset / mainLength);
                const slack = childMainLength - mainLength;
                if (slack <= 0) {
                    endFade = 1;
                } else {
                    endFade = Math.max(endFade, 1 - (slack - this._effectiveOffset) / mainLength);
                }
            }

            if (startFade !== 0 || endFade !== 1) {
                fastPath = false;
                // TODO there has to be a more efficient way to do this, right?
                const scratchCanvas = sctx.canvas;
                const widthScaled = this.width * res;
                const heightScaled = this.height * res;
                if (widthScaled > scratchCanvas.width) scratchCanvas.width = widthScaled;
                if (heightScaled > scratchCanvas.height) scratchCanvas.height = heightScaled;

                // XXX "copy" is probably faster, but then we need to clip it
                sctx.globalCompositeOperation = "source-over";
                sctx.clearRect(0, 0, widthScaled, heightScaled);
                const innerWidthScaled = innerWidth * res;
                const innerHeightScaled = innerHeight * res;
                for (let sliceOffset = mainStart; sliceOffset < mainEnd; sliceOffset += childMainLength) {
                    // copy slice to scratch canvas
                    if (this.vertical) {
                        sctx.drawImage(ivCanvas, 0, 0, srcW, srcH, 0, (sliceOffset - this.y) * res, innerWidthScaled, innerHeightScaled);
                    } else {
                        sctx.drawImage(ivCanvas, 0, 0, srcW, srcH, (sliceOffset - this.x) * res, 0, innerWidthScaled, innerHeightScaled);
                    }
                }

                // add gradient effect
                let gradient: CanvasGradient;
                if (this.vertical) {
                    gradient = sctx.createLinearGradient(0, 0, 0, heightScaled);
                } else {
                    gradient = sctx.createLinearGradient(0, 0, widthScaled, 0);
                }

                sctx.globalCompositeOperation = "destination-out";
                gradient.addColorStop(0, "rgba(255,255,255,1.0)");
                gradient.addColorStop(startFade, "rgba(255,255,255,0.0)");
                gradient.addColorStop(endFade, "rgba(255,255,255,0.0)");
                gradient.addColorStop(1, "rgba(255,255,255,1.0)");
                sctx.fillStyle = gradient;
                sctx.fillRect(0, 0, widthScaled, heightScaled);

                // draw scratch canvas to viewport canvas
                ctx.drawImage(scratchCanvas, 0, 0, widthScaled, heightScaled, this.x, this.y, this.width, this.height);
            }
        }

        if (fastPath) {
            const [childWidth, childHeight] = this.child.dimensions;
            for (let sliceOffset = mainStart; sliceOffset < mainEnd; sliceOffset += childMainLength) {
                if (this.vertical) {
                    ctx.drawImage(ivCanvas, 0, 0, srcW, srcH, this.x, sliceOffset, childWidth, childMainLength);
                } else {
                    ctx.drawImage(ivCanvas, 0, 0, srcW, srcH, sliceOffset, this.y, childMainLength, childHeight);
                }
            }
        }

        ctx.restore();
    }

    override propagateDirtyRect(rect: Rect): void {
        // convert damage region from relative coordinates to absolute
        // coordinates if necessary
        const vpCrossOffset = this.crossOffset;
        const rectCrossOffset = this.vertical ? rect[0] : rect[1];
        const rectCrossLength = this.vertical ? rect[2] : rect[3];
        let crossOffset = Math.floor(rectCrossOffset + vpCrossOffset);
        let crossEnd = Math.ceil(rectCrossOffset + vpCrossOffset + rectCrossLength);

        // clip dirty rects to avoid dirty rects being spammed from widgets that
        // are offscreen and therefore won't be painted, causing a loop of
        // constant dirty rects
        const vpCrossEnd = vpCrossOffset + this.crossLength;
        if (crossOffset < vpCrossOffset) {
            crossOffset = vpCrossOffset;
        }
        if (crossEnd > vpCrossEnd) {
            crossEnd = vpCrossEnd;
        }

        if (crossOffset >= crossEnd) {
            return;
        }

        const crossLength = crossEnd - crossOffset;
        const rectOffset = this.vertical ? rect[1] : rect[0];
        const rectLength = this.vertical ? rect[3] : rect[2];
        const childMainLength = this.childMainLength;
        const mainEnd = this.childMainEnd;
        const vpStart = this.mainOffset;
        const vpEnd = vpStart + this.mainLength;
        for (let offset = vpStart - this._effectiveOffset + rectOffset; offset < mainEnd; offset += childMainLength) {
            let end = Math.ceil(offset + rectLength);

            // clip dirty rects to avoid dirty rects being spammed from widgets
            // that are offscreen and therefore won't be painted, causing a loop
            // of constant dirty rects
            let fOffset = Math.floor(offset);
            if (fOffset < vpStart) {
                fOffset = vpStart;
            }
            if (end > vpEnd) {
                end = vpEnd;
            }

            if (fOffset >= end) {
                continue;
            }

            if (this.vertical) {
                super.propagateDirtyRect([crossOffset, fOffset, crossLength, end - fOffset]);
            } else {
                super.propagateDirtyRect([fOffset, crossOffset, end - fOffset, crossLength]);
            }
        }
    }

    override queryRect(rect: Rect, relativeTo: Widget | null = null): Rect {
        // XXX copy-pasted from ViewportWidget
        const vpX = this.internalViewport.rect[0] + this.internalViewport.offset[0];
        const vpY = this.internalViewport.rect[1] + this.internalViewport.offset[1];

        const left = rect[0] + vpX;
        const top = rect[1] + vpY;
        const right = rect[0] + rect[2] + vpX;
        const bottom = rect[1] + rect[3] + vpY;

        rect = [left, top, right - left, bottom - top];

        return super.queryRect(rect, relativeTo);
    }

    override queryPoint(x: number, y: number, relativeTo: Widget | null = null): [x: number, y: number] {
        // XXX copy-pasted from ViewportWidget
        x += this.internalViewport.rect[0] + this.internalViewport.offset[0];
        y += this.internalViewport.rect[1] + this.internalViewport.offset[1];

        return super.queryPoint(x, y, relativeTo);
    }

    slideToDescendant(descendant: Widget) {
        const desPos = descendant.position;
        const desDims = descendant.dimensions;
        const relMid = descendant.queryPoint(desPos[0] + desDims[0] / 2, desPos[1] + desDims[1] / 2, this.child);
        this.offset = (this.vertical ? relMid[1] : relMid[0]) - this.mainLength / 2;
    }
}