import { MathUtils } from "wle-pp";
import { PopupIconImage } from "../ui/popup/popup.js";

const ENCOURAGEMENTS = [
    "GREAT!",
    "AWESOME!",
    "AMAZING!",
    "WOW!",
    "TERRIFIC!",
    "FANTASTIC!",
    "WAY TO GO!",
    "INCREDIBLE!"
];

export function getRandomEncouragement(): string {
    return MathUtils.randomPick(ENCOURAGEMENTS)!;
}

export const enum RewardTier {
    None = -1,
    Bronze = 0,
    Silver = 1,
    Gold = 2,
    Platinum = 3,
    MAX = Platinum,
}

export const DAILY_REWARD_THRESHOLDS = [50, 150, 300, 500] as const;
export const MEDALS_PER_TROPHY = 10;

export function getDailyMedalFitPointsThreshold(rewardTier: RewardTier): number {
    return DAILY_REWARD_THRESHOLDS[rewardTier as number];
}

export function getDailyMedalName(rewardTier: RewardTier): string {
    return ["bronze", "silver", "gold", "platinum"][rewardTier as number];
}

export function getDailyMedalFitPointsTier(dailyFitPoints: number): RewardTier {
    let dailyRewardTier = RewardTier.None;

    for (let rewardTier = 0; rewardTier <= RewardTier.MAX; rewardTier++) {
        if (dailyFitPoints < DAILY_REWARD_THRESHOLDS[rewardTier]) break;
        dailyRewardTier = rewardTier;
    }

    return dailyRewardTier;
}

export function getDailyMedalPopupIconImage(rewardTier: RewardTier): PopupIconImage {
    switch (rewardTier) {
        case RewardTier.Bronze: return PopupIconImage.DailyMedalBronze;
        case RewardTier.Silver: return PopupIconImage.DailyMedalSilver;
        case RewardTier.Gold: return PopupIconImage.DailyMedalGold;
        case RewardTier.Platinum: return PopupIconImage.DailyMedalPlatinum;
        default: return PopupIconImage.None;
    }
}
