import { Component, MeshComponent, Property, TextComponent } from "@wonderlandengine/api";
import { GameMode } from "hoverfit-shared-netcode";
import { InvincibilityRingComponent } from "src/hoverfit/game/hoverboard/components/invincibility-ring-component.js";
import { TagRingComponent } from "src/hoverfit/game/hoverboard/components/tag-ring-component.js";
import { ItemCategory } from "src/hoverfit/misc/asset-provision/asset-provider.js";
import { getBuiltInItemIDByIndex, getBuiltInItemTypeAmount } from "src/hoverfit/misc/asset-provision/built-in-asset-provider.js";
import { ColorUtils, MaterialUtils, MathUtils, Timer, quat2_create, vec3_create } from "wle-pp";
import { AvatarComponent } from "../../avatar/components/avatar-component.js";
import common from "../../common.js";
import { currentGameConfig } from "../../data/game-configuration.js";
import { NPCControllerComponent } from "../../game/hoverboard/components/npc-controller-component.js";
import { GameGlobals } from "../../misc/game-globals.js";
import { NameSource } from "../../misc/player-name-tracker.js";
import { seededRandom } from "../../utils/math-utils.js";
import { getSynchedParamKeyIndex, synchedObjects } from "./network-sync-component.js";
import { NetworkedHoverboardComponent } from "./networked-hoverboard-component.js";

const LOUD_MIN_DBFS = -60;
const LOUD_MAX_DBFS = -50;
const LOUD_TO_ALPHA = 1 / (LOUD_MAX_DBFS - LOUD_MIN_DBFS);
const TMP_COLOR = new Float32Array(4);
const NAME_POOL = ["Turbo", "Shockwave", "Thunderbolt", "Phoenix", "Nightshade", "Raptor", "Wildfire", "Spark", "Sandstorm"];
let currentlyUsedNPCSuitColorIndices = [];

export class NetworkPlayerComponent extends Component {
    static TypeName = "network-player";
    static Properties = {
        chaserColor: Property.color(),
        evaderColor: Property.color(),
    };

    static onRegister(engine) {
        engine.registerComponent(NPCControllerComponent);
    }

    init() {
        this.nameSource = new NameSource();
        this.nameListener = this.updateDisplayName.bind(this);
        this._displayName = "";
        this._enabled = false;
        this.wasLoud = true; // XXX true because outline starts active
        this.sessionId = null;
        this.onActivateCallbacks = [];
        this.onDeactivateCallbacks = [];
        this.onHeadTransformUpdate = [];
        this.onNameChange = [];

        // Support Variables
        this.headPosition = vec3_create();

        this.utilityObjectsInitialized = false;
    }

    onActivate() {
        this.nameSource.listen(this.nameListener);
    }

    onDeactivate() {
        this.nameSource.unlisten(this.nameListener);
    }

    start() {
        this.lerpedTransformQuat = {
            head: quat2_create(),
            leftHand: quat2_create(),
            rightHand: quat2_create(),
            feet: quat2_create(),
            hoverboard: quat2_create()
        };

        this.currentTransformQuat = {
            head: quat2_create(),
            leftHand: quat2_create(),
            rightHand: quat2_create(),
            feet: quat2_create(),
            hoverboard: quat2_create(),
        };

        this.newTransformQuat = {
            head: quat2_create(),
            leftHand: quat2_create(),
            rightHand: quat2_create(),
            feet: quat2_create(),
            hoverboard: quat2_create()
        };

        this.hoverboardData = {
            currentSpeed: 0,
            currentYSpeed: 0,
            currentTurnSpeed: 0
        };

        this.shouldUpdate = {
            head: false,
            leftHand: false,
            rightHand: false,
            feet: false,
            hoverboard: false
        };

        this.firstNetworkUpdate = {
            head: true,
            leftHand: true,
            rightHand: true,
            feet: true,
            hoverboard: true
        };

        this.head = this.object.pp_getObjectByName("Head");
        if (this.head != null) {
            this.head.getTransformLocal(this.currentTransformQuat.head);
        }

        this.leftHand = this.object.pp_getObjectByName("Hand Left");
        if (this.leftHand != null) {
            this.leftHand.getTransformLocal(this.currentTransformQuat.leftHand);
        }

        this.rightHand = this.object.pp_getObjectByName("Hand Right");
        if (this.rightHand != null) {
            this.rightHand.getTransformLocal(this.currentTransformQuat.rightHand);
        }

        this.feet = this.object.pp_getObjectByName("Feet");
        if (this.feet != null) {
            this.feet.getTransformLocal(this.currentTransformQuat.feet);
        }

        this.hoverboard = this.object.pp_getObjectByName("Hoverboard");
        if (this.hoverboard != null) {
            this.hoverboard.getTransformLocal(this.currentTransformQuat.hoverboard);
            this.hoverboardComponent = this.hoverboard.getComponent(NetworkedHoverboardComponent);
            this.hoverboardComponent.setHoverboardData(this.hoverboardData);

            MaterialUtils.setObjectClonedMaterials(this.hoverboard);
        }

        this.avatar = this.object.pp_getObjectByName("Avatar");

        this.playerName = this.object.pp_getObjectByName("Name");
        if (this.playerName != null) {
            const playerNameLabel = this.playerName.pp_getObjectByName("Label");
            const playerNameOutlineLabel = this.playerName.pp_getObjectByName("OutlineLabel");
            this.playerNameTextComponent = playerNameLabel.pp_getComponent(TextComponent);
            this.playerNameOutlineTextComponent = playerNameOutlineLabel.pp_getComponent(TextComponent);
            this.playerNameOutlineTextComponent.material = this.playerNameOutlineTextComponent.material.clone();
            // XXX need to copy, material colors are live views (very goodn't)
            this.highLoudnessOutlineColor = new Float32Array(4);
            const tmpColor = this.playerNameOutlineTextComponent.material.effectColor;
            this.highLoudnessOutlineColor[0] = tmpColor[0];
            this.highLoudnessOutlineColor[1] = tmpColor[1];
            this.highLoudnessOutlineColor[2] = tmpColor[2];
            this.highLoudnessOutlineColor[3] = tmpColor[3];
            console.debug(this.highLoudnessOutlineColor[0], this.highLoudnessOutlineColor[1], this.highLoudnessOutlineColor[2]);
            this.lowLoudnessOutlineColor = new Float32Array(4);
            this.lowLoudnessOutlineColor[0] = this.highLoudnessOutlineColor[0];
            this.lowLoudnessOutlineColor[1] = this.highLoudnessOutlineColor[1];
            this.lowLoudnessOutlineColor[2] = this.highLoudnessOutlineColor[2];
            this.lowLoudnessOutlineColor[3] = 1;
            ColorUtils.rgbToHSV(this.lowLoudnessOutlineColor);
            // halve saturation and value (lightness)
            this.lowLoudnessOutlineColor[1] *= 0.5;
            this.lowLoudnessOutlineColor[2] *= 0.5;
            ColorUtils.hsvToRGB(this.lowLoudnessOutlineColor);
        }

        this.playerTagStatus = this.object.pp_getObjectByName("Tag Status");
        if (this.playerTagStatus != null) {
            MaterialUtils.setObjectClonedMaterials(this.playerTagStatus);
        }

        let avatarBody = this.object.pp_getObjectByName("Suit");
        this.avatarMesh = avatarBody.getComponent(MeshComponent, 0);

        this.avatarComponent = this.object.pp_getComponent(AvatarComponent);

        this.hoverboardComponent.avatar = this.avatarComponent;

        // Tag Visuals
        let tagPositionOffset = common.hoverboard.computeTagPositionOffset(false);
        tagPositionOffset.vec3_add(GameGlobals.up.vec3_scale(-0.3), tagPositionOffset);

        // TODO: Remove when NPC logic comes to other game modes 
        if (currentGameConfig.mode === GameMode.Race) {
            this.npcController = this.object.getComponent(NPCControllerComponent);
            this.npcController.player = this;
            this.npcController.active = false;
        }

        this.currentSplineTime = 0;

        this._resetPlayer();
    }

    resetPlayer() {
        if (!this.utilityObjectsInitialized) {
            this._initUtilityObjects();
        }

        this._resetPlayer();
    }

    _resetPlayer() {
        this.keepSyncingForwardWithHeadTimer = new Timer(1, false);

        for (const synchedObject of synchedObjects) {
            this.lerpedTransformQuat[synchedObject].quat2_identity();
            this.currentTransformQuat[synchedObject].quat2_identity();
            this.newTransformQuat[synchedObject].quat2_identity();
            this.shouldUpdate[synchedObject] = false;
            this.firstNetworkUpdate[synchedObject] = true;
        }

        this.hoverboardData.currentSpeed = 0;
        this.hoverboardData.currentYSpeed = 0;
        this.hoverboardData.currentTurnSpeed = 0;

        this.lerped = true;
        this.avoidLerpAboveDistance = 1;
        this.avoidLerpTimer = new Timer(0);

        this.racing = false;
        this.onTrack = false;
        this.tagged = false;
        this.lapsAmount = -1;

        if (this.utilityObjectsInitialized) {
            this.tagRingComponent.setVisible(false);
            this.invincibilityRingComponent.setVisible(false);
        }

        this.object.pp_setActive(false);

        this.object.pp_resetTransformLocal();

        for (let child of this.object.pp_getChildren()) {
            child.pp_resetTransformLocal();
        }

        this.hoverboardComponent.resetHoverboard();

        if (this._enabled) this.nameSource.untrack();
        this._enabled = false;

        this.isNPC = false;
    }

    _initUtilityObjects() {
        const tagPositionOffset = common.hoverboard.computeTagPositionOffset(false);
        tagPositionOffset.vec3_add(GameGlobals.up.vec3_scale(-0.3), tagPositionOffset);

        this.tagRingComponent = this.object.pp_getComponent(TagRingComponent);
        this.tagRingComponent.object.pp_setPositionLocal(tagPositionOffset);

        this.invincibilityRingComponent = this.object.pp_getComponent(InvincibilityRingComponent);
        this.invincibilityRingComponent.object.pp_setPositionLocal(tagPositionOffset);

        this.utilityObjectsInitialized = true;
    }

    setFaceComponent(faceComponent) {
        this.faceComponent = faceComponent;
    }

    setRacing(racing) {
        this.racing = racing;

        this._refreshTagVisuals();
    }

    setNPC(npcIndex, npcSeed) {
        if (npcIndex === 0) {
            currentlyUsedNPCSuitColorIndices = [];
        }

        this.npcController.active = true;
        this.npcController.player = this;

        this.isNPC = true;

        this.setSessionId(npcIndex);
        this.setOnTrack(true);

        this.npcController.toTrack(npcIndex);
        // Returns speed 0..1
        this.npcController.setSpeedFromSeed(npcSeed);

        const name = NAME_POOL[npcIndex] + " [CPU]";
        this.npcController.setName(name);
        this.setName(name);
        this.updateDisplayName(name, 0);

        // Calculation so that it is not one side male and one side female
        let seed = Math.floor(seededRandom(npcSeed) * 100);
        let seed2 = Math.floor(seededRandom(npcSeed * 3) * 100);
        let seed3 = Math.floor(seededRandom(npcSeed * 5) * 100);
        let seed4 = Math.floor(seededRandom(npcSeed * 7) * 100);

        common.avatarSelector.setAvatarType(seed % 2, this.avatarComponent);
        common.avatarSelector.setAvatarSkinColor(getBuiltInItemIDByIndex(ItemCategory.Skin, seed % getBuiltInItemTypeAmount(ItemCategory.Skin)), this.avatarComponent);
        common.avatarSelector.setAvatarHeadwear(getBuiltInItemIDByIndex(ItemCategory.Headwear, seed3 % getBuiltInItemTypeAmount(ItemCategory.Headwear)), this.avatarComponent);
        common.avatarSelector.setAvatarHairColor(getBuiltInItemIDByIndex(ItemCategory.HairColor, seed4 % getBuiltInItemTypeAmount(ItemCategory.HairColor)), this.avatarComponent);

        // Makes sure that suit color is unique for each npc
        let suitColorIterationIndex = 0;
        const SUIT_COLOR_COUNT = getBuiltInItemTypeAmount(ItemCategory.Suit);
        let suitColorIndex = (seed2 + suitColorIterationIndex) % SUIT_COLOR_COUNT;
        while (currentlyUsedNPCSuitColorIndices.includes(suitColorIndex)) {
            suitColorIterationIndex++;
            suitColorIndex = (seed2 + suitColorIterationIndex) % SUIT_COLOR_COUNT;
        }
        currentlyUsedNPCSuitColorIndices.push(suitColorIndex);
        common.avatarSelector.setAvatarSuit(getBuiltInItemIDByIndex(ItemCategory.Suit, suitColorIndex), this.avatarComponent);

        common.hoverboardSelector.setHoverboard(getBuiltInItemIDByIndex(ItemCategory.Hoverboard, npcSeed % getBuiltInItemTypeAmount(ItemCategory.Hoverboard)), this.hoverboardComponent.getHoverboardMeshObject(), false);
    }

    setOnTrack(onTrack) {
        this.onTrack = onTrack;

        this.hoverboardComponent.setOnTrack(onTrack);

        if (!this.isNPC) {
            const activeTrack = common.tracksManager.getCurrentTrack();
            if (activeTrack != null && activeTrack.hasSpline()) {
                this.currentSplineTime = common.tracksManager.getCurrentTrack().getSpawnPositionProvider().getSpawnPositionSplineTime();
            }
        }

        if (!onTrack) {
            this.playerTagStatus.pp_setActive(false);
            this.tagRingComponent.setVisible(false);
            this.invincibilityRingComponent.setVisible(false);
        }
    }

    setLapsAmount(lapsAmount) {
        this.lapsAmount = lapsAmount;
    }

    updateSplineTime() {
        const spline = common.tracksManager.getCurrentTrack().getSpline();
        const objectPosition = this.hoverboardComponent.object.getPositionWorld(this.headPosition);
        this.currentSplineTime = spline.getClosestTimeAroundTime(objectPosition, this.currentSplineTime, 0.01);
    }

    getCurrentSplineTime() {
        return this.currentSplineTime;
    }

    setTagged(tagged) {
        this.tagged = tagged;

        this._refreshTagVisuals();
    }

    setInvincible(invincible) {
        this.invincible = invincible;

        if (this.racing && currentGameConfig.mode == GameMode.Tag) {
            this.invincibilityRingComponent.setVisible(invincible);
        }
    }

    isEnabled() {
        return this._enabled;
    }

    setEnabled(enabled) {
        this._enabled = enabled;
        if (enabled) {
            for (const f of this.onActivateCallbacks) f();
            this.nameSource.track();
        } else {
            for (const f of this.onDeactivateCallbacks) f();
            this.nameSource.untrack();
        }

        this.object.pp_setActive(enabled);
        if (this.npcController && !this.isNPC) this.npcController.active = false;
        this.wasLoud = true; // XXX outline gets enabled, so we need to pretend last frame had loud audio

        if (enabled) {
            if (this.avatarComponent == null) {
                this.avatarComponent = this.object.pp_getComponent(AvatarComponent);
                this.hoverboardComponent.avatar = this.avatarComponent;
            }

            if (this.hoverboardComponent == null) {
                this.hoverboardComponent.getHoverboardMeshObject().pp_setActive(false);
            }

            this.keepSyncingForwardWithHeadTimer.start();
            this.avatarComponent.setForcedSyncForwardWithHead(true);

            this.playerTagStatus.pp_setActive(false);

            this.tagRingComponent.setVisible(false);
            this.invincibilityRingComponent.setVisible(false);

            this._updateLoudnessIndicator(null);
        }
    }

    setTransformIndex(key, index, value) {
        if (!synchedObjects.includes(key)) return console.error("network-player: Can not set tranform for unsynched object.");

        this.newTransformQuat[key][index] = value;
        this.shouldUpdate[key] = true;
    }

    setTransform(key, changeKey, value) {
        if (!synchedObjects.includes(key)) return console.error("network-player: Can not set tranform for unsynched object.");

        this.newTransformQuat[key][getSynchedParamKeyIndex(changeKey)] = value;
        this.shouldUpdate[key] = true;
    }

    setHoverboardDataPropertyValue(propertyName, value) {
        if (!(propertyName in this.hoverboardData)) return console.error("network-player: Can't set hoverboard data property since it does not exist: " + propertyName);

        this.hoverboardData[propertyName] = value;
    }

    setName(name) {
        this.nameSource.name = name;
    }

    updateDisplayName(name, priority) {
        const taggedName = `${name}${priority === 0 ? "" : ` (${priority})`}`;
        this.playerNameTextComponent.text = taggedName;
        this.playerNameOutlineTextComponent.text = taggedName;
        this._displayName = taggedName;

        for (const f of this.onNameChange) f(this._displayName);
    }

    getDisplayName() {
        return this._displayName;
    }

    getHeadObject() {
        return this.head;
    }

    setSessionId(sessionId) {
        if (this.hoverboardComponent) {
            this.sessionId = sessionId;
            this.hoverboardComponent.sessionId = sessionId;
        }
    }

    update(dt) {
        if (this.isNPC) {
            return;
        }

        if (this.racing) {
            const activeTrack = common.tracksManager.getCurrentTrack();
            if (activeTrack != null && activeTrack.hasSpline()) {
                this.updateSplineTime();
            }
        }

        this.avoidLerpTimer.update(dt);

        if (this.keepSyncingForwardWithHeadTimer.isRunning()) {
            this.keepSyncingForwardWithHeadTimer.update(dt);
            if (this.keepSyncingForwardWithHeadTimer.isDone()) {
                this.avatarComponent.setForcedSyncForwardWithHead(false);
            }
        }

        for (const synchedObject of synchedObjects) {
            if (this.shouldUpdate[synchedObject]) {
                let shouldKeepUpdating = this._updateTransform(dt, synchedObject);
                this.shouldUpdate[synchedObject] = shouldKeepUpdating;
            }
        }

        // update VoIP spatial audio position and loudness indicator. no need to
        // apply distance threshold logic here, VoIPPeer already does it
        const voipClient = common.hoverboardNetworking.voip;
        if (voipClient && this.sessionId) {
            const otherPeer = voipClient.getOtherPeer(this.sessionId);
            if (voipClient.spatialAudio) {
                if (otherPeer) {
                    this.head.getPositionWorld(otherPeer.audioSource.placement.position);
                    otherPeer.updateSpatialPos();
                }
            }

            this._updateLoudnessIndicator(otherPeer);
        }
    }

    _updateLoudnessIndicator(peer) {
        // -60dBFS works pretty well for speech volumes, so that's what we're
        // using (very scientific). i'd use something like the whispering volume
        // (30dBSPL), but there is no way of converting dBSPL to dBFS
        const curLoudness = peer ? peer.audioSource.loudness : -Infinity;
        const isLoud = curLoudness > LOUD_MIN_DBFS;
        if (isLoud) {
            const loudAlpha = (curLoudness - LOUD_MIN_DBFS) * LOUD_TO_ALPHA;
            TMP_COLOR[0] = MathUtils.lerp(this.lowLoudnessOutlineColor[0], this.highLoudnessOutlineColor[0], loudAlpha);
            TMP_COLOR[1] = MathUtils.lerp(this.lowLoudnessOutlineColor[1], this.highLoudnessOutlineColor[1], loudAlpha);
            TMP_COLOR[2] = MathUtils.lerp(this.lowLoudnessOutlineColor[2], this.highLoudnessOutlineColor[2], loudAlpha);
            TMP_COLOR[3] = 1;
            this.playerNameOutlineTextComponent.material.effectColor = TMP_COLOR;
            if (this.faceComponent) this.faceComponent.setMouthOpenness(loudAlpha);
        }

        if (this.wasLoud !== isLoud) {
            this.wasLoud = isLoud;

            if (isLoud) {
                this.playerNameOutlineTextComponent.active = true;
            } else {
                if (this.faceComponent) this.faceComponent.setMouthOpenness(0.0);
                this.playerNameOutlineTextComponent.active = false;
            }
        }
    }

    _updateTransform(dt, key) {
        // Implemented outside class definition
    }

    _refreshTagVisuals() {
        if (this.racing) {
            if (currentGameConfig.mode == GameMode.Tag) {
                let meshComponents = this.playerTagStatus.pp_getComponents(MeshComponent);
                if (this.tagged) {
                    for (let meshComponent of meshComponents) {
                        meshComponent.material.diffuseColor = this.chaserColor;
                    }
                } else {
                    for (let meshComponent of meshComponents) {
                        meshComponent.material.diffuseColor = this.evaderColor;
                    }
                }

                this.playerTagStatus.pp_setActive(true);

                this.tagRingComponent.setVisible(true);
                this.tagRingComponent.setCatchDistance(common.hoverboard.tagCatchDistance);
                this.tagRingComponent.setTaggedState(this.tagged);
            }
        }
    }
}



// IMPLEMENTATION

NetworkPlayerComponent.prototype._updateTransform = function () {
    let newTransformQuat = quat2_create();

    let currentPosition = vec3_create();
    let newPosition = vec3_create();

    let currentTransformQuatLocal = quat2_create();
    let parentTransformQuat = quat2_create();
    return function _updateTransform(dt, key) {
        let shouldKeepUpdating = false;

        let currentObject = this[key];
        let currentTransformQuat = this.currentTransformQuat[key];

        newTransformQuat.quat2_copy(this.newTransformQuat[key]);
        if (!this.onTrack && key == "hoverboard") {
            newTransformQuat.quat2_copy(this.newTransformQuat["feet"]);
        }

        if (this.lerped && !this.firstNetworkUpdate[key]) {
            currentObject.pp_getTransformWorldQuat(currentTransformQuat);

            currentPosition = currentTransformQuat.quat2_getPosition(currentPosition);
            newPosition = newTransformQuat.quat2_getPosition(newPosition);

            // when racing we are going fast so we need to always lerp
            // we need to find a better way to handle teleports so that we just avoid lerping when teleporting
            let distanceToNewPosition = currentPosition.vec3_distance(newPosition);
            if (this.avoidLerpTimer.isDone() && (this.racing || distanceToNewPosition <= this.avoidLerpAboveDistance)) {
                currentTransformQuat.quat2_slerp(newTransformQuat, 15 * dt, currentTransformQuat);

                if (currentTransformQuat.vec_equals(newTransformQuat, Math.PP_EPSILON)) {
                    currentTransformQuat.quat2_copy(newTransformQuat);
                } else {
                    shouldKeepUpdating = true;
                }
            } else {
                this.lerpAboveDistanceAnyway = true;
                currentTransformQuat.quat2_copy(newTransformQuat);
            }
        } else {
            currentTransformQuat.quat2_copy(newTransformQuat);
        }

        currentTransformQuat.quat2_normalize(currentTransformQuat);
        currentTransformQuatLocal.quat2_copy(currentTransformQuat);

        currentTransformQuatLocal.quat2_toLocal(currentObject.parent.pp_getTransformWorldQuat(parentTransformQuat), currentTransformQuatLocal);
        currentTransformQuatLocal.quat2_normalize(currentTransformQuatLocal);

        currentObject.pp_setTransformLocalQuat(currentTransformQuatLocal);

        if (key === "head") {
            for (const f of this.onHeadTransformUpdate) f(currentObject);
        }

        this.firstNetworkUpdate[key] = false;

        return shouldKeepUpdating;
    };
}();
