import { Component } from "@wonderlandengine/api";
import { property } from "@wonderlandengine/api/decorators.js";
import { quat } from "gl-matrix";
import { AudioSink } from "../audio-sink.js";

const ROT180Y = new Float32Array([0, 1, 0, 0]);

export class AudioSinkComponent extends Component {
    static override TypeName = "audio-sink";

    @property.bool(false)
    rotateForward!: boolean;
    private _sink!: AudioSink;
    protected vrButtonListener!: () => void;

    get sink() {
        return this._sink;
    }

    override init() {
        this.vrButtonListener = this.tryResume.bind(this);
        this._sink = new AudioSink();
        this._sink.tryResuming();

        const vrButton = document.getElementById("vr-button");
        if (vrButton) {
            vrButton.addEventListener("click", this.vrButtonListener);
        }

        const arButton = document.getElementById("ar-button");
        if (arButton) {
            arButton.addEventListener("click", this.vrButtonListener);
        }
    }

    override onDestroy() {
        const vrButton = document.getElementById("vr-button");
        if (vrButton) {
            vrButton.removeEventListener("click", this.vrButtonListener);
        }

        const arButton = document.getElementById("ar-button");
        if (arButton) {
            arButton.removeEventListener("click", this.vrButtonListener);
        }
    }

    tryResume() {
        this._sink.tryResuming();
    }

    override update(_dt: number) {
        const placement = this._sink.placement;
        const obj = this.object;
        obj.getPositionWorld(placement.position);
        obj.getRotationWorld(placement.rotation);

        if (this.rotateForward) {
            quat.mul(placement.rotation, placement.rotation, ROT180Y);
        }

        this._sink.updatePlacement();
    }

    get volume() {
        return this._sink.volume;
    }

    set volume(volume: number) {
        this._sink.volume = volume;
    }
}