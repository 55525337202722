import { Globals, MathUtils, Quaternion, Quaternion2, Vec3Utils, Vector3, quat2_create, quat_create, vec3_create, vec4_create } from "wle-pp";
import { HoverboardDebugs } from "../../components/hoverboard-debugs-component.js";
import { BezierCurveParams, SplineUtils } from "./spline_utils.js";

export class SplinePoint {
    public controlPoint: Vector3 = vec3_create();
    public controlHandleIn: Vector3 = vec3_create();
    public controlHandleOut: Vector3 = vec3_create();
}

export enum SplineDirection {
    Forward = 1,
    Backward = -1
}

export class SplineParams {
    public splinePoints: SplinePoint[] = [];

    public startTimeOffset: number = 0;
    public direction: SplineDirection = SplineDirection.Forward;
    public isLoop: boolean = true;

    /** The shorter the length, the more precise the spline, but it will also be less efficient  
        It is specified as a percentage between `0` and `1` of the overall spline length */
    public arcLengthPercentage: number = 0.001;

    /** 
     * The shorter the length, the more precise the searches, but it will also be less efficient  
     * An example of search is `getClosestTime`, while `getPosition` is just a normal fetch and therefore doesn't need this parameter
     * 
     * It is specified as a percentage between `0` and `1` of the overall spline length
     */
    public searchStepLengthPercentage: number = 0.0001;

    /** Used to compute the rotation of the point since throught the spline only the forward can be computed */
    public defaultUp: Vector3 = vec3_create(0, 1, 0);
}

/**
 * Some infos:
 *  - the raw time is a percentage of the spline points array length, and is therefore not a percentage of the actual spline length
 */
export class Spline {
    private _params: SplineParams;

    /** An array with the intermediate lengths along the spline */
    private _arcLengths: number[] = [];
    private _length: number = 0;

    /** An array with the intermediate lengths along the spline */
    private _arcLengthsRaw: number[] = [];
    private _lengthRaw: number = 0;
    private _startTimeOffsetRaw: number = 0;
    private _directionRaw: SplineDirection = SplineDirection.Forward;

    private static readonly _SV = {
        prevPosition: vec3_create(),
        position: vec3_create(),
        forward: vec3_create(),
        rotationQuat: quat_create(),
        color: vec4_create(),
        bezierCurveParams: new BezierCurveParams()
    };

    constructor(splineParams: SplineParams) {
        this._params = splineParams;

        this._startTimeOffsetRaw = 0;
        this._directionRaw = SplineDirection.Forward;
        this._computeArcLengths();
        this._arcLengthsRaw = this._arcLengths.pp_clone();
        this._lengthRaw = this._length;

        this._startTimeOffsetRaw = this.convertTimeToRawTime(this._params.startTimeOffset);
        this._directionRaw = this._params.direction;
        this._computeArcLengths();
    }

    public getParams(): SplineParams {
        return this._params;
    }

    public paramsUpdated(): void {
        const oldArcLengths = this._arcLengths;

        this._arcLengths = this._arcLengthsRaw;
        this._length = this._lengthRaw;

        this._startTimeOffsetRaw = this.convertTimeToRawTime(this._params.startTimeOffset);
        this._directionRaw = this._params.direction;

        this._arcLengths = oldArcLengths;
        this._length = 0;
        this._computeArcLengths();
    }

    public getPosition(time: number, out: Vector3 = vec3_create()): Vector3 {
        return this.getPositionRaw(this.convertTimeToRawTime(time), out);
    }

    public getForward(time: number, out: Vector3 = vec3_create()): Vector3 {
        return this.getForwardRaw(this.convertTimeToRawTime(time), out);
    }

    public getRotationQuat(time: number, out: Quaternion = quat_create()): Quaternion {
        return this.getRotationQuatRaw(this.convertTimeToRawTime(time), out);
    }

    public getTransformQuat(time: number, out: Quaternion2 = quat2_create()): Vector3 {
        const splinePosition = Spline._SV.position;
        const splineRotationQuat = Spline._SV.rotationQuat;

        const rawTime = this.convertTimeToRawTime(time);
        out.quat2_setPositionRotationQuat(this.getPositionRaw(rawTime, splinePosition), this.getRotationQuatRaw(rawTime, splineRotationQuat));
        return out;
    }

    public getClosestTime(position: Vector3): number {
        return this.getClosestTimeAroundTime(position, 0.5, 0.5);
    }

    public getClosestTimeAroundTime(position: Vector3, referenceTime: number, maxTimeDistance: number): number {
        let closestTime = 0;

        // Adjusting the prcentage so that it perfectly ends where it starts
        const stepsAmount = Math.round(maxTimeDistance * 2 / this._params.searchStepLengthPercentage);
        const adjustedSearchStepLengthPercentage = maxTimeDistance * 2 / stepsAmount;

        let minDistanceToSpline = -1;
        const splinePosition = Spline._SV.position;
        for (let i = 0; i <= stepsAmount; i++) {
            const currentTime = this.clampTime(i * adjustedSearchStepLengthPercentage + referenceTime - maxTimeDistance);

            this.getPosition(currentTime, splinePosition);

            const distanceToSpline = Vec3Utils.distanceSquared(splinePosition, position);
            if (minDistanceToSpline < 0 || distanceToSpline < minDistanceToSpline) {
                minDistanceToSpline = distanceToSpline;
                closestTime = currentTime;
            }
        }

        return closestTime;
    }

    /** Raw means unprocessed, which means it's not evenly distributed */
    public getPositionRaw(time: number, out: Vector3 = vec3_create()): Vector3 {
        const adjustedTime = SplineUtils.clampTimeCircular(this._directionRaw * (this._params.isLoop ? time : this.clampTime(time)) + this._startTimeOffsetRaw);

        const splinePoints = this._params.splinePoints;

        const prevPointIndex = Math.floor((splinePoints.length - 1) * adjustedTime);

        const prevPoint = splinePoints[prevPointIndex];
        const nextPoint = prevPointIndex + 1 < splinePoints.length - 1 ? splinePoints[prevPointIndex + 1] : splinePoints[0];

        const bezierCurveParams = Spline._SV.bezierCurveParams;
        bezierCurveParams.controlPoint0 = prevPoint.controlPoint;
        bezierCurveParams.controlPoint1 = prevPoint.controlHandleOut;
        bezierCurveParams.controlPoint2 = nextPoint.controlHandleIn;
        bezierCurveParams.controlPoint3 = nextPoint.controlPoint;

        const prevPointTime = prevPointIndex / (splinePoints.length - 1);
        const nextPointTime = (prevPointIndex + 1) / (splinePoints.length - 1);
        const normalizedTimeBetweenPrevAndNext = (adjustedTime - prevPointTime) / (nextPointTime - prevPointTime);

        SplineUtils.getBezierCurvePosition(bezierCurveParams, normalizedTimeBetweenPrevAndNext, out);
        return out;
    }

    /** Raw means unprocessed, which means it's not evenly distributed */
    public getForwardRaw(time: number, out: Vector3 = vec3_create()): Vector3 {
        const nextTime = time + 0.0001;

        const nextPosition = Spline._SV.position;
        this.getPositionRaw(time, out);
        this.getPositionRaw(nextTime, nextPosition);

        nextPosition.vec3_sub(out, out);
        out.vec3_normalize(out);
        return out;
    }

    /** Raw means unprocessed, which means it's not evenly distributed */
    public getRotationQuatRaw(time: number, out: Quaternion = quat_create()): Quaternion {
        const forward = Spline._SV.forward;
        this.getForwardRaw(time, forward);
        out.quat_setForward(forward, this._params.defaultUp);
        return out;
    }

    /** Raw means unprocessed, which means it's not evenly distributed */
    public getClosestTimeRaw(position: Vector3): number {
        return this.convertTimeToRawTime(this.getClosestTime(position));
    }

    public convertTimeToRawTime(time: number): number {
        const adjustedTime = this.clampTime(time);
        const targetLength = adjustedTime * this._length;

        // Binary search to find the closest arc to the given time
        let lowArcIndex = 0;
        let highArcIndex = this._arcLengths.length - 1;
        while (highArcIndex - lowArcIndex > 1) {
            const halfIndex = lowArcIndex + Math.floor((highArcIndex - lowArcIndex) / 2);
            if (this._arcLengths[halfIndex] == targetLength) {
                lowArcIndex = halfIndex;
                highArcIndex = halfIndex;
                break;
            } else if (this._arcLengths[halfIndex] < targetLength) {
                lowArcIndex = halfIndex;
            } else {
                highArcIndex = halfIndex;
            }
        }

        // Prefer the closest index before the actual target length
        const bestArcIndex = lowArcIndex;

        let rawTime = 0;

        const bestArcLength = this._arcLengths[bestArcIndex];
        if (bestArcLength === targetLength) {
            rawTime = bestArcIndex / (this._arcLengths.length - 1);
        } else {
            rawTime = (bestArcIndex + (targetLength - bestArcLength) / (this._arcLengths[bestArcIndex + 1] - bestArcLength)) / (this._arcLengths.length - 1);
        }

        return this.clampTime(rawTime);
    }

    public convertRawTimeToTime(time: number): number {
        const position = Spline._SV.position;
        this.getPositionRaw(time);
        return this.getClosestTime(position);
    }

    public getTimeByDistance(distanceFromStart: number): number {
        if (this._length == 0) {
            return 0;
        }

        return this.clampTime(distanceFromStart / this._length);
    }

    public getLength(time: number = 1): number {
        const adjustedTime = this.clampTime(time);
        return this._length * adjustedTime;
    }

    public getLengthRaw(time: number = 1): number {
        const adjustedTime = this.clampTime(time);
        if (adjustedTime == 1) {
            return this._length;
        }

        return this.getLength(this.convertRawTimeToTime(adjustedTime));
    }

    public clampTime(time: number): number {
        if (this._params.isLoop) {
            return SplineUtils.clampTimeCircular(time);
        }

        return MathUtils.clamp(time, 0, 1);
    }

    public getClosestTimeDifference(first: number, second: number): number {
        return SplineUtils.getClosestTimeDifferenceCircular(first, second);
    }

    public setStartClosestToPosition(position: Vector3): void {
        const oldArcLengths = this._arcLengths;

        this._arcLengths = this._arcLengthsRaw;
        this._length = this._lengthRaw;
        this._startTimeOffsetRaw = 0;
        this._directionRaw = SplineDirection.Forward;

        this._params.startTimeOffset = this.getClosestTime(position);
        this._startTimeOffsetRaw = this.convertTimeToRawTime(this._params.startTimeOffset);
        this._directionRaw = this._params.direction;

        this._arcLengths = oldArcLengths;
        this._length = 0;
        this._computeArcLengths();
    }

    public setDirectionClosestToForward(forward: Quaternion2): void {
        const startPosition = this.getPosition(0);
        const pastStartPosition = startPosition.vec3_add(forward.vec3_scale(this._length * this._params.searchStepLengthPercentage * 10));
        const pastStartPositionTime = this.getClosestTime(pastStartPosition);

        let splineDirection = this._params.direction;
        if (pastStartPositionTime > 0.5 || (!this._params.isLoop && pastStartPositionTime == 0)) {
            switch (this._params.direction) {
                case SplineDirection.Backward:
                    splineDirection = SplineDirection.Forward;
                    break;
                case SplineDirection.Forward:
                    splineDirection = SplineDirection.Backward;
                    break;
            }
        }

        this._params.direction = splineDirection;
        this._directionRaw = this._params.direction;

        this._computeArcLengths();
    }

    public debugDraw(): void {
        const pointsAmount = 300;

        const position = Spline._SV.position;
        const forward = Spline._SV.forward;
        const color = Spline._SV.color;

        for (let i = 0; i <= pointsAmount; i++) {
            this.getPosition(i / pointsAmount, position);
            color.vec4_set(1 - (i / pointsAmount) * 0.5, 1 - (i / pointsAmount) * 0.5, 1 - (i / pointsAmount) * 0.5, 1);
            Globals.getDebugVisualManager()!.drawPoint(0, position, color, 1);

            if (HoverboardDebugs.showSplineForward) {
                this.getForward(i / pointsAmount, forward);
                color.vec4_set(1 - (i / pointsAmount) * 0.5, 1 - (i / pointsAmount) * 0.5, 1 - (i / pointsAmount) * 0.5, 1);
                Globals.getDebugVisualManager()!.drawArrow(0, position, forward, 3, color, 0.25);
            }
        }

        color.vec4_set(1, 0, 0, 1);
        this.getPosition(0, position);
        Globals.getDebugVisualManager()!.drawPoint(0, position, color, 2);
    }

    /** Needed for even spline point distribution */
    private _computeArcLengths(): void {
        const prevPosition = Spline._SV.prevPosition;
        const currentPosition = Spline._SV.position;

        this._arcLengths.pp_clear();

        if (this._params.splinePoints.length <= 1) {
            this._arcLengths.push(0);
            this._length = 0;
            return;
        }

        this.getPositionRaw(0, prevPosition);

        let currentDistanceFromStart = 0;
        this._arcLengths.push(0);

        // Adjusting the prcentage so that it perfectly ends where it starts
        const arcsAmount = Math.round(1 / this._params.arcLengthPercentage);
        const adjustedArcsLengthPercentage = 1 / arcsAmount;
        for (let i = 1; i <= arcsAmount; i++) {
            if (i == arcsAmount) {
                this.getPositionRaw(1, currentPosition);
            } else {
                this.getPositionRaw(i * adjustedArcsLengthPercentage, currentPosition);
            }

            currentDistanceFromStart += currentPosition.vec3_distance(prevPosition);
            this._arcLengths.push(currentDistanceFromStart);

            prevPosition.vec3_copy(currentPosition);
        }

        this._length = currentDistanceFromStart;
    }
}