import { computeBinomialCoefficient } from "src/hoverfit/utils/math-utils.js";
import { MathUtils, Vector3, vec3_create } from "wle-pp";

export class BezierCurveParams {
    public controlPoint0: Vector3 = vec3_create();
    public controlPoint1: Vector3 = vec3_create();
    public controlPoint2: Vector3 = vec3_create();
    public controlPoint3: Vector3 = vec3_create();
}

export function clampTimeCircular(splineTime: number): number {
    // Early return, otherwise when the time is exactly 1 is automatically converted to 0
    if (splineTime % 2 == 1) return 1;

    let adjustedSplineTime = splineTime % 1;
    if (adjustedSplineTime < 0) {
        adjustedSplineTime += 1;
    }

    return adjustedSplineTime;
}

export function getClosestTimeDifferenceCircular(first: number, second: number): number {
    let closestTifference = second - first;

    if (Math.abs(closestTifference) > 0.5) {
        closestTifference = -MathUtils.sign(closestTifference) * (1 - Math.abs(closestTifference));
    }

    return closestTifference;
}

export const getBezierCurvePosition = function () {
    const weightedPoint = vec3_create();
    const controlPoints: Vector3[] = new Array(4);
    return function getBezierCurvePosition(bazierCurveParams: BezierCurveParams, time: number, out: Vector3 = vec3_create(),) {
        out.vec3_zero();

        controlPoints[0] = bazierCurveParams.controlPoint0;
        controlPoints[1] = bazierCurveParams.controlPoint1;
        controlPoints[2] = bazierCurveParams.controlPoint2;
        controlPoints[3] = bazierCurveParams.controlPoint3;

        for (let i = 0; i < 4; i++) {
            const binomialCoefficient = computeBinomialCoefficient(3, i);
            const adjustedTime = Math.pow(time, i) * Math.pow(1 - time, 3 - i);
            const controlPointWeight = binomialCoefficient * adjustedTime;

            controlPoints[i].vec3_scale(controlPointWeight, weightedPoint);
            out.vec3_add(weightedPoint, out);
        }

        return out;
    };
}();

export const SplineUtils = {
    clampTimeCircular,
    getClosestTimeDifferenceCircular,
    getBezierCurvePosition
} as const;