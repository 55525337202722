import { Vector3 } from "wle-pp";
import { EncryptedAudioComponent } from "../../encrypted-audio/components/encrypted-audio-component.js";
import { SinkedAudio, SourceSink } from "../sinked-audio.js";
import { TypedAudio } from "./typed-audio.js";

export class EncryptedAudio extends TypedAudio<EncryptedAudioComponent> implements SinkedAudio {

    play(): void {
        this.getTypedSourceAudio().play();
    }

    stop(): void {
        this.getTypedSourceAudio().stop();
    }

    pause(): void {
        this.getTypedSourceAudio().pause();
    }

    resume(): void {
        this.getTypedSourceAudio().playPause();
    }

    isPlaying(): boolean {
        return this.getTypedSourceAudio().playing;
    }

    setPosition(position: Vector3, updateOnlyLastAudio: boolean = false): void {
        throw new Error("Method not implemented.");
    }

    setVolume(volume: number, updateOnlyLastAudio: boolean = false): void {
        if (updateOnlyLastAudio) {
            throw new Error("Parameter updateOnlyLastAudio not implemented.");
        }

        this.getTypedSourceAudio().volume = volume;
    }

    getVolume(): number {
        return this.getTypedSourceAudio().volume;
    }

    fade(fromVolume: number, toVolume: number, durationSeconds: number): void {
        throw new Error("Method not implemented.");
    }

    setPitch(pitch: number, updateOnlyLastAudio: boolean = false): void {
        throw new Error("Method not implemented.");
    }

    getSink(): SourceSink | null {
        return this.getTypedSourceAudio().sink;
    }
}