import { Vector3 } from "wle-pp";
import { SourceAudio, SourceAudioType } from "./source-audio.js";

export abstract class Audio {
    private _defaultVolume = 1;

    abstract getSourceAudio(): SourceAudio;
    abstract getSourceAudioType(): SourceAudioType;

    abstract play(): void;
    abstract stop(): void;
    abstract pause(): void;
    abstract resume(): void;

    abstract isPlaying(): boolean;

    abstract setPosition(position: Vector3, updateOnlyLastAudio?: boolean): void;

    setDefaultVolume(volume: number): void {
        this._defaultVolume = volume;
    }

    getDefaultVolume(): number {
        return this._defaultVolume;
    }

    abstract setVolume(volume: number, updateOnlyLastAudio?: boolean): void;
    abstract getVolume(): number;
    abstract fade(fromVolume: number, toVolume: number, durationSeconds: number): void;

    abstract setPitch(pitch: number, updateOnlyLastAudio?: boolean): void;
}