import { FocusType, LeaveEvent, PassthroughWidget, SingleParentXMLInputConfig, type Widget, type WidgetAutoXML, type WidgetEvent, type WidgetProperties } from "lazy-widgets";

function isWidgetDescendantOf(widget: Widget, ascendant: Widget): boolean {
    for (let focus: Widget | null = widget; focus; focus = focus.parent) {
        if (focus === ascendant) return true;
    }

    return false;
}

interface EventFilterWidgetProperties extends WidgetProperties {
    filterEnabled?: boolean;
}

/**
 * Prevents all events from propagating to the descendant widgets if
 * {@link EventFilterWidget#filterEnabled} is true. Normally you would never
 * need this, but if you want to prevent keyboard input from controlling widgets
 * that are obscured, but still enabled and attached to the UI tree (such as
 * when doing popups), then this is for you.
 */
export class EventFilterWidget<W extends Widget = Widget> extends PassthroughWidget<W> {
    static override autoXML: WidgetAutoXML = {
        name: "event-filter",
        inputConfig: SingleParentXMLInputConfig
    };

    private _filterEnabled = false;

    constructor(child: W, properties?: Readonly<EventFilterWidgetProperties>) {
        super(child, properties);
        this._filterEnabled = properties?.filterEnabled ?? true;
    }

    get filterEnabled() {
        return this._filterEnabled;
    }

    set filterEnabled(filterEnabled: boolean) {
        if (this._filterEnabled === filterEnabled) return;
        this._filterEnabled = filterEnabled;
        if (!filterEnabled) return;
        const root = this._root;
        if (!root) return;

        for (const focusType of [FocusType.Keyboard, FocusType.Pointer, FocusType.Tab]) {
            const widget = root.getFocusCapturer(focusType);
            if (!widget) continue;
            if (isWidgetDescendantOf(widget, this)) root.clearFocus(focusType);
        }
    }

    protected override handleEvent(event: WidgetEvent): Widget | null {
        if (this._filterEnabled && !event.isa(LeaveEvent)) return null;
        return super.handleEvent(event);
    }
}