import { Component, MeshComponent, Property } from "@wonderlandengine/api";
import { CursorTarget } from "@wonderlandengine/components";
import { Vec3Utils, vec3_create } from "wle-pp";

/**
 * scale-on-hover
 */
export class ScaleOnHoverComponent extends Component {
    static TypeName = "scale-on-hover";
    static Properties = {
        scaleMultiplierOnHover: Property.float(1.3),
        scaleSpeed: Property.float(1.0),
        applyOnFirstMesh: Property.bool(true)
    };

    start() {
        this.scaling = false;
        this.t = 0;
        this.currentScale = vec3_create();
        this.normalScale = vec3_create();
        this.targetScale = vec3_create();

        this.objectToScale = this.object;
        if (this.applyOnFirstMesh) {
            this.objectToScale = this.object.pp_getComponent(MeshComponent).object;
        }

        // Set scale for unhovered state
        this.objectToScale.getScalingLocal(this.normalScale);

        // Set scale for hovered state
        this.currentScale.set(this.normalScale);
        Vec3Utils.scale(this.normalScale, this.scaleMultiplierOnHover, this.targetScale);

        let cursorTarget = this.object.pp_getComponent(CursorTarget);
        cursorTarget.onHover.add(() => {
            this._onHover();
        });
        cursorTarget.onUnhover.add(() => {
            this._onUnhover();
        });
    }

    _onHover() {
        this.hovering = true;
    }

    _onUnhover() {
        this.hovering = false;
    }

    _setScale() {
        Vec3Utils.lerp(this.normalScale, this.targetScale, this.t, this.currentScale);
        this.objectToScale.setScalingLocal(this.currentScale);
    }

    update(dt) {
        if (this.hovering && this.t < 1.0) {
            this.t = Math.min(1.0, this.t + dt * this.scaleSpeed);
            this._setScale();
        } else if (!this.hovering && this.t > 0.0) {
            this.t = Math.max(0.0, this.t - dt * this.scaleSpeed);
            this._setScale();
        }
    }
}
