import { Component, Material } from "@wonderlandengine/api";
import { property } from "@wonderlandengine/api/decorators.js";
import { vec4 } from "gl-matrix";
import { EasingFunction, Timer, Vector4, vec4_create } from "wle-pp";

/**
 * Periodically fades two flat materials between two colors
 */
export class MaterialColorFaderComponent extends Component {
    static override TypeName = "material-color-fader";

    @property.material()
    material!: Material;

    @property.color()
    firstColor!: Vector4;

    @property.color()
    secondColor!: Vector4;

    @property.enum(["Color", "Diffuse Color", "Ambient Color", "Specular Color", "Emissive Color", "Fog Color"], "Color")
    colorProperty!: number;

    @property.float(1.0)
    timeToFade!: number;

    @property.enum(["Linear", "Ease In/Out", "Ease In", "Ease Out"], "Linear")
    easingType!: number;

    private _fadeTimer: Timer = new Timer(0);
    private _isFadingToSecond: boolean = true;

    private _easingFunction: EasingFunction = EasingFunction.linear;

    private static _colorPropertyNames = ["color", "diffuseColor", "ambientColor", "specularColor", "emissiveColor", "fogColor"];

    init() {
        switch (this.easingType) {
            case 0:
                this._easingFunction = EasingFunction.linear;
                break;
            case 1:
                this._easingFunction = EasingFunction.easeInOut;
                break;
            case 2:
                this._easingFunction = EasingFunction.easeIn;
                break;
            case 3:
                this._easingFunction = EasingFunction.easeOut;
                break;
        }

        this._fadeTimer.start(this.timeToFade);
    }

    private static readonly _updateSV =
        {
            fadeColor: vec4_create()
        };
    update(dt: number) {
        this._fadeTimer.update(dt);
        if (this._fadeTimer.isDone()) {
            this._fadeTimer.start();
            this._isFadingToSecond = !this._isFadingToSecond;
        }

        let interpolationFactor = this._easingFunction(this._fadeTimer.getPercentage());
        if (!this._isFadingToSecond) {
            interpolationFactor = 1 - interpolationFactor;
        }

        const fadeColor = MaterialColorFaderComponent._updateSV.fadeColor;
        vec4.lerp(fadeColor as vec4, this.firstColor as vec4, this.secondColor as vec4, interpolationFactor);

        (this.material as unknown as Record<string, Vector4>)[MaterialColorFaderComponent._colorPropertyNames[this.colorProperty]] = fadeColor;
    }
}
