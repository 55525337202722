import { Object3D, TextComponent } from "@wonderlandengine/api";
import { NumberOverFactor } from "wle-pp";
import { PriorityLevel } from "../../../misc/data-structs/expiring-priority-queue.js";
import { PopupParams, PopupResources, PopupType, PopupWindowImplementation, PopupWindowParams } from "../popup.js";

export class MessagePopupWindowParams extends PopupWindowParams {
    message: string = "";
}

export class MessagePopupParams extends PopupParams<MessagePopupWindowParams> {
    readonly popupType: PopupType = PopupType.Message;

    popupWindowParams: MessagePopupWindowParams = new MessagePopupWindowParams();

    delaySeconds: number | NumberOverFactor = 0;
    durationSeconds: number | NumberOverFactor = new NumberOverFactor(3, 0.5, 0, 6);
    showSeconds: number | NumberOverFactor = new NumberOverFactor(1, 0.25, 0, 4);
    hideSeconds: number | NumberOverFactor = new NumberOverFactor(1, 0.25, 0, 4);

    gamepadPulseParam: { intensity: number, duration: number } = { intensity: 0.3, duration: 0.1 };

    constructor() {
        super();

        this.priorityParams.priorityLevel = PriorityLevel.Middle;
        this.priorityParams.expireSeconds = 10;
    }
}

export class MessagePopupWindow extends PopupWindowImplementation<MessagePopupWindowParams> {
    private _messageTextComponent: TextComponent;

    constructor(object: Object3D) {
        super(object);

        this._messageTextComponent = this._visualObject.pp_getObjectByName("Message")!.getComponent(TextComponent) as TextComponent;
        this._messageTextComponent.text = "";
    }

    override prepare(params: MessagePopupWindowParams, resources: PopupResources): void {
        super.prepare(params, resources);

        this._messageTextComponent.text = params.message;
    }
}