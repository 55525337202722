import { type ItemNamespace } from "../asset-provision/shop-item.js";
import { ObservableItemIDCollection, ObservableItemIDCollectionEventType } from "./observable-item-id-collection.js";

/** An observable ordered set of item IDs, mapped to an arbitrary value */
export class ObservableItemIDMap<V> extends ObservableItemIDCollection {
    private readonly values = new Map<string, V>();

    get(id: string): V | undefined {
        if (this._suppressed) return undefined;
        return this.values.get(id);
    }

    getRequired(id: string): V {
        if (this._suppressed) {
            throw new Error(`Can't get item with ID "${id}"; list is suppressed`);
        }

        const value = this.values.get(id);
        if (value === undefined) {
            throw new Error(`No such item with ID "${id}"`);
        }

        return value;
    }

    override has(id: string) {
        if (this._suppressed) return false;
        return this.values.has(id);
    }

    add(id: string, value: V) {
        if (this.has(id)) return false;
        this.ids.push(id);
        this.values.set(id, value);
        this.notify(ObservableItemIDCollectionEventType.Add, [id]);
        return true;
    }

    addMany(ids: ReadonlyArray<readonly [id: string, value: V]>) {
        const added: string[] = [];
        for (let i = ids.length - 1; i >= 0; i--) {
            const idValuePair = ids[i];
            const id = idValuePair[0];
            if (this.has(id)) continue;
            this.ids.push(id);
            this.values.set(id, idValuePair[1]);
            added.push(id);
        }

        if (added.length > 0) {
            this.notify(ObservableItemIDCollectionEventType.Add, added);
        }
    }

    addShort(shortID: string, namespace: ItemNamespace, value: V) {
        this.add(`${namespace}:${shortID}`, value);
    }

    addManyShort(shortIDs: ReadonlyArray<readonly [shortID: string, value: V]>, namespace: ItemNamespace) {
        const added: string[] = [];
        for (let i = shortIDs.length - 1; i >= 0; i--) {
            const idValuePair = shortIDs[i];
            const id = `${namespace}:${idValuePair[0]}`;
            if (this.has(id)) continue;
            this.ids.push(id);
            this.values.set(id, idValuePair[1]);
            added.push(id);
        }

        if (added.length > 0) {
            this.notify(ObservableItemIDCollectionEventType.Add, added);
        }
    }

    remove(id: string) {
        const i = this.ids.indexOf(id);
        if (i < 0) return false;
        this.ids.splice(i, 1);
        this.values.delete(id);
        this.notify(ObservableItemIDCollectionEventType.Remove, [id]);
        return true;
    }

    removeMany(ids: ReadonlyArray<string>) {
        const removed: string[] = [];
        for (let i = ids.length - 1; i >= 0; i--) {
            const id = ids[i];
            const idx = this.ids.indexOf(id);
            if (idx < 0) continue;
            this.ids.splice(idx, 1);
            this.values.delete(id);
            removed.push(id);
        }

        if (removed.length > 0) {
            this.notify(ObservableItemIDCollectionEventType.Remove, removed);
        }
    }

    removeNamespace(namespace: ItemNamespace) {
        const prefix = `${namespace}:`;
        const removed: string[] = [];
        for (let i = this.ids.length - 1; i >= 0; i--) {
            const id = this.ids[i];
            if (!id.startsWith(prefix)) continue;
            this.ids.splice(i, 1);
            this.values.delete(id);
            removed.push(id);
        }

        if (removed.length > 0) {
            this.notify(ObservableItemIDCollectionEventType.Remove, removed);
        }
    }
}