import { PassthroughWidget, SingleParentXMLInputConfig } from "lazy-widgets";

export class FilterContainer extends PassthroughWidget {
    static autoXML = {
        name: "filter-container",
        inputConfig: SingleParentXMLInputConfig
    };

    _filter = "";

    constructor(child, properties) {
        super(child, properties);

        this._filter = properties?.filter ?? "";
    }

    get filter() {
        return this._filter;
    }

    set filter(filter) {
        if (this._filter !== filter) {
            this._filter = filter;
            this.markWholeAsDirty();
        }
    }

    handlePainting(dirtyRects) {
        const wantedFilter = this._filter;
        const hasFilter = wantedFilter !== "";
        const ctx = this.viewport.context;
        const isCSSFilter = (typeof wantedFilter) === "string";

        if (hasFilter && isCSSFilter) {
            ctx.save();

            ctx.filter = wantedFilter;
            ctx.rect(this.x, this.y, this.width, this.height);
            // XXX need to clip because the blur effect will result in rendering
            //     outside a widget's bounds
            ctx.clip();
        }

        super.handlePainting(dirtyRects);

        if (hasFilter) {
            if (isCSSFilter) {
                ctx.restore();
            } else {
                ctx.save();
                ctx.fillStyle = `rgba(${wantedFilter[0]} ${wantedFilter[1]} ${wantedFilter[2]} / ${wantedFilter[3]})`;
                ctx.fillRect(this.x, this.y, this.width, this.height);
                ctx.restore();
            }
        }
    }
}