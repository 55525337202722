import { Alignment, Background, FlexAlignment, Icon, LiveLabel, ObservableTransformer, RoundedCorners, Row, TextAlignMode, filterIDFromProperties, type Observable, type WidgetAutoXML, type WidgetProperties } from "lazy-widgets";

export interface ScoreRowProperties extends WidgetProperties {
    dark?: boolean;
}

export class ScoreRow extends Row {
    static override autoXML: WidgetAutoXML = {
        name: "score-row",
        inputConfig: [
            {
                name: "fit-points",
                mode: "value",
                validator: "observable",
            },
            {
                name: "fitabux",
                mode: "value",
                validator: "observable",
            },
            {
                name: "heyvr-coins",
                mode: "value",
                validator: "observable",
            },
        ],
    };

    constructor(fitPoints: Observable<number>, fitabux: Observable<number>, heyvrCoins: Observable<number>, properties?: Readonly<ScoreRowProperties>) {
        const filteredProperties = filterIDFromProperties(properties);
        const dark = properties?.dark ?? false;

        function makeCard(source: Observable<number>, iconName: string) {
            return new RoundedCorners(
                new Background(
                    new Row([
                        new LiveLabel(
                            new ObservableTransformer([source], () => `${Math.floor(source.value)}`),
                            {
                                bodyTextAlign: TextAlignMode.End,
                                bodyTextFont: "1.5em sui-generis",
                                bodyTextHeight: 10, // HACK provides a better baseline for the text
                                bodyTextFill: "white",
                                ...filteredProperties,
                                flex: 1,
                            },
                        ),
                        new Icon(
                            `assets/textures/ui/icons/kiosk/${iconName}.svg`,
                            {
                                ...filteredProperties,
                                // XXX this is bigger than it fits, which is
                                //     on purpose, because it creates a
                                //     small space around the icon
                                //     horizontally
                                width: 24,
                                height: 24,
                            }
                        ),
                    ], {
                        multiContainerSpacing: 8,
                        multiContainerAlignment: {
                            main: FlexAlignment.SpaceAround,
                            cross: Alignment.Stretch,
                        },
                        ...filteredProperties,
                    }),
                    {
                        canvasFill: dark ? "rgba(0, 0, 0, 0.25)" : "rgba(255, 255, 255, 0.2)",
                        ...filteredProperties,
                        containerPadding: { top: 2, right: 2, bottom: 2, left: 2 },
                    },
                ),
                {
                    roundedCornersRadii: 3,
                    ...filteredProperties,
                    minWidth: 160, maxWidth: 160,
                    minHeight: 24, maxHeight: 24,
                },
            );
        }

        super([
            makeCard(fitPoints, "fitpoints"),
            makeCard(fitabux, "fitabux"),
            makeCard(heyvrCoins, "heyvr-coin"),
        ], {
            multiContainerAlignment: {
                main: FlexAlignment.SpaceBetween,
                cross: Alignment.Stretch,
            },
            ...properties,
        });
    }
}