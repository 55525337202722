import { Object3D } from "@wonderlandengine/api";
import common from "src/hoverfit/common.js";
import { SuperSpringStatusEffect, SuperSpringStatusEffectParams } from "src/hoverfit/game/hoverboard/status-effects/implementations/super-spring-status-effect.js";
import { StatusEffectTarget } from "src/hoverfit/game/hoverboard/status-effects/status-effect-target.js";
import { PickupGrabber } from "../pickup-grabber.js";
import { PickupType } from "../pickup.js";
import { SyncedPickup, SyncedPickupParams } from "./synced-pickup.js";

export class PickupSuperSpringParams extends SyncedPickupParams { }

export class PickupSuperSpring extends SyncedPickup {
    declare protected _params: PickupSuperSpringParams;

    constructor(pickupObject: Object3D, params: PickupSuperSpringParams) {
        super(PickupType.SuperSpring, pickupObject, params);
    }

    protected _onPickupHook(pickupGrabber: PickupGrabber) {
        const statusEffectsManager = common.tracksManager.getStatusEffectsManager();

        const statusEffectParams = new SuperSpringStatusEffectParams();
        const statusEffect = new SuperSpringStatusEffect(pickupGrabber as unknown as StatusEffectTarget, statusEffectParams);

        statusEffectsManager.addStatusEffect(statusEffect);
    }
}