import { TextAlignMode, TextHelper, Widget, WrapMode, type Observable, type Rect, type WidgetProperties } from "lazy-widgets";

export class LevelBar extends Widget {
    private leftText = new TextHelper();
    private rightText = new TextHelper();
    private percentInt = 0;
    private lastLeftNum = 0;
    private lastRightNum = 0;

    constructor(readonly variable: Observable<number>, properties?: WidgetProperties) {
        super(properties);

        this.leftText.text = "0";
        this.leftText.wrapMode = WrapMode.Shrink;
        this.leftText.alignMode = TextAlignMode.End;
        this.rightText.text = "0";
        this.rightText.wrapMode = WrapMode.Shrink;
        this.rightText.alignMode = TextAlignMode.Start;
    }

    protected override onThemeUpdated(property: string | null = null): void {
        super.onThemeUpdated(property);

        if (property === null ||
            property === "bodyTextFont" ||
            property === "bodyTextHeight") {
            this._layoutDirty = true;
            this.markWholeAsDirty();
        } else if (property === "bodyTextFill") {
            this.markWholeAsDirty();
        }
    }

    protected override handlePreLayoutUpdate(): void {
        // Update text helper variables
        const val = this.variable.value;
        const curLevel = Math.floor(val);
        const nextLevel = curLevel + 1;
        const percentInt = Math.floor((val - curLevel) * 100);

        if (this.lastLeftNum !== curLevel) {
            this.leftText.text = `${curLevel}`;
        }

        if (this.lastRightNum !== nextLevel) {
            this.rightText.text = `${nextLevel}`;
        }

        if (this.percentInt !== percentInt) {
            this.percentInt = percentInt;
            this.markWholeAsDirty();
        }

        this.leftText.font = this.bodyTextFont;
        this.leftText.lineHeight = this.bodyTextHeight;
        this.rightText.font = this.bodyTextFont;
        this.rightText.lineHeight = this.bodyTextHeight;

        // Mark as dirty if text helper is dirty
        const rightDirty = this.rightText.dirty; // HACK avoids short-circuit
        if (this.leftText.dirty || rightDirty) {
            this.markWholeAsDirty();
            this._layoutDirty = true;
        }
    }

    protected override handleResolveDimensions(minWidth: number, maxWidth: number, minHeight: number, maxHeight: number): void {
        const rightDirty = this.rightText.dirty; // HACK avoids short-circuit
        if (this.leftText.dirty || rightDirty) {
            this.markWholeAsDirty();
        }

        this.idealWidth = isFinite(maxWidth) ? maxWidth : minWidth;
        this.idealHeight = Math.max(Math.min(this.leftText.height + this.leftText.actualLineSpacing, maxHeight), minHeight);
    }

    protected override handlePainting(_dirtyRects: Array<Rect>): void {
        const ctx = this.viewport.context;
        const barLeft = this.x + this.width * 0.2;
        const barTop = this.y + this.height * 0.1;
        const barWidth = this.width * 0.6;
        const barHeight = this.height * 0.8;
        const textFill = this.bodyTextFill;
        const textTop = barTop + barHeight - this.leftText.height;

        ctx.strokeStyle = textFill;
        ctx.lineWidth = 0.5;
        ctx.strokeRect(barLeft, barTop, barWidth, barHeight);
        ctx.fillStyle = textFill;
        ctx.fillRect(barLeft, barTop, barWidth * this.percentInt * 0.01, barHeight);

        this.leftText.paint(ctx, textFill, barLeft - this.leftText.width - 4, textTop);
        this.rightText.paint(ctx, textFill, barLeft + barWidth + 4, textTop);
    }
}