import { Component, Material, Mesh, MeshComponent } from "@wonderlandengine/api";
import { property } from "@wonderlandengine/api/decorators.js";

export class TargetFramerateUserAgentFilteredComponent extends Component {
    static TypeName = "switch-material-user-agent-filtered";

    @property.string("Quest 2, PICO 4")
    _firstValidUserAgents!: string;

    @property.mesh()
    _firstMesh!: Mesh;

    @property.material()
    _firstMaterial!: Material;

    @property.string()
    _secondValidUserAgents!: string;

    @property.mesh()
    _secondMesh!: Mesh;

    @property.material()
    _secondMaterial!: Material;

    @property.string()
    _thirdValidUserAgents!: string;

    @property.mesh()
    _thirdMesh!: Mesh;

    @property.material()
    _thirdMaterial!: Material;

    start() {
        let done = false;

        const userAgent = window.navigator.userAgent;

        if (this._firstValidUserAgents.length > 0) {
            const firstValidUserAgents = this._getUserAgentsFromString(this._firstValidUserAgents);
            for (const validUserAgent of firstValidUserAgents) {
                if (userAgent.match(validUserAgent) != null) {
                    let meshToReplace = null;
                    if (this._firstMesh == null) {
                        meshToReplace = this.object.pp_getComponent(MeshComponent);
                    } else {
                        const meshes = this.object.pp_getComponents(MeshComponent);
                        for (const mesh of meshes) {
                            if (mesh.mesh?.equals(this._firstMesh)) {
                                meshToReplace = mesh;
                                break;
                            }
                        }
                    }

                    if (meshToReplace != null) {
                        meshToReplace.material = this._firstMaterial;
                        done = true;
                    }

                    break;
                }
            }
        }

        if (!done) {
            if (this._secondValidUserAgents.length > 0) {
                const secondValidUserAgents = this._getUserAgentsFromString(this._secondValidUserAgents);
                for (const validUserAgent of secondValidUserAgents) {
                    if (userAgent.match(validUserAgent) != null) {
                        let meshToReplace = null;
                        if (this._secondMesh == null) {
                            meshToReplace = this.object.pp_getComponent(MeshComponent);
                        } else {
                            const meshes = this.object.pp_getComponents(MeshComponent);
                            for (const mesh of meshes) {
                                if (mesh.mesh?.equals(this._secondMesh)) {
                                    meshToReplace = mesh;
                                    break;
                                }
                            }
                        }

                        if (meshToReplace != null) {
                            meshToReplace.material = this._secondMaterial;
                            done = true;
                        }

                        break;
                    }
                }
            }
        }

        if (!done) {
            if (this._thirdValidUserAgents.length > 0) {
                const thirdValidUserAgents = this._getUserAgentsFromString(this._thirdValidUserAgents);
                for (const validUserAgent of thirdValidUserAgents) {
                    if (userAgent.match(validUserAgent) != null) {
                        let meshToReplace = null;
                        if (this._thirdMesh == null) {
                            meshToReplace = this.object.pp_getComponent(MeshComponent);
                        } else {
                            const meshes = this.object.pp_getComponents(MeshComponent);
                            for (const mesh of meshes) {
                                if (mesh.mesh?.equals(this._thirdMesh)) {
                                    meshToReplace = mesh;
                                    break;
                                }
                            }
                        }

                        if (meshToReplace != null) {
                            meshToReplace.material = this._thirdMaterial;
                            done = true;
                        }

                        break;
                    }
                }
            }
        }
    }

    _getUserAgentsFromString(userAgentsString: string) {
        let userAgents: string[] = [];

        if (userAgentsString.length > 0) {
            userAgents = [...userAgentsString.split(",")];
            for (let i = 0; i < userAgents.length; i++) {
                userAgents[i] = userAgents[i].trim();
            }
        }

        return userAgents;
    }
}