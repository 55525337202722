import { ObservableItemIDList } from "../data-structs/observable-item-id-list.js";
import { ObservableItemIDMap } from "../data-structs/observable-item-id-map.js";
import { type AssetBase, type AssetManifest, type Bundle, type HairColorVariant, type HeadwearVariant, type HoverboardVariant, type SkinVariant, type SuitVariant } from "./asset-manifest-types.js";
import { type AssetProvider, ItemCategory, type ItemNamespace } from "./asset-provider.js";
import { ShopItem } from "./shop-item.js";

export class IAPContentController {
    private waitingManifests = 0;
    readonly inventory = new ObservableItemIDList(true);
    readonly catalog = new ObservableItemIDMap<ShopItem>(true);
    private readonly assets = new Map<string, Readonly<AssetBase>>();
    private readonly assetCategories = new Map<string, ItemCategory>();
    private readonly shopProviders: AssetProvider[] = [];
    private readonly readyQueue: (() => void)[] = [];

    constructor(shopProviderCtors: ReadonlyArray<new (controller: IAPContentController) => AssetProvider>) {
        for (const ctor of shopProviderCtors) {
            this.shopProviders.push(new ctor(this));
        }

        if (this.ready) this.onFinishLoadingManifests();
    }

    private onFinishLoadingManifests() {
        if (!this.inventory.suppressed) return;

        // we have to wait for all inventories so that we don't unequip
        // things that we own, however, we don't care if the catalog is not
        // loaded yet. we can handle the catalog asynchronously
        const invPromises: Promise<unknown>[] = [];
        for (let i = this.shopProviders.length - 1; i >= 0; i--) {
            invPromises.push(this.shopProviders[i].fetchInventory());
        }

        Promise.allSettled(invPromises).then(() => {
            this.inventory.unsuppress();
            this.catalog.unsuppress();
        });

        for (const callback of this.readyQueue) {
            try {
                callback();
            } catch(err) {
                console.error(err);
            }
        }

        this.readyQueue.length = 0;
    }

    private finishLoadingManifest() {
        if (--this.waitingManifests === 0) this.onFinishLoadingManifests();
    }

    get ready() {
        return this.waitingManifests === 0;
    }

    waitForReady() {
        return new Promise<void>((resolve, _reject) => {
            if (this.ready) {
                resolve();
            } else {
                this.readyQueue.push(resolve);
            }
        });
    }

    private loadManifestList<T extends AssetBase>(inManifestList: { [key: string]: Readonly<T> }, namespace: ItemNamespace, category: ItemCategory) {
        for (const shortID of Object.getOwnPropertyNames(inManifestList)) {
            const id = `${namespace}:${shortID}`;
            if (this.assets.has(id)) {
                console.error(`Ignored asset with ID "${id}"; ID already exists`);
                continue;
            }

            this.assets.set(id, inManifestList[shortID]);
            this.assetCategories.set(id, category);
        }
    }

    loadManifest(manifest: AssetManifest, namespace: ItemNamespace) {
        this.loadManifestList(manifest.hoverboard, namespace, ItemCategory.Hoverboard);
        this.loadManifestList(manifest.suit, namespace, ItemCategory.Suit);
        this.loadManifestList<HeadwearVariant>(manifest.headwear, namespace, ItemCategory.Headwear);
        this.loadManifestList(manifest.hairColor, namespace, ItemCategory.HairColor);
        this.loadManifestList(manifest.skin, namespace, ItemCategory.Skin);
        this.loadManifestList(manifest.bundle, namespace, ItemCategory.Bundle);
        // TODO locations
    }

    async fetchAndLoadManifest(url: string, namespace: ItemNamespace): Promise<AssetManifest> {
        this.waitingManifests++;
        let manifest: AssetManifest;

        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`Unexpected status code ${response.status} ${response.statusText}`);
            }
            manifest = await response.json() as AssetManifest;

            this.loadManifest(manifest, namespace);
        } finally {
            this.finishLoadingManifest();
        }

        return manifest;
    }

    getAsset(id: string, categoryHint: ItemCategory.Hoverboard): HoverboardVariant | undefined;
    getAsset(id: string, categoryHint: ItemCategory.Suit): SuitVariant | undefined;
    getAsset(id: string, categoryHint: ItemCategory.Headwear): HeadwearVariant | undefined;
    getAsset(id: string, categoryHint: ItemCategory.HairColor): HairColorVariant | undefined;
    getAsset(id: string, categoryHint: ItemCategory.Skin): SkinVariant | undefined;
    // getAsset(id: string, categoryHint: ItemCategory.Location): LocationVariant | undefined;
    getAsset(id: string, categoryHint: ItemCategory.Bundle): Bundle | undefined;
    getAsset(id: string, categoryHint?: ItemCategory | null): AssetBase | undefined;
    getAsset(id: string, categoryHint: ItemCategory | null = null): AssetBase | undefined {
        if (categoryHint !== null && this.getAssetClass(id) !== categoryHint) {
            return;
        }

        return this.assets.get(id);
    }

    getAssetRequired(id: string, categoryHint: ItemCategory.Hoverboard): HoverboardVariant;
    getAssetRequired(id: string, categoryHint: ItemCategory.Suit): SuitVariant;
    getAssetRequired(id: string, categoryHint: ItemCategory.Headwear): HeadwearVariant;
    getAssetRequired(id: string, categoryHint: ItemCategory.HairColor): HairColorVariant;
    getAssetRequired(id: string, categoryHint: ItemCategory.Skin): SkinVariant;
    // getAssetRequired(id: string, categoryHint: ItemCategory.Location): LocationVariant;
    getAssetRequired(id: string, categoryHint: ItemCategory.Bundle): Bundle;
    getAssetRequired(id: string, categoryHint?: ItemCategory): AssetBase;
    getAssetRequired(id: string, categoryHint?: ItemCategory) {
        const asset = this.getAsset(id, categoryHint);
        if (!asset) throw new Error(`No such asset with ID "${id}"`);
        return asset;
    }

    getAssetClass(id: string): ItemCategory | undefined {
        return this.assetCategories.get(id);
    }
}