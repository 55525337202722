import { Component } from "@wonderlandengine/api";
import { GameGlobals } from "src/hoverfit/misc/game-globals.js";

export class InvincibilityRingComponent extends Component {
    static TypeName = "invincibility-ring";

    start() {
        this.setVisible(false);
    }

    update(dt: number) {
        this.object.pp_rotateAxisObject(45 * dt, GameGlobals.up);
    }

    setVisible(visible: boolean) {
        this.object.pp_setActive(visible);
    }
}