import { GameConfigurationIdentifier } from '../config/game-configuration-types.js';
import { VALID_CONFIGURATIONS } from '../config/networking-constants.js';

export function isValidConfiguration(config: GameConfigurationIdentifier, ignoreLockState: boolean = false) {
    const locationConfig = VALID_CONFIGURATIONS.get(config.location);
    if (!locationConfig) return false;
    const modeConfig = locationConfig.modes.get(config.mode);
    if (!modeConfig) return false;
    const trackConfig = modeConfig.tracks[config.track];
    return !!trackConfig && (!trackConfig.locked || ignoreLockState);
}