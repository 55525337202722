import { Component, type Material, type Mesh } from "@wonderlandengine/api";
import { property } from "@wonderlandengine/api/decorators.js";
import common from "../../../common.js";

export class SceneSwitcherVariablesComponent extends Component {
    static override TypeName = "scene-switcher-variables";
    @property.material()
    textMaterial!: Material;
    @property.mesh()
    backgroundMesh!: Mesh;
    @property.material()
    backgroundMaterial!: Material;

    override init() {
        common.sceneSwitcherVars = this;
    }
}
