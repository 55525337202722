import { Label } from "lazy-widgets";

export class WarnLabel extends Label {
    static autoXML = {
        name: "warn-label",
        inputConfig: [
            {
                mode: "text",
                name: "text",
                optional: true
            }
        ]
    };

    constructor(text = "", properties) {
        super(text, {
            bodyTextFill: "orangered",
            bodyTextFont: "0.8em sui-generis",
            ...properties
        });
    }
}