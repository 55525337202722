import { AudioID } from "src/hoverfit/audio/audio-manager/audio-id.js";
import { StatusEffectTarget } from "../status-effect-target.js";
import { StatusEffectType } from "../status-effect.js";
import { ExpiringStatusEffect, ExpiringStatusEffectParams } from "./expiring-status-effect.js";

export class ShieldStatusEffectParams extends ExpiringStatusEffectParams {
    shieldsAmount: number = 1;

    sfxOnStart: AudioID | null = AudioID.STATUS_EFFECT_SHIELD;
}

export class ShieldStatusEffect extends ExpiringStatusEffect {

    declare protected _params: ShieldStatusEffectParams;

    constructor(target: StatusEffectTarget, params: ShieldStatusEffectParams) {
        super(StatusEffectType.Shield, target, params);
    }

    getParams(): ShieldStatusEffectParams {
        return this._params;
    }
}