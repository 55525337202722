import { Component, Object3D } from "@wonderlandengine/api";
import { property } from "@wonderlandengine/api/decorators.js";
import { Timer, Vector3, vec3_create } from "wle-pp";

export class FollowForwardComponent extends Component {
    static override TypeName = "follow-forward";

    @property.object()
    targetObject!: Object3D;

    @property.float(30)
    minAngleToStartFollowing!: number;

    @property.float(0.3)
    springStrength!: number;
    @property.float(0.125)
    springFriction!: number;

    @property.bool(true)
    preventBounceBack!: boolean;

    private _following: boolean = false;
    private _currentSpeed: number = 0;

    private _almostReached: boolean = false;

    private _currentTargetForward: Vector3 = vec3_create();

    private _timeBeforeStart: Timer = new Timer(2);
    private _timeBeforeStopFollowing: Timer = new Timer(0.25);

    private static readonly _almostReachedAngle: number = 5;

    sync(): void {
        this._following = false;
        this._currentSpeed = 0;

        this.object.pp_setUp(this.object.pp_getUp(), this.targetObject.pp_getForward());
    }

    private static readonly _updateSV =
        {
            currentForward: vec3_create(),
            currentUp: vec3_create(),
            targetForward: vec3_create()
        };
    override update(dt: number): void {
        const currentForward = FollowForwardComponent._updateSV.currentForward;
        this.object.pp_getForward(currentForward);

        const currentUp = FollowForwardComponent._updateSV.currentUp;
        this.object.pp_getUp(currentUp);

        const targetForward = FollowForwardComponent._updateSV.targetForward;
        this.targetObject.pp_getForward(targetForward);

        if (this._timeBeforeStart.isRunning()) {
            this._timeBeforeStart.update(dt);

            this.object.pp_setUp(currentUp, targetForward);
            return;
        }

        if (!this._following || !this._almostReached) {
            this._currentTargetForward.vec3_copy(targetForward);
        } else {
            const angleCurrentTargetToTarget = this._currentTargetForward.vec3_anglePivoted(targetForward, currentUp);
            if (angleCurrentTargetToTarget > FollowForwardComponent._almostReachedAngle) {
                this._almostReached = false;
                this._currentTargetForward.vec3_copy(targetForward);
            }
        }

        const angleToCurrentTarget = currentForward.vec3_anglePivotedSigned(this._currentTargetForward, currentUp);

        if (!this._following && Math.abs(angleToCurrentTarget) > this.minAngleToStartFollowing) {
            this._following = true;
            this._almostReached = false;
            this._currentSpeed = 0;
            this._timeBeforeStopFollowing.start();
        }

        if (this._following) {
            if (!this._almostReached && angleToCurrentTarget <= FollowForwardComponent._almostReachedAngle) {
                this._almostReached = true;
            }

            if (Math.abs(angleToCurrentTarget) < 0.01) {
                this._timeBeforeStopFollowing.update(dt);

                if (this._timeBeforeStopFollowing.isDone()) {
                    this._following = false;
                }

                this._currentSpeed = 0;
                this.object.pp_setUp(currentUp, this._currentTargetForward);
            } else {
                this._timeBeforeStopFollowing.start();

                this._currentSpeed += angleToCurrentTarget * this.springStrength;
                this._currentSpeed *= (1 - this.springFriction);

                let rotationToPerform = this._currentSpeed * dt;
                if (this.preventBounceBack) {
                    if (Math.sign(rotationToPerform) == Math.sign(angleToCurrentTarget)) {
                        rotationToPerform = Math.sign(angleToCurrentTarget) * Math.min(Math.abs(rotationToPerform), Math.abs(angleToCurrentTarget));
                    }
                }

                this.object.pp_rotateAxis(rotationToPerform, currentUp);
            }
        }
    }
}