import { Component, Property } from "@wonderlandengine/api";
import { Globals, PlayerLocomotionComponent, quat_create, vec3_create } from "wle-pp";
import common from "../../../common.js";
import { DisableFarObjectsComponent } from "../../../misc/components/enable/disable-far-objects-component.js";
import { HoverboardComponent } from "../../hoverboard/components/hoverboard-component.js";

export class BalconyComponent extends Component {
    static TypeName = "balcony";
    static Properties = {
        playerBalconyStartPosition: Property.object(),
    };

    init() {
        this.byeByePosition = vec3_create(0, -100000, 0);

        common.balcony = this;
    }

    start() {
        this.hoverboard = Globals.getRootObject(this.engine).pp_getComponent(HoverboardComponent);

        this.isStarted = false;

        this.isPlayerOnBalcony = false;
        this.balconyPosition = vec3_create();
        this.balconyForward = vec3_create();
        this.playerBalconyStartPosition.pp_getPosition(this.balconyPosition);
        this.playerBalconyStartPosition.pp_getForward(this.balconyForward);

        this.disableFarObjectsComponents = Globals.getRootObject(this.engine).pp_getComponents(DisableFarObjectsComponent);
    }

    get playerLocomotion() {
        if (this._playerLocomotion == null) {
            this._playerLocomotion = Globals.getPlayerObjects(this.engine).myPlayer.pp_getComponent(PlayerLocomotionComponent);
            this._playerLocomotion.getPlayerLocomotion().setIdle(true);
        }

        return this._playerLocomotion;
    }

    update(dt) {
    }

    moveToTrack(startingPosition = 0) {
        this.isPlayerOnBalcony = false;

        this.playerLocomotion.active = false;
        //Globals.getPlayerObjects(this.engine).myPlayer.pp_setForward(vec3_create(GameGlobals.right);

        this.hoverboard.prepareHoverboardToRace(startingPosition);

        for (let disableFarObjectsComponent of this.disableFarObjectsComponents) {
            disableFarObjectsComponent.disableObjects();
        }
    }

    moveToBalcony(teleportEvenIfAlreadyOnBalcony = false) {
        if (this.isPlayerOnBalcony && !teleportEvenIfAlreadyOnBalcony) return;
        this.isPlayerOnBalcony = true;

        this.hoverboard.stopHoverboard();

        this.playerLocomotion.active = true;
        const playerTransformManager = this.playerLocomotion.getPlayerLocomotion().getPlayerTransformManager();
        playerTransformManager.teleportPosition(this.balconyPosition, null, true);
        playerTransformManager.move(vec3_create(0, -10, 0)); // Snap on ground

        let rotation = quat_create();
        rotation.quat_setForward(this.balconyForward);
        playerTransformManager.setRotationQuat(rotation);

        playerTransformManager.resetReal(true, false, false, true);
        playerTransformManager.resetHeadToReal();

        for (let disableFarObjectsComponent of this.disableFarObjectsComponents) {
            disableFarObjectsComponent.disableObjects();
        }
    }

    getPlayerBalconyStartPosition() {
        return this.playerBalconyStartPosition;
    }
}
