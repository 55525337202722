import { Component } from '@wonderlandengine/api';
import { PickupSuperSpring, PickupSuperSpringParams } from '../implementations/pickup-super-spring.js';
import { PickupsManager } from '../pickups-manager.js';
import { PickupComponent } from './pickup-component.js';

export class PickupSuperSpringComponent extends Component implements PickupComponent {
    static TypeName = "pickup-super-spring";

    static onRegister() {
        PickupsManager.getPickupComponentTypes().pp_pushUnique(PickupSuperSpringComponent);
    }

    createPickup(): PickupSuperSpring {
        const params = new PickupSuperSpringParams();
        return new PickupSuperSpring(this.object, params);
    }
}