import { type CatalogItem, type heyVRSDK, type InventoryItem, type OnAuthMessage } from "@heyvr/sdk-types";
import { currentPlayerData } from "src/hoverfit/data/player-data.js";
import { unpackAssetShortID } from "src/hoverfit/utils/asset-utils.js";
import { wait } from "src/hoverfit/utils/wait.js";
import { type AssetBase, type AssetManifest } from "./asset-manifest-types.js";
import { AssetProvider, GenericPurchaseError, type ItemCategory, ItemCurrency, ItemNamespace, NoFundsPurchaseError } from "./asset-provider.js";
import { type IAPContentController } from "./iap-content-controller.js";
import { ShopItem } from "./shop-item.js";

interface MockShopConfig {
    catalog: CatalogItem[];
    inventory: string[];
    balance: number;
}

let mockConfig: MockShopConfig | undefined;

const IAP_ASSET_MANIFEST_URL = "assets/json/shop/iap-asset-manifest.json";

abstract class BaseHeyVRAssetProvider extends AssetProvider {
    protected readonly manifest: Promise<AssetManifest>;

    constructor(controller: IAPContentController) {
        super(controller);
        this.manifest = controller.fetchAndLoadManifest(IAP_ASSET_MANIFEST_URL, ItemNamespace.HeyVR);
    }

    protected async parseCatalog(catalog: readonly CatalogItem[]) {
        const manifest = await this.manifest;
        const added: Array<[id: string, item: HeyVRShopItem]> = [];

        for (const item of catalog) {
            const category = item.item_class as ItemCategory | null;
            if (!category) continue;
            const manifestCat = manifest[category];
            if (!manifestCat) continue;
            const asset = manifestCat[item.slug];
            if (!asset) continue;
            const heyVRShopItem = new HeyVRShopItem(this, asset, item);
            added.push([heyVRShopItem.id, heyVRShopItem]);
        }

        this.catalog.removeNamespace(ItemNamespace.HeyVR);
        this.catalog.addMany(added);
    }
}

export class MockAssetProvider extends BaseHeyVRAssetProvider {
    private _mockConfigPath = "assets/json/shop/mock-config.json";
    private _configPromise: Promise<MockShopConfig> | undefined;

    constructor(controller: IAPContentController) {
        super(controller);
        currentPlayerData.heyVRCoins = mockConfig ? mockConfig.balance : 0;

        wait(0.5).then(async () => {
            this.onUserLoggedIn();

            if (!mockConfig) mockConfig = await this.getConfig();

            if (mockConfig.catalog) {
                await this.parseCatalog(mockConfig.catalog);
            }
        });
    }

    private getConfig(): Promise<MockShopConfig> {
        if (this._configPromise) return this._configPromise;

        this._configPromise = fetch(this._mockConfigPath).then(response => {
            if (!response.ok) {
                throw new Error(response.statusText);
            }
            return response.json();
        });

        this._configPromise.then((cfg) => {
            currentPlayerData.heyVRCoins = cfg.balance;
        });

        return this._configPromise;
    }

    async fetchInventory(): Promise<void> {
        await wait(0.5);

        if (!mockConfig) mockConfig = await this.getConfig();

        if (mockConfig.inventory) {
            const added: string[] = [];
            const manifest = await this.manifest;
            for (const shortID of mockConfig.inventory) {
                unpackAssetShortID(added, manifest, shortID);
            }

            this.inventory.removeNamespace(ItemNamespace.HeyVR);
            this.inventory.addManyShort(added, ItemNamespace.HeyVR);
        }
    }

    async purchaseItem(shortID: string): Promise<boolean> {
        await wait(0.5);

        if (!mockConfig) mockConfig = await this.getConfig();

        let price: number = -1;
        for (const catItem of mockConfig.catalog) {
            if (catItem.slug === shortID) {
                price = catItem.price_discounted ?? catItem.price;
                break;
            }
        }

        if (price < 0) {
            throw new GenericPurchaseError(`Item with short ID "${shortID}" is not for sale`);
        }

        if (price > currentPlayerData.heyVRCoins) {
            throw new NoFundsPurchaseError(ItemCurrency.HeyVR);
        }

        currentPlayerData.heyVRCoins -= price;

        // For persistence through map reloads
        const added: string[] = [];
        unpackAssetShortID(added, await this.manifest, shortID);
        this.inventory.addManyShort(added, ItemNamespace.HeyVR);

        if (mockConfig) {
            mockConfig.inventory.push(shortID);
        }

        return true;
    }

    private onUserLoggedIn(): void {
        this.inventory.removeNamespace(ItemNamespace.HeyVR);
        this.fetchInventory();
    }
}

export class HeyVRAssetProvider extends BaseHeyVRAssetProvider {
    constructor(readonly heyVR: heyVRSDK, controller: IAPContentController) {
        super(controller);

        // User is logged in on page load
        this.heyVR.user.isLoggedIn().then((loggedIn: boolean) => {
            if (loggedIn) {
                this.onUserLoggedIn();
            }
        }).catch((err: Error) => {
            console.log("User not logged in: ", err);
        });

        this.heyVR.user.onAuthChange((payload: OnAuthMessage) => {
            if (payload.loggedIn) {
                this.onUserLoggedIn();
            } else {
                this.inventory.removeNamespace(ItemNamespace.HeyVR);
                currentPlayerData.heyVRCoins = 0;
            }
        });

        this.heyVR.inventory.getCatalog().then((catalog) => {
            this.parseCatalog(catalog);
        });
    }

    private async updateCoins(): Promise<void> {
        currentPlayerData.heyVRCoins = await this.heyVR.user.getAmountCoins();
    }

    override async fetchInventory(): Promise<void> {
        const inventoryItems: ReadonlyArray<InventoryItem> = await this.heyVR.inventory.get();

        this.inventory.removeNamespace(ItemNamespace.HeyVR);
        const added: string[] = [];
        const manifest = await this.manifest;

        for (const item of inventoryItems) {
            // FIXME somehow inventoryItems is an array of slug strings when using the heyvr sandbox
            unpackAssetShortID(added, manifest, item.slug);
        }

        this.inventory.removeNamespace(ItemNamespace.HeyVR);
        this.inventory.addManyShort(added, ItemNamespace.HeyVR);
    }

    private onUserLoggedIn(): void {
        this.inventory.removeNamespace(ItemNamespace.HeyVR);
        this.updateCoins();
        this.fetchInventory();
    }

    override async purchaseItem(shortID: string): Promise<boolean> {
        try {
            if (!await this.heyVR.inventory.purchase(shortID, 1)) return false;
            const added: string[] = [];
            unpackAssetShortID(added, await this.manifest, shortID);
            this.inventory.addManyShort(added, ItemNamespace.HeyVR);
            return true;
        } catch (err) {
            if (err && (err as any).status) {
                if ((err as any).status.debug === "err_not_enough_coins") {
                    throw new NoFundsPurchaseError(ItemCurrency.HeyVR);
                } else {
                    throw new GenericPurchaseError(`${(err as any).status.message}`);
                }
            } else {
                throw new GenericPurchaseError();
            }
        } finally {
            this.updateCoins();
        }
    }
}

export class HeyVRShopItem extends ShopItem {
    constructor(shopProvider: AssetProvider, asset: AssetBase, readonly catalogItem: Readonly<CatalogItem>) {
        super(shopProvider, asset);
    }

    get shortID() {
        return this.catalogItem.slug;
    }

    get namespace(): ItemNamespace.HeyVR {
        return ItemNamespace.HeyVR;
    }

    get itemCategory() {
        return this.catalogItem.item_class as ItemCategory;
    }

    get currencyType(): ItemCurrency.HeyVR {
        return ItemCurrency.HeyVR;
    }

    get price() {
        return this.catalogItem.price;
    }

    get priceDiscounted() {
        return this.catalogItem.price_discounted;
    }

    get description() {
        return super.description ?? this.catalogItem.description;
    }

    purchase() {
        return this.shopProvider.purchaseItem(this.shortID);
    }
}