import { Howl, Howler, Sound } from "howler";
import { Vector3 } from "wle-pp";
import { TypedAudio } from "./typed-audio.js";

class HowlerGlobalHoverfit {
    extensionApplied: boolean = false;
}

class HowlHoverfit {
    gainNode: GainNode | null = null;
}

declare module "howler" {
    interface HowlerGlobal {
        hoverfit: HowlerGlobalHoverfit;
    }

    interface Howl {
        _webAudio: boolean;
        _sounds: Sound[];

        hoverfit: HowlHoverfit;
    }

    class Sound {
        _node: GainNode;
        _parent: Howl;

        create: () => Sound;
    }
}

export class HowlerAudio extends TypedAudio<Howl> {

    play(): void {
        this.getTypedSourceAudio().play();
    }

    stop(): void {
        this.getTypedSourceAudio().stop();
    }

    pause(): void {
        this.getTypedSourceAudio().pause();
    }

    resume(): void {
        this.getTypedSourceAudio().play();
    }

    isPlaying(): boolean {
        const howl = this.getTypedSourceAudio();
        return howl.playing();
    }

    setPosition(position: Vector3, updateOnlyLastAudio: boolean = false): void {
        if (updateOnlyLastAudio) {
            throw new Error("Parameter updateOnlyLastAudio not implemented.");
        }

        const howl = this.getTypedSourceAudio();
        howl.pos(position[0], position[1], position[2]);
    }

    setVolume(volume: number, updateOnlyLastAudio: boolean = false): void {
        if (updateOnlyLastAudio) {
            throw new Error("Parameter updateOnlyLastAudio not implemented.");
        }

        const howl = this.getTypedSourceAudio();
        howl.volume(volume);
    }

    getVolume(): number {
        const howl = this.getTypedSourceAudio();
        return howl.volume();
    }

    fade(fromVolume: number, toVolume: number, durationSeconds: number): void {
        const howl = this.getTypedSourceAudio();
        howl.fade(fromVolume, toVolume, durationSeconds * 1000);
    }

    setPitch(pitch: number, updateOnlyLastAudio: boolean = false): void {
        if (updateOnlyLastAudio) {
            throw new Error("Parameter updateOnlyLastAudio not implemented.");
        }

        const howl = this.getTypedSourceAudio();
        howl.rate(pitch);
    }

    resetGainNode() {
        this.setGainNode(Howler.masterGain);
    }

    setGainNode(gainNode: GainNode) {
        const howl = this.getTypedSourceAudio();

        if (howl.hoverfit == null) {
            howl.hoverfit = new HowlHoverfit();
        }

        if (howl._webAudio) {
            for (const sound of howl._sounds) {
                sound._node.disconnect(howl.hoverfit.gainNode == null ? Howler.masterGain : gainNode);
                sound._node.connect(gainNode);
            }
        }

        if (gainNode != Howler.masterGain) {
            howl.hoverfit.gainNode = gainNode;
        } else {
            howl.hoverfit.gainNode = null;
        }
    }
}

export function applyHowlerVolumeMixerExtension(): void {
    if (!Howler.hoverfit?.extensionApplied) {

        Sound.prototype.create = function (_super: () => Sound) {
            return function (this: Sound) {
                const returnValue = _super.call(this);

                const parent = this._parent;
                if (parent._webAudio && parent?.hoverfit?.gainNode != null) {
                    this._node.disconnect(Howler.masterGain);
                    this._node.connect(parent.hoverfit.gainNode);
                }

                return returnValue;
            };
        }(Sound.prototype.create);

        if (Howler.hoverfit == null) {
            Howler.hoverfit = new HowlerGlobalHoverfit();
        }

        Howler.hoverfit.extensionApplied = true;
    }
}