import { type GameConfigurationIdentifier } from '../config/game-configuration-types.js';
import { VALID_CONFIGURATIONS } from '../config/networking-constants.js';

/**
 * If the game configuration was changed, would a reload be required? If falsy,
 * then the configuration either already matches, or only a track change is
 * required; null is returned if the configuration already matches, and false is
 * returned if only the track was changed and no map change is required. If
 * true, then the mode/map changed, and a reload is required.
 */
export function gameConfigChangeRequiresReload(currentConfig: GameConfigurationIdentifier, newConfig: GameConfigurationIdentifier) {
    if (currentConfig.location !== newConfig.location || currentConfig.mode !== newConfig.mode) return true;
    if (currentConfig.track === newConfig.track) return null;

    const modeConfig = VALID_CONFIGURATIONS.get(currentConfig.location)!.modes.get(currentConfig.mode)!;
    const curTrackConfig = modeConfig.tracks[currentConfig.track];
    const newTrackConfig = modeConfig.tracks[newConfig.track];

    return curTrackConfig.map !== newTrackConfig.map;
}