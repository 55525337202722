import { MultiParent, MultiParentXMLInputConfig, PropagationModel, type Rect, type Widget, type WidgetAutoXML, type WidgetEvent, type WidgetProperties } from "lazy-widgets";

export interface BookProperties extends WidgetProperties {
    pageNames?: string[] | null;
}

export class Book<W extends Widget = Widget> extends MultiParent<W> {
    static autoXML: WidgetAutoXML = {
        name: "book",
        inputConfig: MultiParentXMLInputConfig
    };

    private pageNames: string[] | null;
    private currentPage = 0;

    constructor(pages: W[], properties?: Readonly<BookProperties>) {
        if (pages) {
            for (let i = 1; i < pages.length; i++) {
                pages[i].enabled = false;
            }
        }

        super(pages, properties);

        this.pageNames = properties?.pageNames ?? null;
    }

    changePage(newPage: string | number) {
        let i: number;
        if (typeof newPage === "string") {
            if (this.pageNames) {
                i = this.pageNames.indexOf(newPage);
            } else {
                throw new Error("Can't change page to named page; Book has no page names");
            }
        } else {
            i = newPage;
        }

        if (i !== this.currentPage) {
            this._children[this.currentPage].enabled = false;
            this._children[i].enabled = true;
            this.currentPage = i;
        }
    }

    isInPage(page: string | number) {
        if (this.pageNames && typeof page === "string") {
            page = this.pageNames.indexOf(page);
        }

        return page === this.currentPage;
    }

    get child() {
        return this._children[this.currentPage];
    }

    protected override handleEvent(event: WidgetEvent): Widget | null {
        if (event.propagation !== PropagationModel.Trickling) {
            return super.handleEvent(event);
        } else {
            return this.child.dispatchEvent(event);
        }
    }

    protected override handlePreLayoutUpdate(): void {
        // Pre-layout update child
        const child = this.child;
        child.preLayoutUpdate();

        // If child's layout is dirty, set self's layout as dirty
        if (child.layoutDirty) {
            this._layoutDirty = true;
        }
    }

    protected override handlePostLayoutUpdate(): void {
        // Post-layout update child
        this.child.postLayoutUpdate();
    }

    protected override handleResolveDimensions(minWidth: number, maxWidth: number, minHeight: number, maxHeight: number): void {
        // Resolve child's dimensions and set own resolved dimensions to be
        // equal to the child's
        const child = this.child;
        child.resolveDimensions(minWidth, maxWidth, minHeight, maxHeight);
        [this.idealWidth, this.idealHeight] = child.idealDimensions;
    }

    override resolvePosition(x: number, y: number): void {
        super.resolvePosition(x, y);

        // Resolve child's position to be the same as this widget's position
        this.child.resolvePosition(x, y);
    }

    protected override handlePainting(dirtyRects: Array<Rect>): void {
        // Paint child
        this.child.paint(dirtyRects);
    }
}