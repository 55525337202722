import { Alignment, Column, FlexAlignment, Icon, IconFit, Label, LiveLabel, ObservableTransformer, Row, Spacing, TextAlignMode, type Observable, type WidgetAutoXML, type WidgetProperties } from "lazy-widgets";
import { BackPane } from "../../lazy-widgets/widgets/back-pane.js";
import { DecoratedButtonBadge } from "../../lazy-widgets/widgets/base-decorated-button.js";
import { DecoratedButton } from "../../lazy-widgets/widgets/decorated-button.js";
import { LevelBar } from "./level-bar.js";

export const FP_PER_HELMET_LEVEL = 600;
export const FP_PER_SUIT_LEVEL = 1000;
export const FP_PER_BOARD_LEVEL = 2000;

export enum LifetimeLevelType {
    Helmet = "helmet",
    Suit = "suit",
    Board = "board",
}

export class LifetimeLevelDisplay extends Column {
    static override autoXML: WidgetAutoXML = {
        name: "lifetime-level-display",
        inputConfig: [
            {
                mode: "value",
                name: "type",
                validator: "string"
            },
            {
                mode: "value",
                name: "level",
                validator: "observable"
            },
        ]
    };

    private equipButton: DecoratedButton;
    private levelWatcher = () => {
        const clickable = this.level.value >= 1;
        this.equipButton.clickable = clickable;
        this.equipButton.badge = clickable ? DecoratedButtonBadge.Lock : null;
    };

    constructor(type: LifetimeLevelType, readonly level: Observable<number>, properties?: WidgetProperties) {
        const equipButton = new DecoratedButton("EQUIP", {
            minWidth: 70, maxWidth: 70, minHeight: 24, maxHeight: 24,
        });

        super([
            new Label(`LIFETIME ${type.toUpperCase()}`, {
                bodyTextAlign: TextAlignMode.Center,
            }),
            new Row([
                new BackPane(
                    new Column([
                        new Icon(`assets/textures/ui/icons/kiosk/rewards-${type}.svg`, {
                            fit: IconFit.Contain, width: 100, height: 70,
                        }),
                        new Label("LEVEL", {
                            bodyTextFont: "0.75em sui-generis",
                            bodyTextAlign: TextAlignMode.Center,
                        }),
                        new LevelBar(level, {
                            bodyTextFont: "0.75em sui-generis",
                        }),
                    ]),
                ),
            ], {
                multiContainerAlignment: {
                    main: FlexAlignment.SpaceAround, cross: Alignment.Stretch,
                },
            }),
            new Spacing({ minHeight: 16 }),
            new LiveLabel(new ObservableTransformer([level], () => {
                return `LEVEL ${Math.floor(level.value)}`;
            }), {
                bodyTextFont: "0.75em sui-generis",
                bodyTextAlign: TextAlignMode.Center,
            }),
            new Row([equipButton], {
                multiContainerAlignment: {
                    main: FlexAlignment.SpaceAround, cross: Alignment.Stretch,
                },
            })
        ], {
            flex: 1,
            ...properties,
        });

        this.equipButton = equipButton;
    }

    protected activate(): void {
        super.activate();
        this.level.watch(this.levelWatcher, true);
    }

    protected deactivate(): void {
        this.level.unwatch(this.levelWatcher);
        super.deactivate();
    }
}