import { Component } from "@wonderlandengine/api";
import { Globals, vec3_create } from "wle-pp";
import { GameGlobals } from "../../misc/game-globals.js";
import { VoipDebugMenuComponent } from "./voip-debug-menu-component.js";

export class SetVoIPDebugMenuTransformComponent extends Component {
    static TypeName = "set-voip-debug-menu-transform";
    static Properties = {};

    start() {
        this.voipDebugMenu = this.object.pp_getComponent(VoipDebugMenuComponent);
        this.currentEnabled = this.voipDebugMenu.isEnabled();

        this.positionLocalToPlayer = vec3_create();
    }

    update(dt) {
        // Implemented outside class definition
    }
}

SetVoIPDebugMenuTransformComponent.prototype.update = function () {
    let worldPosition = vec3_create();
    return function update(dt) {
        if (this.voipDebugMenu.isEnabled() != this.currentEnabled) {
            this.currentEnabled = this.voipDebugMenu.isEnabled();

            this.positionLocalToPlayer = Globals.getPlayerObjects().myHead.pp_getPosition();
            this.positionLocalToPlayer = Globals.getPlayerObjects().myPlayer.pp_convertPositionWorldToObject(this.positionLocalToPlayer, this.positionLocalToPlayer);
            this.positionLocalToPlayer = this.positionLocalToPlayer.vec3_removeComponentAlongAxis(GameGlobals.up, this.positionLocalToPlayer);

            this.object.pp_setUp(GameGlobals.up, Globals.getPlayerObjects().myHead.pp_getForward());
        }

        if (this.currentEnabled) {
            worldPosition = Globals.getPlayerObjects().myPlayer.pp_convertPositionObjectToWorld(this.positionLocalToPlayer, worldPosition);
            this.object.pp_setPosition(worldPosition);
        }
    };
}();