import { Component, MeshComponent, type Object3D } from "@wonderlandengine/api";
import { setMaterialFromMaterialConfig } from "src/hoverfit/misc/asset-loading.js";
import { type MaterialInterface } from "src/hoverfit/misc/asset-provision/asset-manifest-types.js";
import { ItemCategory } from "src/hoverfit/misc/asset-provision/asset-provider.js";
import { CancellablePromise, CancelledMarkerMap } from "src/hoverfit/utils/cancellable-promise.js";
import { Globals, quat2_create } from "wle-pp";
import common from "../../../common.js";
import { HoverboardComponent } from "./hoverboard-component.js";

const BOARD_MATERIAL_INDEX_TO_KEY = ["primaryMaterial", "secondaryMaterial", "tertiaryMaterial", "emissive"] as const;

const tempDualQuat = quat2_create();

export class HoverboardSelectorComponent extends Component {
    static TypeName = "hoverboard-selector";

    hoverboard!: HoverboardComponent;
    private markers!: CancelledMarkerMap<Object3D>;

    start() {
        this.hoverboard = Globals.getRootObject(this.engine)!.pp_getComponent(HoverboardComponent)!;
        common.hoverboardSelector = this;
        this.markers = new CancelledMarkerMap();
    }

    setHoverboard(hoverboardVariant: string, hoverboardObject: Object3D, isConfigBoard: boolean, shareToNetwork: boolean) {
        const boardConfigObject = common.kioskLowerUI.iapContentController.getAsset(hoverboardVariant, ItemCategory.Hoverboard);
        if (!boardConfigObject) return console.error("Can't load board ", hoverboardVariant);

        // this.setDefaultMeshData(hoverboardObject);
        const adjustedHoverboardObject = hoverboardObject.pp_getComponent(MeshComponent)!.object;
        const meshComponents = adjustedHoverboardObject.pp_getComponentsSelf(MeshComponent);
        const streamObjects = hoverboardObject.pp_getObjectsByName("Stream", true);
        const logoObject = hoverboardObject.pp_getObjectByName("Logo", true)!;

        const marker = this.markers.replace(hoverboardObject);
        const localRes = common.hoverfitSceneResources;
        CancellablePromise.wrapPromise(localRes.getObjectFromURL(boardConfigObject.url), marker).then((boardRoot) => {
            if (!boardRoot) return;

            const newHoverboardObject = boardRoot.pp_getObjectByName("Hoverboard", true)!;
            const newHoverboardMeshComponents = newHoverboardObject.pp_getComponentsSelf(MeshComponent);
            const newStreams: Object3D[] = [];
            let logoTargetObject = null;

            const searchQueue = newHoverboardObject.children;
            while (searchQueue.length > 0) {
                const obj = searchQueue.pop()!;
                if (obj.name.match("Stream") != null) {
                    newStreams.push(obj);
                } else if (obj.name.match("Logo") != null) {
                    logoTargetObject = obj;
                } else {
                    newHoverboardMeshComponents.push(...obj.pp_getComponentsSelf(MeshComponent));
                    searchQueue.push(...obj.children);
                }
            }

            for (let i = 0; i < newHoverboardMeshComponents.length; i++) {
                const oldMeshComponent = meshComponents[i];
                oldMeshComponent.mesh = newHoverboardMeshComponents[i].mesh;
                setMaterialFromMaterialConfig(oldMeshComponent, (boardConfigObject.materials as { [key: string]: MaterialInterface })[BOARD_MATERIAL_INDEX_TO_KEY[i]], isConfigBoard, this.engine, marker);
            }

            let streamMaterial;
            for (let i = 0; i < streamObjects.length; i++) {
                const streamObject = streamObjects[i];
                const streamMeshComponent = streamObject.pp_getComponent(MeshComponent)!;
                if (i == 0) {
                    streamMaterial = streamMeshComponent.material!;
                    (streamMaterial as any).color = boardConfigObject.jetstreamColor;
                } else {
                    streamMeshComponent.material = streamMaterial;
                }

                if (i < newStreams.length) {
                    streamMeshComponent.active = true;
                    streamMeshComponent.mesh = newStreams[i].pp_getComponent(MeshComponent)!.mesh;
                    newStreams[i].getTransformLocal(tempDualQuat);
                    streamObject.setTransformLocal(tempDualQuat);
                } else {
                    streamMeshComponent.mesh = null;
                }
            }

            if (boardConfigObject.logoLocalPosition) {
                // TODO remove logo position from manifest. use objects instead
                logoObject.setPositionLocal(boardConfigObject.logoLocalPosition);
            } else {
                if (!logoTargetObject) {
                    // TODO remove workaround for boards with no logo object once this
                    //      is fixed at the asset level
                    const defaultBoard = common.hoverfitSceneResources.getObject("hoverboardDefaultRoot");
                    logoTargetObject = defaultBoard.pp_getObjectByName("Logo", true)!;
                }

                logoObject.setPositionLocal(logoTargetObject.getPositionLocal());
            }
        }).catch(CancellablePromise.ignoreCancel);

        const hoverboardRoom = common.hoverboardNetworking.room;
        if (shareToNetwork && hoverboardRoom != null) {
            hoverboardRoom.send("set-hoverboard-variant", { hoverboardVariant });
        }
    }
}