import { Component, Property } from "@wonderlandengine/api";
import anime from "animejs";
import { InstructorAudioType } from "src/hoverfit/avatar/components/hoverboard-instructor-component.js";
import { Globals, MathUtils, Vec3Utils, vec3_create } from "wle-pp";
import { AvatarComponent } from "../../../avatar/components/avatar-component.js";
import common from "../../../common.js";
import { GameGlobals } from "../../../misc/game-globals.js";
import { getTime } from "../../../utils/time-utils.js";

const tempVec = vec3_create();

export class KioskControllerComponent extends Component {
    static TypeName = "kiosk-controller";

    static Properties = {
        rotationSpeed: Property.float(0.05),
        activationDistance: Property.float(4.0),
        holoDisplayFrameOpenY: Property.float(1.315),
        holoDisplayFrameClosedY: Property.float(0.521)
    };

    start() {
        common.kioskController = this;

        this.rotatingHead = this.object.pp_getObjectByName("MainDisplay");
        this.frameHoloDisplay = this.rotatingHead.pp_getObjectByName("FrameHoloDisplay");
        this.holoDisplay = this.frameHoloDisplay.pp_getObjectByName("HoloDisplay");
        this.configAvatar = this.object.pp_getObjectByName("ConfigAvatar");
        this.configAvatarBoard = this.configAvatar.pp_getObjectByName("ConfigAvatar_Board");
        this.configBoard = this.frameHoloDisplay.pp_getObjectByName("ConfigBoard");
        this.tutorialRoot = this.object.pp_getObjectByName("Hoverboard Instructions");
        this.howToHoverboardPanel = this.object.pp_getObjectByName("How To Hoverboard Panel");
        this.fitnessBenefitsPanel = this.object.pp_getObjectByName("Fitness Benefits Panel");
        this.howToHoverboardPanel.pp_resetTransformLocal();
        this.fitnessBenefitsPanel.pp_resetTransformLocal();

        this.configAvatarComponent = this.configAvatar.getComponent(AvatarComponent);

        this.configAvatarNormalScale = this.configAvatar.pp_getScaleLocal(tempVec)[0];
        this.configBoardNormalScale = this.configBoard.pp_getScaleLocal(tempVec)[0];

        this.tutorialRoot.pp_setActive(false);
        this.tutorialActive = false;

        this.activated = false;
        this.activationNormalizedTime = 0.0;

        this.reactivateExternally = false;
        this.reactivateStarted = false;

        this.initalized = false;
    }

    initalize() {
        this.configAvatar.pp_setActive(false);
        this.configBoard.pp_setActive(false);
        this.initalized = true;
    }

    update(dt) {
        if (!this.initalized) {
            this.initalize();
        }

        if (this.currentAnim) {
            this.currentAnim.tick(getTime());
        }

        if (this.reactivateExternally) {
            if (!this.reactivateStarted) this._deactivate(this.currentCallback);
        } else {
            const distance = this._getDistanceToPlayer();
            if (!this.activated && distance <= this.activationDistance) {
                this._activate();
            } else if (this.activated && distance > this.activationDistance) {
                this._deactivate();
            }
        }

        const onBalcony = common.balcony.isPlayerOnBalcony;
        common.kioskUpperUI.active = onBalcony;
        common.kioskLowerUI.active = onBalcony;

        if (!this.activated) return;

        const angleToPlayer = this._getAngleToPlayer();

        if (Math.abs(angleToPlayer) > 0.1) {
            this.rotatingHead.pp_rotateAxis(angleToPlayer * this.rotationSpeed, GameGlobals.up);
        }
    }

    setConfigAvatarActive(b) {
        this.configAvatar.pp_setActive(b);
        this.avatarActive = b;
    }

    setConfigBoardActive(b) {
        this.configBoard.pp_setActive(b);
        this.boardActive = b;
    }

    setHoloDisplayExternalReactivation(callback) {
        this.currentCallback = callback;
        this.reactivateExternally = true;
        this.reactivateStarted = false;
    }


    toggleTutorial(tutorialButton) {
        this.setTutorialActive(!this.tutorialActive, tutorialButton);
    }

    setTutorialActive(active, tutorialButton) {
        this.tutorialActive = active;
        this.tutorialRoot.pp_setActive(this.tutorialActive);
        tutorialButton.forced = active;

        if (active) {
            this.setHowToHoverboardActiveAndPlayAudio();
        } else {
            common.hoverboardInstructor.stopAudioPlayers();
        }
    }

    setHowToHoverboardActiveAndPlayAudio() {
        common.hoverboardInstructor.setSelectedAudioPlayer(InstructorAudioType.Instructions);
        common.hoverboardInstructor.restartAnimation(true, true);

        this.howToHoverboardPanel.pp_setActive(true);
        this.fitnessBenefitsPanel.pp_setActive(false);
    }

    setFitnessBenefitsActiveAndPlayAudio() {
        common.hoverboardInstructor.setSelectedAudioPlayer(InstructorAudioType.HealthBenefits);
        common.hoverboardInstructor.restartAnimation(true, true);

        this.howToHoverboardPanel.pp_setActive(false);
        this.fitnessBenefitsPanel.pp_setActive(true);
    }

    _activate() {
        this.activated = true;

        Vec3Utils.set(tempVec, 0, 0, 0);
        this.configAvatar.pp_setScaleLocal(tempVec);
        this.configBoard.pp_setScaleLocal(tempVec);

        this.currentAnim = anime({
            targets: this,
            easing: "easeOutQuad",
            activationNormalizedTime: 1.0,
            delay: 0,
            duration: 1000 * (1.0 - this.activationNormalizedTime),
            autoplay: false,
            update: () => {
                this._setHoloDisplayTransforms(
                    MathUtils.lerp(this.holoDisplayFrameClosedY, this.holoDisplayFrameOpenY, this.activationNormalizedTime),
                    MathUtils.lerp(0.0, 1.0, this.activationNormalizedTime)
                );

                if (this.avatarActive) {
                    const scale = this.configAvatarNormalScale * this.activationNormalizedTime;
                    Vec3Utils.set(tempVec, scale, scale, scale);
                    this.configAvatar.pp_setScaleLocal(tempVec);
                    this.configAvatar.pp_setActive(true);
                }

                if (this.boardActive) {
                    const scale = this.configBoardNormalScale * MathUtils.mapToRange(this.activationNormalizedTime, 0.35, 1, 0, 1);
                    Vec3Utils.set(tempVec, scale, scale, scale);
                    this.configBoard.pp_setScaleLocal(tempVec);
                    this.configBoard.pp_setActive(true);
                }
            },
            complete: () => {
                this._setHoloDisplayTransforms(
                    this.holoDisplayFrameOpenY,
                    1.0
                );

                if (this.avatarActive) {
                    Vec3Utils.set(tempVec, this.configAvatarNormalScale, this.configAvatarNormalScale, this.configAvatarNormalScale);
                    this.configAvatar.pp_setScaleLocal(tempVec);
                }

                if (this.boardActive) {
                    Vec3Utils.set(tempVec, this.configBoardNormalScale, this.configBoardNormalScale, this.configBoardNormalScale);
                    this.configBoard.pp_setScaleLocal(tempVec);
                }

                this.currentAnim = null;
            }
        });
    }

    _deactivate(callback) {
        this.activated = false;
        this.reactivateStarted = true;

        this.currentAnim = anime({
            targets: this,
            easing: "easeOutQuad",
            activationNormalizedTime: 0.0,
            delay: 0,
            duration: Math.max(500 * this.activationNormalizedTime, 100),
            autoplay: false,
            update: (anim) => {
                this._setHoloDisplayTransforms(
                    MathUtils.lerp(this.holoDisplayFrameClosedY, this.holoDisplayFrameOpenY, this.activationNormalizedTime),
                    MathUtils.lerp(0.0, 1.0, this.activationNormalizedTime)
                );

                if (this.avatarActive) {
                    const scale = this.configAvatarNormalScale * this.activationNormalizedTime;
                    Vec3Utils.set(tempVec, scale, scale, scale);
                    this.configAvatar.pp_setScaleLocal(tempVec);
                }

                if (this.boardActive) {
                    const scale = this.configBoardNormalScale * MathUtils.mapToRange(this.activationNormalizedTime, 0.35, 1, 0, 1);
                    Vec3Utils.set(tempVec, scale, scale, scale);
                    this.configBoard.pp_setScaleLocal(tempVec);
                }

            },
            complete: () => {
                this._setHoloDisplayTransforms(
                    this.holoDisplayFrameClosedY,
                    0.0
                );

                this.currentAnim = null;

                if (this.avatarActive) {
                    this.configAvatar.pp_setActive(false);
                }

                if (this.boardActive) {
                    this.configBoard.pp_setActive(false);
                }

                if (callback) {
                    this.reactivateExternally = false;
                    callback();
                    this._activate();
                }
            }
        });
    }

    _setHoloDisplayTransforms(positionY, scaleY) {
        this.frameHoloDisplay.pp_getPositionLocal(tempVec);
        tempVec[1] = positionY;
        this.frameHoloDisplay.pp_setPositionLocal(tempVec);

        this.holoDisplay.pp_getScaleLocal(tempVec);
        tempVec[1] = scaleY;
        this.holoDisplay.pp_setScaleLocal(tempVec);
    }
}

KioskControllerComponent.prototype._getDistanceToPlayer = function () {
    let kioskPosition = vec3_create();
    let playerPosition = vec3_create();
    return function _getDistanceToPlayer() {
        // Get player Position and kiosk monitor position
        Globals.getPlayerObjects(this.engine).myHead.pp_getPositionWorld(playerPosition);
        this.rotatingHead.pp_getPositionWorld(kioskPosition);

        // Return difference in angle between forward and positional direction
        return playerPosition.vec3_distance(kioskPosition);
    };
}();

KioskControllerComponent.prototype._getAngleToPlayer = function () {
    let kioskForward = vec3_create();
    let playerPosition = vec3_create();
    let kioskToPlayerDirection = vec3_create();
    return function _rotateToPlayer() {
        // Get kiosk monitor forward
        kioskForward = this.rotatingHead.getForwardWorld(kioskForward);
        kioskForward = kioskForward.vec3_removeComponentAlongAxis(GameGlobals.up, kioskForward);

        // Get player Position and kiosk monitor position
        Globals.getPlayerObjects(this.engine).myHead.pp_getPositionWorld(playerPosition);
        this.rotatingHead.pp_getPositionWorld(kioskToPlayerDirection);

        // Calculate vector from kiosk to player
        Vec3Utils.sub(kioskToPlayerDirection, playerPosition, kioskToPlayerDirection);
        kioskToPlayerDirection.vec3_removeComponentAlongAxis(GameGlobals.up, kioskToPlayerDirection);
        Vec3Utils.normalize(kioskToPlayerDirection, kioskToPlayerDirection);

        // Return difference in angle between forward and positional direction
        return kioskForward.vec3_angleSigned(kioskToPlayerDirection, GameGlobals.up);
    };
}();
