import { Component } from "@wonderlandengine/api";
import { GameMode } from "hoverfit-shared-netcode";
import { currentGameConfig } from "../../../data/game-configuration.js";

export class TrackModeInstructionsComponent extends Component {
    static TypeName = "track-mode-instructions";

    init() {
        this.race = this.object.pp_getObjectByName("Race");
        this.roam = this.object.pp_getObjectByName("Roam");
        this.tag = this.object.pp_getObjectByName("Tag");
    }

    setModeInstructionsVisible(visible) {
        this.race.pp_setActive(false);
        this.roam.pp_setActive(false);
        this.tag.pp_setActive(false);

        if (visible) {
            switch (currentGameConfig.mode) {
                case GameMode.Race:
                    this.race.pp_setActive(visible);
                    break;
                case GameMode.Roam:
                    this.roam.pp_setActive(visible);
                    break;
                case GameMode.Tag:
                    this.tag.pp_setActive(visible);
                    break;
            }
        }
    }
}