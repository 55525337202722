import { Component, Property, RetainEmitter } from "@wonderlandengine/api";
import common from "src/hoverfit/common.js";
import { EasyTuneNumber, EasyTuneNumberArray, Globals, vec3_create, vec4_create } from "wle-pp";
import { EasyTuneExtraParamsSet, HoverboardDebugs } from "../../../components/hoverboard-debugs-component.js";
import { Spline, SplineParams, SplinePoint } from "../spline.js";

export let MOVEMENT_DIRECTION = {
    FORWARD: 1,
    BACKWARD: -1
};

export class SplineComponent extends Component {
    static TypeName = "spline";

    static Properties = {
        splineDataPath: Property.string("selected_spline.json"),
        startT: Property.float(0.0),
        movementDirectionType: Property.enum(["forward", "backward"], "forward"),
        isClosed: Property.bool(),
        roadScale: Property.float(1.0),
        xOffset: Property.float(0.0),
        yOffset: Property.float(0.0),
        zOffset: Property.float(0.0)
    };

    init() {
        const splineParams = new SplineParams();
        splineParams.splinePoints = [new SplinePoint()];
        this._spline = new Spline(splineParams);

        this.movementDirection = this.movementDirectionType == 0 ? MOVEMENT_DIRECTION.FORWARD : MOVEMENT_DIRECTION.BACKWARD;

        this.finishT = 0;

        this.loaded = false;

        this.loadEmitter = new RetainEmitter();

        this._loadSplineData(this.splineDataPath).then(this._onSplineDataLoaded.bind(this));
    }

    start() {
        let easyTuneVariables = Globals.getEasyTuneVariables();
        easyTuneVariables.add(new EasyTuneNumberArray("Spline Offset", vec3_create(this.xOffset, this.yOffset, this.zOffset), () => { this.tuneSplineValueChanged = true; }, Globals.isDebugEnabled() && HoverboardDebugs.tuneSpline, 3, 10).setEasyTuneVariableExtraParams(EasyTuneExtraParamsSet.importExportDisabled));
        easyTuneVariables.add(new EasyTuneNumber("Spline Scale", this.roadScale, () => { this.tuneSplineValueChanged = true; }, Globals.isDebugEnabled() && HoverboardDebugs.tuneSpline, 3, 1).setEasyTuneVariableExtraParams(EasyTuneExtraParamsSet.importExportDisabled));
        easyTuneVariables.add(new EasyTuneNumber("Spline Debug Point", 0, null, Globals.isDebugEnabled() && HoverboardDebugs.tuneSpline, 3, 0.1, 0, 1).setEasyTuneVariableExtraParams(EasyTuneExtraParamsSet.importExportDisabled));
    }

    onActivate() {
        if (Globals.isDebugEnabled()) {
            let easyTuneVariables = Globals.getEasyTuneVariables();

            easyTuneVariables.set("Spline Offset", vec3_create(this.xOffset, this.yOffset, this.zOffset));
            easyTuneVariables.set("Spline Scale", this.roadScale);
        }
    }

    update(dt) {
        if (Globals.isDebugEnabled() && this.loaded) {
            if (HoverboardDebugs.showSpline) {
                this._spline.debugDraw();

                let position = vec3_create();
                let color = vec4_create();

                color.vec4_set(0, 1, 0, 1);
                this._spline.getPosition(common.tracksManager.getCurrentTrack().getSpawnPositionProvider().getSpawnPositionSplineTime(), position);
                Globals.getDebugVisualManager().drawPoint(0, position, color, 2);

                color.vec4_set(0, 1, 1, 1);
                this._spline.getPosition(Globals.getEasyTuneVariables().get("Spline Debug Point"), position);
                Globals.getDebugVisualManager().drawPoint(0, position, color, 2);

                color.vec4_set(1, 1, 0, 1);
                this._spline.getPosition(this._spline.getClosestTime(Globals.getPlayerObjects(this.engine).myHead.pp_getPosition()), position);
                Globals.getDebugVisualManager().drawPoint(0, position, color, 2);
            }

            if (HoverboardDebugs.tuneSpline && this.tuneSplineValueChanged) {
                this.tuneSplineValueChanged = false;

                let easyTuneVariables = Globals.getEasyTuneVariables();

                this.xOffset = easyTuneVariables.get("Spline Offset")[0];
                this.yOffset = easyTuneVariables.get("Spline Offset")[1];
                this.zOffset = easyTuneVariables.get("Spline Offset")[2];

                this.roadScale = easyTuneVariables.get("Spline Scale");

                this._createSpline(this.originalSplinePoints);
                common.tracksManager.getCurrentTrack()._setupTrack();
            }
        }
    }

    getSpline() {
        return this._spline;
    }

    isSplineLoaded() {
        return this.loaded;
    }

    _createSpline(rawSplinePoints) {
        const splinePoints = rawSplinePoints.map(rawSplinePoint => {
            const splinePoint = new SplinePoint();
            splinePoint.controlPoint.vec3_set((rawSplinePoint.co[0] + this.xOffset) * this.roadScale, (rawSplinePoint.co[1] + this.yOffset) * this.roadScale, (rawSplinePoint.co[2] + this.zOffset) * this.roadScale);
            splinePoint.controlHandleIn.vec3_set((rawSplinePoint.handle_left[0] + this.xOffset) * this.roadScale, (rawSplinePoint.handle_left[1] + this.yOffset) * this.roadScale, (rawSplinePoint.handle_left[2] + this.zOffset) * this.roadScale);
            splinePoint.controlHandleOut.vec3_set((rawSplinePoint.handle_right[0] + this.xOffset) * this.roadScale, (rawSplinePoint.handle_right[1] + this.yOffset) * this.roadScale, (rawSplinePoint.handle_right[2] + this.zOffset) * this.roadScale);

            return splinePoint;
        });

        if (this.isClosed) {
            splinePoints.push(splinePoints[0]);
        }

        const splineParams = new SplineParams();
        splineParams.splinePoints = splinePoints;
        this._spline = new Spline(splineParams);
    }

    async _loadSplineData(path) {
        return fetch(path)
            .then(response => response.json())
            .then(data => data)
            .catch(error => {
                console.error("Error loading JSON data:", error);
            });
    }

    _onSplineDataLoaded(splineData) {
        this.originalSplinePoints = splineData.points;

        this._createSpline(splineData.points);

        this.loaded = true;

        this.loadEmitter.notify();
    }
}