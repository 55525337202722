import { Component, WonderlandEngine } from "@wonderlandengine/api";
import { TracksManager } from "../tracks-manager.js";
import { FinishGoalComponent } from "./finish-goal-component.js";
import { StartGoalComponent } from "./start-goal-component.js";

export class TracksManagerComponent extends Component {
    static TypeName = "tracks-manager";

    private _tracksManager!: TracksManager;

    static onRegister(engine: WonderlandEngine) {
        engine.registerComponent(StartGoalComponent);
        engine.registerComponent(FinishGoalComponent);
    }

    init() {
        this._tracksManager = new TracksManager(this.object);
    }

    start() {
        this._tracksManager.start();
    }

    update(dt: number) {
        this._tracksManager.update(dt);
    }
}