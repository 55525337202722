import { Alignment, Container, SingleParentXMLInputConfig, Widget, WidgetAutoXML, safeRoundRect, type Alignment2D, type Rect, type WidgetProperties } from "lazy-widgets";

export interface DecoratedButtonLikePaneProperties extends WidgetProperties {
    hasBorder?: boolean;
    canvasFill?: string;
    looksClickable?: boolean;
}

export class DecoratedButtonLikePane<W extends Widget = Widget> extends Container<W> {
    static override autoXML: WidgetAutoXML = {
        name: "decorated-button-like-pane",
        inputConfig: SingleParentXMLInputConfig
    };

    private curFill = "white";
    private _hasBorder = true;
    private _looksClickable = true;

    constructor(child: W, properties?: Readonly<DecoratedButtonLikePaneProperties>) {
        super(
            child,
            {
                containerAlignment: <Alignment2D>{
                    horizontal: Alignment.Center, vertical: Alignment.Stretch,
                },
                roundedCornersRadii: 6,
                ...properties,
            },
        );

        this._hasBorder = properties?.hasBorder ?? true;
        this._looksClickable = properties?.looksClickable ?? true;
        this.curFill = properties?.canvasFill ?? "white";
    }

    get hasBorder() {
        return this._hasBorder;
    }

    set hasBorder(hasBorder) {
        if (this._hasBorder === hasBorder) return;
        this._hasBorder = hasBorder;
        this.markWholeAsDirty();
    }

    set looksClickable(looksClickable) {
        if (this._looksClickable === looksClickable) return;
        this._looksClickable = looksClickable;
        this.markWholeAsDirty();
    }

    get looksClickable() {
        return this._looksClickable;
    }

    protected override handlePainting(dirtyRects: Rect[]): void {
        const ctx = this.viewport.context;
        ctx.save();
        ctx.beginPath();
        safeRoundRect(ctx, this.x, this.y, this.width, this.height, this.roundedCornersRadii);
        ctx.clip();

        ctx.fillStyle = this.curFill;
        ctx.fillRect(this.x, this.y, this.width, this.height);

        super.handlePainting(dirtyRects);

        if (this._hasBorder) {
            ctx.beginPath();
            safeRoundRect(ctx, this.x, this.y, this.width, this.height, this.roundedCornersRadii);
            ctx.strokeStyle = `rgba(${this.looksClickable ? "2, 18, 36" : "128,128,128"},0.8)`;
            ctx.lineWidth = 2;
            ctx.stroke();
        }

        ctx.restore();
    }
}