import EventEmitter from "events";
import { Variable } from "lazy-widgets";
import { FakeStorage } from "./fake-storage.js";

const STORE_PREFS_KEY = "fitness-resort-prefs";
const STORE_TEST_KEY = "__storage_test__";

export class PreferenceManager extends EventEmitter {
    constructor() {
        super({
            captureRejections: true
        });

        this.lastModified = 0;
        this.prefs = {};

        let hadPrefs = this.readPrefs("localStorage");
        this.allowStorage = new Variable(hadPrefs);
        this.storage = null;

        this.allowStorage.watch(() => {
            if (this.storage && this.storage === window.localStorage) {
                this.clearStoredPrefs("localStorage");
            }

            if (this.allowStorage.value && this.hasStorageType("localStorage")) {
                this.storage = window.localStorage;
            } else {
                console.warn("Using fake storage for preferences. Preferences will not persist");
                this.storage = new FakeStorage();
            }

            this.writePrefs();
        }, true);

        if (hadPrefs) {
            this.emit("prefs-read", this.prefs);
        }
    }

    readPrefs(storageName) {
        const storage = window[storageName];
        if (!storage) return false;

        try {
            const val = storage.getItem(STORE_PREFS_KEY);
            if (val === null) {
                return false;
            }

            const parsed = JSON.parse(val);
            if (!("lastModified" in parsed) || !("prefs" in parsed) || typeof parsed.lastModified !== "number" || typeof parsed.prefs !== "object" || Array.isArray(parsed.prefs)) {
                return false;
            }

            if (parsed.lastModified < this.lastModified) {
                return false;
            }

            this.prefs = parsed.prefs;
            return true;
        } catch (err) {
            console.warn(`Could not read preferences from ${storageName};`, err);
            return false;
        }
    }

    clearStoredPrefs(storageName) {
        const storage = window[storageName];
        if (!storage) return;
        storage.removeItem(STORE_PREFS_KEY);
    }

    writePrefs() {
        this.lastModified = Date.now();
        this.storage.setItem(
            STORE_PREFS_KEY,
            JSON.stringify({
                lastModified: this.lastModified,
                prefs: this.prefs
            })
        );
    }

    setPref(key, value) {
        if (this.prefs[key] === value) {
            return;
        }

        this.prefs[key] = value;
        this.emit("pref-set", key, value);
        this.writePrefs();
    }

    setPrefs(keyValuePairs) {
        for (const [key, value] of keyValuePairs) {
            if (this.prefs[key] === value) {
                continue;
            }

            this.prefs[key] = value;
            this.emit("pref-set", key, value);
        }

        this.writePrefs();
    }

    /**
     * TS type inference helper
     * 
     * @param {any} defaultValue
     */
    getPref(key, defaultValue = null) {
        if (key in this.prefs) {
            return this.prefs[key];
        } else {
            return defaultValue;
        }
    }

    hasStorageType(type) {
        // XXX from the MDN web storage API example
        let storage;
        try {
            storage = window[type];
            storage.setItem(STORE_TEST_KEY, STORE_TEST_KEY);
            storage.removeItem(STORE_TEST_KEY);
            return true;
        } catch (err) {
            return "DOMException" in window && err instanceof window.DOMException && (
                // XXX the use of the deprecated api is on purpose for backwards
                //     compatibility
                err.code === 22 || err.code === 1014
                || err.name === "QuotaExceededError"
                || err.name === "NS_ERROR_DOM_QUOTA_REACHED"
            ) && (storage && storage.length !== 0);
        }
    }
}

export const GLOBAL_PREFS = new PreferenceManager();