import { Component } from '@wonderlandengine/api';
import { PickupTimer, PickupTimerParams } from '../implementations/pickup-timer.js';
import { PickupsManager } from '../pickups-manager.js';
import { PickupComponent } from './pickup-component.js';

export class PickupTimerComponent extends Component implements PickupComponent {
    static TypeName = "pickup-timer";

    static onRegister() {
        PickupsManager.getPickupComponentTypes().pp_pushUnique(PickupTimerComponent);
    }

    createPickup(): PickupTimer {
        const params = new PickupTimerParams();
        return new PickupTimer(this.object, params);
    }
}