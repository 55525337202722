import { MeshComponent, Object3D, Texture } from "@wonderlandengine/api";
import { Audio } from "src/hoverfit/audio/audio-manager/audio.js";
import { FlatTexturedMaterial } from "src/hoverfit/types/material-types.js";
import { EasingFunction, Globals, MathUtils, NumberOverFactor, Timer, Vector3, vec3_create } from "wle-pp";
import { ExpiringPriorityQueueElementParams, PriorityLevel } from "../../misc/data-structs/expiring-priority-queue.js";

export enum PopupType {
    Message,
    LapCompleted
}

export enum PopupIconImage {
    None,
    Info,
    Warn,
    Error,
    FitPoints,
    Ramp,
    Rocket,
    Squat,
    StopWatch,
    DailyFitPointsTrophyBronze,
    DailyFitPointsTrophySilver,
    DailyFitPointsTrophyGold,
    DailyFitPointsTrophyPlatinum,
    DailyMedalBronze,
    DailyMedalSilver,
    DailyMedalGold,
    DailyMedalPlatinum
}

export enum PopupIconDecoration {
    None,
    BayleafWreath
}

export class PopupResources {
    popupIconImageTextures: Map<PopupIconImage, Texture | null> = new Map();
    popupIconDecorationTextures: Map<PopupIconDecoration, Texture | null> = new Map();
}

export class PopupWindowParams {
    popupIconImage: PopupIconImage = PopupIconImage.None;
    popupIconDecoration: PopupIconDecoration = PopupIconDecoration.None;
}

export abstract class PopupParams<T extends PopupWindowParams> {
    abstract readonly popupType: PopupType;

    popupTag: string | null = null;

    priorityParams: ExpiringPriorityQueueElementParams = new ExpiringPriorityQueueElementParams();

    abstract popupWindowParams: T;

    /** If `delaySeconds` is a `NumberOverFactor`, it will be computed based on the remaning elements on the queue (the queue pressure)
     *  This means that the seconds can be less the more elements in the queue */
    abstract delaySeconds: number | NumberOverFactor;

    /** If `durationSeconds` is a `NumberOverFactor`, it will be computed based on the remaning elements on the queue (the queue pressure)
     *  This means that the seconds can be less the more elements in the queue */
    abstract durationSeconds: number | NumberOverFactor;

    /** If `showSeconds` is a `NumberOverFactor`, it will be computed based on the remaning elements on the queue (the queue pressure)
     *  This means that the seconds can be less the more elements in the queue */
    abstract showSeconds: number | NumberOverFactor;
    /** If `hideSeconds` is a `NumberOverFactor`, it will be computed based on the remaning elements on the queue (the queue pressure)
     *  This means that the seconds can be less the more elements in the queue */
    abstract hideSeconds: number | NumberOverFactor;

    abstract gamepadPulseParam: { intensity: number, duration: number };
}

export interface PopupWindow {
    getRoot(): Object3D;
    prepare(params: PopupWindowParams, resources: PopupResources): void;
}

export class PopupWindowImplementation<T extends PopupWindowParams> implements PopupWindow {
    protected _visualObject: Object3D;

    protected _iconImageMeshComponent: MeshComponent;
    protected _iconDecorationMeshComponent: MeshComponent;

    constructor(visualObject: Object3D) {
        this._visualObject = visualObject;

        this._iconImageMeshComponent = this._visualObject.pp_getObjectByName("Icon Image")!.getComponent(MeshComponent) as MeshComponent;
        this._iconDecorationMeshComponent = this._visualObject.pp_getObjectByName("Icon Decoration")!.getComponent(MeshComponent) as MeshComponent;

        const iconImageMaterial = this._iconImageMeshComponent.material!.clone()!;
        this._iconImageMeshComponent.material = iconImageMaterial;

        const iconDecorationMaterial = this._iconDecorationMeshComponent.material!.clone()!;
        this._iconDecorationMeshComponent.material = iconDecorationMaterial;
    }

    getRoot(): Object3D {
        return this._visualObject;
    }

    prepare(params: T, resources: PopupResources): void {
        if (params.popupIconImage != PopupIconImage.None) {
            this._iconImageMeshComponent.active = true;
            (this._iconImageMeshComponent.material as FlatTexturedMaterial).flatTexture = resources.popupIconImageTextures.get(params.popupIconImage)!;
        } else {
            this._iconImageMeshComponent.active = false;
        }

        if (params.popupIconDecoration != PopupIconDecoration.None) {
            this._iconDecorationMeshComponent.active = true;
            (this._iconDecorationMeshComponent.material as FlatTexturedMaterial).flatTexture = resources.popupIconDecorationTextures.get(params.popupIconDecoration)!;
        } else {
            this._iconDecorationMeshComponent.active = false;
        }
    }
}

export interface Popup {

    getPopupType(): PopupType;
    getPopupTag(): string | null;
    getPriorityLevel(): PriorityLevel;

    isVisible(): boolean;
    isDone(): boolean;

    show(popupPressure: number): void;
    end(): void;
    hide(): void;

    setAudio(audio: Audio): void;
    setAnchor(locator: Object3D): void;

    overridePopupPressure(pressureOverride: number, ignoreForCurrentTransition?: boolean): void;

    update(dt: number, popupPressure: number): void;
}

export class PopupImplementation<T extends PopupWindowParams> implements Popup {
    private _popupWindow: PopupWindow;
    private _params: PopupParams<T>;
    private _resources: PopupResources;

    private _done: boolean = false;

    private _delayTimer: Timer = new Timer(0, false);
    private _showTimer: Timer = new Timer(0, false);
    private _visibleTimer: Timer = new Timer(0, false);
    private _hideTimer: Timer = new Timer(0, false);

    private _currentScale: Vector3 = vec3_create();
    private _hideStartScale: Vector3 = vec3_create();

    private _popupShowAudio: Audio | null = null;

    private _pressureOverride: number | null = null;
    private _pressureOverrideToApply: number | null = null;

    constructor(popupWindow: PopupWindow, params: PopupParams<T>, resources: PopupResources) {
        this._popupWindow = popupWindow;
        this._params = params;
        this._resources = resources;
    }

    getPopupType(): PopupType {
        return this._params.popupType;
    }

    getPopupTag(): string | null {
        return this._params.popupTag;
    }

    getParams(): PopupParams<T> {
        return this._params;
    }

    getPriorityLevel(): PriorityLevel {
        return this._params.priorityParams.priorityLevel;
    }

    isVisible(): boolean {
        return this._showTimer.isRunning() || this._visibleTimer.isRunning() || this._hideTimer.isRunning();
    }

    isDone(): boolean {
        return this._done;
    }

    show(popupPressure: number): void {
        this._done = false;

        this._delayTimer.start(this._params.showSeconds.get(popupPressure));
        this._delayTimer.update(0);

        if (this._delayTimer.isDone()) {
            this._show(popupPressure);
            this._delayTimer.reset();
        }
    }

    end(): void {
        this._delayTimer.reset();
        this._showTimer.reset();
        this._visibleTimer.reset();
        this._hideTimer.start(this._hideTimer.getDuration() * this._currentScale[1]);
    }

    hide(): void {
        const windowRoot = this._popupWindow.getRoot();
        windowRoot.pp_setActive(false);

        this._done = true;
    }

    setAudio(audio: Audio | null) {
        this._popupShowAudio = audio;
    }

    setAnchor(locator: Object3D): void {
        const windowRoot = this._popupWindow.getRoot();
        windowRoot.pp_setParent(locator, false);
    }

    overridePopupPressure(pressureOverride: number, ignoreForCurrentTransition: boolean = false) {
        if (ignoreForCurrentTransition && !this._visibleTimer.isRunning()) {
            this._pressureOverrideToApply = pressureOverride;
        } else {
            this._pressureOverride = pressureOverride;
        }
    }

    update(dt: number, popupPressure: number): void {
        if (this._done) return;

        this._updatePopupPressure(this._pressureOverride ?? popupPressure);

        if (this._delayTimer.isRunning()) {
            this._delayTimer.update(dt);

            if (this._delayTimer.isDone()) {
                this._show(this._pressureOverride ?? popupPressure);
                this._delayTimer.reset();
            }
        } else if (this._showTimer.isRunning()) {
            this._showTimer.update(dt);
            const percentage = this._showTimer.getPercentage();
            const scaleValueX = MathUtils.interpolate(0, 1, MathUtils.mapToRange(percentage, 0, 0.75, 0, 1), EasingFunction.easeInOut);
            const scaleValueY = MathUtils.interpolate(0, 1, percentage, EasingFunction.easeInOut);

            this._currentScale.vec3_set(scaleValueX, scaleValueY, 1);

            const windowRoot = this._popupWindow.getRoot();
            windowRoot.pp_setScale(this._currentScale);

            if (this._showTimer.isDone()) {
                this._pressureOverride = this._pressureOverrideToApply;

                this._visibleTimer.start();
            }
        } else if (this._visibleTimer.isRunning()) {
            this._visibleTimer.update(dt);
            if (this._visibleTimer.isDone()) {
                this._hideTimer.start();
                this._hideStartScale.vec3_copy(this._currentScale);
            }
        } else if (this._hideTimer.isRunning()) {
            this._hideTimer.update(dt);
            const percentage = this._hideTimer.getPercentage();
            const scaleValueX = MathUtils.interpolate(this._hideStartScale[0], 0, MathUtils.mapToRange(percentage, 0.25, 1, 0, 1), EasingFunction.easeInOut);
            const scaleValueY = MathUtils.interpolate(this._hideStartScale[1], 0, percentage, EasingFunction.easeInOut);

            this._currentScale.vec3_set(scaleValueX, scaleValueY, 1);
            const windowRoot = this._popupWindow.getRoot();
            windowRoot.pp_setScale(this._currentScale);

            if (this._hideTimer.isDone()) {
                this.hide();
            }
        }
    }

    _show(popupPressure: number): void {
        const windowRoot = this._popupWindow.getRoot();
        windowRoot.pp_setActive(true);
        this._popupWindow.prepare(this._params.popupWindowParams, this._resources);

        this._currentScale.vec3_set(0, 0, 1);
        windowRoot.pp_setScale(this._currentScale);

        this._showTimer.reset(this._params.showSeconds.get(popupPressure));
        this._visibleTimer.reset(this._params.durationSeconds.get(popupPressure));
        this._hideTimer.reset(this._params.hideSeconds.get(popupPressure));

        this._showTimer.start();

        Globals.getLeftGamepad()!.pulse(this._params.gamepadPulseParam.intensity, this._params.gamepadPulseParam.duration);
        Globals.getRightGamepad()!.pulse(this._params.gamepadPulseParam.intensity, this._params.gamepadPulseParam.duration);

        if (this._popupShowAudio) {
            this._popupShowAudio?.play();
        }
    }

    /** If there are a lot of popups in the queue (the queue pressure), this method updates the current timers for this popup so that it takes less time
     *  The opposite is also true, if now there is just this popup (someone cleared the queue, no pressure anymore) it can be shown for more time */
    _updatePopupPressure(popupPressure: number): void {
        this._delayTimer.setDuration(this._params.delaySeconds.get(popupPressure));

        if (!this._showTimer.isRunning()) {
            this._showTimer.reset(this._params.showSeconds.get(popupPressure));
        } else {
            const showSeconds = this._params.showSeconds.get(popupPressure);
            if (showSeconds < this._showTimer.getDuration()) {
                const currentPercentage = this._showTimer.getPercentage();
                this._showTimer.start(showSeconds);
                this._showTimer.setPercentage(currentPercentage);
            }
        }

        this._visibleTimer.setDuration(this._params.durationSeconds.get(popupPressure));

        if (!this._hideTimer.isRunning()) {
            this._hideTimer.reset(this._params.hideSeconds.get(popupPressure));
        } else {
            const hideSeconds = this._params.hideSeconds.get(popupPressure);
            if (hideSeconds < this._hideTimer.getDuration()) {
                const currentPercentage = this._hideTimer.getPercentage();
                this._hideTimer.start(hideSeconds);
                this._hideTimer.setPercentage(currentPercentage);
            }
        }
    }
}

