import { vec3 } from "gl-matrix";

const FORWARD = new Float32Array([0, 0, -1]);
const UP = new Float32Array([0, 1, 0]);

export class AudioPlacement {
    constructor(public position: Float32Array, public rotation: Float32Array) { }

    static fromValues(x: number, y: number, z: number, rx: number, ry: number, rz: number, rw: number) {
        const buffer = new ArrayBuffer(28);

        const position = new Float32Array(buffer, 0, 3);
        position[0] = x;
        position[1] = y;
        position[2] = z;

        const rotation = new Float32Array(buffer, 12, 4);
        rotation[0] = rx;
        rotation[1] = ry;
        rotation[2] = rz;
        rotation[3] = rw;

        return new AudioPlacement(position, rotation);
    }

    static default() {
        return AudioPlacement.fromValues(0, 0, 0, 0, 0, 0, 1);
    }

    getForward(out: Float32Array) {
        vec3.transformQuat(out, FORWARD, this.rotation);
    }

    getUp(out: Float32Array) {
        vec3.transformQuat(out, UP, this.rotation);
    }
}
