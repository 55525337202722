// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore - The build system supports XML importing, but typescript doesn't
import xmlContent from "../xml/pause-menu.xml";

import { Property } from "@wonderlandengine/api";
import { type Room } from "colyseus.js";
import { GameMode } from "hoverfit-shared-netcode";
import { Theme, ValidatedVariable, Variable, Widget } from "lazy-widgets";
import { BasicXMLUIRootComponent, WLVirtualKeyboardRoot } from "lazy-widgets-wle";
import { AnalyticsUtils, BrowserUtils, Globals, MathUtils, vec3_create } from "wle-pp";
import { AudioMainChannelName } from "../../../audio/audio-manager/audio-manager.js";
import common from "../../../common.js";
import { currentGameConfig } from "../../../data/game-configuration.js";
import { currentPlayerData } from "../../../data/player-data.js";
import { canJoinTrack } from "../../../game/track/track-utils.js";
import { getRandomName } from "../../../misc/get-random-name.js";
import { cancelSceneLoad } from "../../../misc/load-scene/load-scene.js";
import { MS_PREF_DEFAULT, MS_PREF_KEY, P2P_PREF_DEFAULT, P2P_PREF_KEY, PERMISSIONS_KEY, QUIET_MODE_PREF_DEFAULT, QUIET_MODE_PREF_KEY, USER_ACCEPTED_MICROPHONE_QUERY_DEFAULT, USER_ACCEPTED_MICROPHONE_QUERY_KEY } from "../../../misc/preferences/pref-keys.js";
import { GLOBAL_PREFS } from "../../../misc/preferences/preference-manager.js";
import { BUILD_TIMESTAMP_FORMATTED } from "../../../misc/version/build-timestamp-formatted.js";
import { VERSION_STRING } from "../../../misc/version/version-string.js";
import { currentRoomData } from "../../../network/components/hoverboard-networking-component.js";
import { replaceSearchParams } from "../../../utils/url-utils.js";
import { BaseFitnessResortUIComponent } from "../../lazy-widgets/components/base-fitness-resort-ui-component.js";
import { Book } from "../../lazy-widgets/widgets/book.js";
import { ClickyButton, ContainerClickyButton } from "../../lazy-widgets/widgets/clicky-button.js";
import { ClickyCheckbox } from "../../lazy-widgets/widgets/clicky-checkbox.js";
import { Hyperlink } from "../../lazy-widgets/widgets/hyperlink.js";
import { Numpad } from "../../lazy-widgets/widgets/numpad.js";
import { WarnLabel } from "../../lazy-widgets/widgets/warn-label.js";
import { copyText } from "../../misc/copy-text.js";
import { sfTheme, sfThemeSmaller } from "../../misc/sf-theme.js";
import { PopupIconImage } from "../../popup/popup.js";

const POLICY_URL_NICE = "privacy-hf.playkloud.com";
const TERMS_URL_NICE = "terms-hf.playkloud.com";
const POLICY_URL = `http://${POLICY_URL_NICE}`;
const TERMS_URL = `http://${TERMS_URL_NICE}`;

const pausePosition = vec3_create();

/**
 * state machine (i wont do fancy diagrams this time):
 *
 * [state]: [possible state transitions]
 * maybe_close: pause*, hidden*
 * hidden: pause
 * pause: hidden, room_pick, game_settings
 * room_pick: hidden, pause, wait_msg
 * wait_msg: maybe_close*, ok_msg*
 * ok_msg: maybe_close
 * game_settings: pause
 * confirm_end: maybe_clone
 * mp_consent: maybe_close
 *
 * transitions marked with * can't be done by the user; they are done
 * automatically
 *
 * special behaviour:
 * - transitioning to the maybe_close state automatically transitions to the
 *   pause state if paused flag is set, or hidden state otherwise
 * - transitioning to the hidden state auto-disables the ui root
 */
export enum PauseState {
    MAYBE_CLOSE = -2,
    HIDDEN = -1,
    PAUSE = 0,
    ROOM_PICK = 1,
    WAIT_MSG = 2,
    OK_MSG = 3,
    GAME_SETTINGS = 4,
    CONFIRM_END = 5,
    PERMISSIONS_CONSENT = 6,
    CONFIRM_MSG = 7,
    ASK_GAME_MODE = 8
}

export enum RaceButtonState {
    Start = 0,
    ToBalcony = 1,
}

export class PauseMenuComponent extends BaseFitnessResortUIComponent {
    static override TypeName = "pause-menu";

    static override Properties = {
        ...BasicXMLUIRootComponent.Properties,
        resolution: Property.float(1.0),
    };

    private consentWaiting: null | ((accepted: boolean) => void) = null;
    private userAcceptedMicrophoneQueryCheckbox: Variable<boolean> = new Variable(true);

    private confirmMessageResolveCallback: null | ((value: unknown) => void) = null;
    private confirmMessageButton!: ClickyButton;

    private askGameModeResolveCallback: null | ((askGameModeResult: { gameMode: GameMode, isOnline: boolean } | null) => void) = null;

    private ready: boolean = false;

    private p2pVar!: Variable<boolean>;
    private mediasoupVar!: Variable<boolean>;
    private message!: Variable<string>;
    private toTrackEnabled!: Variable<boolean>;
    private toTrackDebugEnabled!: Variable<boolean>;
    private joinRoomClickable!: Variable<boolean>;
    private joinRoomText!: Variable<string>;
    private shareButtonEnabled!: Variable<boolean>;
    private joinByIDClickable!: Variable<boolean>;
    private roomIDVar!: ValidatedVariable<string, number | null>;
    private curState!: Variable<PauseState>;
    private paused!: boolean;
    private lastRoomID!: null | string;
    private loadingScene!: boolean;
    private showP2PBadWarning!: Variable<boolean>;
    private showP2PUnavailableWarning!: Variable<boolean>;
    private showMediasoupUnavailableWarning!: Variable<boolean>;
    // TODO this should not be updated by menu.js. this should update itself by
    //      adding some sort of listener
    private raceButtonState!: Variable<RaceButtonState>;
    private quietModeVar!: Variable<boolean>;

    protected override createXMLParser() {
        const parser = super.createXMLParser();
        parser.autoRegisterFactory(Book);
        parser.autoRegisterFactory(Numpad);
        parser.autoRegisterFactory(ClickyButton);
        parser.autoRegisterFactory(ContainerClickyButton);
        parser.autoRegisterFactory(ClickyCheckbox);
        parser.autoRegisterFactory(WarnLabel);
        parser.autoRegisterFactory(Hyperlink);

        return parser;
    }

    protected override getRootProperties() {
        const properties = super.getRootProperties();

        return {
            ...properties,
            resolution: this.resolution,
            theme: new Theme({
                bodyTextFill: "white",
            }, properties?.theme ?? sfTheme),
            enabled: false, // XXX menu starts hidden
            enablePasteEvents: !BrowserUtils.isMobile(), // XXX paste event handling shows virtual keyboard on mobile, so disable it for mobile
        };
    }

    protected override getXMLParserConfig() {
        return {
            ...super.getXMLParserConfig(),
            variables: {
                resumeCallback: () => common.menu.resumeGame(),
                toTrackCallback: () => {
                    if (this.raceButtonState.value === RaceButtonState.ToBalcony) {
                        AnalyticsUtils.sendEvent("move_to_balcony");
                        common.menu.returnToBalcony();
                    } else {
                        common.menu.moveToTrack();
                    }
                },
                toTrackDebugCallback: () => {
                    if (this.raceButtonState.value === RaceButtonState.ToBalcony) {
                        common.menu.returnToBalconyDebug();
                    } else {
                        common.menu.moveToTrackDebug();
                    }
                },
                joinRoomCallback: () => {
                    const joinText = (this.root!.getWidgetByID("join") as ClickyButton).text;
                    if (joinText === "Join Room") {
                        this.setState(PauseState.ROOM_PICK);
                    } else if (joinText === "Cancel Map Change") {
                        if (cancelSceneLoad()) {
                            currentRoomData.roomNumber = null;
                            currentRoomData.privateRoom = false;
                            const url = new URL(window.location.href);
                            const searchParams = url.searchParams;
                            searchParams.delete("room");
                            replaceSearchParams(url, searchParams);

                            common.popupManager.showQuickMessagePopup("Cancelled map change", PopupIconImage.Warn);
                        }
                    } else {
                        common.roomProxy.disconnect();
                    }
                },
                shareRoomCallback: () => {
                    copyText(
                        "Share this URL with your friends:",
                        location.origin.replace(new RegExp("^https?://"), "") +
                        location.pathname +
                        "?room=" + this.lastRoomID,
                    );
                },
                settingsCallback: () => this.setState(PauseState.GAME_SETTINGS),
                requestMicrophoneCallback: () => common.hoverboardNetworking.voip!.requestMic(),
                toggleDebugMenuCallback: () => {
                    // TODO remove before release, and remove in pause-menu.xml
                    common.MAIN_CHANNEL.emit("toggle_voip_debug");
                },
                backCallback: () => this.setState(PauseState.PAUSE),
                joinRoomByIDCallback: () => {
                    if (this.roomIDVar.validValue != null) {
                        common.roomProxy.hostOrJoinRoom(this.roomIDVar.validValue, true);
                    } else {
                        common.roomProxy.quickPlay();
                    }
                },
                maybeCloseCallback: () => this.setState(PauseState.MAYBE_CLOSE),
                prevMusicVolumeCallback: function () {
                    const musicVolume = common.audioManager.getMainChannelVolume(AudioMainChannelName.MUSIC) || 0.0;
                    common.audioManager.setMainChannelVolume(MathUtils.clamp(musicVolume - 0.1, 0, 1), AudioMainChannelName.MUSIC);

                    AnalyticsUtils.sendEventOnce("change_music_volume");
                },
                nextMusicVolumeCallback: function () {
                    const musicVolume = common.audioManager.getMainChannelVolume(AudioMainChannelName.MUSIC) || 0.0;
                    common.audioManager.setMainChannelVolume(MathUtils.clamp(musicVolume + 0.1, 0, 1), AudioMainChannelName.MUSIC);

                    AnalyticsUtils.sendEventOnce("change_music_volume");
                },
                prevVOVolumeCallback: function () {
                    const musicVolume = common.audioManager.getMainChannelVolume(AudioMainChannelName.VOICE_OVERS) || 0.0;
                    common.audioManager.setMainChannelVolume(MathUtils.clamp(musicVolume - 0.1, 0, 1), AudioMainChannelName.VOICE_OVERS);

                    AnalyticsUtils.sendEventOnce("change_music_volume");
                },
                nextVOVolumeCallback: function () {
                    const musicVolume = common.audioManager.getMainChannelVolume(AudioMainChannelName.VOICE_OVERS) || 0.0;
                    common.audioManager.setMainChannelVolume(MathUtils.clamp(musicVolume + 0.1, 0, 1), AudioMainChannelName.VOICE_OVERS);

                    AnalyticsUtils.sendEventOnce("change_music_volume");
                },
                pickRandomName: () => {
                    currentPlayerData.name = getRandomName(currentPlayerData);
                },
                applyConfirmMessage: () => {
                    this.applyConfirmMessage();
                },
                rejectConsent: () => {
                    this.finalizeConsent(false);
                },
                acceptConsent: () => {
                    this.finalizeConsent(true);
                },
                askGameModeSingleRace: () => {
                    this.finalizeAskGameMode(GameMode.Race, false);
                },
                askGameModeMultiTag: () => {
                    this.finalizeAskGameMode(GameMode.Tag, true);
                },
                askPermissions: () => {
                    this.getPermissionsConsent();
                },
                p2pVar: this.p2pVar,
                quietModeVar: this.quietModeVar,
                mediasoupVar: this.mediasoupVar,
                roomIDVar: this.roomIDVar,
                message: this.message,
                numpadInputFilter: (inStr: string) => /^\d+$/.test(inStr),
                isNotMobile: !BrowserUtils.isMobile(),
                devMode: DEV_MODE,
                buildVersion: VERSION_STRING,
                buildTime: BUILD_TIMESTAMP_FORMATTED,
                buildBranch: BUILD_BRANCH ?? "<unknown>",
                nicePolicyURL: POLICY_URL_NICE,
                niceTermsURL: TERMS_URL_NICE,
                policyURL: POLICY_URL,
                termsURL: TERMS_URL,
                consentContentTheme: sfThemeSmaller,
                userAcceptedMicrophoneQueryCheckbox: this.userAcceptedMicrophoneQueryCheckbox,
            },
        };
    }

    protected override onUITreePicked(uiTree: Widget, context) {
        super.onUITreePicked(uiTree, context);

        const joinByID = context.idMap.get("join-by-id");
        this.joinByIDClickable.watch(() => {
            joinByID.clickable = this.joinByIDClickable.value;
        }, true);

        const toTrack = context.idMap.get("toTrack");
        this.toTrackEnabled.watch(() => {
            toTrack.enabled = this.toTrackEnabled.value;
        }, true);

        const toTrackDebug = context.idMap.get("toTrackDebug");
        this.toTrackDebugEnabled.watch(() => {
            toTrackDebug.enabled = this.toTrackDebugEnabled.value;
        }, true);

        this.raceButtonState.watch(() => {
            switch (this.raceButtonState.value) {
                case RaceButtonState.Start:
                    toTrackDebug.text = "To Track Debug";

                    switch (currentGameConfig.mode) {
                        case GameMode.Roam:
                            toTrack.text = "Start Roam";
                            break;
                        case GameMode.Race:
                            toTrack.text = "Start Race";
                            break;
                        case GameMode.Tag:
                            toTrack.text = "Start Tag";
                            break;
                    }
                    break;
                default:
                    toTrack.text = "To Balcony";
                    toTrackDebug.text = "To Balcony Debug";
            }
        }, true);

        const join = context.idMap.get("join");
        this.joinRoomClickable.watch(() => {
            join.clickable = this.joinRoomClickable.value;
        }, true);
        this.joinRoomText.watch(() => {
            join.text = this.joinRoomText.value;
        }, true);

        const share = context.idMap.get("share");
        this.shareButtonEnabled.watch(() => {
            share.enabled = this.shareButtonEnabled.value;
        }, true);

        const book = context.idMap.get("menu-book");
        this.curState.watch(() => {
            if (this.curState.value !== PauseState.HIDDEN) {
                book.changePage(this.curState.value);
            }
        }, true);

        const handleQuietModeToggle = () => {
            common.audioManager.setQuietModeEnabled(this.quietModeVar.value);
        };
        this.quietModeVar.watch(handleQuietModeToggle);

        const voipDisabledWarning = context.idMap.get("voip-disabled-warning");
        const handleVoIPToggle = () => {
            if (this.p2pVar.value && this.mediasoupVar.value) {
                if (common.intro.isDone()) {
                    common.hoverboardNetworking.voip!.requestMic();
                }
                voipDisabledWarning.enabled = false;
                return;
            } else if (!(this.p2pVar.value || this.mediasoupVar.value)) {
                if (common.intro.isDone()) {
                    common.hoverboardNetworking.voip!.requestMic();
                }
                voipDisabledWarning.text = "You will not be able to talk to anybody";
            } else {
                voipDisabledWarning.text = `You will only be able to talk to players that have ${this.p2pVar.value ? "P2P" : "Mediasoup"} VoIP enabled`;
            }

            voipDisabledWarning.enabled = true;
        };
        this.p2pVar.watch(handleVoIPToggle);
        this.mediasoupVar.watch(handleVoIPToggle, true);

        const p2pBadWarning = context.idMap.get("p2p-bad-warning");
        this.showP2PBadWarning.watch(() => p2pBadWarning.enabled = this.showP2PBadWarning.value, true);
        const p2pUnavailableWarning = context.idMap.get("p2p-unavailable-warning");
        this.showP2PUnavailableWarning.watch(() => p2pUnavailableWarning.enabled = this.showP2PUnavailableWarning.value, true);
        const mediasoupUnavailableWarning = context.idMap.get("mediasoup-unavailable-warning");
        this.showMediasoupUnavailableWarning.watch(() => mediasoupUnavailableWarning.enabled = this.showMediasoupUnavailableWarning.value, true);
    }

    protected override getXMLContent() {
        return xmlContent;
    }

    override onRootReady(root: WLVirtualKeyboardRoot) {
        super.onRootReady(root);

        // HACK prevent stutter at end of race by delaying UI activation ONLY
        //      FOR CONFIRMATION POPUPS
        // TODO remove this once engine supports ImageData texture uploads which
        //      should fix performance issues in lazy-widgets
        let delayActive: number | null = null;
        this.curState.watch(() => {
            if (delayActive !== null) clearTimeout(delayActive);
            delayActive = null;

            const newPauseState = this.curState.value;
            this.active = newPauseState !== PauseState.HIDDEN && newPauseState !== PauseState.CONFIRM_END;
        }, true);

        this.confirmMessageButton = this.root!.getWidgetByID("confirmMessageButton") as ClickyButton;

        this.ready = true;
    }

    private showUpdateMessage(code: number) {
        if (code !== 4400) return false;
        common.popupManager.showMessagePopup("Outdated game client.\nPlease update before trying again.", PopupIconImage.Error);
        return true;
    }

    override init() {
        common.pauseMenu = this;

        this.userAcceptedMicrophoneQueryCheckbox.value = GLOBAL_PREFS.getPref(USER_ACCEPTED_MICROPHONE_QUERY_KEY, USER_ACCEPTED_MICROPHONE_QUERY_DEFAULT);
        this.quietModeVar = new Variable(GLOBAL_PREFS.getPref(QUIET_MODE_PREF_KEY, QUIET_MODE_PREF_DEFAULT));
        this.quietModeVar.watch(() => GLOBAL_PREFS.setPref(QUIET_MODE_PREF_KEY, this.quietModeVar.value));
        this.p2pVar = new Variable(GLOBAL_PREFS.getPref(P2P_PREF_KEY, P2P_PREF_DEFAULT));
        this.p2pVar.watch(() => GLOBAL_PREFS.setPref(P2P_PREF_KEY, this.p2pVar.value));
        this.mediasoupVar = new Variable(GLOBAL_PREFS.getPref(MS_PREF_KEY, MS_PREF_DEFAULT));
        this.mediasoupVar.watch(() => GLOBAL_PREFS.setPref(MS_PREF_KEY, this.mediasoupVar.value));

        this.object.getPositionLocal(pausePosition);

        this.message = new Variable("");

        this.toTrackEnabled = new Variable(true);
        this.toTrackDebugEnabled = new Variable(true);

        this.joinRoomClickable = new Variable(false);
        this.joinRoomText = new Variable("Join Room");
        this.shareButtonEnabled = new Variable(false);
        this.raceButtonState = new Variable(RaceButtonState.Start);

        this.joinByIDClickable = new Variable(false);
        /** @type {ValidatedVariable<string, number | null>} */
        const validator = (str: string): [boolean, null | number] => {
            if (str === "") {
                return [true, null];
            }

            const num = parseInt(str, 10);
            if (isNaN(num) || !isFinite(num) || num < 0) {
                return [false, null];
            }

            return [true, num];
        };
        this.roomIDVar = new ValidatedVariable("", (str) => {
            const tuple = validator(str);
            this.joinByIDClickable.value = tuple[0];
            return tuple;
        });

        this.curState = new Variable(PauseState.HIDDEN);

        this.paused = false;
        this.lastRoomID = null;

        super.init();

        const MAIN_CHANNEL = common.MAIN_CHANNEL;
        MAIN_CHANNEL.on("room-no-autojoin", () => {
            this.makeJoinButton();
        });

        MAIN_CHANNEL.on("room-join", (roomID) => {
            if (this.loadingScene) return;

            if (roomID === null) {
                common.popupManager.showMessagePopup("Joining available room...", PopupIconImage.Info, "joining", 0.5);
            } else {
                common.popupManager.showMessagePopup("Joining room " + roomID, PopupIconImage.Info, "joining", 0.5);
            }

            this.disableJoinButton();
        });

        MAIN_CHANNEL.on("room-create", (roomID, privateRoom) => {
            if (this.loadingScene) return;

            const roomType = privateRoom ? "private" : "public";
            if (roomID === null) {
                common.popupManager.showMessagePopup("Creating " + roomType + " room", PopupIconImage.Info, "joining", 0.5);
            } else {
                common.popupManager.showMessagePopup("Creating " + roomType + " room " + roomID, PopupIconImage.Info, "joining", 0.5);
            }

            this.disableJoinButton();
        });

        MAIN_CHANNEL.on("room-init-start", (room: Room) => {
            this.lastRoomID = room.id;

            if (this.loadingScene) return;
            this.disableJoinButton();
        });

        MAIN_CHANNEL.on("room-init-done", (isCreate: boolean, privateRoom: boolean) => {
            this.setState(PauseState.MAYBE_CLOSE);
            common.menu.resumeGame();

            if (this.loadingScene) return;
            this.makeDisconnectButton();

            const roomType = privateRoom == null ? "room" : (privateRoom ? "private room" : "public room");
            if (isCreate) {
                common.popupManager.showQuickMessagePopup("Created " + roomType + " " + this.lastRoomID, PopupIconImage.Info, "joining");
            } else {
                common.popupManager.showQuickMessagePopup("Joined room " + this.lastRoomID, PopupIconImage.Info, "joining");
            }
        });

        MAIN_CHANNEL.on("confirm-end", () => {
            const hoverboardRoom = common.hoverboardNetworking.room;
            if (hoverboardRoom) {
                hoverboardRoom.send("set-end-confirmed");
            } else {
                common.menu.returnToBalcony();
            }
        });

        MAIN_CHANNEL.on("room-create-error", (e) => {
            if (currentPlayerData.isGuest) {
                currentPlayerData.name = null;
            }

            if (this.loadingScene) return;

            if (!('code' in e) || !this.showUpdateMessage(e.code)) {
                common.popupManager.showMessagePopup("An error happened while creating the room\nPlease try again", PopupIconImage.Error, "joining");
            }

            common.roomProxy.updateLastSessionGameOnlineConfig(false, null, false);

            this.makeJoinButton();
        });

        MAIN_CHANNEL.on("room-join-error", (e) => {
            if (currentPlayerData.isGuest) {
                currentPlayerData.name = null;
            }

            if (this.loadingScene) return;

            if (!('code' in e) || !this.showUpdateMessage(e.code)) {
                common.popupManager.showMessagePopup("An error happened while joining the room\nPlease try again", PopupIconImage.Error, "joining");
            }

            common.roomProxy.updateLastSessionGameOnlineConfig(false, null, false);

            this.makeJoinButton();
        });

        MAIN_CHANNEL.on("room-init-error", (e) => {
            if (this.loadingScene) return;

            if (!('code' in e) || !this.showUpdateMessage(e.code)) {
                common.popupManager.showMessagePopup("An error happened while preparing the room\nPlease try again", PopupIconImage.Error, "joining");
            }

            common.roomProxy.updateLastSessionGameOnlineConfig(false, null, false);

            this.makeJoinButton();
        });

        MAIN_CHANNEL.on("room-leave", (code) => {
            if (this.loadingScene) return;

            if (!this.showUpdateMessage(code)) {
                const okCodes = [1000, 1005, 4000, 4989];
                if (!okCodes.includes(code)) {
                    common.popupManager.showQuickMessagePopup("You have left the room\nCode: " + code, PopupIconImage.Info);
                } else {
                    common.popupManager.showQuickMessagePopup("You have left the room", PopupIconImage.Info);
                }
            }

            this.makeJoinButton();
        });

        MAIN_CHANNEL.on("room-error", (e) => {
            if (this.loadingScene) return;

            if (!('code' in e) || !this.showUpdateMessage(e.code)) {
                common.popupManager.showMessagePopup("A network error occurred", PopupIconImage.Error);
            }

            this.makeJoinButton();
        });

        MAIN_CHANNEL.on("room-race-completed", () => {
            if (!common.hoverboardNetworking.room) {
                common.leaderboard.addExternalData({ name: currentPlayerData.name || "You", finishTime: common.menu.finishTime });
                common.leaderboard.displayLeaderboard();

                common.leaderboard.submitToHeyVR(common.menu.finishTime, common.menu.bestLapTime);
            }

            this.showConfirmEnd();
        });

        MAIN_CHANNEL.on("room-tag-completed", () => {
            this.showConfirmEnd();
        });

        MAIN_CHANNEL.on("hide-message", () => {
            this.setState(PauseState.MAYBE_CLOSE);
            common.menu.resumeGame();
        });

        this.loadingScene = false;
        MAIN_CHANNEL.on("load-scene-start", () => {
            this.loadingScene = true;
            this.makeCancelChangeButton();
        });

        MAIN_CHANNEL.on("load-scene-end", () => {
            this.loadingScene = false;
            this.makeJoinButton();
        });

        this.showP2PBadWarning = new Variable(false);
        this.showP2PBadWarning.watch(() => console.debug(`showP2PBadWarning: ${this.showP2PBadWarning.value}`));
        MAIN_CHANNEL.on("show-p2p-bad-warning", () => this.showP2PBadWarning.setValue(true));
        MAIN_CHANNEL.on("hide-p2p-bad-warning", () => this.showP2PBadWarning.setValue(false));

        this.showP2PUnavailableWarning = new Variable(false);
        this.showP2PUnavailableWarning.watch(() => console.debug(`showP2PUnavailableWarning: ${this.showP2PUnavailableWarning.value}`));
        MAIN_CHANNEL.on("show-p2p-unavailable-warning", () => this.showP2PUnavailableWarning.setValue(true));
        MAIN_CHANNEL.on("hide-p2p-unavailable-warning", () => this.showP2PUnavailableWarning.setValue(false));

        this.showMediasoupUnavailableWarning = new Variable(false);
        this.showMediasoupUnavailableWarning.watch(() => console.debug(`showMediasoupUnavailableWarning: ${this.showMediasoupUnavailableWarning.value}`));
        MAIN_CHANNEL.on("show-p2p-unavailable-warning", () => this.showMediasoupUnavailableWarning.setValue(true));
        MAIN_CHANNEL.on("hide-p2p-unavailable-warning", () => this.showMediasoupUnavailableWarning.setValue(false));
    }

    disableJoinButton() {
        this.joinRoomClickable.value = false;
        this.shareButtonEnabled.value = false;
    }

    makeJoinButton() {
        this.joinRoomClickable.value = true;
        this.joinRoomText.value = "Join Room";
        this.shareButtonEnabled.value = false;
    }

    makeDisconnectButton() {
        this.joinRoomClickable.value = true;
        this.joinRoomText.value = "Disconnect";
        this.shareButtonEnabled.value = true;
    }

    makeCancelChangeButton() {
        this.joinRoomClickable.value = true;
        this.joinRoomText.value = "Cancel Map Change";
        this.shareButtonEnabled.value = false;
    }

    isStateLocked() {
        return (
            this.curState.value === PauseState.OK_MSG ||
            this.curState.value === PauseState.CONFIRM_END ||
            this.curState.value === PauseState.PERMISSIONS_CONSENT ||
            this.curState.value === PauseState.CONFIRM_MSG
        );
    }

    isPaused(): boolean {
        return this.paused;
    }

    setPaused(paused: boolean) {
        if (paused === this.paused) return;

        this.paused = paused;

        if (paused) {
            this.setState(PauseState.PAUSE);
        } else {
            this.setState(PauseState.HIDDEN);
        }
    }

    setState(newState: PauseState, checkConfirmEnd = true) {
        if (checkConfirmEnd && this.curState.value === PauseState.CONFIRM_END && newState !== PauseState.CONFIRM_END) {
            this.applyConfirmEnd();
        }

        if (newState === PauseState.MAYBE_CLOSE) {
            if (this.paused) {
                newState = PauseState.PAUSE;
            } else {
                newState = PauseState.HIDDEN;
            }
        }

        this.curState.value = newState;

        if (this.consentWaiting !== null) {
            const callback = this.consentWaiting;
            this.consentWaiting = null;
            callback(false);
        }
    }

    isReady() {
        return this.ready;
    }

    isInState(pauseState: PauseState): boolean {
        return this.curState.value == pauseState;
    }

    getRaceButtonState() {
        return this.raceButtonState;
    }

    showMessage(message: string, waitForOK = false) {
        this.message.value = message;
        this.setState(waitForOK ? PauseState.OK_MSG : PauseState.WAIT_MSG);
    }

    showConfirmEnd() {
        this.setState(PauseState.CONFIRM_END);
    }

    applyConfirmEnd() {
        this.setState(PauseState.MAYBE_CLOSE, false);
        common.MAIN_CHANNEL.emit("confirm-end");
    }

    showConfirmMessage(message: string, buttonLabel: string) {
        return new Promise((resolve: (value: unknown) => void, _reject) => {
            if (this.confirmMessageResolveCallback !== null) {
                resolve(undefined);
                return;
            }

            this.confirmMessageResolveCallback = resolve;

            this.message.value = message;
            this.confirmMessageButton.text = buttonLabel;

            this.setState(PauseState.CONFIRM_MSG);
        });
    }

    applyConfirmMessage() {
        if (this.confirmMessageResolveCallback === null) return;

        const callback = this.confirmMessageResolveCallback;
        this.confirmMessageResolveCallback = null;
        this.setState(PauseState.MAYBE_CLOSE);
        callback(undefined);
    }

    getPermissionsConsent() {
        return new Promise((resolve: (accepted: boolean) => void, _reject) => {
            if (this.consentWaiting !== null) {
                resolve(false);
                return;
            }

            this.userAcceptedMicrophoneQueryCheckbox.value = true;

            this.setState(PauseState.PERMISSIONS_CONSENT);
            this.consentWaiting = (accept) => {
                GLOBAL_PREFS.allowStorage.value = accept;

                if (accept) {
                    GLOBAL_PREFS.setPref(USER_ACCEPTED_MICROPHONE_QUERY_KEY, this.userAcceptedMicrophoneQueryCheckbox.value);
                    if (!this.userAcceptedMicrophoneQueryCheckbox.value) {
                        common.hoverboardNetworking.voip?.revokeMic();
                    }
                } else {
                    GLOBAL_PREFS.setPref(USER_ACCEPTED_MICROPHONE_QUERY_KEY, false);
                    common.hoverboardNetworking.voip?.revokeMic();
                }

                GLOBAL_PREFS.setPref(PERMISSIONS_KEY, accept);

                resolve(accept);
            };
        });
    }

    finalizeConsent(accept: boolean) {
        if (this.consentWaiting === null) return;

        const callback = this.consentWaiting;
        this.consentWaiting = null;
        this.setState(PauseState.MAYBE_CLOSE);
        callback(accept);
    }

    getAskGameModePanel() {
        return new Promise((resolve: (askGameModeResult: { gameMode: GameMode, isOnline: boolean } | null) => void, _reject) => {
            if (this.askGameModeResolveCallback !== null) {
                resolve(null);
                return;
            }

            this.setState(PauseState.ASK_GAME_MODE);
            this.askGameModeResolveCallback = resolve;
        });
    }

    finalizeAskGameMode(gameMode: GameMode, isOnline: boolean) {
        if (this.askGameModeResolveCallback === null) return;

        const callback = this.askGameModeResolveCallback;
        this.askGameModeResolveCallback = null;
        this.setState(PauseState.MAYBE_CLOSE);
        callback({ gameMode, isOnline });
    }

    override update(dt: number): void {
        this.toTrackEnabled.value = canJoinTrack();
        this.toTrackDebugEnabled.value = Globals.isDebugEnabled();
        super.update(dt);
    }
}
