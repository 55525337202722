import { Component } from "@wonderlandengine/api";
import { property } from "@wonderlandengine/api/decorators.js";

export class TrackComponent extends Component {
    static TypeName = "track";

    @property.int(0)
    mapTrackIndex!: number;

    @property.bool(true)
    showDynamicChevrons!: boolean;
}