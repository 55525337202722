export const enum ObservableItemIDCollectionEventType {
    Add = 0,
    Remove = 1,
}

export type ObservableItemIDCollectionEventListener = (type: ObservableItemIDCollectionEventType, ids: ReadonlyArray<string>) => void;

export abstract class ObservableItemIDCollection {
    protected readonly ids: string[] = [];
    private readonly listeners: ObservableItemIDCollectionEventListener[] = [];

    constructor(protected _suppressed = false) { }

    get suppressed() {
        return this._suppressed;
    }

    unsuppress() {
        if (!this._suppressed) return;
        this._suppressed = false;
        this.notify(ObservableItemIDCollectionEventType.Add, this.ids);
    }

    getAllIDs() {
        if (this._suppressed) return [];
        // XXX should this return a snapshot?
        return this.ids as ReadonlyArray<string>;
    }

    abstract has(id: string): boolean;

    protected notify(type: ObservableItemIDCollectionEventType, ids: ReadonlyArray<string>) {
        if (this._suppressed) return;
        for (let i = 0; i < this.listeners.length; i++) {
            try {
                this.listeners[i](type, ids);
            } catch (err) {
                console.error(err);
            }
        }
    }

    watch(listener: ObservableItemIDCollectionEventListener, callNow = false) {
        if (this.listeners.indexOf(listener) >= 0) return false;
        this.listeners.push(listener);
        if (callNow && !this._suppressed && this.ids.length > 0) {
            listener(ObservableItemIDCollectionEventType.Add, this.ids);
        }
        return true;
    }

    unwatch(listener: ObservableItemIDCollectionEventListener) {
        const i = this.listeners.indexOf(listener);
        if (i < 0) return false;
        this.listeners.splice(i, 1);
        return true;
    }
}