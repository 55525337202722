import { Component } from "@wonderlandengine/api";
import { BUILD_TIMESTAMP_DATE_FORMATTED, BUILD_TIMESTAMP_TIME_FORMATTED } from "../build-timestamp-formatted.js";
import { VERSION_STRING } from "../version-string.js";

export class VersionTextSetterComponent extends Component {
    static override TypeName = "version-text-setter";

    override init() {
        const textComp = this.object.getComponent("text");
        if (textComp) textComp.text = `${BUILD_TIMESTAMP_DATE_FORMATTED}\n${BUILD_TIMESTAMP_TIME_FORMATTED}\n${VERSION_STRING}`;
    }
}
