import { Component, Object3D, TextComponent } from "@wonderlandengine/api";
import { property } from "@wonderlandengine/api/decorators.js";
import { CursorTarget } from "@wonderlandengine/components";
import { currentPlayerData } from "src/hoverfit/data/player-data.js";
import { Globals, ObjectUtils, Vector3, XRUtils, quat_create, vec3_create } from "wle-pp";
import { AudioID } from "../../audio/audio-manager/audio-id.js";
import common from "../../common.js";

const buttonPressedPositionLocal: Vector3 = vec3_create(0, 0, -0.002);
const buttonPressPositionOffset: Vector3 = vec3_create(0.021, 0.014, -0.008);

let lastRoundEarnedFitnessPoints = 0;
let lastRoundTime = "00:00.000";

export class WatchControllerComponent extends Component {
    static TypeName = "watch-controller";

    @property.object()
    watchCursor!: Object3D;

    private watchObject!: Object3D;
    private watchPivotObject!: Object3D;

    private leftWatchButtonParentObject!: Object3D;
    private leftWatchButtonObject!: Object3D;
    private rightWatchButtonParentObject!: Object3D;
    private rightWatchButtonObject!: Object3D;

    private vignette!: Object3D;
    private vignetteActive: boolean = false;

    private playerNameTextComponent!: TextComponent | null;
    private playerNameTextComponentDefaultScale: number = 1;

    private timeTextComponent!: TextComponent | null;
    private currentFitnessPointsTextComponent!: TextComponent | null;
    private dailyFitnessPointsTextComponent!: TextComponent | null;
    private totalFitnessPointsTextComponent!: TextComponent | null;

    // Support Variables
    private buttonPosition: Vector3 = vec3_create();

    init(): void {
        common.watchController = this;
    }

    private onTotalFitPointsChanged = () => {
        this.updateTotalFitnessPoints(Math.floor(currentPlayerData.totalFitPoints));
    };

    private onDailyFitPointsChanged = () => {
        this.updateDailyFitnessPoints(Math.floor(currentPlayerData.dailyFitPoints));
    };

    private onNameChanged = () => {
        this.updatePlayerName(currentPlayerData.name);
    };

    start() {
        this.watchObject = ObjectUtils.getObjectByName(this.object, "Watch")!;
        this.watchPivotObject = ObjectUtils.getObjectByName(this.object, "Pivot")!;

        this.leftWatchButtonParentObject = ObjectUtils.getObjectByName(this.object, "WatchLeftButtonParent")!;
        this.leftWatchButtonObject = ObjectUtils.getObjectByName(this.leftWatchButtonParentObject, "WatchLeftButton")!;
        this.rightWatchButtonParentObject = ObjectUtils.getObjectByName(this.object, "WatchRightButtonParent")!;
        this.rightWatchButtonObject = ObjectUtils.getObjectByName(this.rightWatchButtonParentObject, "WatchRightButton")!;

        this.playerNameTextComponent = ObjectUtils.getObjectByName(this.object, "PlayerNameText")!.getComponent(TextComponent)!;
        this.playerNameTextComponentDefaultScale = this.playerNameTextComponent.object.pp_getScaleLocal()[0];

        this.timeTextComponent = ObjectUtils.getObjectByName(this.object, "TimeText")!.getComponent(TextComponent)!;
        this.currentFitnessPointsTextComponent = ObjectUtils.getObjectByName(this.object, "CurrentFitnessPointsText")!.getComponent(TextComponent);
        this.dailyFitnessPointsTextComponent = ObjectUtils.getObjectByName(this.object, "DailyFitnessPointsText")!.getComponent(TextComponent);
        this.totalFitnessPointsTextComponent = ObjectUtils.getObjectByName(this.object, "TotalFitnessPointsText")!.getComponent(TextComponent);

        this.vignette = ObjectUtils.getObjectByName(Globals.getPlayerObjects(this.engine)!.myHead!, "Vignette")!;
        this.vignette.pp_setActive(this.vignetteActive);
        this.vignette.pp_resetTransformLocal();

        const leftButtonCursorTarget = this.leftWatchButtonParentObject.pp_getComponent(CursorTarget)!;
        leftButtonCursorTarget.onDown.add(() => this.setLeftWatchButtonPressed(true));
        leftButtonCursorTarget.onUp.add(() => this.setLeftWatchButtonPressed(false));

        const rightButtonCursorTarget = this.rightWatchButtonParentObject.pp_getComponent(CursorTarget)!;
        rightButtonCursorTarget.onDown.add(() => this.setRightWatchButtonPressed(true));
        rightButtonCursorTarget.onUp.add(() => this.setRightWatchButtonPressed(false));

        this.updateTime(lastRoundTime);
        this.updateEarnedFitnessPoints(lastRoundEarnedFitnessPoints);

        this.updateTotalFitnessPoints(Math.floor(currentPlayerData.totalFitPoints));
        this.updateDailyFitnessPoints(Math.floor(currentPlayerData.dailyFitPoints));
        this.updatePlayerName(currentPlayerData.name);

        currentPlayerData.listen(this.onTotalFitPointsChanged, "totalFitPoints");
        currentPlayerData.listen(this.onDailyFitPointsChanged, "dailyFitPoints");
        currentPlayerData.listen(this.onNameChanged, "name");
    }

    private static _updateSV =
        {
            headPosition: vec3_create(),
            watchPosition: vec3_create(),
            directionWatchToHead: vec3_create(),
            watchPivotUp: vec3_create(),
            watchRotationQuat: quat_create(),
            watchTargetRotationQuat: quat_create(),
            watchNewRotationQuat: quat_create(),
            rotationToTargetQuat: quat_create(),
        };
    update(dt: number): void {
        const headPosition = WatchControllerComponent._updateSV.headPosition;
        Globals.getPlayerObjects(this.engine)!.myHead!.pp_getPosition(headPosition);

        const watchPosition = WatchControllerComponent._updateSV.watchPosition;
        this.watchObject.pp_getPosition(watchPosition);

        const directionWatchToHead = WatchControllerComponent._updateSV.directionWatchToHead;
        headPosition.vec3_sub(watchPosition, directionWatchToHead).vec3_normalize(directionWatchToHead);

        const watchRotationQuat = WatchControllerComponent._updateSV.watchRotationQuat;
        this.watchPivotObject.pp_getRotationQuat(watchRotationQuat);

        const watchTargetRotationQuat = WatchControllerComponent._updateSV.watchTargetRotationQuat;
        const watchPivotUp = WatchControllerComponent._updateSV.watchPivotUp;
        watchTargetRotationQuat.quat_copy(watchRotationQuat);
        watchTargetRotationQuat.quat_setUp(this.watchPivotObject.pp_getUp(watchPivotUp), directionWatchToHead);

        if (XRUtils.isSessionActive()) {
            const watchNewRotationQuat = WatchControllerComponent._updateSV.watchNewRotationQuat;
            watchRotationQuat.quat_slerp(watchTargetRotationQuat, 2.5 * dt, watchNewRotationQuat);

            const rotationToTargetQuat = WatchControllerComponent._updateSV.rotationToTargetQuat;
            if (watchNewRotationQuat.quat_rotationTo(watchTargetRotationQuat, rotationToTargetQuat).quat_getAngle() < 0.1) {
                this.watchPivotObject.pp_setRotationQuat(watchTargetRotationQuat);
            } else {
                this.watchPivotObject.pp_setRotationQuat(watchNewRotationQuat);
            }
        } else {
            this.watchPivotObject.pp_setRotationQuat(watchTargetRotationQuat);
        }
    }

    setWatchCursorOffset(useFemaleAvatarOffset: boolean) {
        if (useFemaleAvatarOffset) {
            this.watchCursor.resetPosition();
        } else {
            this.watchCursor.setPositionLocal(buttonPressPositionOffset);
        }
    }

    updateTime(text: string) {
        this.timeTextComponent!.text = text;

        lastRoundTime = text;
    }

    private lastEarnedFitnessPoints = -1;
    updateEarnedFitnessPoints(value: number) {
        if (this.lastEarnedFitnessPoints === value) return;
        this.lastEarnedFitnessPoints = value;
        this.currentFitnessPointsTextComponent!.text = `+${value}`;

        lastRoundEarnedFitnessPoints = this.lastEarnedFitnessPoints;
    }

    private lastDailyFitnessPoints = -1;
    updateDailyFitnessPoints(value: number) {
        if (this.lastDailyFitnessPoints === value) return;
        this.lastDailyFitnessPoints = value;
        this.dailyFitnessPointsTextComponent!.text = `${value}`;
    }

    private lastTotalFitnessPoints = -1;
    updateTotalFitnessPoints(value: number) {
        if (this.lastTotalFitnessPoints === value) return;
        this.lastTotalFitnessPoints = value;
        this.totalFitnessPointsTextComponent!.text = `${value}`;
    }

    private lastName = "";
    updatePlayerName(name: string) {
        if (name === this.lastName) return;

        const defaultMaxCharacter = 12;
        const maxLength = defaultMaxCharacter * this.playerNameTextComponentDefaultScale;
        const newScale = maxLength / name.length;
        this.playerNameTextComponent!.object.pp_setScaleLocal(vec3_create(Math.min(newScale, this.playerNameTextComponentDefaultScale)));

        this.playerNameTextComponent!.text = name;
    }

    private setLeftWatchButtonPressed(pressed: boolean) {
        if (pressed) {
            const watchAudioPlayer = common.audioManager.getAudio(AudioID.WATCH_BUTTON_CLICK);
            if (watchAudioPlayer) {
                watchAudioPlayer.play();
                this.leftWatchButtonParentObject.getPositionWorld(this.buttonPosition);
                watchAudioPlayer.setPosition(this.buttonPosition);
            }
            this.leftWatchButtonObject.setPositionLocal(buttonPressedPositionLocal);
            this.vignetteActive = !this.vignetteActive;
            this.vignette.pp_setActive(this.vignetteActive);
        } else {
            this.leftWatchButtonObject.resetPosition();
        }
    }

    private setRightWatchButtonPressed(pressed: boolean) {
        if (pressed) {
            const watchAudioPlayer = common.audioManager.getAudio(AudioID.WATCH_BUTTON_CLICK);
            if (watchAudioPlayer) {
                watchAudioPlayer.play();
                this.rightWatchButtonParentObject.getPositionWorld(this.buttonPosition);
                watchAudioPlayer.setPosition(this.buttonPosition);
            }
            this.rightWatchButtonObject.setPositionLocal(buttonPressedPositionLocal);
            common.menu.togglePause();

        } else {
            this.rightWatchButtonObject.resetPosition();
        }
    }

    override onDestroy() {
        currentPlayerData.unlisten(this.onTotalFitPointsChanged);
        currentPlayerData.unlisten(this.onDailyFitPointsChanged);
        currentPlayerData.unlisten(this.onNameChanged);
    }
}
