import { Component, MeshComponent, Property } from "@wonderlandengine/api";
import { Vec4Utils, vec3_create, vec4_create } from "wle-pp";
import common from "../../../common.js";
import { GameGlobals } from "../../../misc/game-globals.js";
import { StatusEffectType } from "../status-effects/status-effect.js";


export class HoverboardWindEffectComponent extends Component {
    static TypeName = "hoverboard-wind-effect";
    /* Properties that are configurable in the editor */
    static Properties = {
        runningWindEffectSpeed: Property.float(1.0),
        effectActivationThreshold: Property.float(0.25)
    };

    start() {
        this.originalScale = vec3_create();
        this.object.pp_getScaleLocal(this.originalScale);
        this.boostedScale = vec3_create(this.originalScale[0] * 0.5, this.originalScale[1] * 0.5, 1.0);
        this.nerfedScale = vec3_create(this.originalScale[0] * 1.25, this.originalScale[1] * 1.25, 1.0);

        this.originalWindColor = vec4_create();
        this.boostedWindColor = vec4_create(0.9237668514251709, 1.0, 0.0, 1.0);
        this.nerfedWindColor = vec4_create(0.15829795598983765, 0.6624690890312195, 0.8609865307807922, 1.0);

        // These are used to always show the wind as long as these effects are active
        this.boostedAmountDisplayWindOverride = 2.0;
        this.nerfedAmountDisplayWindOverride = 2.0;

        this.boostedWindSpeedMultiplier = 1.5;
        this.superBoostedWindSpeedMultiplier = 2.5;
        this.nerfedWindSpeedMultiplier = 0.75;

        this.windMesh = this.object.pp_getComponent(MeshComponent);
        this.windMesh.material = this.object.pp_getComponent(MeshComponent).material.clone();
        Vec4Utils.copy(this.windMesh.material.color, this.originalWindColor);

        this.prevDisplayWind = 0.0;
        this.runningWindTextureScroll = 0.0;

        this.windMesh.object.pp_setActive(false);
    }

    update(dt) {
        if (common.hoverboard.hoverboardStarted) {
            const hasGlueStatusEffect = common.tracksManager.getStatusEffectsManager().getStatusEffect(common.hoverboard, StatusEffectType.Glue);
            const hasRocketStatusEffect = common.tracksManager.getStatusEffectsManager().getStatusEffect(common.hoverboard, StatusEffectType.Rocket);
            const hasSuperRocketStatusEffect = common.tracksManager.getStatusEffectsManager().getStatusEffect(common.hoverboard, StatusEffectType.SuperRocket);
            const hasSpringStatusEffect = common.tracksManager.getStatusEffectsManager().getStatusEffect(common.hoverboard, StatusEffectType.Spring);
            const hasSuperSpringStatusEffect = common.tracksManager.getStatusEffectsManager().getStatusEffect(common.hoverboard, StatusEffectType.SuperSpring);
            const hasRampStatusEffect = common.tracksManager.getStatusEffectsManager().getStatusEffect(common.hoverboard, StatusEffectType.Ramp);

            let color = this.originalWindColor;
            let scale = this.originalScale;
            let amountDisplayWind = common.hoverboard.speedPercentage;
            let windSpeed = this.runningWindEffectSpeed;
            if (hasGlueStatusEffect) {
                scale = this.nerfedScale;
                color = this.nerfedWindColor;
                amountDisplayWind = this.nerfedAmountDisplayWindOverride;
                windSpeed *= this.nerfedWindSpeedMultiplier;
            } else if (hasRampStatusEffect || hasSpringStatusEffect || hasRocketStatusEffect || hasSuperRocketStatusEffect || hasSuperSpringStatusEffect) {
                scale = this.boostedScale;
                color = this.boostedWindColor;
                amountDisplayWind = this.boostedAmountDisplayWindOverride;

                if (hasSuperRocketStatusEffect || hasSuperSpringStatusEffect) {
                    windSpeed *= this.superBoostedWindSpeedMultiplier;
                } else {
                    windSpeed *= this.boostedWindSpeedMultiplier;
                }
            }

            this.object.pp_setScaleLocal(scale);

            // Deactivate if below threshhold
            if (amountDisplayWind < this.effectActivationThreshold && this.prevDisplayWind >= this.effectActivationThreshold) {
                this.windMesh.object.pp_setActive(false);
            } else if (amountDisplayWind >= this.effectActivationThreshold && this.prevDisplayWind < this.effectActivationThreshold) {
                this.windMesh.object.pp_setActive(true);
                this.object.pp_resetRotationLocal();
                this.object.pp_rotateAxisObject(Math.pp_random(-180, 180), GameGlobals.forward);
            }

            const alpha = (amountDisplayWind - this.effectActivationThreshold) / (1 - this.effectActivationThreshold);

            color[3] = alpha;
            this.windMesh.material.color = color;

            this.runningWindTextureScroll += windSpeed * dt * amountDisplayWind;
            if (this.runningWindTextureScroll >= 1.0) {
                this.runningWindTextureScroll -= 1.0;
            }
            this.windMesh.material.yScroll = this.runningWindTextureScroll;

            this.prevDisplayWind = amountDisplayWind;
        } else {
            this.prevDisplayWind = 0.0;
            this.runningWindTextureScroll = 0.0;
            this.windMesh.object.pp_setActive(false);
        }
    }
}
