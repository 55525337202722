import { Object3D } from "@wonderlandengine/api";
import { GameMode } from "hoverfit-shared-netcode";
import common from "src/hoverfit/common.js";
import { currentGameConfig } from "src/hoverfit/data/game-configuration.js";
import { PickupGrabber } from "../pickup-grabber.js";
import { PickupType } from "../pickup.js";
import { SyncedPickup, SyncedPickupParams } from "./synced-pickup.js";

export class PickupTimerParams extends SyncedPickupParams {
    respawnDuration: number = Number.POSITIVE_INFINITY; // Never respawns during current game

    timerAdjustment: number = 5;
}

export class PickupTimer extends SyncedPickup {
    declare protected _params: PickupTimerParams;

    constructor(pickupObject: Object3D, params: PickupTimerParams) {
        super(PickupType.Timer, pickupObject, params);
    }

    protected _onPickupHook(pickupGrabber: PickupGrabber) {
        const hoverboardNetworking = common.hoverboardNetworking;
        if (hoverboardNetworking.room) {
            if (currentGameConfig.mode == GameMode.Tag) {
                if (pickupGrabber.isChaser()) {
                    hoverboardNetworking.adjustTimer(this._params.timerAdjustment);
                } else {
                    hoverboardNetworking.adjustTimer(-this._params.timerAdjustment);
                }
            }
        }
    }
}