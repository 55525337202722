import { EventEmitter } from "events";
import { VoIPAudioSource } from "./voip-audio-source.js";
import { Level, voipLog } from "./voip-log.js";

/**
 * @typedef { import("mediasoup-client/lib/Consumer").Consumer } Consumer
 * @typedef { import("./voip-options.js").VoIPOptions } VoIPOptions
 */

// XXX connectionState is preferred over iceConnectionState since
// iceConnectionState never goes into the "failed" state on Chromium preventing
// proper re-negotiation
// XXX the extra `window.RTCPeerConnection` check is only needed so that there
// are no build errors in wonderland engine, since it uses a node environment
// with no RTCPeerConnection API
// XXX technically this isn't needed anymore now that we are using
// webrtc-adapter, but i'm keeping it just in case we stop using it
const hasConnState = (window.RTCPeerConnection && "connectionState" in window.RTCPeerConnection.prototype);
const connStateKey = hasConnState ? "connectionState" : "iceConnectionState";
const connStateEvent = hasConnState ? "connectionstatechange" : "iceconnectionstatechange";

/**
 * A consumption mode. Possible values:
 * - null: the peer is currently not connected via any method
 * - "p2p": the peer is connected via P2P WebRTC
 * - "mediasoup": the peer is connected via mediasoup
 *
 * @typedef {null | "p2p" | "mediasoup"} ConsumptionMode
 */

/**
 * Represents a VoIP connection with a specific peer.
 *
 * @template T - The type used for the VoIPPeer's ID
 */
export class VoIPPeer extends EventEmitter {
    log(level, ...data) {
        voipLog(level, "VoIPPeer", ...data);
    }

    /**
     * @param {T} peerID - The ID of this peer. Unique for each peer
     * @param {VoIPOptions} options - Shared options object passed to VoIPHelper
     */
    constructor(peerID, options) {
        super();

        // general properties
        /**
         * Options used in the VoIPHelper object. Shared with helper and all
         * other peers, do not modify.
         *
         * @type {VoIPOptions}
         * @readonly
         * @private
         */
        this._options = options;
        /**
         * The ID of this peer. IDs are unique for each peer.
         *
         * @type {T}
         * @readonly
         */
        this.id = peerID;
        /**
         * The audio source associated with this peer.
         *
         * @type {VoIPAudioSource}
         * @readonly
         */
        this.audioSource = new VoIPAudioSource(this._options.audioSink, this._options.spatialAudio);
        /**
         * The last consumption mode. Used to know whether an event should be
         * sent notifying that the consumption mode changed.
         *
         * @type {ConsumptionMode}
         * @private
         */
        this._lastConsumptionMode = null;

        // p2p properties
        /**
         * Current P2P priority. Used to determine if P2P is enabled (non-zero
         * values mean P2P is enabled), and if the peer is polite or impolite
         * (if the peer has a higher priority than the client, then the peer is
         * impolite).
         *
         * Automatically set by {@link VoIPHelper}, after which
         * {@link VoIPPeer#updateP2P} is called.
         *
         * @type {number}
         */
        this.p2pPriority = 0;
        /**
         * The current P2P connection. `null` if the connection hasn't been
         * created yet.
         *
         * @type {RTCPeerConnection | null}
         * @private
         */
        this._p2pConnection = null;
        /**
         * Is P2P being used? Used by {@link VoIPPeer#updateP2P} to determine
         * whether a new connection should be started.
         *
         * @type {boolean}
         * @private
         */
        this._usingP2P = false;
        /**
         * Should we ignore P2P offers? Used for perfect negotiation.
         *
         * @type {boolean}
         * @private
         */
        this._ignoreOffer = false;
        /**
         * Are we making a P2P offer? Used for perfect negotiation.
         *
         * @type {boolean}
         * @private
         */
        this._makingOffer = false;
        /**
         * Are we consuming via P2P? We may be connected via P2P but not
         * consuming due to a bad connection. Used for falling back to
         * mediasoup.
         *
         * @type {boolean}
         * @readonly
         */
        this.consumingP2P = false;
        /**
         * The microphone track currently assigned to this peer. Only used for
         * managing P2P connections. Set with {@link VoIPPeer#setMicTrack}.
         *
         * @type {MediaStreamTrack | null}
         * @private
         */
        this._micTrack = null;

        // mediasoup properties
        /**
         * The current mediasoup consumer. If `null`, then we are not consuming
         * audio from this peer via mediasoup.
         *
         * @type {Consumer | null}
         * @private
         */
        this._consumer = null;
        /**
         * Is this peer using mediasoup? If true, then we can try to connect to
         * it via mediasoup. Use the public setter instead of this property.
         *
         * @type {boolean}
         * @private
         */
        this._usingMediasoup = false;
    }

    /**
     * Update the audio source position.
     *
     * Does nothing if spatial audio is disabled. If the change in position is
     * not large enough, then the position is not changed. This is controlled by
     * the spatial audio epsilon value in the options object.
     */
    updateSpatialPos() {
        if (!this._options.spatialAudio) {
            return;
        }

        // const oldPos = audioSource.getPos();
        // const eps = this._options.spatialAudioEPS;

        // if (!oldPos
        //     || Math.abs(this.x - oldPos[0]) > eps
        //     || Math.abs(this.y - oldPos[1]) > eps
        //     || Math.abs(this.z - oldPos[2]) > eps) {
        //     audioSource.setPos(this.x, this.y, this.z);
        // }
        this.audioSource.updatePlacement();
    }

    /**
     * Get the current consumption mode. Note that this also represents what
     * method is being used to SEND audio data, not just receive.
     *
     * @readonly
     * @type {ConsumptionMode}
     */
    get consumptionMode() {
        if (this.consumingP2P) {
            return "p2p";
        } else if (this.usingMediasoup) {
            return "mediasoup";
        } else {
            return null;
        }
    }

    /**
     * Update the current consumption mode. If the consumption mode is changed
     * after this call a "consumption-mode-changed" event is emitted.
     *
     * Switching to "p2p" will disconnect the mediasoup consumer.
     * Switching to "mediasoup" will emit a "want-mediasoup-audio" event so that
     * the mediasoup consumer is created.
     *
     * @private
     */
    updateConsumptionMode() {
        const lastConsumptionMode = this._lastConsumptionMode;
        const newConsumptionMode = this.consumptionMode;
        this.log(Level.DEBUG, "updateConsumptionMode", lastConsumptionMode, newConsumptionMode);
        if (lastConsumptionMode !== newConsumptionMode) {
            // disconnect mediasoup consumer if switched to p2p
            const newIsP2P = newConsumptionMode === "p2p";
            if (newIsP2P) {
                this.disconnectMediasoupConsumer();
            }

            this._lastConsumptionMode = newConsumptionMode;
            this.emit("consumption-mode-changed", newConsumptionMode);

            // try to use mediasoup if mediasoup was not being used and there is
            // no new consumption method
            if (lastConsumptionMode !== "mediasoup" && !newIsP2P && !this._consumer) {
                this.emit("want-mediasoup-audio");
            }
        }
    }

    /**
     * Clean up this peer. Peer must not be used after this is called.
     *
     * Stops and cleans up all connections and audio sources.
     */
    dispose() {
        // disconnect mediasoup consumer
        this.disconnectMediasoupConsumer();

        // disconnect p2p
        this.cleanupP2PConnection(this._p2pConnection);

        // dispose audio source
        this.audioSource.dispose();

        // remove all event listeners; this object is disposed
        this.removeAllListeners();
    }

    /////////
    // P2P //
    /////////

    /**
     * Does this peer have P2P enabled?
     *
     * @readonly
     * @type {boolean}
     */
    get hasP2P() {
        return this.p2pPriority !== 0;
    }

    /**
     * Is the P2P connection bad? If the connection state is not "connected"
     * (or "completed" if using ICE connection state), then the connection is
     * classified as bad. A missing P2P connection object also counts as a bad
     * connection.
     *
     * @readonly
     * @type {boolean}
     */
    get badP2PConnection() {
        if (this._p2pConnection === null) {
            return true;
        }

        const connState = this._p2pConnection[connStateKey];
        // XXX "completed" is only valid if using iceConnectionState
        return connState !== "connected" && connState !== "completed";
    }

    /**
     * Update the P2P state. Will clean up P2P connections or create a new one
     * if necessary. Called automatically by {@link VoIPHelper}, should not be
     * called manually.
     *
     * @param {number} clientP2PPriority - The current priority of the client, used to compare with the peer's priority for deciding which is impolite.
     */
    updateP2P(clientP2PPriority) {
        const clientHasP2P = clientP2PPriority !== 0;
        const needsP2P = clientHasP2P && this.hasP2P;
        if (needsP2P === this._usingP2P) {
            return;
        }

        this._usingP2P = needsP2P;

        this.cleanupP2PConnection(this._p2pConnection);

        this._makingOffer = false;
        this._ignoreOffer = false;

        if (needsP2P) {
            const connection = new RTCPeerConnection({
                iceServers: this._options.iceServers,
            });

            // XXX only the impolite peer creates the transceiver because a
            // matching transceiver is created on the other end. if both peers
            // create a transceiver, then both peers will have 2 transceivers,
            // which defeats the whole purpose of transceivers
            const impoliteClient = clientP2PPriority > this.p2pPriority;
            if (impoliteClient) {
                this.log(Level.DEBUG, "client is impolite, creating transceiver");
                const transceiver = this.makeTransceiver(connection);

                if (this._micTrack) {
                    this.log(Level.WARN, "Early-setting microphone track");
                    this.replaceTransceiverMicTrack(connection, transceiver, this._micTrack);
                }
            }

            this._p2pConnection = connection;
            let failedCounter = 0;
            this.log(Level.DEBUG, "p2p connection created");

            connection.addEventListener("icecandidate", ev => {
                if (this._p2pConnection !== connection) {
                    return;
                }

                // relay ice candidate to other peer (or end-of-candidates as a
                // null value)
                this.emit("ice-candidate", ev.candidate);
            });

            connection.addEventListener("negotiationneeded", async (ev) => {
                if (this._p2pConnection !== connection) {
                    return;
                }

                this.log(Level.DEBUG, "renegotiation needed");

                try {
                    this._makingOffer = true;
                    await connection.setLocalDescription();
                    this.emit("session-description", connection.localDescription);
                } catch (e) {
                    this.log(Level.ERROR, "setLocalDescription failed in offer;", e);
                } finally {
                    this._makingOffer = false;
                }
            });

            connection.addEventListener(connStateEvent, _ev => {
                this.log(Level.DEBUG, "connection state changed:", connection[connStateKey]);
                const connMatches = this._p2pConnection === connection;

                switch (connection[connStateKey]) {
                    case "connected":
                        if (connMatches) {
                            // XXX get rid of simultaneous connection if P2P
                            // connection is now normal
                            this.disconnectMediasoupConsumer();

                            // XXX the state can switch from connected to
                            // disconnected and vice-versa if there is a
                            // temporary disconnection. the `connected` variable
                            // is used to make sure this is a new P2P connection
                            // and not a re-connection (unless it's a
                            // reconnection that required re-negotiation)
                            if (!this.consumingP2P) {
                                this.log(Level.DEBUG, "p2p connected");
                                failedCounter = 0;
                                this.consumingP2P = true;
                                this.updateConsumptionMode();
                            }

                            // XXX when switching from mediasoup to p2p the
                            // connection is technically considered bad, because
                            // new connections are classified as bad. this means
                            // that the optimiser think the producer is still
                            // needed and never disables the producer. to fix
                            // this, fire an event that notifies that the p2p
                            // connection is ok again so that the optimiser can
                            // disable the producer
                            this.emit("p2p-restored");
                        }
                        break;
                    case "closed":
                        this.log(Level.DEBUG, "p2p closed");
                        if (connMatches) {
                            this.consumingP2P = false;
                        }

                        this.cleanupP2PConnection(connection);

                        if (connMatches) {
                            this.updateConsumptionMode();
                        }
                        break;
                    case "failed": {
                        this.log(Level.WARN, "ICE connection failed");
                        failedCounter++;

                        if (connMatches && this.consumingP2P) {
                            // XXX the connection is fully down. try to use
                            // mediasoup while attempting to recover the
                            // connection, but don't clean up the webrtc
                            // connection yet (only done if failed too many
                            // times)
                            this.consumingP2P = false;
                            this.updateConsumptionMode();
                        }

                        const tooManyFailures = failedCounter > this._options.maxP2PRetries;
                        if (!connMatches || tooManyFailures) {
                            // this connection has failed too many times IN A
                            // ROW, or is no longer the primary P2P connection.
                            // close it
                            this.cleanupP2PConnection(connection);

                            if (tooManyFailures) {
                                this.log(Level.WARN, "P2P connection was closed due to too many failed re-negotiations");
                            }
                        } else {
                            // request renegotiation
                            connection.restartIce();
                        }
                        break;
                    }
                }
            });

            connection.addEventListener("track", ev => {
                this.log(Level.DEBUG, "new track/transceiver received from peer");
                // XXX transceivers created by the remote are always in recvonly
                // mode by default. make sure to make the transceiver
                // bi-directional on this end too, otherwise we wont be able to
                // send audio
                ev.transceiver.direction = "sendrecv";

                if (this._p2pConnection === connection) {
                    this.receiveP2PTrack(ev.track);
                }

                // XXX this event name is a misnomer, it should be called
                // "transceiver". even if the other side doesn't send a mic
                // track this event will still be called with the transceiver
                // created and a muted track, so we can use it to detect when we
                // can send our mic track if we are a polite peer
                const transceiver = ev.transceiver;
                if (this._micTrack !== transceiver.sender.track) {
                    this.log(Level.DEBUG, "transceiver received from peer has incorrect sender track, replacing");
                    this.replaceTransceiverMicTrack(connection, transceiver, this._micTrack);
                }
            });

            this.setSenderTrack(connection);
        }
    }

    /**
     * Receive a track from a P2P connection. This track will be handled
     * automatically; input track will be set or cleared when needed.
     *
     * @param {MediaStreamTrack} track - The new audio track
     * @private
     */
    receiveP2PTrack(track) {
        this.log(Level.DEBUG, `received P2P track ${track.id}`, track);
        if (track.kind === "audio") {
            track.addEventListener("mute", () => {
                this.log(Level.DEBUG, `inbound track ${track.id} muted`);
                this.audioSource.removeTrack(track);
            });

            track.addEventListener("unmute", () => {
                this.log(Level.DEBUG, `inbound track ${track.id} unmuted`);
                this.audioSource.addTrack(track);
            });

            track.addEventListener("ended", () => {
                this.log(Level.DEBUG, `inbound track ${track.id} ended`);
                this.audioSource.removeTrack(track);
            });

            if (track.readyState === "live" && !track.muted) {
                this.log(Level.DEBUG, `inbound track ${track.id} was already live and unmuted`);
                this.audioSource.addTrack(track);
            }
        }
    }

    /**
     * Stop/clean up a P2P connection belonging to this peer. Audio sources
     * associated with the connection will be disposed and the consumption mode
     * will change.
     *
     * @param {RTCPeerConnection} connection - The P2P connection to close
     * @private
     */
    cleanupP2PConnection(connection) {
        if (connection === null) {
            return;
        }

        // cleanup mic tracks
        for (const sender of connection.getSenders()) {
            if (sender.track) {
                connection.removeTrack(sender);
            }
        }

        // cleanup sounds created by p2p connection
        for (const receiver of connection.getReceivers()) {
            if (receiver.track) {
                this.audioSource.removeTrack(receiver.track);
                receiver.track.stop();
            }
        }

        // unset connection
        if (connection === this._p2pConnection) {
            this.consumingP2P = false;
            this._p2pConnection = null;

            // notify that consumption mode changed
            this.updateConsumptionMode();
        }

        // close if necessary
        if (connection[connStateKey] !== "closed") {
            connection.close();
        }
    }

    /**
     * Add an ICE candidate from this peer (received via a signaling server) to
     * the P2P connection.
     *
     * @param {RTCIceCandidateInit} candidate - The ICE candidate to add, as a plain JS object
     */
    addIceCandidate(candidate) {
        const connection = this._p2pConnection;
        if (!connection) {
            this.log(Level.WARN, "Ignored ICE candidate; P2P connection not created");
            return;
        }

        // add ICE candidate to connection
        try {
            connection.addIceCandidate(candidate);
        } catch (e) {
            if (!this._ignoreOffer) {
                this.log(Level.ERROR, "Failed to add ICE candidate;", e);
            }
        }
    }

    /**
     * Add a session candidate from this peer (received via a signaling server)
     * to the P2P connection.
     *
     * @param {RTCSessionDescriptionInit} description - The remote session description to add, as a plain JS object
     * @param {number} clientP2PPriority - The current priority of the client, used to compare with the peer's priority for deciding which is impolite.
     */
    async setRemoteDescription(description, clientP2PPriority) {
        const connection = this._p2pConnection;
        if (!connection) {
            this.log(Level.WARN, "Ignored session description; P2P connection not created");
            return;
        }

        // set remote description
        const isOffer = description.type === "offer";
        const offerCollision = isOffer && (this._makingOffer || connection.signalingState !== "stable");
        this._ignoreOffer = this.p2pPriority > clientP2PPriority && offerCollision;

        if (this._ignoreOffer) {
            this.log(Level.WARN, "WebRTC offer ignored");
            return;
        }

        try {
            await connection.setRemoteDescription(description);
        } catch (err) {
            this.log(Level.ERROR, "setRemoteDescription failed;", err);
            return;
        }

        try {
            if (isOffer) {
                await connection.setLocalDescription();
                this.emit("session-description", connection.localDescription);
            }
        } catch (err) {
            this.log(Level.ERROR, "setLocalDescription failed in answer;", err);
        }
    }

    /**
     * Set the microphone track used in the P2P connection.
     *
     * @param {MediaStreamTrack | null} track - The microphone audio track
     * @returns {Promise<void>}
     */
    async setMicTrack(track) {
        this._micTrack = track;
        await this.setSenderTrack(this._p2pConnection);
    }

    /**
     * Set the sender track of the first transceiver in the P2P connection. Uses
     * {@link VoIPPeer#_micTrack} as the track.
     *
     * @private
     * @param {RTCPeerConnection} connection - The connection to get the transceivers from
     * @returns {Promise<void>}
     */
    async setSenderTrack(connection) {
        const micTrack = this._micTrack;

        if (connection) {
            const transceivers = connection.getTransceivers();

            if (transceivers.length === 0) {
                this.log(Level.WARN, "No transceivers, microphone track not sent");
                return;
            }

            if (transceivers.length > 1) {
                this.log(Level.WARN, `${transceivers.length} transceivers available; only first transceiver will be used`);
            }

            await this.replaceTransceiverMicTrack(connection, transceivers[0], micTrack);
        }
    }

    /**
     * Set the sender track of the a specific transceiver in the P2P connection. Uses
     * {@link VoIPPeer#_micTrack} as the track.
     *
     * @private
     * @param {RTCPeerConnection} connection - The connection to use for creating a new transceiver if replaceTrack fails
     * @param {RTCRtpTransceiver} transceiver - The transceiver to replace the sender track of
     * @param {MediaStreamTrack | null} micTrack - The replacement microphone track
     * @returns {Promise<void>}
     */
    async replaceTransceiverMicTrack(connection, transceiver, micTrack) {
        try {
            await transceiver.sender.replaceTrack(micTrack);
            this.log(Level.DEBUG, `replaced mic track for peer ${this.id} with track ${micTrack?.id}`, micTrack);
        } catch (err) {
            // XXX replaceTrack can very rarely fail when the new track's codecs
            // don't match with the new one, or the new track has a different
            // number of channels
            this.log(Level.WARN, "Failed to replace transceiver track. Replacing transceiver with new one via renegotiation;", err);
            transceiver.stop();
            const newTransceiver = this.makeTransceiver(connection);

            try {
                newTransceiver.sender.replaceTrack(micTrack);
            } catch (err) {
                this.log(Level.WARN, "Failed to replace transceiver track of new transceiver. Giving up;", err);
            }
        }
    }

    /**
     * Create a new bi-directional transceiver for a given P2P connection.
     *
     * @private
     * @param {RTCPeerConnection} connection - The connection to use for creating a new transceiver
     * @returns {RTCRtpTransceiver}
     */
    makeTransceiver(/** @type {RTCPeerConnection} */ connection) {
        const transceiver = connection.addTransceiver(
            "audio", { direction: "sendrecv" }
        );

        this.receiveP2PTrack(transceiver.receiver.track);
        return transceiver;
    }

    ///////////////
    // mediasoup //
    ///////////////

    /**
     * Is this peer using mediasoup? If true, then we can try to connect to
     * it via mediasoup. Automatically set by {@link VoIPHelper}
     *
     * @type {boolean}
     */
    get usingMediasoup() {
        return this._usingMediasoup;
    }

    set usingMediasoup(usingMediasoup) {
        if (this._usingMediasoup === usingMediasoup) {
            return;
        }

        this._usingMediasoup = usingMediasoup;
        if (!usingMediasoup) {
            this.disconnectMediasoupConsumer();
        }

        this.updateConsumptionMode();
    }

    /**
     * Add a mediasoup consumer to this peer. If a mediasoup consumer is already
     * set, then the old one will be closed. Automatically called by
     * {@link VoIPHelper}. Consumption mode is updated.
     *
     * @param {Consumer} consumer - The new mediasoup consumer
     */
    addMediasoupConsumer(consumer) {
        // XXX there can be a data race here where a P2P connection was finished
        // WHILE the client was waiting for a consumer to be created. in that
        // case, don't add the mediasoup consumer
        if (this.consumingP2P) {
            // XXX note that, if a P2P connection fails, different clients
            // realise at different times that the connection has failed, which
            // can result in one client waiting for the other client to join
            // mediasoup consumption mode, resulting in a period where there is
            // a one-way connection until the other client realises the
            // connection has failed. to work around this issue, allow having
            // both a mediasoup and a P2P connection at the same time if the P2P
            // connection state is not "connected" or "completed" (when using
            // iceConnectionState). "disconnected" states are always detected
            // very early, so it should prevent the one-way connection issue
            if (this.badP2PConnection) {
                this.log(Level.WARN, "Mediasoup consumer added while P2P connection is bad; temporarily switching to mediasoup until P2P connection is restored");

                this.consumingP2P = false;
                this.updateConsumptionMode();
            } else {
                this.log(Level.WARN, "Ignored mediasoup consumer; a P2P connection was finished while waiting for a mediasoup consumer to be created");
                consumer.close();
                this.disconnectMediasoupConsumer();
                return;
            }
        }

        // clean up old consumer if any
        const oldConsumer = this._consumer;
        if (oldConsumer) {
            try {
                if (!oldConsumer.closed) {
                    oldConsumer.close();
                }
            } catch (e) {
                this.log(Level.WARN, "Could not close old consumer", e);
            }
        }

        // track new consumer
        this._consumer = consumer;

        // make audio source for incoming audio track
        const consumerTrack = consumer.track;
        this.audioSource.addTrack(consumerTrack);

        // clean up when consumer is closed
        consumer.observer.on("close", () => {
            this.audioSource.removeTrack(consumerTrack);

            // untrack consumer
            if (this._consumer === consumer) {
                this._consumer = null;
                this.updateConsumptionMode();
            }
        });

        this.log(Level.DEBUG, "mediasoup consumer added");

        // notify that consumption mode changed
        this.updateConsumptionMode();
    }

    /**
     * Disconnect the current mediasoup consumer. If there is no consumer, then
     * nothing is done. Consumption mode is updated.
     *
     * @private
     */
    disconnectMediasoupConsumer() {
        if (this._consumer) {
            if (!this._consumer.closed) {
                this._consumer.close();
            }

            this._consumer = null;
            this.log(Level.DEBUG, "mediasoup consumer disconnected");

            // notify that consumption mode changed
            this.updateConsumptionMode();
        }
    }
}