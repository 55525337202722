import { Howl } from "howler";
import { AudioPlayer } from "wle-pp";
import { EncryptedAudioComponent } from "../encrypted-audio/components/encrypted-audio-component.js";
import { VoIPAudioSource } from "../voip/voip-audio-source.js";
import { Audio } from "./audio.js";
import { EncryptedAudio } from "./implementations/encrypted-audio.js";
import { HowlerAudio } from "./implementations/howler-audio.js";
import { PPAudio } from "./implementations/pp-audio.js";
import { VoIPAudio } from "./implementations/voip-audio.js";

export enum SourceAudioType {
    HOWLER = "howler",
    ENCRYPTED_AUDIO = "encrypted-audio",
    VOIP = "voip",
    PP = "pp"
}

export type SourceAudio = Howl | EncryptedAudioComponent | VoIPAudioSource | AudioPlayer;

export function getSourceAudioType(sourceAudio: SourceAudio): SourceAudioType {
    if (sourceAudio instanceof Howl) {
        return SourceAudioType.HOWLER;
    }

    if (sourceAudio instanceof EncryptedAudioComponent) {
        return SourceAudioType.ENCRYPTED_AUDIO;
    }

    if (sourceAudio instanceof VoIPAudioSource) {
        return SourceAudioType.VOIP;
    }

    if (sourceAudio instanceof AudioPlayer) {
        return SourceAudioType.PP;
    }

    throw new Error("Source audio not supported");
}

export function getAudioFromSourceAudio(sourceAudio: SourceAudio): Audio {
    let audio: Audio | null = null;

    const sourceAudioType = getSourceAudioType(sourceAudio);
    switch (sourceAudioType) {
        case SourceAudioType.HOWLER:
            audio = new HowlerAudio(sourceAudio as Howl);
            break;
        case SourceAudioType.ENCRYPTED_AUDIO:
            audio = new EncryptedAudio(sourceAudio as EncryptedAudioComponent);
            break;
        case SourceAudioType.VOIP:
            audio = new VoIPAudio(sourceAudio as VoIPAudioSource);
            break;
        case SourceAudioType.PP:
            audio = new PPAudio(sourceAudio as AudioPlayer);
            break;
    }

    return audio!;
}