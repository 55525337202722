import { Component, Property } from "@wonderlandengine/api";
import { Globals, PlayerLocomotionComponent, vec3_create } from "wle-pp";
import { GameGlobals } from "../../game-globals.js";

export class LookAtPlayerComponent extends Component {
    static TypeName = "look-at-player";
    static Properties = {
        keepUp: Property.bool(true)
    };

    update(dt) {
        // Implemented outside class definition
    }
}

LookAtPlayerComponent.prototype.update = function () {
    let headPosition = vec3_create();
    let currentPosition = vec3_create();
    let lookTo = vec3_create();
    return function update(dt) {
        if (this.playerHeadManager == null) {
            this.playerHeadManager = Globals.getPlayerObjects(this.engine).myPlayer.pp_getComponent(PlayerLocomotionComponent).getPlayerLocomotion().getPlayerHeadManager();
        }

        headPosition = this.playerHeadManager.getPositionHead(headPosition);
        currentPosition = this.object.pp_getPosition(currentPosition);

        lookTo = headPosition.vec3_sub(currentPosition, lookTo);
        lookTo.vec3_normalize(lookTo);

        this.object.pp_setUp(GameGlobals.up, lookTo);
    };
}();