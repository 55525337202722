import { Component, MeshComponent } from "@wonderlandengine/api";
import { Handedness, MathUtils, vec3_create } from "wle-pp";
import { AVATAR_FORWARD_OFFSET_FROM_HEAD } from "../../avatar/components/avatar-component.js";
import common from "../../common.js";
import { GameGlobals } from "../../misc/game-globals.js";

export class NetworkedHoverboardComponent extends Component {
    static TypeName = "networked-hoverboard";

    init() {
        this.avatar = null;
        this.hoverboardData = null;

        this.visualCurrentSpeed = 0;
        this.visualCurrentYSpeed = 0;
        this.visualTurnSpeed = 0;
    }

    start() {
        this.hoverboardMeshObject = this.object.pp_getObjectByName("Board").pp_getComponent(MeshComponent).object;
        this.hoverboardMeshObject.pp_setPositionLocal(vec3_create(0, 0, AVATAR_FORWARD_OFFSET_FROM_HEAD));

        this.feetTargetObject = this.object.pp_getObjectByName("Feet IK Target");
        this.feetTargetObject.pp_setPositionLocal(vec3_create(0, 0.1, 0.1));

        this._setupStreams();

        this.resetHoverboard();
    }

    resetHoverboard() {
        this.sessionId = null;

        this.racing = false;
        this.onTrack = false;

        this.updateMeshVisibility = true;
    }

    setHoverboardData(hoverboardData) {
        this.hoverboardData = hoverboardData;
    }

    setOnTrack(onTrack) {
        const circularMap = common.circularMap;
        if (circularMap) {
            if (onTrack) {
                circularMap.showPlayerOnMap(this.sessionId);
            } else {
                circularMap.hidePlayerOnMap(this.sessionId);
            }
        }

        this.onTrack = onTrack;
        this.updateMeshVisibility = true;

        if (this.onTrack) {
            this.visualCurrentSpeed = 0;
            this.visualCurrentYSpeed = 0;
            this.visualTurnSpeed = 0;

            this.hoverboardMeshObject.pp_resetRotationLocal();
            this.feetTargetObject.pp_resetRotationLocal();

            this.feetTargetObject.pp_setPositionLocal(vec3_create(0, 0.1, AVATAR_FORWARD_OFFSET_FROM_HEAD / 3));
        } else {
            this.feetTargetObject.pp_setPositionLocal(vec3_create(0, 0.1, -AVATAR_FORWARD_OFFSET_FROM_HEAD * 2 / 3));
        }
    }

    setRacing(racing) {
        this.racing = racing;
    }

    getHoverboardMeshObject() {
        return this.hoverboardMeshObject;
    }

    onActivate() {
        this.updateMeshVisibility = true;
    }

    update(dt) {
        // Implemented outside class definition
    }

    _setupStreams() {
        this.frontStreams = {};
        this.backStreams = {};

        let streamScale = vec3_create(1, 1, 0);

        this.backStreams[Handedness.LEFT] = this.hoverboardMeshObject.pp_getObjectByName("Stream_BL");
        this.backStreams[Handedness.LEFT].pp_scaleObject(streamScale);

        this.backStreams[Handedness.RIGHT] = this.hoverboardMeshObject.pp_getObjectByName("Stream_BR");
        this.backStreams[Handedness.RIGHT].pp_scaleObject(streamScale);

        this.frontStreams[Handedness.LEFT] = this.hoverboardMeshObject.pp_getObjectByName("Stream_FL");
        this.frontStreams[Handedness.LEFT].pp_scaleObject(streamScale);

        this.frontStreams[Handedness.RIGHT] = this.hoverboardMeshObject.pp_getObjectByName("Stream_FR");
        this.frontStreams[Handedness.RIGHT].pp_scaleObject(streamScale);
    }

    _updateVisualAndSound(dt) {
        // Implemented outside class definition
    }
}



NetworkedHoverboardComponent.prototype.update = function () {
    return function update(dt) {
        if (this.updateMeshVisibility) {
            this.updateMeshVisibility = false;
            this.getHoverboardMeshObject().pp_setActive(this.onTrack);

            if (this.avatar != null) {
                this.avatar.setHoverboardStanceActive(this.onTrack);
            }
        }

        if (this.onTrack && this.hoverboardData != null) {
            this._updateVisualAndSound(dt);
        }
    };
}();

NetworkedHoverboardComponent.prototype._updateVisualAndSound = function () {
    let streamScale = vec3_create();
    return function _updateVisualAndSound(dt) {
        // Visual
        this.visualCurrentSpeed = Math.pp_lerp(this.visualCurrentSpeed, this.hoverboardData.currentSpeed, 5 * dt);
        this.visualCurrentYSpeed = Math.pp_lerp(this.visualCurrentYSpeed, this.hoverboardData.currentYSpeed, 5 * dt);
        this.visualTurnSpeed = Math.pp_lerp(this.visualTurnSpeed, this.hoverboardData.currentTurnSpeed, 5 * dt);

        let boardTiltAngle = MathUtils.lerp(0, 50, Math.abs(this.visualTurnSpeed) / common.hoverboard.maxTurnSpeed) * Math.pp_sign(this.visualTurnSpeed);

        this.hoverboardMeshObject.pp_resetRotationLocal();
        this.hoverboardMeshObject.pp_rotateAxisObject(-boardTiltAngle, GameGlobals.forward);

        this.feetTargetObject.pp_resetRotationLocal();
        this.feetTargetObject.pp_rotateAxisObject(-boardTiltAngle, GameGlobals.forward);

        this.hoverboardMeshObject.pp_resetRotationLocal();
        this.hoverboardMeshObject.pp_rotateAxisObject(this.visualTurnSpeed * -0.6, GameGlobals.forward);

        this.feetTargetObject.pp_resetRotationLocal();
        this.feetTargetObject.pp_rotateAxisObject(this.visualTurnSpeed * -0.6, GameGlobals.forward);

        let boardVerticalTilt = Math.pp_mapToRange(this.visualCurrentYSpeed, -25, 25, -25, 25);
        this.hoverboardMeshObject.pp_rotateAxisObject(boardVerticalTilt, GameGlobals.right);

        this.hoverboardMeshObject.pp_rotateAxisObject(180, GameGlobals.up);

        this.speedPercentage = Math.min(1, Math.abs(this.visualCurrentSpeed / common.hoverboard.maxSpeed));
        streamScale.vec3_set(1, 1, this.speedPercentage);
        this.frontStreams.left.pp_setScaleLocal(streamScale);
        this.frontStreams.right.pp_setScaleLocal(streamScale);
        this.backStreams.left.pp_setScaleLocal(streamScale);
        this.backStreams.right.pp_setScaleLocal(streamScale);

        this.frontStreams.left.pp_resetRotationLocal();
        this.frontStreams.left.pp_rotateAxisObjectDegrees(boardVerticalTilt * 3, GameGlobals.left);
        this.frontStreams.right.pp_resetRotationLocal();
        this.frontStreams.right.pp_rotateAxisObjectDegrees(boardVerticalTilt * 3, GameGlobals.left);
    };
}();
