import { Alignment, Background, Button, ClickState, Column, Container, FlexAlignment, Icon, Label, LayeredContainer, LeaveEvent, type Observable, PointerMoveEvent, PointerPressEvent, PointerReleaseEvent, type Rect, type Root, RoundedCorners, Row, Spacing, TextAlignMode, type Variable, type Viewport, Widget, type WidgetAutoXML, type WidgetEvent, type WidgetProperties, safeRoundRect } from "lazy-widgets";
import { type Gender } from "src/hoverfit/data/values/gender.js";
import { type ShopItem } from "src/hoverfit/misc/asset-provision/shop-item.js";
import { type ObservableItemIDCollectionEventListener, ObservableItemIDCollectionEventType } from "src/hoverfit/misc/data-structs/observable-item-id-collection.js";
import { getAssetThumbnail } from "src/hoverfit/utils/asset-utils.js";
import { AudioID } from "../../../audio/audio-manager/audio-id.js";
import { Audio } from "../../../audio/audio-manager/audio.js";
import common from "../../../common.js";
import { downHapticFeedback, hoverHapticFeedback, upHapticFeedback } from "../../../misc/haptic-feedback.js";
import { getEffectivePrice } from "../../misc/getEffectivePrice.js";
import { getCursorFromLZCID } from "../get-cursor-from-lzcid.js";
import { BevelledCorners } from "./bevelled-corners.js";
import { Carousel } from "./carousel.js";
import { FilterContainer } from "./filter-container.js";
import { PriceRow } from "./price-row.js";

export interface LocationButtonProperties extends WidgetProperties {
    releasedFilter?: string,
    hoverFilter?: string,
    holdFilter?: string,
    inactiveFilter?: string,
}

export const CURRENCY_ICONS = [
    `assets/textures/ui/icons/kiosk/heyvr-coin.svg`,
    `assets/textures/ui/icons/kiosk/fitabux.svg`,
];

export class ShopItemButton extends Button<FilterContainer> {
    static override autoXML: WidgetAutoXML = {
        name: "shop-item-button",
        inputConfig: [
            {
                mode: "value",
                name: "item",
            },
            {
                mode: "value",
                name: "selection-variable",
                validator: "variable",
            },
            {
                mode: "value",
                name: "gender",
                validator: "observable",
            },
        ]
    };

    private _releasedFilter: string;
    private _hoverFilter: string;
    private _holdFilter: string;
    private _inactiveFilter: string;
    private _curState = ClickState.Released;
    private _prevState = ClickState.Released;
    private _filterChangedExternally = false;
    private clickSound: Audio | null;
    private hoverSound: Audio | null;
    private inBounds = false;
    private forced: boolean;
    private ascendantCarousel: Carousel | null = null;
    private thumbnail: HTMLImageElement;

    constructor(readonly item: ShopItem, readonly selectionVariable: Variable<string>, readonly gender: Observable<Gender>, properties?: Readonly<LocationButtonProperties>) {
        const releasedFilter = properties?.releasedFilter ?? "";
        const [effectivePrice, discounted] = getEffectivePrice(item.price, item.priceDiscounted);
        const thumbnail = new Image();

        super(
            new FilterContainer(
                new Background(
                    new Column([
                        new Container(
                            new Label(
                                item.name,
                                {
                                    bodyTextFill: "black",
                                    bodyTextFont: "0.9em sui-generis",
                                    bodyTextAlign: TextAlignMode.Center,
                                    bodyTextSpacing: 0,
                                },
                            ),
                            {
                                containerPadding: { left: 0, right: 0, top: 4, bottom: 0 },
                            },
                        ),
                        new Container(
                            new RoundedCorners(
                                new BevelledCorners(
                                    new LayeredContainer<Widget>([
                                        {
                                            child: new Background(
                                                new Icon(thumbnail),
                                                {
                                                    canvasFill: '#d9d9d9',
                                                    containerPadding: { left: 0, top: 0, right: 0, bottom: 0 },
                                                },
                                            ),
                                        },
                                        {
                                            child: new Column([
                                                new Row([
                                                    new Spacing(),
                                                    new RoundedCorners(
                                                        new Background(
                                                            new PriceRow(
                                                                effectivePrice,
                                                                item.currencyType,
                                                                {
                                                                    bodyTextFill: discounted ? "white" : "black",
                                                                    bodyTextFont: "0.9em sui-generis",
                                                                    iconSize: 10,
                                                                }
                                                            ),
                                                            {
                                                                canvasFill: discounted ? "red" : "white",
                                                            }
                                                        ),
                                                        {
                                                            roundedCornersRadii: [6, 6, 0, 0],
                                                        },
                                                    ),
                                                    new Spacing(),
                                                ]),
                                            ], {
                                                multiContainerAlignment: {
                                                    main: FlexAlignment.End,
                                                    cross: Alignment.Center,
                                                },
                                            }),
                                        },
                                    ], 0, {
                                        minWidth: 88, maxWidth: 88,
                                        minHeight: 88, maxHeight: 88,
                                    }),
                                    {
                                        roundedCornersRadii: [24, 0, 0, 0],
                                    },
                                ),
                            ),
                            {
                                containerPadding: {
                                    left: 4, right: 4, top: 4, bottom: 4
                                },
                            },
                        )
                    ]),
                    {
                        containerPadding: { left: 0, right: 0, top: 0, bottom: 0 },
                        canvasFill: "white",
                        minWidth: 96, maxWidth: 96,
                    },
                ),
                {
                    filter: releasedFilter,
                },
            ),
            {
                containerPadding: { left: 0, right: 0, top: 0, bottom: 0 },
                ...properties,
            },
        );

        this._releasedFilter = releasedFilter;
        // XXX these can either be css filters, or an rgba array containing the
        // tint color that will be overlayed, with RGB components in the range
        // 0-255, and A component in the range 0-1
        this._hoverFilter = properties?.hoverFilter ?? "brightness(75%)";// [127, 131, 255, 0.75];
        this._holdFilter = properties?.holdFilter ?? "brightness(50%)";// [127, 131, 255, 0.5];
        this._inactiveFilter = properties?.inactiveFilter ?? "brightness(25%)";// [127, 131, 255, 0.25];

        this.clickSound = common.audioManager.getAudio(AudioID.BUTTON_CLICK);
        this.hoverSound = common.audioManager.getAudio(AudioID.BUTTON_HOVER);

        this.thumbnail = thumbnail;

        const selectionValue = item.id;
        this.forced = this.selectionVariable.value === selectionValue;

        this.on("click", () => {
            this.selectionVariable.value = selectionValue;
        });
    }

    private readonly onInventoryChanged: ObservableItemIDCollectionEventListener = (type, ids) => {
        const isPurchase = type === ObservableItemIDCollectionEventType.Add;
        if (isPurchase === this.clickable && ids.indexOf(this.item.id) >= 0) {
            this.setPurchased(isPurchase);
        }
    };

    private setPurchased(purchased: boolean) {
        this.clickable = !purchased;
        if (purchased) {
            this.child.filter = this._inactiveFilter;
        } else {
            this._updatePickedFilter();
        }
    }

    get releasedFilter() {
        return this._releasedFilter;
    }

    set releasedFilter(releasedFilter) {
        if (this._releasedFilter !== releasedFilter) {
            this._releasedFilter = releasedFilter;
            this._filterChangedExternally = true;
        }
    }

    get hoverFilter() {
        return this._hoverFilter;
    }

    set hoverFilter(hoverFilter) {
        if (this._hoverFilter !== hoverFilter) {
            this._hoverFilter = hoverFilter;
            this._filterChangedExternally = true;
        }
    }

    get holdFilter() {
        return this._holdFilter;
    }

    set holdFilter(holdFilter) {
        if (this._holdFilter !== holdFilter) {
            this._holdFilter = holdFilter;
            this._filterChangedExternally = true;
        }
    }

    get inactiveFilter() {
        return this._inactiveFilter;
    }

    set inactiveFilter(inactiveFilter) {
        if (this._inactiveFilter !== inactiveFilter) {
            this._inactiveFilter = inactiveFilter;
            this._filterChangedExternally = true;
        }
    }

    private _updatePickedFilter() {
        switch (this._curState) {
            case ClickState.Hover:
                this.child.filter = this._hoverFilter;
                break;
            case ClickState.Hold:
                this.child.filter = this._holdFilter;
                break;
            default:
                this.child.filter = this._releasedFilter;
                break;
        }
    }

    override click() {
        if (this.clickSound) {
            this.clickSound.play();
        }

        super.click();
    }

    override handleEvent(event: WidgetEvent) {
        const captured = super.handleEvent(event);

        if (this.clickHelper.clickStateChanged) {
            this._curState = this.clickHelper.clickState;
        }

        if (this.active && this.clickable) {
            if (event.isa(PointerMoveEvent)) {
                if (!this.inBounds) {
                    this.inBounds = true;
                    this.hoverSound!.play();
                    const cursor = getCursorFromLZCID(event);
                    if (cursor) hoverHapticFeedback(cursor);
                }
            } else if (event.isa(LeaveEvent)) {
                if (this.inBounds) this.inBounds = false;
            } else if (event.isa(PointerPressEvent)) {
                const cursor = getCursorFromLZCID(event);
                if (cursor) downHapticFeedback(cursor);
            } else if (event.isa(PointerReleaseEvent)) {
                const cursor = getCursorFromLZCID(event);
                if (cursor) upHapticFeedback(cursor);
            }
        }

        return captured;
    }

    override set clickable(clickable: boolean) {
        if (!clickable) {
            this.inBounds = false;
        }

        super.clickable = clickable;
    }

    override get clickable() {
        return super.clickable;
    }

    private onGenderSwitch = () => {
        this.thumbnail.src = getAssetThumbnail(this.item.asset, this.gender.value);
    };

    protected override activate(): void {
        super.activate();
        this.gender.watch(this.onGenderSwitch, true);
    }

    protected override deactivate() {
        this.gender.unwatch(this.onGenderSwitch);
        super.deactivate();
        this.inBounds = false;
    }

    override handlePostLayoutUpdate() {
        super.handlePostLayoutUpdate();

        if (this._curState !== this._prevState && this.clickable) {
            this._prevState = this._curState;
            this._updatePickedFilter();
        } else if (this._filterChangedExternally) {
            this._updatePickedFilter();
        }

        this._filterChangedExternally = false;

        if (this.ascendantCarousel && this.forced) {
            this.ascendantCarousel.slideToDescendant(this);
        }
    }

    protected override handlePainting(dirtyRects: Rect[]): void {
        const ctx = this.viewport.context;
        ctx.save();
        ctx.beginPath();
        safeRoundRect(ctx, this.x, this.y, this.width, this.height, this.roundedCornersRadii);
        ctx.clip();

        super.handlePainting(dirtyRects);

        ctx.beginPath();
        safeRoundRect(ctx, this.x, this.y, this.width, this.height, this.roundedCornersRadii);
        const baseLineColor = this.forced ? "0,192,255" : (this.clickable ? "0,0,64" : "128,128,128");
        ctx.strokeStyle = `rgba(${baseLineColor},0.8)`;
        ctx.lineWidth = this.forced ? 6 : 2;
        ctx.stroke();

        ctx.restore();
    }

    override attach(root: Root, viewport: Viewport, parent: Widget | null): void {
        super.attach(root, viewport, parent);

        let focus = this.parent;
        while (focus) {
            if (focus instanceof Carousel) {
                this.ascendantCarousel = focus;
                break;
            }
            focus = focus.parent;
        }

        common.kioskLowerUI.iapContentController.inventory.watch(this.onInventoryChanged, true);
    }

    override detach(): void {
        common.kioskLowerUI.iapContentController.inventory.unwatch(this.onInventoryChanged);
        super.detach();
        this.ascendantCarousel = null;
    }
}