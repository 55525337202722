import { Alignment, Column, Container, FlexAlignment, Icon, Label, LiveLabel, ObservableTransformer, RoundedCorners, Row, TextAlignMode, filterIDFromProperties, type Observable, type WidgetAutoXML, type WidgetProperties } from "lazy-widgets";
import common from "src/hoverfit/common.js";
import { FeedbackFilledButton } from "../../lazy-widgets/widgets/feedback-filled-button.js";

export interface ScoreRowProperties extends WidgetProperties {
    dark?: boolean;
}

export class ScoreRow extends Row {
    static override autoXML: WidgetAutoXML = {
        name: "score-row",
        inputConfig: [
            {
                name: "fit-points",
                mode: "value",
                validator: "observable",
            },
            {
                name: "fitabux",
                mode: "value",
                validator: "observable",
            },
            {
                name: "heyvr-coins",
                mode: "value",
                validator: "observable",
            },
        ],
    };

    constructor(fitPoints: Observable<number>, fitabux: Observable<number>, heyvrCoins: Observable<number>, properties?: Readonly<ScoreRowProperties>) {
        const filteredProperties = filterIDFromProperties(properties);
        const dark = properties?.dark ?? false;

        function makeCard(source: Observable<number>, labelName: string, iconName: string, clickCallback: () => void) {
            return new Column([
                new Row([
                    new Label(
                        labelName, {
                        bodyTextAlign: TextAlignMode.Center,
                        bodyTextFont: "1.2em sui-generis",
                        bodyTextHeight: 10, // HACK provides a better baseline for the text
                        bodyTextFill: "white",
                        ...filteredProperties,
                        flex: 1,
                    }
                    )
                ]),
                new RoundedCorners(
                    new FeedbackFilledButton(
                        new Container(
                            new Row([
                                new LiveLabel(
                                    new ObservableTransformer([source], () => `${Math.floor(source.value)}`),
                                    {
                                        bodyTextAlign: TextAlignMode.End,
                                        bodyTextFont: "1.5em sui-generis",
                                        bodyTextHeight: 10, // HACK provides a better baseline for the text
                                        bodyTextFill: "white",
                                        ...filteredProperties,
                                        flex: 1,
                                    },
                                ),
                                new Icon(
                                    `assets/textures/ui/icons/kiosk/${iconName}.svg`,
                                    {
                                        ...filteredProperties,
                                        // XXX this is bigger than it fits, which is
                                        //     on purpose, because it creates a
                                        //     small space around the icon
                                        //     horizontally
                                        width: 24,
                                        height: 24,
                                    }
                                ),
                            ], {
                                multiContainerSpacing: 8,
                                multiContainerAlignment: {
                                    main: FlexAlignment.SpaceAround,
                                    cross: Alignment.Stretch,
                                },
                                ...filteredProperties,
                            }),
                            {
                                containerPadding: { top: 0, right: 0, bottom: 0, left: 0 },
                                ...filteredProperties,
                            },
                        ),
                        {
                            backgroundFill: dark ? "rgba(0, 0, 0, 0.25)" : "rgba(255, 255, 255, 0.2)",
                            backgroundGlowFill: dark ? "rgba(0, 0, 0, 0.35)" : "rgba(255, 255, 255, 0.3)",
                            accentFill: dark ? "rgba(0, 0, 0, 0.45)" : "rgba(255, 255, 255, 0.4)",
                            primaryFill: dark ? "rgba(0, 0, 0, 0.45)" : "rgba(255, 255, 255, 0.4)",
                            containerPadding: { top: 4, right: 6, bottom: 4, left: 6 },
                            ...filteredProperties,
                        },
                    ).on("click", clickCallback), {
                    roundedCornersRadii: 6,
                    ...filteredProperties,
                    minWidth: 160, maxWidth: 160,
                    minHeight: 24, maxHeight: 24,
                })
            ]);
        }

        super([
            makeCard(fitPoints, "LIFETIME FIT POINTS", "fitpoints", () => { common.kioskLowerUI.displayFitPointsInfo(); }),
            makeCard(fitabux, "FITABUX", "fitabux", () => { common.kioskLowerUI.displayFitabuxInfo(); }),
            makeCard(heyvrCoins, "HEYVR COINS", "heyvr-coin", () => { common.kioskLowerUI.displayHeyVRCoinsInfo(); }),
        ], {
            multiContainerAlignment: {
                main: FlexAlignment.SpaceBetween,
                cross: Alignment.Stretch,
            },
            ...properties,
        });
    }
}