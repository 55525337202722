import { Component, Property } from '@wonderlandengine/api';
import anime from "animejs";
import { GameGlobals } from 'src/hoverfit/misc/game-globals.js';
import { getTime } from "src/hoverfit/utils/time-utils.js";
import { AnimatedNumber, AnimatedNumberParams, Timer, vec4_create } from "wle-pp";

export class AvatarFaceComponent extends Component {
    static TypeName = 'avatar-face';
    /* Properties that are configurable in the editor */
    static Properties = {
        lowerJaw: Property.object(),
        lowerJawMaxXRotation: Property.float(15.0),
        upperEyeLidLeft: Property.object(),
        upperEyeLidLeftMaxXRotation: Property.float(75.0),
        upperEyeLidRight: Property.object(),
        upperEyeLidRightMaxXRotation: Property.float(75.0),
        randomBlink: Property.bool(true),
        blinkDuration: Property.float(0.3),
        blinkTimerBaseDelay: Property.float(3.5),
        blinkTimerRandomAdditionalDelayOffset: Property.float(0.5),
    };

    lowerJawDefaultRotation = vec4_create();
    upperEyeLidLeftDefaultRotation = vec4_create();
    upperEyeLidRightDefaultRotation = vec4_create();

    start() {
        this.lowerJaw.getRotationLocal(this.lowerJawDefaultRotation);
        this.lowerJaw.rotateAxisAngleDegObject([1, 0, 0], -5);
        this.lowerJaw.getRotationLocal(this.lowerJawDefaultRotation);

        this.upperEyeLidLeft.getRotationLocal(this.upperEyeLidLeftDefaultRotation);
        this.upperEyeLidRight.getRotationLocal(this.upperEyeLidRightDefaultRotation);
        this.upperEyeLidLeft.rotateAxisAngleDegObject([1, 0, 0], 5);
        this.upperEyeLidRight.rotateAxisAngleDegObject([1, 0, 0], 5);
        this.upperEyeLidLeft.getRotationLocal(this.upperEyeLidLeftDefaultRotation);
        this.upperEyeLidRight.getRotationLocal(this.upperEyeLidRightDefaultRotation);

        this.blinkTimer = new Timer(this.blinkTimerBaseDelay + (Math.random() * this.blinkTimerRandomAdditionalDelayOffset) - (this.blinkTimerRandomAdditionalDelayOffset / 2));

        let mouthOpennessParams = new AnimatedNumberParams();
        mouthOpennessParams.myInitialValue = 0;
        mouthOpennessParams.myAnimationSeconds = 0.15;
        this.mouthOpenness = new AnimatedNumber(mouthOpennessParams);

        this.mouthOpennessTarget = 0;
        this.waitMouthClose = true;
    }

    setMouthOpenness(amount) {
        this.mouthOpennessTarget = amount;
    }

    _restartBlinkTimer() {
        this.blinkTimer.start(this.blinkTimerBaseDelay + (Math.random() * this.blinkTimerRandomAdditionalDelayOffset) - (this.blinkTimerRandomAdditionalDelayOffset / 2));
    }

    blink() {
        this.blinkAmount = 0.0;
        let loopCount = 0;
        this.blinkAnim = anime({
            targets: this,
            easing: "easeOutQuad",
            blinkAmount: 1.0,
            duration: this.blinkDuration * 500,
            loop: 1,
            direction: "alternate",
            autoplay: false,
            update: (anim) => {
                if (this.blinkAnim != null) {
                    this.upperEyeLidLeft.resetRotation();
                    this.upperEyeLidLeft.setRotationLocal(this.upperEyeLidLeftDefaultRotation);
                    this.upperEyeLidLeft.rotateAxisAngleDegObject([1, 0, 0], this.blinkAmount * this.upperEyeLidLeftMaxXRotation);

                    this.upperEyeLidRight.resetRotation();
                    this.upperEyeLidRight.setRotationLocal(this.upperEyeLidRightDefaultRotation);
                    this.upperEyeLidRight.rotateAxisAngleDegObject([1, 0, 0], this.blinkAmount * this.upperEyeLidRightMaxXRotation);
                }
            },
            loopComplete: (anim) => {
                loopCount++;
                if (loopCount === 2) {
                    this.upperEyeLidLeft.resetRotation();
                    this.upperEyeLidLeft.setRotationLocal(this.upperEyeLidLeftDefaultRotation);
                    this.upperEyeLidRight.resetRotation();
                    this.upperEyeLidRight.setRotationLocal(this.upperEyeLidRightDefaultRotation);

                    this.blinkAnim = null;
                }
            }
        });

    }

    update(dt) {
        if (this.blinkAnim) this.blinkAnim.tick(getTime());
        this.blinkTimer.update(dt);

        if (this.blinkTimer.isDone()) {
            this._restartBlinkTimer();
            this.isBlinking = true;
            this.blink();
        }

        if (!this.waitMouthClose && this.mouthOpenness.getTargetValue() != 0 && this.mouthOpenness.isDone()) {
            this.mouthOpennessTarget = 0;
            this.mouthOpenness.setTargetValue(0);
            this.waitMouthClose = true;
        }

        if (this.waitMouthClose && this.mouthOpennessTarget > 0 && this.mouthOpenness.getCurrentValue() == 0) {
            this.waitMouthClose = false;
            this.mouthOpenness.getParams().myAnimationSeconds = Math.pp_random(0.075, 0.25);
            this.mouthOpenness.setTargetValue(Math.pp_random(Math.max(0, this.mouthOpennessTarget - 0.25), Math.max(1, this.mouthOpennessTarget + 0.25)));
        }

        this.mouthOpenness.update(dt);

        this.lowerJaw.resetRotation();
        this.lowerJaw.setRotationLocal(this.lowerJawDefaultRotation);
        this.lowerJaw.rotateAxisAngleDegObject(GameGlobals.left, this.mouthOpenness.getCurrentValue() * this.lowerJawMaxXRotation);
    }
}
