import { Component } from '@wonderlandengine/api';
import { PickupGlue, PickupGlueParams } from '../implementations/pickup-glue.js';
import { PickupsManager } from '../pickups-manager.js';
import { PickupComponent } from './pickup-component.js';

export class PickupGlueComponent extends Component implements PickupComponent {
    static TypeName = "pickup-glue";

    static onRegister() {
        PickupsManager.getPickupComponentTypes().pp_pushUnique(PickupGlueComponent);
    }

    createPickup(): PickupGlue {
        const params = new PickupGlueParams();
        return new PickupGlue(this.object, params);
    }
}