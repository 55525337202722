import { Globals, Handedness } from "wle-pp";

export function hapticFeedback(cursor, strength, duration) {
    if (cursor && (cursor.handedness == Handedness.LEFT || cursor.handedness == Handedness.RIGHT)) {
        const gamepad = Globals.getGamepad(cursor.handedness);
        if (gamepad) gamepad.pulse(strength, duration);
    }
}

export function hoverHapticFeedback(cursor) {
    hapticFeedback(cursor, 0.25, 0.05);
}

export function unHoverHapticFeedback(cursor) {
    //hapticFeedback(cursor, 0.3, 0.05);
}

export function downHapticFeedback(cursor) {
    //hapticFeedback(cursor, 1.0, 0.02);
}

export function upHapticFeedback(cursor) {
    hapticFeedback(cursor, 0.25, 0.05);
}