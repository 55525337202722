import { Widget, type Rect, type WidgetAutoXML, type WidgetProperties } from "lazy-widgets";

let UP_ARROW_SHAPE: Path2D;
if (WL_EDITOR) {
    UP_ARROW_SHAPE = null as unknown as Path2D;
} else {
    UP_ARROW_SHAPE = new Path2D("M 0.5 0.1464 L 0 0.8536 L 1 0.8536 z");
}

export enum ArrowDirection {
    Up = "up",
    Right = "right",
    Down = "down",
    Left = "left",
}

export interface ArrowIconProperties extends WidgetProperties {
    iconSize?: number;
}

export class ArrowIcon extends Widget {
    static override autoXML: WidgetAutoXML = {
        name: "arrow-icon",
        inputConfig: [
            {
                mode: "value",
                name: "direction",
            }
        ]
    };

    private _iconSize: number;

    constructor(readonly direction: ArrowDirection, properties?: Readonly<ArrowIconProperties>) {
        super(properties);

        this._iconSize = properties?.iconSize ?? 24;
    }

    get iconSize() {
        return this._iconSize;
    }

    set iconSize(iconSize: number) {
        if (iconSize === this._iconSize) return;
        this._iconSize = iconSize;
        this._layoutDirty = true;
        this.markWholeAsDirty();
    }

    protected override onThemeUpdated(property: string | null = null): void {
        super.onThemeUpdated(property);

        if (property === null) {
            this._layoutDirty = true;
            this.markWholeAsDirty();
        } else if (property === "bodyTextFill") {
            this.markWholeAsDirty();
        }
    }

    protected override handleResolveDimensions(minWidth: number, maxWidth: number, minHeight: number, maxHeight: number): void {
        this.idealWidth = Math.max(minWidth, Math.min(maxWidth, this._iconSize));
        this.idealHeight = Math.max(minHeight, Math.min(maxHeight, this._iconSize));
    }

    protected override handlePainting(_dirtyRects: Rect[]): void {
        const ctx = this.viewport.context;
        ctx.save();
        ctx.translate(this.x + this.width * 0.5, this.y + this.height * 0.5);

        switch (this.direction) {
            case ArrowDirection.Right:
                ctx.rotate(Math.PI / 2);
                break;
            case ArrowDirection.Down:
                ctx.rotate(Math.PI);
                break;
            case ArrowDirection.Left:
                ctx.rotate(-Math.PI / 2);
                break;
        }

        const iconSize = Math.min(this.width, this.height, this._iconSize);
        const halfIconSize = iconSize * 0.5;
        ctx.translate(-halfIconSize, -halfIconSize);
        ctx.scale(iconSize, iconSize);

        ctx.fillStyle = this.bodyTextFill;
        ctx.fill(UP_ARROW_SHAPE, "evenodd");

        ctx.restore();
    }
}