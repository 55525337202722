import { Object3D, TextComponent } from "@wonderlandengine/api";
import { NumberOverFactor } from "wle-pp";
import { PriorityLevel } from "../../../misc/data-structs/expiring-priority-queue.js";
import { PopupIconImage, PopupParams, PopupResources, PopupType, PopupWindowImplementation, PopupWindowParams } from "../popup.js";

export class LapCompletedPopupWindowParams extends PopupWindowParams {
    override popupIconImage: PopupIconImage = PopupIconImage.StopWatch;

    lastLap: number = 0;
    lapsAmount: number = 0;

    lapTime: number = 0;
    currentTime: number = 0;

    lapFitPoints: number = 0;
    currentFitPoints: number = 0;

    lapSquats: number = 0;
    totalSquats: number = 0;

    lapPlayerPosition: number = 0;
}

export class LapCompletedPopupParams extends PopupParams<LapCompletedPopupWindowParams> {
    readonly popupType: PopupType = PopupType.LapCompleted;

    popupWindowParams: LapCompletedPopupWindowParams = new LapCompletedPopupWindowParams();

    delaySeconds: number | NumberOverFactor = 0;
    durationSeconds: number | NumberOverFactor = 5;
    showSeconds: number | NumberOverFactor = 1;
    hideSeconds: number | NumberOverFactor = 1;

    gamepadPulseParam: { intensity: number, duration: number } = { intensity: 0.3, duration: 0.1 };

    constructor() {
        super();

        this.priorityParams.priorityLevel = PriorityLevel.VeryHigh;
        this.priorityParams.expireSeconds = Infinity;
    }
}

export class LapCompletedPopupWindow extends PopupWindowImplementation<LapCompletedPopupWindowParams> {
    private _fieldNamesTextComponent: TextComponent;
    private _fieldValuesTextComponent: TextComponent;

    constructor(visualObject: Object3D) {
        super(visualObject);

        this._fieldNamesTextComponent = this._visualObject.pp_getObjectByName("Field Names")!.getComponent(TextComponent) as TextComponent;
        this._fieldValuesTextComponent = this._visualObject.pp_getObjectByName("Field Values")!.getComponent(TextComponent) as TextComponent;
        this._fieldNamesTextComponent.text = "";
        this._fieldValuesTextComponent.text = "";
    }

    override prepare(params: LapCompletedPopupWindowParams, resources: PopupResources): void {
        super.prepare(params, resources);

        const sideSpace = "        ";

        let fieldNamesText = "";
        //fieldNamesText += "\n";
        fieldNamesText += sideSpace + "LAP:\n";
        //fieldNamesText += sideSpace + "CURRENT TIME:\n";
        //fieldNamesText += sideSpace + "CURRENT FIT POINTS:\n";
        //fieldNamesText += sideSpace + "RACE POSITION:\n";
        fieldNamesText += sideSpace + "LAP FIT POINTS:\n";
        fieldNamesText += "\n";
        fieldNamesText += sideSpace + "LAP SQUATS:\n";
        fieldNamesText += sideSpace + "TOTAL SQUATS:\n";
        //fieldNamesText += sideSpace + "LAP TIME:";

        this._fieldNamesTextComponent.text = fieldNamesText;

        const lapsAmountDigitCount = params.lapsAmount.toString().length;

        let fieldValuesText = "";
        //fieldValuesText += "\n";
        fieldValuesText += params.lastLap.toString().padStart(lapsAmountDigitCount, "0") + " of " + params.lapsAmount.toString() + sideSpace + "\n";
        //fieldValuesText += formatScoreToTime(params.currentTime) + sideSpace + "\n";
        //fieldValuesText += params.currentFitPoints + "sideSpace+\n";
        //fieldValuesText += params.lapPlayerPosition + getRacePlacementSuffix(params.lapPlayerPosition) + sideSpace + "\n";
        fieldValuesText += params.lapFitPoints + sideSpace + "\n";
        fieldValuesText += "\n";
        fieldValuesText += params.lapSquats + sideSpace + "\n";
        fieldValuesText += params.totalSquats + sideSpace + "\n";
        //fieldValuesText += formatScoreToTime(params.lapTime) + sideSpace;

        this._fieldValuesTextComponent.text = fieldValuesText;
    }
}