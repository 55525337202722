export class TrackStatistics {
    maxSpeed: number = 0;
    maxAirSeconds: number = 0;
    squatsAmount: number = 0;
    jumpsAmount: number = 0;

    reset(): void {
        this.maxSpeed = 0;
        this.maxAirSeconds = 0;
        this.squatsAmount = 0;
        this.jumpsAmount = 0;
    }
}