import { Component, MeshComponent, Object3D } from "@wonderlandengine/api";
import { property } from "@wonderlandengine/api/decorators.js";
import { PhongMaterial } from "src/hoverfit/types/material-types.js";
import { Vector4 } from "wle-pp";

export class TagRingComponent extends Component {
    static TypeName = "tag-ring";

    @property.color()
    chaserColor!: Vector4;

    @property.color()
    evaderColor!: Vector4;

    private _tagRing!: Object3D;
    private _tagMaterials: PhongMaterial[] = [];

    start() {
        this._tagRing = this.object.pp_getObjectByName("Tag Ring")!;

        const meshes = this._tagRing.pp_getComponents(MeshComponent);
        for (const mesh of meshes) {
            this._tagMaterials.push(mesh.material as PhongMaterial);
        }

        this.setVisible(false);
    }

    setCatchDistance(distance: number) {
        this._tagRing.pp_setScale(distance);
    }

    setVisible(visible: boolean) {
        this._tagRing.pp_setActive(visible);
    }

    setTaggedState(tagged: boolean) {
        let color = null;
        if (tagged) {
            color = this.chaserColor;
        } else {
            color = this.evaderColor;
        }

        for (const material of this._tagMaterials) {
            material.diffuseColor = color;
            material.specularColor = color;
        }
    }
}
