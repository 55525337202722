if (WL_EDITOR) {
    globalThis.BUILD_TIMESTAMP = 0;
}

const bt = new Date(BUILD_TIMESTAMP);
export const BUILD_TIMESTAMP_DATE_FORMATTED = `${bt.getUTCFullYear()}-${datetimePad(bt.getUTCMonth() + 1)}-${datetimePad(bt.getUTCDate())}`;
export const BUILD_TIMESTAMP_TIME_FORMATTED = `${datetimePad(bt.getUTCHours())}:${datetimePad(bt.getUTCMinutes())}:${datetimePad(bt.getUTCSeconds())}`;
export const BUILD_TIMESTAMP_FORMATTED = `${BUILD_TIMESTAMP_DATE_FORMATTED} ${BUILD_TIMESTAMP_TIME_FORMATTED}`;

function datetimePad(part: number) {
    return part.toString().padStart(2, "0");
}