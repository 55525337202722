import { Component, Property } from "@wonderlandengine/api";
import { GamepadButtonID, Globals, Timer, vec3_create } from "wle-pp";
import common from "../../../common.js";
import { GameGlobals } from "../../game-globals.js";

export class DisableFarObjectsComponent extends Component {
    static TypeName = "disable-far-objects";
    static Properties = {
        distanceToDisable: Property.float(900),
        distanceToDisableOnBalcony: Property.float(700),
        secondsBetweenDisables: Property.float(1),
        updateDrawOrderToLast: Property.bool(false),
        objectNamesToInclude: Property.string(""),
        objectNamesToExclude: Property.string("")
    };

    start() {
        this._activeObjectsTimer = new Timer(0.5, this.updateDrawOrderToLast);

        let namesToInclude = [];
        if (this.objectNamesToInclude.length > 0) {
            let toIncludeList = [...this.objectNamesToInclude.split(",")];
            for (let i = 0; i < toIncludeList.length; i++) {
                toIncludeList[i] = toIncludeList[i].trim();
            }
            namesToInclude.push(...toIncludeList);
        }

        let namesToExclude = [];
        if (this.objectNamesToInclude.length > 0) {
            let toExcludeList = [...this.objectNamesToExclude.split(",")];
            for (let i = 0; i < toExcludeList.length; i++) {
                toExcludeList[i] = toExcludeList[i].trim();
            }
            namesToExclude.push(...toExcludeList);
        }

        this.objectsToManage = [];
        let descendants = this.object.pp_getDescendants();
        for (let descendant of descendants) {
            if (this._isNameValid(descendant.pp_getName(), namesToInclude, namesToExclude)) {
                this.objectsToManage.push([descendant,
                    descendant.pp_getPositionWorld().vec3_removeComponentAlongAxis(GameGlobals.up),
                    descendant.pp_getPositionLocal()]);
            }
        }

        this.disableTimer = new Timer(this.secondsBetweenDisables);

        this.prevPlayerPosition = vec3_create();
    }

    update(dt) {
        // Implemented outside class definition
    }

    disableObjects() {
        // Implemented outside class definition
    }

    _isNameValid(name, includeList, excludeList) {
        let validName = includeList.length == 0;
        for (let includeName of includeList) {
            if (name.match(includeName) != null) {
                validName = true;
                break;
            }
        }

        if (validName) {
            for (let excludeName of excludeList) {
                if (name.match(excludeName) != null) {
                    validName = false;
                    break;
                }
            }
        }

        return validName;
    }
}




// IMPLEMENTATION

DisableFarObjectsComponent.prototype.update = function () {
    let playerPosition = vec3_create();
    return function update(dt) {
        if (this._activeObjectsTimer.isRunning()) {
            this._activeObjectsTimer.update(dt);
            if (this._activeObjectsTimer.isDone()) {
                const objectsToActive = this.objectsToManage.pp_clone();
                const zero = vec3_create();
                objectsToActive.sort((first, second) => {
                    return first[0].pp_getPosition().vec3_distanceSquared(zero) - second[0].pp_getPosition().vec3_distanceSquared(zero);
                });

                let currentIndex = 0;
                const randomIncrement = 13;
                while (objectsToActive.length > 0) {
                    const objectToActive = objectsToActive.pp_removeIndex(currentIndex);
                    objectToActive[0].pp_setActive(true);
                    currentIndex = (currentIndex + randomIncrement) % objectsToActive.length;
                }
            }
        }

        if (Globals.isDebugEnabled() && Globals.getLeftGamepad().getButtonInfo(GamepadButtonID.SQUEEZE).isPressEnd(6)) {
            for (let i = 0; i < this.objectsToManage.length; i++) {
                const object = this.objectsToManage[i][0];
                object.pp_setActive(false);
            }

            this._activeObjectsTimer.start();
        }

        playerPosition = common.hoverboard.object.pp_getPosition(playerPosition);
        this.disableTimer.update(dt);
        if (this.disableTimer.isDone() || this.prevPlayerPosition.vec3_distanceSquared(playerPosition) > 10 * 10) {
            this.disableTimer.start();
            this.disableObjects();
        }

        this.prevPlayerPosition.vec3_copy(playerPosition);
    };
}();

DisableFarObjectsComponent.prototype.disableObjects = function () {
    let flatPlayerPosition = vec3_create();
    let byeByePosition = vec3_create(0, -100000, 0);
    return function disableObjects() {
        if (this.objectsToManage == null) return;

        flatPlayerPosition = common.hoverboard.object.pp_getPosition(flatPlayerPosition);
        flatPlayerPosition = flatPlayerPosition.vec3_removeComponentAlongAxis(GameGlobals.up, flatPlayerPosition);
        for (let i = 0; i < this.objectsToManage.length; i++) {
            let object = this.objectsToManage[i][0];
            let flatObjectPosition = this.objectsToManage[i][1];
            let objectPositionLocal = this.objectsToManage[i][2];
            flatObjectPosition = flatObjectPosition.vec3_removeComponentAlongAxis(GameGlobals.up, flatObjectPosition);

            let currentDistanceToDisable = common.balcony.isPlayerOnBalcony ? this.distanceToDisableOnBalcony : this.distanceToDisable;
            if (flatObjectPosition.vec3_distanceSquared(flatPlayerPosition) > currentDistanceToDisable * currentDistanceToDisable) {
                object.pp_setPositionLocal(byeByePosition);
            } else {
                object.pp_setPositionLocal(objectPositionLocal);
            }
        }
    };
}();