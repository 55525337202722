import { type ItemNamespace } from "../asset-provision/shop-item.js";
import { ObservableItemIDCollection, ObservableItemIDCollectionEventType } from "./observable-item-id-collection.js";

/** An observable ordered set of item IDs */
export class ObservableItemIDList extends ObservableItemIDCollection {
    override has(id: string) {
        if (this._suppressed) return false;
        return this.ids.indexOf(id) >= 0;
    }

    add(id: string) {
        if (this.has(id)) return false;
        this.ids.push(id);
        this.notify(ObservableItemIDCollectionEventType.Add, [id]);
        return true;
    }

    addMany(ids: ReadonlyArray<string>) {
        const added: string[] = [];
        for (let i = ids.length - 1; i >= 0; i--) {
            const id = ids[i];
            if (this.has(id)) continue;
            this.ids.push(id);
            added.push(id);
        }

        if (added.length > 0) {
            this.notify(ObservableItemIDCollectionEventType.Add, added);
        }
    }

    addShort(shortID: string, namespace: ItemNamespace) {
        this.add(`${namespace}:${shortID}`);
    }

    addManyShort(shortIDs: ReadonlyArray<string>, namespace: ItemNamespace) {
        const added: string[] = [];
        for (let i = shortIDs.length - 1; i >= 0; i--) {
            const id = `${namespace}:${shortIDs[i]}`;
            if (this.has(id)) continue;
            this.ids.push(id);
            added.push(id);
        }

        if (added.length > 0) {
            this.notify(ObservableItemIDCollectionEventType.Add, added);
        }
    }

    remove(id: string) {
        const i = this.ids.indexOf(id);
        if (i < 0) return false;
        this.ids.splice(i, 1);
        this.notify(ObservableItemIDCollectionEventType.Remove, [id]);
        return true;
    }

    removeMany(ids: ReadonlyArray<string>) {
        const removed: string[] = [];
        for (let i = ids.length - 1; i >= 0; i--) {
            const id = ids[i];
            const idx = this.ids.indexOf(id);
            if (idx < 0) continue;
            this.ids.splice(idx, 1);
            removed.push(id);
        }

        if (removed.length > 0) {
            this.notify(ObservableItemIDCollectionEventType.Remove, removed);
        }
    }

    removeNamespace(namespace: ItemNamespace) {
        const prefix = `${namespace}:`;
        const removed: string[] = [];
        for (let i = this.ids.length - 1; i >= 0; i--) {
            const id = this.ids[i];
            if (!id.startsWith(prefix)) continue;
            this.ids.splice(i, 1);
            removed.push(id);
        }

        if (removed.length > 0) {
            this.notify(ObservableItemIDCollectionEventType.Remove, removed);
        }
    }
}