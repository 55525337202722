import { Component, Property } from "@wonderlandengine/api";
import { quat, vec3 } from "gl-matrix";
import { MathUtils, ObjectUtils, QuatUtils, Vec3Utils, quat_create, vec3_create } from "wle-pp";
import common from "../../common.js";
import { GameGlobals } from "../../misc/game-globals.js";
import { AvatarComponent } from "./avatar-component.js";
import { WristComponent } from "./wrist-component.js";

// try to put the memory as close to each other as possible to (maybe) increase
// cache hits. this probably does nothing though because javascript's memory is
// all over the place
// 4x quat (16 bytes per quat) + 7x vec3 (12 bytes per vec3)
const workingBuffer = new ArrayBuffer(160);
const headRotationQuat = new Float32Array(workingBuffer, 0, 4);
const headRotationLocalQuat = new Float32Array(workingBuffer, 16, 4);
const headParentRotationQuat = new Float32Array(workingBuffer, 32, 4);
const avatarHeadForward = new Float32Array(workingBuffer, 48, 3);
const avatarHeadUp = new Float32Array(workingBuffer, 60, 3);
const avatarHeadParentForward = new Float32Array(workingBuffer, 72, 3);
const sourceFeetPosition = new Float32Array(workingBuffer, 84, 3);
const sourceHeadPosition = new Float32Array(workingBuffer, 96, 3);
const currentHeightVector = new Float32Array(workingBuffer, 108, 3);
const adjustedHipsPositionLocal = new Float32Array(workingBuffer, 120, 3);
const sourceFeetRotationQuat = new Float32Array(workingBuffer, 132, 4);
const avatarArmatureScale = new Float32Array(workingBuffer, 148, 3);

export class IKBridgeComponent extends Component {
    static TypeName = "ikbridge";
    static Properties = {
        head: Property.object(null),
        bodyRotationThresholdDeg: Property.float(45.0),
        currentPlayer: Property.bool(false)
    };

    static onRegister(engine) {
        engine.registerComponent(WristComponent);
    }

    init() {
        this.forcedSyncForwardWithHead = false;
        this.syncForwardWithHeadCounter = 0;

        this.avatar = this.object.getComponent(AvatarComponent);

        this.ikSolverList = [];

        this.minHipsHeight = 0.5;

        this.ready = false;

        this.startHipsPosition = vec3_create();
        this.startHipsRotationQuat = quat_create();
    }

    start() {
        this.doupdate = this.ikcheck;

        this.lastSyncForwardSourceFeetPosition = vec3_create();
    }

    update(dt) {
        this.doupdate(dt);
    }

    addIKSolver(ikSolver) {
        this.ikSolverList.push(ikSolver);
        ikSolver.active = false;
    }

    isReady() {
        return this.ready;
    }

    ikcheck(dt) {
        if (this.avatar.avatarRoot) {
            this.targetHandL = this.avatar.handL;
            this.targetHandR = this.avatar.handR;

            this.avatarHips = this.avatar.bones["b_Hips"];
            this.avatarArmature = this.avatar.bones["b_Hips"].parent;
            while (this.avatarArmature.pp_getName() != "Armature") {
                this.avatarArmature = this.avatarArmature.parent;
            }

            this.wristCheck();

            this.avatarObject = this.avatarArmature.parent;
            while (this.avatarObject.pp_getName() != "Avatar") {
                this.avatarObject = this.avatarObject.parent;
            }

            this.avatarHead = this.avatar.bones["b_Head"];
            this.avatarHeadTopEnd = this.avatar.bones["b_HeadTop_End"];

            let baseY = this.avatarArmature.pp_getPositionWorld()[1];
            let headY = this.avatarHeadTopEnd.pp_getPositionWorld()[1];
            let hipsY = this.avatarHips.pp_getPositionWorld()[1];
            this.avatarHeight = headY - baseY;
            this.avatarHipsHeight = hipsY - baseY;

            this.avatarHipsPositionLocal = this.avatarHips.pp_getPositionLocal();

            if (this.targetHandL) {  // the target object
                // now find the source object
                this.sourceHandL = this.object.pp_getObjectByNameHierarchy("Hand Left");

                this.sourceHandR = this.object.pp_getObjectByNameHierarchy("Hand Right");

                this.sourceHead = this.head || this.object.pp_getObjectByNameHierarchy("Head");

                this.sourceFeet = this.object.pp_getObjectByNameHierarchy("Feet");

                this.targetHandL.parent = this.sourceHandL;
                this.targetHandR.parent = this.sourceHandR;
                this.targetHandL.resetPositionRotation();
                this.targetHandR.resetPositionRotation();

                this.targetHandL.rotateAxisAngleDegObject(GameGlobals.forward, -180);
                this.targetHandL.rotateAxisAngleDegObject(GameGlobals.up, 90);
                this.targetHandL.rotateAxisAngleDegObject(GameGlobals.forward, 45);

                this.targetHandR.rotateAxisAngleDegObject(GameGlobals.forward, 180);
                this.targetHandR.rotateAxisAngleDegObject(GameGlobals.up, -90);
                this.targetHandR.rotateAxisAngleDegObject(GameGlobals.forward, -45);

                if (this.sourceHandL && this.sourceHandR && this.sourceHead) {
                    this.doupdate = this.ikupdate;
                    if (!this.ready) {
                        this.avatarHips.getPositionLocal(this.startHipsPosition);
                        this.avatarHips.getRotationLocal(this.startHipsRotationQuat);
                    }
                    this.ready = true;
                }
            }
        }
    }

    wristCheck() {
        this.avatarForearm = this.avatar.bones["b_LeftForeArm"];
        if (this.avatarForearm) {
            // TODO: Clear up Wrist component purpose
            // this.avatarForearm.addComponent(WristComponent);
        }
    }

    ikupdate(dt) {
        this.sourceFeet.getPositionWorld(sourceFeetPosition);

        if (this.head) {
            this.head.getRotationWorld(headRotationQuat);
            quat.normalize(headRotationQuat, headRotationQuat);

            this.avatarHead.parent.getRotationWorld(headParentRotationQuat);
            QuatUtils.toLocal(headRotationQuat, headParentRotationQuat, headRotationLocalQuat);
            quat.normalize(headRotationLocalQuat, headRotationLocalQuat);
            this.avatarHead.setRotationLocal(headRotationLocalQuat);

            if (this.currentPlayer) {
                // let q = this.avatarHead.getRotationLocal();
                // let pitch = Math.asin(2.0 * (q[0] * q[2] - q[3] * q[1]));
                ObjectUtils.getForwardWorld(this.avatarHead, avatarHeadForward);
                ObjectUtils.getForwardWorld(this.avatarHead.parent, avatarHeadParentForward);

                let angleThreshold = 10;
                if (Vec3Utils.angleDegrees(avatarHeadForward, GameGlobals.up) < angleThreshold) {
                    ObjectUtils.getDownWorld(this.avatarHead, avatarHeadForward); // Head pointing downwards
                } else if (Vec3Utils.angleDegrees(avatarHeadForward, GameGlobals.up) > 180 - angleThreshold) {
                    ObjectUtils.getUpWorld(this.avatarHead, avatarHeadForward); // Head pointing upwards
                } else {
                    ObjectUtils.getUpWorld(this.avatarHead, avatarHeadUp);
                    if (!Vec3Utils.isConcordant(avatarHeadUp, GameGlobals.up)) {
                        vec3.negate(avatarHeadForward, avatarHeadForward);
                    }
                }

                const distanceToPrevFeet = vec3.distance(sourceFeetPosition, this.lastSyncForwardSourceFeetPosition);

                const deg = Vec3Utils.anglePivotedSigned(avatarHeadForward, avatarHeadParentForward, GameGlobals.up);

                this.syncForwardWithHeadCounter--;
                if (!this.avatar.currentPlayer || common.balcony.isPlayerOnBalcony) {
                    if (Math.abs(deg) >= this.bodyRotationThresholdDeg || this.syncForwardWithHeadCounter > 0 || this.forcedSyncForwardWithHead || distanceToPrevFeet > 0.2) {
                        let speed = 100;
                        let amountToRotate = MathUtils.clamp(-Math.sign(deg) * dt * speed, -deg, deg);

                        if (this.forcedSyncForwardWithHead) {
                            amountToRotate = -deg;
                        }

                        ObjectUtils.rotateAxisObjectDegrees(this.avatarObject, amountToRotate, GameGlobals.up);

                        if (Math.abs(deg) > 5) {
                            this.syncForwardWithHeadCounter = 2;
                        }

                        this.lastSyncForwardSourceFeetPosition.vec3_copy(sourceFeetPosition);
                    }

                    vec3.copy(this.lastSyncForwardSourceFeetPosition, sourceFeetPosition);
                }
            } else {
                this.sourceFeet.pp_getRotationQuat(sourceFeetRotationQuat);
                this.avatarObject.pp_setRotationQuat(sourceFeetRotationQuat);
            }
        }

        this.avatarArmature.setPositionWorld(sourceFeetPosition);

        this.sourceHead.getPositionWorld(sourceHeadPosition);
        vec3.sub(currentHeightVector, sourceHeadPosition, sourceFeetPosition);
        const foreheadExtraHeight = 0.1;
        const currentHeight = Vec3Utils.valueAlongAxis(currentHeightVector, GameGlobals.up) + foreheadExtraHeight;

        const currentHipsHeight = this.avatarHipsHeight - Math.max(0, (this.avatarHeight - currentHeight));

        let currentHipsHeightLocal = currentHipsHeight;
        let avatarArmatureScaleY = this.avatarArmature.pp_getScale(avatarArmatureScale)[1];
        if (avatarArmatureScaleY != 0) {
            currentHipsHeightLocal = currentHipsHeightLocal / avatarArmatureScaleY;
        }

        currentHipsHeightLocal = Math.max(this.minHipsHeight, currentHipsHeightLocal);

        vec3.copy(adjustedHipsPositionLocal, this.avatarHipsPositionLocal);

        let hipDip = this.startHipsPosition[1] - currentHipsHeightLocal;
        Vec3Utils.removeComponentAlongAxis(adjustedHipsPositionLocal, GameGlobals.up, adjustedHipsPositionLocal);

        hipDip = (hipDip < 0) ? 0 : hipDip;
        let hipDipRange = 0.4;
        let hipDipFraction = hipDip / hipDipRange;
        hipDipFraction = (hipDipFraction > 1) ? 1 : hipDipFraction;

        let hipBack = hipDipRange * hipDipFraction * 0.4;
        let hipAngle = 45 * hipDipFraction;

        adjustedHipsPositionLocal[2] -= hipBack;

        this.avatarHips.setRotationLocal(this.startHipsRotationQuat);
        this.avatarHips.rotateAxisAngleDegObject(GameGlobals.left, hipAngle);

        vec3.scaleAndAdd(adjustedHipsPositionLocal, adjustedHipsPositionLocal, GameGlobals.up, currentHipsHeightLocal);
        this.avatarHips.setPositionLocal(adjustedHipsPositionLocal);

        for (let i = 0; i < this.ikSolverList.length; i++) {
            let ikSolver = this.ikSolverList[i];
            ikSolver.active = false;
            ikSolver.update(dt);
        }
    }
}
