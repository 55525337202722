import { FilledButton, LeaveEvent, PointerMoveEvent, PointerPressEvent, PointerReleaseEvent, SingleParentXMLInputConfig, Widget, WidgetAutoXML, WidgetEvent, WidgetProperties } from "lazy-widgets";
import { AudioID } from "src/hoverfit/audio/audio-manager/audio-id.js";
import { Audio } from "src/hoverfit/audio/audio-manager/audio.js";
import common from "src/hoverfit/common.js";
import { downHapticFeedback, hoverHapticFeedback, upHapticFeedback } from "src/hoverfit/misc/haptic-feedback.js";
import { getCursorFromLZCID } from "../get-cursor-from-lzcid.js";

export interface FeedbackFilledButtonProperties extends WidgetProperties {
    clickAudioIdOverride?: string;
}

export class FeedbackFilledButton<W extends Widget = Widget> extends FilledButton<W> {
    static override autoXML: WidgetAutoXML = {
        name: "feedback-filled-button",
        inputConfig: SingleParentXMLInputConfig
    };

    private clickSound: Audio | null;
    private hoverSound: Audio | null;
    private inBounds = false;
    protected _forced = false;

    constructor(child: W, properties?: Readonly<FeedbackFilledButtonProperties>) {
        super(child, properties);

        const audioIdString = properties?.clickAudioIdOverride || "BUTTON_CLICK";
        this.clickSound = common.audioManager.getAudio(AudioID[audioIdString as keyof typeof AudioID]);
        this.hoverSound = common.audioManager.getAudio(AudioID.BUTTON_HOVER);
    }

    protected override handleEvent(event: WidgetEvent) {
        const captured = super.handleEvent(event);

        if (this.active && this.clickable) {
            if (event.isa(PointerMoveEvent)) {
                if (!this.inBounds) {
                    this.inBounds = true;
                    this.hoverSound!.play();
                    const cursor = getCursorFromLZCID(event);
                    if (cursor) hoverHapticFeedback(cursor);
                }
            } else if (event.isa(LeaveEvent)) {
                this.inBounds = false;
            } else if (event.isa(PointerPressEvent)) {
                const cursor = getCursorFromLZCID(event);
                if (cursor) downHapticFeedback(cursor);
            } else if (event.isa(PointerReleaseEvent)) {
                const cursor = getCursorFromLZCID(event);
                if (cursor) upHapticFeedback(cursor);
            }
        }

        return captured;
    }

    override click() {
        if (this.clickSound) {
            this.clickSound.play();
        }

        super.click();
    }

    protected override activate() {
        super.activate();
        this.inBounds = false;
    }

    protected override deactivate() {
        super.deactivate();
        this.inBounds = false;
    }
}