import { Component, Object3D, Texture } from "@wonderlandengine/api";
import { property } from "@wonderlandengine/api/decorators.js";
import { vec3_create } from "wle-pp";
import common from "../../../common.js";
import { PriorityLevel } from "../../../misc/data-structs/expiring-priority-queue.js";
import { PopupManager, PopupManagerParams } from "../popup-manager.js";
import { PopupIconDecoration, PopupIconImage } from "../popup.js";

export class PopupManagerComponent extends Component {
    static override TypeName = "popup-manager";

    @property.object()
    walkPopupAnchor!: Object3D;

    @property.object()
    hoverboardPopupAnchor!: Object3D;

    @property.object()
    simplePopupWindowObject!: Object3D;

    @property.object()
    lapCompletedPopupWindowObject!: Object3D;

    @property.texture()
    infoImage!: Texture;
    @property.texture()
    warnImage!: Texture;
    @property.texture()
    errorImage!: Texture;
    @property.texture()
    fitPointsImage!: Texture;
    @property.texture()
    rampImage!: Texture;
    @property.texture()
    rocketImage!: Texture;
    @property.texture()
    squatImage!: Texture;
    @property.texture()
    stopWatchImage!: Texture;
    @property.texture()
    dailyFitPointsTrophyBronzeImage!: Texture;
    @property.texture()
    dailyFitPointsTrophySilverImage!: Texture;
    @property.texture()
    dailyFitPointsTrophyGoldImage!: Texture;
    @property.texture()
    dailyFitPointsTrophyPlatinumImage!: Texture;
    @property.texture()
    dailyMedalBronzeImage!: Texture;
    @property.texture()
    dailyMedalSilverImage!: Texture;
    @property.texture()
    dailyMedalGoldImage!: Texture;
    @property.texture()
    dailyMedalPlatinumImage!: Texture;

    @property.texture()
    bayleafWreathImage!: Texture;

    private _firstUpdateDone: boolean = false;

    _start() {
        this.simplePopupWindowObject.pp_setParent(this.walkPopupAnchor, false);
        this.lapCompletedPopupWindowObject.pp_setParent(this.walkPopupAnchor, false);
        this.simplePopupWindowObject.pp_setPositionLocal(vec3_create(0, 0, 0));
        this.lapCompletedPopupWindowObject.pp_setPositionLocal(vec3_create(0, 0, 0));

        this.simplePopupWindowObject.pp_setActive(false);
        this.lapCompletedPopupWindowObject.pp_setActive(false);

        const popupManagerParams = new PopupManagerParams();
        popupManagerParams.walkPopupAnchor = this.walkPopupAnchor;
        popupManagerParams.hoverboardPopupAnchor = this.hoverboardPopupAnchor;
        popupManagerParams.simplePopupWindowObject = this.simplePopupWindowObject;
        popupManagerParams.lapCompletedPopupWindowObject = this.lapCompletedPopupWindowObject;

        popupManagerParams.priorityLevelWeightMap.set(PriorityLevel.Low, 1);
        popupManagerParams.priorityLevelWeightMap.set(PriorityLevel.VeryLow, 3);
        popupManagerParams.priorityLevelWeightMap.set(PriorityLevel.Middle, 6);
        popupManagerParams.priorityLevelWeightMap.set(PriorityLevel.High, 10);
        popupManagerParams.priorityLevelWeightMap.set(PriorityLevel.VeryHigh, 14);

        const popupIconImageTextures = popupManagerParams.popupResources.popupIconImageTextures;
        popupIconImageTextures.set(PopupIconImage.None, null);
        popupIconImageTextures.set(PopupIconImage.Info, this.infoImage);
        popupIconImageTextures.set(PopupIconImage.Warn, this.warnImage);
        popupIconImageTextures.set(PopupIconImage.Error, this.errorImage);
        popupIconImageTextures.set(PopupIconImage.FitPoints, this.fitPointsImage);
        popupIconImageTextures.set(PopupIconImage.Ramp, this.rampImage);
        popupIconImageTextures.set(PopupIconImage.Rocket, this.rocketImage);
        popupIconImageTextures.set(PopupIconImage.Squat, this.squatImage);
        popupIconImageTextures.set(PopupIconImage.StopWatch, this.stopWatchImage);
        popupIconImageTextures.set(PopupIconImage.DailyFitPointsTrophyBronze, this.dailyFitPointsTrophyBronzeImage);
        popupIconImageTextures.set(PopupIconImage.DailyFitPointsTrophySilver, this.dailyFitPointsTrophySilverImage);
        popupIconImageTextures.set(PopupIconImage.DailyFitPointsTrophyGold, this.dailyFitPointsTrophyGoldImage);
        popupIconImageTextures.set(PopupIconImage.DailyFitPointsTrophyPlatinum, this.dailyFitPointsTrophyPlatinumImage);
        popupIconImageTextures.set(PopupIconImage.DailyMedalBronze, this.dailyMedalBronzeImage);
        popupIconImageTextures.set(PopupIconImage.DailyMedalSilver, this.dailyMedalSilverImage);
        popupIconImageTextures.set(PopupIconImage.DailyMedalGold, this.dailyMedalGoldImage);
        popupIconImageTextures.set(PopupIconImage.DailyMedalPlatinum, this.dailyMedalPlatinumImage);

        const popupIconDecorationTextures = popupManagerParams.popupResources.popupIconDecorationTextures;
        popupIconDecorationTextures.set(PopupIconDecoration.None, null);
        popupIconDecorationTextures.set(PopupIconDecoration.BayleafWreath, this.bayleafWreathImage);

        common.popupManager = new PopupManager(popupManagerParams);
    }

    override update(dt: number): void {
        if (!this._firstUpdateDone) {
            this._firstUpdateDone = true;
            this._start();
        }

        common.popupManager.update(dt);
    }
}