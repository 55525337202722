let logDisabled: null | boolean = null;

export enum Level {
    DEBUG = 0,
    WARN = 1,
    ERROR = 2,
}

const logFuns: [(...data: unknown[]) => void, string][] = [
    [console.debug, "DEBUG"],
    [console.warn, "WARN"],
    [console.error, "ERROR"]
];

export function voipLog(level: Level, tag: string | null, ...logArgs: unknown[]) {
    if (logDisabled) {
        return;
    } else if (logDisabled === null) {
        // XXX can't put this in the global scope or the wle editor fails to
        // build ON PROJECT RELOAD. for some reason it works on the first time.
        // also, it needs to check if URL exists otherwise building also fails
        if ("URL" in window) {
            const url = new URL(window.location.href);
            logDisabled = !url.searchParams.has("voip_log");
        } else {
            logDisabled = true;
        }

        if (logDisabled) {
            return;
        }
    }

    const [logFun, logLevelTag] = logFuns[level];

    logFun(`[VoIP ${logLevelTag}${tag === null ? "" : `: ${tag}`}]`, ...logArgs);
}