// use webrtc-adapter to prevent issues with browsers that handle webrtc poorly
// like safari
import adapter from "webrtc-adapter";
adapter.disableLog(false);
voipLog(Level.DEBUG, null, "Using webrtc-adapter");

import EventEmitter from "events";
import { Device } from "mediasoup-client";
import { Peer, WebSocketTransport } from "protoo-client";
import { USER_ACCEPTED_MICROPHONE_QUERY_DEFAULT, USER_ACCEPTED_MICROPHONE_QUERY_KEY } from "src/hoverfit/misc/preferences/pref-keys.js";
import { GLOBAL_PREFS } from "src/hoverfit/misc/preferences/preference-manager.js";
import { AudioSink } from "./audio-sink.js";
import { Level, voipLog } from "./voip-log.js";
import { VoIPPeer } from "./voip-peer.js";

/**
 * @typedef { import("mediasoup-client/lib/Transport").Transport } Transport
 * @typedef { import("mediasoup-client/lib/Producer").Producer } Producer
 * @typedef { import("./voip-options.js").VoIPOptions } VoIPOptions
 */

/**
 * A type of queued signaling message; used for messages in the data race queue.
 *
 * @readonly
 * @enum {number}
 */
const SigQueueMsgType = {
    P2P_PRIORITY: 0,
    ICE_CANDIDATE: 1,
    SESSION_DESCRIPTION: 2,
};

const ICE_DESC = "An ICE candidate was received from";
const SD_DESC = "A session description was received from";

const IMPL_LISTENERS = ["toggle-p2p", "ice-candidate", "session-description", "get-mediasoup-ticket"];

/**
 * A helper class for doing VoIP. Support P2P and mediasoup. This should be
 * engine/project agnostic.
 *
 * In the future it would be nice to split this into 4 classes:
 * - VoIPManager
 * - VoIPProvider
 * - VoIPMediasoupProvider (extends VoIPProvider)
 * - VoIPP2PProvider (extends VoIPProvider)
 *
 * This way you could add support for more VoIP backends and pick which should
 * be supported while making them tree-shakeable, unlike the current system
 * where, for example, if mediasoup is disabled, mediasoup-client and
 * protoo-client are still bundled despite not being needed.
 *
 * @template T - The type used for the key of each VoIPPeer
 */
export class VoIPHelper extends EventEmitter {
    log(level, ...data) {
        voipLog(level, "VoIPHelper", ...data);
    }

    /**
     * @param {Partial<VoIPOptions>} options - Options to be shared with each created VoIPPeer and this VoIPHelper
     */
    constructor(options = {}) {
        super();

        // general properties
        /**
         * Options passed to VoIPHelper. Readonly, do not modify, unless done
         * after calling {@link VoIPHelper#reset}
         *
         * @type {VoIPOptions}
         * @readonly
         * @private
         */
        this.options = {
            spatialAudio: false,
            spatialAudioEPS: 0.2,
            iceServers: [],
            maxP2PRetries: 2,
            commandTimeout: 5000,
            sigQueueTimeout: 5000,
            ...options
        };

        // XXX done afterwards because it's not a trivial value to initialise
        if (!this.options.audioSink) {
            this.options.audioSink = new AudioSink();
        }

        /**
         * List of all VoIP peers currently connected to our room
         *
         * @type {Map<T, VoIPPeer<T>>}
         */
        this.otherPeers = new Map();
        /**
         * Queue for commands issued to the signaling server
         *
         * @type {Map<number, [type: "toggle-p2p" | "get-mediasoup-ticket", timeoutID: number, resolve: (() => void), reject: ((error) => void)]>}
         * @readonly
         * @private
         */
        this._commandQueue = new Map();
        /**
         * Next nonce used by the command queue
         *
         * @type {number}
         * @private
         */
        this._nextNonce = 0;
        /**
         * Queue for raced messages from the signalling queue
         *
         * @type {Array<[type: SigQueueMsgType, otherPeerID: T, needsP2P: boolean, data: unknown, setTime: number]>}
         * @private
         */
        this._sigQueue = [];
        /**
         * Current microphone stream
         *
         * @type {MediaStream | null}
         * @private
         */
        this._micStream = null;
        /**
         * Current microphone track
         *
         * @type {MediaStreamTrack | null}
         * @private
         */
        this._micTrack = null;
        /**
         * Is there a mic request already underway?
         *
         * @type {boolean}
         * @private
         */
        this._requestingMic = false;
        /**
         * Current mic request ID
         *
         * @type {number}
         * @private
         */
        this._micRequestID = 0;
        /**
         * Current mic stream's "addtrack" listener
         *
         * @type {((ev: MediaStreamTrackEvent) => void) | null}
         * @private
         */
        this._micStreamListener = null;
        /**
         * Current mic track's "ended" listener
         *
         * @type {(() => void) | null}
         * @private
         */
        this._micTrackListener = null;

        // mediasoup-specific properties
        /**
         * Is mediasoup support enabled?
         *
         * @type {boolean}
         * @private
         */
        this._mediasoupSupported = false;
        /**
         * Mediasoup signalling server connection
         *
         * @type {Peer | null}
         * @private
         */
        this._mediasoupSig = null;
        /**
         * Mediasoup receiving (RX) audio transport
         *
         * @type {Transport | null}
         * @private
         */
        this._rxTransport = null;
        /**
         * Mediasoup transmitting (TX) audio transport
         *
         * @type {Transport | null}
         * @private
         */
        this._txTransport = null;
        /**
         * Mediasoup producer for microphone output
         *
         * @type {Producer | null}
         * @private
         */
        this._producer = null;
        /**
         * Mediasoup device. Used for connecting to mediasoup (not the mediasoup
         * signaling server, the actual routing server)
         *
         * @type {Device | null}
         * @private
         */
        this._device = null;
        /**
         * List of peers that wanted a mediasoup audio stream, but requested it
         * before the receiving transport was created.
         *
         * @type {Set<T>}
         * @private
         */
        this._msAudioStreamWait = new Set();

        // P2P-specific properties
        /**
         * Is P2P support enabled?
         *
         * @type {boolean}
         * @private
         */
        this._p2pSupported = false;
        /**
         * Current P2P priority of this peer
         *
         * @type {number}
         * @private
         */
        this._p2pPriority = 0;
        /**
         * Whether P2P should be enabled or disabled once the current P2P toggle
         * command is finished
         *
         * @type {boolean}
         * @private
         */
        this._p2pQueuedEnable = false;
        /**
         * Whether Mediasoup should be enabled or disabled once the current
         * ticket command is finished
         *
         * @type {boolean}
         * @private
         */
        this._mediasoupQueuedEnable = false;
    }

    /**
     * Is spatial audio enabled? Gets the `spatialAudio` value in the options
     * object.
     */
    get spatialAudio() {
        return this.options.spatialAudio;
    }

    /**
     * Check if anyone is listening via mediasoup. This is useful for preventing
     * the unnecessary creation of a mediasoup producer so that bandwidth can be
     * saved.
     */
    get hasMediasoupListeners() {
        for (const otherPeer of this.otherPeers.values()) {
            if (otherPeer.consumptionMode === "p2p") {
                if (otherPeer.badP2PConnection) {
                    return true;
                }
            } else if (otherPeer.usingMediasoup) {
                return true;
            }
        }

        return false;
    }

    /**
     * Reset this VoIPHelper to its default state. Similar to `dispose` methods
     * found in other classes, but the helper can still be used after calling
     * this method. Should be called on cleanup. Does not clean up the
     * microphone stream.
     *
     * @param {boolean} removeImplListeners - If true, then all implementation event listeners ("toggle-p2p", "ice-candidate", "session-description", "get-mediasoup-ticket") will also be removed. False by default
     */
    reset(removeImplListeners = false) {
        // reject all queued-up commands/reset command queu
        for (const [_type, timeoutID, _resolve, reject] of this._commandQueue.values()) {
            clearTimeout(timeoutID);
            reject(new Error("VoIPHelper reset, command cancelled"));
        }

        this._commandQueue.clear();
        this._nextNonce = 0;

        // clear signalling queue
        this._sigQueue.length = 0;

        // clear mediasoup audio stream waiting list
        this._msAudioStreamWait.clear();

        // disconnect from everything
        this.disconnectMediasoup();
        this.clearOtherPeers();

        // remove support for all VoIP types
        this.p2pSupported = false;
        this.mediasoupSupported = false;

        // reset mediasoup state
        this._mediasoupQueuedEnable = false;

        // reset P2P state
        this._p2pPriority = 0;
        this._p2pQueuedEnable = false;

        // remove event listeners
        if (removeImplListeners) {
            for (const implListener of IMPL_LISTENERS) {
                this.removeAllListeners(implListener);
            }
        }
    }

    ////////////////////////
    // Microphone helpers //
    ////////////////////////

    /**
     * Cleanup a given microphone stream, **but not the tracks**.
     *
     * @private
     */
    _cleanupStream(/** @type {MediaStream} */ stream) {
        for (const track of stream.getTracks()) {
            stream.removeTrack(track);
        }
    }

    /**
     * Cleanup the current microphone stream and track.
     *
     * @private
     */
    _cleanupCurrentStreamAndTrack() {
        if (this._micTrack) {
            this._micTrack.removeEventListener("ended", this._micTrackListener);
            this._micTrackListener = null;
            this._micTrack = null;
        }

        this._cleanupStream(this._micStream);

        this._micStream.removeEventListener("addtrack", this._micStreamListener);
    }

    /**
     * Requests the microphone from the user. Connections are automatically
     * updated with the new microphone track.
     *
     * If the microphone is requested multiple times at the same time, then only
     * the first request is processed.
     */
    async requestMic() {
        if (!GLOBAL_PREFS.getPref(USER_ACCEPTED_MICROPHONE_QUERY_KEY, USER_ACCEPTED_MICROPHONE_QUERY_DEFAULT)) {
            return;
        }

        if (this._requestingMic) {
            this.log(Level.WARN, "Ignored microphone request; there is already a request underway");
            return;
        }

        const requestID = ++this._micRequestID;
        this._requestingMic = true;

        try {
            if (this._micStream) {
                if (!this._micTrack || (this._micTrack && this._micTrack.readyState === "ended")) {
                    if (this._micTrack) {
                        this._micStream.removeTrack(this._micTrack);
                        this._micTrack = null;
                    }

                    this._micStream = null;
                } else {
                    return;
                }
            }

            // request mic
            const newStream = await navigator.mediaDevices.getUserMedia({
                video: false,
                // XXX firefox defaults to stereo mic. use mono to save
                // bandwidth
                audio: {
                    advanced: [
                        {
                            channelCount: 1,
                        },
                    ],
                },
            });

            if (!this._requestingMic || this._micRequestID !== requestID) {
                this._cleanupStream(newStream);
                return;
            }

            if (!newStream) {
                this.log(Level.WARN, "Failed to get microphone permissions; null returned");
                return;
            }

            this._micStream = newStream;

            // get first mic audio track
            this.replaceMicTrack(this._micStream.getAudioTracks()[0]);

            // HACK this shouldn't be needed, but in the stupid event that a
            // browser decides to add a new mic track to an existing mic stream,
            // replace the picked track with the new one. not sure if this ever
            // happens
            this._micStreamListener = (/** @type {MediaStreamTrackEvent} */ ev) => {
                this.log(Level.WARN, "A track was added to the microphone media stream; replacing current track");
                this.replaceMicTrack(ev.track);
            };
            this._micStream.addEventListener("addtrack", this._micStreamListener);
        } catch (err) {
            this.log(Level.WARN, "Failed to get microphone permissions;", err);
        } finally {
            if (this._micRequestID === requestID) {
                this._requestingMic = false;
            }
        }
    }

    /** Cancels the current microphone request. */
    cancelMicRequest() {
        this._requestingMic = false;
    }

    /**
     * Cancels the current microphone request and closes the input microphone
     * stream.
     */
    revokeMic() {
        this.cancelMicRequest();
        if (!this._micStream) return;
        this._cleanupCurrentStreamAndTrack();
    }

    /////////////////////
    // Peer management //
    /////////////////////

    /**
     * Add a new peer to the list of peers.
     *
     * @param {T} otherPeerID - ID of new peer
     */
    addOtherPeer(otherPeerID) {
        const otherPeer = new VoIPPeer(otherPeerID, this.options);

        otherPeer.on("want-mediasoup-audio", () => {
            if (otherPeer.usingMediasoup) {
                if (this._rxTransport) {
                    this.requestMediasoupAudioStream(otherPeerID);
                } else {
                    this.log(Level.WARN, "Peer wants a mediasoup audio stream, but RX transport isn't ready; added to waiting list");
                    this._msAudioStreamWait.add(otherPeer.id);
                }
            }
        });

        otherPeer.on("ice-candidate", (iceCandidate) => {
            if (this._p2pSupported && this.p2pEnabled) {
                // this.log(Level.DEBUG, "sending ice-candidate to peer", otherPeerID, iceCandidate);
                this.emit("ice-candidate", otherPeerID, iceCandidate);
            }
        });

        otherPeer.on("session-description", (sessionDescription) => {
            if (this._p2pSupported && this.p2pEnabled) {
                // this.log(Level.DEBUG, "sending session-description to peer", otherPeerID, sessionDescription);
                this.emit("session-description", otherPeerID, sessionDescription);
            }
        });

        otherPeer.on("consumption-mode-changed", (consumptionMode) => {
            this.log(Level.DEBUG, `consumption mode changed to ${consumptionMode} for peer ${otherPeerID}`);
            this.updateTxMediasoup();
        });

        otherPeer.on("p2p-restored", () => {
            this.log(Level.DEBUG, `p2p connection restored for peer ${otherPeerID}`);
            this.updateTxMediasoup();
        });

        if (this._micTrack) {
            otherPeer.setMicTrack(this._micTrack);
        }

        this.otherPeers.set(otherPeerID, otherPeer);
        this.flushSignalingQueue();

        this.emit("peer-added", otherPeer);

        return otherPeer;
    }

    /**
     * Remove a peer from the list of peers. Peer is disposed
     *
     * @param {T} otherPeerID - ID of peer to remove
     */
    removeOtherPeer(otherPeerID) {
        const otherPeer = this.otherPeers.get(otherPeerID);
        if (!otherPeer) {
            this.log(Level.WARN, "Ignored unknown peer", otherPeerID);
            return;
        }

        otherPeer.dispose();
        this.otherPeers.delete(otherPeerID);
        this.emit("peer-removed", otherPeer);
    }

    /**
     * Get a peer by ID from the {@link VoIPHelper#otherPeers} Map
     *
     * @param {T} otherPeerID - ID of peer to get
     */
    getOtherPeer(otherPeerID) {
        return this.otherPeers.get(otherPeerID);
    }

    /**
     * Update the audio source position of all peers. Does nothing if spatial
     * audio is disabled
     */
    updateSpatialPos() {
        if (!this.options.spatialAudio) {
            return;
        }

        for (const otherPeer of this.otherPeers.values()) {
            otherPeer.updateSpatialPos();
        }
    }

    /**
     * Remove all peers from the list of peers. All peers are disposed
     */
    clearOtherPeers() {
        const markedForRemoval = Array.from(this.otherPeers.values());
        for (const otherPeer of markedForRemoval) {
            otherPeer.dispose();
        }

        this.otherPeers.clear();

        for (const otherPeer of markedForRemoval) {
            this.emit("peer-removed", otherPeer);
        }
    }

    /////////
    // P2P //
    /////////

    /**
     * Is P2P support enabled? Not to be confused with
     * {@link VoIPHelper#p2pEnabled}. If true, the programmer has the
     * responsibility of correctly handling "toggle-p2p", "ice-candidate"
     * and "session-description" events.
     */
    get p2pSupported() {
        return this._p2pSupported;
    }

    set p2pSupported(p2pSupported) {
        if (this._p2pSupported !== p2pSupported) {
            this._p2pSupported = p2pSupported;
            if (p2pSupported) {
                this.emit("p2p-support-added");
            } else {
                this.emit("p2p-support-removed");
            }
        }
    }

    /**
     * Is P2P currently toggled on? True when a P2P priority has been given by
     * the signaling server
     */
    get p2pEnabled() {
        return this._p2pPriority !== 0;
    }

    /**
     * What is our P2P priority? If 0, then P2P is currently toggled off
     */
    get p2pPriority() {
        return this._p2pPriority;
    }

    /**
     * Request the server to toggle P2P on or off. An error is thrown if P2P is
     * not supported
     *
     * @param {boolean | null} [enable] - Should P2P be enabled? If null, then P2P will be toggled
     */
    toggleP2P(enable = null) {
        if (!this._p2pSupported) {
            throw new Error("P2P is not supported");
        }

        if (enable === null) {
            enable = !this.p2pEnabled;
        }

        if (enable == this._p2pQueuedEnable) {
            return;
        }

        this._p2pQueuedEnable = enable;

        // XXX disconnect p2p connections now if disabling p2p. otherwise, wait
        // for the server response
        if (!enable) {
            for (const otherPeer of this.otherPeers.values()) {
                otherPeer.updateP2P(0);
            }
        }

        this.emitCommand("toggle-p2p", enable);
    }

    /**
     * Should be called when the signaling server has toggled P2P on or off for
     * us, after a `toggle-p2p` command was issued
     *
     * @param {number} nonce - Nonce used to identify the command ID that started the toggle request
     * @param {number} newPriority - New P2P priority for us
     */
    p2pToggled(nonce, newPriority) {
        if (this._p2pQueuedEnable !== (newPriority !== 0)) {
            // wanted p2p state changed while waiting for response from server
            this.finishCommand("toggle-p2p", nonce, new Error("P2P toggle cancelled"));
            return;
        }

        if (newPriority !== this._p2pPriority) {
            this._p2pPriority = newPriority;

            for (const otherPeer of this.otherPeers.values()) {
                otherPeer.updateP2P(newPriority);
            }

            if (newPriority !== 0) {
                this.emit("p2p-enabled");
            } else {
                this.emit("p2p-disabled");
            }
        }

        this.finishCommand("toggle-p2p", nonce); // resolve
    }

    /**
     * Should be called when the signaling server has rejected to toggle P2P on
     * or off for us, after a `toggle-p2p` command was issued
     *
     * @param {number} nonce - Nonce used to identify the command ID that started the toggle request
     */
    p2pToggleDenied(nonce) {
        this.finishCommand("toggle-p2p", nonce, new Error("P2P toggle denied")); // reject
    }

    /**
     * Should be called when the signaling server has changed the P2P priority
     * of another peer
     *
     * @param {T} otherPeerID - ID of other peer getting its priority changed
     * @param {number} newPriority - New P2P priority for other peer
     */
    setP2PPriorityOf(otherPeerID, newPriority) {
        const otherPeer = this.otherPeers.get(otherPeerID);
        if (otherPeer) {
            this.setP2PPriorityOfNoQueue(otherPeer, newPriority);
            this.flushSignalingQueue();
        } else {
            this.enqueueSignaling("A priority was set for", false, SigQueueMsgType.P2P_PRIORITY, otherPeerID, newPriority);
        }
    }

    /**
     * Should be called when another peer has sent us an ICE candidate via the
     * signaling server
     *
     * @param {T} otherPeerID - ID of other peer who sent the ICE candidate
     * @param {RTCIceCandidateInit} iceCandidate - The ICE candidate sent by the other peer
     */
    receiveIceCandidate(otherPeerID, iceCandidate) {
        if (!this.p2pEnabled) {
            this.log(Level.WARN, "Rejected P2P ICE candidate (P2P not enabled) from peer", otherPeerID);
            return;
        }

        const otherPeer = this.otherPeers.get(otherPeerID);
        if (!otherPeer) {
            this.enqueueSignaling(ICE_DESC, false, SigQueueMsgType.ICE_CANDIDATE, otherPeerID, iceCandidate);
            return;
        }

        if (!otherPeer.hasP2P) {
            this.enqueueSignaling(ICE_DESC, true, SigQueueMsgType.ICE_CANDIDATE, otherPeerID, iceCandidate);
            return;
        }

        // this.log(Level.DEBUG, "received ICE candidate from peer", otherPeerID, iceCandidate);
        otherPeer.addIceCandidate(iceCandidate);
    }

    /**
     * Should be called when another peer has sent us a session description via
     * the signaling server
     *
     * @param {T} otherPeerID - ID of other peer who sent the session description
     * @param {RTCSessionDescriptionInit} sessionDescription - The session description sent by the other peer
     */
    receiveSessionDescription(otherPeerID, sessionDescription) {
        if (!this.p2pEnabled) {
            this.log(Level.WARN, "Rejected P2P offer (P2P not enabled) from peer", otherPeerID);
            return;
        }

        const otherPeer = this.otherPeers.get(otherPeerID);
        if (!otherPeer) {
            this.enqueueSignaling(SD_DESC, false, SigQueueMsgType.SESSION_DESCRIPTION, otherPeerID, sessionDescription);
            return;
        }

        if (!otherPeer.hasP2P) {
            this.enqueueSignaling(SD_DESC, true, SigQueueMsgType.SESSION_DESCRIPTION, otherPeerID, sessionDescription);
            return;
        }

        // this.log(Level.DEBUG, "received session description from peer", otherPeerID, sessionDescription);
        otherPeer.setRemoteDescription(sessionDescription, this._p2pPriority);
    }

    ///////////////
    // mediasoup //
    ///////////////

    /**
     * Is mediasoup supported in this game? Not to be confused with
     * {@link VoIPHelper#mediasoupEnabled}. If true, the programmer has the
     * responsibility of correctly handling "get-mediasoup-ticket" event.
     */
    get mediasoupSupported() {
        return this._mediasoupSupported;
    }

    set mediasoupSupported(mediasoupSupported) {
        if (this._mediasoupSupported !== mediasoupSupported) {
            this._mediasoupSupported = mediasoupSupported;
            if (mediasoupSupported) {
                this.emit("mediasoup-support-added");
            } else {
                this.emit("mediasoup-support-removed");
            }
        }
    }

    /**
     * Is mediasoup currently toggled on? True when a ticket has been received
     * by the signaling server and a mediasoup signaling server connection has
     * started
     */
    get mediasoupEnabled() {
        return this._mediasoupSig !== null;
    }

    /**
     * Try to toggle mediasoup on or off. An error is thrown if mediasoup is
     * not supported. If toggling on, then a request for a ticket is sent to the
     * signaling server, otherwise, mediasoup is disconnected.
     *
     * @param {boolean | null} [enable] - Should mediasoup be enabled? If null, then mediasoup will be toggled
     */
    toggleMediasoup(enable = null) {
        if (!this._mediasoupSupported) {
            throw new Error("Mediasoup not supported");
        }

        if (enable === null) {
            enable = !this.mediasoupEnabled;
        }

        if (enable == this._mediasoupQueuedEnable) {
            return;
        }

        this._mediasoupQueuedEnable = enable;

        if (enable) {
            // get ticket
            if (!this.isCommandTypeQueued("get-mediasoup-ticket")) {
                this.emitCommand("get-mediasoup-ticket");
            }
        } else {
            // disconnect mediasoup
            this.disconnectMediasoup();
        }
    }

    /**
     * Should be called when the signaling server has created a mediasoup ticket
     * for us, after a `get-mediasoup-ticket` command was issued
     *
     * @param {number} nonce - Nonce used to identify the command ID that started the toggle request
     * @param {string} ticket - A mediasoup ticket; a URL to the mediasoup signaling server, with authentication as a search parameter
     */
    async receiveMediasoupTicket(nonce, ticket) {
        if (!this._mediasoupQueuedEnable) {
            // wanted mediasoup state changed while waiting for response from
            // server
            this.finishCommand("get-mediasoup-ticket", nonce, new Error("Mediasoup ticket acquisition cancelled"));
            return;
        }

        try {
            // connect to mediasoup dedicated server
            await this.connectMediasoup(ticket);

            // make mediasoup device
            if (!this._device) {
                this._device = new Device();
            }

            // get router capabilities and pipe them to the mediasoup device (is
            // rtp supported? etc...)
            if (!this._device.loaded) {
                await this._device.load(await this.getRouterCapabilities());
            }
        } catch (e) {
            this.disconnectMediasoup();
            this.finishCommand("get-mediasoup-ticket", nonce, e);
            return;
        }

        // setup mediasoup audio rx/tx
        try {
            await this.setupAudioRX(this._device);
        } catch (e) {
            this.log(Level.WARN, "Failed to setup mediasoup audio RX", e);
        }

        try {
            await this.setupAudioTX(this._device);
        } catch (e) {
            this.log(Level.WARN, "Failed to setup mediasoup audio TX", e);
        }

        this.finishCommand("get-mediasoup-ticket", nonce);
    }

    /**
     * Should be called when the signaling server has rejected to create a
     * mediasoup ticket for us, after a `get-mediasoup-ticket` command was
     * issued
     *
     * @param {number} nonce - Nonce used to identify the command ID that started the toggle request
     */
    mediasoupTicketDenied(nonce) {
        this.finishCommand("get-mediasoup-ticket", nonce, new Error("Mediasoup ticket denied"));
    }

    //////////////////////
    // Internal methods //
    //////////////////////

    /**
     * Finish an issued command, either successfully, or with an error
     *
     * @param {string} commandName - The name of the command being finished. Only used for validation
     * @param {number} nonce - The nonce (ID) of the command being finished
     * @param {Error | null} [error=null] - The error for the command. If null (default), the command finishes successfully
     * @private
     */
    finishCommand(commandName, nonce, error = null) {
        const command = this._commandQueue.get(nonce);

        if (!command || command[0] !== commandName) {
            this.log(Level.WARN, "Command not queued. Is this a command from an old connection? Command:", commandName);
            return;
        }

        this._commandQueue.delete(nonce);
        clearTimeout(command[1]);

        if (error === null) {
            command[2]();
        } else {
            command[3](error);
        }
    }

    /**
     * Connect to a mediasoup signaling server with a given ticket
     *
     * @param {string} ticket - mediasoup ticket (URL with auth)
     * @private
     */
    connectMediasoup(ticket) {
        if (this._mediasoupSig !== null) {
            this.log(Level.WARN, "Already connected, closing old mediasoup connection");
            this.disconnectMediasoup();
        }

        return new Promise((resolve, reject) => {
            const mediasoupTransport = new WebSocketTransport(ticket);
            const mediasoupSig = new Peer(mediasoupTransport);
            let openedOnce = false;

            mediasoupSig.on("open", () => {
                openedOnce = true;
                resolve();
            });

            mediasoupSig.on("close", () => {
                if (!openedOnce) {
                    openedOnce = true;
                    reject("Failed to connect to server");
                }

                for (const otherPeer of this.otherPeers.values()) {
                    otherPeer.usingMediasoup = false;
                }

                if (mediasoupSig === this._mediasoupSig) {
                    // XXX for some reason transports aren't closed
                    // automatically when the connection fails, but we can close
                    // them when the signaling server fails
                    this.disconnectMediasoup();
                }
            });

            mediasoupSig.on("request", async (request, accept, reject) => {
                switch (request.method) {
                    case "audio-stream":
                        if (this._rxTransport) {
                            const otherPeerID = request.data.peerID;
                            const otherPeer = this.otherPeers.get(otherPeerID);
                            if (!otherPeer) {
                                reject(400, "Invalid peer");
                                break;
                            }

                            // reject audio stream if consuming via p2p
                            if (this.p2pEnabled && otherPeer.consumingP2P && !otherPeer.badP2PConnection) {
                                this.log(Level.WARN, "Rejected mediasoup audio stream; peers already connected via P2P");
                                reject(403, "Already consuming via P2P");
                                break;
                            }

                            // consume audio from transport
                            let consumer = null;
                            try {
                                consumer = await this._rxTransport.consume({
                                    id: request.data.consumerId,
                                    producerId: request.data.producerId,
                                    kind: "audio",
                                    rtpParameters: request.data.rtpParameters,
                                });
                            } catch (err) {
                                this.log(Level.ERROR, "Failed to create mediasoup consumer;", err);
                                reject(400, "Could not create mediasoup consumer");
                                return;
                            }

                            try {
                                otherPeer.addMediasoupConsumer(consumer);
                            } catch (err) {
                                this.log(Level.ERROR, "Failed to add mediasoup consumer to peer;", err);

                                if (!consumer.closed) {
                                    consumer.close();
                                }

                                reject(400, "Could not add mediasoup consumer to peer");
                                return;
                            }

                            if (consumer.closed) {
                                reject(403, "Consumer expired");
                                return;
                            }

                            accept();
                        } else {
                            reject(400, "mediasoup RX transport not created yet");
                        }
                        break;
                    default:
                        reject(400, "Unknown method");
                        this.log(Level.WARN, "Unknown request method from signaling server:", request.method);
                }
            });

            mediasoupSig.on("notification", async (notification) => {
                switch (notification.method) {
                    case "peers-list":
                        for (const otherPeerID of notification.data) {
                            const otherPeer = this.otherPeers.get(otherPeerID);
                            if (otherPeer) {
                                otherPeer.usingMediasoup = true;
                            } else {
                                this.log(Level.WARN, "Ignored unknown peer", otherPeerID);
                            }
                        }
                        break;
                    case "peer-connected":
                        {
                            const otherPeerID = notification.data.peerID;
                            const otherPeer = this.otherPeers.get(otherPeerID);
                            if (otherPeer) {
                                otherPeer.usingMediasoup = true;
                            } else {
                                this.log(Level.WARN, "Ignored unknown peer", otherPeerID);
                            }
                            break;
                        }
                    case "peer-disconnected":
                        {
                            const otherPeerID = notification.data.peerID;
                            const otherPeer = this.otherPeers.get(otherPeerID);
                            if (otherPeer) {
                                otherPeer.usingMediasoup = false;
                                this._msAudioStreamWait.delete(otherPeer.id);
                            } else {
                                this.log(Level.WARN, "Ignored unknown peer", otherPeerID);
                            }
                            break;
                        }
                    default:
                        reject(400, "Unknown method");
                        this.log(Level.WARN, "Unknown notification method from mediasoup signaling server:", notification.method);
                }
            });

            this._mediasoupSig = mediasoupSig;
            this.emit("mediasoup-enabled");
        });
    }

    /**
     * Disconnect mediasoup (signaling server, transports and device)
     *
     * @private
     */
    disconnectMediasoup() {
        if (this._rxTransport) {
            this._rxTransport.close();
            this._rxTransport = null;
        }

        if (this._txTransport) {
            this._txTransport.close();
            this._txTransport = null;
        }

        if (this._device) {
            this._device = null;
        }

        if (this._mediasoupSig) {
            const mediasoupSig = this._mediasoupSig;
            // XXX set to null before closing so that disconnectMediasoup isn't
            // called again by the "close" event handler
            this._mediasoupSig = null;
            this.emit("mediasoup-disabled");

            if (!mediasoupSig.closed) {
                mediasoupSig.close();
            }
        }
    }

    /**
     * Issue a command. An event will be emitted so the right message is sent to
     * the signaling server.
     *
     * @param {string} commandName - The command type being issued
     * @param {...unknown} args - A list of extra arguments to emit in the event
     * @private
     */
    emitCommand(commandName, ...args) {
        return new Promise((resolve, reject) => {
            const nonce = this._nextNonce++;

            const timeoutID = setTimeout(
                () => reject(new Error("Command timed out")),
                this.options.commandTimeout,
            );

            this._commandQueue.set(nonce, [commandName, timeoutID, resolve, reject]);
            this.emit(commandName, nonce, ...args);
        });
    }

    /**
     * Check if command of the given type is already underway
     *
     * @param {string} commandName - The command type to check
     * @private
     */
    isCommandTypeQueued(commandName) {
        for (const [command, _nonce, _resolve, _reject] of this._commandQueue) {
            if (command === commandName) {
                return true;
            }
        }

        return false;
    }

    /**
     * Add a raced message to the signalling queue
     *
     * @param {string} description - The prefix of the message in the console warning
     * @param {boolean} reasonP2P - Was this enqueued because P2P was disabled for the peer in question? Only used for the console warning
     * @param {SigQueueMsgType} newType - The type of the signalling message being queued
     * @param {T} otherPeerID - The other peer in question
     * @param {any} data - Any data associated with the message. Can be message parameters, for example
     * @private
     */
    enqueueSignaling(description, reasonP2P, newType, otherPeerID, data) {
        this.log(Level.WARN, `${description} ${reasonP2P ? "a peer with P2P disabled" : `an unknown peer`} (${otherPeerID}). This has been queued as it might be caused by a race in the signaling.`);

        // remove any other queued signaling message that matches this peer and
        // type if this type of message is unique
        let needsP2P = false;
        let highPriority = false;
        if (newType === SigQueueMsgType.P2P_PRIORITY) {
            for (let idx = this._sigQueue.length - 1; idx >= 0; idx--) {
                const [type, peerID, _needsP2P, _data, _setTime] = this._sigQueue[idx];
                if (type === newType && peerID === otherPeerID) {
                    this._sigQueue.splice(idx, 1);
                }
            }

            // XXX P2P-priority messages have a high priority because they need
            // to be processed before ICE candidates and session descriptions
            highPriority = true;
        } else {
            // XXX ICE candidates and session descriptions also need the peer's
            // P2P to be toggled on; without this, there can be a race condition
            // where:
            // 1. ICE and session description received, but peer not connected,
            //    so they get queued
            // 2. Peer gets connected
            // 3. Queue flushed
            // 4. ICE/session description processed due to queue flush, but
            //    rejected because P2P is not toggled on yet
            // 5. P2P toggled on, but connection starts improperly because ICE
            //    candidates or session description were rejected
            needsP2P = true;
        }

        // enqueue
        const message = [newType, otherPeerID, needsP2P, data, Date.now()];
        if (highPriority) {
            this._sigQueue.unshift(message);
        } else {
            this._sigQueue.push(message);
        }
    }

    /**
     * Try to flush any raced message from the signalling queue
     *
     * @private
     */
    flushSignalingQueue() {
        this.log(Level.DEBUG, "flushing queue");
        const flushTime = Date.now();

        for (let idx = 0; idx < this._sigQueue.length;) {
            const [type, peerID, needsP2P, data, setTime] = this._sigQueue[idx];
            const peer = this.otherPeers.get(peerID);
            let dequeue = false;

            if (peer && (!needsP2P || (needsP2P && peer.hasP2P))) {
                switch (type) {
                    case SigQueueMsgType.P2P_PRIORITY:
                        this.setP2PPriorityOfNoQueue(peer, data);
                        break;
                    case SigQueueMsgType.ICE_CANDIDATE:
                        peer.addIceCandidate(data);
                        break;
                    case SigQueueMsgType.SESSION_DESCRIPTION:
                        peer.setRemoteDescription(data, this._p2pPriority);
                        break;
                    default:
                        this.log(Level.WARN, "Ignored unknown queued signaling message type:", type);
                }

                dequeue = true;
            } else if ((flushTime - setTime) > this.options.sigQueueTimeout) {
                dequeue = true;
            }

            if (dequeue) {
                this._sigQueue.splice(idx, 1);
            } else {
                idx++;
            }
        }
    }

    /**
     * Similar to {@link VoIPHelper#setP2PPriority}, but never enqueues to the
     * signaling queue
     *
     * @param {T} otherPeerID - ID of other peer getting its priority changed
     * @param {number} newPriority - New P2P priority for other peer
     * @private
     */
    setP2PPriorityOfNoQueue(otherPeer, newPriority) {
        if (otherPeer.p2pPriority !== newPriority) {
            otherPeer.p2pPriority = newPriority;
            otherPeer.updateP2P(this._p2pPriority);
        }
    }

    /**
     * Get router capabilities from mediasoup signaling server
     *
     * @returns {Promise<{ routerRtpCapabilities: RtpCapabilities }>}
     * @private
     */
    async getRouterCapabilities() {
        return {
            routerRtpCapabilities: await this._mediasoupSig.request("get-router-capabilities")
        };
    }

    /**
     * Setup mediasoup receiving transport
     *
     * @param {Device} device - The mediasoup device to make the transport from
     * @private
     */
    async setupAudioRX(device) {
        if (this._rxTransport) {
            return;
        }

        // create RX transport
        const transportOpts = await this._mediasoupSig.request("create-transport", {
            rtpCapabilities: device.rtpCapabilities,
            sctpCapabilities: device.sctpCapabilities,
            isTx: false,
        });
        const rxTransport = device.createRecvTransport(transportOpts);

        // handle connections
        rxTransport.on("connect", async ({ dtlsParameters }, accept, reject) => {
            try {
                await this._mediasoupSig.request("transport-connect", {
                    dtlsParameters, isTx: false,
                });

                accept(rxTransport);
            } catch (e) {
                reject(e);
            }
        });

        rxTransport.observer.on("close", () => {
            if (rxTransport === this._rxTransport) {
                this._rxTransport = null;
            }
        });

        this._rxTransport = rxTransport;

        // flush audio stream waiting list
        for (const otherPeerID of this._msAudioStreamWait) {
            if (this.otherPeers.has(otherPeerID)) {
                this.requestMediasoupAudioStream(otherPeerID);
            }
        }

        this._msAudioStreamWait.clear();
    }

    /**
     * Setup mediasoup transmitting transport
     *
     * @param {Device} device - The mediasoup device to make the transport from
     * @private
     */
    async setupAudioTX(device) {
        if (this._txTransport) {
            return;
        }

        if (!device.canProduce("audio")) {
            this.log(Level.WARN, "mediasoup device can't produce audio; Outgoing audio will only work via P2P, incoming audio will still work");
            return;
        }

        // create TX transport
        const transportOpts = await this._mediasoupSig.request("create-transport", {
            rtpCapabilities: device.rtpCapabilities,
            sctpCapabilities: device.sctpCapabilities,
            isTx: true,
        });
        const txTransport = device.createSendTransport(transportOpts);

        // handle connections and outgoing audio stream
        txTransport.on("connect", async ({ dtlsParameters }, accept, reject) => {
            try {
                await this._mediasoupSig.request("transport-connect", {
                    dtlsParameters, isTx: true,
                });

                accept(txTransport);
            } catch (err) {
                this.log(Level.ERROR, "mediasoup transport - connect request failed;", err);
                reject(err);
            }
        });

        txTransport.on("produce", async ({ kind, rtpParameters, appData }, accept, reject) => {
            try {
                const { id } = await this._mediasoupSig.request("produce", {
                    kind, rtpParameters, appData,
                });

                accept({ id });
            } catch (err) {
                this.log(Level.ERROR, "mediasoup produce request failed;", err);
                reject(err);
            }
        });

        txTransport.observer.on("close", () => {
            if (txTransport === this._txTransport) {
                this._txTransport = null;
            }
        });

        this._txTransport = txTransport;

        // mic may have been requested before transport setup. try to send mic
        // audio via transport
        this.updateTxMediasoup(true);
    }

    /**
     * Try to create a mediasoup producer with the current microphone track
     *
     * @param {boolean} [replace] - Should an existing producer be replaced? True by default
     */
    async tryTxMediasoup(replace = true) {
        // send audio to mediasoup if mic ready and outgoing transport ready
        if (this._micTrack && this._txTransport) {
            if (this._micTrack.readyState === "ended") {
                this.log(Level.WARN, "Mic stream was non-null but ended, requesting mic stream again");
                await this.requestMic();
                return;
            }

            if ((!replace && this._producer) || !this.hasMediasoupListeners) {
                return;
            }

            const producer = await this._txTransport.produce({
                track: this._micTrack,
                stopTracks: false,
                disableTrackOnPause: false,
                zeroRtpOnPause: true
            });

            producer.observer.on("close", () => {
                if (producer === this._producer) {
                    this._producer = null;
                }
            });

            producer.on("trackended", () => {
                producer.close();
            });

            if (this._producer) {
                this._producer.close();
            }

            if (this.hasMediasoupListeners) {
                this._producer = producer;
            } else {
                this.log(Level.WARN, "mediasoup producer created, but no longer needed. Closing");
                producer.close();
            }
        }
    }

    /**
     * Close the mediasoup producer if any exists
     */
    async stopTxMediasoup() {
        if (this._producer) {
            this._producer.close();
            this._producer = null;
        }
    }

    /**
     * Replace the current microphone track with a new one
     *
     * @param {MediaStreamTrack | null} micTrack - The new microphone track to use for VoIP. If null, then the current track is stopped and set to null. Ended tracks are ignored
     * @private
     */
    replaceMicTrack(micTrack = null) {
        // make sure track is valid
        if (micTrack !== null && micTrack.readyState === "ended") {
            if (this._micTrack !== null && this._micTrack.readyState === "ended") {
                // current track is also invalid, remove it
                micTrack = null;
            } else {
                return;
            }
        }

        // ignore matching tracks
        if (micTrack === this._micTrack) {
            return;
        }

        // setup track ended listener
        if (micTrack !== null) {
            this._micTrackListener = () => {
                if (micTrack === this._micTrack) {
                    this._micTrack = null;
                    this._micTrack.removeEventListener("ended", this._micTrackListener);
                    this._micTrackListener = null;
                    // XXX either the track was ended due to a problem, or mic
                    // permissions were revoked. if the track ended due to a
                    // problem, get a new track by re-requesting mic
                    // permissions, otherwise (the user revoked permissions) the
                    // user will deny permissions and it won't be asked again
                    // (unless the user tries to toggle P2P or mediasoup on)
                    this.log(Level.WARN, "Microphone track ended without a replacement track. Either there is a problem with the microphone, or the user revoked microphone permissions; asking for permissions again");
                    this.requestMic();
                }
            };
            micTrack.addEventListener("ended", this._micTrackListener);
        }

        // replace with new track
        this._micTrack = micTrack;

        this.updateTxMediasoup();

        for (const otherPeer of this.otherPeers.values()) {
            otherPeer.setMicTrack(this._micTrack);
        }
    }

    /**
     * Update the transmission state for mediasoup. If anyone is listening, try
     * to start transmitting audio, otherwise, try to stop transmitting audio.
     *
     * @private
     * @param {boolean} [replace] - Should the producer be replaced? False by default
     * @returns {Promise<void>}
     */
    async updateTxMediasoup(replace = false) {
        // optimise network usage; if all peers are connected via p2p, but
        // mediasoup is enabled, then pause the producer (no need to send mic
        // data to mediasoup if no one is listening)
        if (this.hasMediasoupListeners) {
            await this.tryTxMediasoup(replace);
        } else {
            await this.stopTxMediasoup();
        }
    }

    /**
     * Request an audio stream from the mediasoup signaling server.
     *
     * @type {T} otherPeerID - ID of peer to get audio stream for
     * @private
     */
    requestMediasoupAudioStream(otherPeerID) {
        if (this._mediasoupSig) {
            this._mediasoupSig.request("request-audio-stream", {
                otherPeer: otherPeerID,
            }).catch((err) => {
                this.log(Level.ERROR, "mediasoup audio stream request failed;", err);
            });
        }
    }
}