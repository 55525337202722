import { Component } from '@wonderlandengine/api';
import { PickupInvincibility, PickupInvincibilityParams } from '../implementations/pickup-invincibility.js';
import { PickupsManager } from '../pickups-manager.js';
import { PickupComponent } from './pickup-component.js';

export class PickupInvincibilityComponent extends Component implements PickupComponent {
    static TypeName = "pickup-invincibility";

    static onRegister() {
        PickupsManager.getPickupComponentTypes().pp_pushUnique(PickupInvincibilityComponent);
    }

    createPickup(): PickupInvincibility {
        const params = new PickupInvincibilityParams();
        return new PickupInvincibility(this.object, params);
    }
}