import { type GameLocation } from "hoverfit-shared-netcode";
import common from "src/hoverfit/common.js";
import { HoverboardDebugs } from "src/hoverfit/game/components/hoverboard-debugs-component.js";
import { Globals } from "wle-pp";
import { ObservableItemIDList } from "../data-structs/observable-item-id-list.js";
import { ObservableItemIDMap } from "../data-structs/observable-item-id-map.js";
import { type AssetBase, type AssetManifest, type Bundle, type HairColorVariant, type HeadwearVariant, type HoverboardVariant, type LocationVariant, type SkinVariant, type SuitVariant } from "./asset-manifest-types.js";
import { type AssetProvider, ItemCategory, type ItemNamespace } from "./asset-provider.js";
import { type DynamicManifest } from "./dynamic-manifest.js";
import { ShopItem } from "./shop-item.js";

export class IAPContentController {
    private waitingManifests = 0;
    readonly inventory = new ObservableItemIDList(true);
    readonly catalog = new ObservableItemIDMap<ShopItem>(true);
    private readonly assets = new Map<string, Readonly<AssetBase>>();
    private readonly assetCategories = new Map<string, ItemCategory>();
    private readonly shopProviders: AssetProvider[] = [];
    private readonly readyQueue: (() => void)[] = [];
    private readonly dynamicManifests = new Map<ItemNamespace, DynamicManifest>();

    constructor(shopProviderCtors: ReadonlyArray<new (controller: IAPContentController) => AssetProvider>) {
        for (const ctor of shopProviderCtors) {
            this.shopProviders.push(new ctor(this));
        }

        if (this.ready) this.onFinishLoadingManifests();
    }

    private onFinishLoadingManifests() {
        if (!this.inventory.suppressed) return;

        // we have to wait for all inventories so that we don't unequip
        // things that we own, however, we don't care if the catalog is not
        // loaded yet. we can handle the catalog asynchronously
        const invPromises: Promise<unknown>[] = [];
        for (let i = this.shopProviders.length - 1; i >= 0; i--) {
            invPromises.push(this.shopProviders[i].fetchInventory());
        }

        Promise.allSettled(invPromises).then(() => {
            this.inventory.unsuppress();
            this.catalog.unsuppress();
        });

        for (const callback of this.readyQueue) {
            try {
                callback();
            } catch (err) {
                console.error(err);
            }
        }

        this.readyQueue.length = 0;
    }

    private finishLoadingManifest() {
        if (--this.waitingManifests === 0) this.onFinishLoadingManifests();
    }

    get ready() {
        return this.waitingManifests === 0;
    }

    waitForReady() {
        return new Promise<void>((resolve, _reject) => {
            if (this.ready) {
                resolve();
            } else {
                this.readyQueue.push(resolve);
            }
        });
    }

    private loadManifestList<T extends AssetBase>(inManifestList: { [key: string]: Readonly<T> }, namespace: ItemNamespace, category: ItemCategory) {
        for (const shortID of Object.getOwnPropertyNames(inManifestList)) {
            const id = `${namespace}:${shortID}`;
            if (this.assets.has(id)) {
                console.error(`Ignored asset with ID "${id}"; ID already exists`);
                continue;
            }

            this.assets.set(id, inManifestList[shortID]);
            this.assetCategories.set(id, category);
        }
    }

    loadManifest(manifest: AssetManifest, namespace: ItemNamespace) {
        this.loadManifestList(manifest.hoverboard, namespace, ItemCategory.Hoverboard);
        this.loadManifestList(manifest.suit, namespace, ItemCategory.Suit);
        this.loadManifestList<HeadwearVariant>(manifest.headwear, namespace, ItemCategory.Headwear);
        this.loadManifestList(manifest.hairColor, namespace, ItemCategory.HairColor);
        this.loadManifestList(manifest.skin, namespace, ItemCategory.Skin);
        this.loadManifestList(manifest.location, namespace, ItemCategory.Location);
        this.loadManifestList(manifest.bundle, namespace, ItemCategory.Bundle);
    }

    async fetchAndLoadManifest(url: string, namespace: ItemNamespace): Promise<AssetManifest> {
        this.waitingManifests++;
        let manifest: AssetManifest;

        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`Unexpected status code ${response.status} ${response.statusText}`);
            }
            manifest = await response.json() as AssetManifest;

            this.loadManifest(manifest, namespace);
        } finally {
            this.finishLoadingManifest();
        }

        return manifest;
    }

    registerDynamicManifest(namespace: ItemNamespace, dynamicManifest: DynamicManifest) {
        if (this.dynamicManifests.has(namespace)) {
            throw new Error('DynamicManifest already registered to given namespace');
        }

        this.dynamicManifests.set(namespace, dynamicManifest);
    }

    private getDynamicAsset(id: string) {
        const colonIdx = id.indexOf(':');
        if (colonIdx < 0) return;

        const dm = this.dynamicManifests.get(id.slice(0, colonIdx) as ItemNamespace);
        if (!dm) return;

        return dm.getAssetByShortID(id.slice(colonIdx + 1));
    }

    getAsset(id: string, categoryHint: ItemCategory.Hoverboard): HoverboardVariant | undefined;
    getAsset(id: string, categoryHint: ItemCategory.Suit): SuitVariant | undefined;
    getAsset(id: string, categoryHint: ItemCategory.Headwear): HeadwearVariant | undefined;
    getAsset(id: string, categoryHint: ItemCategory.HairColor): HairColorVariant | undefined;
    getAsset(id: string, categoryHint: ItemCategory.Skin): SkinVariant | undefined;
    getAsset(id: string, categoryHint: ItemCategory.Location): LocationVariant | undefined;
    getAsset(id: string, categoryHint: ItemCategory.Bundle): Bundle | undefined;
    getAsset(id: string, categoryHint?: ItemCategory): AssetBase | undefined;
    getAsset(id: string, categoryHint?: ItemCategory): AssetBase | undefined {
        if (categoryHint !== undefined && this.getAssetClass(id) !== categoryHint) {
            return;
        }

        const asset = this.assets.get(id);
        if (asset) return asset;

        const pair = this.getDynamicAsset(id);
        if (pair) {
            if (categoryHint !== undefined && pair[0] !== categoryHint) return;
            return pair[1];
        }

        return;
    }

    getAssetRequired(id: string, categoryHint: ItemCategory.Hoverboard): HoverboardVariant;
    getAssetRequired(id: string, categoryHint: ItemCategory.Suit): SuitVariant;
    getAssetRequired(id: string, categoryHint: ItemCategory.Headwear): HeadwearVariant;
    getAssetRequired(id: string, categoryHint: ItemCategory.HairColor): HairColorVariant;
    getAssetRequired(id: string, categoryHint: ItemCategory.Skin): SkinVariant;
    getAssetRequired(id: string, categoryHint: ItemCategory.Location): LocationVariant;
    getAssetRequired(id: string, categoryHint: ItemCategory.Bundle): Bundle;
    getAssetRequired(id: string, categoryHint?: ItemCategory): AssetBase;
    getAssetRequired(id: string, categoryHint?: ItemCategory) {
        const asset = this.getAsset(id, categoryHint);
        if (!asset) throw new Error(`No such asset with ID "${id}"`);
        return asset;
    }

    getAssetClass(id: string): ItemCategory | undefined {
        const cat = this.assetCategories.get(id);
        if (cat) return cat;
        const pair = this.getDynamicAsset(id);
        if (pair) return pair[0];
        return;
    }

    getAllIDsOfClass(category: ItemCategory): string[] {
        const ids: string[] = [];

        for (const [id, idCategory] of this.assetCategories) {
            if (idCategory === category) ids.push(id);
        }

        return ids;
    }

    get unlockAllLocations() {
        return DEV_MODE && common.gameReady && Globals.isDebugEnabled() && HoverboardDebugs.unlockAllKioskUI;
    }

    isLocationOwned(location: GameLocation) {
        if (this.unlockAllLocations) return true;

        for (const id of this.inventory.getAllIDs()) {
            if (this.assetCategories.get(id) !== ItemCategory.Location) continue;
            const asset = this.assets.get(id) as LocationVariant | undefined;
            if (asset && asset.unlocksLocation === location) return true;
        }

        return false;
    }

    dispose() {
        for (const provider of this.shopProviders) {
            provider.dispose();
        }
    }
}