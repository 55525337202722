import { Component, MeshComponent, Property } from "@wonderlandengine/api";
import { currentPlayerData } from "src/hoverfit/data/player-data.js";
import { setMaterialFromMaterialConfig } from "src/hoverfit/misc/asset-loading.js";
import { HeadwearSubCategory, ItemCategory } from "src/hoverfit/misc/asset-provision/asset-provider.js";
import { CancellablePromise, CancelledMarkerMap } from "src/hoverfit/utils/cancellable-promise.js";
import { vec4_create } from "wle-pp";
import common from "../../common.js";
import { Gender } from "../../data/values/gender.js";

const SUIT_MATERIAL_INDEX_TO_KEY = ["primaryMaterial", "secondaryMaterial", "emissive"];

export class AvatarSelectorComponent extends Component {
    static TypeName = "avatar-selector";
    static Properties = {
        /* Female mesh properties */
        femaleBodyMesh: Property.mesh(null),
        femaleBodyMaterial: Property.material(null),
        femaleEyelashesMesh: Property.mesh(null),
        femaleEyelashesMaterial: Property.material(null),
        femaleHairMesh: Property.mesh(null),
        femaleHairMaterial: Property.material(null),
        femaleEyesMesh: Property.mesh(null),
        femaleEyesMaterial: Property.material(null),
        femaleTeethMesh: Property.mesh(null),
        femaleTeethMaterial: Property.material(null),
        femaleSuitMesh: Property.mesh(null),
        femaleSuitMaterial: Property.material(null),
        femaleEyebrowsMesh: Property.mesh(null),
        femaleEyebrowsMaterial: Property.material(null),

        /* Male mesh properties */
        maleBodyMesh: Property.mesh(null),
        maleBodyMaterial: Property.material(null),
        maleEyelashesMesh: Property.mesh(null),
        maleEyelashesMaterial: Property.material(null),
        maleHairMesh: Property.mesh(null),
        maleHairMaterial: Property.material(null),
        maleEyesMesh: Property.mesh(null),
        maleEyesMaterial: Property.material(null),
        maleTeethMesh: Property.mesh(null),
        maleTeethMaterial: Property.material(null),
        maleSuitMesh: Property.mesh(null),
        maleSuitMaterial: Property.material(null),
        maleEyebrowsMesh: Property.mesh(null),
        maleEyebrowsMaterial: Property.material(null),

        localAvatar: Property.object(),
    };

    init() {
        common.avatarSelector = this;
        this.suitMarkers = new CancelledMarkerMap();
        this.headwearMarkers = new CancelledMarkerMap();
        this.hairColorMarkers = new CancelledMarkerMap();
    }

    setAvatarType(type, avatar, shareToNetwork) {
        if (avatar.currentAvatarType === type && avatar.avatarEquipSetupDone) return;
        avatar.currentAvatarType = type;

        const body = avatar.bodyObject;

        if (avatar === currentPlayerData.avatar) {
            common.watchController.setWatchCursorOffset(type === Gender.Female);
        }

        let genderPrefix = type === Gender.Female ? "female" : "male";

        const bodyMesh = body.getComponent(MeshComponent, 0);
        bodyMesh.mesh = this[genderPrefix + "BodyMesh"];
        let bodyMeshMat = this[genderPrefix + "BodyMaterial" + bodyMesh.object.pp_getID() + "Cloned"];
        if (!bodyMeshMat) {
            this[genderPrefix + "BodyMaterial" + bodyMesh.object.pp_getID() + "Cloned"] = this[genderPrefix + "BodyMaterial"].clone();
            bodyMeshMat = this[genderPrefix + "BodyMaterial" + bodyMesh.object.pp_getID() + "Cloned"];
        }
        bodyMesh.material = bodyMeshMat;

        const bodyEyelashesMesh = body.getComponent(MeshComponent, 1);
        bodyEyelashesMesh.mesh = this[genderPrefix + "EyelashesMesh"];
        bodyEyelashesMesh.material = this[genderPrefix + "EyelashesMaterial"];

        const bodyHairMesh = body.getComponent(MeshComponent, 2);
        bodyHairMesh.mesh = this[genderPrefix + "HairMesh"];
        let bodyHairMeshMat = this[genderPrefix + "HairMaterial" + bodyHairMesh.object.pp_getID() + "Cloned"];
        if (!bodyHairMeshMat) {
            this[genderPrefix + "HairMaterial" + bodyHairMesh.object.pp_getID() + "Cloned"] = this[genderPrefix + "HairMaterial"].clone();
            bodyHairMeshMat = this[genderPrefix + "HairMaterial" + bodyHairMesh.object.pp_getID() + "Cloned"];
        }
        bodyHairMesh.material = bodyHairMeshMat;

        const eyes = avatar.eyesObject;
        const eyesMesh = eyes.getComponent(MeshComponent, 0);
        eyesMesh.mesh = this[genderPrefix + "EyesMesh"];
        eyesMesh.material = this[genderPrefix + "EyesMaterial"];

        const teethMesh = body.getComponent(MeshComponent, 3);
        teethMesh.mesh = this[genderPrefix + "TeethMesh"];
        teethMesh.material = this[genderPrefix + "TeethMaterial"];

        const bodyEyebrowsMesh = body.getComponent(MeshComponent, 4);
        bodyEyebrowsMesh.mesh = this[genderPrefix + "EyebrowsMesh"];
        let bodyEyebrowsMeshMat = this[genderPrefix + "EyebrowsMaterial" + bodyEyebrowsMesh.object.pp_getID() + "Cloned"];
        if (!bodyEyebrowsMeshMat) {
            this[genderPrefix + "EyebrowsMaterial" + bodyEyebrowsMesh.object.pp_getID() + "Cloned"] = this[genderPrefix + "EyebrowsMaterial"].clone();
            bodyEyebrowsMeshMat = this[genderPrefix + "EyebrowsMaterial" + bodyEyebrowsMesh.object.pp_getID() + "Cloned"];
        }
        bodyEyebrowsMesh.material = bodyEyebrowsMeshMat;

        const suit = avatar.suitObject;
        const suitMesh = suit.getComponent(MeshComponent, 0);
        let suitMeshMat = this[genderPrefix + "SuitMaterial" + suitMesh.object.pp_getID() + "Cloned"];
        if (!suitMeshMat) {
            suitMesh.mesh = this[genderPrefix + "SuitMesh"];
            this[genderPrefix + "SuitMaterial" + suitMesh.object.pp_getID() + "Cloned"] = this[genderPrefix + "SuitMaterial"].clone();
            suitMeshMat = this[genderPrefix + "SuitMaterial" + suitMesh.object.pp_getID() + "Cloned"];
            suitMesh.material = suitMeshMat;
            this.disableAdditionalSuitMeshes(suit);
        }

        // On avatar type switch, set the same settings for other avatar types
        this.setAvatarSkinColor(avatar.skinColorKey, avatar, false);
        this.setAvatarSuit(avatar.suitVariant, avatar, false);
        this.setAvatarHeadwear(avatar.headwearVariant, avatar, false);
        this.setAvatarHairColor(avatar.hairColor, avatar, false);

        const hoverboardRoom = common.hoverboardNetworking.room;
        if (shareToNetwork && hoverboardRoom) hoverboardRoom.send("set-avatar-type", { type });

        avatar.avatarEquipSetupDone = true;
    }

    disableAdditionalSuitMeshes(suitObject) {
        const suitMeshes = suitObject.pp_getComponentsSelf(MeshComponent);

        if (suitMeshes.length < 2) {
            const mesh1 = suitObject.addComponent(MeshComponent);
            mesh1.skin = suitMeshes[0].skin;
            const mesh2 = suitObject.addComponent(MeshComponent);
            mesh2.skin = suitMeshes[0].skin;
            suitMeshes.push(mesh1);
            suitMeshes.push(mesh2);
        }

        if (suitMeshes[1]) suitMeshes[1].active = false;
        if (suitMeshes[2]) suitMeshes[2].active = false;

        // Temp adjustment for the fact that activating the object will sadly also reactivate this
        if (!suitMeshes[1].active) {
            suitMeshes[1].mesh = null;
        }

        if (!suitMeshes[2].active) {
            suitMeshes[2].mesh = null;
        }
    }

    setAvatarSkinColor(skinColorKey, avatar, shareToNetwork) {
        const configObject = common.kioskLowerUI.iapContentController.getAsset(skinColorKey, ItemCategory.Skin);
        if (!configObject) return console.error("Can't load config ", skinColorKey);

        const body = avatar.bodyObject;
        const bodyMesh = body.getComponent(MeshComponent, 0);
        bodyMesh.material.diffuseColor = configObject.color;
        avatar.skinColorKey = skinColorKey;

        const hoverboardRoom = common.hoverboardNetworking.room;
        if (shareToNetwork && hoverboardRoom) hoverboardRoom.send("set-skin-color", { color: skinColorKey });
    }

    setAvatarSuit(suitVariant, avatar, shareToNetwork) {
        const configObject = common.kioskLowerUI.iapContentController.getAsset(suitVariant, ItemCategory.Suit);
        if (!configObject) return console.error("Can't load config ", suitVariant);

        const genderSuffix = avatar.currentAvatarType === Gender.Female ? "Female" : "Male";
        const url = configObject["url" + genderSuffix];
        const suit = avatar.suitObject;

        this.disableAdditionalSuitMeshes(suit);

        const suitMeshes = suit.pp_getComponentsSelf(MeshComponent);
        const materials = configObject["materials" + genderSuffix];

        const marker = this.suitMarkers.replace(avatar);
        avatar.suitVariant = suitVariant;

        CancellablePromise.wrapPromise(common.hoverfitSceneResources.getObjectFromURL(url), marker).then((suitRoot) => {
            const newSuitObject = suitRoot.pp_getObjectByName("[Ss]uit", true);
            const newSuitMeshComponents = newSuitObject.pp_getComponentsSelf(MeshComponent);
            for (let i = 0; i < newSuitMeshComponents.length; i++) {
                const oldMeshComponent = suitMeshes[i];
                const newMesh = newSuitMeshComponents[i].mesh;
                oldMeshComponent.mesh = newMesh;
                const active = !!newMesh;
                oldMeshComponent.active = active;
                if (active) {
                    setMaterialFromMaterialConfig(oldMeshComponent, materials[SUIT_MATERIAL_INDEX_TO_KEY[i]], true, this.engine, marker);
                }
            }
        }).catch(CancellablePromise.ignoreCancel);

        const hoverboardRoom = common.hoverboardNetworking.room;
        if (shareToNetwork && hoverboardRoom) hoverboardRoom.send("set-suit-variant", { suitVariant });
    }

    setAvatarHeadwear(headwearVariant, avatar, shareToNetwork) {
        const configObject = common.kioskLowerUI.iapContentController.getAsset(headwearVariant, ItemCategory.Headwear);
        if (!configObject) return console.error("Can't load config ", headwearVariant);

        const isHair = configObject.type === HeadwearSubCategory.Hair;
        const localRes = common.hoverfitSceneResources;

        this._setHelmetEnabled(avatar, !isHair);

        const marker = this.headwearMarkers.replace(avatar);
        avatar.headwearVariant = headwearVariant;

        if (isHair) {
            // Set Hair

            const body = avatar.bodyObject;
            const bodyHeadwearMesh = body.getComponent(MeshComponent, 2);

            const genderSuffix = avatar.currentAvatarType === Gender.Female ? "Female" : "Male";

            CancellablePromise.wrapPromise(localRes.getObjectFromURL(configObject["url" + genderSuffix]), marker).then((headwearRoot) => {
                bodyHeadwearMesh.mesh = headwearRoot.pp_getComponent(MeshComponent).mesh;
                this.setAvatarHairColor(avatar.hairColor, avatar, false);
            }).catch(CancellablePromise.ignoreCancel);
        } else {
            // Set Helmet

            const helmetObject = avatar.getHelmetObject();
            if (!helmetObject) return;

            CancellablePromise.wrapPromise(localRes.getObjectFromURL(configObject.url)).then((helmetRoot) => {
                const mesh0 = helmetObject.getComponent(MeshComponent, 0);
                const mesh1 = helmetObject.getComponent(MeshComponent, 1);
                mesh0.mesh = helmetRoot.pp_getComponent(MeshComponent, 0).mesh;
                mesh1.mesh = helmetRoot.pp_getComponent(MeshComponent, 1).mesh;
                setMaterialFromMaterialConfig(mesh0, configObject.materials.primaryMaterial, true, this.engine, marker);
                setMaterialFromMaterialConfig(mesh1, configObject.materials.secondaryMaterial, true, this.engine, marker);
                helmetObject.resetTransform();
            }).catch(CancellablePromise.ignoreCancel);
        }

        const hoverboardRoom = common.hoverboardNetworking.room;
        if (shareToNetwork && hoverboardRoom) hoverboardRoom.send("set-headwear-variant", { headwearVariant: headwearVariant });
    }

    _setHelmetEnabled(avatar, helmetEnabled) {
        const helmetObject = avatar.getHelmetObject();
        if (helmetObject) helmetObject.active = helmetEnabled;
        avatar.bodyObject.getComponent(MeshComponent, 2).active = !helmetEnabled;

        // Temp adjustment for the fact that activating the object will sadly also reactivate this
        if (helmetEnabled) {
            avatar.bodyObject.getComponent(MeshComponent, 2).mesh = null;
        } else {
            for (const meshComponent of helmetObject.pp_getComponents(MeshComponent)) {
                meshComponent.mesh = null;
            }
        }
    }

    setAvatarHairColor(hairColor, avatar, shareToNetwork) {
        const config = common.kioskLowerUI.iapContentController.getAsset(hairColor, ItemCategory.HairColor);
        if (!config) return console.error("Can't load config ", hairColor);

        const marker = this.hairColorMarkers.replace(avatar);
        avatar.hairColor = hairColor;

        CancellablePromise.wrapPromise(common.hoverfitSceneResources.getTextureFromURL(config.url), marker).then((diffuseTexture) => {
            const headwearConfig = common.kioskLowerUI.iapContentController.getAsset(avatar.headwearVariant, ItemCategory.Headwear);
            if (!headwearConfig) return console.error("Can't load config ", avatar.headwearVariant);

            if (headwearConfig.type === HeadwearSubCategory.Hair) {
                const body = avatar.bodyObject;

                const bodyHeadwearMesh = body.getComponent(MeshComponent, 2);
                bodyHeadwearMesh.material.diffuseColor = vec4_create(1, 1, 1, 1);
                bodyHeadwearMesh.material.diffuseTexture = diffuseTexture;

                const bodyEyebrowsMesh = body.getComponent(MeshComponent, 4);
                bodyEyebrowsMesh.material.diffuseTexture = diffuseTexture;
                bodyEyebrowsMesh.material.diffuseColor = vec4_create(1, 1, 1, 1);
            }
        }).catch(CancellablePromise.ignoreCancel);

        const hoverboardRoom = common.hoverboardNetworking.room;
        if (shareToNetwork && hoverboardRoom) {
            // FIXME handle string IDs (stop converting to number) - needs
            //       server-side changes
            hoverboardRoom.send("set-hair-color", { color: hairColor });
        }
    }

    onDestroy() {
        this.suitMarkers.cancelAll();
        this.headwearMarkers.cancelAll();
        this.hairColorMarkers.cancelAll();
    }
}
