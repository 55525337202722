import { type Widget, type XMLUIParser, type XMLUIParserConfig, type XMLUIParserContext } from "lazy-widgets";
import { BaseLazyWidgetsXMLComponent, type WLVirtualKeyboardRootProperties } from "lazy-widgets-wle";
import { sfTheme } from "../../misc/sf-theme.js";

export abstract class BaseFitnessResortUIComponent extends BaseLazyWidgetsXMLComponent {
    protected abstract getXMLContent(): string;

    private loadWait: null | true | (() => void) = null;

    protected override getRootProperties(): WLVirtualKeyboardRootProperties {
        return {
            ...super.getRootProperties(),
            theme: sfTheme,
        };
    }

    protected override parseXML(xmlParser: XMLUIParser): Promise<[Map<string, Widget>, XMLUIParserContext]> {
        // HACK wait for start to be called before loading XML. this is done
        //      because the load order in hoverfit is all over the place, and
        //      this prevents null dereference errors
        return new Promise((resolve) => {
            const callback = () => {
                resolve(xmlParser.parseFromString(this.getXMLContent(), this.getXMLParserConfig()));
                this.loadWait = null;
            };

            if (this.loadWait === true) {
                // already started
                callback();
            } else {
                // not started. wait for start
                this.loadWait = callback;
            }
        });
    }

    protected override getXMLParserConfig(): XMLUIParserConfig {
        return {
            allowScripts: false,
        };
    }

    override start() {
        if (this.loadWait && this.loadWait !== true) this.loadWait();
        this.loadWait = true;

        const superStart = super.start;
        if (superStart) superStart();
    }
}