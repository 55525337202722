import { Component, type Object3D } from "@wonderlandengine/api";
import { property } from "@wonderlandengine/api/decorators.js";
import { EventfulEncryptedAudio, GhostAudioState, PanningOptions, extractEntropyFromURL, type EncryptedAudioSink } from "encrypted-audio";

const DISTANCE_MODEL_WLE_ENUM = ["exponential", "inverse", "linear"];
const PANNING_MODEL_WLE_ENUM = ["HRTF", "equalpower"];

export class EncryptedAudioComponent extends Component {
    static override TypeName = "encrypted-audio";

    @property.float(0.0)
    currentTimeStartValue!: number;
    @property.float(1.0)
    playbackRateStartValue!: number;
    @property.float(1.0)
    volumeStartValue!: number;
    @property.bool(false)
    loopStartValue!: boolean;
    @property.string()
    encryptedAudioURL!: string;
    @property.bool(false)
    startSpatialized!: boolean;
    @property.float(360)
    coneInnerAngleStartValue!: number;
    @property.float(0)
    coneOuterAngleStartValue!: number;
    @property.float(0)
    coneOuterGainStartValue!: number;
    @property.enum(DISTANCE_MODEL_WLE_ENUM, "inverse")
    distanceModelStartValue!: number;
    @property.float(10000)
    maxDistanceStartValue!: number;
    @property.enum(PANNING_MODEL_WLE_ENUM, "equalpower")
    panningModelStartValue!: number;
    @property.float(1)
    refDistanceStartValue!: number;
    @property.float(1)
    rolloffFactorStartValue!: number;
    @property.object()
    sinkObjectStartValue!: Object3D;
    @property.string("encrypted-audio-sink")
    sinkComponentName!: string;

    private updatePlacement!: boolean;
    private decrypted!: boolean;
    private currentState!: GhostAudioState | EventfulEncryptedAudio;
    private queuedSink!: EncryptedAudioSink | null;

    override init() {
        this.queuedSink = null;
        if (this.sinkObjectStartValue) {
            const sinkComp = this.sinkObjectStartValue.getComponent(this.sinkComponentName);
            if (sinkComp) {
                this.queuedSink = (sinkComp as unknown as { sink: EncryptedAudioSink }).sink;
            }
        }

        this.updatePlacement = false;
        this.decrypted = false;
        this.currentState = new GhostAudioState();
        this.currentState.currentTime = this.currentTimeStartValue;
        this.currentState.playbackRate = this.playbackRateStartValue;
        this.currentState.volume = this.volumeStartValue;
        this.currentState.loop = this.loopStartValue;

        if (this.startSpatialized) {
            this.updatePlacement = true;
            const panOpts = new PanningOptions();
            panOpts.coneInnerAngle = this.coneInnerAngleStartValue;
            panOpts.coneOuterAngle = this.coneOuterAngleStartValue;
            panOpts.coneOuterGain = this.coneOuterGainStartValue;
            panOpts.distanceModel = DISTANCE_MODEL_WLE_ENUM[this.distanceModelStartValue] as DistanceModelType;
            panOpts.maxDistance = this.maxDistanceStartValue;
            panOpts.panningModel = PANNING_MODEL_WLE_ENUM[this.panningModelStartValue] as PanningModelType;
            panOpts.refDistance = this.refDistanceStartValue;
            panOpts.rolloffFactor = this.rolloffFactorStartValue;
            this.currentState.panning = panOpts;
        }

        extractEntropyFromURL("misc/entropy.bin").then((entropySource) => {
            EventfulEncryptedAudio.fromEncryptedURL(entropySource, this.encryptedAudioURL).then((encAudio) => {
                encAudio.addEventListener("play", () => {
                    if (!this.active) {
                        this.active = true;
                    }
                });

                encAudio.addEventListener("pause", () => {
                    if (this.active) {
                        this.active = false;
                    }
                });

                if (this.queuedSink) {
                    encAudio.sink = this.queuedSink;
                }

                (this.currentState as GhostAudioState).transferTo(encAudio);
                this.currentState = encAudio;
                this.decrypted = true;
            });
        });
    }

    override update(dt: number) {
        if (this.updatePlacement) {
            const placement = this.currentState.placement;
            this.object.getPositionWorld(placement.position);
            this.object.getRotationWorld(placement.rotation);
        }

        if (this.decrypted) {
            (this.currentState as EventfulEncryptedAudio).updatePlacement();
        }
    }

    override onActivate() {
        if (!this.currentState.playing) {
            this.currentState.play();
        }
    }

    override onDeactivate() {
        if (this.currentState.playing) {
            this.currentState.pause();
        }
    }

    override onDestroy() {
        this.currentState.dispose();
    }

    get currentTime() {
        return this.currentState.currentTime;
    }

    set currentTime(currentTime) {
        this.currentState.currentTime = currentTime;
    }

    get playbackRate() {
        return this.currentState.playbackRate;
    }

    set playbackRate(playbackRate) {
        this.currentState.playbackRate = playbackRate;
    }

    get volume() {
        return this.currentState.volume;
    }

    set volume(volume) {
        this.currentState.volume = volume;
    }

    get loop() {
        return this.currentState.loop;
    }

    set loop(loop) {
        this.currentState.loop = loop;
    }

    get duration() {
        return this.currentState.duration;
    }

    get playing() {
        return this.currentState.playing;
    }

    get panning() {
        return this.currentState.panning;
    }

    set panning(panning) {
        this.updatePlacement = !!panning;
        this.currentState.panning = panning;
    }

    play() {
        this.currentState.play();
    }

    pause() {
        this.currentState.pause();
    }

    stop() {
        this.currentState.stop();
    }

    resetAudio() {
        this.currentState.reset();
    }

    playPause() {
        this.currentState.playPause();
    }

    get ready() {
        if (this.decrypted) {
            return (this.currentState as EventfulEncryptedAudio).ready;
        } else {
            return false;
        }
    }

    get loaded() {
        if (this.decrypted) {
            return (this.currentState as EventfulEncryptedAudio).loaded;
        } else {
            return false;
        }
    }

    get sink() {
        if (this.decrypted) {
            return (this.currentState as EventfulEncryptedAudio).sink;
        } else {
            return this.queuedSink;
        }
    }

    set sink(sink) {
        if (this.decrypted) {
            (this.currentState as EventfulEncryptedAudio).sink = sink;
        } else {
            this.queuedSink = sink;
        }
    }
}
