import { Component, MeshComponent } from "@wonderlandengine/api";
import { property } from "@wonderlandengine/api/decorators.js";
import { ScrollingTextureMaterial } from "../../../types/material-types.js";

/**
 * Automatically scrolls textures on the component
 * Requires a ScrollingTextureMaterial
 */
export class AutoTextureScrollComponent extends Component {
    static override TypeName = "auto-texture-scroll";

    @property.float(1.0)
    speed!: number;

    @property.bool(true)
    scrollY!: boolean;

    material!: ScrollingTextureMaterial;
    scrollTime: number = 0.0;

    override start() {
        this.material = this.object.getComponent(MeshComponent)?.material as ScrollingTextureMaterial;
    }

    override update(dt: number) {
        if (this.material) {
            this.scrollTime += dt * this.speed;

            if (this.scrollTime > 1.0) {
                this.scrollTime -= 1.0;
            } else if (this.scrollTime < 0.0) {
                this.scrollTime += 1.0;
            }

            if (this.scrollY) {
                this.material.yScroll = this.scrollTime;
            } else {
                this.material.xScroll = this.scrollTime;
            }
        }
    }
}
