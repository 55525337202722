import { Icon, Label, Row, TextAlignMode, WidgetAutoXML, filterIDFromProperties } from "lazy-widgets";
import { BaseDecoratedButton, type DecoratedButtonProperties } from "./base-decorated-button.js";

export interface LabelledDecoratedButtonProperties extends DecoratedButtonProperties {
    buttonLabelFill?: string;
    unclickableButtonLabelFill?: string;
}

export class ThumbnailDecoratedButton extends BaseDecoratedButton<Row<Label | Icon>> {
    static override autoXML: WidgetAutoXML = {
        name: "thumbnail-decorated-button",
        inputConfig: [
            {
                mode: "text",
                name: "name",
            },
            {
                mode: "value",
                name: "image",
            },
        ]
    };

    private buttonLabelFill: string;
    private unclickableButtonLabelFill: string;
    readonly label: Label;

    constructor(name: string, image: HTMLImageElement, properties?: Readonly<LabelledDecoratedButtonProperties>) {
        const buttonLabelFill = properties?.buttonLabelFill ?? "black";
        const unclickableButtonLabelFill = properties?.unclickableButtonLabelFill ?? "rgb(128,128,128)";

        const label = new Label(
            name,
            {
                bodyTextFill: buttonLabelFill,
                bodyTextFont: (properties?.fontSize || 0.9) + "em sui-generis",
                bodyTextAlign: TextAlignMode.Center,
                bodyTextSpacing: 0,
                ...filterIDFromProperties(properties),
            },
        );

        super(
            new Row([new Icon(image, { width: 16, height: 16 }), label]),
            properties,
        );

        this.buttonLabelFill = buttonLabelFill;
        this.unclickableButtonLabelFill = unclickableButtonLabelFill;
        this.label = label;
        this.updateTextColor();
    }

    private updateTextColor() {
        this.child.bodyTextFill = this.clickable ? this.buttonLabelFill : this.unclickableButtonLabelFill;
    }

    override set clickable(clickable) {
        super.clickable = clickable;
        this.updateTextColor();
    }

    override get clickable() {
        return super.clickable;
    }
}