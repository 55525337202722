/**
 * A priority queue data structure. Priorities are optional and default to 0.
 *
 * @template T - The value stored in this queue.
 */
export class PriorityQueue {
    constructor() {
        /**
         * Internal queue representation.
         *
         * @private
         * @type {Array<[priority: number, subQueue: Array<T>]>}
         */
        this.prioritySubqueues = [];
        /**
         * The number of values in the queue. Do not modify.
         *
         * @readonly
         * @type {number}
         */
        this.length = 0;
    }

    /**
     * Is the queue empty? Equivalent to checking if the length is 0.
     *
     * @type {boolean}
     */
    get empty() {
        return this.length === 0;
    }

    /**
     * Get (or make) the sub-queue assigned to this priority.
     *
     * @private
     * @param {number} priority - The priority of the queue to get
     * @param {boolean} canCreate - Can we create a new queue if it's missing?
     * @returns {[subqueue: Array<T>, startIndex: number] | null} The subqueue assigned to this priority, and the starting index of the subqueue. `null` can be returned if the queue doesn't exist and canCreate is false.
     */
    getSubqueue(priority, canCreate) {
        const count = this.prioritySubqueues.length;
        let i = 0, startIndex = 0;
        for (; i < count; i++) {
            const [subPriority, subQueue] = this.prioritySubqueues[i];

            if (subPriority === priority) {
                return [subQueue, startIndex];
            } else if (subPriority > priority) {
                break;
            }

            startIndex += subQueue.length;
        }

        if (canCreate) {
            const newSubQueue = [];
            this.prioritySubqueues.splice(i, 0, [priority, newSubQueue]);
            return [newSubQueue, startIndex];
        } else {
            return null;
        }
    }

    /**
     * Remove a specific index from a subqueue. If the subqueue becomes empty,
     * it's removed from the subqueue list.
     *
     * @private
     * @param {number} priority - Priority representing the subqueue to modify.
     * @param {number} index - The index in the subqueue to remove.
     * @returns {[value: T, index: number] | null} Returns the removed value and index of the value. `null` if no value was removed (index or subqueue didn't exist).
     */
    removeFromSubqueue(priority, index) {
        if (index < 0) {
            return null;
        }

        const [subqueue, startIndex] = this.getSubqueue(priority, false);
        if (!subqueue || index >= subqueue.length) {
            return null;
        }

        const removed = subqueue.splice(index, 1)[0];

        if (subqueue.length === 0) {
            const count = this.prioritySubqueues.length;
            for (let i = 0; i < count; i++) {
                const iterSubqueue = this.prioritySubqueues[i][1];

                if (iterSubqueue === subqueue) {
                    this.prioritySubqueues.splice(i, 1);
                    break;
                }
            }
        }

        this.length--;
        return [removed, startIndex + index];
    }

    /**
     * Add value to end of queue.
     *
     * @param {T} value - The value to add.
     * @param {number} priority - The priority of the value. If no priority is provided, 0 is used.
     * @returns {number} Returns the index of the enqueued value.
     */
    enqueue(value, priority = 0) {
        const [subQueue, index] = this.getSubqueue(priority, true);
        subQueue.unshift(value);
        this.length++;
        return index;
    }

    /**
     * Remove and get the first value in the queue.
     *
     * @returns {[value: T, index: number] | null} The value removed and the index of the value. If there are no more values in the queue, then `null` is returned.
     */
    dequeue() {
        if (this.empty) {
            return null;
        }

        const [priority, subqueue] = this.prioritySubqueues[this.prioritySubqueues.length - 1];
        return this.removeFromSubqueue(priority, subqueue.length - 1);
    }

    /**
     * Remove the first value that matches the input value from the queue.
     *
     * @param {T} value - The value to match
     * @return {number} Returns the index of the value which was removed, or -1 if the value is missing.
     */
    dequeueMatching(value) {
        for (const [priority, subqueue] of this.prioritySubqueues) {
            const count = subqueue.length;
            for (let i = count - 1; i >= 0; i--) {
                if (subqueue[i] === value) {
                    return this.removeFromSubqueue(priority, i)[1];
                }
            }
        }

        return -1;
    }

    /**
     * Remove all elements from the queue.
     */
    clear() {
        this.prioritySubqueues.length = 0;
        this.length = 0;
    }
}