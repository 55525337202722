import { StatusEffectTarget } from "./status-effect-target.js";

export enum StatusEffectType {
    Glue,
    Rocket,
    SuperRocket,
    Spring,
    SuperSpring,
    Ramp,
    Invincibility,
    Shield
}

export abstract class StatusEffect {
    private _type: StatusEffectType;

    protected _target: StatusEffectTarget;

    protected _done: boolean = false;

    constructor(type: StatusEffectType, target: StatusEffectTarget) {
        this._type = type;
        this._target = target;
    }

    getType(): StatusEffectType {
        return this._type;
    }

    getTarget(): StatusEffectTarget {
        return this._target;
    }

    clear(): void {
        this._done = true;
    }

    isDone(): boolean {
        return this._done;
    }

    abstract start(): void;
    abstract update(dt: number): void;
}