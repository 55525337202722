import { Vector3 } from "wle-pp";
import { VoIPAudioSource } from "../../voip/voip-audio-source.js";
import { SinkedAudio, SourceSink } from "../sinked-audio.js";
import { TypedAudio } from "./typed-audio.js";

export class VoIPAudio extends TypedAudio<VoIPAudioSource> implements SinkedAudio {

    play(): void {
        throw new Error("Method not implemented.");
    }

    stop(): void {
        throw new Error("Method not implemented.");
    }

    pause(): void {
        throw new Error("Method not implemented.");
    }

    resume(): void {
        throw new Error("Method not implemented.");
    }

    isPlaying(): boolean {
        throw new Error("Method not implemented.");
    }

    setPosition(position: Vector3, updateOnlyLastAudio: boolean = false): void {
        throw new Error("Method not implemented.");
    }

    setVolume(volume: number, updateOnlyLastAudio: boolean = false): void {
        if (updateOnlyLastAudio) {
            throw new Error("Parameter updateOnlyLastAudio not implemented.");
        }

        this.getTypedSourceAudio().volume = volume;
    }

    getVolume(): number {
        return this.getTypedSourceAudio().volume;
    }

    fade(fromVolume: number, toVolume: number, durationSeconds: number): void {
        throw new Error("Method not implemented.");
    }

    setPitch(pitch: number, updateOnlyLastAudio: boolean = false): void {
        throw new Error("Method not implemented.");
    }

    getSink(): SourceSink | null {
        return this.getTypedSourceAudio().sink;
    }
}