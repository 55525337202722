import { Component, Object3D } from "@wonderlandengine/api";
import { property } from "@wonderlandengine/api/decorators.js";
import { vec3 } from "gl-matrix";

const TMP_BUF = new Float32Array(4);

export class UIAnchorComponent extends Component {
    static override TypeName = "ui-anchor";

    @property.object()
    referenceSpace!: Object3D;
    @property.float(1)
    minFreeAnchorHeight!: number;
    @property.float(2)
    scaleRefDistance!: number;
    @property.float(2)
    scaleRefWidth!: number;
    @property.float(1.3)
    scaleRefHeight!: number;

    headObject!: Object3D;
    headAnchor!: Object3D;
    freeAnchor!: Object3D;
    currentAnchor!: Object3D;
    onEnterXRCallback!: () => void;
    onLeaveXRCallback!: () => void;
    uiScaleDirty = true;
    resizeObserver!: ResizeObserver;

    override init() {
        this.freeAnchor = this.engine.scene.addObject(this.referenceSpace);
        this.headObject = this.object.parent!;
        this.headAnchor = this.engine.scene.addObject(this.headObject);
        this.object.parent = this.headAnchor;
        this.currentAnchor = this.headAnchor;

        this.onEnterXRCallback = this.onEnterXR.bind(this);
        this.onLeaveXRCallback = this.onLeaveXR.bind(this);

        this.resizeObserver = new ResizeObserver(() => {
            this.uiScaleDirty = true;
        });
    }

    override onActivate(): void {
        this.engine.onXRSessionStart.add(this.onEnterXRCallback);
        this.engine.onXRSessionEnd.add(this.onLeaveXRCallback);
        this.resizeObserver.observe(this.engine.canvas);
    }

    override onDeactivate(): void {
        if (this.currentAnchor === this.freeAnchor) {
            this.onLeaveXR();
        }

        this.engine.onXRSessionStart.remove(this.onEnterXRCallback);
        this.engine.onXRSessionEnd.remove(this.onLeaveXRCallback);
        this.resizeObserver.unobserve(this.engine.canvas);
    }

    onEnterXR() {
        this.currentAnchor = this.freeAnchor;
        this.object.parent = this.currentAnchor;
        this.updateAnchor(0, false);
    }

    onLeaveXR() {
        this.currentAnchor = this.headAnchor;
        this.object.parent = this.currentAnchor;
        this.uiScaleDirty = true;
        this.updateAnchor(0);
    }

    updateAnchor(dt: number, doSlerp = true) {
        if (this.currentAnchor === this.headAnchor) {
            // scale UI to fit reference rectangle at given distance
            if (!this.uiScaleDirty) return;
            this.uiScaleDirty = false;

            const activeViews = this.engine.scene.activeViews;
            const view = activeViews[0];
            if (!view) {
                // XXX for some reason no view is available. reset scale
                this.currentAnchor.setScalingLocal([1, 1, 1]);
                return;
            }

            TMP_BUF[0] = this.scaleRefWidth * 0.5;
            TMP_BUF[1] = this.scaleRefHeight * 0.5;
            TMP_BUF[2] = -this.scaleRefDistance;
            vec3.transformMat4(TMP_BUF, TMP_BUF, view.projectionMatrix);

            const scale = Math.min(1 / TMP_BUF[0], 1 / TMP_BUF[1]);
            TMP_BUF[0] = scale;
            TMP_BUF[1] = scale;
            TMP_BUF[2] = 1;
            this.currentAnchor.setScalingLocal(TMP_BUF);
        } else {
            // correct height of free anchor (level with head, unless head is
            // too low)
            this.headObject.getPositionLocal(TMP_BUF);
            TMP_BUF[0] = 0;
            TMP_BUF[1] = Math.max(TMP_BUF[1], this.minFreeAnchorHeight);
            TMP_BUF[2] = 0;
            this.currentAnchor.setPositionLocal(TMP_BUF);
        }
    }

    override update(dt: number) {
        this.updateAnchor(dt);
    }
}