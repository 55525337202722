import { AudioID } from "src/hoverfit/audio/audio-manager/audio-id.js";
import { StatusEffectTarget } from "../status-effect-target.js";
import { StatusEffectType } from "../status-effect.js";
import { ExpiringStatusEffect, ExpiringStatusEffectParams } from "./expiring-status-effect.js";

export class SuperSpringStatusEffectParams extends ExpiringStatusEffectParams {
    verticalSpeedBoost: number = 60;

    sfxOnStart: AudioID | null = AudioID.STATUS_EFFECT_SUPER_SPRING;
}

export class SuperSpringStatusEffect extends ExpiringStatusEffect {

    declare protected _params: SuperSpringStatusEffectParams;

    constructor(target: StatusEffectTarget, params: SuperSpringStatusEffectParams) {
        super(StatusEffectType.SuperSpring, target, params);
    }

    getParams(): SuperSpringStatusEffectParams {
        return this._params;
    }

    protected _startHook(): void {
        this._target.applyVerticalSpeedBoost(this._params.verticalSpeedBoost);
    }
}