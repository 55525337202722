import { Component, type WonderlandEngine } from "@wonderlandengine/api";
import { property } from "@wonderlandengine/api/decorators.js";
import { EncryptedAudioSink } from "encrypted-audio";

export class EncryptedAudioSinkComponent extends Component {
    static override TypeName = "encrypted-audio-sink";

    @property.bool(true)
    autoResumeStartValue!: boolean;

    sink!: EncryptedAudioSink;

    constructor(engine: WonderlandEngine, manager?: number, id?: number) {
        super(engine, manager, id);

        // HACK we need `sink` to be available before init for other components
        this.sink = new EncryptedAudioSink({
            createContext: false,
            autoResume: false,
        });
    }

    override init() {
        this.sink.autoResume = this.autoResumeStartValue;
    }

    override onActivate() {
        this.sink.makeContext();
    }

    override onDeactivate() {
        this.sink.context = null;
    }

    override update(_dt: number) {
        const placement = this.sink.placement;
        this.object.getPositionWorld(placement.position);
        this.object.getRotationWorld(placement.rotation);
        this.sink.updatePlacement();
    }

    get volume() {
        return this.sink.volume;
    }

    set volume(volume: number) {
        this.sink.volume = volume;
    }
}
