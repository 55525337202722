<?xml version="1.0" encoding="UTF-8"?>
<ui-tree name="default" xmlns="lazy-widgets" xmlns:option="lazy-widgets:options" xmlns:on="lazy-widgets:on">
    <book option:id="mode-book" option:min-width="@624" option:max-width="@624" option:min-height="@303" option:max-height="@303">
        <container option:container-padding='@{"top":8,"right":16,"bottom":8,"left":16}' option:flex="@1">
            <column>
                <row option:multi-container-alignment='@{"main":0.5,"cross":1}'>
                    <icon image="assets/textures/ui/icons/kiosk/person.svg" option:width="@24" option:height="@24"/>
                    <live-label option:body-text-align="@0.5" option:body-text-fill="white" option:body-text-font="2em sui-generis" text-source="$welcome"/>
                </row>
                <rounded-corners option:rounded-corners-radii="@3">
                    <background option:canvas-fill="rgba(30, 50, 100, 0.3)" option:container-padding='@{"top":8,"right":16,"bottom":8,"left":16}'>
                        <score-row fit-points="$fitPoints" fitabux="$fitabux" heyvr-coins="$heyvrCoins" option:dark="@true"/>
                    </background>
                </rounded-corners>
                <spacing option:min-height="@8" option:flex="@0"/>
                <back-pane option:flex="@1" option:alpha="@0.25" option:container-padding='@{"top":8,"right":16,"bottom":8,"left":16}'>
                    <column>
                        <label option:body-text-align="@0.5" option:body-text-font="1.5em sui-generis" option:body-text-fill="black" text="Leaderboard - Fastest Lap"/>
                        <live-label option:body-text-align="@0.5" text-source="$headerText" option:body-text-fill="black"/>
                        <row>
                            <row option:multi-container-spacing="@0" option:min-width="$leaderboardWidth" option:max-width="$leaderboardWidth">
                                <label option:body-text-align="@0.5" option:body-text-fill="black" option:min-width="$leaderboardRankWidth" option:max-width="$leaderboardRankWidth">Rank</label>
                                <label option:body-text-align="@0.5" option:flex="@1" option:body-text-fill="black">Name</label>
                                <label option:body-text-align="@0.5" option:body-text-fill="black" option:min-width="$leaderboardTimeWidth" option:max-width="$leaderboardTimeWidth">Time</label>
                            </row>
                        </row>
                        <leaderboard option:id="leaderboard" table-width="$leaderboardWidth" rank-width="$leaderboardRankWidth" time-width="$leaderboardTimeWidth" row-height="$leaderboardRowHeight">
                            <label option:body-text-align="@0.5">No high scores available</label>
                        </leaderboard>
                    </column>
                </back-pane>
            </column>
        </container>
        <rounded-corners option:rounded-corners-radii="@20" option:flex="@1">
            <kiosk-background>
                <container option:container-padding='@{"top":8,"right":16,"bottom":8,"left":16}'>
                    <column>
                        <score-row fit-points="$fitPoints" fitabux="$fitabux" heyvr-coins="$heyvrCoins"/>
                        <back-pane option:flex="@1">
                            <column>
                                <label option:body-text-align="@0.5" option:body-text-font="2em sui-generis">CUSTOMISE YOUR AVATAR</label>
                                <spacing/>
                            </column>
                        </back-pane>
                    </column>
                </container>
            </kiosk-background>
        </rounded-corners>
    </book>
</ui-tree>