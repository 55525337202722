import { type IAPContentController } from "./iap-content-controller.js";

export enum ItemNamespace {
    BuiltIn = "bi",
    HeyVR = "heyvr",
    Fitabux = "fitabux",
}

export enum ItemCategory {
    Skin = "skin",
    Hoverboard = "hoverboard",
    Headwear = "headwear",
    Suit = "suit",
    HairColor = "hairColor",
    Location = "location",
    Bundle = "bundle",
}

export enum HeadwearSubCategory {
    Hair = "hair",
    Helmet = "helmet",
}

export enum ItemCurrency {
    HeyVR = 0,
    Fitabux = 1,
}

export class GenericPurchaseError extends Error { }
export class NoFundsPurchaseError extends GenericPurchaseError {
    constructor(readonly currencyType: ItemCurrency) {
        super('Not enough funds');
    }
}

export abstract class AssetProvider {
    abstract fetchInventory(): Promise<void>;
    abstract purchaseItem(shortID: string): Promise<boolean>;

    constructor(readonly controller: IAPContentController) { }

    get catalog() {
        return this.controller.catalog;
    }

    get inventory() {
        return this.controller.inventory;
    }
}