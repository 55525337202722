// Uses Mulberry32
export function seededRandom(a: number): number {
    let t = a += 0x6D2B79F5;
    t = Math.imul(t ^ t >>> 15, t | 1);
    t ^= t + Math.imul(t ^ t >>> 7, t | 61);
    return ((t ^ t >>> 14) >>> 0) / 4294967296;
}

export function circularDistanceWithSign(a: number, b: number, maxValue: number): Record<string, number> {
    const diff = b - a;

    let distance = Math.abs(diff);
    distance = Math.min(distance, maxValue - distance);

    const sign = (diff >= 0 && diff <= maxValue / 2) || (diff < -maxValue / 2) ? -1 : 1;

    return { distance, sign };
}

export function modulo(x: number, n: number) {
    return ((x % n) + n) % n;
}

export function computeBinomialCoefficient(n: number, k: number) {
    if (k < 0 || k > n) {
        return 0;
    }

    let coefficient = 1;

    for (let i = 1; i <= k; i++) {
        coefficient *= (n - i + 1) / i;
    }

    return coefficient;
}