import { Alignment, Container, SingleParentXMLInputConfig, Widget, safeRoundRect, type Alignment2D, type Rect, type WidgetAutoXML, type WidgetProperties } from "lazy-widgets";

export interface BackPaneProperties extends WidgetProperties {
    alpha?: number,
}

export class HeaderPane<W extends Widget = Widget> extends Container<W> {
    static override autoXML: WidgetAutoXML = {
        name: 'header-pane',
        inputConfig: SingleParentXMLInputConfig
    };

    constructor(child: W, properties?: Readonly<BackPaneProperties>) {
        super(
            child,
            {
                containerAlignment: <Alignment2D>{
                    horizontal: Alignment.Stretch, vertical: Alignment.Center,
                },
                roundedCornersRadii: 3,
                ...properties,
            },
        );
    }

    protected override handlePainting(dirtyRects: Rect[]): void {
        const ctx = this.viewport.context;
        ctx.save();
        ctx.beginPath();
        safeRoundRect(ctx, this.x, this.y, this.width, this.height, this.roundedCornersRadii);
        ctx.clip();

        ctx.fillStyle = "rgba(120, 120, 120, 0.25)";
        ctx.fillRect(this.x, this.y, this.width, this.height);

        super.handlePainting(dirtyRects);

        ctx.beginPath();
        safeRoundRect(ctx, this.x, this.y, this.width, this.height, this.roundedCornersRadii);
        ctx.strokeStyle = "rgba(120, 120, 120, 0.35)";
        ctx.lineWidth = 1;
        ctx.stroke();

        ctx.restore();
    }
}