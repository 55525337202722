import { type PointerEvent } from "lazy-widgets";
import { WLRoot } from "lazy-widgets-wle";

export function getCursorFromLZCID(event: PointerEvent) {
    const source = event.source;
    if (source != null) {
        for (const [cursor, id] of WLRoot.pointerIDs) {
            if (id === source[1]) {
                return cursor;
            }
        }
    }

    return null;
}