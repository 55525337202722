import { Component, MeshComponent } from "@wonderlandengine/api";
import { Globals } from "wle-pp";
import { HoverboardComponent } from "../../game/hoverboard/components/hoverboard-component.js";

export class SpeedometerSpeedSliderComponent extends Component {
    static TypeName = "speedometer-speed-slider";

    start() {
        this.hoverboard = Globals.getRootObject(this.engine).pp_getComponent(HoverboardComponent);
        let children = this.object.children;

        this.blocks = {};
        this.amountBlocks = children.length;

        this.blockUnitPercentage = 1.0 / this.amountBlocks;
        this.currentAmountPercentage = 0.0;
        this.currentActiveAmount = 0;

        this.resetOnNextUpdate = false;

        this.resetSpeedometer();
    }

    resetSpeedometer() {
        let children = this.object.children;
        this.amountBlocks = children.length;
        for (let i = 0; i < this.amountBlocks; i++) {
            const child = children[i];
            let index = child.name.match(/\d+$/)[0];
            this.blocks[index] = child.getComponent(MeshComponent);
            this.blocks[index].active = false;
        }

        this.currentAmountPercentage = 0.0;
        this.currentActiveAmount = 0;
    }

    updateDisplayedBlocks(amountActive) {
        if (amountActive > this.currentActiveAmount) {
            for (let i = this.currentActiveAmount; i < amountActive; i++) {
                this.blocks[i].active = true;
            }
        } else if (amountActive < this.currentActiveAmount) {
            for (let i = this.currentActiveAmount - 1; i >= amountActive; i--) {
                this.blocks[i].active = false;
            }
        }

        this.currentActiveAmount = amountActive;
    }

    onActivate() {
        this.resetOnNextUpdate = true;
    }

    update(dt) {
        if (this.resetOnNextUpdate) {
            this.resetOnNextUpdate = false;
            this.resetSpeedometer();
        }

        let blocksToActivate = Math.pp_clamp(Math.ceil(this.hoverboard.speedPercentage * this.amountBlocks), 0, this.amountBlocks);

        if (Math.round(this.hoverboard.visualCurrentSpeedConverted) < 1) {
            blocksToActivate = 0;
        }

        this.updateDisplayedBlocks(blocksToActivate);
    }
}
