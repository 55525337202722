export class DownloadAbortError extends Error {
    constructor() {
        super("Download aborted");
    }
}

// modified version of wle api's fetchWithProgress
export function fetchCancellableProgress(path: string, onProgress?: (current: number, total: number) => void, timeout = 30000): [promise: Promise<ArrayBuffer>, cancel: () => void] {
    let cancelled = false;
    let abortHandler: (() => void) | null = null;
    const cancel = () => {
        cancelled = true;
        if (abortHandler) abortHandler();
    };

    const promise = new Promise<ArrayBuffer>((resolve, reject) => {
        if (cancelled) {
            reject(new DownloadAbortError());
            return;
        }

        try {
            const timeoutCallback = () => {
                abortHandler = null;
                reject(new Error("Download timed out"));
                xhr.abort();
            };

            let timeoutHandle: number | null = setTimeout(timeoutCallback, timeout);
            const clearTimeoutHandle = () => {
                if (timeoutHandle !== null) {
                    clearTimeout(timeoutHandle);
                    return true;
                } else {
                    return false;
                }
            };

            const xhr = new XMLHttpRequest();
            xhr.open("GET", path);
            xhr.responseType = "arraybuffer";
            xhr.onprogress = (progress) => {
                if (!abortHandler) return;
                if (clearTimeoutHandle()) {
                    timeoutHandle = setTimeout(timeoutCallback, timeout);
                }

                if (progress.lengthComputable) {
                    onProgress?.(progress.loaded, progress.total);
                }
            };
            xhr.onload = () => {
                clearTimeoutHandle();
                abortHandler = null;
                if (xhr.status >= 200 && xhr.status < 300) {
                    const buffer = xhr.response as ArrayBuffer;
                    onProgress?.(buffer.byteLength, buffer.byteLength);
                    resolve(buffer);
                } else {
                    reject(xhr.statusText);
                }
            };
            xhr.onerror = () => {
                clearTimeoutHandle();
                abortHandler = null;
                reject(xhr.statusText);
            };
            xhr.send();

            abortHandler = () => {
                clearTimeoutHandle();
                reject(new DownloadAbortError());
                xhr.abort();
            };
        } catch (err) {
            reject(err);
        }
    });

    return [promise, cancel];
}