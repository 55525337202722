export function replaceSearchParams(url: URL, searchParams: URLSearchParams) {
    if (Array.from(searchParams.keys()).length > 0) {
        window.history.replaceState(null, "", `${url.origin}${url.pathname}?${searchParams.toString()}${url.hash}`);
    } else {
        window.history.replaceState(null, "", `${url.origin}${url.pathname}${url.hash}`);
    }
}

const LOCAL_URL_SCHEMA = "local://";
export function getNameFromLocalURL(url: string) {
    return url.substring(LOCAL_URL_SCHEMA.length);
}

export function isLocalURL(url: string) {
    return url.startsWith(LOCAL_URL_SCHEMA);
}