import { AudioID } from "src/hoverfit/audio/audio-manager/audio-id.js";
import { StatusEffectTarget } from "../status-effect-target.js";
import { StatusEffectType } from "../status-effect.js";
import { ExpiringStatusEffect, ExpiringStatusEffectParams } from "./expiring-status-effect.js";

export class SpringStatusEffectParams extends ExpiringStatusEffectParams {
    verticalSpeedBoost: number = 35;

    sfxOnStart: AudioID | null = AudioID.STATUS_EFFECT_SPRING;
}

export class SpringStatusEffect extends ExpiringStatusEffect {

    declare protected _params: SpringStatusEffectParams;

    constructor(target: StatusEffectTarget, params: SpringStatusEffectParams) {
        super(StatusEffectType.Spring, target, params);
    }

    getParams(): SpringStatusEffectParams {
        return this._params;
    }

    protected _startHook(): void {
        this._target.applyVerticalSpeedBoost(this._params.verticalSpeedBoost);
    }
}