import { AudioID } from "src/hoverfit/audio/audio-manager/audio-id.js";
import { StatusEffectTarget } from "../status-effect-target.js";
import { StatusEffectType } from "../status-effect.js";
import { ExpiringStatusEffect, ExpiringStatusEffectParams } from "./expiring-status-effect.js";

export class SuperRocketStatusEffectParams extends ExpiringStatusEffectParams {
    speedBoost: number = 100;

    sfxOnStart: AudioID | null = AudioID.STATUS_EFFECT_SUPER_ROCKET;
}

export class SuperRocketStatusEffect extends ExpiringStatusEffect {

    declare protected _params: SuperRocketStatusEffectParams;

    constructor(target: StatusEffectTarget, params: SuperRocketStatusEffectParams) {
        super(StatusEffectType.SuperRocket, target, params);
    }

    getParams(): SuperRocketStatusEffectParams {
        return this._params;
    }

    protected _startHook(): void {
        this._target.applySpeedBoost(this._params.speedBoost);
    }
}