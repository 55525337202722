import { Object3D } from '@wonderlandengine/api';
import { Globals, Timer, Vec3Utils, vec3_create } from 'wle-pp';
import { AudioID } from '../../../audio/audio-manager/audio-id.js';
import common from '../../../common.js';
import { PickupGrabber } from './pickup-grabber.js';

export enum PickupType {
    Rocket,
    SuperRocket,
    Spring,
    SuperSpring,
    Glue,
    Timer,
    Shield,
    Invincibility
}

export class PickupParams {
    respawnDuration: number = 2;
    pickupDistance: number = 5;

    gamepadPulseIntensityOnPickup: number = 0;
    gamepadPulseDurationOnPickup: number = 0;

    sfxOnPickup: AudioID | null = null;
}

export class Pickup {

    protected _pickupType: PickupType;
    protected _pickupObject: Object3D;
    protected _params: PickupParams;

    private _id: string;

    protected _active: boolean = false;

    private _objectsToDeactivate: Object3D[] = [];

    private _grabbersToIgnoreWhileColliding: PickupGrabber[] = [];

    private _respawnTimer: Timer;

    constructor(pickupType: PickupType, pickupObject: Object3D, params: PickupParams) {
        this._pickupType = pickupType;
        this._pickupObject = pickupObject;
        this._params = params;

        this._id = pickupObject.name;

        this._respawnTimer = new Timer(this._params.respawnDuration);
        this._objectsToDeactivate = this._pickupObject.pp_getObjectsByNameDescendants("^(?!Base)", true)!;

        this.setActive(false);
    }

    update(dt: number, pickupGrabbers: PickupGrabber[]) {
        if (!this._active) return;

        if (this._respawnTimer.isRunning()) {
            this._respawnTimer.update(dt);
            if (this._respawnTimer.isDone()) {
                this._onRespawn();
            }
        }

        this._pickupUpdate(dt, pickupGrabbers);
    }

    reset() {
        this._onRespawn();
    }

    setActive(active: boolean) {
        this._active = active;

        this._pickupObject.pp_setActive(active);

        const pickupScale = 2;
        this._pickupObject.pp_setScaleLocal(vec3_create(pickupScale));

        this._respawnTimer.reset();

        this._grabbersToIgnoreWhileColliding.pp_clear();
    }

    isRespawning() {
        return this._respawnTimer.isRunning();
    }

    isEnabled() {
        return this._active && !this.isRespawning();
    }

    getID() {
        return this._id;
    }

    canBePickedUp(pickupGrabber: PickupGrabber) {
        return this.isEnabled() && !this._grabbersToIgnoreWhileColliding.pp_hasEqual(pickupGrabber) && this._canBePickedUpHook(pickupGrabber);
    }

    protected _onPickupHook(pickupGrabber: PickupGrabber) {

    }

    protected _canBePickedUpHook(pickupGrabber: PickupGrabber) {
        return true;
    }

    protected _onPickup(pickupGrabber: PickupGrabber) {
        if (pickupGrabber.arePickupFeedbacksEnabled()) {
            if (this._params.sfxOnPickup != null) {
                const sfx = common.audioManager.getAudio(this._params.sfxOnPickup);
                sfx!.play();
            }

            if (this._params.gamepadPulseIntensityOnPickup > 0 && this._params.gamepadPulseDurationOnPickup > 0) {
                Globals.getLeftGamepad()!.pulse(this._params.gamepadPulseIntensityOnPickup, this._params.gamepadPulseDurationOnPickup);
                Globals.getRightGamepad()!.pulse(this._params.gamepadPulseIntensityOnPickup, this._params.gamepadPulseDurationOnPickup);
            }
        }

        this._onPickupHook(pickupGrabber);

        this._disablePickup();
    }

    protected _disablePickup() {
        for (const objectToDeactivate of this._objectsToDeactivate) {
            objectToDeactivate.pp_setActive(false);
        }

        this._respawnTimer.start();
    }

    protected _onRespawn() {
        this._respawnTimer.reset();

        for (const objectToDeactivate of this._objectsToDeactivate) {
            objectToDeactivate.pp_setActive(true);
        }
    }

    private static _pickupUpdateSV =
        {
            pickupPosition: vec3_create(),
            grabberPosition: vec3_create()
        };
    private _pickupUpdate(dt: number, pickupGrabbers: PickupGrabber[]) {
        const pickupPosition = Pickup._pickupUpdateSV.pickupPosition;
        const grabberPosition = Pickup._pickupUpdateSV.grabberPosition;

        this._pickupObject.getPositionWorld(pickupPosition);

        for (const pickupGrabber of pickupGrabbers) {
            pickupGrabber.getPosition(grabberPosition);
            const distance = Vec3Utils.distanceSquared(pickupPosition, grabberPosition);

            if (distance < this._params.pickupDistance * this._params.pickupDistance) {
                if (!this.isRespawning()) {
                    if (this.canBePickedUp(pickupGrabber)) {
                        this._onPickup(pickupGrabber);
                    }

                    this._grabbersToIgnoreWhileColliding.pp_pushUnique(pickupGrabber);
                }
            } else if (this._grabbersToIgnoreWhileColliding.length > 0 && this._grabbersToIgnoreWhileColliding.pp_hasEqual(pickupGrabber)) {
                const pickupExitDistance = this._params.pickupDistance * 1.25;
                if (distance > pickupExitDistance * pickupExitDistance) {
                    this._grabbersToIgnoreWhileColliding.pp_removeEqual(pickupGrabber);
                }
            }
        }
    }
}