import { Column, Row, Spacing, type ValidatedVariable, type WidgetProperties } from "lazy-widgets";
import { DecoratedButton } from "./decorated-button.js";

interface NumpadProperties extends WidgetProperties {
    buttonWidth?: number
}

export class Numpad extends Column {
    static override autoXML = {
        name: "numpad",
        inputConfig: [
            {
                mode: "value",
                validator: "variable",
                name: "variable"
            }
        ],
    };

    private buttonWidth: number;

    constructor(readonly variable: ValidatedVariable<string, number | null>, properties?: NumpadProperties) {
        super([], properties);

        this.buttonWidth = properties!.buttonWidth ?? 50;

        this.add([
            this.nRow(1, 2, 3),
            this.nRow(4, 5, 6),
            this.nRow(7, 8, 9),
            new Row([
                this.button("CLEAR").on("click", () => { this.variable.value = ""; }),
                this.nButton(0),
                new Spacing(this.fixedWidthProperties()),
            ]),
        ]);
    }

    private fixedWidthProperties() {
        return { minWidth: this.buttonWidth, maxWidth: this.buttonWidth };
    }

    private button(text: string) {
        return new DecoratedButton(text, this.fixedWidthProperties());
    }

    private nRow(n1: number, n2: number, n3: number) {
        return new Row([this.nButton(n1), this.nButton(n2), this.nButton(n3)]);
    }

    private nButton(n: number) {
        return this.button(n.toString()).on("click", () => {
            if (this.variable.setValue(`${this.variable.value}${n}`) && this.variable.validValue !== null) {
                this.variable.setValue(this.variable.validValue.toString(), false);
            }
        });
    }
}