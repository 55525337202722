import common from "../../common.js";
import { GameModeVariable } from "./game-mode-variable.js";

export class NPCsDifficultyVariable extends GameModeVariable<number> {
    constructor() {
        super(0, "change_difficulty");
    }

    protected override sendNetVar(): void {
        common.hoverboardNetworking.updateNPCsDifficulty(this.value);
    }
}