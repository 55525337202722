import { currentPlayerData } from "src/hoverfit/data/player-data.js";
import { getAssetFromManifest, unpackAssetShortID } from "src/hoverfit/utils/asset-utils.js";
import { type AssetBase, type AssetManifest } from "./asset-manifest-types.js";
import { AssetProvider, GenericPurchaseError, ItemCategory, ItemCurrency, ItemNamespace, NoFundsPurchaseError } from "./asset-provider.js";
import { type IAPContentController } from "./iap-content-controller.js";
import { ShopItem } from "./shop-item.js";

const FITABUX_ASSET_MANIFEST_URL = "assets/json/shop/fitabux-asset-manifest.json";

export class FitabuxAssetProvider extends AssetProvider {
    static instance: FitabuxAssetProvider;
    private readonly manifest: Promise<AssetManifest>;

    private onInventoryChanged: (() => void) | undefined;

    constructor(controller: IAPContentController) {
        super(controller);
        FitabuxAssetProvider.instance = this;
        this.manifest = controller.fetchAndLoadManifest(FITABUX_ASSET_MANIFEST_URL, ItemNamespace.Fitabux);

        this.manifest.then((manifest) => {
            this.fetchInventory();
            this.onInventoryChanged = this.fetchInventory.bind(this);
            currentPlayerData.listen(this.onInventoryChanged, ["fitabuxInventory", "totalFitPoints"]);

            const added: Array<[id: string, item: FitabuxShopItem]> = [];
            this.makeShopItemsFromAssets(added, manifest.hoverboard, ItemCategory.Hoverboard);
            this.makeShopItemsFromAssets(added, manifest.suit, ItemCategory.Suit);
            this.makeShopItemsFromAssets(added, manifest.headwear, ItemCategory.Headwear);
            this.makeShopItemsFromAssets(added, manifest.hairColor, ItemCategory.HairColor);
            this.makeShopItemsFromAssets(added, manifest.bundle, ItemCategory.Bundle);
            this.catalog.removeNamespace(ItemNamespace.Fitabux);
            this.catalog.addMany(added);
        });
    }

    clientSideAssetBaseMap: Map<string, AssetBase> = new Map<string, AssetBase>();

    private makeShopItemsFromAssets(added: Array<[id: string, item: FitabuxShopItem]>, manifestList: { [shortID: string]: AssetBase }, catalogClass: ItemCategory) {
        for (const shortID of Object.getOwnPropertyNames(manifestList)) {
            const assetBase = manifestList[shortID];
            const price = assetBase.price ?? 0; // fitabux have no discounts
            const item = new FitabuxShopItem(this, assetBase, shortID, catalogClass, price, price);
            added.push([item.id, item]);
        }
    }

    async fetchInventory(): Promise<void> {
        const added: string[] = [];
        const manifest = await this.manifest;
        const shortInv = currentPlayerData.fitabuxInventory;
        for (let i = 0; i < shortInv.length; i++) {
            unpackAssetShortID(added, manifest, shortInv[i]);
        }

        this.inventory.replaceNamespace(added, ItemNamespace.Fitabux);
    }

    async purchaseItem(shortID: string): Promise<boolean> {
        const asset = getAssetFromManifest(await this.manifest, shortID);

        if (!asset) {
            throw new GenericPurchaseError(`No such item with short ID "${shortID}"`);
        }

        const price = asset.price!;
        if (currentPlayerData.fitabux < price) {
            throw new NoFundsPurchaseError(ItemCurrency.Fitabux);
        }

        const added: string[] = [];
        unpackAssetShortID(added, await this.manifest, shortID);

        const newInventory = [...currentPlayerData.fitabuxInventory];
        for (const newShortID of added) {
            if (newInventory.indexOf(newShortID) < 0) {
                newInventory.push(newShortID);
            }
        }

        currentPlayerData.fitabux -= price;
        currentPlayerData.fitabuxInventory = newInventory;
        currentPlayerData.savePlayerData();

        this.inventory.addManyShort(added, ItemNamespace.Fitabux);
        return true;
    }

    override dispose() {
        super.dispose();

        if (this.onInventoryChanged) {
            currentPlayerData.unlisten(this.onInventoryChanged);
        }
    }
}

export class FitabuxShopItem extends ShopItem {
    constructor(shopProvider: AssetProvider, asset: AssetBase, readonly shortID: string, readonly itemCategory: ItemCategory, readonly price: number, readonly priceDiscounted: number) {
        super(shopProvider, asset);
    }

    get namespace(): ItemNamespace.Fitabux {
        return ItemNamespace.Fitabux;
    }

    get currencyType(): ItemCurrency.Fitabux {
        return ItemCurrency.Fitabux;
    }

    purchase() {
        return this.shopProvider.purchaseItem(this.shortID);
    }
}
