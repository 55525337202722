import { Component, Object3D } from "@wonderlandengine/api";
import { property } from "@wonderlandengine/api/decorators.js";
import { Timer, Vector3, vec3_create } from "wle-pp";

export class FollowPositionComponent extends Component {
    static override TypeName = "follow-position";

    @property.object()
    targetObject!: Object3D;

    @property.float(0.15)
    minDistanceToStartFollowing!: number;

    @property.float(3)
    springStrength!: number;
    @property.float(0.1)
    springFriction!: number;

    @property.bool(true)
    preventBounceBack!: boolean;

    private _following: boolean = false;
    private _currentVelocity: Vector3 = vec3_create();

    private _almostReached: boolean = false;

    private _currentTargetPosition: Vector3 = vec3_create();

    private _timeBeforeStart: Timer = new Timer(2);
    private _timeBeforeStopFollowing: Timer = new Timer(0.25);

    private static readonly _almostReachedDistance: number = 0.1;

    sync(): void {
        this._following = false;
        this._currentVelocity.vec3_zero();

        this.object.pp_setPosition(this.targetObject.pp_getPosition());
    }

    private static readonly _updateSV =
        {
            currentPosition: vec3_create(),
            targetPosition: vec3_create(),
            movementToTarget: vec3_create(),
            movementToPerform: vec3_create()
        };
    override update(dt: number): void {
        const currentPosition = FollowPositionComponent._updateSV.currentPosition;
        this.object.pp_getPosition(currentPosition);

        const targetPosition = FollowPositionComponent._updateSV.targetPosition;
        this.targetObject.pp_getPosition(targetPosition);

        if (this._timeBeforeStart.isRunning()) {
            this._timeBeforeStart.update(dt);

            this.object.pp_setPosition(targetPosition);
            return;
        }

        if (!this._following || !this._almostReached) {
            this._currentTargetPosition.vec3_copy(targetPosition);
        } else {
            const distanceFromCurrentTargetToTarget = targetPosition.vec3_distance(this._currentTargetPosition);
            if (distanceFromCurrentTargetToTarget > FollowPositionComponent._almostReachedDistance) {
                this._almostReached = false;
                this._currentTargetPosition.vec3_copy(targetPosition);
            }
        }

        const movementToTarget = FollowPositionComponent._updateSV.movementToTarget;
        this._currentTargetPosition.vec3_sub(currentPosition, movementToTarget);
        const distanceToTarget = movementToTarget.vec3_length();

        if (!this._following && distanceToTarget > this.minDistanceToStartFollowing) {
            this._following = true;
            this._almostReached = false;
            this._currentVelocity.vec3_zero();
            this._timeBeforeStopFollowing.start();
        }

        if (this._following) {
            if (!this._almostReached && distanceToTarget <= FollowPositionComponent._almostReachedDistance) {
                this._almostReached = true;
            }

            if (distanceToTarget < 0.001) {
                this._timeBeforeStopFollowing.update(dt);

                if (this._timeBeforeStopFollowing.isDone()) {
                    this._following = false;
                }

                this._currentVelocity.vec3_zero();
                this.object.pp_setPosition(this._currentTargetPosition);
            } else {
                this._timeBeforeStopFollowing.start();

                this._currentVelocity.vec3_add(movementToTarget.vec3_scale(this.springStrength, this._currentVelocity));
                this._currentVelocity.vec3_scale(1 - this.springFriction, this._currentVelocity);

                const movementToPerform = FollowPositionComponent._updateSV.movementToPerform;
                this._currentVelocity.vec3_scale(dt, movementToPerform);

                if (this.preventBounceBack) {
                    const angleToPositon = this._currentVelocity.vec3_angle(movementToTarget);
                    if (angleToPositon < 5 && movementToTarget.vec3_lengthSquared() < this._currentVelocity.vec3_lengthSquared()) {
                        this._currentVelocity.vec3_copy(movementToTarget);
                    }
                }

                this.object.pp_translate(movementToPerform);
            }
        }
    }
}