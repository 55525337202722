import { Background, Container, Label, RoundedCorners, ScrollableViewportWidget, TextAlignMode, type WidgetAutoXML, type WidgetProperties } from "lazy-widgets";

export class KioskShopPopup extends ScrollableViewportWidget {
    static override autoXML: WidgetAutoXML = {
        name: "kiosk-shop-popup",
        inputConfig: []
    };

    constructor(properties?: Readonly<WidgetProperties>) {
        super(new RoundedCorners(
            new Background(
                new Container(
                    new Label("Test",
                        {
                            bodyTextFill: "black",
                            bodyTextFont: "0.9em sui-generis",
                            bodyTextAlign: TextAlignMode.Center,
                            bodyTextSpacing: 0,
                        })
                ),
                {
                    canvasFill: "rgb(148, 197, 255)",
                },
            ),
            {
                roundedCornersRadii: 3,
                minWidth: 250, maxWidth: 250,
                minHeight: 150, maxHeight: 150,
                ...properties,
            },
        ));
    }
}