<?xml version="1.0" encoding="UTF-8"?>
<ui-tree name="default" xmlns="lazy-widgets" xmlns:option="lazy-widgets:options" xmlns:on="lazy-widgets:on">
    <layered-container option:min-width="@570" option:max-width="@570" option:min-height="@306" option:max-height="@306">
        <layer>
            <event-filter option:enable-filter="@false" option:id="main-event-filter">
                <kiosk-background>
                    <column option:multi-container-alignment='@{"main":0,"cross":"stretch"}'>
                        <container option:container-padding='@{"left":20,"right":20,"top":8,"bottom":8}' option:flex="@1">
                            <book option:id="kiosk-book">
                                <column option:flex="@1" option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}'>
                                    <row>
                                        <award-row award-type="medal" bronze-var="$bronzeMedals" silver-var="$silverMedals" gold-var="$goldMedals" platinum-var="$platinumMedals"/>
                                        <spacing option:min-width="@12"/>
                                        <column option:multi-container-alignment='@{"main":0,"cross":0.5}' option:multi-container-spacing="@2" option:min-width="@150" option:max-width="@150">
                                            <back-pane>
                                                <row>
                                                    <spacing option:min-width="@0" option:min-height="@0" option:flex="@1"/>
                                                    <live-label text-source="$lifetimeFitPoints" option:body-text-font="1.7em sui-generis" option:body-text-align="@1"/>
                                                    <icon image="assets/textures/ui/icons/kiosk/fitpoints.svg" option:width="@20" option:height="@20"/>
                                                    <spacing option:min-width="@0" option:min-height="@0" option:flex="@1"/>
                                                </row>
                                            </back-pane>
                                            <back-pane>
                                                <row>
                                                    <spacing option:min-width="@0" option:min-height="@0" option:flex="@1"/>
                                                    <live-label text-source="$dailyFitPoints" option:body-text-font="1.7em sui-generis" option:body-text-align="@1"/>
                                                    <icon image="assets/textures/ui/icons/kiosk/rosette.svg" option:width="@20" option:height="@20"/>
                                                    <spacing option:min-width="@0" option:min-height="@0" option:flex="@1"/>
                                                </row>
                                            </back-pane>
                                        </column>
                                        <container option:flex="@1" option:container-padding='@{"left":0,"right":0,"top":0,"bottom":8}' option:container-alignment='@{"horizontal":0,"vertical":1}'>
                                            <icon-button option:id="rewards-legend-button" image="assets/textures/ui/icons/kiosk/info.svg" option:max-width="@12" option:max-height="@12" option:container-padding='@{"left":0,"right":0,"top":0,"bottom":0}'/>
                                        </container>
                                        <award-row award-type="trophy" bronze-var="$bronzeTrophies" silver-var="$silverTrophies" gold-var="$goldTrophies" platinum-var="$platinumTrophies"/>
                                    </row>
                                    <spacing/>
                                    <row>
                                        <lifetime-level-display option:id="helmetLevelDisplay" type="helmet" level="$lifetimeHelmetLevel"/>
                                        <lifetime-level-display option:id="suitLevelDisplay" type="suit" level="$lifetimeSuitLevel"/>
                                        <lifetime-level-display option:id="boardLevelDisplay" type="board" level="$lifetimeBoardLevel"/>
                                    </row>
                                    <spacing/>
                                </column>
                                <column option:flex="@1">
                                    <book option:id="multiplayer-book" option:flex="@1">
                                        <column option:flex="@1" option:multi-container-alignment='@{"main":"between","cross":"stretch"}' option:multi-container-spacing="@32">
                                            <spacing/>
                                            <label option:body-text-align="@0.5">CHANGING GAME CONFIGURATION...</label>
                                            <decorated-button on:click="$cancelConfigurationChange">CANCEL</decorated-button>
                                            <spacing/>
                                        </column>
                                        <column option:flex="@1" option:multi-container-alignment='@{"main":"between","cross":"stretch"}' option:multi-container-spacing="@32">
                                            <spacing option:flex="@0" option:min-height="@16"/>
                                            <label option:body-text-align="@0.5" option:body-text-font="1.5em sui-generis">START MULTIPLAYER</label>
                                            <spacing/>
                                            <row option:multi-container-alignment='@{"main":"around","cross":"stretch"}' option:multi-container-spacing="@16">
                                                <container option:container-padding='@{"left":0,"right":0,"top":0,"bottom":30}'>
                                                    <decorated-button on:click="$quickPlay" option:font-size="@1.5" option:min-width="@160" option:max-width="@160" option:min-height="@48" option:max-height="@48">QUICK PLAY</decorated-button>
                                                </container>
                                                <container option:container-padding='@{"left":0,"right":0,"top":30,"bottom":0}'>
                                                    <decorated-button option:id="hostRoomButton" option:font-size="@1.5" option:min-width="@160" option:max-width="@160" option:min-height="@48" option:max-height="@48">HOST ROOM</decorated-button>
                                                </container>
                                                <container option:container-padding='@{"left":0,"right":0,"top":0,"bottom":30}'>
                                                    <decorated-button option:id="joinRoomButton" option:font-size="@1.5" option:min-width="@160" option:max-width="@160" option:min-height="@48" option:max-height="@48">JOIN ROOM</decorated-button>
                                                </container>
                                            </row>
                                            <spacing/>
                                        </column>
                                        <column option:flex="@1" option:multi-container-alignment='@{"main":"between","cross":"stretch"}' option:multi-container-spacing="@32">
                                            <spacing/>
                                            <label option:body-text-align="@0.5">CONNECTING...</label>
                                            <spacing/>
                                        </column>
                                        <column option:flex="@1" option:multi-container-alignment='@{"main":"between","cross":"stretch"}' option:multi-container-spacing="@32">
                                            <header-pane>
                                                <live-label option:body-text-align="@0.5" option:body-text-font="1.5em sui-generis" text-source="$currentRoomLong"/>
                                            </header-pane>
                                            <row option:multi-container-alignment='@{"main":"between","cross":"stretch"}' option:multi-container-spacing="@32" option:flex="@1">
                                                <column option:multi-container-spacing="@32">
                                                    <spacing/>
                                                    <decorated-button option:id="toTrackButtonMP" on:click="$goToTrack" option:font-size="@1.5" option:min-width="@160" option:max-width="@160" option:min-height="@48" option:max-height="@48"></decorated-button>
                                                    <decorated-button on:click="$disconnect" option:font-size="@1.5" option:min-width="@160" option:max-width="@160" option:min-height="@48" option:max-height="@48">DISCONNECT</decorated-button>
                                                    <spacing/>
                                                </column>
                                                <player-list option:flex="@1"/>
                                            </row>
                                        </column>
                                        <container option:flex="@1" option:container-padding='@{"left":100,"right":100,"top":0,"bottom":0}'>
                                            <column>
                                                <spacing/>
                                                <label option:body-text-align="@0.5">HOST ROOM - ENTER PREFERRED ROOM ID:</label>
                                                <text-input variable="$roomIDVar" option:input-filter="$numpadInputFilter"></text-input>
                                                <numpad variable="$roomIDVar" option:button-width="@80"/>
                                                <spacing option:flex="@0" option:min-height="@4"/>
                                                <row>
                                                    <label>PRIVATE:</label>
                                                    <clicky-checkbox variable="$privateRoomVar"/>
                                                    <spacing option:flex="@1" option:min-width="@0" option:min-height="@0"/>
                                                    <decorated-button option:id="cancelHostButton" option:min-width="@60" option:max-width="@60">CANCEL</decorated-button>
                                                    <decorated-button on:click="$hostRoom" option:min-width="@60" option:max-width="@60">HOST</decorated-button>
                                                </row>
                                                <spacing/>
                                            </column>
                                        </container>
                                        <container option:flex="@1" option:container-padding='@{"left":100,"right":100,"top":0,"bottom":0}'>
                                            <column>
                                                <spacing/>
                                                <label option:body-text-align="@0.5">JOIN ROOM - ENTER ROOM ID:</label>
                                                <text-input variable="$roomIDVar" option:input-filter="$numpadInputFilter"></text-input>
                                                <numpad variable="$roomIDVar" option:button-width="@80"/>
                                                <spacing option:flex="@0" option:min-height="@4"/>
                                                <row option:multi-container-alignment='@{"main":1,"cross":"stretch"}'>
                                                    <decorated-button option:id="cancelJoinButton" option:min-width="@60" option:max-width="@60">CANCEL</decorated-button>
                                                    <decorated-button option:id="doRoomJoinButton" option:min-width="@60" option:max-width="@60" on:click="$joinRoom">JOIN</decorated-button>
                                                </row>
                                                <spacing/>
                                            </column>
                                        </container>
                                    </book>
                                </column>
                                <column option:flex="@1">
                                    <column option:flex="@1" option:multi-container-alignment='@{"main":"between","cross":"stretch"}'>
                                        <center>
                                            <carousel option:slide-speed-percent="@0" option:min-width="@430" option:max-width="@430">
                                                <row option:id="location-row">
                                                    <spacing option:flex="@0"/>
                                                    <!-- XXX filled in at runtime -->
                                                </row>
                                            </carousel>
                                        </center>
                                        <center>
                                            <carousel option:slide-speed-percent="@0">
                                                <row>
                                                    <spacing option:flex="@0"/>
                                                    <option-button option:id="race-mode-button" value="race" variable="$mode" option:min-width="@80" option:max-width="@80" option:min-height="$buttonHeight" option:max-height="$buttonHeight">RACE</option-button>
                                                    <option-button option:id="tag-mode-button" value="tag" variable="$mode" option:min-width="@80" option:max-width="@80" option:min-height="$buttonHeight" option:max-height="$buttonHeight">TAG</option-button>
                                                    <option-button option:id="roam-mode-button" value="roam" variable="$mode" option:min-width="@80" option:max-width="@80" option:min-height="$buttonHeight" option:max-height="$buttonHeight">ROAM</option-button>
                                                </row>
                                            </carousel>
                                        </center>
                                        <row option:multi-container-alignment='@{"main":"between","cross":"stretch"}' option:flex="@1">
                                            <!-- XXX this is just for counter-balancing the space taken by the "tutorial" button -->
                                            <spacing option:min-width="@80" option:max-width="@80" option:min-height="$buttonHeight" option:max-height="$buttonHeight"/>
                                            <column option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}'>
                                                <decorated-button option:id="toTrackButton" on:click="$goToTrack" option:font-size="@1.5" option:min-width="@160" option:max-width="@160" option:min-height="@48" option:max-height="@48"></decorated-button>
                                            </column>
                                            <container option:container-alignment='@{"horizontal":"stretch","vertical":1}' option:container-padding='@{"left":0,"right":0,"top":0,"bottom":0}'>
                                                <decorated-button option:min-width="@80" option:max-width="@80" option:min-height="$buttonHeight" option:max-height="$buttonHeight" on:click="$toggleTutorial">TUTORIAL</decorated-button>
                                            </container>
                                        </row>
                                        <back-pane option:flex="@1">
                                            <container option:container-padding='@{"left":10,"right":10,"top":0,"bottom":0}'>
                                                <row option:multi-container-alignment='@{"main":"between","cross":"stretch"}'>
                                                    <column option:multi-container-alignment='@{"main":"around","cross":"stretch"}' option:multi-container-spacing="@0" option:min-width="@150" option:max-width="@150">
                                                        <stepper-input option:id="trackInput" option:value-width="@100" option:value-height="@36" option:labels="$trackLabels" variable="$track" min-value="@0" max-value="$trackMax">TRACK</stepper-input>
                                                    </column>
                                                    <column option:multi-container-alignment='@{"main":"around","cross":"stretch"}' option:multi-container-spacing="@0" option:min-width="@150" option:max-width="@150">
                                                        <stepper-input option:id="npcsAmountInput" option:value-width="@72" variable="$npcsAmount" min-value="@0" max-value="@5">CPU RACERS</stepper-input>
                                                        <stepper-input option:id="npcsDifficultyInput" option:value-width="@72" option:labels='@["Very Easy","Easy","Moderate","Hard","Very Hard"]' variable="$npcsDifficulty" min-value="@0" max-value="@4">CPU DIFFICULTY</stepper-input>
                                                    </column>
                                                    <column option:multi-container-alignment='@{"main":"around","cross":"stretch"}' option:multi-container-spacing="@0" option:min-width="@150" option:max-width="@150">
                                                        <stepper-input option:id="lapsAmountInput" option:value-width="@100" option:value-height="@36" variable="$lapsAmount" min-value="@1" max-value="@50">LAPS</stepper-input>
                                                        <time-stepper-input option:id="tagDurationInput" option:value-width="@100" option:value-height="@36" variable="$tagDuration" min-value="@30" max-value="@1200">TAG DURATION</time-stepper-input>
                                                    </column>
                                                </row>
                                            </container>
                                        </back-pane>
                                    </column>
                                </column>
                                <column option:flex="@1" option:multi-container-alignment='@{"main":"between","cross":"stretch"}'>
                                    <row>
                                        <row option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}'>
                                            <customise-tab-button variable="$customPage" value="@0">SKIN TONE</customise-tab-button>
                                            <customise-tab-button variable="$customPage" value="@1">BOARD</customise-tab-button>
                                            <customise-tab-button variable="$customPage" value="@2">SUIT</customise-tab-button>
                                            <customise-tab-button variable="$customPage" value="@3">HAIR</customise-tab-button>
                                            <customise-tab-button variable="$customPage" value="@4">HELMET</customise-tab-button>
                                        </row>
                                        <center><separator option:body-text-fill="rgba(120, 120, 120, 0.25)"/></center>
                                        <row option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}'>
                                            <customise-gender-button variable="$gender" gender="@0" option:min-width="@24" option:max-width="@24" option:min-height="@24" option:max-height="@24"/>
                                            <customise-gender-button variable="$gender" gender="@1" option:min-width="@24" option:max-width="@24" option:min-height="@24" option:max-height="@24"/>
                                        </row>
                                    </row>
                                    <!-- XXX just to provide extra blank space between top tabs and items. space is occupied by multi-container-spacing; this spacing widget is 0-height -->
                                    <spacing option:flex="@0"/>
                                    <container option:container-padding='@{"left":18,"right":18,"top":0,"bottom":0}'>
                                        <book option:id="custom-book" option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}'>
                                            <kiosk-item-grid item-generator="$skinColorCustomItemGenerator" collection="$inventory" filter="$skinItemFilter" option:min-height="@200" option:max-height="@200"/>
                                            <kiosk-item-grid item-generator="$hoverboardCustomItemGenerator" collection="$inventory" filter="$hoverboardItemFilter" option:min-height="@200" option:max-height="@200"/>
                                            <kiosk-item-grid item-generator="$suitCustomItemGenerator" collection="$inventory" filter="$suitItemFilter" option:min-height="@200" option:max-height="@200"/>
                                            <book option:id="hair-book">
                                                <kiosk-item-grid item-generator="$headwearCustomItemGenerator" collection="$inventory" filter="$hairItemFilter" option:min-height="@200" option:max-height="@200"/>
                                                <kiosk-item-grid item-generator="$hairColorCustomItemGenerator" collection="$inventory" filter="$hairColorItemFilter" option:min-height="@200" option:max-height="@200"/>
                                            </book>
                                            <kiosk-item-grid item-generator="$headwearCustomItemGenerator" collection="$inventory" filter="$helmetItemFilter" option:min-height="@200" option:max-height="@200"/>
                                        </book>
                                    </container>
                                    <row option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}'>
                                        <decorated-button on:click="$equip" option:click-audio-id-override="BUTTON_EQUIP" option:min-width="@80" option:max-width="@80" option:min-height="@25" option:max-height="@25">EQUIP</decorated-button>
                                        <book option:id="pick-mode-book" option:enabled="@false" option:min-width="@110" option:max-width="@110" option:min-height="@25" option:max-height="@25">
                                            <decorated-button option:id="hairToggle">PICK COLOR</decorated-button>
                                            <decorated-button option:id="styleToggle">PICK STYLE</decorated-button>
                                        </book>
                                    </row>
                                    <!-- XXX just to provide extra blank space at the bottom. space is occupied by multi-container-spacing; this spacing widget is 0-height -->
                                    <spacing option:flex="@0"/>
                                </column>
                                <column option:flex="@1" option:multi-container-alignment='@{"main":"between","cross":0.5}'>
                                    <column>
                                        <row option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}'>
                                            <customise-tab-button variable="$shopPage" value="@0">BOARDS</customise-tab-button>
                                            <customise-tab-button variable="$shopPage" value="@1">SUITS</customise-tab-button>
                                            <customise-tab-button variable="$shopPage" value="@2">HAIR</customise-tab-button>
                                            <customise-tab-button variable="$shopPage" value="@3">HAIR COLORS</customise-tab-button>
                                            <customise-tab-button variable="$shopPage" value="@4">HELMET</customise-tab-button>
                                            <customise-tab-button variable="$shopPage" value="@5">LOCATIONS</customise-tab-button>
                                            <customise-tab-button variable="$shopPage" value="@6">BUNDLES</customise-tab-button>
                                        </row>
                                    </column>
                                    <spacing option:flex="@0"/>
                                    <container option:container-padding='@{"left":15,"right":15,"top":0,"bottom":0}' option:container-alignment='@{"horizontal":"stretch","vertical":"stretch"}' option:max-width="@570" option:max-height="@235">
                                        <book option:id="shop-or-item-book">
                                            <carousel option:slide-speed-percent="@0" option:vertical="@true" option:loops="@false">
                                                <book option:id="shop-book">
                                                    <!-- TODO make this layout better; maybe upgrade lazy-widgets and use flex-shrink? -->
                                                    <kiosk-item-grid item-generator="$makeShopItemButton" collection="$catalog" filter="$hoverboardItemFilter" option:min-height="@235" option:max-height="@235"></kiosk-item-grid>
                                                    <kiosk-item-grid item-generator="$makeShopItemButton" collection="$catalog" filter="$suitItemFilter" option:min-height="@235" option:max-height="@235"></kiosk-item-grid>
                                                    <kiosk-item-grid item-generator="$makeShopItemButton" collection="$catalog" filter="$hairItemFilter" option:min-height="@235" option:max-height="@235"></kiosk-item-grid>
                                                    <kiosk-item-grid item-generator="$makeShopItemButton" collection="$catalog" filter="$hairColorItemFilter" option:min-height="@235" option:max-height="@235"></kiosk-item-grid>
                                                    <kiosk-item-grid item-generator="$makeShopItemButton" collection="$catalog" filter="$helmetItemFilter" option:min-height="@235" option:max-height="@235"></kiosk-item-grid>
                                                    <!-- <kiosk-item-grid item-generator="$makeShopItemButton" collection="$catalog" filter="$locationItemFilter" option:min-height="@235" option:max-height="@235"></kiosk-item-grid> -->
                                                    <label option:body-text-align="@0.5">Coming soon...</label>
                                                    <kiosk-item-grid item-generator="$makeShopItemButton" collection="$catalog" filter="$bundleItemFilter" option:min-height="@235" option:max-height="@235"></kiosk-item-grid>
                                                </book>
                                            </carousel>
                                            <theme-scope theme="$lightShopTheme">
                                                <decorated-button-like-pane>
                                                    <container>
                                                        <row>
                                                            <column>
                                                                <arrow-decorated-button option:id="itemBackButton" direction="left" option:unhover-background-fill="rgba(120, 120, 120, 0.35)" option:hover-background-fill="rgb(231,231,241)" option:hold-background-fill="rgb(231,231,241)"/>
                                                            </column>
                                                            <decorated-button-like-pane option:min-width="@270" option:max-width="@270">
                                                                <label option:id="itemDescLabel" option:body-text-align="@0.5" option:body-text-fill="black"></label>
                                                            </decorated-button-like-pane>
                                                            <container option:container-padding='@{"left": 0, "right": 0, "top": 16, "bottom": 0}' option:min-width="@160" option:max-width="@160">
                                                                <column>
                                                                    <row option:id="shop-gender-row">
                                                                        <spacing/>
                                                                        <customise-gender-button variable="$gender" gender="@0" option:dark-mode="@true" option:min-width="@24" option:max-width="@24" option:min-height="@24" option:max-height="@24"/>
                                                                        <customise-gender-button variable="$gender" gender="@1" option:dark-mode="@true" option:min-width="@24" option:max-width="@24" option:min-height="@24" option:max-height="@24"/>
                                                                        <spacing/>
                                                                    </row>
                                                                    <spacing option:id="shop-gender-placebo" option:enabled="@false" option:flex="@0" option:min-height="@16"/>
                                                                    <label option:body-text-align="@0.5" option:id="itemNameLabel" option:body-text-font="1.5em sui-generis" option:body-text-fill="black"></label>
                                                                    <purchase-button option:id="purchaseItemButton" price="@0" currency="@0" option:unhover-background-fill="rgba(120, 120, 120, 0.35)"/>
                                                                </column>
                                                            </container>
                                                        </row>
                                                    </container>
                                                </decorated-button-like-pane>
                                            </theme-scope>
                                        </book>
                                    </container>
                                </column>
                            </book>
                        </container>
                        <row option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}'>
                            <kiosk-tab-button variable="$kioskPage" value="@0">REWARDS</kiosk-tab-button>
                            <kiosk-tab-button variable="$kioskPage" value="@1">MULTIPLAYER</kiosk-tab-button>
                            <kiosk-tab-button variable="$kioskPage" value="@2">PLAY</kiosk-tab-button>
                            <kiosk-tab-button variable="$kioskPage" value="@3">CUSTOMISE</kiosk-tab-button>
                            <kiosk-tab-button variable="$kioskPage" value="@4">SHOP</kiosk-tab-button>
                        </row>
                    </column>
                </kiosk-background>
            </event-filter>
        </layer>
        <layer>
            <background option:canvas-fill="rgba(0,0,0,0.5)" option:id="popup-container" option:enabled="@false">
                <column option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}'>
                    <center>
                        <background-9slice image="assets/textures/ui/9slice/purchasenotificationpanel-dark-compressed.png" option:horizontal-slice-percent="@0.492647058824" option:vertical-slice-percent="@0.45" option:horizontal-edge-pixels="@134" option:vertical-edge-pixels="@18" option:min-width="@420" option:max-width="@420" option:min-height="@200" option:max-height="@200">
                            <book option:id="popup-book">
                                <row option:multi-container-alignment='@{"main":1,"cross":"stretch"}' option:multi-container-spacing="@0">
                                    <spacing option:flex="@0" option:min-width="@28"/>
                                    <column option:min-width="@356" option:max-width="@356" option:max-height="@200">
                                        <container option:container-padding='@{"left":0,"right":0,"top":24,"bottom":0}'>
                                            <label option:body-text-align="@0.5" option:body-text-font="2.4em sui-generis">CONFIRM PURCHASE</label>
                                        </container>
                                        <column option:multi-container-spacing="@6">
                                            <label option:id="itemBuyConfirmLabel" option:body-text-align="@0.5" option:body-text-font="2.0em sui-generis" option:max-width="@356" option:max-height="@64"></label>
                                            <price-row price="@0" currency="@0" option:id="popup-price-row"/>
                                        </column>
                                        <container option:container-padding='@{"left":0,"right":0,"top":0,"bottom":24}'>
                                            <row option:multi-container-alignment='@{"main":"around","cross":0.5}' option:multi-container-spacing="@16">
                                                <decorated-button option:id="itemPurchaseCancelButton" option:font-size="@1.2" option:normal-stroke="#425d7d" option:unclickable-stroke="#425d7d" option:unhover-background-fill="#587ba3" option:hover-background-fill="#6e8caf" option:hold-background-fill="#6e8caf" option:min-width="@120" option:max-width="@120" option:min-height="@32" option:max-height="@32">CANCEL</decorated-button>
                                                <decorated-button option:id="purchaseItemConfirmButton" option:font-size="@1.5" option:button-label-fill="white" option:normal-stroke="white" option:unclickable-stroke="white" option:unhover-background-fill="#587ba3" option:hover-background-fill="#6e8caf" option:hold-background-fill="#6e8caf" option:min-width="@150" option:max-width="@150" option:min-height="@40" option:max-height="@40">BUY</decorated-button>
                                            </row>
                                        </container>
                                    </column>
                                    <container option:container-padding='@{"left":0,"right":8,"top":8,"bottom":0}' option:container-alignment='@{"horizontal":"stretch","vertical":0}'>
                                        <icon-button option:container-padding='@{"left":0,"right":0,"top":0,"bottom":0}' option:container-alignment='@{"horizontal":"stretch","vertical":0}' option:id="itemPurchaseCloseButton" image="assets/textures/ui/icons/kiosk/close.svg" option:width="@20" option:height="@20"/>
                                    </container>
                                </row>
                                <row option:multi-container-alignment='@{"main":1,"cross":"stretch"}' option:multi-container-spacing="@0">
                                    <spacing option:flex="@0" option:min-width="@28"/>
                                    <column option:min-width="@356" option:max-width="@356" option:max-height="@200">
                                        <container option:container-padding='@{"left":0,"right":0,"top":24,"bottom":0}'>
                                            <label option:body-text-align="@0.5" option:body-text-font="2.4em sui-generis">JUST BOUGHT</label>
                                        </container>
                                        <row option:multi-container-alignment='@{"main":0.5,"cross":"0.5"}' option:max-width="@356" option:max-height="@64">
                                            <icon image="$itemPostPurchaseImage" option:width="@64" option:height="@64"/>
                                            <label option:id="itemPostPurchaseLabel" option:body-text-font="2.0em sui-generis"></label>
                                        </row>
                                        <container option:container-padding='@{"left":0,"right":0,"top":0,"bottom":24}'>
                                            <row option:multi-container-alignment='@{"main":"around","cross":0.5}' option:multi-container-spacing="@16">
                                                <decorated-button option:id="itemPostPurchaseEquipButton" option:font-size="@1.5" option:button-label-fill="white" option:normal-stroke="white" option:unclickable-stroke="white" option:unhover-background-fill="#587ba3" option:hover-background-fill="#6e8caf" option:hold-background-fill="#6e8caf" option:min-width="@150" option:max-width="@150" option:min-height="@40" option:max-height="@40">EQUIP</decorated-button>
                                                <decorated-button option:id="itemPostPurchaseCancelButton" option:font-size="@1.5" option:button-label-fill="white" option:normal-stroke="white" option:unclickable-stroke="white" option:unhover-background-fill="#587ba3" option:hover-background-fill="#6e8caf" option:hold-background-fill="#6e8caf" option:min-width="@150" option:max-width="@150" option:min-height="@40" option:max-height="@40">CONTINUE</decorated-button>
                                            </row>
                                        </container>
                                    </column>
                                    <container option:container-padding='@{"left":0,"right":8,"top":8,"bottom":0}' option:container-alignment='@{"horizontal":"stretch","vertical":0}'>
                                        <icon-button option:container-padding='@{"left":0,"right":0,"top":0,"bottom":0}' option:container-alignment='@{"horizontal":"stretch","vertical":0}' option:id="itemPostPurchaseCloseButton" image="assets/textures/ui/icons/kiosk/close.svg" option:width="@20" option:height="@20"/>
                                    </container>
                                </row>
                                <row option:multi-container-alignment='@{"main":1,"cross":"stretch"}' option:multi-container-spacing="@0">
                                    <spacing option:flex="@0" option:min-width="@28"/>
                                    <column option:min-width="@356" option:max-width="@356" option:max-height="@200">
                                        <container option:container-padding='@{"left":0,"right":0,"top":24,"bottom":0}'>
                                            <label option:body-text-align="@0.5" option:body-text-font="2.4em sui-generis">TRANSACTION FAILED</label>
                                        </container>
                                        <label option:body-text-align="@0.5" option:body-text-font="1.5em sui-generis" option:id="transactionFailureMessage"></label>
                                        <container option:container-alignment='@{"horizontal":0.5,"vertical":"stretch"}' option:container-padding='@{"left":0,"right":0,"top":0,"bottom":24}'>
                                            <decorated-button option:id="itemFailPurchaseCancelButton" option:font-size="@1.5" option:button-label-fill="white" option:normal-stroke="white" option:unclickable-stroke="white" option:unhover-background-fill="#587ba3" option:hover-background-fill="#6e8caf" option:hold-background-fill="#6e8caf" option:min-width="@150" option:max-width="@150" option:min-height="@40" option:max-height="@40">CLOSE</decorated-button>
                                        </container>
                                    </column>
                                    <container option:container-padding='@{"left":0,"right":8,"top":8,"bottom":0}' option:container-alignment='@{"horizontal":"stretch","vertical":0}'>
                                        <icon-button option:container-padding='@{"left":0,"right":0,"top":0,"bottom":0}' option:container-alignment='@{"horizontal":"stretch","vertical":0}' option:id="itemFailPurchaseCloseButton" image="assets/textures/ui/icons/kiosk/close.svg" option:width="@20" option:height="@20"/>
                                    </container>
                                </row>
                                <row option:multi-container-alignment='@{"main":1,"cross":"stretch"}' option:multi-container-spacing="@0">
                                    <spacing option:flex="@0" option:min-width="@28"/>
                                    <column option:min-width="@356" option:max-width="@356" option:max-height="@200">
                                        <container option:container-padding='@{"left":0,"right":0,"top":24,"bottom":24}'>
                                            <column>
                                                <label option:body-text-align="@0.5" option:body-text-font="2.4em sui-generis">DON'T HAVE ENOUGH</label>
                                                <row>
                                                    <spacing/>
                                                    <label option:body-text-align="@0.5" option:body-text-font="2.4em sui-generis" option:id="notEnoughCoinsMessage"></label>
                                                    <icon image="$notEnoughCoinsIcon" option:width="@32" option:height="@32"/>
                                                    <spacing/>
                                                </row>
                                            </column>
                                        </container>
                                        <container option:container-alignment='@{"horizontal":0.5,"vertical":"stretch"}' option:container-padding='@{"left":0,"right":0,"top":0,"bottom":24}'>
                                            <decorated-button option:id="notEnoughCoinsCancelButton" option:font-size="@1.5" option:button-label-fill="white" option:normal-stroke="white" option:unclickable-stroke="white" option:unhover-background-fill="#587ba3" option:hover-background-fill="#6e8caf" option:hold-background-fill="#6e8caf" option:min-width="@150" option:max-width="@150" option:min-height="@40" option:max-height="@40">CLOSE</decorated-button>
                                        </container>
                                    </column>
                                    <container option:container-padding='@{"left":0,"right":8,"top":8,"bottom":0}' option:container-alignment='@{"horizontal":"stretch","vertical":0}'>
                                        <icon-button option:container-padding='@{"left":0,"right":0,"top":0,"bottom":0}' option:container-alignment='@{"horizontal":"stretch","vertical":0}' option:id="notEnoughCoinsCloseButton" image="assets/textures/ui/icons/kiosk/close.svg" option:width="@20" option:height="@20"/>
                                    </container>
                                </row>
                                <row option:multi-container-alignment='@{"main":1,"cross":"stretch"}' option:multi-container-spacing="@0">
                                    <spacing option:flex="@0" option:min-width="@28"/>
                                    <column option:min-width="@356" option:max-width="@356" option:max-height="@200">
                                        <container option:container-padding='@{"left":0,"right":0,"top":24,"bottom":0}'>
                                            <label option:body-text-align="@0.5" option:body-text-font="2.4em sui-generis">COMING SOON</label>
                                        </container>
                                        <label option:body-text-align="@0.5" option:body-text-font="1.5em sui-generis" option:id="comingSoonMessage"></label>
                                        <container option:container-alignment='@{"horizontal":0.5,"vertical":"stretch"}' option:container-padding='@{"left":0,"right":0,"top":0,"bottom":24}'>
                                            <decorated-button option:id="comingSoonCancelButton" option:font-size="@1.5" option:button-label-fill="white" option:normal-stroke="white" option:unclickable-stroke="white" option:unhover-background-fill="#587ba3" option:hover-background-fill="#6e8caf" option:hold-background-fill="#6e8caf" option:min-width="@150" option:max-width="@150" option:min-height="@40" option:max-height="@40">CLOSE</decorated-button>
                                        </container>
                                    </column>
                                    <container option:container-padding='@{"left":0,"right":8,"top":8,"bottom":0}' option:container-alignment='@{"horizontal":"stretch","vertical":0}'>
                                        <icon-button option:container-padding='@{"left":0,"right":0,"top":0,"bottom":0}' option:container-alignment='@{"horizontal":"stretch","vertical":0}' option:id="comingSoonCloseButton" image="assets/textures/ui/icons/kiosk/close.svg" option:width="@20" option:height="@20"/>
                                    </container>
                                </row>
                                <row option:multi-container-alignment='@{"main":1,"cross":"stretch"}' option:multi-container-spacing="@0">
                                    <spacing option:flex="@0" option:min-width="@28"/>
                                    <column option:min-width="@356" option:max-width="@356" option:max-height="@200">
                                        <container option:container-padding='@{"left":0,"right":0,"top":24,"bottom":0}'>
                                            <label option:body-text-align="@0.5" option:body-text-font="2.4em sui-generis">CHANGE EQUIPMENT?</label>
                                        </container>
                                        <label option:body-text-align="@0.5" option:body-text-font="1.5em sui-generis">Would you like to keep your changes?</label>
                                        <container option:container-padding='@{"left":0,"right":0,"top":0,"bottom":24}'>
                                            <row option:multi-container-alignment='@{"main":"around","cross":0.5}' option:multi-container-spacing="@16">
                                                <decorated-button option:id="changeEquipmentRevertButton" option:font-size="@1.5" option:button-label-fill="white" option:normal-stroke="white" option:unclickable-stroke="white" option:unhover-background-fill="#587ba3" option:hover-background-fill="#6e8caf" option:hold-background-fill="#6e8caf" option:min-width="@150" option:max-width="@150" option:min-height="@40" option:max-height="@40">REVERT</decorated-button>
                                                <decorated-button option:id="changeEquipmentKeepButton" option:font-size="@1.5" option:button-label-fill="white" option:normal-stroke="white" option:unclickable-stroke="white" option:unhover-background-fill="#587ba3" option:hover-background-fill="#6e8caf" option:hold-background-fill="#6e8caf" option:min-width="@150" option:max-width="@150" option:min-height="@40" option:max-height="@40">KEEP</decorated-button>
                                            </row>
                                        </container>
                                    </column>
                                    <container option:container-padding='@{"left":0,"right":8,"top":8,"bottom":0}' option:container-alignment='@{"horizontal":"stretch","vertical":0}'>
                                        <icon-button option:container-padding='@{"left":0,"right":0,"top":0,"bottom":0}' option:container-alignment='@{"horizontal":"stretch","vertical":0}' option:id="changeEquipmentCloseButton" image="assets/textures/ui/icons/kiosk/close.svg" option:width="@20" option:height="@20"/>
                                    </container>
                                </row>
                                <row option:multi-container-alignment='@{"main":1,"cross":"stretch"}' option:multi-container-spacing="@0">
                                    <spacing option:flex="@0" option:min-width="@28"/>
                                    <column option:multi-container-alignment='@{"main":"around","cross":"stretch"}' option:min-width="@356" option:max-width="@356" option:max-height="@200">
                                        <row>
                                            <row option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}' option:min-width="@100" option:max-width="@100">
                                                <label text="$fpPerBronzeMedal" option:body-text-align="@1" option:min-width="@30" option:max-width="@30"/>
                                                <icon image="assets/textures/ui/icons/kiosk/rosette.svg" option:width="@24" option:height="@24"/>
                                            </row>
                                            <icon image="assets/textures/ui/icons/kiosk/rewards-legend-arrow.svg" option:width="@36" option:height="@18"/>
                                            <row option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}' option:min-width="@100" option:max-width="@100">
                                                <icon image="assets/textures/ui/icons/kiosk/medal-bronze.svg" option:width="@24" option:height="@24"/>
                                            </row>
                                        </row>
                                        <row>
                                            <row option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}' option:min-width="@100" option:max-width="@100">
                                                <label text="$fpPerSilverMedal" option:body-text-align="@1" option:min-width="@30" option:max-width="@30"/>
                                                <icon image="assets/textures/ui/icons/kiosk/rosette.svg" option:width="@24" option:height="@24"/>
                                            </row>
                                            <icon image="assets/textures/ui/icons/kiosk/rewards-legend-arrow.svg" option:width="@36" option:height="@18"/>
                                            <row option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}' option:min-width="@100" option:max-width="@100">
                                                <icon image="assets/textures/ui/icons/kiosk/medal-silver.svg" option:width="@24" option:height="@24"/>
                                            </row>
                                        </row>
                                        <row>
                                            <row option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}' option:min-width="@100" option:max-width="@100">
                                                <label text="$fpPerGoldMedal" option:body-text-align="@1" option:min-width="@30" option:max-width="@30"/>
                                                <icon image="assets/textures/ui/icons/kiosk/rosette.svg" option:width="@24" option:height="@24"/>
                                            </row>
                                            <icon image="assets/textures/ui/icons/kiosk/rewards-legend-arrow.svg" option:width="@36" option:height="@18"/>
                                            <row option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}' option:min-width="@100" option:max-width="@100">
                                                <icon image="assets/textures/ui/icons/kiosk/medal-gold.svg" option:width="@24" option:height="@24"/>
                                            </row>
                                        </row>
                                        <row>
                                            <row option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}' option:min-width="@100" option:max-width="@100">
                                                <label text="$fpPerPlatinumMedal" option:body-text-align="@1" option:min-width="@30" option:max-width="@30"/>
                                                <icon image="assets/textures/ui/icons/kiosk/rosette.svg" option:width="@24" option:height="@24"/>
                                            </row>
                                            <icon image="assets/textures/ui/icons/kiosk/rewards-legend-arrow.svg" option:width="@36" option:height="@18"/>
                                            <row option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}' option:min-width="@100" option:max-width="@100">
                                                <icon image="assets/textures/ui/icons/kiosk/medal-platinum.svg" option:width="@24" option:height="@24"/>
                                            </row>
                                        </row>
                                        <row>
                                            <row option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}' option:min-width="@100" option:max-width="@100">
                                                <label text="$medalsPerTrophy" option:body-text-align="@1"/>
                                                <icon image="assets/textures/ui/icons/kiosk/medals.svg" option:width="@72" option:height="@24"/>
                                            </row>
                                            <icon image="assets/textures/ui/icons/kiosk/rewards-legend-arrow.svg" option:width="@36" option:height="@18"/>
                                            <row option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}' option:min-width="@100" option:max-width="@100">
                                                <label option:body-text-align="@1">1x</label>
                                                <icon image="assets/textures/ui/icons/kiosk/trophies.svg" option:width="@72" option:height="@24"/>
                                            </row>
                                        </row>
                                    </column>
                                    <container option:container-padding='@{"left":0,"right":8,"top":8,"bottom":0}' option:container-alignment='@{"horizontal":"stretch","vertical":0}'>
                                        <icon-button option:container-padding='@{"left":0,"right":0,"top":0,"bottom":0}' option:container-alignment='@{"horizontal":"stretch","vertical":0}' option:id="rewardsLegendCloseButton" image="assets/textures/ui/icons/kiosk/close.svg" option:width="@20" option:height="@20"/>
                                    </container>
                                </row>
                            </book>
                        </background-9slice>
                    </center>
                </column>
            </background>
        </layer>
    </layered-container>
</ui-tree>