import { Component, Property } from "@wonderlandengine/api";
import { GameGlobals } from "../../game-globals.js";

export class RotateOverTimeComponent extends Component {
    static TypeName = "rotate-over-time";
    static Properties = {
        rotationAxis: Property.enum(["x", "y", "z"], "y"),
        secondsTo360: Property.float(180),
        randomRotationOnStart: Property.bool(true)
    };

    start() {
        this.rotationSpeed = 360 / this.secondsTo360;

        this.axis = this.rotationAxis === 0 ? GameGlobals.right : this.rotationAxis === 1 ? GameGlobals.up : GameGlobals.forward;

        if (this.randomRotationOnStart) {
            this.object.pp_rotateAxisObject(Math.pp_random(-180, 180), this.axis);
        }
    }

    update(dt) {
        this.object.pp_rotateAxisObject(this.rotationSpeed * dt, this.axis);
    }
}