import { Component } from "@wonderlandengine/api";
import { Globals, vec3_create } from "wle-pp";
import { AVATAR_FORWARD_OFFSET_FROM_HEAD } from "../../../avatar/components/avatar-component.js";
import { GameGlobals } from "../../game-globals.js";

export class SetFeetPositionComponent extends Component {
    static TypeName = "set-feet-position";
    static Properties = {
    };

    start() {
        this.avatar = this.object.parent.pp_getObjectByName("Avatar");
    }

    update(dt) {
        // Implemented outside class definition
    }
}

SetFeetPositionComponent.prototype.update = function () {
    let headPosition = vec3_create();
    let headForward = vec3_create();
    let headUp = vec3_create();
    let flatHeadForward = vec3_create();
    let playerPosition = vec3_create();
    let playerUp = vec3_create();
    let heightVector = vec3_create();
    let feetPosition = vec3_create();
    return function update(dt) {
        headPosition = Globals.getPlayerObjects(this.engine).myHead.pp_getPosition(headPosition);
        playerPosition = Globals.getPlayerObjects(this.engine).myPlayer.pp_getPosition(playerPosition);
        playerUp = Globals.getPlayerObjects(this.engine).myPlayer.pp_getUp(playerUp);

        heightVector = headPosition.vec3_sub(playerPosition, heightVector).vec3_componentAlongAxis(playerUp, heightVector);

        feetPosition.vec3_copy(headPosition);
        feetPosition.vec3_sub(heightVector, feetPosition);

        headForward = Globals.getPlayerObjects(this.engine).myHead.pp_getForward(headForward);

        let angleThreshold = 10;
        if (headForward.vec3_angle(GameGlobals.up) < angleThreshold) {
            headForward = Globals.getPlayerObjects(this.engine).myHead.pp_getDown(headForward); // Head pointing upwards
        } else if (headForward.vec3_angle(GameGlobals.up) > 180 - angleThreshold) {
            headForward = Globals.getPlayerObjects(this.engine).myHead.pp_getUp(headForward); // Head pointing downwards
        } else {
            headUp = Globals.getPlayerObjects(this.engine).myHead.pp_getUp(headUp);
            if (!headUp.vec3_isConcordant(GameGlobals.up)) {
                headForward.vec3_negate(headForward);
            }
        }

        flatHeadForward = headForward.vec3_removeComponentAlongAxis(GameGlobals.up, flatHeadForward);
        flatHeadForward.vec3_normalize(flatHeadForward);

        feetPosition.vec3_add(flatHeadForward.vec3_scale(AVATAR_FORWARD_OFFSET_FROM_HEAD, flatHeadForward), feetPosition);

        this.object.pp_setPosition(feetPosition);
        this.object.pp_setRotationQuat(this.avatar.pp_getRotationQuat());
    };
}();