import { StatusEffectTarget } from "./status-effect-target.js";
import { StatusEffect, StatusEffectType } from "./status-effect.js";

export class StatusEffectsManager {
    private _statusEffects: Map<StatusEffectTarget, StatusEffect[]> = new Map();
    private _removeDoneEffects: Map<StatusEffectTarget, boolean> = new Map();

    update(dt: number) {
        for (const [currentTarget, statusEffects] of this._statusEffects.entries()) {
            let removeDoneEffects = this._removeDoneEffects.get(currentTarget) ?? false;
            for (const statusEffect of statusEffects) {
                statusEffect.update(dt);
                removeDoneEffects ||= statusEffect.isDone();
            }

            if (removeDoneEffects) {
                statusEffects.pp_removeAll((elementToCheck) => elementToCheck.isDone());
                this._removeDoneEffects.set(currentTarget, false);
            }
        }
    }

    addStatusEffect(statusEffect: StatusEffect) {
        if (!this._statusEffects.has(statusEffect.getTarget())) {
            this._statusEffects.set(statusEffect.getTarget(), []);
        }

        this._statusEffects.get(statusEffect.getTarget())!.push(statusEffect);

        statusEffect.start();
    }

    getStatusEffects(target: StatusEffectTarget | null, type: StatusEffectType | null = null): StatusEffect[] {
        const statusEffectsToReturn = [];

        for (const statusEffects of this._statusEffects.values()) {
            for (const statusEffect of statusEffects) {
                if ((type == null || statusEffect.getType() == type) && (target == null || statusEffect.getTarget() == target) && !statusEffect.isDone()) {
                    statusEffectsToReturn.push(statusEffect);
                }
            }
        }

        return statusEffectsToReturn;
    }

    getStatusEffect(target: StatusEffectTarget, type: StatusEffectType): StatusEffect | null {
        let statusEffectToReturn = null;

        for (const statusEffects of this._statusEffects.values()) {
            for (const statusEffect of statusEffects) {
                if (statusEffect.getType() == type && statusEffect.getTarget() == target && !statusEffect.isDone()) {
                    statusEffectToReturn = statusEffect;
                    break;
                }
            }

            if (statusEffectToReturn != null) {
                break;
            }
        }

        return statusEffectToReturn;
    }

    hasStatusEffect(target: StatusEffectTarget, type: StatusEffectType): boolean {
        return this.getStatusEffect(target, type) != null;
    }

    clearEffects(target: StatusEffectTarget | null, type: StatusEffectType | null = null, statusEffectsToIgnore?: StatusEffect[]) {
        for (const [currentTarget, statusEffects] of this._statusEffects.entries()) {
            for (const statusEffect of statusEffects) {
                if (statusEffectsToIgnore == null || !statusEffectsToIgnore.pp_hasEqual(statusEffect)) {
                    if ((type == null || statusEffect.getType() == type) && (target == null || statusEffect.getTarget() == target)) {
                        statusEffect.clear();
                        this._removeDoneEffects.set(currentTarget, true);
                    }
                }
            }

            if (target == currentTarget) {
                break;
            }
        }
    }

    clearEffect(target: StatusEffectTarget, type: StatusEffectType, statusEffectsToIgnore?: StatusEffect[]) {
        for (const [currentTarget, statusEffects] of this._statusEffects.entries()) {
            for (const statusEffect of statusEffects) {
                if (statusEffectsToIgnore == null || !statusEffectsToIgnore.pp_hasEqual(statusEffect)) {
                    if (statusEffect.getType() == type && statusEffect.getTarget() == target) {
                        statusEffect.clear();
                        this._removeDoneEffects.set(currentTarget, true);
                        break;
                    }
                }
            }

            if (target == currentTarget) {
                break;
            }
        }
    }
}