export function getNumberAtTheEnd(str) {
    return parseInt(str.match(/\d+$/)[0]);
}

export function capitaliseFirstLetter(str) {
    return `${str[0].toUpperCase()}${str.slice(1)}`;
}

export function formatSecondsToTime(secondsToFormat) {
    let minutes = String(Math.floor(secondsToFormat / 60)).padStart(2, "0");
    let seconds = String(Math.floor(secondsToFormat) % 60).padStart(2, "0");
    let milliseconds = String(Math.floor((secondsToFormat * 1000) % 1000)).padStart(3, "0");
    return minutes + ":" + seconds + "." + milliseconds;
}

