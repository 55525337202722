import { Component, PhysXComponent, Property } from "@wonderlandengine/api";
import { CursorTarget } from "@wonderlandengine/components";
import { CursorStyleManagerComponent } from "cursor-style-manager-wle";
import { AnalyticsUtils, AudioPlayer, AudioSetup, Globals, ObjectCloneParams, Vec3Utils, quat_create } from "wle-pp";
import { AudioChannelName } from "../../../audio/audio-manager/audio-channel.js";
import { AudioID } from "../../../audio/audio-manager/audio-id.js";
import common from "../../../common.js";
import { ScaleOnHoverComponent } from "../../../misc/components/transform/scale-on-hover-component.js";
import { downHapticFeedback, hoverHapticFeedback, unHoverHapticFeedback, upHapticFeedback } from "../../../misc/haptic-feedback.js";

/**
 * road-map-balcony-marker
 */
export class RoadMapBalconyMarkerComponent extends Component {
    static TypeName = "road-map-balcony-marker";
    static Properties = {
        miniMap: Property.object(),
        markerPrototype: Property.object(),
        hubBalcony: Property.object(),
        balconyParent: Property.object(),
        returnToBalconyButton: Property.object(),
        balconySFXPath: Property.string(""),
        balconySFXYOffset: Property.float(5.0)
    };

    static onRegister(engine) {
        engine.registerComponent(ScaleOnHoverComponent);
    }

    start() {
        const csm = Globals.getRootObject().pp_getComponent(CursorStyleManagerComponent);

        if (this.balconySFXPath) {
            const balconyAmbientSFXSetup = new AudioSetup(this.balconySFXPath);
            balconyAmbientSFXSetup.mySpatial = true;
            balconyAmbientSFXSetup.myLoop = true;
            balconyAmbientSFXSetup.myVolume = 0.2;
            this.balconyAmbientSFXPlayer = new AudioPlayer(balconyAmbientSFXSetup);
            common.audioManager.addSourceAudioToChannel(AudioID.BALCONY_AMBIENT, this.balconyAmbientSFXPlayer, AudioChannelName.AMBIENT);
        }

        this.markerPrototype.pp_setActive(false);

        this.position = new Float32Array(3);
        this.forward = new Float32Array(3);
        for (const currentBalcony of this.balconyParent.children) {
            const balconyPhysxComponent = currentBalcony.pp_getComponent(PhysXComponent);

            let cloneParams = new ObjectCloneParams();
            cloneParams.myUseDefaultComponentCloneAsFallback = true;
            const balconyMarkerObject = this.markerPrototype.pp_clone(cloneParams);
            balconyMarkerObject.pp_setActive(true);
            balconyMarkerObject.addComponent(ScaleOnHoverComponent, { scaleSpeed: 4.5 });

            currentBalcony.pp_getPosition(this.position);
            this.miniMap.pp_convertPositionLocalToWorld(this.position, this.position);
            balconyMarkerObject.pp_setPosition(this.position);

            let balconyTarget = balconyMarkerObject.pp_getComponent(CursorTarget);
            balconyTarget.onUpWithDown.add(() => {
                balconyPhysxComponent.active = true;
                this.currentBalconyPhysx = balconyPhysxComponent;
                this.transportPlayerToSmallBalcony(currentBalcony);
                common.audioManager.getAudio(AudioID.BUTTON_CLICK).play();
            });

            balconyTarget.onHover.add((_, cursor) => {
                if (csm) csm.setStyle(this, "pointer");
                common.audioManager.getAudio(AudioID.BUTTON_HOVER).play();
                hoverHapticFeedback(cursor);
            });

            balconyTarget.onUnhover.add((_, cursor) => {
                if (csm) csm.clearStyle(this);
                unHoverHapticFeedback(cursor);
            });

            balconyTarget.onUp.add((_, cursor) => {
                upHapticFeedback(cursor);
            });

            balconyTarget.onDown.add((_, cursor) => {
                downHapticFeedback(cursor);
            });
        }

        if (this.hubBalcony != null) {
            let cloneParams = new ObjectCloneParams();
            cloneParams.myUseDefaultComponentCloneAsFallback = true;
            const balconyMarkerObject = this.markerPrototype.pp_clone(cloneParams);
            balconyMarkerObject.pp_setActive(true);
            balconyMarkerObject.addComponent(ScaleOnHoverComponent, { scaleSpeed: 4.5 });

            this.hubBalcony.pp_getPosition(this.position);
            this.miniMap.pp_convertPositionLocalToWorld(this.position, this.position);
            balconyMarkerObject.pp_setPosition(this.position);

            let balconyTarget = balconyMarkerObject.pp_getComponent(CursorTarget);
            balconyTarget.onUpWithDown.add(() => {
                common.balcony.moveToBalcony(true);
            });

            balconyTarget.onHover.add((_, cursor) => {
                if (csm) csm.setStyle(this, "pointer");
                common.audioManager.getAudio(AudioID.BUTTON_HOVER).play();
                hoverHapticFeedback(cursor);
            });

            balconyTarget.onUnhover.add((_, cursor) => {
                if (csm) csm.clearStyle(this);
                unHoverHapticFeedback(cursor);
            });

            balconyTarget.onUp.add((_, cursor) => {
                upHapticFeedback(cursor);
            });

            balconyTarget.onDown.add((_, cursor) => {
                downHapticFeedback(cursor);
            });
        }

        let returnButtonTarget = this.returnToBalconyButton.pp_getComponent(CursorTarget);
        returnButtonTarget.onUpWithDown.add(() => {
            this.currentBalconyPhysx.active = false;
            common.balcony.isPlayerOnBalcony = false;
            common.balcony.moveToBalcony();

            if (common.environmentSourroundingController) {
                common.environmentSourroundingController.onMoveToBalconyOrTrack();
            }

            if (this.balconySFXPath) {
                this.balconyAmbientSFXPlayer.stop();
            }

            this.returnToBalconyButton.pp_setParent(this.returnToBalconyButtonOriginalParent, false);
            this.returnToBalconyButton.pp_setScale(this.returnToBalconyButtonOriginalScale);
            this.returnToBalconyButton.pp_setActive(false);
        });

        this.returnToBalconyButton.pp_setActive(false);
        this.returnToBalconyButtonOriginalParent = this.returnToBalconyButton.pp_getParent();
        this.returnToBalconyButtonOriginalScale = this.returnToBalconyButton.pp_getScale();
    }

    transportPlayerToSmallBalcony(smallBalcony) {
        AnalyticsUtils.sendEvent("move_to_small_balcony");

        smallBalcony.pp_getPosition(this.position);
        smallBalcony.pp_getForward(this.forward);
        Vec3Utils.add(this.position, this.forward, this.position);

        common.balcony.playerLocomotion.active = true;
        common.balcony.playerLocomotion.getPlayerLocomotion().getPlayerTransformManager().teleportPosition(this.position, null, true);

        if (this.balconySFXPath) {
            this.balconyAmbientSFXPlayer.play();
            this.position[1] += this.balconySFXYOffset;
            this.balconyAmbientSFXPlayer.updatePosition(this.position);
        }

        if (common.environmentSourroundingController) {
            common.environmentSourroundingController.onMoveToSmallBalcony();
        }

        // TODO: Rotate player in balcony direction
        let rotation = quat_create();
        rotation.quat_setForward(this.forward);
        common.balcony.playerLocomotion.getPlayerLocomotion().getPlayerTransformManager().setRotationQuat(rotation);

        common.balcony.playerLocomotion.getPlayerLocomotion().getPlayerTransformManager().resetReal(true, false, false, true);
        common.balcony.playerLocomotion.getPlayerLocomotion().getPlayerTransformManager().resetHeadToReal();

        this.returnToBalconyButton.pp_setParent(smallBalcony, false);
        this.returnToBalconyButton.pp_setScale(this.returnToBalconyButtonOriginalScale);
        this.returnToBalconyButton.pp_setActive(true);
    }
}
