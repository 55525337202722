import { filterIDFromProperties, type WidgetAutoXML } from "lazy-widgets";
import { ArrowIcon, type ArrowDirection, type ArrowIconProperties } from "./arrow-icon.js";
import { BaseDecoratedButton, type DecoratedButtonProperties } from "./base-decorated-button.js";

interface ArrowDecoratedButtonProperties extends ArrowIconProperties, DecoratedButtonProperties {
    arrowFillStyle?: string;
    arrowFillStyleDisabled?: string;
}

export class ArrowDecoratedButton extends BaseDecoratedButton<ArrowIcon> {
    static override autoXML: WidgetAutoXML = {
        name: "arrow-decorated-button",
        inputConfig: [
            {
                mode: "value",
                name: "direction",
            }
        ]
    };

    private arrowFillStyle: string;
    private arrowFillStyleDisabled: string;

    constructor(direction: ArrowDirection, properties?: Readonly<ArrowDecoratedButtonProperties>) {
        super(
            new ArrowIcon(direction, filterIDFromProperties(properties)),
            {
                hasBorder: false,
                ...properties,
            },
        );

        this.arrowFillStyle = properties?.arrowFillStyle ?? "black";
        this.arrowFillStyleDisabled = properties?.arrowFillStyleDisabled ?? "rgb(128,128,128)";

        this.updateIconColor();
    }

    private updateIconColor() {
        this.child.bodyTextFill = this.clickable ? this.arrowFillStyle : this.arrowFillStyleDisabled;
    }

    override set clickable(clickable) {
        super.clickable = clickable;
        this.updateIconColor();
    }

    override get clickable() {
        return super.clickable;
    }
}