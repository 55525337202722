import { Component, Property } from "@wonderlandengine/api";
import { vec3 } from "gl-matrix";
import { MathUtils, ObjectUtils, Vec3Utils } from "wle-pp";
import { GameGlobals } from "../../misc/game-globals.js";

// try to put the memory as close to each other as possible to (maybe)
// increase cache hits. this probably does nothing though because
// javascript's memory is all over the place
// 17x vec3 (12 bytes per vec3) + 1x quat (16 bytes per quat)
const workingBuffer = new ArrayBuffer(220);
const rootScaling = new Float32Array(workingBuffer, 0, 3);
const middlePos = new Float32Array(workingBuffer, 12, 3);
const endPos = new Float32Array(workingBuffer, 24, 3);
const targetPos = new Float32Array(workingBuffer, 36, 3);
const helperPos = new Float32Array(workingBuffer, 48, 3);
const endUp = new Float32Array(workingBuffer, 60, 3);
const endRight = new Float32Array(workingBuffer, 72, 3);
const endFlatRight = new Float32Array(workingBuffer, 84, 3);
const fixRightTiltRotationAxis = new Float32Array(workingBuffer, 96, 3);
const ta = new Float32Array(workingBuffer, 108, 3);
const ca = new Float32Array(workingBuffer, 120, 3);
const ba = new Float32Array(workingBuffer, 132, 3);
const ab = new Float32Array(workingBuffer, 144, 3);
const cb = new Float32Array(workingBuffer, 156, 3);
const axis0 = new Float32Array(workingBuffer, 168, 3);
const axis1 = new Float32Array(workingBuffer, 180, 3);
const temp = new Float32Array(workingBuffer, 192, 3);
const tempQuat = new Float32Array(workingBuffer, 204, 4);

// From http://theorangeduck.com/page/simple-two-joint
function twoJointIK(root, middle, b, c, targetPos, eps, helper) {
    /* a = [0, 0, 0], since everything is computed in root-space */
    ba.set(b);
    const lab = vec3.length(ba);
    vec3.sub(ta, b, c);
    const lcb = vec3.length(ta);
    ta.set(targetPos);
    const lat = MathUtils.clamp(vec3.length(ta), eps, lab + lcb - eps);

    ca.set(c);
    vec3.scale(ab, b, -1);
    vec3.sub(cb, c, b);

    vec3.normalize(ca, ca);
    vec3.normalize(ba, ba);
    vec3.normalize(ab, ab);
    vec3.normalize(cb, cb);
    vec3.normalize(ta, ta);

    /* Supposedly numberical errors can cause the dot to go out of -1, 1 range */
    const ac_ab_0 = Math.acos(MathUtils.clamp(vec3.dot(ca, ba), -1, 1));
    const ba_bc_0 = Math.acos(MathUtils.clamp(vec3.dot(ab, cb), -1, 1));
    const ac_at_0 = Math.acos(MathUtils.clamp(vec3.dot(ca, ta), -1, 1));

    const ac_ab_1 = Math.acos(MathUtils.clamp((lcb * lcb - lab * lab - lat * lat) / (-2 * lab * lat), -1, 1));
    const ba_bc_1 = Math.acos(MathUtils.clamp((lat * lat - lab * lab - lcb * lcb) / (-2 * lab * lcb), -1, 1));

    if (helper) {
        vec3.sub(ba, helper, b);
        vec3.normalize(ba, ba);
    }

    vec3.cross(axis0, ca, ba);
    vec3.normalize(axis0, axis0);

    vec3.cross(axis1, c, targetPos);
    vec3.normalize(axis1, axis1);

    middle.transformVectorInverseLocal(temp, axis0);

    root.rotateAxisAngleRadObject(axis1, ac_at_0);
    root.rotateAxisAngleRadObject(axis0, ac_ab_1 - ac_ab_0);
    middle.rotateAxisAngleRadObject(axis0, ba_bc_1 - ba_bc_0);
}

// XXX using different name, otherwise the @wonderlandengine/components one is
//     preferred over ours, which causes a crash
/**
 * Inverse Kinematics for two-joint chains (e.g. knees or ellbows)
 */
export class HoverfitTwoJointIkSolverComponent extends Component {
    static TypeName = "hoverfit-two-joint-ik-solver";
    static Properties = {
        /** Root bone, never moves */
        root: Property.object(),
        /** Bone attached to the root */
        middle: Property.object(),
        /** Bone attached to the middle */
        end: Property.object(),
        /** Target the joins should reach for */
        target: Property.object(),
        /** Flag for copying rotation from target to end */
        copyTargetRotation: Property.bool(true),
        /** Flag for make it so the end stays aligned with the global up, only working when not copying target rotation */
        alignWithGlobalUp: Property.bool(true),
        /** Helper object to use to determine joint rotation axis */
        helper: Property.object(),
        /** Minimum amount of time that has to pass for the IK to be updated (limits IK to a maximum of 30 FPS by default) */
        minGapTime: Property.float(0),
    };

    init() {
        // try to put the memory as close to each other as possible to (maybe)
        // increase cache hits. this probably does nothing though because
        // javascript's memory is all over the place
        // 3x quat2 (32 bytes each), 1x quat (16 bytes each)
        const transformBuffer = new ArrayBuffer(112);
        this.rootTransform = new Float32Array(transformBuffer, 0, 8);
        this.middleTransform = new Float32Array(transformBuffer, 32, 8);
        this.endTransform = new Float32Array(transformBuffer, 64, 8);
        this.extraEndRotationQuat = new Float32Array(transformBuffer, 96, 4);
        this.extraEndRotationEnabled = false; // Used to add an offset rotation to the feet after the IK, so they point a bit outwards
        this.timeAccum = 0;
    }

    start() {
        this.root.getTransformLocal(this.rootTransform);
        this.middle.getTransformLocal(this.middleTransform);
        this.end.getTransformLocal(this.endTransform);
        ObjectUtils.getUpWorld(this.end, endUp);
        ObjectUtils.getRightWorld(this.end, endRight);
        this.endInitialRotationAroundRight = Vec3Utils.angleSignedDegrees(endUp, GameGlobals.up, endRight);
    }

    update(dt) {
        if (this.minGapTime > 0) {
            this.timeAccum += dt;

            if (this.timeAccum >= this.minGapTime) {
                this.timeAccum = 0;
            } else {
                return;
            }
        }

        /* Reset to original pose for stability */
        this.root.setTransformLocal(this.rootTransform);
        this.middle.setTransformLocal(this.middleTransform);
        this.end.setTransformLocal(this.endTransform);

        this.root.getScalingWorld(rootScaling);

        /* Get joint positions in root-space */
        this.middle.getPositionLocal(middlePos);

        this.end.getPositionLocal(endPos);
        this.middle.transformPointLocal(endPos, endPos);

        if (this.helper) {
            /* Get helper position in root space */
            this.helper.getPositionWorld(helperPos);
            this.root.transformPointInverseWorld(helperPos, helperPos);
            vec3.div(helperPos, helperPos, rootScaling);
        }

        /* Get target position in root space */
        this.target.getPositionWorld(targetPos);
        this.root.transformPointInverseWorld(targetPos, targetPos);
        vec3.div(targetPos, targetPos, rootScaling);

        twoJointIK(
            this.root,
            this.middle,
            middlePos,
            endPos,
            targetPos,
            0.01,
            this.helper ? helperPos : null,
        );

        if (this.copyTargetRotation) {
            this.end.setRotationWorld(this.target.getRotationWorld(tempQuat));
        } else if (this.alignWithGlobalUp) {
            ObjectUtils.getRightWorld(this.end, endRight);
            Vec3Utils.removeComponentAlongAxis(endRight, GameGlobals.up, endFlatRight);
            if (!Vec3Utils.isZero(endFlatRight, MathUtils.EPSILON)) {
                vec3.normalize(endFlatRight, endFlatRight);

                vec3.cross(endFlatRight, endRight, fixRightTiltRotationAxis);
                vec3.normalize(fixRightTiltRotationAxis, fixRightTiltRotationAxis);
                const angleToRotate = Vec3Utils.angleSignedDegrees(endRight, endFlatRight, fixRightTiltRotationAxis);
                ObjectUtils.rotateAxisWorldDegrees(this.end, angleToRotate, fixRightTiltRotationAxis);
            }

            ObjectUtils.getUpWorld(this.end, endUp);
            ObjectUtils.getRightWorld(this.end, endRight);
            const endCurrentRotationAroundRight = Vec3Utils.angleSignedDegrees(endUp, GameGlobals.up, endRight);
            const rotationToAlignWithUp = endCurrentRotationAroundRight - this.endInitialRotationAroundRight;
            ObjectUtils.rotateAxisWorldDegrees(this.end, rotationToAlignWithUp, endRight);
        }

        if (this.extraEndRotationEnabled) {
            this.end.rotateObject(this.extraEndRotationQuat);
        }
    }
}