{
    "canyon": {
        "name": "Thunder Canyon",
        "defaultMode": "race",
        "modes": {
            "race": {
                "tracks": [
                    {
                        "name": "High Noon",
                        "map": "canyon-open",
                        "mapTrackIndex": 1,
                        "maxPlayers": 6,
                        "difficulties": 5
                    },
                    {
                        "name": "River Run",
                        "map": "canyon-open",
                        "mapTrackIndex": 2,
                        "maxPlayers": 6,
                        "difficulties": 5,
                        "locked": true
                    },
                    {
                        "name": "Gold Rush",
                        "map": "canyon-open",
                        "mapTrackIndex": 3,
                        "maxPlayers": 6,
                        "difficulties": 5,
                        "locked": true
                    }
                ]
            },
            "tag": {
                "tracks": [
                    {
                        "name": "Road Runner",
                        "map": "canyon-open",
                        "mapTrackIndex": 4,
                        "maxPlayers": 6,
                        "difficulties": 0
                    }
                ]
            },
            "roam": {
                "tracks": [
                    {
                        "name": "Roam track",
                        "map": "canyon-open",
                        "mapTrackIndex": 0,
                        "maxPlayers": null,
                        "difficulties": 0
                    }
                ]
            }
        }
    },
    "snow": {
        "name": "Polar World",
        "defaultMode": "race",
        "modes": {
            "race": {
                "tracks": [
                    {
                        "name": "Polar Dash",
                        "map": "snow-race",
                        "mapTrackIndex": 1,
                        "maxPlayers": 6,
                        "difficulties": 5
                    },
                    {
                        "name": "Arctic Speedway",
                        "map": "snow-race",
                        "mapTrackIndex": 2,
                        "maxPlayers": 6,
                        "difficulties": 5,
                        "locked": true
                    },
                    {
                        "name": "Glacier Drift",
                        "map": "snow-race",
                        "mapTrackIndex": 3,
                        "maxPlayers": 6,
                        "difficulties": 5,
                        "locked": true
                    }
                ]
            },
            "tag": {
                "tracks": [
                    {
                        "name": "Ice Chase",
                        "map": "snow",
                        "mapTrackIndex": 1,
                        "maxPlayers": 6,
                        "difficulties": 0,
                        "locked": true
                    }
                ]
            },
            "roam": {
                "tracks": [
                    {
                        "name": "Roam track",
                        "map": "snow",
                        "mapTrackIndex": 0,
                        "maxPlayers": null,
                        "difficulties": 0,
                        "locked": true
                    }
                ]
            }
        }
    },
    "city": {
        "name": "Thrill City",
        "defaultMode": "race",
        "modes": {
            "race": {
                "tracks": [
                    {
                        "name": "Outer Limits",
                        "map": "city",
                        "mapTrackIndex": 1,
                        "maxPlayers": 6,
                        "difficulties": 5
                    },
                    {
                        "name": " Downtown Derby",
                        "map": "city",
                        "mapTrackIndex": 2,
                        "maxPlayers": 6,
                        "difficulties": 5,
                        "locked": true
                    },
                    {
                        "name": "City Rush",
                        "map": "city",
                        "mapTrackIndex": 3,
                        "maxPlayers": 6,
                        "difficulties": 5,
                        "locked": true
                    }
                ]
            },
            "tag": {
                "tracks": [
                    {
                        "name": "Thunder Tag",
                        "map": "city",
                        "mapTrackIndex": 4,
                        "maxPlayers": 6,
                        "difficulties": 0,
                        "locked": true
                    }
                ]
            },
            "roam": {
                "tracks": [
                    {
                        "name": "Roam track",
                        "map": "city",
                        "mapTrackIndex": 0,
                        "maxPlayers": null,
                        "difficulties": 0,
                        "locked": true
                    }
                ]
            }
        }
    }
}