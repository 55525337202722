import '@playkostudios/wle-patcher'; // TODO remove once WLE devs decide to fix their stuff
// import wleTrace from '@playkostudios/wle-trace';
import { loadRuntime } from '@wonderlandengine/api';
import { mockConfig } from './hoverfit/misc/asset-provision/mock-config.js';
// import { injectDebugCode, toggleDebugFeature } from 'lazy-widgets';

// wleTrace.waitForInjections(() => {
//     // XXX check for resource management bugs
//     // wleTrace.enableWithPrefix('guard:');
//     // wleTrace.enable('breakpoint:guard-failed');
//     // wleTrace.enable('breakpoint:strict-guard-only');
//     // wleTrace.enable('destruction-traces');
//     // wleTrace.enableWithPrefix('debug:');

//     wleTrace.enable('trace:WASM._wljs_component_init');
// });

// injectDebugCode();
// toggleDebugFeature('flashdamage', true);

let preloadPromise = null;
if (!WL_EDITOR && DEV_MODE) {
    if (!DISABLE_HEYVR_SANDBOX) {
        // XXX `require` is used instead of dynamic import so that esbuild
        // auto-removes it from the bundle, and so we don't have to include
        // heyvr in a separate file and wait for it to load
        console.warn('Using heyVR in development mode');
        // eslint-disable-next-line no-undef, @typescript-eslint/no-require-imports
        require('../custom-packages/latest.heyvr-sandbox.js');
        window.heyVR.mock.importConfig(mockConfig);
    } else {
        console.warn('HeyVR sandbox disabled despite being in development mode');
    }
}

const hooks = [];
export let loadStarted = false;

export function addAutoRegisterHook(hook) {
    hooks.push(hook);
}

function requestSession(engine, Constants, mode) {
    engine
        .requestXRSession(mode, Constants.WebXRRequiredFeatures, Constants.WebXROptionalFeatures)
        .catch((e) => console.error(e));
}

function setupButtonsXR(engine, Constants) {
    /* Setup AR / VR buttons */
    const arButton = document.getElementById('ar-button');
    if (arButton) {
        arButton.dataset.supported = engine.arSupported;
        arButton.addEventListener('click', () => requestSession(engine, Constants, 'immersive-ar'));
    }
    const vrButton = document.getElementById('vr-button');
    if (vrButton) {
        vrButton.dataset.supported = engine.vrSupported;
        vrButton.addEventListener('click', () => requestSession(engine, Constants, 'immersive-vr'));
    }
}

export async function load(RuntimeOptions, Constants, sceneOverride = null) {
    if (sceneOverride === null) {
        console.warn("\n\n\n\nHEY YOU!\nYES, YOU!\nThis is an editor build, which is doing a scene override. The default scene in the CI could be different.\nDon't forget to update build-config.json\n\n\n\n\n");
        sceneOverride = Constants.ProjectName;
    }

    if (RuntimeOptions.canvas !== undefined && RuntimeOptions.canvas !== RUNTIME_OPTIONS_CANVAS) {
        console.warn(`Editor-supplied canvas ID ("${RuntimeOptions.canvas}") does not match the build config's canvas ID ("${RUNTIME_OPTIONS_CANVAS}") and will be ignored`);
        RuntimeOptions.canvas = RUNTIME_OPTIONS_CANVAS;
    }

    if (preloadPromise) {
        console.warn('This build has an async pre-load stage. Waiting...');
        try {
            await preloadPromise;
        } catch (e) {
            console.error(e);
        }
    }

    const engine = await loadRuntime(Constants.RuntimeBaseName, RuntimeOptions);

    engine.onSceneLoaded.once(() => {
        const el = document.getElementById('version');
        if (el) setTimeout(() => el.remove(), 2000);
    });

    /* WebXR setup. */
    const setupButtonsXRBind = setupButtonsXR.bind(null, engine, Constants);
    if (document.readyState === 'loading') {
        window.addEventListener('load', setupButtonsXRBind);
    } else {
        setupButtonsXRBind();
    }

    // auto-register components from ALL scenes
    for (const hook of hooks) {
        hook(engine);
    }

    const sceneBinName = `${sceneOverride}.bin`;
    try {
        await engine.scene.load(sceneBinName);
    } catch (err) {
        console.error(err);
        window.alert(`Failed to load ${sceneBinName}. Check console for details`);
    }
}
