import { Component } from '@wonderlandengine/api';
import { PickupRocket, PickupRocketParams } from '../implementations/pickup-rocket.js';
import { PickupsManager } from '../pickups-manager.js';
import { PickupComponent } from './pickup-component.js';

export class PickupRocketComponent extends Component implements PickupComponent {
    static TypeName = "pickup-rocket";

    static onRegister() {
        PickupsManager.getPickupComponentTypes().pp_pushUnique(PickupRocketComponent);
    }

    createPickup(): PickupRocket {
        const params = new PickupRocketParams();
        return new PickupRocket(this.object, params);
    }
}