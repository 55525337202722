// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore - The build system supports XML importing, but typescript doesn't
import xmlContent from "../xml/kiosk-lower.xml";

import { GameLocation, GameMode, VALID_CONFIGURATIONS, isValidConfiguration } from "hoverfit-shared-netcode";
import { type Background, ClickEvent, Label, ObservableTransformer, Row, Theme, ValidatedVariable, Variable } from "lazy-widgets";
import { type WLRoot, type WLVirtualKeyboardRoot } from "lazy-widgets-wle";
import { AudioID } from "src/hoverfit/audio/audio-manager/audio-id.js";
import { HoverboardDebugs } from "src/hoverfit/game/components/hoverboard-debugs-component.js";
import { BuiltInAssetProvider } from "src/hoverfit/misc/asset-provision/built-in-asset-provider.js";
import { FitabuxAssetProvider } from "src/hoverfit/misc/asset-provision/fitabux-asset-provider.js";
import { ObservableItemIDCollectionEventType } from "src/hoverfit/misc/data-structs/observable-item-id-collection.js";
import { getAssetThumbnail, isItemCategoryEquippable } from "src/hoverfit/utils/asset-utils.js";
import { MEDALS_PER_TROPHY, RewardTier, getDailyMedalFitPointsThreshold } from "src/hoverfit/utils/reward-utils.js";
import { AnalyticsUtils, BrowserUtils, Globals } from "wle-pp";
import common from "../../../common.js";
import { HoverboardGameConfig, currentGameConfig } from "../../../data/game-configuration.js";
import { currentPlayerData } from "../../../data/player-data.js";
import { Gender } from "../../../data/values/gender.js";
import { canJoinTrack } from "../../../game/track/track-utils.js";
import { type GenericPurchaseError, HeadwearSubCategory, ItemCategory, ItemCurrency, NoFundsPurchaseError } from "../../../misc/asset-provision/asset-provider.js";
import { HeyVRAssetProvider, MockAssetProvider } from "../../../misc/asset-provision/heyvr-asset-provider.js";
import { IAPContentController } from "../../../misc/asset-provision/iap-content-controller.js";
import { RoomData, currentRoomData } from "../../../network/components/hoverboard-networking-component.js";
import { RoomState } from "../../../network/room-proxy.js";
import { BaseFitnessResortUIComponent } from "../../lazy-widgets/components/base-fitness-resort-ui-component.js";
import { ArrowDecoratedButton } from "../../lazy-widgets/widgets/arrow-decorated-button.js";
import { BackPane } from "../../lazy-widgets/widgets/back-pane.js";
import { DecoratedButtonBadge } from "../../lazy-widgets/widgets/base-decorated-button.js";
import { Book } from "../../lazy-widgets/widgets/book.js";
import { Carousel } from "../../lazy-widgets/widgets/carousel.js";
import { ClickyButton } from "../../lazy-widgets/widgets/clicky-button.js";
import { ClickyCheckbox } from "../../lazy-widgets/widgets/clicky-checkbox.js";
import { CustomisationButton } from "../../lazy-widgets/widgets/customisation-button.js";
import { DecoratedButtonLikePane } from "../../lazy-widgets/widgets/decorated-button-like-pane.js";
import { DecoratedButton } from "../../lazy-widgets/widgets/decorated-button.js";
import { HeaderPane } from "../../lazy-widgets/widgets/header-pane.js";
import { Numpad } from "../../lazy-widgets/widgets/numpad.js";
import { OptionButton } from "../../lazy-widgets/widgets/option-button.js";
import { PriceRow } from "../../lazy-widgets/widgets/price-row.js";
import { PurchaseButton } from "../../lazy-widgets/widgets/purchase-button.js";
import { CURRENCY_ICONS, ShopItemButton } from "../../lazy-widgets/widgets/shop-item-button.js";
import { StepperInput } from "../../lazy-widgets/widgets/stepper-input.js";
import { ThumbnailDecoratedButton } from "../../lazy-widgets/widgets/thumbnail-decorated-button.js";
import { TimeStepperInput } from "../../lazy-widgets/widgets/time-stepper-input.js";
import { getEffectivePrice } from "../../misc/getEffectivePrice.js";
import { sfTheme } from "../../misc/sf-theme.js";
import { AwardRow } from "../widgets/award-row.js";
import { Background9Slice } from "../widgets/background-9slice.js";
import { CustomiseGenderButton } from "../widgets/customise-gender-button.js";
import { CustomiseTabButton } from "../widgets/customise-tab-button.js";
import { EventFilterWidget } from "../widgets/event-filter-widget.js";
import { KioskBackground } from "../widgets/kiosk-background.js";
import { KioskItemGrid } from "../widgets/kiosk-item-grid.js";
import { KioskShopPopup } from "../widgets/kiosk-shop-popup.js";
import { KioskTabButton } from "../widgets/kiosk-tab-button.js";
import { FP_PER_BOARD_LEVEL, FP_PER_HELMET_LEVEL, FP_PER_SUIT_LEVEL, LifetimeLevelDisplay } from "../widgets/lifetime-level-display.js";
import { LocationButton } from "../widgets/location-button.js";
import { PlayerList } from "../widgets/player-list.js";
import { UpperUIMode } from "./kiosk-upper-ui-component.js";

const BUTTON_HEIGHT = 20;

enum MultiplayerBookSpecialPage {
    Start = RoomState.Disconnected,
    HostNumpad = 4,
    JoinNumpad = 5,
}

enum PopupPage {
    PurchaseConfirmation = 0,
    PurchaseSuccess = 1,
    PurchaseFailureGeneric = 2,
    PurchaseFailureNoMoney = 3,
    ComingSoon = 4,
    ChangeEquipment = 5,
    RewardsLegend = 6,
}

type MultiplayerBookPage = RoomState | MultiplayerBookSpecialPage;

function validateRoomID(str: string): [boolean, null | number] {
    if (str === "") {
        return [true, null];
    }

    const num = parseInt(str, 10);
    if (isNaN(num) || !isFinite(num) || num < 0) {
        return [false, null];
    }

    return [true, num];
}

export class KioskLowerUIComponent extends BaseFitnessResortUIComponent {
    static override TypeName = "kiosk-lower-ui";

    private previousKioskPageNumber!: number;
    private kioskPage!: Variable<number>;
    private customPage!: Variable<number>;
    private shopPage!: Variable<number>;
    private showItemPage!: Variable<string>;
    private gender!: Variable<Gender>;
    private skinColor!: Variable<string>;
    private hoverboard!: Variable<string>;
    private suit!: Variable<string>;
    private headwear!: Variable<string>;
    private hairColor!: Variable<string>;
    private roomIDVar!: ValidatedVariable<string, number | null>;
    private privateRoomVar!: Variable<boolean>;
    private npcsAmountInput!: StepperInput;
    private lapsAmountInput!: StepperInput;
    private tagDurationInput!: TimeStepperInput;
    private npcsDifficultyInput!: StepperInput;
    private toTrackButton!: DecoratedButton;
    private toTrackButtonMP!: DecoratedButton;
    private location = new Variable<GameLocation | null>(null);
    private mode = new Variable<GameMode | null>(null);
    private track = new Variable<number | null>(null);
    private lifetimeFitPoints!: Variable<number>;
    private dailyFitPoints!: Variable<number>;
    private bronzeMedals!: Variable<number>;
    private silverMedals!: Variable<number>;
    private goldMedals!: Variable<number>;
    private platinumMedals!: Variable<number>;

    private postPurchaseThumbnail!: HTMLImageElement;
    private notEnoughCoinsIcon!: HTMLImageElement;

    iapContentController!: IAPContentController;

    override init(): void {
        super.init();

        this.iapContentController = new IAPContentController([
            BuiltInAssetProvider,
            FitabuxAssetProvider,
            window.heyVR ? HeyVRAssetProvider.bind(null, window.heyVR) : MockAssetProvider,
        ]);

        this.kioskPage = new Variable(2);
        this.previousKioskPageNumber = this.kioskPage.value;
        this.customPage = new Variable(1);
        this.shopPage = new Variable(0);
        this.showItemPage = new Variable("");
        this.gender = new Variable(0);
        this.skinColor = new Variable("");
        this.hoverboard = new Variable("");
        this.suit = new Variable("");
        this.headwear = new Variable("");
        this.hairColor = new Variable("");
        this.roomIDVar = new ValidatedVariable<string, number | null>("", validateRoomID);
        this.privateRoomVar = new Variable(false);

        this.lifetimeFitPoints = new Variable(0);
        this.dailyFitPoints = new Variable(0);
        this.bronzeMedals = new Variable(0);
        this.silverMedals = new Variable(0);
        this.goldMedals = new Variable(0);
        this.platinumMedals = new Variable(0);

        this.postPurchaseThumbnail = new Image(); // XXX filled in later
        this.postPurchaseThumbnail.crossOrigin = 'anonymous';
        this.notEnoughCoinsIcon = new Image();
        this.notEnoughCoinsIcon.crossOrigin = 'anonymous';

        this.gender.watch(() => {
            common.avatarSelector.setAvatarType(this.gender.value, common.kioskController.configAvatarComponent, false);
        });

        this.skinColor.watch(() => {
            common.avatarSelector.setAvatarSkinColor(this.skinColor.value, common.kioskController.configAvatarComponent, false);
        });

        this.hoverboard.watch(() => {
            common.hoverboardSelector.setHoverboard(this.hoverboard.value, common.kioskController.configBoard!, true, false);
            common.hoverboardSelector.setHoverboard(this.hoverboard.value, common.kioskController.configAvatarBoard!, true, false);
        });

        this.suit.watch(() => {
            common.avatarSelector.setAvatarSuit(this.suit.value, common.kioskController.configAvatarComponent, false);
        });

        this.headwear.watch(() => {
            common.avatarSelector.setAvatarHeadwear(this.headwear.value, common.kioskController.configAvatarComponent, false);
        });

        this.hairColor.watch(() => {
            common.avatarSelector.setAvatarHairColor(this.hairColor.value, common.kioskController.configAvatarComponent, false);
        });

        this.iapContentController.inventory.watch((type, ids) => {
            if (type === ObservableItemIDCollectionEventType.Remove) {
                this.handleItemDisowning(ids);
            }
        });

        common.kioskLowerUI = this;
    }

    protected override createXMLParser() {
        const parser = super.createXMLParser();
        parser.autoRegisterFactory(Book);
        parser.autoRegisterFactory(ClickyButton);
        parser.autoRegisterFactory(PlayerList);
        parser.autoRegisterFactory(Carousel);
        parser.autoRegisterFactory(KioskBackground);
        parser.autoRegisterFactory(KioskShopPopup);
        parser.autoRegisterFactory(DecoratedButton);
        parser.autoRegisterFactory(OptionButton);
        parser.autoRegisterFactory(CustomisationButton);
        parser.autoRegisterFactory(ShopItemButton);
        parser.autoRegisterFactory(Numpad);
        parser.autoRegisterFactory(ClickyCheckbox);
        parser.autoRegisterFactory(StepperInput);
        parser.autoRegisterFactory(TimeStepperInput);
        parser.autoRegisterFactory(LocationButton);
        parser.autoRegisterFactory(KioskTabButton);
        parser.autoRegisterFactory(BackPane);
        parser.autoRegisterFactory(CustomiseTabButton);
        parser.autoRegisterFactory(CustomiseGenderButton);
        parser.autoRegisterFactory(HeaderPane);
        parser.autoRegisterFactory(EventFilterWidget);
        parser.autoRegisterFactory(Background9Slice);
        parser.autoRegisterFactory(ArrowDecoratedButton);
        parser.autoRegisterFactory(DecoratedButtonLikePane);
        parser.autoRegisterFactory(PurchaseButton);
        parser.autoRegisterFactory(PriceRow);
        parser.autoRegisterFactory(KioskItemGrid);
        parser.autoRegisterFactory(ThumbnailDecoratedButton);
        parser.autoRegisterFactory(AwardRow);
        parser.autoRegisterFactory(LifetimeLevelDisplay);
        return parser;
    }

    protected override getRootProperties() {
        return {
            ...super.getRootProperties(),
            enablePasteEvents: !BrowserUtils.isMobile(), // XXX paste event handling shows virtual keyboard on mobile, so disable it for mobile
        };
    }

    protected override getXMLParserConfig() {
        const curTracks = currentGameConfig.modeConfig.tracks;
        const trackCount = curTracks.length;
        const trackLabels: string[] = [];
        for (let track = 0; track < trackCount; track++) {
            trackLabels.push(curTracks[track].name.toUpperCase());
        }

        const makeCustomisationButton = (selectedItem: Variable<string>, id: string) => {
            return new CustomisationButton(id, this.iapContentController.getAssetRequired(id), selectedItem, this.gender);
        };

        const makeShopItemButton = (id: string) => {
            return new ShopItemButton(this.iapContentController.catalog.getRequired(id), this.showItemPage, this.gender);
        };

        return {
            ...super.getXMLParserConfig(),
            variables: {
                kioskPage: this.kioskPage,
                customPage: this.customPage,
                shopPage: this.shopPage,
                gender: this.gender,
                board: this.hoverboard,
                skinColor: this.skinColor,
                suit: this.suit,
                headwear: this.headwear,
                hairColor: this.hairColor,
                // TODO: When bundles are added as items, add the emitter
                disconnect: () => common.roomProxy.disconnect(),
                quickPlay: () => common.roomProxy.quickPlay(),
                cancelConfigurationChange: () => common.roomProxy.cancelConfigurationChange(),
                quickAction: () => common.roomProxy.quickAction(),
                hostRoom: () => common.roomProxy.hostRoom(this.roomIDVar.validValue, this.privateRoomVar.value),
                joinRoom: () => common.roomProxy.joinRoom(this.roomIDVar.validValue),
                goToTrack: () => common.menu.moveToTrack(),
                toggleTutorial: (clickEvent: ClickEvent) => common.kioskController.toggleTutorial(clickEvent.origin),
                equip: this.writeCurrentStyle.bind(this),
                currentRoomLong: new ObservableTransformer([common.roomProxy.currentRoomText], () => common.roomProxy.currentRoomText.value.toUpperCase()),
                numpadInputFilter: (inStr: string) => /^\d+$/.test(inStr),
                roomIDVar: this.roomIDVar,
                privateRoomVar: this.privateRoomVar,
                npcsAmount: currentGameConfig.npcsAmount,
                lapsAmount: currentGameConfig.lapsAmount,
                tagDuration: currentGameConfig.tagDuration,
                npcsDifficulty: currentGameConfig.npcsDifficulty,
                location: this.location,
                mode: this.mode,
                track: this.track,
                buttonHeight: BUTTON_HEIGHT,
                trackLabels,
                trackMax: trackCount - 1,
                lightShopTheme: new Theme({
                    bodyTextFill: 'black',
                }, sfTheme),
                itemPostPurchaseImage: this.postPurchaseThumbnail,
                notEnoughCoinsIcon: this.notEnoughCoinsIcon,
                lifetimeFitPoints: new ObservableTransformer([this.lifetimeFitPoints], () => `${Math.floor(this.lifetimeFitPoints.value)}`),
                dailyFitPoints: new ObservableTransformer([this.dailyFitPoints], () => `${Math.floor(this.dailyFitPoints.value)}`),
                bronzeMedals: new ObservableTransformer([this.lifetimeFitPoints], () => this.bronzeMedals.value % MEDALS_PER_TROPHY),
                silverMedals: new ObservableTransformer([this.lifetimeFitPoints], () => this.silverMedals.value % MEDALS_PER_TROPHY),
                goldMedals: new ObservableTransformer([this.lifetimeFitPoints], () => this.goldMedals.value % MEDALS_PER_TROPHY),
                platinumMedals: new ObservableTransformer([this.lifetimeFitPoints], () => this.platinumMedals.value % MEDALS_PER_TROPHY),
                bronzeTrophies: new ObservableTransformer([this.lifetimeFitPoints], () => Math.floor(this.bronzeMedals.value / MEDALS_PER_TROPHY)),
                silverTrophies: new ObservableTransformer([this.lifetimeFitPoints], () => Math.floor(this.silverMedals.value / MEDALS_PER_TROPHY)),
                goldTrophies: new ObservableTransformer([this.lifetimeFitPoints], () => Math.floor(this.goldMedals.value / MEDALS_PER_TROPHY)),
                platinumTrophies: new ObservableTransformer([this.lifetimeFitPoints], () => Math.floor(this.platinumMedals.value / MEDALS_PER_TROPHY)),
                lifetimeHelmetLevel: new ObservableTransformer([this.lifetimeFitPoints], () => this.lifetimeFitPoints.value / FP_PER_HELMET_LEVEL),
                lifetimeSuitLevel: new ObservableTransformer([this.lifetimeFitPoints], () => this.lifetimeFitPoints.value / FP_PER_SUIT_LEVEL),
                lifetimeBoardLevel: new ObservableTransformer([this.lifetimeFitPoints], () => this.lifetimeFitPoints.value / FP_PER_BOARD_LEVEL),
                fpPerBronzeMedal: `${getDailyMedalFitPointsThreshold(RewardTier.Bronze)}`,
                fpPerSilverMedal: `${getDailyMedalFitPointsThreshold(RewardTier.Silver)}`,
                fpPerGoldMedal: `${getDailyMedalFitPointsThreshold(RewardTier.Gold)}`,
                fpPerPlatinumMedal: `${getDailyMedalFitPointsThreshold(RewardTier.Platinum)}`,
                medalsPerTrophy: `${MEDALS_PER_TROPHY}x`,
                skinItemFilter: (id: string) => this.iapContentController.getAssetClass(id) === ItemCategory.Skin,
                hoverboardItemFilter: (id: string) => this.iapContentController.getAssetClass(id) === ItemCategory.Hoverboard,
                suitItemFilter: (id: string) => this.iapContentController.getAssetClass(id) === ItemCategory.Suit,
                hairItemFilter: (id: string) => {
                    const asset = this.iapContentController.getAsset(id, ItemCategory.Headwear);
                    return asset && asset.type === HeadwearSubCategory.Hair;
                },
                hairColorItemFilter: (id: string) => this.iapContentController.getAssetClass(id) === ItemCategory.HairColor,
                helmetItemFilter: (id: string) => {
                    const asset = this.iapContentController.getAsset(id, ItemCategory.Headwear);
                    return asset && asset.type === HeadwearSubCategory.Helmet;
                },
                locationItemFilter: (id: string) => this.iapContentController.getAssetClass(id) === ItemCategory.Location,
                bundleItemFilter: (id: string) => this.iapContentController.getAssetClass(id) === ItemCategory.Bundle,
                skinColorCustomItemGenerator: makeCustomisationButton.bind(this, this.skinColor),
                hoverboardCustomItemGenerator: makeCustomisationButton.bind(this, this.hoverboard),
                suitCustomItemGenerator: makeCustomisationButton.bind(this, this.suit),
                headwearCustomItemGenerator: makeCustomisationButton.bind(this, this.headwear),
                hairColorCustomItemGenerator: makeCustomisationButton.bind(this, this.hairColor),
                inventory: this.iapContentController.inventory,
                makeShopItemButton,
                catalog: this.iapContentController.catalog,
            },
        };
    }

    protected override onRootReady(root: WLRoot): void {
        // HACK this cast shouldn't be necessary. there's an issue with types in
        //      lazy-widgets-wle. FIXME in lazy-widgets-wle
        super.onRootReady(root as WLVirtualKeyboardRoot);

        const popupContainer = root.getWidgetByID("popup-container") as Background;
        const popupBook = root.getWidgetByID("popup-book") as Book;
        const comingSoonMessage = root.getWidgetByID("comingSoonMessage") as Label;

        const closePopup = () => {
            popupContainer.enabled = false;
            mainEventFilter.filterEnabled = false;
        };

        root.getWidgetByID("rewards-legend-button").on("click", () => {
            popupContainer.enabled = true;
            mainEventFilter.filterEnabled = true;
            popupBook.changePage(PopupPage.RewardsLegend);
        });
        root.getWidgetByID("rewardsLegendCloseButton").on("click", closePopup);

        root.getWidgetByID("comingSoonCancelButton").on("click", closePopup);
        root.getWidgetByID("comingSoonCloseButton").on("click", closePopup);

        const showComingSoonPopup = (message: string) => {
            comingSoonMessage.text = message;
            popupContainer.enabled = true;
            mainEventFilter.filterEnabled = true;
            popupBook.changePage(PopupPage.ComingSoon);
        };

        const showRewardsEquipComingSoon = () => {
            showComingSoonPopup("Rewards equipment is not available yet");
        };
        root.getWidgetByID("helmetLevelDisplay").on("click", showRewardsEquipComingSoon);
        root.getWidgetByID("suitLevelDisplay").on("click", showRewardsEquipComingSoon);
        root.getWidgetByID("boardLevelDisplay").on("click", showRewardsEquipComingSoon);

        const onClickLockedMode = () => {
            showComingSoonPopup("This mode is not available yet");
            this.mode.value = currentGameConfig.mode;
        };

        const onConfigChange = () => {
            const location = this.location.value!;
            const locConfig = VALID_CONFIGURATIONS.get(location)!;
            const mode = this.mode.value!;
            const modeConfig = locConfig.modes.get(mode)!;
            const track = modeConfig.defaultTrack;
            const trackConfig = modeConfig.tracks[track]!;

            AnalyticsUtils.sendEvent("change_map");
            AnalyticsUtils.sendEvent("change_map_" + trackConfig.map);
            const newGameConfig = new HoverboardGameConfig();
            newGameConfig.location = location;
            newGameConfig.mode = mode;
            newGameConfig.track = track;

            if (location !== currentGameConfig.location && !isValidConfiguration(newGameConfig, Globals.isDebugEnabled() && HoverboardDebugs.unlockAllKioskUI)) {
                // not a valid config. maybe the mode is locked/unavailable?
                // switch to default mode
                newGameConfig.mode = locConfig.defaultMode;
                newGameConfig.track = locConfig.defaultModeConfig.defaultTrack;
            }

            if (!newGameConfig.matches(currentGameConfig)) {
                common.menu.changeGameConfig(new RoomData(currentRoomData), newGameConfig);
            }
        };

        const modeButtons = new Map<GameMode, OptionButton>([
            [GameMode.Race, root.getWidgetByID("race-mode-button") as OptionButton],
            [GameMode.Tag, root.getWidgetByID("tag-mode-button") as OptionButton],
            [GameMode.Roam, root.getWidgetByID("roam-mode-button") as OptionButton],
        ]);

        for (const [gameMode, button] of modeButtons) {
            const locConfig = currentGameConfig.locationConfig;
            const modeConfig = locConfig.modes.get(gameMode)!;
            let locked = true;

            for (const trackConfig of modeConfig.tracks) {
                if (!trackConfig.locked) {
                    locked = false;
                    break;
                }
            }

            if (Globals.isDebugEnabled() && HoverboardDebugs.unlockAllKioskUI) {
                locked = false;
            }

            if (locked) {
                button.badge = DecoratedButtonBadge.Lock;
                button.on("click", onClickLockedMode);
            } else {
                button.on("click", onConfigChange);
            }
        }

        const locationRow = root.getWidgetByID("location-row") as Row;
        for (const location of Array.from(VALID_CONFIGURATIONS.keys()).sort()) {
            const locationButton = new LocationButton(location, this.location);
            locationButton.on("click", onConfigChange);

            locationRow.add(locationButton);
        }

        this.location.watch(() => {
            for (const button of modeButtons.values()) button.clickable = false;

            let hasSameModeAsCurrent = false;
            let firstClickableMode: GameMode | null = null;
            if (this.location.value !== null) {
                const locConfig = VALID_CONFIGURATIONS.get(this.location.value);
                if (locConfig) {
                    for (const mode of locConfig.modes.keys()) {
                        const button = modeButtons.get(mode)!;
                        button.clickable = true;

                        if (firstClickableMode == null) {
                            firstClickableMode = mode;
                        }

                        if (mode == this.mode.value) {
                            hasSameModeAsCurrent = true;
                        }
                    }
                }
            }

            if (!hasSameModeAsCurrent) {
                this.mode.value = firstClickableMode;
            }
        }, true);

        this.track.watch((_source, group) => {
            if (group === "net-sync") return;

            const location = currentGameConfig.location;
            const locConfig = VALID_CONFIGURATIONS.get(location)!;
            const mode = currentGameConfig.mode!;
            const modeConfig = locConfig.modes.get(mode)!;
            const track = this.track.value!;
            const trackConfig = modeConfig.tracks[track]!;

            AnalyticsUtils.sendEvent("change_map");
            AnalyticsUtils.sendEvent("change_map_" + trackConfig.map);
            const newGameConfig = new HoverboardGameConfig();
            newGameConfig.location = location;
            newGameConfig.mode = mode;
            newGameConfig.track = track;
            common.menu.changeGameConfig(new RoomData(currentRoomData), newGameConfig);
        });

        this.location.value = currentGameConfig.location;
        this.mode.value = currentGameConfig.mode;
        this.track.value = currentGameConfig.track;

        const cBook = root.getWidgetByID("custom-book") as Book;
        const sBook = root.getWidgetByID("shop-book") as Book;
        const siBook = root.getWidgetByID("shop-or-item-book") as Book;
        const mainEventFilter = root.getWidgetByID("main-event-filter") as EventFilterWidget;
        const purchaseItemButton = root.getWidgetByID("purchaseItemButton") as PurchaseButton;
        const hairBook = root.getWidgetByID("hair-book") as Book;
        const pickModeBook = root.getWidgetByID("pick-mode-book") as Book;
        const hairToggle = root.getWidgetByID("hairToggle") as ThumbnailDecoratedButton;
        const styleToggle = root.getWidgetByID("styleToggle") as ThumbnailDecoratedButton;

        const hairBookVar = new Variable(0);
        hairBookVar.watch(() => {
            hairBook.changePage(hairBookVar.value);
            pickModeBook.changePage(hairBookVar.value);
        });
        hairToggle.on("click", () => { hairBookVar.value = 1; });
        styleToggle.on("click", () => { hairBookVar.value = 0; });

        const resetShopSelection = () => {
            this.readCurrentStyle();
            this.showItemPage.value = "";
            sBook.changePage(this.shopPage.value);
            closePopup();
        };

        const kBook = root.getWidgetByID("kiosk-book") as Book;
        kBook.changePage(this.kioskPage.value);

        let queuedPageSwitch = 0;
        root.getWidgetByID("changeEquipmentRevertButton").on("click", () => {
            this.readCurrentStyle();
            this.kioskPage.value = queuedPageSwitch;
        });
        root.getWidgetByID("changeEquipmentKeepButton").on("click", () => {
            this.writeCurrentStyle();
            this.kioskPage.value = queuedPageSwitch;
        });
        root.getWidgetByID("changeEquipmentCloseButton").on("click", closePopup);

        this.kioskPage.watch(() => {
            if (this.kioskPage.value === this.previousKioskPageNumber) return;

            if (this.previousKioskPageNumber === 3) {
                const dirty = this.gender.value !== currentPlayerData.avatarType
                    || this.hoverboard.value !== currentPlayerData.hoverboardVariant
                    || this.skinColor.value !== currentPlayerData.skinColor
                    || this.suit.value !== currentPlayerData.suitVariant
                    || this.headwear.value !== currentPlayerData.headwearVariant
                    || this.hairColor.value !== currentPlayerData.hairColor;

                if (dirty) {
                    queuedPageSwitch = this.kioskPage.value;
                    this.kioskPage.value = this.previousKioskPageNumber;
                    popupContainer.enabled = true;
                    mainEventFilter.filterEnabled = true;
                    popupBook.changePage(PopupPage.ChangeEquipment);
                    return;
                }
            }

            kBook.changePage(this.kioskPage.value);

            if (this.kioskPage.value > 2) {
                this.readCurrentStyle();
            }
            // Close tutorial if open
            if (this.kioskPage.value !== 2 && common.kioskController.tutorialActive) common.kioskController.setTutorialActive(false);

            // Fade between menu and balcony music
            if (this.previousKioskPageNumber == 4) {
                const balconyMusicAudio = common.audioManager.getAudio(AudioID.BALCONY_MUSIC);
                balconyMusicAudio!.fade(0.0, balconyMusicAudio!.getDefaultVolume(), 0.8);
                const shopMusicAudio = common.audioManager.getAudio(AudioID.SHOP_MUSIC);
                shopMusicAudio!.fade(shopMusicAudio!.getVolume(), 0.0, 0.8);
            } else if (this.kioskPage.value == 4) {
                const balconyMusicAudio = common.audioManager.getAudio(AudioID.BALCONY_MUSIC);
                balconyMusicAudio!.fade(balconyMusicAudio!.getVolume(), 0.0, 0.8);
                const shopMusicAudio = common.audioManager.getAudio(AudioID.SHOP_MUSIC);
                shopMusicAudio!.fade(0.0, shopMusicAudio!.getDefaultVolume(), 0.8);
            }

            // reset shop
            resetShopSelection();

            // Check if top page should change
            if ((this.previousKioskPageNumber > 2 && this.kioskPage.value > 2) || (this.previousKioskPageNumber < 3 && this.kioskPage.value < 3)) {
                this.previousKioskPageNumber = this.kioskPage.value;
                return;
            }

            if (this.kioskPage.value > 2) {
                this.readCurrentStyle();
                common.kioskController.setHoloDisplayExternalReactivation(() => {
                    common.kioskUpperUI.changeMode(UpperUIMode.CustomisationPreview);
                    common.kioskController.setConfigAvatarActive(true);
                    common.kioskController.setConfigBoardActive(true);
                });
            } else {
                if (this.previousKioskPageNumber > 2) {
                    common.kioskController.setHoloDisplayExternalReactivation(() => {
                        this.readCurrentStyle();
                        common.kioskUpperUI.changeMode(UpperUIMode.Leaderboard);
                        common.kioskController.setConfigAvatarActive(false);
                        common.kioskController.setConfigBoardActive(false);
                    });
                }
            }

            this.previousKioskPageNumber = this.kioskPage.value;
        }, true);

        this.customPage.watch(() => {
            pickModeBook.enabled = this.customPage.value === 3;
            cBook.changePage(this.customPage.value);
        }, true);
        this.shopPage.watch(resetShopSelection, true);
        this.showItemPage.watch(() => siBook.changePage(this.showItemPage.value !== "" ? 1 : 0));

        const mpBook = root.getWidgetByID("multiplayer-book") as Book;
        common.roomProxy.watch((rp) => {
            const state = rp.value;
            mpBook.changePage(state);
        }, true);

        root.getWidgetByID("hostRoomButton").on("click", () => {
            mpBook.changePage(MultiplayerBookSpecialPage.HostNumpad);
        });

        root.getWidgetByID("joinRoomButton").on("click", () => {
            mpBook.changePage(MultiplayerBookSpecialPage.JoinNumpad);
        });

        root.getWidgetByID("cancelHostButton").on("click", () => {
            mpBook.changePage(MultiplayerBookSpecialPage.Start);
        });

        root.getWidgetByID("cancelJoinButton").on("click", () => {
            mpBook.changePage(MultiplayerBookSpecialPage.Start);
        });

        root.getWidgetByID("itemBackButton").on("click", resetShopSelection);

        purchaseItemButton.on("click", () => {
            // TODO: Open confirmation page and move function call there
            popupContainer.enabled = true;
            mainEventFilter.filterEnabled = true;
            popupBook.changePage(PopupPage.PurchaseConfirmation);
        });

        root.getWidgetByID("itemPurchaseCancelButton").on("click", closePopup);
        root.getWidgetByID("itemPostPurchaseCancelButton").on("click", closePopup);
        root.getWidgetByID("itemPurchaseCloseButton").on("click", closePopup);
        root.getWidgetByID("itemPostPurchaseCloseButton").on("click", closePopup);
        root.getWidgetByID("itemFailPurchaseCancelButton").on("click", closePopup);
        root.getWidgetByID("itemFailPurchaseCloseButton").on("click", closePopup);
        root.getWidgetByID("notEnoughCoinsCancelButton").on("click", closePopup);
        root.getWidgetByID("notEnoughCoinsCloseButton").on("click", closePopup);

        const itemPostPurchaseEquipButton = root.getWidgetByID("itemPostPurchaseEquipButton");
        itemPostPurchaseEquipButton.on("click", () => {
            const id = this.showItemPage.value;
            switch (this.iapContentController.getAssetClass(id)) {
                case ItemCategory.Hoverboard:
                    this.hoverboard.value = id;
                    break;
                case ItemCategory.Suit:
                    this.suit.value = id;
                    break;
                case ItemCategory.Headwear:
                    this.headwear.value = id;
                    break;
                case ItemCategory.HairColor:
                    this.hairColor.value = id;
                    break;
                default:
                    break;
            }
            this.writeCurrentStyle();
            closePopup();
        });

        const doRoomJoinButton = root.getWidgetByID("doRoomJoinButton") as DecoratedButton;
        const updateClickable = () => {
            doRoomJoinButton.clickable = this.roomIDVar.valid && this.roomIDVar.validValue !== null;
        };
        updateClickable();
        this.roomIDVar.watch(updateClickable);

        this.toTrackButton = root.getWidgetByID("toTrackButton") as DecoratedButton;
        this.toTrackButton.child.text = `Start ${currentGameConfig.fancyMode}`.toUpperCase();
        this.toTrackButtonMP = root.getWidgetByID("toTrackButtonMP") as DecoratedButton;
        this.toTrackButtonMP.child.text = `Start ${currentGameConfig.fancyMode}`.toUpperCase();

        this.npcsAmountInput = root.getWidgetByID("npcsAmountInput") as StepperInput;
        this.lapsAmountInput = root.getWidgetByID("lapsAmountInput") as StepperInput;
        this.tagDurationInput = root.getWidgetByID("tagDurationInput") as TimeStepperInput;
        this.npcsDifficultyInput = root.getWidgetByID("npcsDifficultyInput") as StepperInput;

        if (!(Globals.isDebugEnabled() && HoverboardDebugs.unlockAllKioskUI)) {
            const trackInput = root.getWidgetByID("trackInput") as StepperInput;
            trackInput.lockMin = 1;
            trackInput.lockCallback = showComingSoonPopup.bind(this, "Other tracks not available yet");
            this.npcsAmountInput.lockMin = 3;
            this.npcsAmountInput.lockCallback = showComingSoonPopup.bind(this, "More NPCs not available yet");
            this.lapsAmountInput.lockMin = 4;
            this.lapsAmountInput.lockCallback = showComingSoonPopup.bind(this, "More laps not available yet");
            this.tagDurationInput.locked = true;
            this.tagDurationInput.lockCallback = showComingSoonPopup.bind(this, "Different tag durations not available yet");
            this.npcsDifficultyInput.lockMin = 3;
            this.npcsDifficultyInput.lockCallback = showComingSoonPopup.bind(this, "Higher difficulties not available yet");
        }

        const postPurchasePageLabel = root.getWidgetByID("itemPostPurchaseLabel") as Label;
        const purchaseItemConfirmButton = root.getWidgetByID("purchaseItemConfirmButton") as DecoratedButton;
        const transactionFailureMessage = root.getWidgetByID("transactionFailureMessage") as Label;
        const notEnoughCoinsMessage = root.getWidgetByID("notEnoughCoinsMessage") as Label;
        const origPurchaseButtonLabel = purchaseItemConfirmButton.child.text;
        purchaseItemConfirmButton.on("click", () => {
            purchaseItemConfirmButton.clickable = false;
            purchaseItemConfirmButton.child.text = "PURCHASING...";

            const handleFailure = (err?: GenericPurchaseError) => {
                if (err && (err instanceof NoFundsPurchaseError)) {
                    notEnoughCoinsMessage.text = `${["HEYVR COINS", "FITABUX", "FITPOINTS"][err.currencyType]}`;
                    this.notEnoughCoinsIcon.src = CURRENCY_ICONS[err.currencyType];
                    popupBook.changePage(PopupPage.PurchaseFailureNoMoney);
                } else {
                    transactionFailureMessage.text = err?.message ?? 'Unknown error occurred';
                    popupBook.changePage(PopupPage.PurchaseFailureGeneric);
                }
            };

            const item = this.iapContentController.catalog.get(this.showItemPage.value);
            item?.purchase().then((success) => {
                if (!success) {
                    handleFailure(undefined);
                    return;
                }
                const itemName = item.name;
                postPurchasePageLabel.text = itemName.toUpperCase();
                this.postPurchaseThumbnail.src = getAssetThumbnail(item.asset, this.gender.value);
                siBook.changePage(0);
                popupBook.changePage(PopupPage.PurchaseSuccess);
                itemPostPurchaseEquipButton.enabled = isItemCategoryEquippable(item.itemCategory);
                common.audioManager.getAudio(AudioID.PURCHASE)!.play();
            }).catch((e) => {
                handleFailure(e);
            }).finally(() => {
                purchaseItemConfirmButton.clickable = true;
                purchaseItemConfirmButton.child.text = origPurchaseButtonLabel;
            });
        });

        // Set item page labels
        const itemNameLabel = root.getWidgetByID("itemNameLabel") as Label;
        const itemDescLabel = root.getWidgetByID("itemDescLabel") as Label;
        const itemBuyConfirmLabel = root.getWidgetByID("itemBuyConfirmLabel") as Label;
        const shopGenderRow = root.getWidgetByID("shop-gender-row");
        const shopGenderPlacebo = root.getWidgetByID("shop-gender-placebo");
        const popupPriceRow = root.getWidgetByID("popup-price-row") as PriceRow;

        this.showItemPage.watch(() => {
            const id = this.showItemPage.value;
            if (!id) return;

            const item = this.iapContentController.catalog.getRequired(id);
            const itemName = item!.name;
            const [effectivePrice, discounted] = getEffectivePrice(item!.price, item!.priceDiscounted);

            let showGenderRow = false;
            switch (item!.itemCategory) {
                case ItemCategory.Hoverboard:
                    this.hoverboard.value = item!.id;
                    break;
                case ItemCategory.Suit:
                    this.suit.value = item!.id;
                    showGenderRow = true;
                    break;
                case ItemCategory.Headwear:
                    if (this.iapContentController.getAsset(id, ItemCategory.Headwear)?.type === HeadwearSubCategory.Hair) {
                        showGenderRow = true;
                    }
                    this.headwear.value = item!.id;
                    break;
                case ItemCategory.HairColor:
                    this.hairColor.value = item!.id;
                    break;
                default:
                    break;
            }

            shopGenderRow.enabled = showGenderRow;
            shopGenderPlacebo.enabled = !showGenderRow;

            itemNameLabel.text = itemName;
            itemDescLabel.text = item?.description ?? 'No item description';
            purchaseItemButton.price = effectivePrice;
            purchaseItemButton.discounted = discounted;
            purchaseItemButton.currency = item?.currencyType ?? ItemCurrency.HeyVR; // TODO don't
            popupPriceRow.price = effectivePrice;
            popupPriceRow.currency = item?.currencyType ?? ItemCurrency.HeyVR;
            itemBuyConfirmLabel.text = itemName.toUpperCase();
        });
    }

    protected override beforeWidgetUpdate(_root: WLVirtualKeyboardRoot, _dt: number): boolean | void {
        this.toTrackButton.clickable = canJoinTrack();
        this.toTrackButtonMP.clickable = canJoinTrack();
        const allowChange = common.menu.getPlayersOnTrack(true) === 0;
        const allowChangeNPCs = allowChange && currentGameConfig.canHaveNPCs;
        this.npcsAmountInput.clickable = allowChangeNPCs;
        this.lapsAmountInput.enabled = currentGameConfig.mode != GameMode.Tag;
        this.lapsAmountInput.clickable = allowChange && currentGameConfig.mode == GameMode.Race;
        this.tagDurationInput.enabled = currentGameConfig.mode == GameMode.Tag;
        this.tagDurationInput.clickable = allowChange;
        this.npcsDifficultyInput.clickable = allowChangeNPCs;
        this.track.setValue(currentGameConfig.track, "net-sync");
        this.lifetimeFitPoints.value = currentPlayerData.totalFitPoints;
        this.dailyFitPoints.value = currentPlayerData.dailyFitPoints;
        this.bronzeMedals.value = currentPlayerData.bronzeMedals;
        this.silverMedals.value = currentPlayerData.silverMedals;
        this.goldMedals.value = currentPlayerData.goldMedals;
        this.platinumMedals.value = currentPlayerData.platinumMedals;
    }

    protected override getXMLContent(): string {
        return xmlContent;
    }

    override onActivate(): void {
        super.onActivate();
    }

    override onDeactivate(): void {
        super.onDeactivate();
    }

    readCurrentStyle() {
        if (!this.iapContentController.ready) return;

        this.gender.value = currentPlayerData.avatarType;
        this.hoverboard.value = currentPlayerData.hoverboardVariant;
        this.skinColor.value = currentPlayerData.skinColor;
        this.suit.value = currentPlayerData.suitVariant;
        this.headwear.value = currentPlayerData.headwearVariant;
        this.hairColor.value = currentPlayerData.hairColor;
    }

    private handleItemDisowning(removedIDs: readonly string[]) {
        if (removedIDs.indexOf(currentPlayerData.skinColor) >= 0) {
            const wasPicked = currentPlayerData.skinColor === this.skinColor.value;
            currentPlayerData.resetSkinColor();
            if (wasPicked) this.skinColor.value = currentPlayerData.skinColor;
            common.avatarSelector.setAvatarSkinColor(currentPlayerData.skinColor, currentPlayerData.avatar, true);
        }

        if (removedIDs.indexOf(currentPlayerData.hoverboardVariant) >= 0) {
            const wasPicked = currentPlayerData.hoverboardVariant === this.hoverboard.value;
            currentPlayerData.resetHoverboard();
            if (wasPicked) this.hoverboard.value = currentPlayerData.hoverboardVariant;
            common.hoverboardSelector.setHoverboard(currentPlayerData.hoverboardVariant, common.hoverboard.hoverboardMeshObject, false, true);
        }

        if (removedIDs.indexOf(currentPlayerData.suitVariant) >= 0) {
            const wasPicked = currentPlayerData.suitVariant === this.suit.value;
            currentPlayerData.resetSuit();
            if (wasPicked) this.suit.value = currentPlayerData.suitVariant;
            common.avatarSelector.setAvatarSuit(currentPlayerData.suitVariant, currentPlayerData.avatar, true);
        }

        if (removedIDs.indexOf(currentPlayerData.headwearVariant) >= 0) {
            const wasPicked = currentPlayerData.headwearVariant === this.headwear.value;
            currentPlayerData.resetHeadwear();
            if (wasPicked) this.headwear.value = currentPlayerData.headwearVariant;
            common.avatarSelector.setAvatarHeadwear(currentPlayerData.headwearVariant, currentPlayerData.avatar, true);
        }

        if (removedIDs.indexOf(currentPlayerData.hairColor) >= 0) {
            const wasPicked = currentPlayerData.hairColor === this.hairColor.value;
            currentPlayerData.resetHairColor();
            if (wasPicked) this.hairColor.value = currentPlayerData.hairColor;
            common.avatarSelector.setAvatarHairColor(currentPlayerData.hairColor, currentPlayerData.avatar, true);
        }
    }

    private writeCurrentStyle() {
        if (currentPlayerData.avatarType !== this.gender.value && this.gender.value !== undefined) {
            currentPlayerData.avatarType = this.gender.value;
            common.avatarSelector.setAvatarType(currentPlayerData.avatarType, currentPlayerData.avatar, true);
            AnalyticsUtils.sendEventOnce("change_avatar_gender");
            AnalyticsUtils.sendEventOnce("change_avatar_gender_" + currentPlayerData.avatarType);
        }

        if (currentPlayerData.skinColor !== this.skinColor.value && this.skinColor.value) {
            currentPlayerData.skinColor = this.skinColor.value;
            common.avatarSelector.setAvatarSkinColor(currentPlayerData.skinColor, currentPlayerData.avatar, true);
            AnalyticsUtils.sendEventOnce("change_skin_color");
            AnalyticsUtils.sendEventOnce("change_skin_color_" + currentPlayerData.skinColor);
        }

        if (currentPlayerData.hoverboardVariant !== this.hoverboard.value && this.hoverboard.value) {
            currentPlayerData.hoverboardVariant = this.hoverboard.value;
            common.hoverboardSelector.setHoverboard(currentPlayerData.hoverboardVariant, common.hoverboard.hoverboardMeshObject, false, true);
            AnalyticsUtils.sendEventOnce("change_hoverboard_skin");
            AnalyticsUtils.sendEventOnce("change_hoverboard_skin_" + currentPlayerData.hoverboardVariant);
        }

        if (currentPlayerData.suitVariant !== this.suit.value && this.suit.value) {
            currentPlayerData.suitVariant = this.suit.value;
            common.avatarSelector.setAvatarSuit(currentPlayerData.suitVariant, currentPlayerData.avatar, true);
            AnalyticsUtils.sendEventOnce("change_suit_color");
            AnalyticsUtils.sendEventOnce("change_suit_color_" + currentPlayerData.suitVariant);
        }

        if (currentPlayerData.headwearVariant !== this.headwear.value && this.headwear.value) {
            currentPlayerData.headwearVariant = this.headwear.value;
            common.avatarSelector.setAvatarHeadwear(currentPlayerData.headwearVariant, currentPlayerData.avatar, true);
            AnalyticsUtils.sendEventOnce("change_headwear");
            AnalyticsUtils.sendEventOnce("change_headwear_" + currentPlayerData.headwearVariant);
        }

        if (currentPlayerData.hairColor !== this.hairColor.value && this.hairColor.value) {
            currentPlayerData.hairColor = this.hairColor.value;
            common.avatarSelector.setAvatarHairColor(currentPlayerData.hairColor, currentPlayerData.avatar, true);
            AnalyticsUtils.sendEventOnce("change_hair_color");
            AnalyticsUtils.sendEventOnce("change_hair_color_" + currentPlayerData.hairColor);
        }

        currentPlayerData.savePlayerData();
    }
}
