import { Component } from '@wonderlandengine/api';
import { PickupSpring, PickupSpringParams } from '../implementations/pickup-spring.js';
import { PickupsManager } from '../pickups-manager.js';
import { PickupComponent } from './pickup-component.js';

export class PickupSpringComponent extends Component implements PickupComponent {
    static TypeName = "pickup-spring";

    static onRegister() {
        PickupsManager.getPickupComponentTypes().pp_pushUnique(PickupSpringComponent);
    }

    createPickup(): PickupSpring {
        const params = new PickupSpringParams();
        return new PickupSpring(this.object, params);
    }
}