import { AudioVolumeMixer } from "./audio-volume-mixer.js";
import { Audio } from "./audio.js";
import { SourceAudio, getAudioFromSourceAudio } from "./source-audio.js";

export enum AudioChannelName {
    VOICE_OVERS = "voice_overs",
    MUSIC = "music",
    MUSIC_ALMOST_QUIET = "music_almost_quiet",
    MUSIC_QUIET = "music_quiet",
    AMBIENT = "ambient",
    INSTRUCTIONS = "instructions",
    SFX = "sfx",
    SFX_QUIET = "sfx_quiet",
    VOIP = "voip"
}

export class AudioChannel {

    private _audios = new Map<string, Audio>();
    private _volumeMixer: AudioVolumeMixer = new AudioVolumeMixer();

    getVolumeMixer(): AudioVolumeMixer {
        return this._volumeMixer;
    }

    stop() {
        for (const audio of this._audios.values()) {
            audio.stop();
        }
    }

    addSourceAudio(audioID: string, sourceAudio: SourceAudio): void {
        if (!this.hasAudio(audioID)) {
            const audio = getAudioFromSourceAudio(sourceAudio);
            this._audios.set(audioID, audio);

            this._volumeMixer.attachAudio(audio);
        }
    }

    hasAudio(audioID: string): boolean {
        return this._audios.has(audioID);
    }

    getAudio(audioID: string): Audio | null {
        const audio = this._audios.get(audioID);
        return audio ? audio : null;
    }

    removeAudio(audioID: string): boolean {
        return this._audios.delete(audioID);
    }
}