import { Material, type MeshComponent, type WonderlandEngine } from "@wonderlandengine/api";
import { vec4 } from "gl-matrix";
import { vec4_create, Vec4Utils } from "wle-pp";
import common from "../common.js";
import { CancellablePromise, type CancelledMarker } from "../utils/cancellable-promise.js";
import { type AssetMaterial, type MaterialInterface } from "./asset-provision/asset-manifest-types.js";

const WHITE = vec4_create(1.0, 1.0, 1.0, 1.0);
const CONFIG_DIFFUSE = vec4_create(0.6, 0.6, 0.6, 1.0);
const DEFAULT_EMISSIVE = vec4_create(0.5, 0.5, 0.5, 1.0);
const TEMP_COLOR = vec4_create(0.4, 0.4, 0.4, 1.0);

export function setMaterialFromMaterialConfig(meshComponent: MeshComponent, configObject: MaterialInterface, isConfigBoard: boolean, engine: WonderlandEngine, cancelMarker: CancelledMarker) {
    const localRes = common.hoverfitSceneResources;
    const newMaterial = new Material(engine, { pipeline: configObject.pipeline }) as AssetMaterial;
    meshComponent.material = newMaterial;
    if (configObject.pipeline === "Phong Opaque Textured" || configObject.pipeline === "Phong Transparent Textured") {
        if (configObject.diffuseTexture) {
            CancellablePromise.wrapPromise(localRes.getTextureFromURL(configObject.diffuseTexture)).then((tex) => {
                newMaterial.diffuseTexture = tex;
            }).catch(CancellablePromise.ignoreCancel);
        }
        if (isConfigBoard) {
            setMaterialLightEmissiveDiffuseColor(newMaterial, null);
        } else {
            newMaterial.diffuseColor = WHITE as number[];
        }
    } else if (configObject.pipeline === "Phong Normalmapped") {
        if (configObject.diffuseTexture) {
            CancellablePromise.wrapPromise(localRes.getTextureFromURL(configObject.diffuseTexture)).then((tex) => {
                newMaterial.diffuseTexture = tex;
            }).catch(CancellablePromise.ignoreCancel);
        }
        if (configObject.normalTexture) {
            CancellablePromise.wrapPromise(localRes.getTextureFromURL(configObject.normalTexture)).then((tex) => {
                newMaterial.normalTexture = tex;
            }).catch(CancellablePromise.ignoreCancel);
        }
        if (isConfigBoard) {
            setMaterialLightEmissiveDiffuseColor(newMaterial, null);
        } else {
            newMaterial.diffuseColor = WHITE as number[];
        }
    } else if (configObject.pipeline === "Phong Opaque Textured Emissive") {
        if (configObject.diffuseTexture) {
            CancellablePromise.wrapPromise(localRes.getTextureFromURL(configObject.diffuseTexture)).then((tex) => {
                newMaterial.diffuseTexture = tex;
            }).catch(CancellablePromise.ignoreCancel);
        }
        if (configObject.emissiveTexture) {
            CancellablePromise.wrapPromise(localRes.getTextureFromURL(configObject.emissiveTexture)).then((tex) => {
                newMaterial.emissiveTexture = tex;
                if (!configObject.emissiveColor) {
                    newMaterial.emissiveColor = DEFAULT_EMISSIVE as number[];
                }
            }).catch(CancellablePromise.ignoreCancel);
        }
        if (isConfigBoard) {
            setMaterialLightEmissiveDiffuseColor(newMaterial, null);
        } else {
            newMaterial.diffuseColor = WHITE as number[];
        }

        if (configObject.diffuseColor) {
            newMaterial.diffuseColor = configObject.diffuseColor;
        }
        if (configObject.emissiveColor) {
            newMaterial.emissiveColor = configObject.emissiveColor;
        }
        if (configObject.ambientColor) {
            newMaterial.ambientColor = configObject.ambientColor;
        }
    } else if (configObject.pipeline === "Phong Opaque") {
        if (isConfigBoard) {
            setMaterialLightEmissiveDiffuseColor(newMaterial, configObject.diffuseColor as vec4);
        } else {
            newMaterial.diffuseColor = configObject.diffuseColor;
        }
    } else if (configObject.pipeline === "Flat Opaque") {
        newMaterial.color = configObject.color;
    } else {
        console.warn(`Unknown pipeline "${configObject.pipeline}"`);
    }
}

export function setMaterialLightEmissiveDiffuseColor(material: AssetMaterial, colorOverride: vec4 | null) {
    let color;
    if (colorOverride) {
        Vec4Utils.copy(colorOverride, TEMP_COLOR);
        vec4.scale(TEMP_COLOR as vec4, TEMP_COLOR as vec4, 0.85);
        color = TEMP_COLOR as number[];
    } else {
        color = CONFIG_DIFFUSE as number[];
    }

    material.diffuseColor = color;
    material.ambientColor = WHITE as number[];
}

const STANDARD_DIFFUSE = vec4_create(0.75, 0.75, 0.75, 1.0) as number[];
const STANDARD_AMBIENT = vec4_create(0.25, 0.25, 0.25, 1.0) as number[];

export function setMaterialStandardEmissiveDiffuseColor(material: AssetMaterial) {
    material.diffuseColor = STANDARD_DIFFUSE;
    material.ambientColor = STANDARD_AMBIENT;
}