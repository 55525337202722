import { Audio } from "../audio.js";
import { SourceAudio, SourceAudioType, getSourceAudioType } from "../source-audio.js";

export abstract class TypedAudio<T extends SourceAudio> extends Audio {

    private _sourceAudio: T;

    constructor(sourceAudio: T) {
        super();

        this._sourceAudio = sourceAudio;
    }

    getSourceAudio(): SourceAudio {
        return this._sourceAudio;
    }

    getTypedSourceAudio(): T {
        return this._sourceAudio;
    }

    getSourceAudioType(): SourceAudioType {
        return getSourceAudioType(this.getSourceAudio());
    }
}