import { Theme } from "lazy-widgets";

export const sfTheme = new Theme({
    primaryFill: "rgb(227, 229, 241)",
    accentFill: "rgb(176, 177, 210)",
    radioButtonLength: 18,
    radioButtonInnerPadding: 3,
    checkboxLength: 18,
    checkboxInnerPadding: 3,
    scrollBarThickness: 12,
    scrollBarMinPixels: 30,
    bodyTextFont: "1em sui-generis",
    inputTextFont: "1em sui-generis",
    bodyTextFill: "white",
});

export const sfThemeSmaller = new Theme({
    bodyTextFont: "0.75em sui-generis",
    inputTextFont: "0.75em sui-generis",
}, sfTheme);