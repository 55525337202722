import { Component, Property } from "@wonderlandengine/api";
import { currentPlayerData } from "src/hoverfit/data/player-data.js";
import { AnalyticsUtils, Globals } from "wle-pp";
import common from "../../../common.js";
import { currentGameConfig } from "../../../data/game-configuration.js";

// TODO ~~nuke this to smithereens~~ actually, don't!:
// - move this out of the kiosk folder
// - make this not have any relationship with kiosk
// - make this component listenable (add addEventListener/removeEventListener methods)
// - make kiosk depend on this component, instead of this component depend on kiosk; use aforementioned event listeners

export class LeaderboardComponent extends Component {
    static TypeName = "leaderboard-component";
    static Properties = {
        amountScoresDisplayed: Property.int(10),
    };

    start() {
        common.leaderboard = this;
        this.externalData = { scores: [] };

        this.clearOnNextAddEntryFlag = false;

        common.kioskUpperUI.setLastRaceTime(null, null);

        this.getLeaderboard();

        common.MAIN_CHANNEL.on("room-init-start", this.getLeaderboard.bind(this));
        common.MAIN_CHANNEL.on("room-leave", this.getLeaderboard.bind(this));

        // for (let i = 0; i < 20; i++) { this.addExternalData({ name: `player ${i}`, finishTime: 1000 + i * 10 }); } this.displayLeaderboard();
    }

    setHidden(b) {
        this.title.active = !b;
        this.firstColumn.active = !b;
        this.secondColumn.active = !b;
        this.thirdColumn.active = !b;
    }

    clearDisplay() {
        this.displayLeaderboard([]);
    }

    submitToHeyVR(finishTime, bestLapTime = -1) {
        let isNPCRace = common.menu.currentNPCs.length > 0;

        const score = Math.floor(((bestLapTime > -1) ? bestLapTime : finishTime) * 1000);
        if (window.heyVR) {
            const leaderboardID = currentGameConfig.location + "-" + currentGameConfig.mode + "-" + currentGameConfig.trackConfig.id + "-fastest-lap";
            if (!currentPlayerData.isGuest) {
                window.heyVR.leaderboard.postScore(leaderboardID, score).then((e) => {
                    AnalyticsUtils.sendEvent("learderboard_score_submit");

                    if (!common.hoverboardNetworking.room && !isNPCRace) {
                        this.getLeaderboard(); // only update the leaderboard if not online, online we want a room local leaderboard
                    }
                }).catch((err) => {
                    console.error(err);
                });
            } else {
                currentPlayerData.addPendingLeaderboardScore(leaderboardID, score);
            }
        }

        common.kioskUpperUI.setLastRaceTime(finishTime, bestLapTime);
    }

    getLeaderboard() {
        if (window.heyVR) {
            const leaderboardID = currentGameConfig.location + "-" + currentGameConfig.mode + "-" + currentGameConfig.trackConfig.id + "-fastest-lap";
            window.heyVR.leaderboard.get(leaderboardID, this.amountScoresDisplayed).then((leaderboard) => {
                this.clearExternalData();

                let lastValidLeaderboardEntry = null;
                for (const entry of leaderboard) {
                    if (entry == null && lastValidLeaderboardEntry != null && Globals.isDebugEnabled()) {
                        this.addExternalDataHeyVR({
                            rank: lastValidLeaderboardEntry.rank,
                            name: lastValidLeaderboardEntry.user,
                            finishTime: lastValidLeaderboardEntry.score / 1000,
                        });
                    } else if (entry != null) {
                        this.addExternalDataHeyVR({
                            rank: entry.rank,
                            name: entry.user,
                            finishTime: entry.score / 1000,
                        });

                        lastValidLeaderboardEntry = entry;
                    }
                }

                this.displayLeaderboard();
            });
        }
    }

    displayLeaderboard() {
        common.kioskUpperUI?.replaceLeaderboardData(this.externalData.scores);
    }

    clearExternalData() {
        this.clearOnNextAddEntryFlag = false;

        this.externalData.scores = [];
        this.displayLeaderboard();
    }

    addExternalData(player) {
        if (this.clearOnNextAddEntryFlag) {
            common.leaderboard.clearExternalData();
        }

        this.externalData.scores.push({
            rank: this.externalData.scores.length + 1,
            displayName: player.name,
            score: player.finishTime,
        });

        this.externalData.scores.sort((first, second) => first.score - second.score);

        for (let i = 0; i < this.externalData.scores.length; i++) {
            this.externalData.scores[i].rank = i + 1;
        }
    }

    addExternalDataHeyVR(player) {
        if (this.clearOnNextAddEntryFlag) {
            common.leaderboard.clearExternalData();
        }

        this.externalData.scores.push({
            rank: player.rank,
            displayName: player.name,
            score: player.finishTime,
        });
    }

    clearOnNextAddEntry() {
        this.clearOnNextAddEntryFlag = true;
    }
}
