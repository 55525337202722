import { PassthroughWidget, SingleParentXMLInputConfig, type Rect, type Widget, type WidgetAutoXML, type WidgetProperties } from "lazy-widgets";
import { bevelRect } from "../../misc/bevelRect.js";

/**
 * Similar to RoundedCorners from lazy-widgets, but bevels instead of rounding.
 */
export class BevelledCorners<W extends Widget = Widget> extends PassthroughWidget<W> {
    static override autoXML: WidgetAutoXML = {
        name: "bevelled-corners",
        inputConfig: SingleParentXMLInputConfig
    };

    constructor(child: W, properties?: Readonly<WidgetProperties>) {
        super(child, properties);
    }

    protected override onThemeUpdated(property: string | null = null): void {
        super.onThemeUpdated(property);

        if (property === null || property === "roundedCornersRadii") {
            this.markWholeAsDirty();
        }
    }

    protected override handlePainting(dirtyRects: Array<Rect>): void {
        // Paint brackground
        const ctx = this.viewport.context;
        ctx.save();
        ctx.beginPath();

        bevelRect(ctx, this.x, this.y, this.width, this.height, this.roundedCornersRadii);
        ctx.clip();

        super.handlePainting(dirtyRects);

        ctx.restore();
    }
}
