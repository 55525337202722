import { EventEmitter } from "events";
import { AudioPlacement } from "./audio-placement.js";
import { type AudioSink } from "./audio-sink.js";
import { Level, voipLog } from "./voip-log.js";

/* Default fftSize to use for analysing audio amplitude */
const ANALYSER_FFT_SIZE = 128;
/* Max bincount */
const ANALYSER_BIN_COUNT = ANALYSER_FFT_SIZE / 2;
const ANALYSER_BIN_COUNT_INV = 1 / ANALYSER_BIN_COUNT;
const ANALYSER_BIN_DATA = new Uint8Array(ANALYSER_BIN_COUNT);
const BYTE_INV = 1 / 255;
const TMP_DIR = new Float32Array(3);
// human hearing range is 20hz (13.01dB) to 20000hz (43.01dB)
const MIN_FREQ = 20;
const MAX_FREQ = 20000;
const FAR_VOLUME = 0.5;
const NEAR_DISTANCE = 1;
const FAR_DISTANCE = 4;
const ROLLOFF_FACTOR = 1 - FAR_VOLUME;

function getFrequencyRangeLoudnessFactor(freqMin: number, freqMax: number): number {
    // check if outside audible range
    if (freqMax < MIN_FREQ || freqMin > MAX_FREQ) {
        return 0;
    }

    let factor = 1;
    // check if we only need part of range
    if (freqMin < MIN_FREQ) {
        factor = (freqMax - MIN_FREQ) / (freqMax - freqMin);
    } else if (freqMax > MAX_FREQ) {
        factor = (MAX_FREQ - freqMin) / (freqMax - freqMin);
    }

    // TODO we could use a-weighting. we might have a scenario where there is a
    // high amplitude on low-frequency waves, so the loudness factor increases,
    // but the apparent loudness is actually low, since we are less sensitive to
    // low-frequency sound:
    // https://www.researchgate.net/figure/Normal-hearing-threshold-and-equal-loudness-curves-from-ISO-226-OpenStax-College-2014_fig1_288270778
    return factor;
}

// TODO generalise this to non-stream audio sources, make it part of encrypted
// audio library

/**
 * A audio source. Does not necessarily have an input audio node.
 */
export class VoIPAudioSource extends EventEmitter {
    private availableListener: ((context: AudioContext, node: AudioNode) => void) | null = null;
    private unavailableListener: ((context: AudioContext) => void) | null = null;
    private trackNodes = new Map<MediaStreamTrack, MediaStreamAudioSourceNode | null>();
    private gainNode: GainNode | null = null;
    private pannerNode: PannerNode | null = null;
    private analyserNode: AnalyserNode | null = null;
    private _volume: number = 1;
    readonly placement: AudioPlacement;
    private _muted = false;

    private log(level: Level, ...data: unknown[]) {
        voipLog(level, "VoIPAudioSource", ...data);
    }

    /**
     * @param sink - The audio sink to use as the destination of this audio source
     * @param spatial - Is spatial audio enabled for this audio source?
     */
    constructor(readonly sink: AudioSink, readonly spatial: boolean, placement?: AudioPlacement) {
        super();

        if (placement) {
            this.placement = placement;
        } else {
            this.placement = AudioPlacement.default();
        }

        this.availableListener = this.onContextAvailable.bind(this);
        sink.addListener("available", this.availableListener!);
        this.unavailableListener = this.onContextUnavailable.bind(this);
        sink.addListener("unavailable", this.unavailableListener!);

        if (sink.context) {
            this.onContextAvailable(sink.context, sink.node!);
        }
    }

    /**
     * @param track - The audio track to add to the media stream
     */
    addTrack(track: MediaStreamTrack) {
        if (!this.trackNodes.has(track)) {
            this.log(Level.DEBUG, `Adding track ${track.id}`);
            this.trackNodes.set(track, null);
            this.maybeCreateAudioNodes();
        }
    }

    /**
     * @param track - The audio track to remove from the media stream
     */
    removeTrack(track: MediaStreamTrack) {
        if (this.trackNodes.has(track)) {
            this.log(Level.DEBUG, `Removing track ${track.id}`);
            this.destroyAudioNode(track);
            this.trackNodes.delete(track);
        }
    }

    private maybeCreateAudioNodes() {
        if (!this.gainNode) return;

        for (const track of Array.from(this.trackNodes.keys())) {
            if (this.trackNodes.get(track)) continue;

            const stream = new MediaStream([track]);

            // XXX There is a Chromium bug
            // (https://bugs.chromium.org/p/chromium/issues/detail?id=933677) where
            // remote MediaStreams don't play unless they are assigned to a media
            // element. Workaround:
            const ua = window.navigator ? window.navigator.userAgent : "";
            if (ua.indexOf("Chrome") !== -1) {
                let tmpAudio: HTMLAudioElement | null = new Audio();

                const tmpAudioCallback = function () {
                    if (tmpAudio) {
                        tmpAudio.removeEventListener("error", tmpAudioCallback);
                        tmpAudio.removeEventListener("canplaythrough", tmpAudioCallback);
                        tmpAudio = null;
                    }
                };

                tmpAudio.muted = true;
                tmpAudio.addEventListener("error", tmpAudioCallback);
                tmpAudio.addEventListener("canplaythrough", tmpAudioCallback);
                tmpAudio.srcObject = stream;
            }

            this.log(Level.DEBUG, `Creating audio node for track ${track.id}`);
            const node = this.sink.context!.createMediaStreamSource(stream);
            node.connect(this.gainNode);
            this.trackNodes.set(track, node);
        }
    }

    private destroyAudioNode(track: MediaStreamTrack, node?: MediaStreamAudioSourceNode | null) {
        if (node === undefined) {
            node = this.trackNodes.get(track);
        }

        if (node) {
            this.log(Level.DEBUG, `Destroying audio node for track ${track.id}`);
            node.disconnect();
            this.trackNodes.set(track, null);
        }
    }

    private onContextAvailable(context: AudioContext, node: AudioNode) {
        if (this.gainNode) {
            this.onContextUnavailable(this.gainNode.context);
        }

        this.log(Level.DEBUG, "Context available");
        this.gainNode = context.createGain();
        this.gainNode.gain.value = this._volume;
        this.analyserNode = context.createAnalyser();
        this.analyserNode.fftSize = ANALYSER_FFT_SIZE;
        this.gainNode.connect(this.analyserNode);

        if (this.spatial) {
            this.pannerNode = context.createPanner();
            this.updatePannerPlacement(this.pannerNode);
            // XXX can only use maxDistance if using linear model
            this.pannerNode.distanceModel = "linear";
            this.pannerNode.refDistance = NEAR_DISTANCE;
            this.pannerNode.maxDistance = FAR_DISTANCE;
            this.pannerNode.rolloffFactor = ROLLOFF_FACTOR;

            this.gainNode.connect(this.pannerNode);
            this.pannerNode.connect(node);

            this.updatePlacement();
        } else {
            this.gainNode.connect(node);
        }

        this.maybeCreateAudioNodes();
    }

    private onContextUnavailable(context: BaseAudioContext, _node?: AudioNode) {
        if (!this.gainNode || context !== this.gainNode.context) {
            return;
        }

        this.log(Level.DEBUG, "Context unavailable");
        this.analyserNode!.disconnect();
        this.analyserNode = null;
        this.gainNode!.disconnect();
        this.gainNode = null;

        for (const [track, node] of this.trackNodes) {
            this.destroyAudioNode(track, node);
        }

        if (this.pannerNode) {
            this.pannerNode.disconnect();
            this.pannerNode = null;
        }
    }

    /**
     * Clean up this audio source. Audio source is invalidated after this call.
     * A "disposed" event is emitted if the audio source was not already
     * disposed.
     */
    dispose() {
        this.log(Level.DEBUG, "Disposing");
        let disposed = false;

        if (this.gainNode) {
            this.onContextUnavailable(this.gainNode.context);
            disposed = true;
        }

        this.trackNodes.clear();

        if (this.availableListener) {
            this.sink.removeListener("available", this.availableListener);
            this.availableListener = null;
            disposed = true;
        }

        if (this.unavailableListener) {
            this.sink.removeListener("unavailable", this.unavailableListener);
            this.unavailableListener = null;
            disposed = true;
        }

        if (disposed) {
            this.emit("disposed");
        }
    }

    /**
     * Update the audio source's placement (position and rotation).
     * Automatically called by {@link VoIPPeer} when needed. Do not call this
     * directly
     */
    updatePlacement() {
        if (!this.spatial) return;

        if (this.pannerNode) {
            this.updatePannerPlacement(this.pannerNode);
        }
    }

    private updatePannerPlacement(pannerNode: PannerNode) {
        const position = this.placement.position;
        pannerNode.positionX.value = position[0];
        pannerNode.positionY.value = position[1];
        pannerNode.positionZ.value = position[2];
        this.placement.getForward(TMP_DIR);
        pannerNode.orientationX.value = TMP_DIR[0];
        pannerNode.orientationY.value = TMP_DIR[1];
        pannerNode.orientationZ.value = TMP_DIR[2];
    }

    /**
     * Get the maximum loudness of the audio, in dBFS (NOT dBSPL). If the
     * analyser node is not available (due to no audio context), then this will
     * return -Infinity.
     */
    get loudness(): number {
        if (!this.analyserNode || !this.gainNode) {
            return -Infinity;
        }

        const minDb = this.analyserNode.minDecibels;
        const maxDb = this.analyserNode.maxDecibels;
        if (minDb === maxDb) {
            return minDb;
        }

        this.analyserNode.getByteFrequencyData(ANALYSER_BIN_DATA);

        let maxLoudnessByte = 0;
        const freqFactor = ANALYSER_BIN_COUNT_INV * this.analyserNode.context.sampleRate * 0.5;
        for (let i = 0; i < ANALYSER_BIN_COUNT; i++) {
            const byte = ANALYSER_BIN_DATA[i];
            const freqMin = i * freqFactor;
            const freqMax = (i + 1) * freqFactor;
            const loudnessFactor = getFrequencyRangeLoudnessFactor(freqMin, freqMax);
            maxLoudnessByte = Math.max(maxLoudnessByte, byte * loudnessFactor);
        }

        return minDb + maxLoudnessByte * BYTE_INV * (maxDb - minDb);
    }

    get volume(): number {
        return this._volume;
    }

    set volume(volume: number) {
        if (this._volume !== volume) {
            this._volume = volume;
            this.updateGainNodeVolume();
        }
    }

    get muted(): boolean {
        return this._muted;
    }

    set muted(muted: boolean) {
        if (this._muted !== muted) {
            this._muted = muted;
            this.updateGainNodeVolume();
        }
    }

    private updateGainNodeVolume() {
        if (this.gainNode) {
            this.gainNode.gain.value = this._muted ? 0 : this._volume;
        }
    }
}