// TODO compare against something that is network shared, so all players get
//      consistent priorities
// TODO use this with local name as well

/** @type {Map<string, Array<NameSource>>} */
const takenNames = new Map();
function trackTakenName(source) {
    const name = source.name;
    let sources = takenNames.get(name);
    if (!sources) {
        sources = [];
        takenNames.set(name, sources);
    } else if (sources.includes(source)) {
        source.notify();
        return;
    }

    const sourceCount = sources.length;
    for (let i = 0; i < sourceCount; i++) {
        if (sources[i] === null) {
            source.setPriority(i);
            sources[i] = source;
            return;
        }
    }

    source.setPriority(sources.length);
    sources.push(source);
}

function untrackTakenName(source) {
    const name = source.name;
    const sources = takenNames.get(name);
    if (!sources) return;

    const idx = sources.indexOf(source);
    if (idx < 0) return;

    sources[idx] = null;

    const sourceCount = sources.length;
    let i = sourceCount;
    for (; i >= 0 && sources[i] === null; i--);

    if (i < 0) {
        takenNames.delete(name);
    } else {
        takenNames.length = i + 1;
    }
}

export class NameSource {
    constructor(name = null) {
        this._name = name;
        this.priority = 0;
        this.listeners = new Set();
        if (name !== null) this.track();
    }

    get name() {
        return this._name;
    }

    set name(name) {
        if (this._name !== name) {
            this.untrack();
            this._name = name;
            this.track();
        }
    }

    track() {
        if (this._name !== null) trackTakenName(this);
    }

    untrack() {
        if (this._name !== null) untrackTakenName(this);
    }

    setPriority(newPriority) {
        this.priority = newPriority;
        this.notify();
    }

    notify() {
        for (const listener of this.listeners) {
            listener(this.name, this.priority);
        }
    }

    listen(listener) {
        this.listeners.add(listener);
    }

    unlisten(listener) {
        this.listeners.delete(listener);
    }
}