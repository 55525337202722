import { Component, Object3D, TextComponent } from "@wonderlandengine/api";
import { property } from "@wonderlandengine/api/decorators.js";
import { GameMode, gameConfigChangeRequiresReload, isValidConfiguration } from "hoverfit-shared-netcode";
import { LAST_SESSION_GAME_CONFIG_KEY } from "src/hoverfit/misc/preferences/pref-keys.js";
import { GLOBAL_PREFS } from "src/hoverfit/misc/preferences/preference-manager.js";
import { AnalyticsUtils, BrowserUtils, GamepadButtonEvent, GamepadButtonID, Globals, Timer, Vector3, XRUtils } from "wle-pp";
import { AudioID } from "../../audio/audio-manager/audio-id.js";
import common from "../../common.js";
import { HoverboardGameConfig, HoverboardGameConfigJSON, currentGameConfig } from "../../data/game-configuration.js";
import { currentPlayerData } from "../../data/player-data.js";
import { cancelSceneLoad, loadScene } from "../../misc/load-scene/load-scene.js";
import { RoomData, currentRoomData } from "../../network/components/hoverboard-networking-component.js";
import { NetworkPlayerComponent } from "../../network/components/network-player-component.js";
import { PopupIconImage } from "../../ui/popup/popup.js";
import { PauseMenuComponent, RaceButtonState } from "../../ui/xml-ui/components/pause-menu-component.js";
import { DownloadAbortError } from "../../utils/fetch-utils.js";
import { GAME_STATES } from "../game-states.js";
import { NPCControllerComponent } from "../hoverboard/components/npc-controller-component.js";
import { TrackModeInstructionsComponent } from "../track/components/track-mode-instructions-component.js";
import { HoverboardDebugs, HoverboardRuntimeDebugs } from "./hoverboard-debugs-component.js";

// TODO pull all game logic out of this. i dont know who had the brilliant idea
//      of tying game logic to the menu, but now this is a shitshow that needs
//      to be cleaned for the kiosk
// raf: just in case you're wondering, i was very angry when i wrote that, but
//      the point still stands. thankfully we're getting closer to a point where
//      we can remove this, but still:

////////////////////////////////////////////////////////////////////////////////
//
//  DO NOT ADD NEW updateGameConfigValue METHODS HERE. WE'RE TRYING TO MOVE AWAY
//  FROM THAT SO THAT NOT EVERY FILE IN THE GAME DEPENDS ON menu.js
//
//  if you're adding another game property like NPC difficulty, then look at
//  NPCsDifficultyVariable and HoverboardGameConfig for an example of what to do
//  instead. it might be faster to just slap a new method on menu.js, but this
//  will just add even more technical debt
//
////////////////////////////////////////////////////////////////////////////////

let feedbackModalOpened = false;

export class MenuComponent extends Component {
    static TypeName = "menu";

    @property.object()
    private main!: Object3D;

    @property.object()
    private pause!: Object3D;

    public finishTime: number = 0;
    public bestLapTime: number = -1;

    public startMainPositionLocal!: Vector3;

    public currentNPCs: NPCControllerComponent[] = [];

    private localState: GAME_STATES = GAME_STATES.INTRO;

    private avatarConfigDone: boolean = false;

    private pauseMenuToTrackLabelDirty: boolean = true;

    private cursorObject!: Object3D;
    private pauseComponent!: PauseMenuComponent;

    private stateChangeCallbacks: ((oldState: GAME_STATES, newState: GAME_STATES) => void)[] = [];

    private changingGameConfig: ((value: unknown) => void)[] | null = null;

    private trackModeInstructionsComponent!: TrackModeInstructionsComponent;

    private startButtonReady: boolean = false;

    private windowVisibilityChangedListener: (() => void) | null = null;
    private sessionVisibilityChangedListener: ((event: XRSessionEvent) => void) | null = null;
    private visibilityChangedDelay: Timer = new Timer(5);

    init() {
        common.menu = this;

        this.startMainPositionLocal = this.main.pp_getPositionLocal();
        this.trackModeInstructionsComponent = this.main.pp_getComponent(TrackModeInstructionsComponent)!;

        AnalyticsUtils.setAnalyticsEnabled(true);
        AnalyticsUtils.setSendDataCallback((window as unknown as { gtag: any }).gtag);

        this.windowVisibilityChangedListener = async () => {
            if (this.visibilityChangedDelay.isDone()) {
                try {
                    if (document.visibilityState != "visible") {
                        currentPlayerData.savePlayerData();
                        this.visibilityChangedDelay.start();
                    }
                } catch (error) {
                    // Do Nothing
                }
            }
        };

        window.addEventListener("visibilitychange", this.windowVisibilityChangedListener);
    }

    start() {
        currentPlayerData.init();

        this.cursorObject = Globals.getPlayerObjects()!.myHandRight!.pp_getObjectByName("Cursor")!;

        Globals.getAudioManager()!.stop();

        this.pauseComponent = this.pause.getComponent(PauseMenuComponent)!;

        Globals.getRightGamepad()!.registerButtonEventListener(GamepadButtonID.TOP_BUTTON, GamepadButtonEvent.PRESS_END, 0, () => {
            if (this.localState !== GAME_STATES.INTRO) {
                this.togglePause();
            }
        });

        Globals.getLeftGamepad()!.registerButtonEventListener(GamepadButtonID.MENU, GamepadButtonEvent.PRESS_END, 0, () => {
            if (this.localState !== GAME_STATES.INTRO) {
                this.togglePause();
            }
        });

        this.stateChangeCallbacks = [];

        this.stateChangeCallbacks.push(this.cursorToggleFunction.bind(this));

        this.setMainEnabled(false);

        XRUtils.registerSessionStartEndEventListeners(this, this._onXRSessionStart.bind(this), this._onXRSessionEnd.bind(this), true);

        if (Globals.isDebugEnabled() && HoverboardDebugs.disableAnalyticsOnLocalhost && BrowserUtils.isLocalhost()) {
            AnalyticsUtils.setAnalyticsEnabled(false);
        }

        if (Globals.isDebugEnabled() && HoverboardDebugs.logAnalyticsEvents) {
            AnalyticsUtils.setEventsLogEnabled(true);
        }
    }

    togglePause() {
        // XXX this is the actual public function for pausing and unpausing
        if (!this.pauseComponent.isStateLocked()) {
            if (!this.inPausedState()) {
                this.setPauseEnabled(true);
                AnalyticsUtils.sendEventOnce("open_pause_menu");
            } else {
                this.resumeGame();
            }
        }
    }

    private setMainEnabled(enabled: boolean) {
        this.main.pp_setActive(enabled);

        this.trackModeInstructionsComponent.setModeInstructionsVisible(enabled);

        if (currentGameConfig.mode != GameMode.Roam && common.hoverboardNetworking.room != null) {
            common.readinessIndicator.setEnabled(enabled);
        } else {
            common.readinessIndicator.setEnabled(false);
        }

        if (!enabled) {
            this.main.pp_resetTransformLocal();
        }
    }

    private inPausedState(): boolean {
        const CURRENT_STATE = common.CURRENT_STATE;
        return CURRENT_STATE === GAME_STATES.PAUSE || CURRENT_STATE === GAME_STATES.PAUSE_MENU || CURRENT_STATE === GAME_STATES.POST_ENDGAME;
    }

    private setPauseEnabled(enabled: boolean) {
        const countdown = common.countdown;
        if (enabled && common.hoverboardNetworking.room && countdown.isRunning()) return;
        const CURRENT_STATE = common.CURRENT_STATE;
        if (enabled && (CURRENT_STATE !== GAME_STATES.GAME && CURRENT_STATE !== GAME_STATES.MENU)) return;

        if (enabled) {
            countdown.pauseCountdown();
            common.CURRENT_STATE = (CURRENT_STATE === GAME_STATES.GAME) ? GAME_STATES.PAUSE : GAME_STATES.PAUSE_MENU;
        }

        this.pauseComponent.setPaused(enabled);
    }

    setStartButtonReady(ready: boolean) {
        const startButtonText = this.main.pp_getObjectByName("Start Button")!.pp_getComponent(TextComponent)!;
        if (ready) {
            startButtonText.text = "READY";
        } else {
            startButtonText.text = "CANCEL";
        }

        this.startButtonReady = ready;
    }

    resumeGame() {
        if (!this.inPausedState()) return;
        common.CURRENT_STATE = (common.CURRENT_STATE === GAME_STATES.PAUSE) ? GAME_STATES.GAME : GAME_STATES.MENU;

        if (common.CURRENT_STATE === GAME_STATES.GAME) {
            const countdown = common.countdown;
            if (countdown.finished) {
                common.timer.resumeTimer();
                const raceMusicAudio = common.audioManager.getAudio(AudioID.TRACK_MUSIC);
                if (raceMusicAudio && !raceMusicAudio.isPlaying()) {
                    raceMusicAudio.play();
                }
            } else {
                countdown.resumeCountdown();
            }
        }

        this.setPauseEnabled(false);
    }

    startRace(checkNetworkRoom: boolean = true, numberOfRacers: number = 1, roundDuration: number | null = null) {
        if (Globals.isDebugEnabled() && HoverboardRuntimeDebugs.moveToTrackDebug) {
            this.startRaceDebug();
            return;
        }

        if (!this.inPausedState()) {
            if (common.hoverboardNetworking.room && checkNetworkRoom) {
                common.hoverboardNetworking.getPlayersOnTrack();
                // TODO: Change text and button color
                common.hoverboardNetworking.setRoundReady(this.startButtonReady);
            } else {
                if (!common.balcony.isPlayerOnBalcony) {
                    this.setMainEnabled(false);
                }

                common.leaderboard.clearOnNextAddEntry();

                for (const npc of this.currentNPCs) {
                    npc.startRound();
                }

                this.finishTime = 0;
                this.bestLapTime = -1;

                if (!common.balcony.isPlayerOnBalcony) {
                    if (common.CURRENT_STATE === GAME_STATES.MENU) {
                        common.CURRENT_STATE = GAME_STATES.GAME;
                    }
                }

                if (currentGameConfig.mode != GameMode.Roam) {
                    if (currentGameConfig.mode == GameMode.Tag) {
                        common.timer.setDuration(roundDuration ?? currentGameConfig.tagDuration.value);
                    } else {
                        common.timer.setDuration(null);

                        if (numberOfRacers == 1) {
                            common.audioManager.getAudio(AudioID.RACER_READY)!.play();
                        } else {
                            common.audioManager.getAudio(AudioID.RACERS_READY)!.play();
                        }
                    }
                }

                common.countdown.startCountdown();

                if (!common.balcony.isPlayerOnBalcony) {
                    this.resumeGame();
                }

                if (currentGameConfig.mode == GameMode.Roam) {
                    common.countdown.endCountdown(true);
                }

                if (common.balcony.isPlayerOnBalcony) {
                    AnalyticsUtils.sendEvent("start_game_mode_player_on_balcony");
                    AnalyticsUtils.sendEvent("start_" + currentGameConfig.mode + "_player_on_balcony");
                } else {
                    AnalyticsUtils.sendEvent("start_game_mode");
                    AnalyticsUtils.sendEvent("start_" + currentGameConfig.mode);
                    if (common.hoverboardNetworking.room) {
                        AnalyticsUtils.sendEvent("start_game_mode_online");
                        AnalyticsUtils.sendEvent("start_game_mode_online_players_" + numberOfRacers);
                        AnalyticsUtils.sendEvent("start_" + currentGameConfig.mode + "_online");
                        AnalyticsUtils.sendEvent("start_" + currentGameConfig.mode + "_online_players_" + numberOfRacers);
                    } else {
                        AnalyticsUtils.sendEvent("start_game_mode_offline");
                        AnalyticsUtils.sendEvent("start_" + currentGameConfig.mode + "_offline");
                    }

                    if (currentGameConfig.mode == GameMode.Race) {
                        AnalyticsUtils.sendEvent("start_race_laps_" + currentGameConfig.lapsAmount.value);
                        AnalyticsUtils.sendEvent("start_race_" + currentGameConfig.map.replace(/-/g, "_") + "_laps_" + currentGameConfig.lapsAmount.value);
                    }
                }
            }

            if (!common.hoverboardNetworking.room) {
                common.tracksManager.setRoundStarted(true);
            }
        }
    }

    returnToBalcony(checkNetworkRoom: boolean = true) {
        if (Globals.isDebugEnabled() && HoverboardRuntimeDebugs.moveToTrackDebug) {
            this.returnToBalconyDebug();
            return;
        }

        if (common.balcony.isPlayerOnBalcony) return;

        if (common.hoverboardNetworking.room && checkNetworkRoom) {
            // TODO: Change text and button color
            common.hoverboardNetworking.returnToBalcony();
        } else {
            if (currentGameConfig.mode == GameMode.Race) {
                if (common.CURRENT_STATE == GAME_STATES.PAUSE) {
                    AnalyticsUtils.sendEvent("abandon_race");

                    if (common.hoverboardNetworking.room) {
                        AnalyticsUtils.sendEvent("abandon_race_online");
                    } else {
                        AnalyticsUtils.sendEvent("abandon_race_offline");
                    }
                }
            }

            this.pauseComponent.getRaceButtonState().value = RaceButtonState.Start;

            this.setMainEnabled(false);

            common.tracksManager.getTagResultsBoard().setVisible(false);

            currentPlayerData.savePlayerData();

            if (!common.hoverboardNetworking.room) {
                const balconyMusicAudio = common.audioManager.getAudio(AudioID.BALCONY_MUSIC)!;
                const raceMusicAudio = common.audioManager.getAudio(AudioID.TRACK_MUSIC)!;
                balconyMusicAudio.fade(0, balconyMusicAudio.getDefaultVolume(), 0.8);
                // raceMusicAudio.fade(raceMusicAudio.getDefaultVolume(), 0.0, 0.8);
                raceMusicAudio?.stop();
                common.motivationalAudio.stopMotivational();

                const countdown = common.countdown;
                countdown.setVisible(false);
                countdown.resetCountdown();
            }

            const raceFinishAudio = common.audioManager.getAudio(AudioID.RACE_FINISH)!;
            if (raceFinishAudio.isPlaying()) {
                raceFinishAudio.fade(raceFinishAudio.getDefaultVolume(), 0, 1);
            }

            if (!common.hoverboardNetworking.room) {
                common.timer.stopTimer();
            }

            common.timer.hideTimer();

            if (common.CURRENT_STATE != GAME_STATES.INTRO) {
                common.CURRENT_STATE = GAME_STATES.PAUSE_MENU;
            }

            common.balcony.moveToBalcony();

            common.tracksManager.returnedToBalcony();

            this.resumeGame();
            if (!common.hoverboardNetworking.room) {
                common.tracksManager.setRoundStarted(false);

                this.finishNPCsRace();
                this.returnAllNPCs();
            }
        }
    }

    moveToTrack(checkNetworkRoom: boolean = true, startingPosition: number = 0) {
        if (!common.balcony.isPlayerOnBalcony) return;

        HoverboardRuntimeDebugs.moveToTrackDebug = false;

        const hoverboardNetworking = common.hoverboardNetworking;
        const hoverboardRoom = hoverboardNetworking.room;

        if (hoverboardRoom && checkNetworkRoom) {
            // TODO: Change text and button color
            hoverboardNetworking.moveToTrack();
        } else {
            if (!hoverboardRoom && currentGameConfig.mode == GameMode.Tag) {
                common.popupManager.showQuickMessagePopup("Tag mode is only available in Multiplayer", PopupIconImage.Warn);
            } else {
                this.setStartButtonReady(true);

                this.pauseComponent.getRaceButtonState().value = RaceButtonState.ToBalcony;

                const balconyMusicAudio = common.audioManager.getAudio(AudioID.BALCONY_MUSIC)!;
                balconyMusicAudio.fade(balconyMusicAudio.getVolume(), 0.0, 0.8);

                this.setMainEnabled(true);

                if (currentGameConfig.mode != GameMode.Roam) {
                    const countdown = common.countdown;
                    countdown.setVisible(true);
                    countdown.resetCountdown();
                }

                common.timer.stopTimer();
                common.timer.resetTimer();

                common.balcony.moveToTrack(startingPosition);

                // Cleanup before going to the track to see npcs race on the track
                this.returnAllNPCs();
                this.setupNPCs(false);

                common.CURRENT_STATE = GAME_STATES.PAUSE_MENU;

                common.hoverboardInstructor.stopAudioPlayers();

                this.resumeGame();

                AnalyticsUtils.sendEvent("move_to_track");

                if (common.hoverboardNetworking.room) {
                    AnalyticsUtils.sendEvent("move_to_track_online");
                } else {
                    AnalyticsUtils.sendEvent("move_to_track_offline");
                }

                AnalyticsUtils.sendEvent("move_to_track_" + currentGameConfig.map.replace(/-/g, "_"));
                AnalyticsUtils.sendEvent("move_to_track_" + currentGameConfig.mode);
                AnalyticsUtils.sendEvent("move_to_track_" + currentGameConfig.map.replace(/-/g, "_") + "_" + currentGameConfig.mode);
            }
        }
    }

    private startRaceDebug() {
        if (!this.inPausedState()) {
            if (!common.balcony.isPlayerOnBalcony) {
                this.setMainEnabled(false);
            }

            common.leaderboard.clearOnNextAddEntry();

            this.finishTime = 0;
            this.bestLapTime = -1;

            if (!common.balcony.isPlayerOnBalcony) {
                if (common.CURRENT_STATE === GAME_STATES.MENU) {
                    common.CURRENT_STATE = GAME_STATES.GAME;
                }
            }

            common.countdown.startCountdown();

            if (!common.balcony.isPlayerOnBalcony) {
                this.resumeGame();
            }

            if (currentGameConfig.mode == GameMode.Roam) {
                common.countdown.endCountdown(true);
            }
        }
    }

    returnToBalconyDebug() {
        if (common.balcony.isPlayerOnBalcony) return;

        this.pauseComponent.getRaceButtonState().value = RaceButtonState.Start;

        this.setMainEnabled(false);

        common.tracksManager.getTagResultsBoard().setVisible(false);

        currentPlayerData.savePlayerData();

        if (!common.hoverboardNetworking.room) {
            const balconyMusicAudio = common.audioManager.getAudio(AudioID.BALCONY_MUSIC)!;
            const raceMusicAudio = common.audioManager.getAudio(AudioID.TRACK_MUSIC)!;
            balconyMusicAudio.fade(0, balconyMusicAudio.getDefaultVolume(), 0.8);
            // raceMusicAudio.fade(raceMusicAudio.getDefaultVolume(), 0.0, 0.8);
            raceMusicAudio?.stop();
            common.motivationalAudio.stopMotivational();

            const countdown = common.countdown;
            countdown.setVisible(false);
            countdown.resetCountdown();
        }

        const raceFinishAudio = common.audioManager.getAudio(AudioID.RACE_FINISH)!;
        if (raceFinishAudio.isPlaying()) {
            raceFinishAudio.fade(raceFinishAudio.getDefaultVolume(), 0, 1);
        }

        if (!common.hoverboardNetworking.room) {
            common.timer.stopTimer();
        }

        common.timer.hideTimer();

        if (common.CURRENT_STATE != GAME_STATES.INTRO) {
            common.CURRENT_STATE = GAME_STATES.PAUSE_MENU;
        }

        common.balcony.moveToBalcony();

        common.tracksManager.returnedToBalcony();

        this.resumeGame();
    }

    moveToTrackDebug() {
        if (!common.balcony.isPlayerOnBalcony) return;

        HoverboardRuntimeDebugs.moveToTrackDebug = true;

        this.setStartButtonReady(true);

        this.pauseComponent.getRaceButtonState().value = RaceButtonState.ToBalcony;

        const balconyMusicAudio = common.audioManager.getAudio(AudioID.BALCONY_MUSIC)!;
        balconyMusicAudio.fade(balconyMusicAudio.getVolume(), 0.0, 0.8);

        this.setMainEnabled(true);

        common.timer.stopTimer();
        common.timer.resetTimer();

        common.balcony.moveToTrack(0);

        common.CURRENT_STATE = GAME_STATES.PAUSE_MENU;

        common.hoverboardInstructor.stopAudioPlayers();

        this.resumeGame();
    }

    returnAllNPCs() {
        common.hoverboardNetworking.clearNPCReferences();

        if (this.currentNPCs.length) {
            for (let i = 0; i < this.currentNPCs.length; i++) {
                const npc = this.currentNPCs[i];
                common.networkPlayerPool.returnEntity(npc.object);
            }
        }

        this.currentNPCs = [];
    }

    setupNPCs(includeCurrentPlayer: boolean) {
        if (currentGameConfig.canHaveNPCs && currentGameConfig.npcsAmount.value > 0) {
            const seed = common.hoverboardNetworking.npcSeed;

            const activeNPCsAmount = this.getNPCsActiveAmount(includeCurrentPlayer);
            for (let i = 0; i < activeNPCsAmount; i++) {
                const player = common.networkPlayerPool.getEntity();
                const networkPlayerComponent = player.getComponent(NetworkPlayerComponent);
                networkPlayerComponent.setEnabled(true);

                networkPlayerComponent.setNPC(i, seed + i);
                common.hoverboardNetworking.setupNPCReferences(i, player);

                this.currentNPCs.push(networkPlayerComponent.npcController);
            }
        }
    }

    finishNPCsRace() {
        for (const currentNPC of this.currentNPCs) {
            currentNPC.finishRace();
        }
    }

    onCountdownFinished() {
        for (let i = 0; i < this.currentNPCs.length; i++) {
            const npc = this.currentNPCs[i];
            npc.startRacing();
        }
    }

    private onStateChange(oldState: GAME_STATES, newState: GAME_STATES) {
        for (const f of this.stateChangeCallbacks) {
            f(oldState, newState);
        }
    }

    private cursorToggleFunction(oldState: GAME_STATES, newState: GAME_STATES) {
        if (newState == GAME_STATES.GAME) {
            this.cursorObject.pp_setActive(false);
        } else {
            this.cursorObject.pp_setActive(true);
        }
    }

    async changeGameConfig(newRoomData: RoomData, newGameConfig: HoverboardGameConfig, checkNetworkRoom: boolean = true) {
        const hoverboardNetworking = common.hoverboardNetworking;

        if (!isValidConfiguration(newGameConfig, Globals.isDebugEnabled() && HoverboardDebugs.unlockAllKioskUI)) {
            if (isValidConfiguration(newGameConfig, true)) {
                common.popupManager?.showQuickMessagePopup("The game configuration is locked!\nYou need to unlock it to access it.", PopupIconImage.Error);
            } else {
                common.popupManager?.showQuickMessagePopup("Invalid game configuration\nAre you running an outdated game client?", PopupIconImage.Error);
            }

            return false;
        }

        const needsReload = gameConfigChangeRequiresReload(currentGameConfig, newGameConfig);
        if (!needsReload) {
            if (needsReload === null) {
                if (common.popupManager != null) {
                    common.popupManager?.showQuickMessagePopup("You are already playing with\nthis game configuration", PopupIconImage.Warn);
                }
            } else {
                if (hoverboardNetworking.room && checkNetworkRoom) {
                    hoverboardNetworking.changeGameConfig(newGameConfig);
                } else {
                    currentGameConfig.track = newGameConfig.track;
                    common.tracksManager.setTrackByMapTrackIndex(currentGameConfig.trackConfig.mapTrackIndex);
                }
            }
            return true;
        }

        // can only cancel scene download when in singleplayer
        if (this.changingGameConfig && currentRoomData.roomNumber == null) {
            if (!cancelSceneLoad()) {
                common.popupManager?.showQuickMessagePopup("A game configuration change\nis already in progress", PopupIconImage.Warn);
                return false;
            }

            await new Promise((resolve, _reject) => {
                if (this.changingGameConfig) {
                    this.changingGameConfig.push(resolve);
                } else {
                    resolve(undefined);
                }
            });
        }

        let configChanged = false;

        if (!this.changingGameConfig) {
            if (hoverboardNetworking.room && checkNetworkRoom) {
                hoverboardNetworking.changeGameConfig(newGameConfig);
            } else {
                this.changingGameConfig = [];

                try {
                    await loadScene(this.engine, newGameConfig, newRoomData);
                    configChanged = true;

                    const newSessionGameConfig = new HoverboardGameConfigJSON();
                    newSessionGameConfig.location = newGameConfig.location;
                    newSessionGameConfig.mode = newGameConfig.mode;
                    newSessionGameConfig.track = newGameConfig.track;
                    GLOBAL_PREFS.setPref(LAST_SESSION_GAME_CONFIG_KEY, newSessionGameConfig);
                } catch (error) {
                    if (!(error instanceof DownloadAbortError)) {
                        common.popupManager?.showQuickMessagePopup("Could not change game configuration", PopupIconImage.Error);

                        console.error("Change game config error: ", error);
                    }

                    if (common.intro.isDone()) {
                        common.intro.cancelManualFadeOut();
                    }
                }

                const callbacks = this.changingGameConfig;
                this.changingGameConfig = null;
                for (const callback of callbacks) callback(undefined);
            }
        } else {
            common.popupManager?.showQuickMessagePopup("A game configuration change\nis already in progress", PopupIconImage.Warn);
        }

        return configChanged;
    }

    update(dt: number) {
        const CURRENT_STATE = common.CURRENT_STATE;
        if (this.localState !== CURRENT_STATE) {
            this.onStateChange(this.localState, CURRENT_STATE);
            this.localState = CURRENT_STATE;
        }

        if (!this.avatarConfigDone) {
            this.avatarConfigDone = this.updateAvatarConfig();
        }

        if (this.pauseMenuToTrackLabelDirty) {
            this.pauseComponent.getRaceButtonState().value = RaceButtonState.Start;

            this.pauseMenuToTrackLabelDirty = false;
        }

        this.visibilityChangedDelay.update(dt);

        currentPlayerData.update(dt);
    }

    updateAvatarConfig() {
        let avatarUpdated = false;

        // TODO maybe replace a good chunk of this with a new `gameReady` global
        if (currentPlayerData.avatar!.isReady() && common.kioskController.configAvatarComponent!.isReady() && common.kioskLowerUI.iapContentController.ready) {
            common.avatarSelector.setAvatarType(currentPlayerData.avatarType, currentPlayerData.avatar, true);
            common.avatarSelector.setAvatarSkinColor(currentPlayerData.skinColor, currentPlayerData.avatar, true);
            common.avatarSelector.setAvatarSuit(currentPlayerData.suitVariant, currentPlayerData.avatar, true);
            common.avatarSelector.setAvatarHeadwear(currentPlayerData.headwearVariant, currentPlayerData.avatar, true);
            common.avatarSelector.setAvatarHairColor(currentPlayerData.hairColor, currentPlayerData.avatar, true);
            common.hoverboardSelector.setHoverboard(currentPlayerData.hoverboardVariant, common.hoverboard.hoverboardMeshObject, false, true);

            avatarUpdated = true;
        }

        return avatarUpdated;
    }

    getPlayersOnTrack(includeCurrentPlayer: boolean): number {
        let playersOnTrack = (!common.balcony.isPlayerOnBalcony && includeCurrentPlayer) ? 1 : 0;
        if (common.hoverboardNetworking.room) {
            playersOnTrack += common.hoverboardNetworking.getPlayersOnTrack(false).length;
        }

        return playersOnTrack;
    }

    private getNPCsActiveAmount(includeCurrentPlayer: boolean): number {
        if (!currentGameConfig.canHaveNPCs) return 0;

        let maxNPCs = currentGameConfig.npcsAmount.value;

        const trackMaxPlayers = currentGameConfig.trackConfig.maxPlayers;
        if (trackMaxPlayers !== null) {
            // FIXME this looks wrong. it should be
            // (!common.balcony.isPlayerOnBalcony && includeCurrentPlayer) ? ...
            //       but idk if that messes up setupNPCs
            let playersOnTrack = common.balcony.isPlayerOnBalcony ? 0 : 1;
            if (common.hoverboardNetworking.room) {
                playersOnTrack = common.hoverboardNetworking.getPlayersOnTrack(includeCurrentPlayer).length;
                playersOnTrack += !includeCurrentPlayer ? 1 : 0;
            }

            maxNPCs = Math.max(0, trackMaxPlayers - playersOnTrack);
        }

        return Math.min(maxNPCs, currentGameConfig.npcsAmount.value);

    }

    private _onXRSessionStart(session: XRSession) {
        AnalyticsUtils.sendEventOnce("enter_xr");

        (window as any).closeBetaInfoModal();

        this.sessionVisibilityChangedListener = async (event: XRSessionEvent) => {
            if (this.visibilityChangedDelay.isDone()) {
                try {
                    if (event.session.visibilityState != "visible") {
                        currentPlayerData.savePlayerData();
                        this.visibilityChangedDelay.start();
                    }
                } catch (error) {
                    // Do Nothing
                }
            }
        };

        session.addEventListener("visibilitychange", this.sessionVisibilityChangedListener);
    }

    private _onXRSessionEnd() {
        if (common.intro.isDone() && !feedbackModalOpened) {
            if (!Globals.isDebugEnabled() || !HoverboardDebugs.disableFeedbackModal) {
                feedbackModalOpened = true;
                //(window as any).openBetaInfoModal();
            }
        }

        if (this.visibilityChangedDelay.isDone()) {
            currentPlayerData.savePlayerData();
            this.visibilityChangedDelay.start();
        }
    }

    onDestroy(): void {
        currentPlayerData.savePlayerData();

        if (this.windowVisibilityChangedListener != null) {
            window.removeEventListener("visibilitychange", this.windowVisibilityChangedListener);
            this.windowVisibilityChangedListener = null;
        }

        if (this.sessionVisibilityChangedListener != null && XRUtils.isSessionActive()) {
            XRUtils.getSession()!.removeEventListener("visibilitychange", this.sessionVisibilityChangedListener);
            this.sessionVisibilityChangedListener = null;
        }
    }
}