import { Howler } from "howler";
import { ArrayUtils } from "wle-pp";
import { Audio } from "./audio.js";
import { HowlerAudio } from "./implementations/howler-audio.js";
import { SinkedAudio, SourceSink } from "./sinked-audio.js";
import { SourceAudioType } from "./source-audio.js";

export class AudioVolumeMixer {

    private _volume = 1;

    private _audioMap = new Map<SourceAudioType, Audio[]>();

    private _howlerGainNode: GainNode | null = null;
    private _sourceSinks = new Map<SourceSink, Audio[]>;

    constructor() {
        for (const sourceAudioType of Object.values(SourceAudioType)) {
            this._audioMap.set(sourceAudioType, []);
        }
    }

    getVolume(): number {
        return this._volume;
    }

    setVolume(volume: number): void {
        this._volume = volume;

        if (this._howlerGainNode == null) {
            this._createHowlerGainNode();
        }

        this._howlerGainNode!.gain.value = volume;

        for (const sink of this._sourceSinks.keys()) {
            sink.volume = volume;
        }
    }

    isAudioAttached(audio: Audio): boolean {
        return ArrayUtils.hasEqual(this._audioMap.get(audio.getSourceAudioType())!, audio);
    }

    attachAudio(audio: Audio) {
        if (!this.isAudioAttached(audio)) {
            const sourceAudioType = audio.getSourceAudioType();

            this._audioMap.get(sourceAudioType)?.push(audio);

            if (sourceAudioType == SourceAudioType.HOWLER || sourceAudioType == SourceAudioType.PP) {
                const howlerAudio = audio as HowlerAudio;

                if (this._howlerGainNode == null) {
                    this._createHowlerGainNode();
                }

                howlerAudio.setGainNode(this._howlerGainNode!);
            } else if (sourceAudioType == SourceAudioType.ENCRYPTED_AUDIO || sourceAudioType == SourceAudioType.VOIP) {
                const sinkedAudio = audio as unknown as SinkedAudio;
                const sink = sinkedAudio.getSink();
                if (sink != null) {
                    if (!this._sourceSinks.has(sink)) {
                        this._sourceSinks.set(sink, []);

                        sink.volume = this._volume;
                    }

                    this._sourceSinks.get(sink)?.push(audio);
                }
            }
        }
    }

    detachAudio(audio: Audio) {
        if (this.isAudioAttached(audio)) {
            const sourceAudioType = audio.getSourceAudioType();

            ArrayUtils.removeEqual(this._audioMap.get(sourceAudioType)!, audio);

            if (sourceAudioType == SourceAudioType.HOWLER || sourceAudioType == SourceAudioType.PP) {
                const howlerAudio = audio as HowlerAudio;
                howlerAudio.resetGainNode();
            } else if (sourceAudioType == SourceAudioType.ENCRYPTED_AUDIO || sourceAudioType == SourceAudioType.VOIP) {
                const sinkedAudio = audio as unknown as SinkedAudio;
                const sink = sinkedAudio.getSink();
                if (sink != null) {
                    if (this._sourceSinks.has(sink)) {
                        const sourceSinks = this._sourceSinks.get(sink)!;
                        ArrayUtils.removeEqual(sourceSinks, audio);

                        if (sourceSinks.length == 0) {
                            this._sourceSinks.delete(sink);
                        }
                    }
                }
            }
        }
    }

    _createHowlerGainNode() {
        if (Howler.ctx == null) {
            Howler.volume(); // Triggers a context creation, sadly the setupAudioContext function is not exposed
            if (typeof Howler.ctx !== "undefined" && Howler.ctx && Howler.autoUnlock) {
                (Howler as unknown as { _unlockAudio: () => void })._unlockAudio(); // This basically will trigger an unload and recreate the context, after this Howler is "fully" initialized
            }
        }

        this._howlerGainNode = typeof Howler.ctx.createGain === "undefined" ? (Howler.ctx as unknown as { createGainNode: () => GainNode }).createGainNode() : Howler.ctx.createGain();
        this._howlerGainNode!.connect(Howler.masterGain);
    }
}