import { Object3D } from "@wonderlandengine/api";
import common from "../../common.js";
import { currentGameConfig } from "../../data/game-configuration.js";
import { StatusEffectsManager } from "../hoverboard/status-effects/status-effects-manager.js";
import { TrackComponent } from "./components/track-component.js";
import { MiniMapManager } from "./mini-map-manager.js";
import { RaceManager } from "./race-manager.js";
import { TagResultsBoard } from "./results/tag-results-board.js";
import { TrackStatistics } from "./results/track-statistics.js";
import { Track } from "./track.js";

export class TracksManager {
    private _tracksObject: Object3D;

    private _trackStatistics: TrackStatistics = new TrackStatistics();
    private _raceManager: RaceManager = new RaceManager();

    private _miniMapManager: MiniMapManager = new MiniMapManager();

    private _statusEffectsManager: StatusEffectsManager = new StatusEffectsManager();

    private _tagResultsBoard!: TagResultsBoard;

    private _updateCountdown: number = 5;
    private _tracksReady: boolean = false;

    private _tracks: Track[] = [];
    private _tracksByMapTrackIndex: Map<number, Track> = new Map();
    private _currentTrack: Track | null = null;
    private _currentMapTrackIndex: number | null = null;

    private _roundStarted: boolean = false;

    constructor(tracksObject: Object3D) {
        common.tracksManager = this;

        this._tracksObject = tracksObject;
        this._raceManager.setTracksManager(this);

        for (const trackComponent of this._tracksObject.pp_getComponents(TrackComponent)) {
            const track = new Track(trackComponent.object, trackComponent.mapTrackIndex, trackComponent.showDynamicChevrons);
            track.setTracksManager(this);

            this._tracks.push(track);

            // XXX only include map track if it's referenced by any track under
            //     the current game mode. some track maps are exclusive to
            //     specific game modes
            for (const trackConfig of currentGameConfig.modeConfig.tracks) {
                if (trackConfig.mapTrackIndex === track.getMapTrackIndex()) {
                    this._tracksByMapTrackIndex.set(track.getMapTrackIndex(), track);
                    break;
                }
            }
        }
    }

    start() {
        const tagResultsBoardObject = common.hoverboard.object.pp_getObjectByName("Tag Results Board");
        this._tagResultsBoard = new TagResultsBoard(tagResultsBoardObject!);
        this._tagResultsBoard.setVisible(false);

        for (const track of this._tracks) {
            track.start();
        }
    }

    returnedToBalcony() {
        this._statusEffectsManager.clearEffects(null);

        if (this._currentTrack != null) {
            this._currentTrack.returnedToBalcony();
        }
    }

    countdownStarted() {
        if (this._currentTrack != null) {
            this._currentTrack.countdownStarted();
        }

        this.getTrackStatistics().reset();
    }

    areAllTracksReady(): boolean {
        let areAllTracksReady = this._tracksReady;

        for (const trackComponent of this._tracksByMapTrackIndex.values()) {
            areAllTracksReady &&= trackComponent.isSplineLoaded();
        }

        return areAllTracksReady;
    }

    getRaceManager(): RaceManager {
        return this._raceManager;
    }

    getStatusEffectsManager(): StatusEffectsManager {
        return this._statusEffectsManager;
    }

    getMiniMapManager(): MiniMapManager {
        return this._miniMapManager;
    }

    getTagResultsBoard(): TagResultsBoard {
        return this._tagResultsBoard;
    }

    getTrackStatistics(): TrackStatistics {
        return this._trackStatistics;
    }

    getCurrentTrack(): Track | null {
        return this._currentTrack;
    }

    setRoundStarted(roundStarted: boolean): void {
        if (this._roundStarted != roundStarted) {
            this._roundStarted = roundStarted;

            if (!this._roundStarted) {
                if (this._currentTrack != null) {
                    this._currentTrack.roundEnded();
                }
            }
        }
    }

    isRoundStarted(): boolean {
        return this._roundStarted;
    }

    setTrackByMapTrackIndex(mapTrackIndex: number) {
        if (this._tracksReady) {

            if (this._currentTrack != null) this._currentTrack.setActive(false);

            this._currentTrack = this._tracksByMapTrackIndex.get(mapTrackIndex)!;
            if (!this._currentTrack) throw new Error("Invalid map track index");

            this._currentTrack.setActive(true);
        }

        this._currentMapTrackIndex = mapTrackIndex;
    }

    update(dt: number) {
        if (this._updateCountdown > 0) {
            this._updateCountdown--;
            if (this._updateCountdown == 0) {
                this.setTrackByMapTrackIndex(currentGameConfig.trackConfig.mapTrackIndex);

                this._raceManager.start(this._tracksObject);
                this._miniMapManager.start(common.balcony.object.pp_getObjectByName("Track Mini Map")!);
            }
        } else {
            if (!this._tracksReady) {
                this._tracksReady = true;
                for (const trackComponent of this._tracksByMapTrackIndex.values()) {
                    this._tracksReady &&= trackComponent.isReady();
                }

                if (this._tracksReady && this._currentMapTrackIndex! != null) {
                    this.setTrackByMapTrackIndex(this._currentMapTrackIndex);
                }
            }

            this._statusEffectsManager.update(dt);
            this._raceManager.update(dt);
            this._miniMapManager.update(dt);

            for (const track of this._tracks) {
                track.update(dt);
            }
        }
    }
}