import { Gender } from "../data/values/gender.js";
import { type AssetBase, type AssetManifest, type GenderedAssetBase, type GenderlessAssetBase } from "../misc/asset-provision/asset-manifest-types.js";
import { ItemCategory } from "../misc/asset-provision/asset-provider.js";

const EQUIPABLE_ITEM_CATEGORIES: ReadonlySet<ItemCategory> = new Set([
    ItemCategory.Skin,
    ItemCategory.Hoverboard,
    ItemCategory.Headwear,
    ItemCategory.Suit,
    ItemCategory.HairColor,
]);

export function unpackAssetShortID(outIDs: string[], assetManifest: AssetManifest, shortID: string) {
    outIDs.push(shortID);

    const bundle = assetManifest.bundle[shortID];
    if (bundle) {
        // technically this means you can't have bundles in bundles, and bundles
        // can't contain things from other shops. if for some crazy reason you
        // want to allow bundles to contain other bundles, then use
        // unpackAssetShortID here too instead of generating a namespaced ID
        for (const otherShortID of bundle.items) {
            outIDs.push(otherShortID);
        }
    }
}

export function getAssetFromManifest(manifest: AssetManifest, shortID: string): AssetBase | undefined {
    let asset: AssetBase | undefined = manifest.hoverboard[shortID];
    if (asset) return asset;
    if ((asset = manifest.suit[shortID])) return asset;
    if ((asset = manifest.headwear[shortID])) return asset;
    if ((asset = manifest.hairColor[shortID])) return asset;
    if ((asset = manifest.skin[shortID])) return asset;
    if ((asset = manifest.bundle[shortID])) return asset;
    return manifest.location[shortID];
}

export function getAssetThumbnail(asset: AssetBase, gender: Gender) {
    const genderlessThumb = (asset as GenderlessAssetBase).thumbnail;
    if (genderlessThumb) return genderlessThumb;
    return (asset as GenderedAssetBase)[`thumbnail${gender === Gender.Female ? "Fem" : "M"}ale`];
}

export function isItemCategoryEquippable(category: ItemCategory | null = null) {
    if (!category) return false;
    return EQUIPABLE_ITEM_CATEGORIES.has(category);
}