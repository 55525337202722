export function bigNumberToString(x: number): string {
    if (x >= 1e9) {
        // billions
        const bils = Math.trunc(x * 1e-9);
        if (Math.trunc(bils) === bils) {
            return `${bils.toFixed(0)}B`;
        } else {
            return `${bils.toFixed(1)}B`;
        }
    } else if (x >= 1e6) {
        // millions
        const mils = Math.trunc(x * 1e-6);
        if (Math.trunc(mils) === mils) {
            return `${mils.toFixed(0)}M`;
        } else {
            return `${mils.toFixed(1)}M`;
        }
    } else if (x >= 1e3) {
        // thousands
        const thos = Math.trunc(x * 1e-3);
        if (Math.trunc(thos) === thos) {
            return `${thos.toFixed(0)}K`;
        } else {
            return `${thos.toFixed(1)}K`;
        }
    }

    return Math.trunc(x).toFixed(0);
}