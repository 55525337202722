import { Component, Property } from "@wonderlandengine/api";
import { vec3_create } from "wle-pp";

// TODO ????? why. why is this a thing in the first place?

export class MoveAwayComponent extends Component {
    static TypeName = "move-away";
    static Properties = {
        xPos: Property.float(0),
        yPos: Property.float(0),
        zPos: Property.float(0)
    };

    start() {
        this.firstUpdate = true;
    }

    update(dt) {
        if (this.firstUpdate) {
            this.firstUpdate = false;

            this.object.pp_setPositionLocal(vec3_create(this.xPos, this.yPos, this.zPos));
        }
    }
}