import { Component, Object3D, TextComponent } from "@wonderlandengine/api";
import { property } from "@wonderlandengine/api/decorators.js";
import common from "../../common.js";

export class FlatMessageDisplayerComponent extends Component {
    static override TypeName = "flat-message-displayer";

    @property.object()
    textObject!: Object3D;

    @property.float(2)
    messageLifetime!: number;

    @property.float(1.9)
    maxMessageWidth!: number;

    @property.float(0.0444444444444)
    approximateCharacterWidth!: number;

    private _textComponent!: TextComponent;

    private _queuedMessages: [string, number][] = [];
    private _queuedMessageLifeTime: number = 0;

    private _messageLifeTime: number = 0;

    private _messageText: string | null = null;

    private static readonly _maxQueuedMessageLifetime = 5;

    override init() {
        common.flatMessageDisplayer = this;
    }

    override start() {
        this._textComponent = this.textObject.pp_getComponent(TextComponent)!;
    }

    isDisplayingMessage(): boolean {
        return this._messageText != null;
    }

    displayMessage(message: string, lifeTime: number = this.messageLifetime): void {
        // try to "renew" current message or last queued message. renewing is
        // refreshing the lifetime of a message instead of queueing a new one,
        // and only for messages that have the same text
        const lastIdx = this._queuedMessages.length - 1;
        if (this._messageText === message) {
            if (lifeTime <= 0) {
                this._messageLifeTime = 0;
            } else if (lifeTime > this._messageLifeTime) {
                this._messageLifeTime = lifeTime;
            }

            return;
        } else if (lastIdx >= 0) {
            const lastMessage = this._queuedMessages[lastIdx];
            if (lastMessage[0] === message) {
                if (lifeTime <= 0) {
                    this._queuedMessageLifeTime -= lastMessage[1];
                    lastMessage[1] = 0;
                } else if (lifeTime > lastMessage[1]) {
                    this._queuedMessageLifeTime -= lastMessage[1];
                    lastMessage[1] = lifeTime;
                    this._queuedMessageLifeTime += lifeTime;
                }

                return;
            }
        }

        // can't renew current or last queued message. add new message (or
        // replace current one/last queued)
        if (this._messageLifeTime <= 0) {
            // replace current instant/expired message
            this._setMessage(message, lifeTime);
        } else {
            if (lastIdx >= 0 && this._queuedMessages[lastIdx][1] <= 0) {
                // replace last queued instant message if any
                this._queuedMessages[lastIdx] = [message, lifeTime];
            } else {
                // queue message
                if (lifeTime > 0) this._queuedMessageLifeTime += lifeTime;
                this._queuedMessages.push([message, lifeTime]);
            }
        }
    }

    clearMessages(): void {
        this._queuedMessages.length = 0;
        this._queuedMessageLifeTime = 0;

        this._clearMessage();
    }

    override update(dt: number): void {
        // Message display
        let canDequeueMessage = true;
        if (this._messageLifeTime > 0.0) {
            let dtMul = 1;
            if (this._queuedMessageLifeTime > FlatMessageDisplayerComponent._maxQueuedMessageLifetime) {
                // speed up message lifetime if there is too much lifetime
                // queued
                dtMul = Math.exp((this._queuedMessageLifeTime - FlatMessageDisplayerComponent._maxQueuedMessageLifetime) * 0.2);
            }

            this._messageLifeTime -= dt * dtMul;
            if (this._messageLifeTime <= 0.0) {
                this._clearMessage();
            } else {
                canDequeueMessage = false;
            }
        }

        if (canDequeueMessage && this._queuedMessages.length > 0) {
            const [message, lifeTime] = this._queuedMessages.shift()!;
            if (lifeTime > 0) this._queuedMessageLifeTime -= lifeTime;

            if (this._queuedMessages.length === 0) {
                // to avoid precision issues where the total queued lifetime
                // accumulates tiny values over time, reset to 0 once there are
                // no more queued messages. we will probably never have this
                // problem, but just in case...
                this._queuedMessageLifeTime = 0;
            }

            this._setMessage(message, lifeTime);
        }
    }

    private _setMessage(message: string, lifeTime: number): void {
        const messageParts: string[] = [];
        const maxCharacters = Math.trunc(this.maxMessageWidth / this.approximateCharacterWidth);

        for (const fullLine of message.split("\n")) {
            let line = fullLine;
            while (line.length > maxCharacters) {
                let whiteSpaceIdx = maxCharacters - 1;
                for (; whiteSpaceIdx >= 0; whiteSpaceIdx--) {
                    if (line[whiteSpaceIdx] === " ") break;
                }

                if (whiteSpaceIdx < 0) whiteSpaceIdx = maxCharacters - 1;

                messageParts.push(line.slice(0, whiteSpaceIdx));
                line = line.slice(whiteSpaceIdx);
            }

            if (line.length > 0) messageParts.push(line);
        }

        this._messageLifeTime = lifeTime;
        // XXX messageText is only used for comparing the current message with a
        //     new message. this is only used for the message renewal logic
        this._messageText = message;
        this._textComponent.text = messageParts.join("\n");
        this._textComponent.active = true;
    }

    private _clearMessage() {
        this._messageLifeTime = 0;
        this._messageText = null;
        this._textComponent.active = false;
        this._textComponent.text = "";
    }
}