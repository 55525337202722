import { AssetBase } from "./asset-manifest-types.js";
import { type AssetProvider, type ItemCategory, type ItemCurrency, type ItemNamespace } from "./asset-provider.js";

export abstract class ShopItem {
    abstract readonly itemCategory: ItemCategory | null;
    abstract readonly shortID: string;
    abstract readonly namespace: ItemNamespace;
    abstract readonly currencyType: ItemCurrency;
    abstract readonly price: number;
    abstract readonly priceDiscounted: number;

    constructor(readonly shopProvider: AssetProvider, readonly asset: AssetBase) { }

    get id() {
        return `${this.namespace}:${this.shortID}`;
    }

    get name() {
        return this.asset.name;
    }

    get description(): string | undefined {
        const desc = this.asset.description;
        if (desc === '') return undefined;
        return desc;
    }

    abstract purchase(): Promise<boolean>;
}
