import { Component, type ComponentProperty, type Material, type Mesh, type Object3D, type Texture, Type, type WonderlandEngine } from "@wonderlandengine/api";
import { property } from "@wonderlandengine/api/decorators.js";
import common from "src/hoverfit/common.js";
import { getNameFromLocalURL, isLocalURL } from "src/hoverfit/utils/url-utils.js";

export class HoverfitSceneResources {
    private readonly textures = new Map<string, Texture>();
    private readonly externalTextures = new Map<string, Promise<Texture>>();
    private readonly meshes = new Map<string, Mesh>();
    private readonly objects = new Map<string, Object3D>();
    private readonly externalObjects = new Map<string, Promise<Object3D>>();
    private readonly materials = new Map<string, Material>();

    constructor(readonly engine: WonderlandEngine, properties: Record<string, ComponentProperty>, component: Component) {
        for (const key of Object.getOwnPropertyNames(properties)) {
            const value = properties[key];
            if (value.type === Type.Texture) {
                this.textures.set(key, (component as any)[key]);
            } else if (value.type === Type.Mesh) {
                this.meshes.set(key, (component as any)[key]);
            } else if (value.type === Type.Object) {
                this.objects.set(key, (component as any)[key]);
            } else if (value.type === Type.Material) {
                this.materials.set(key, (component as any)[key]);
            } else {
                console.warn(`Unsupported built-in resource type (${value}) for key "${key}"`);
            }
        }
    }

    private getResource<T>(key: string, map: Map<string, T>): T {
        const val = map.get(key);
        if (val === undefined) {
            throw new Error(`Invalid built-in resource key "${key}"`);
        }

        return val;
    }

    private maybeGetLocalResource<T>(url: string, map: Map<string, T>): T | null {
        if (!isLocalURL(url)) return null;
        return this.getResource(getNameFromLocalURL(url), map);
    }

    getTexture(key: string) {
        return this.getResource(key, this.textures);
    }

    maybeGetLocalTexture(url: string) {
        return this.maybeGetLocalResource(url, this.textures);
    }

    getTextureFromURL(url: string): Promise<Texture> {
        const localTex = this.maybeGetLocalTexture(url);
        if (localTex) return Promise.resolve(localTex);
        let promise = this.externalTextures.get(url);
        if (promise) return promise;
        promise = this.engine.textures.load(url, 'anonymous');
        this.externalTextures.set(url, promise);
        return promise;
    }

    getMesh(key: string) {
        return this.getResource(key, this.meshes);
    }

    maybeGetLocalMesh(url: string) {
        return this.maybeGetLocalResource(url, this.meshes);
    }

    getObject(key: string) {
        return this.getResource(key, this.objects);
    }

    maybeGetLocalObject(url: string) {
        return this.maybeGetLocalResource(url, this.objects);
    }

    getObjectFromURL(url: string): Promise<Object3D> {
        const localObj = this.maybeGetLocalObject(url);
        if (localObj) return Promise.resolve(localObj);
        let promise = this.externalObjects.get(url);
        if (promise) return promise;
        promise = this.engine.scene.append(url) as Promise<Object3D>;
        this.externalObjects.set(url, promise);
        return promise;
    }

    getMaterial(key: string) {
        return this.getResource(key, this.materials);
    }

    maybeGetLocalMaterial(url: string) {
        return this.maybeGetLocalResource(url, this.materials);
    }
}

export class HoverfitSceneResourcesComponent extends Component {
    static TypeName = "hoverfit-scene-resources";

    @property.texture()
    hoverboardOakDiffuseTexture!: Texture;
    @property.texture()
    hoverboardOakNormalTexture!: Texture;
    @property.texture()
    hoverboardSpruceDiffuseTexture!: Texture;
    @property.texture()
    hoverboardSpruceNormalTexture!: Texture;
    @property.texture()
    hoverboardCarbonDiffuseTexture!: Texture;
    @property.texture()
    hoverboardCarbonNormalTexture!: Texture;
    @property.texture()
    suitDefaultFemaleDiffuseTexture!: Texture;
    @property.texture()
    suitDefaultFemaleEmissiveTexture!: Texture;
    @property.texture()
    suitDefaultMaleDiffuseTexture!: Texture;
    @property.texture()
    suitDefaultMaleEmissiveTexture!: Texture;
    @property.object()
    hoverboardDefaultRoot!: Object3D;
    @property.object()
    suitDefaultFemaleRoot!: Object3D;
    @property.object()
    suitDefaultMaleRoot!: Object3D;

    init() {
        // XXX do not put this in start. we want this to be available VERY early
        common.hoverfitSceneResources = new HoverfitSceneResources(this.engine, HoverfitSceneResourcesComponent.Properties, this);
    }
}