import { PriorityQueue } from "../../misc/data-structs/priority-queue.js";

/**
 * Helper class for managing modal dialogs. Currently only used for the copyText
 * function.
 */
export class ModalManager {
    /**
     * @param {string} backdropClass - The CSS class name to use for the modal dialog backdrop.
     * @param {string} containerClass - The CSS class name to use for the modal dialog containers.
     */
    constructor(backdropClass, containerClass) {
        this.modals = new PriorityQueue();
        this.removeCallbacks = new Map();
        this.containerClass = containerClass;

        // XXX WLE editor won't build project without this because we are
        // instancing a global ModalManager
        if ("document" in globalThis) {
            this.backdrop = document.createElement("div");
            this.backdrop.className = backdropClass;
            this.backdrop.addEventListener("click", () => this.removeTopModal());
        }
    }

    /**
     * Make a container for a new modal dialog. The backdrop will be added if
     * necessary.
     *
     * @param {(() => void) | null} removeCallback - The callback to call when the modal dialog is removed. If null, then no callback will be used.
     * @param {number} priority - The priority of the modal dialog. A dialog with a priority higher than another will be placed above. Defaults to 0.
     * @returns {HTMLDivElement} Returns the created modal dialog div container.
     */
    makeModal(removeCallback = null, priority = 0) {
        // add backdrop if necessary
        if (this.modals.empty) {
            document.body.appendChild(this.backdrop);
        }

        // make div
        const container = document.createElement("div");
        container.className = this.containerClass;
        // XXX prevent backdrop from being pressed through the modal container
        container.addEventListener("click", (ev) => {
            ev.stopImmediatePropagation();
            ev.preventDefault();
        });

        // add div as child of backdrop, in the right order according to the
        // priority queue
        const index = this.modals.enqueue(container, priority);
        if (index >= this.backdrop.children.length) {
            this.backdrop.appendChild(container);
        } else {
            this.backdrop.insertBefore(container, this.backdrop.children[index]);
        }

        // set callback
        if (removeCallback) {
            this.removeCallbacks.set(container, removeCallback);
        }

        return container;
    }

    /**
     * Remove a modal dialog by its container. The backdrop will be removed if
     * no longer necessary.
     *
     * @param {HTMLDivElement} container - The container of the modal dialog to remove.
     * @param {boolean} doDequeue - Dequeue from the modals list? Should only be changed by internal function. Keep as true otherwise.
     */
    removeModal(container, doDequeue = true) {
        // get callback and remove it
        const removeCallback = this.removeCallbacks.get(container);
        if (removeCallback) {
            this.removeCallbacks.delete(container);
        }

        // remove container from body
        this.backdrop.removeChild(container);

        // remove container from queue
        if (doDequeue) {
            this.modals.dequeueMatching(container);
        }

        // remove backdrop if no longer necessary
        if (this.modals.empty) {
            document.body.removeChild(this.backdrop);
        }

        // call callback
        if (removeCallback) {
            removeCallback();
        }
    }

    /**
     * Remove the top-most modal dialog. The backdrop will be removed if no
     * longer necessary.
     */
    removeTopModal() {
        const top = this.modals.dequeue();
        if (top === null) {
            return;
        }

        this.removeModal(top[0], false);
    }
}

export const GLOBAL_MODALS = new ModalManager("modal-backdrop", "modal-container");