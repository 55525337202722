import { Component, Property } from "@wonderlandengine/api";
import { Background, Column, Container, Label, Spacing, TextAlignMode } from "lazy-widgets";
import { WLRoot } from "lazy-widgets-wle";
import common from "../../common.js";

/**
 * XXX template is missing, but i can't find a way to properly import it without
 * switching to typescript
 *
 * @typedef { import("../voip/VoIPPeer").VoIPPeer } VoIPPeer
 */

export class VoipDebugMenuComponent extends Component {
    static TypeName = "voip-debug-menu";
    static Properties = {
        material: Property.material(),
        textureUniformName: Property.string(),
        unitsPerPixel: Property.float(0.02),
        resolution: Property.float(1.0),
        collisionGroupsMask: Property.int(2),
    };

    init() {
        this.voip = null;
        this.micLabel = new Label("Microphone status: unknown");
        this.producerLabel = new Label("Has mediasoup producer: unknown");
        this.title = new Label("Players (0)", { bodyTextAlign: TextAlignMode.Center });
        this.playerColumn = new Column();
        /** @type {Map<VoIPPeer, Label>} */
        this.players = new Map();

        const topWidget = new Background(
            new Container(
                new Column().add([
                    this.micLabel,
                    this.producerLabel,
                    this.title,
                    new Spacing(),
                    this.playerColumn,
                    new Spacing(),
                    new Label("N tracks are null tracks, M are muted"),
                ])
            )
        );

        this.root = null;

        this.root = new WLRoot(this.object, this.material, topWidget, {
            unitsPerPixel: this.unitsPerPixel,
            collisionGroupsMask: this.collisionGroupsMask,
            resolution: this.resolution,
            textureUniformName: this.textureUniformName.length > 0 ? this.textureUniformName : undefined
        });

        // start hidden if nothing in url to enable debug menu
        const url = new URL(window.location);
        this.root.enabled = url.searchParams.has("voip_debug");

        common.MAIN_CHANNEL.on("toggle_voip_debug", () => this.setEnabled());
    }

    start() {
        this.voip = common.hoverboardNetworking.voip;

        for (const peer of this.voip.otherPeers) {
            this.addPeer(peer);
        }

        this.voip.on("peer-added", peer => {
            this.addPeer(peer);
        });

        this.voip.on("peer-removed", peer => {
            this.removePeer(peer);
        });
    }

    isEnabled() {
        return this.root.enabled;
    }

    updateTitle() {
        this.title.text = `Players (${this.players.size})`;
    }

    getStatsString(total, nullCount, mutedCount) {
        let str = `${total}`;

        if (nullCount > 0 || mutedCount > 0) {
            const parts = [];

            if (nullCount > 0) {
                parts.push(`${nullCount}N`);
            }

            if (mutedCount > 0) {
                parts.push(`${mutedCount}M`);
            }

            str = `${str} (${parts.join(",")})`;
        }

        return str;
    }

    getP2PState(/** @type {VoIPPeer} */ peer) {
        if (!peer._p2pConnection) {
            return "no conn";
        }

        return peer.badP2PConnection ? "bad" : "OK";
    }

    updatePeer(/** @type {VoIPPeer} */ peer) {
        const label = this.players.get(peer);

        if (!label) {
            return; // player may have already disconnected
        }

        let p2pTotalRX = 0;
        let p2pTotalRXNull = 0;
        let p2pTotalRXMuted = 0;
        let p2pTotalTX = 0;
        let p2pTotalTXNull = 0;
        let p2pTotalTXMuted = 0;

        const conn = peer._p2pConnection;
        if (conn) {
            const rx = conn.getReceivers();
            p2pTotalRX = rx.length;
            for (const rec of rx) {
                if (rec.track) {
                    if (rec.track.muted) {
                        p2pTotalRXMuted++;
                    }
                } else {
                    p2pTotalRXNull++;
                }
            }

            const tx = conn.getSenders();
            p2pTotalTX = tx.length;
            for (const snd of tx) {
                if (snd.track) {
                    if (snd.track.muted) {
                        p2pTotalTXMuted++;
                    }
                } else {
                    p2pTotalTXNull++;
                }
            }
        }

        label.text = `${peer.id}; ${peer.consumptionMode} mode; p2p ${this.getP2PState(peer)} RX ${this.getStatsString(p2pTotalRX, p2pTotalRXNull, p2pTotalRXMuted)}, TX ${this.getStatsString(p2pTotalTX, p2pTotalTXNull, p2pTotalTXMuted)}; ms RX ${!!peer._consumer}`;
    }

    addPeer(peer) {
        const label = new Label("");
        this.players.set(peer, label);

        this.updatePeer(peer);
        this.playerColumn.add(label);

        this.updateTitle();
    }

    removePeer(peer) {
        const label = this.players.get(peer);
        this.playerColumn.remove(label);
        this.players.delete(peer);

        this.updateTitle();
    }

    update() {
        if (this.root) {
            if (this.root.enabled && this.voip) {
                // XXX _micTrack and _producer are private and shouldn't be used
                // directly, but this is only used for debugging
                const micTrack = this.voip._micTrack;
                this.micLabel.text = `Microphone track: ${micTrack === null ? "set to null" : (`enabled: ${micTrack.enabled}; readyState: ${micTrack.readyState}; muted: ${micTrack.muted}`)}`;
                this.producerLabel.text = `Has mediasoup producer: ${!!this.voip._producer}`;

                for (const peer of this.players.keys()) {
                    this.updatePeer(peer);
                }
            }

            this.root.update();
        }
    }

    setEnabled(enabled = null) {
        if (enabled === null) {
            enabled = !this.root.enabled;
        }

        this.root.enabled = enabled;
    }
}