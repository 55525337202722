import { Emitter, Object3D, TextComponent } from "@wonderlandengine/api";
import { GameMode } from "hoverfit-shared-netcode";
import { AudioID } from "src/hoverfit/audio/audio-manager/audio-id.js";
import common from "src/hoverfit/common.js";
import { currentGameConfig } from "src/hoverfit/data/game-configuration.js";
import { HoverboardDebugs } from "src/hoverfit/game/components/hoverboard-debugs-component.js";
import { GAME_STATES } from "src/hoverfit/game/game-states.js";
import { NetworkPlayerComponent } from "src/hoverfit/network/components/network-player-component.js";
import { LapCompletedPopupParams } from "src/hoverfit/ui/popup/implementations/lap-completed-popup.js";
import { AnalyticsUtils, Globals, Quaternion2 } from "wle-pp";
import { DynamicChevronComponent } from "./components/dynamic-chevron-component.js";
import { FinishGoalComponent } from "./components/finish-goal-component.js";
import { StartGoalComponent } from "./components/start-goal-component.js";
import { DynamicChevronsManager } from "./dynamic-chevrons-manager.js";
import { RaceResultBoardData, RaceResultsBoard } from "./results/race-results-board.js";
import { getNPCDifficultyFromIndex, getRacePlacementSuffix } from "./track-utils.js";
import { TracksManager } from "./tracks-manager.js";

export class RaceManager {
    static LAP_CHECK_POINTS: number = 10;

    playerNotCloseEmitter: Emitter = new Emitter();
    playerCloseInFrontEmitter: Emitter = new Emitter();
    playerCloseBehindEmitter: Emitter = new Emitter();

    private _playerPositionTextComponent!: TextComponent;
    private _trackProgressTextComponent!: TextComponent;
    private _trackProgressLabelTextComponent!: TextComponent;

    private _tracksManager!: TracksManager;

    private _dynamicChevronsManager: DynamicChevronsManager = new DynamicChevronsManager();

    private _startGoal!: StartGoalComponent;
    private _finishGoal!: FinishGoalComponent;

    private _raceResultsBoard!: RaceResultsBoard;

    private _raceStarted: boolean = false;

    private _currentCheckPoint: number = 0;

    private _lapsAmount: number = -1;
    private _raceTimeAtLastLap: number = 0;
    private _raceTotalSquats: number = 0;
    private _raceTotalFitPoints: number = 0;

    private _prevAlertType: number = -1;

    private _enabled: boolean = true;

    private readonly _proximityTriggerActivationDistance: number = 0.01;

    start(tracksObject: Object3D) {
        this._playerPositionTextComponent = common.hoverboard.object.pp_getObjectByName("Race Position")!.pp_getComponent(TextComponent)!;
        this._trackProgressTextComponent = common.hoverboard.object.pp_getObjectByName("Track Progression Percentage")!.pp_getComponent(TextComponent)!;
        this._trackProgressLabelTextComponent = common.hoverboard.object.pp_getObjectByName("Track Progression Label")!.pp_getComponent(TextComponent)!;

        const dynamicChevronComponents = tracksObject.pp_getComponents(DynamicChevronComponent);
        const dynamicChevronComponentToAdd = [];
        for (const dynamicChevronComponent of dynamicChevronComponents) {
            if (dynamicChevronComponent.active) {
                dynamicChevronComponentToAdd.push(dynamicChevronComponent);
            }
        }
        this._dynamicChevronsManager.setChevrons(dynamicChevronComponentToAdd);

        this._startGoal = tracksObject.pp_getObjectByName("Start Goal")!.pp_addComponent(StartGoalComponent)!;
        this._finishGoal = tracksObject.pp_getObjectByName("Finish Goal")!.pp_addComponent(FinishGoalComponent)!;

        const raceResultsBoardObject = common.hoverboard.object.pp_getObjectByName("Race Results Board");
        this._raceResultsBoard = new RaceResultsBoard(raceResultsBoardObject!);
        this._raceResultsBoard.setVisible(false);
    }

    update(dt: number): void {
        if (this._raceStarted) {
            this._updateLapProgression();

            const playerPosition = this.getPlayerRacePosition();
            this._playerPositionTextComponent.text = playerPosition + getRacePlacementSuffix(playerPosition);
            this._trackProgressTextComponent.text = this._getPlayerLapPercentageText();
        }
    }

    setTracksManager(tracksManager: TracksManager): void {
        this._tracksManager = tracksManager;
    }

    getDynamicChevronsManager(): DynamicChevronsManager {
        return this._dynamicChevronsManager;
    }

    getRaceResultsBoard(): RaceResultsBoard {
        return this._raceResultsBoard;
    }

    setStartAndFinishGoalsPositions(startGoalPosition: Quaternion2, finishGoalPosition: Quaternion2) {
        this._startGoal.object.pp_setTransformQuat(startGoalPosition);
        this._finishGoal.object.pp_setTransformQuat(finishGoalPosition);
    }

    reset() {
        if (this._enabled) {
            if (this._tracksManager.getCurrentTrack() != null && this._tracksManager.getCurrentTrack()!.areDynamicChevronsEnabled()) {
                this._dynamicChevronsManager.resetChevrons();
            } else {
                this._dynamicChevronsManager.deactivateChevrons();
            }

            this._startGoal.resetGoal();
            this._finishGoal.resetGoal();
        } else {
            this._dynamicChevronsManager.deactivateChevrons();

            this._startGoal.hide();
            this._finishGoal.hide();
        }

        this._raceResultsBoard.setVisible(false);
    }

    setEnabled(enabled: boolean) {
        this._enabled = enabled;

        this.reset();
    }

    countdownStarted() {
        if (!this._enabled) return;

        if (this._tracksManager.getCurrentTrack() != null && this._tracksManager.getCurrentTrack()!.areDynamicChevronsEnabled()) {
            this._dynamicChevronsManager.initializeChevrons();
            this._dynamicChevronsManager.activateChevrons();
        }
    }

    returnedToBalcony() {
        this._raceResultsBoard.setVisible(false);

        if (this._tracksManager.isRoundStarted()) {
            if (this._tracksManager.getCurrentTrack() != null && this._tracksManager.getCurrentTrack()!.areDynamicChevronsEnabled()) {
                this._dynamicChevronsManager.resetChevrons();
                this._dynamicChevronsManager.initializeChevrons();
                this._dynamicChevronsManager.activateChevrons();
            }
        }
    }

    prepareFinishLine(showGoal: boolean) {
        this._finishGoal.prepareFinishLine(showGoal);
    }

    showFinishGoal() {
        if (this._finishGoal != null) {
            this._finishGoal.object.pp_setActive(true);
        }
    }

    startRace() {
        this._lapsAmount = -1;
        this._currentCheckPoint = RaceManager.LAP_CHECK_POINTS - 1;

        this._prevAlertType = -1;

        this._raceTotalSquats = 0;
        this._raceTotalFitPoints = 0;
        this._raceTimeAtLastLap = 0;

        if (currentGameConfig.lapsAmount.value == 1) {
            this._finishGoal.prepareFinishLine(false);
        } else {
            const spline = common.tracksManager.getCurrentTrack()!.getSpline()!;
            this._finishGoal.prepareNewLapLine(false, spline.getForward(0));
        }

        this._startGoal.shrink();

        const totalLapsAmount = currentGameConfig.lapsAmount.value;
        common.timer.updateLapsAmount(1, totalLapsAmount);
        common.timer.setStartingLastLapTime();

        this._raceStarted = true;
    }

    stopRace() {
        this._raceStarted = false;
    }

    getPlayerLapsAmount(): number {
        return this._lapsAmount;
    }

    getPlayerLapPercentage(): number {
        if (this._lapsAmount < 0) {
            return 0;
        }

        return common.hoverboard.getCurrentSplineTime();
    }

    getPlayerRacePosition(): number {
        if (!common.tracksManager.getCurrentTrack()!.hasSpline()) return 1;

        // Race time is given but adding the current lap to the current time -> lap 1 at 0.5 = 1.5
        const currentPlayerRaceTime = common.hoverboard.getCurrentSplineTime() + this._lapsAmount;

        const playersPositionTimes = [];
        playersPositionTimes.push(currentPlayerRaceTime);

        // Networked Players
        const otherPlayerObjects = common.hoverboardNetworking.otherPlayerObjects;
        for (const otherPlayerObject of otherPlayerObjects!.values()) {
            const networkPlayerComponent = otherPlayerObject.getComponent(NetworkPlayerComponent);
            let otherPlayerSplineTime = 0;
            let otherPlayerLapsAmount = 0;
            if (networkPlayerComponent.isNPC) {
                otherPlayerSplineTime = networkPlayerComponent.npcController.currentSplineTime;
                otherPlayerLapsAmount = networkPlayerComponent.npcController.lapsAmount;
            } else {
                otherPlayerSplineTime = networkPlayerComponent.getCurrentSplineTime();
                otherPlayerLapsAmount = networkPlayerComponent.lapsAmount;
            }

            const otherPlayerRaceTime = otherPlayerSplineTime + otherPlayerLapsAmount;

            playersPositionTimes.push(otherPlayerRaceTime);
        }

        playersPositionTimes.sort((a, b) => b - a);

        const currentPlayerIndex = playersPositionTimes.indexOf(currentPlayerRaceTime);

        this._playerCloseCheck(playersPositionTimes, currentPlayerIndex);

        return currentPlayerIndex + 1;
    }

    setupSpeedometer(): void {
        if (currentGameConfig.mode !== GameMode.Race) {
            this._playerPositionTextComponent.active = false;
            this._trackProgressTextComponent.active = false;
            this._trackProgressLabelTextComponent.active = false;
            common.timer.setLapIndicatorVisible(false);
            common.timer.hideLastLapTimer();
        } else {
            this._playerPositionTextComponent.active = true;
            this._trackProgressTextComponent.active = true;
            this._trackProgressLabelTextComponent.active = true;
            common.timer.setLapIndicatorVisible(true);
        }
    }

    private _updateLapProgression() {
        const currentCheckPoint = Math.floor(common.hoverboard.getCurrentSplineTime() * RaceManager.LAP_CHECK_POINTS);
        // If you are suddenly more than 1 checkpoint ahead, it's not valid, you might have skipped some part
        if (currentCheckPoint == (this._currentCheckPoint + 1) % RaceManager.LAP_CHECK_POINTS) {
            this._currentCheckPoint = currentCheckPoint;

            if (this._currentCheckPoint == 0) {
                if (Globals.isDebugEnabled() && HoverboardDebugs.endRaceOnFirstGoal) {
                    this._lapCompleted(true);
                    this._raceFinished();
                } else {
                    if (this._lapsAmount < 0) {
                        if (Globals.isDebugEnabled() && HoverboardDebugs.endLapOnFirstGoal) {
                            this._lapCompleted(false);
                        } else {
                            this._increaseLapsAmount();
                        }
                    } else {
                        if (this._lapsAmount == currentGameConfig.lapsAmount.value - 1) {
                            this._lapCompleted(true);
                            this._raceFinished();
                        } else {
                            this._lapCompleted(false);
                        }
                    }
                }
            } else if (this._currentCheckPoint == Math.floor(RaceManager.LAP_CHECK_POINTS / 2)) {
                if (this._lapsAmount == currentGameConfig.lapsAmount.value - 1) {
                    this._finishGoal.prepareFinishLine(true);
                } else {
                    const spline = common.tracksManager.getCurrentTrack()!.getSpline()!;
                    this._finishGoal.prepareNewLapLine(true, spline.getForward(0));
                }
            }
        }
    }

    private _lapCompleted(raceCompleted: boolean) {
        this._increaseLapsAmount();

        const lastLapTime = common.timer.time - this._raceTimeAtLastLap;
        if (common.menu.bestLapTime! < 0 || lastLapTime < common.menu.bestLapTime!) {
            common.menu.bestLapTime = lastLapTime;
        }

        common.menu.finishTime = common.timer.time;

        this._raceTimeAtLastLap = common.timer.time;

        const lapsAmount = currentGameConfig.lapsAmount.value;

        common.timer.updateBestLapTime(lastLapTime);

        if (!raceCompleted) {
            common.timer.updateLastLapTime(lastLapTime);
            common.timer.updateLapsAmount(this._lapsAmount + 1, lapsAmount);

            const lapCompletedPopupParams = new LapCompletedPopupParams();
            const lapCompletedWindowParams = lapCompletedPopupParams.popupWindowParams;
            lapCompletedWindowParams.lastLap = this._lapsAmount;
            lapCompletedWindowParams.lapsAmount = lapsAmount;
            lapCompletedWindowParams.currentTime = common.timer.time;
            lapCompletedWindowParams.lapTime = lastLapTime;
            lapCompletedWindowParams.currentFitPoints = Math.floor(common.hoverboard.getEarnedFitPoints());
            lapCompletedWindowParams.lapFitPoints = lapCompletedWindowParams.currentFitPoints - this._raceTotalFitPoints;
            lapCompletedWindowParams.lapSquats = common.hoverboard.getSquatsAmount() - this._raceTotalSquats;
            lapCompletedWindowParams.totalSquats = common.hoverboard.getSquatsAmount();
            lapCompletedWindowParams.lapPlayerPosition = this.getPlayerRacePosition();

            common.popupManager.showPopup(lapCompletedPopupParams);

            this._raceTotalSquats = common.hoverboard.getSquatsAmount();
            this._raceTotalFitPoints = lapCompletedWindowParams.currentFitPoints;
        }

        if (!raceCompleted) {
            this._finishGoal.hide();
        }

        AnalyticsUtils.sendEvent("race_lap_completed");
        if (common.hoverboardNetworking.room) {
            AnalyticsUtils.sendEvent("race_lap_completed_online");
        } else {

            AnalyticsUtils.sendEvent("race_lap_completed_offline");
        }
    }

    private _increaseLapsAmount() {
        this._lapsAmount++;
        if (common.hoverboardNetworking.room) {
            common.hoverboardNetworking.incrementLaps();
        }
    }

    private _raceFinished() {
        Globals.getLeftGamepad()!.pulse(0.35, 0.5);
        Globals.getRightGamepad()!.pulse(0.35, 0.5);

        AnalyticsUtils.sendEvent("race_completed");
        if (common.hoverboardNetworking.room) {
            AnalyticsUtils.sendEvent("race_completed_online");
            AnalyticsUtils.sendEvent("race_completed_online");
        } else {
            AnalyticsUtils.sendEvent("race_completed_offline");
        }

        const lapsAmount = currentGameConfig.lapsAmount.value;
        AnalyticsUtils.sendEvent("race_completed_laps_" + lapsAmount);
        if (common.hoverboardNetworking.room) {
            AnalyticsUtils.sendEvent("race_completed_online_laps_" + lapsAmount);
        } else {
            AnalyticsUtils.sendEvent("race_completed_offline_laps_" + lapsAmount);
        }

        common.timer.stopUpdatingTimer();

        const raceFinishAudio = common.audioManager.getAudio(AudioID.RACE_FINISH)!;
        raceFinishAudio.setVolume(raceFinishAudio.getDefaultVolume());
        raceFinishAudio.play();

        const raceResultsBoardData = new RaceResultBoardData();
        raceResultsBoardData.placement = common.tracksManager.getRaceManager().getPlayerRacePosition();
        raceResultsBoardData.lapsCompleted = common.tracksManager.getRaceManager().getPlayerLapsAmount();
        raceResultsBoardData.totalLapsAmount = currentGameConfig.lapsAmount.value;
        raceResultsBoardData.bestLap = common.timer.bestLapIndex!;
        raceResultsBoardData.bestLapSeconds = common.timer.bestLapTime;
        raceResultsBoardData.npcDifficulty = getNPCDifficultyFromIndex(currentGameConfig.npcsDifficulty.value);
        raceResultsBoardData.totalSeconds = common.timer.time;
        raceResultsBoardData.fitPoints = common.hoverboard.getEarnedFitPoints();

        const trackStatistics = common.tracksManager.getTrackStatistics()!;
        raceResultsBoardData.maxSpeed = trackStatistics.maxSpeed;
        raceResultsBoardData.maxAirSeconds = trackStatistics.maxAirSeconds;
        raceResultsBoardData.squatsAmount = trackStatistics.squatsAmount;
        raceResultsBoardData.jumpsAmount = trackStatistics.jumpsAmount;

        const raceResultsBoard = this.getRaceResultsBoard();
        raceResultsBoard.updateBoardData(raceResultsBoardData);
        raceResultsBoard.setVisible(true);

        if (common.hoverboardNetworking.room) {
            common.hoverboardNetworking.raceFinished();
        } else {
            common.CURRENT_STATE = GAME_STATES.POST_ENDGAME;
            common.MAIN_CHANNEL.emit("room-race-completed");
        }
    }

    private _getPlayerLapPercentageText(): string {
        const playerRacePercentage = this.getPlayerLapPercentage();
        return Math.trunc(playerRacePercentage * 100).toString().padStart(2, "0") + "%";
    }

    private _playerCloseCheck(playersPositionTimes: number[], currentPlayerIndex: number): void {
        const currentPlayerTime = playersPositionTimes[currentPlayerIndex];

        let minDistance = 1;
        let alertType = 0;

        // Previous player distance
        if (playersPositionTimes.length > currentPlayerIndex) {
            const dist = Math.abs(playersPositionTimes[currentPlayerIndex + 1] - currentPlayerTime);
            if (dist <= this._proximityTriggerActivationDistance) {
                minDistance = dist;
                alertType = 1;
            }
        }

        // Next player distance
        if (currentPlayerIndex > 0) {
            const dist = Math.abs(playersPositionTimes[currentPlayerIndex - 1] - currentPlayerTime);
            if (dist <= this._proximityTriggerActivationDistance && dist < minDistance) {
                alertType = 2;
            }
        }

        if (alertType !== this._prevAlertType) {
            switch (alertType) {
                case 0:
                    this.playerNotCloseEmitter.notify();
                    break;
                case 1:
                    this.playerCloseBehindEmitter.notify();
                    break;
                case 2:
                    this.playerCloseInFrontEmitter.notify();
                    break;
            }

            this._prevAlertType = alertType;
        }
    }
}