/**
 * @implements {Storage}
 */
export class FakeStorage {
    constructor() {
        this.keyValuePairs = [];
    }

    get length() {
        return this.keyValuePairs.length;
    }

    clear() {
        this.keyValuePairs.length = 0;
    }

    getItem(keyName) {
        for (const [key, value] of this.keyValuePairs) {
            if (key === keyName) {
                return value;
            }
        }

        return null;
    }

    key(index) {
        if (index >= this.keyValuePairs.length) {
            return null;
        } else {
            return this.keyValuePairs[index][0];
        }
    }

    removeItem(keyName) {
        for (let i = this.length - 1; i >= 0; i--) {
            if (this.keyValuePairs[i][0] === keyName) {
                this.keyValuePairs.splice(i, 1);
            }
        }
    }

    setItem(keyName, keyValue) {
        const len = this.length;
        for (let i = 0; i < len; i++) {
            const tuple = this.keyValuePairs[i];

            if (tuple[0] === keyName) {
                tuple[1] = keyValue;
                return;
            }
        }

        this.keyValuePairs.push([keyName, keyValue]);
    }
}