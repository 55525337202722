import { GameLocation, GameMap, GameMode, VALID_CONFIGURATIONS, type GameConfigurationIdentifier, type VagueGameConfigurationIdentifier } from "hoverfit-shared-netcode";
import { capitaliseFirstLetter } from "../utils/string-utils.js";
import { LapsAmountVariable } from "./variables/laps-amount-variable.js";
import { NPCsAmountVariable } from "./variables/npcs-amount-variable.js";
import { NPCsDifficultyVariable } from "./variables/npcs-difficulty-variable.js";
import { TagDurationVariable } from "./variables/tag-duration-variable.js";

export class HoverboardGameConfigJSON {
    location: GameLocation = GameLocation.City;
    mode: GameMode = GameMode.Race;
    track: number = 0;
}

export class HoverboardGameOnlineConfigJSON {
    isOnline: boolean = false;

    /** `null` means quick play was selected */
    roomID: number | null = null;
    isPrivateRoom: boolean = false;
}

export class HoverboardGameConfig implements GameConfigurationIdentifier {
    maxClients = -1;
    lapsAmount = new LapsAmountVariable();
    tagDuration = new TagDurationVariable();
    npcsAmount = new NPCsAmountVariable();
    npcsDifficulty = new NPCsDifficultyVariable();
    private _location?: GameLocation;
    private _mode?: GameMode;
    private _track?: number;

    static fromServerJSON(json: HoverboardGameConfigJSON) {
        const config = new HoverboardGameConfig();
        config.location = json.location;
        config.mode = json.mode;
        config.track = json.track;
        return config;
    }

    static fromServerJSONString(jsonStr: string) {
        return HoverboardGameConfig.fromServerJSON(JSON.parse(jsonStr));
    }

    isReady() {
        return this._location != null && this._mode != null && this._track != null;
    }

    get location() {
        if (!this._location) throw new Error("Game config not ready");
        return this._location;
    }

    set location(location: GameLocation) {
        this._location = location;
    }

    get locationConfig() {
        return VALID_CONFIGURATIONS.get(this.location)!;
    }

    get mode() {
        if (!this._mode) throw new Error("Game config not ready");
        return this._mode;
    }

    set mode(mode: GameMode) {
        this._mode = mode;
    }

    get modeConfig() {
        return this.locationConfig.modes.get(this.mode)!;
    }

    get track() {
        if (this._track === undefined) throw new Error("Game config not ready");
        return this._track;
    }

    set track(track: number) {
        this._track = track;
    }

    get trackConfig() {
        return this.modeConfig.tracks[this.track];
    }

    get map() {
        return this.trackConfig.map;
    }

    get supportedGameModes() {
        return Array.from(this.locationConfig.modes.keys());
    }

    get fancyMode() {
        return capitaliseFirstLetter(this.mode);
    }

    matches(other: HoverboardGameConfig) {
        return this.location === other.location && this.mode == other.mode && this.track == other.track;
    }

    get projectName() {
        return `fitness-resort-hoverboard-${this.map}`;
    }

    get sceneBinPath() {
        return `${this.projectName}.bin`;
    }

    get canHaveNPCs() {
        return this.mode === GameMode.Race;
    }

    copyFrom(other: HoverboardGameConfig) {
        this.location = other.location;
        this.mode = other.mode;
        this.track = other.track;
    }

    copyFromIdentifier(identifier: VagueGameConfigurationIdentifier) {
        this.location = identifier.location;
        const locationConfig = VALID_CONFIGURATIONS.get(this.location);
        if (!locationConfig) throw new Error("Invalid location");

        this.mode = identifier.mode ?? locationConfig.defaultMode;
        const modeConfig = locationConfig.modes.get(this.mode);
        if (!modeConfig) throw new Error("Mode not supported");

        this.track = identifier.track ?? modeConfig.defaultTrack;
        const trackConfig = modeConfig.tracks[this.track];
        if (!trackConfig) throw new Error("Track not supported");
    }

    toIdentifier(): GameConfigurationIdentifier {
        return {
            location: this.location,
            mode: this.mode,
            track: this.track,
        };
    }

    guessFromMap(map: GameMap) {
        for (const location of VALID_CONFIGURATIONS.keys()) {
            const locationConfig = VALID_CONFIGURATIONS.get(location);
            if (!locationConfig) throw new Error("No location for map");

            // look for any configuration that matches. sort game modes so that
            // this function is deterministic, and prioritise default game mode
            for (const mode of _sortWithPriority(Object.values(GameMode), locationConfig.defaultMode)) {
                const modeConfig = locationConfig.modes.get(mode);
                if (!modeConfig) continue;

                // prioritise default track
                const defaultTrack = modeConfig.defaultTrack;
                const defaultTrackConfig = modeConfig.tracks[defaultTrack];
                if (map === defaultTrackConfig.map) {
                    this.location = location;
                    this.mode = mode;
                    this.track = defaultTrack;
                    return;
                }

                for (let track = 0; track < modeConfig.tracks.length; track++) {
                    if (track === defaultTrack) continue;
                    const trackConfig = modeConfig.tracks[track];
                    if (map === trackConfig.map) {
                        this.location = location;
                        this.mode = mode;
                        this.track = track;
                        return;
                    }
                }
            }
        }

        throw new Error("No valid configuration exists for this map");
    }

    toString() {
        return `GameConfiguration{${this.mode} match in ${this.track}@${this.location}; resolves to map ${this.map}}`;
    }
}

function _sortWithPriority<T>(arr: Array<T>, priority: T): Array<T> {
    const priorityIdx = arr.indexOf(priority);
    if (priorityIdx >= 0) arr.splice(priorityIdx, 1);
    arr.sort();
    if (priorityIdx >= 0) arr.unshift(priority);
    return arr;
}

// XXX persistent, don't put in the common object
export const currentGameConfig = new HoverboardGameConfig();
