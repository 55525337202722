import { AudioID } from "src/hoverfit/audio/audio-manager/audio-id.js";
import { StatusEffectTarget } from "../status-effect-target.js";
import { StatusEffectType } from "../status-effect.js";
import { ExpiringStatusEffect, ExpiringStatusEffectParams } from "./expiring-status-effect.js";

export class RampStatusEffectParams extends ExpiringStatusEffectParams {
    sfxOnStart: AudioID | null = AudioID.STATUS_EFFECT_RAMP;
}

export class RampStatusEffect extends ExpiringStatusEffect {

    declare protected _params: RampStatusEffectParams;

    constructor(target: StatusEffectTarget, params: RampStatusEffectParams) {
        super(StatusEffectType.Ramp, target, params);
    }

    getParams(): RampStatusEffectParams {
        return this._params;
    }
}