import { Alignment, Component, Property, TextComponent } from "@wonderlandengine/api";
import { Globals } from "wle-pp";

export class WristComponent extends Component {
    static TypeName = "wrist";

    static Properties = {
        param: Property.float(1.0)
    };

    start() {
        this.wristObj = this.engine.scene.addObject(this.object);
        this.textComp = this.wristObj.addComponent(TextComponent);
        this.textComp.material = Globals.getDefaultMaterials(this.engine).myText.clone();
        this.textComp.material.color = [1, 1, 1];
        this.textComp.alignment = Alignment.Center;

        this.textComp.text = "FP";
        this.wristObj.rotateAxisAngleDegLocal([0, 1, 0], -90);
        this.wristObj.rotateAxisAngleDegObject([0, 0, 1], 90);
        this.wristObj.translateLocal([0, 0.2, -0.05]);

        Globals.getPlayerObjects(this.engine).myPlayer.wristController = this;
    }

    setWristText(newText) {
        this.textComp.text = newText;
    }
}
