import { ComponentConstructor } from "@wonderlandengine/api";
import { SyncedPickup } from "./implementations/synced-pickup.js";
import { PickupGrabber } from "./pickup-grabber.js";
import { Pickup } from "./pickup.js";

export class PickupsManager {

    private _pickups: Pickup[] = [];
    private _pickupGrabbers: PickupGrabber[] = [];

    private _active: boolean = false;

    private static pickupComponentTypes: ComponentConstructor[] = [];

    static getPickupComponentTypes(): ComponentConstructor[] {
        return PickupsManager.pickupComponentTypes;
    }

    constructor(pickups: Pickup[]) {
        this._pickups = pickups;
    }

    setPickupGrabbers(pickupGrabbers: PickupGrabber[]) {
        this._pickupGrabbers = pickupGrabbers;
    }

    reset() {
        for (const pickup of this._pickups) {
            pickup.reset();
        }
    }

    update(dt: number) {
        if (this._active) {
            for (const pickup of this._pickups) {
                pickup.update(dt, this._pickupGrabbers);
            }
        }
    }

    setActive(active: boolean) {
        this._active = active;

        for (const pickup of this._pickups) {
            pickup.setActive(active);
        }
    }

    onPickupRequestConfirmed(pickupID: string, syncCounter: number, pickupRequestUUID: string) {
        if (this._active) {
            for (const pickup of this._pickups) {
                if (pickup.getID() == pickupID) {
                    const syncedPickup = pickup as SyncedPickup;
                    syncedPickup.onPickupRequestConfirmed(syncCounter, pickupRequestUUID);
                }
            }
        }
    }

    onPickupRespawn(pickupID: string, syncCounter: number) {
        if (this._active) {
            for (const pickup of this._pickups) {
                if (pickup.getID() == pickupID) {
                    const syncedPickup = pickup as SyncedPickup;
                    syncedPickup.onPickupRespawn(syncCounter);
                }
            }
        }
    }
}