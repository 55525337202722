import { AxisCoupling, Column, Container, Icon, Label, Row, ScrollableViewportWidget, ScrollbarMode, WrapMode, type ScrollableViewportWidgetProperties, type Widget, type WidgetAutoXML } from "lazy-widgets";
import common from "../../../common.js";
import { BackPane } from "../../lazy-widgets/widgets/back-pane.js";
import { IconDecoratedButton } from "../../lazy-widgets/widgets/icon-decorated-button.js";

interface PlayerRowData {
    widget: Widget,
    muteImage: HTMLImageElement,
    wasMuted: boolean,
}

const MUTE_ICON_URL = 'assets/textures/ui/icons/kiosk/mute.svg';
const VOLUME_MAX_ICON_URL = 'assets/textures/ui/icons/kiosk/volume-max.svg';

export class PlayerList extends ScrollableViewportWidget {
    static override autoXML: WidgetAutoXML = {
        name: "player-list",
        inputConfig: []
    };

    private playerRows = new Map<string, PlayerRowData>();

    constructor(properties?: ScrollableViewportWidgetProperties) {
        const column = new Column();

        super(
            column,
            {
                minHeight: 140,
                scrollBarThickness: 12,
                scrollbarMode: ScrollbarMode.Layout,
                widthCoupling: AxisCoupling.Bi,
                heightCoupling: AxisCoupling.None,
                backgroundFill: "rgba(0,0,0,0)",
                backgroundGlowFill: "rgba(0,0,0,0)",
                primaryFill: "rgba(255, 255, 255, 0.75)",
                accentFill: "rgba(255, 255, 255, 0.60)",
                ...properties,
            },
        );

        common.MAIN_CHANNEL.on("room-player-join", ([key, player]: [key: string, player: { name: string }]) => {
            const muteImage = new Image();
            muteImage.crossOrigin = 'anonymous';
            muteImage.src = VOLUME_MAX_ICON_URL;

            const widget =
                new Container(
                    new BackPane(
                        new Row([
                            new Label(player.name, {
                                bodyTextFont: "1.5em sui-generis",
                                wrapMode: WrapMode.Ellipsis,
                                minWidth: 200, maxWidth: 200,
                            }),
                            new IconDecoratedButton(
                                new Icon(muteImage, {
                                    width: 16,
                                    height: 16,
                                }),
                                {
                                    containerPadding: { top: 5, right: 5, bottom: 5, left: 5 },
                                }
                            ).on("click", () => {
                                common.hoverboardNetworking.togglePeerMute(key);
                            })
                        ],
                            { multiContainerSpacing: 16 }
                        ),
                        { containerPadding: { top: 5, right: 10, bottom: 5, left: 10 } },
                    ), {
                    containerPadding: { top: 0, right: 6, bottom: 0, left: 0 },
                }
                );

            this.playerRows.set(key, { widget, muteImage, wasMuted: false });
            column.add(widget);
        });

        common.MAIN_CHANNEL.on("room-player-leave", (key: string) => {
            const rowData = this.playerRows.get(key);
            if (rowData) {
                this.playerRows.delete(key);
                column.remove(rowData.widget);
            }
        });

        common.MAIN_CHANNEL.on("room-leave", (key: string) => {
            for (const rowData of this.playerRows.values()) {
                column.remove(rowData.widget);
            }

            this.playerRows.clear();
        });
    }

    override preLayoutUpdate(): void {
        for (const [key, playerRowData] of this.playerRows) {
            const muted = common.hoverboardNetworking.isPeerMuted(key);
            if (playerRowData.wasMuted !== muted) {
                playerRowData.wasMuted = muted;
                playerRowData.muteImage.src = muted ? MUTE_ICON_URL : VOLUME_MAX_ICON_URL;
            }
        }

        super.preLayoutUpdate();
    }
}