import { unpackAssetShortID } from "src/hoverfit/utils/asset-utils.js";
import { MathUtils } from "wle-pp";
import { type AssetManifest } from "./asset-manifest-types.js";
import { AssetProvider, HeadwearSubCategory, ItemNamespace } from "./asset-provider.js";
import { type IAPContentController } from "./iap-content-controller.js";

const BUILT_IN_ASSET_MANIFEST: AssetManifest = {
    hoverboard: {
        // HACK emissive and secondaryMaterial switched because for some reason
        //      the editor reorders these two
        "hb_0": {
            name: "Oak",
            thumbnail: "assets/items/bi/hb_0/thumbnail.webp",
            url: "local://hoverboardDefaultRoot",
            materials: {
                primaryMaterial: {
                    pipeline: "Phong Opaque",
                    diffuseColor: [0, 0, 0, 1],
                },
                emissive: {
                    pipeline: "Phong Normalmapped",
                    diffuseTexture: "local://hoverboardOakDiffuseTexture",
                    normalTexture: "local://hoverboardOakNormalTexture",
                },
                tertiaryMaterial: {
                    pipeline: "Phong Opaque",
                    diffuseColor: [0, 0, 0, 1],
                },
                secondaryMaterial: {
                    pipeline: "Flat Opaque",
                    color: [0, 0.149, 0.902, 1],
                },
            },
            jetstreamColor: [0, 0.149, 0.902, 1],
        },
        "hb_1": {
            name: "Spruce",
            thumbnail: "assets/items/bi/hb_1/thumbnail.webp",
            url: "local://hoverboardDefaultRoot",
            materials: {
                primaryMaterial: {
                    pipeline: "Phong Opaque",
                    diffuseColor: [1, 1, 1, 1],
                },
                emissive: {
                    pipeline: "Phong Normalmapped",
                    diffuseTexture: "local://hoverboardSpruceDiffuseTexture",
                    normalTexture: "local://hoverboardSpruceNormalTexture",
                },
                tertiaryMaterial: {
                    pipeline: "Phong Opaque",
                    diffuseColor: [1, 1, 1, 1],
                },
                secondaryMaterial: {
                    pipeline: "Flat Opaque",
                    color: [0.447, 0.901, 0, 1],
                },
            },
            jetstreamColor: [0.447, 0.901, 0, 1],
        },
        "hb_2": {
            name: "Carbon",
            thumbnail: "assets/items/bi/hb_2/thumbnail.webp",
            url: "local://hoverboardDefaultRoot",
            materials: {
                primaryMaterial: {
                    pipeline: "Phong Opaque",
                    diffuseColor: [0, 0, 0, 1],
                },
                emissive: {
                    pipeline: "Phong Normalmapped",
                    diffuseTexture: "local://hoverboardCarbonDiffuseTexture",
                    normalTexture: "local://hoverboardCarbonNormalTexture",
                },
                tertiaryMaterial: {
                    pipeline: "Phong Opaque",
                    diffuseColor: [0, 0, 0, 1],
                },
                secondaryMaterial: {
                    pipeline: "Flat Opaque",
                    color: [0.56, 0, 0.851, 1],
                },
            },
            jetstreamColor: [0.56, 0, 0.851, 1],
        },
    },
    headwear: {
        "h_1": {
            type: HeadwearSubCategory.Hair,
            name: "Hair 1",
            thumbnailFemale: "assets/items/bi/h_1/thumbnail-female.webp",
            thumbnailMale: "assets/items/bi/h_1/thumbnail-male.webp",
            urlFemale: "assets/items/bi/h_1/model-female.glb",
            urlMale: "assets/items/bi/h_1/model-male.glb",
        },
    },
    suit: {
        "s_0": {
            name: "Red",
            thumbnailFemale: "assets/items/bi/s_0/thumbnail-female.webp",
            thumbnailMale: "assets/items/bi/s_0/thumbnail-male.webp",
            urlFemale: "local://suitDefaultFemaleRoot",
            urlMale: "local://suitDefaultMaleRoot",
            materialsFemale: {
                primaryMaterial: {
                    pipeline: "Phong Opaque Textured Emissive",
                    ambientColor: [0.677, 0.677, 0.677, 1],
                    diffuseColor: [0.6143, 0, 0, 1],
                    emissiveColor: [1, 0.6726456880569458, 0, 1],
                    diffuseTexture: "local://suitDefaultFemaleDiffuseTexture",
                    emissiveTexture: "local://suitDefaultFemaleEmissiveTexture",
                }
            },
            materialsMale: {
                primaryMaterial: {
                    pipeline: "Phong Opaque Textured Emissive",
                    ambientColor: [0.677, 0.677, 0.677, 1],
                    diffuseColor: [0.6143, 0, 0, 1],
                    emissiveColor: [1, 0.6726456880569458, 0, 1],
                    diffuseTexture: "local://suitDefaultMaleDiffuseTexture",
                    emissiveTexture: "local://suitDefaultMaleEmissiveTexture",
                }
            },
        },
        "s_1": {
            name: "Blue",
            thumbnailFemale: "assets/items/bi/s_1/thumbnail-female.webp",
            thumbnailMale: "assets/items/bi/s_1/thumbnail-male.webp",
            urlFemale: "local://suitDefaultFemaleRoot",
            urlMale: "local://suitDefaultMaleRoot",
            materialsFemale: {
                primaryMaterial: {
                    pipeline: "Phong Opaque Textured Emissive",
                    ambientColor: [0.677, 0.677, 0.677, 1],
                    diffuseColor: [0.0772, 0.3534, 0.5739, 1],
                    emissiveColor: [0, 1, 0.6636, 1],
                    diffuseTexture: "local://suitDefaultFemaleDiffuseTexture",
                    emissiveTexture: "local://suitDefaultFemaleEmissiveTexture",
                },
            },
            materialsMale: {
                primaryMaterial: {
                    pipeline: "Phong Opaque Textured Emissive",
                    ambientColor: [0.677, 0.677, 0.677, 1],
                    diffuseColor: [0.0772, 0.3534, 0.5739, 1],
                    emissiveColor: [0, 1, 0.6636, 1],
                    diffuseTexture: "local://suitDefaultMaleDiffuseTexture",
                    emissiveTexture: "local://suitDefaultMaleEmissiveTexture",
                },
            },
        },
        "s_2": {
            name: "Black",
            thumbnailFemale: "assets/items/bi/s_2/thumbnail-female.webp",
            thumbnailMale: "assets/items/bi/s_2/thumbnail-male.webp",
            urlFemale: "local://suitDefaultFemaleRoot",
            urlMale: "local://suitDefaultMaleRoot",
            materialsFemale: {
                primaryMaterial: {
                    pipeline: "Phong Opaque Textured Emissive",
                    ambientColor: [0.677, 0.677, 0.677, 1],
                    diffuseColor: [0.05829, 0.05829, 0.05829, 1],
                    emissiveColor: [0, 0.1485581, 0.9019607, 1],
                    diffuseTexture: "local://suitDefaultFemaleDiffuseTexture",
                    emissiveTexture: "local://suitDefaultFemaleEmissiveTexture",
                },
            },
            materialsMale: {
                primaryMaterial: {
                    pipeline: "Phong Opaque Textured Emissive",
                    ambientColor: [0.677, 0.677, 0.677, 1],
                    diffuseColor: [0.05829, 0.05829, 0.05829, 1],
                    emissiveColor: [0, 0.1485581, 0.9019607, 1],
                    diffuseTexture: "local://suitDefaultMaleDiffuseTexture",
                    emissiveTexture: "local://suitDefaultMaleEmissiveTexture",
                },
            },
        },
        "s_3": {
            name: "Green",
            thumbnailFemale: "assets/items/bi/s_3/thumbnail-female.webp",
            thumbnailMale: "assets/items/bi/s_3/thumbnail-male.webp",
            urlFemale: "local://suitDefaultFemaleRoot",
            urlMale: "local://suitDefaultMaleRoot",
            materialsFemale: {
                primaryMaterial: {
                    pipeline: "Phong Opaque Textured Emissive",
                    ambientColor: [0.677, 0.677, 0.677, 1],
                    diffuseColor: [0.0020, 0.4663, 0.1103, 1],
                    emissiveColor: [0.6529, 0.8565, 0, 1],
                    diffuseTexture: "local://suitDefaultFemaleDiffuseTexture",
                    emissiveTexture: "local://suitDefaultFemaleEmissiveTexture",
                },
            },
            materialsMale: {
                primaryMaterial: {
                    pipeline: "Phong Opaque Textured Emissive",
                    ambientColor: [0.677, 0.677, 0.677, 1],
                    diffuseColor: [0.0020, 0.4663, 0.1103, 1],
                    emissiveColor: [0.6529, 0.8565, 0, 1],
                    diffuseTexture: "local://suitDefaultMaleDiffuseTexture",
                    emissiveTexture: "local://suitDefaultMaleEmissiveTexture",
                },
            },
        },
        "s_4": {
            name: "White",
            thumbnailFemale: "assets/items/bi/s_4/thumbnail-female.webp",
            thumbnailMale: "assets/items/bi/s_4/thumbnail-male.webp",
            urlFemale: "local://suitDefaultFemaleRoot",
            urlMale: "local://suitDefaultMaleRoot",
            materialsFemale: {
                primaryMaterial: {
                    pipeline: "Phong Opaque Textured Emissive",
                    ambientColor: [0.677, 0.677, 0.677, 1],
                    diffuseColor: [0.6892, 0.6892, 0.6892, 1],
                    emissiveColor: [1, 0.8927, 0.2286, 1],
                    diffuseTexture: "local://suitDefaultFemaleDiffuseTexture",
                    emissiveTexture: "local://suitDefaultFemaleEmissiveTexture",
                },
            },
            materialsMale: {
                primaryMaterial: {
                    pipeline: "Phong Opaque Textured Emissive",
                    ambientColor: [0.677, 0.677, 0.677, 1],
                    diffuseColor: [0.6892, 0.6892, 0.6892, 1],
                    emissiveColor: [1, 0.8927, 0.2286, 1],
                    diffuseTexture: "local://suitDefaultMaleDiffuseTexture",
                    emissiveTexture: "local://suitDefaultMaleEmissiveTexture",
                },
            },
        },
        "s_5": {
            name: "Pink",
            thumbnailFemale: "assets/items/bi/s_5/thumbnail-female.webp",
            thumbnailMale: "assets/items/bi/s_5/thumbnail-male.webp",
            urlFemale: "local://suitDefaultFemaleRoot",
            urlMale: "local://suitDefaultMaleRoot",
            materialsFemale: {
                primaryMaterial: {
                    pipeline: "Phong Opaque Textured Emissive",
                    ambientColor: [0.677, 0.677, 0.677, 1],
                    diffuseColor: [0.8116, 0.1237, 0.4013, 1],
                    emissiveColor: [0.7264, 0.7264, 0.7264, 1],
                    diffuseTexture: "local://suitDefaultFemaleDiffuseTexture",
                    emissiveTexture: "local://suitDefaultFemaleEmissiveTexture",
                },
            },
            materialsMale: {
                primaryMaterial: {
                    pipeline: "Phong Opaque Textured Emissive",
                    ambientColor: [0.677, 0.677, 0.677, 1],
                    diffuseColor: [0.8116, 0.1237, 0.4013, 1],
                    emissiveColor: [0.7264, 0.7264, 0.7264, 1],
                    diffuseTexture: "local://suitDefaultMaleDiffuseTexture",
                    emissiveTexture: "local://suitDefaultMaleEmissiveTexture",
                },
            },
        },
    },
    hairColor: {
        "hc_black": {
            name: "Black",
            thumbnail: "assets/items/bi/hc_black/pattern.png",
            url: "assets/items/bi/hc_black/pattern.png",
        },
        "hc_blond": {
            name: "Blond",
            thumbnail: "assets/items/bi/hc_blond/pattern.png",
            url: "assets/items/bi/hc_blond/pattern.png",
        },
        "hc_brown": {
            name: "Brown",
            thumbnail: "assets/items/bi/hc_brown/pattern.png",
            url: "assets/items/bi/hc_brown/pattern.png",
        },
        "hc_dark-blond": {
            name: "Dark blond",
            thumbnail: "assets/items/bi/hc_dark-blond/pattern.png",
            url: "assets/items/bi/hc_dark-blond/pattern.png",
        },
        "hc_dark-brown": {
            name: "Dark brown",
            thumbnail: "assets/items/bi/hc_dark-brown/pattern.png",
            url: "assets/items/bi/hc_dark-brown/pattern.png",
        },
        "hc_ginger": {
            name: "Ginger",
            thumbnail: "assets/items/bi/hc_ginger/pattern.png",
            url: "assets/items/bi/hc_ginger/pattern.png",
        },
        "hc_gray": {
            name: "Gray",
            thumbnail: "assets/items/bi/hc_gray/pattern.png",
            url: "assets/items/bi/hc_gray/pattern.png",
        },
    },
    skin: {
        "sc_0": {
            name: "Light",
            thumbnail: "assets/items/bi/sc_0/thumbnail.webp",
            color: [0.8430, 0.5860, 0.4914, 1],
        },
        "sc_1": {
            name: "Medium",
            thumbnail: "assets/items/bi/sc_1/thumbnail.webp",
            color: [0.6098, 0.3797, 0.2406, 1],
        },
        "sc_2": {
            name: "Dark",
            thumbnail: "assets/items/bi/sc_2/thumbnail.webp",
            color: [0.2600, 0.1481, 0.0804, 1],
        },
    },
    bundle: {},
    location: {},
} as const;

export function getRandomBuiltInItemID(assetType: keyof typeof BUILT_IN_ASSET_MANIFEST): string {
    const itemNames = Object.keys(BUILT_IN_ASSET_MANIFEST[assetType]);
    return `${ItemNamespace.BuiltIn}:${itemNames[MathUtils.randomInt(0, itemNames.length - 1)]}`;
}

export function getBuiltInItemIDByIndex(assetType: keyof typeof BUILT_IN_ASSET_MANIFEST, index: number): string {
    const itemNames = Object.keys(BUILT_IN_ASSET_MANIFEST[assetType]);
    return `${ItemNamespace.BuiltIn}:${itemNames[index]}`;
}

export function getBuiltInItemTypeAmount(assetType: keyof typeof BUILT_IN_ASSET_MANIFEST): number {
    return Object.keys(BUILT_IN_ASSET_MANIFEST[assetType]).length;
}

export class BuiltInAssetProvider extends AssetProvider {
    constructor(controller: IAPContentController) {
        super(controller);
        controller.loadManifest(BUILT_IN_ASSET_MANIFEST, ItemNamespace.BuiltIn);
    }

    private ownAllInCategory(shortIDs: string[], map: Record<string, unknown>) {
        for (const shortID of Object.getOwnPropertyNames(map)) {
            unpackAssetShortID(shortIDs, BUILT_IN_ASSET_MANIFEST, shortID);
        }
    }

    async fetchInventory(): Promise<void> {
        const shortIDs: string[] = [];
        this.ownAllInCategory(shortIDs, BUILT_IN_ASSET_MANIFEST.hoverboard);
        this.ownAllInCategory(shortIDs, BUILT_IN_ASSET_MANIFEST.headwear);
        this.ownAllInCategory(shortIDs, BUILT_IN_ASSET_MANIFEST.suit);
        this.ownAllInCategory(shortIDs, BUILT_IN_ASSET_MANIFEST.hairColor);
        this.ownAllInCategory(shortIDs, BUILT_IN_ASSET_MANIFEST.skin);
        this.inventory.removeNamespace(ItemNamespace.BuiltIn);
        this.inventory.addManyShort(shortIDs, ItemNamespace.BuiltIn);
    }

    async purchaseItem(_shortID: string): Promise<boolean> {
        return false;
    }
}