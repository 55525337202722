import { Alignment, Column, FlexAlignment, Icon, IconFit, Label, LiveLabel, Row, Spacing, TextAlignMode, Variable, WrapMode, type WidgetAutoXML, type WidgetProperties } from "lazy-widgets";
import { BackPane } from "../../lazy-widgets/widgets/back-pane.js";
import { DecoratedButton } from "../../lazy-widgets/widgets/decorated-button.js";
import { LevelBar } from "./level-bar.js";
import { currentPlayerData, REWARD_TYPE_LABEL, RewardType } from "src/hoverfit/data/player-data.js";
import { getPotentialRewardBoardLevel, getPotentialRewardHelmetLevel, getPotentialRewardSuitLevel } from "../../misc/getRewardIDs.js";
import { DecoratedButtonBadge } from "../../lazy-widgets/widgets/base-decorated-button.js";

export const FP_PER_HELMET_LEVEL = 600;
export const FP_PER_SUIT_LEVEL = 1000;
export const FP_PER_BOARD_LEVEL = 2000;
const FP_PER_EQUIP_LEVEL = [FP_PER_HELMET_LEVEL, FP_PER_SUIT_LEVEL, FP_PER_BOARD_LEVEL] as const;

export class LifetimeLevelDisplay extends Column {
    static override autoXML: WidgetAutoXML = {
        name: "lifetime-level-display",
        inputConfig: [
            {
                mode: "value",
                name: "type",
                validator: "number"
            },
        ]
    };

    private equipButton: DecoratedButton;
    private readonly level: Variable<number>;
    private readonly fpDisplay: Variable<string>;
    private readonly levelDisplay: Variable<string>;
    private updateWatcher = () => {
        const fpPerEquip = FP_PER_EQUIP_LEVEL[this.type];
        const fp = currentPlayerData.totalFitPoints;
        const curLevel = currentPlayerData.rewards[this.type];
        const potentialLevel = Math.max(curLevel, fp / fpPerEquip);
        this.level.value = potentialLevel;
        this.fpDisplay.value = `${fp}/${(Math.trunc(potentialLevel) + 1) * fpPerEquip} FP`;
        this.levelDisplay.value = `LEVEL ${curLevel}`;
        this.updateBadge();
    };

    constructor(readonly type: RewardType, properties?: WidgetProperties) {
        const equipButton = new DecoratedButton("UNLOCK", {
            minWidth: 70, maxWidth: 70, minHeight: 24, maxHeight: 24,
        });

        const typeStr = REWARD_TYPE_LABEL[type];
        const level = new Variable(0);
        const fpDisplay = new Variable("");
        const levelDisplay = new Variable("");

        super([
            new Label(`LIFETIME ${typeStr.toUpperCase()}`, {
                bodyTextAlign: TextAlignMode.Center,
            }),
            new Row([
                new BackPane(
                    new Column([
                        new Icon(`assets/textures/ui/icons/kiosk/rewards-${typeStr}.svg`, {
                            fit: IconFit.Contain, width: 80, height: 56,
                        }),
                        new Label("LEVEL", {
                            bodyTextFont: "0.75em sui-generis",
                            bodyTextAlign: TextAlignMode.Center,
                        }),
                        new LevelBar(level, {
                            bodyTextFont: "0.75em sui-generis",
                        }),
                        new LiveLabel(fpDisplay, {
                            bodyTextFont: "0.5em sui-generis",
                            bodyTextAlign: TextAlignMode.Center,
                            wrapMode: WrapMode.Ellipsis,
                        }),
                    ]),
                    {
                        containerPadding: { left: 10, right: 10, top: 10, bottom: 2 }
                    }
                ),
            ], {
                multiContainerAlignment: {
                    main: FlexAlignment.SpaceAround, cross: Alignment.Stretch,
                }
            }),
            new Spacing(),
            new LiveLabel(levelDisplay, {
                bodyTextFont: "0.75em sui-generis",
                bodyTextAlign: TextAlignMode.Center,
            }),
            new Row([equipButton], {
                multiContainerAlignment: {
                    main: FlexAlignment.SpaceAround, cross: Alignment.Stretch,
                },
            })
        ], {
            flex: 1,
            ...properties,
        });

        this.equipButton = equipButton;
        this.level = level;
        this.fpDisplay = fpDisplay;
        this.levelDisplay = levelDisplay;
    }

    private updateBadge() {
        let expectedLevelGetter: () => number;

        switch(this.type) {
            case RewardType.Helmet:
                expectedLevelGetter = getPotentialRewardHelmetLevel;
                break;
            case RewardType.Suit:
                expectedLevelGetter = getPotentialRewardSuitLevel;
                break;
            case RewardType.Board:
                expectedLevelGetter = getPotentialRewardBoardLevel;
                break;
        }

        const curLevel = currentPlayerData.rewards[this.type];
        this.equipButton.child.text = curLevel === 0 ? "UNLOCK" : "UPGRADE";
        if (curLevel < expectedLevelGetter()) {
            this.equipButton.badge = DecoratedButtonBadge.Upgrade;
            this.equipButton.clickable = true;
        } else {
            this.equipButton.badge = null;
            this.equipButton.clickable = false;
        }
    }

    protected activate(): void {
        super.activate();
        this.level.watch(this.updateWatcher, true);
        currentPlayerData.listen(this.updateWatcher, ["totalFitPoints", "rewards"]);
    }

    protected deactivate(): void {
        currentPlayerData.unlisten(this.updateWatcher);
        this.level.unwatch(this.updateWatcher);
        super.deactivate();
    }
}