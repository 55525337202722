import { Object3D, TextComponent } from "@wonderlandengine/api";
import { formatSecondsToTime } from "src/hoverfit/utils/string-utils.js";
import { ObjectUtils } from "wle-pp";

export class TagResultBoardData {

    chasersWin: boolean = false;

    chasersCatches: [string, number][] = [];
    evadersSurvived: string[] = [];

    totalSeconds: number = 0;

    fitPoints: number = 0;

    maxSpeed: number = 0;
    squatsAmount: number = 0;
}

export class TagResultsBoard {

    private _tagResultsBoardObject: Object3D;

    private _chasersWin: boolean = false;

    private _winnersText: TextComponent;

    private _chasersCatchesObject: Object3D;
    private _chasersCatchesChasersWinAnchor: Object3D;
    private _chasersCatchesEvadersWinAnchor: Object3D;
    private _chasersNamesText: TextComponent;
    private _chasersCatchesText: TextComponent;

    private _evadersSurvivedObject: Object3D;
    private _evadersNamesText: TextComponent;

    private _totalTimeText: TextComponent;

    private _fitPointsText: TextComponent;

    private _maxSpeedText: TextComponent;

    private _squatsAmountText: TextComponent;

    constructor(raceResultsBoardObject: Object3D) {
        this._tagResultsBoardObject = raceResultsBoardObject;

        this._tagResultsBoardObject.pp_resetPositionLocal();

        this._winnersText = ObjectUtils.getObjectByName(this._tagResultsBoardObject, "Winners Text")!.getComponent(TextComponent)!;

        this._chasersCatchesObject = ObjectUtils.getObjectByName(this._tagResultsBoardObject, "Chasers Catches")!;
        this._chasersCatchesChasersWinAnchor = ObjectUtils.getObjectByName(this._tagResultsBoardObject, "Chasers Catches Chasers Win Anchor")!;
        this._chasersCatchesEvadersWinAnchor = ObjectUtils.getObjectByName(this._tagResultsBoardObject, "Chasers Catches Evaders Win Anchor")!;
        this._chasersNamesText = ObjectUtils.getObjectByName(this._tagResultsBoardObject, "Chasers Names Text")!.getComponent(TextComponent)!;
        this._chasersCatchesText = ObjectUtils.getObjectByName(this._tagResultsBoardObject, "Chasers Catches Text")!.getComponent(TextComponent)!;

        this._evadersSurvivedObject = ObjectUtils.getObjectByName(this._tagResultsBoardObject, "Evaders Survived")!;
        this._evadersNamesText = ObjectUtils.getObjectByName(this._tagResultsBoardObject, "Evaders Names Text")!.getComponent(TextComponent)!;

        this._totalTimeText = ObjectUtils.getObjectByName(this._tagResultsBoardObject, "Total Time Text")!.getComponent(TextComponent)!;

        this._fitPointsText = ObjectUtils.getObjectByName(this._tagResultsBoardObject, "Fit Points Text")!.getComponent(TextComponent)!;

        this._maxSpeedText = ObjectUtils.getObjectByName(this._tagResultsBoardObject, "Max Speed Text")!.getComponent(TextComponent)!;

        this._squatsAmountText = ObjectUtils.getObjectByName(this._tagResultsBoardObject, "Squats Amount Text")!.getComponent(TextComponent)!;

        this.setVisible(false);
    }

    setVisible(visible: boolean): void {
        ObjectUtils.setActive(this._tagResultsBoardObject, visible);

        if (this._chasersWin) {
            ObjectUtils.setActive(this._evadersSurvivedObject, false);
            this._chasersCatchesObject.pp_setPositionLocal(this._chasersCatchesChasersWinAnchor.pp_getPositionLocal());
        } else {
            this._chasersCatchesObject.pp_setPositionLocal(this._chasersCatchesEvadersWinAnchor.pp_getPositionLocal());
        }
    }

    updateBoardData(boardData: TagResultBoardData): void {
        this._chasersWin = boardData.chasersWin;

        if (this._chasersWin) {
            this._winnersText.text = "CHASERS WIN";
        } else {
            this._winnersText.text = "EVADERS WIN";
        }

        this._chasersNamesText.text = "";
        this._chasersCatchesText.text = "";
        for (const chaserCatches of boardData.chasersCatches) {
            this._chasersNamesText.text += chaserCatches[0] + "\n";
            this._chasersCatchesText.text += chaserCatches[1] + "\n";
        }

        this._evadersNamesText.text = "";
        for (const evaderSurvived of boardData.evadersSurvived) {
            this._evadersNamesText.text += evaderSurvived + "\n";
        }

        this._totalTimeText.text = formatSecondsToTime(boardData.totalSeconds);

        this._fitPointsText.text = Math.floor(boardData.fitPoints);

        this._maxSpeedText.text = boardData.maxSpeed + " mph";
        this._squatsAmountText.text = boardData.squatsAmount;
    }
}